export default {
  normal: () => `
    font-weight: normal;
  `,
  bold: () => `
    font-weight: bold;
  `,
  lighter: () => `
    font-weight: lighter;
  `,
  bolder: () => `
    font-weight: bolder;
  `,
};
