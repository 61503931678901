import { css } from '@ui-system/css';
import { DIVIDER_UUID, DividerProps } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useDividerDefaultStyle = makeStyle<Partial<DividerProps>>(
  ({ theme, props, utils }) => css`
    background-color: ${theme.colors[theme.mode].gray.A100};
    height: 1px;
    width: 100%;
    ${utils.applyOpacity(theme, props?.opacity)}
    ${utils.applyBackgroundColor(theme, props?.color)}
  `,
);

export const useDividerStyle = makeFinalStyleHook<Partial<DividerProps>>(
  DIVIDER_UUID,
  useDividerDefaultStyle,
);
