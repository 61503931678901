import find from 'lodash/find';
import get from 'lodash/get';
import { useMemo } from 'react';

import useFormValidationSchema from './useFormValidationSchema';

export function useMaxAndMinValidationValues(fieldPath: string): {
  max: number | null;
  min: number | null;
} {
  const schema = useFormValidationSchema();

  return useMemo(() => {
    const description = schema?.describe ? schema?.describe() : {};
    return {
      min: get(
        find(get(description, `fields.${fieldPath}.tests`), { name: 'min' }),
        'params.min',
      ),
      max: get(
        find(get(description, `fields.${fieldPath}.tests`), { name: 'max' }),
        'params.max',
      ),
    };
  }, [fieldPath, schema]);
}
