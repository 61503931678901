import { Color } from '@ui-system/css';
import { I18nShape } from '@ui-system/deps';
import { Style } from '@ui-system/interfaces/types';
import { TypographyVariant } from '@ui-system/interfaces-typography';
import { nanoid } from 'nanoid';
import { FC, ReactNode } from 'react';
import { F } from 'ts-toolbelt';

export interface LinkProps {
  color?: Color;
  style?: Style;
  modifiers?: string;
  href?: string;
  i18n?: I18nShape;
  children?: ReactNode;
  target?: string;
  onClick?: F.Function;
  textVariant?: TypographyVariant;
}

export const LINK_UUID = nanoid();

export type LinkType = FC<LinkProps>;
