import { AsyncState } from '@zustand-store/core';
import get from 'lodash/get';

import { SearchRequestObject, StoresLocationSearchResult } from './types';

export const storesListSelector = (
  state: AsyncState<StoresLocationSearchResult, SearchRequestObject>,
) => state.data?.list;

export const totalStoresListSelector = (
  state: AsyncState<StoresLocationSearchResult, SearchRequestObject>,
) => state.data?.total;

export const searchCoordinatesSelector = (
  state: AsyncState<StoresLocationSearchResult, SearchRequestObject>,
) => ({
  lat: get(state, 'request.lat', 0),
  lng: get(state, 'request.lng', 0),
});

export const searchRadiusSelector = (
  state: AsyncState<StoresLocationSearchResult, SearchRequestObject>,
) => state.request?.radius;
