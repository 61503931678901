import { SET_SALES_ORG_BRAND, SetSalesOrgBrand } from './types';

export const setCurrentSalesOrgBrandAction = (
  salesOrgBrandID: number,
): SetSalesOrgBrand => ({
  type: SET_SALES_ORG_BRAND,
  payload: {
    salesOrgBrandID,
  },
});
