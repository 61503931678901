import { CssBaseline } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ProviderProps, ThemeProviderType } from '@ui-system/theme';
import * as React from 'react';

import createTheme from './createTheme';

const ThemeProvider: ThemeProviderType = ({
  children,
  theme,
}: ProviderProps) => {
  const materialUiTheme = createTheme(theme);
  return (
    <MuiThemeProvider theme={materialUiTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
