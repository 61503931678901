const namespace = 'registration';
const uploadSpreadSheetNamespace = `${namespace}.uploadSpreadSheet`;

const UPLOAD_PANELS_SPREADSHEET_MODAL = {
  TITLE: {
    key: `${uploadSpreadSheetNamespace}.title`,
    message: 'Upload Panels',
  },
  DESCRIPTION: {
    key: `${uploadSpreadSheetNamespace}.description`,
    message:
      'Here you can upload your panels list (model name and serial) based on the template. The panels will be added to your project.',
  },
  USE_REC_TEMPLATE_STYLE: {
    key: `${uploadSpreadSheetNamespace}.useRecTemplateStyle`,
    message: 'USE REC TEMPLATE STYLE',
  },
  STYLE_OF_REC_FLASHER: {
    key: `${uploadSpreadSheetNamespace}.styleOfRecFlasher`,
    message: 'Style of REC flasher data files insert serial no only',
  },
  UPLOAD_REC_TEMPLATE: {
    key: `${uploadSpreadSheetNamespace}.uploadRecTemplate`,
    message: 'Upload REC template',
  },
  DOWNLOAD_REC_TEMPLATE: {
    key: `${uploadSpreadSheetNamespace}.downloadRecTemplate`,
    message: 'Download REC STYLE template',
  },
  FILE_LIMIT_WARNING: {
    key: `${uploadSpreadSheetNamespace}.fileLimitWarning`,
    message:
      'If your excel file exceeds 1000 rows, the operation will be done in the background. To avoid this, you can upload multiple files.',
  },
  USE_THIS_TEMPLATE: {
    key: `${uploadSpreadSheetNamespace}.useThisTemplate`,
    message: 'Use this template to upload panels',
  },
  YOUR_PRODUCT_WASNT_FOUND: {
    key: `${uploadSpreadSheetNamespace}.yourProductWasntFound`,
    message: 'Your product wasnt found by the system?',
  },
  DOWNLOAD_THE_LIST: {
    key: `${uploadSpreadSheetNamespace}.downloadTheList`,
    message: 'Download the list of products',
  },
  THE_DOCUMENT: {
    key: `${uploadSpreadSheetNamespace}.theDocument`,
    message: 'The uploaded document has',
  },
  SUCCESSFULLY: {
    key: `${uploadSpreadSheetNamespace}.successfully`,
    message: 'successfully validated product(s) and',
  },
  WITH_ERROR: {
    key: `${uploadSpreadSheetNamespace}.withError`,
    message: 'with error(s).',
  },
  CLICK_ON_SAVE_TO_CONTINUE: {
    key: `${uploadSpreadSheetNamespace}.clickOnSaveToContinue`,
    message: 'Click on save to continue.',
  },
  BACKGROUND: {
    key: `${uploadSpreadSheetNamespace}.background`,
    message: 'Your upload is being processed, please check again in 10 minutes',
  },
};

const I18N = {
  UPLOAD_PANELS_SPREADSHEET_MODAL,
};

export default I18N;
