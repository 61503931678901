import { Color, Padding } from '@ui-system/css';
import { nanoid } from 'nanoid';
import * as React from 'react';
import { U } from 'ts-toolbelt';

export interface RatingProps {
  maxRating: number;
  iconsSize?: number;
  onChange?: (value: number) => void;
  initialValue?: U.Nullable<number>;
  disabled?: boolean;
  color?: Color;
  errorColor?: Color;
  error?: boolean;
  p?: Padding;
}

export const RATING_UUID = nanoid();

export type RatingType = React.FC<RatingProps>;
