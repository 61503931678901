import { css } from '@ui-system/css';

export const arrowLeftStyle = css`
  float: left;
  width: 40px;
  height: 40px;
  border: none;
  position: absolute;
  left: 0;
  cursor: pointer;
`;

export const arrowRightStyle = css`
  float: right;
  width: 40px;
  height: 40px;
  border: none;
  position: absolute;
  right: 0;
  cursor: pointer;
`;
const STYLE = {
  style: css`
    height: 40px;
  `,
  leftArrow: {
    style: arrowLeftStyle,
  },
  rightArrow: {
    style: arrowRightStyle,
  },
};

export default STYLE;
