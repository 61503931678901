const namespace = 'productHelp';
const serialnumberNamespace = `${namespace}.serialnumber`;

const SERIALNUMBER = {
  TITLE: {
    key: `${serialnumberNamespace}.modalTitle`,
    message: 'Serialnumber help',
  },
  DESCRIPTION: {
    key: `${serialnumberNamespace}.modalDescription`,
    message: 'Check where you can find your serialnumber in the image below.',
  },
};

const I18N = {
  SERIALNUMBER,
};

export default I18N;
