import { createDI } from '@di/core';
import api, { ApiResponse } from '@mmw/api-v2';
import { LanguageCode } from '@mmw/constants-languages';
import contextualConfig from '@mmw/contextual-config';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import {
  DemoDeviceRegisteredJSON,
  RegisterDemoDeviceRequestJSON,
} from '@mmw/services-core-admin-demo-device/types';
import { OperationResultJSON } from '@mmw/services-core-manu-orgunit/types';
import autoBind from 'auto-bind';

import logger from './log';
import { PATHS } from './paths';
import { DemoDeviceCampaignSummaryJSON } from './types';

const { salesOrgBrandID } = contextualConfig.application;

export default class TraderDemoDeviceService {
  static createDI = createDI<TraderDemoDeviceService>;

  authenticationService: AuthenticationService;

  constructor({
    authenticationService,
  }: Partial<TraderDemoDeviceService> = {}) {
    if (authenticationService)
      this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getCampaignOverview({ campaignID }: { campaignID: number }) {
    logger.debug('Trying to get campaign overview');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<DemoDeviceCampaignSummaryJSON> =
        await api.get(PATHS.getCampaignOverviewPath(String(campaignID)), {
          headers,
        });
      const { data } = response;
      logger.info('Successfully got campaign overview');
      return data;
    } catch (error) {
      logger.error('Error getting campaign overview, error=%O', error);
      throw error;
    }
  }

  async registerDevice(
    request: RegisterDemoDeviceRequestJSON,
    language: LanguageCode,
  ): Promise<DemoDeviceRegisteredJSON[]> {
    logger.debug('Trying to register demo device');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<DemoDeviceRegisteredJSON[]> = await api.post(
        PATHS.registerDemoDevicePath(language),
        request,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully registered demo device');
      return data;
    } catch (error) {
      logger.error('Error when registering demo device, error=%O', error);
      throw error;
    }
  }

  async getDevices(
    orgunitID: number,
    language: string,
    campaignID?: number,
  ): Promise<DemoDeviceRegisteredJSON[]> {
    logger.debug('Trying to get devices');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<DemoDeviceRegisteredJSON[]> = await api.get(
        PATHS.getDevicesPath(salesOrgBrandID, orgunitID, language, campaignID),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got devices');
      return data;
    } catch (error) {
      logger.error('Error when getting devices, error=%O', error);
      throw error;
    }
  }

  async removeDeviceFromShowRoom(
    registrationItemIDs: number[],
  ): Promise<OperationResultJSON> {
    logger.debug(
      'Trying to remove device from showroom of ids',
      registrationItemIDs,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<OperationResultJSON> = await api.delete(
        PATHS.removeDeviceFromShowRoomPath(),
        {
          headers,
          data: registrationItemIDs,
        },
      );
      const { data } = response;
      logger.info(
        'Successfully removed device from show room',
        registrationItemIDs,
      );
      return data;
    } catch (error) {
      logger.error('Error when removing from show room, error=%O', error);
      throw error;
    }
  }

  async setShowRoomReady({
    campaignID,
    ids,
  }: {
    campaignID: number;
    ids: number[];
  }) {
    logger.debug('Trying to set show room ready');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Record<string, any>> = await api.post(
        PATHS.getShowRoomReadyPath(String(campaignID)),
        ids,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully set show room ready');
      return data;
    } catch (error) {
      logger.error('Error set show room ready, error=%O', error);
      throw error;
    }
  }

  async setShowRoomNotReady({
    campaignID,
    ids,
  }: {
    campaignID: number;
    ids: number[];
  }) {
    logger.debug('Trying to set show room not ready');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Record<string, any>> = await api.post(
        PATHS.getShowRoomNotReadyPath(String(campaignID)),
        ids,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully set show room not ready');
      return data;
    } catch (error) {
      logger.error('Error set show room not ready, error=%O', error);
      throw error;
    }
  }
}
