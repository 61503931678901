const namespace = 'camera';

const i18n = {
  NO_ACCESS_TO_CAMERA: {
    key: `${namespace}.noAccessToCamera`,
    message: 'No access to camera',
  },
  ADD_PHOTO: {
    key: `${namespace}.addPhoto`,
    message: 'Add photo',
  },
  BARCODE_SUBHEADER: {
    key: `${namespace}.barcodeInstructions`,
    message: 'Please place the barcode along the red line with your camera',
  },
  PLEASE_GRANT_ACCESS_TO_CAMERA: {
    key: `${namespace}.pleaseGrantAccessToCamera`,
    message: 'Please grant us access to camera in your app settings',
  },
  PLEASE_GRANT_ACCESS_TO_CAMERA_ROLL: {
    key: `${namespace}.pleaseGrantAccessToCameraRoll`,
    message:
      'To use this feature you need to grant permission to access your files and media in your app settings',
  },
};

export const {
  NO_ACCESS_TO_CAMERA,
  ADD_PHOTO,
  BARCODE_SUBHEADER,
  PLEASE_GRANT_ACCESS_TO_CAMERA,
  PLEASE_GRANT_ACCESS_TO_CAMERA_ROLL,
} = i18n;

export default i18n;
