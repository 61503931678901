export const PERSON = {
  FIRSTNAME: {
    MAX_LENGTH: 30,
  },
  LASTNAME: {
    MAX_LENGTH: 50,
  },
  EMAIL: {
    MAX_LENGTH: 100,
  },
  PHONE: {
    MAX_LENGTH: 30,
  },
  PHONEWORK: {
    MAX_LENGTH: 30,
  },
  PHONEMOBILEWORK: {
    MAX_LENGTH: 30,
  },
  PHONEMOBILE: {
    MAX_LENGTH: 30,
  },
  FAX: {
    MAX_LENGTH: 30,
  },
  GENDER: {
    MAX_LENGTH: 1,
  },
  DOMKEYTITLE: {
    MAX_LENGTH: 10,
  },
  DOMKEYSALUTATION: {
    MAX_LENGTH: 30,
  },
  ERP_PERSONKEY: {
    MAX_LENGTH: 30,
  },
};

export const CONSUMER = {
  ERP_CONSUMER: {
    // orgunitconsumers relationship table
    MAX_LENGTH: 20,
  },
  CONSUMER_NAME: {
    MAX_LENGTH: 100,
  },
  MEMBERNUMBER: {
    MAX_LENGTH: 12,
  },
};

export const INSTALLER = {
  PHONE: {
    MAX_LENGTH: 30,
  },
  EMAIL: {
    MAX_LENGTH: 50,
  },
};

export const COMPANY = {
  FAX: {
    // orgunitconsumers relationship table
    MAX_LENGTH: 20,
  },
  PHONE: {
    MAX_LENGTH: 100,
  },
  SALESTAXID: {
    MAX_LENGTH: 20,
  },
  TAXNUMBER: {
    MAX_LENGTH: 20,
  },
  HOMEPAGE: {
    MAX_LENGTH: 50,
  },
  CRNUMBER: {
    MAX_LENGTH: 20,
  },
  CRCITY: {
    MAX_LENGTH: 20,
  },
  EMAIL: {
    MAX_LENGTH: 100,
  },
  COMPANY: {
    MAX_LENGTH: 200,
  },
};

export const ADDRESS = {
  COMPANY: {
    MAX_LENGTH: 200,
  },
  STREET: {
    MAX_LENGTH: 250,
  },
  NR: {
    MAX_LENGTH: 80,
  },
  COMPLEMENT: {
    MAX_LENGTH: 80,
  },
  ZIPCODE: {
    MAX_LENGTH: 12,
  },
  CITY: {
    MAX_LENGTH: 250,
    MIN_LENGTH: 3,
  },
  STATE: {
    MAX_LENGTH: 80,
  },
  COUNTRY: {
    MAX_LENGTH: 2,
  },
  NAME1: {
    MAX_LENGTH: 50,
  },
  NAME2: {
    MAX_LENGTH: 50,
  },
};

export const COMMON = {
  LANGUAGE: {
    MAX_LENGTH: 2,
    MIN_LENGTH: 2,
  },
  EMAIL: {
    MAX_LENGTH: 100,
  },
  USER: {
    MAX_LENGTH: 60,
  },
  PASSWORD: {
    MAX_LENGTH: 80,
  },
  APP_ID: {
    MAX_LENGTH: 80,
  },
};

export const FILE = {
  NAME: {
    MAX_LENGTH: 100,
  },
  FORMAT: {
    MAX_LENGTH: 8,
  },
};

export const REGISTRATION = {
  INVOICE_NUMBER: {
    MAX_LENGTH: 40,
  },
  EXTERNAL_ORDERNUMBER: {
    MAX_LENGTH: 50,
  },
};

export const REGISTRATION_ITEM = {
  SERIALNUMBER: {
    MAX_LENGTH: 40,
  },
  IMEI: {
    MAX_LENGTH: 15,
  },
  GIVEN_EAN: {
    MAX_LENGTH: 30,
  },
  COUPON: {
    MAX_LENGTH: 12,
  },
  PROMOTION_CODE: {
    MAX_LENGTH: 12,
  },
};

export const PRODUCT = {
  NAME: {
    MAX_LENGTH: 60,
  },
  EAN: {
    MAX_LENGTH: 60,
  },
  SERIALNUMBER: {
    MAX_LENGTH: 40,
  },
};

export const ACCOUNT = {
  ACCOUNTOWNER: {
    MAX_LENGTH: 100,
  },
  ACCOUNT: {
    MAX_LENGTH: 20,
  },
  AGENCY: {
    MAX_LENGTH: 20,
  },
  BIC: {
    MAX_LENGTH: 20,
  },
  COUNTRY: {
    MAX_LENGTH: 2,
  },
  IBAN: {
    MAX_LENGTH: 40,
  },
  MANUALBANKNAME: {
    MAX_LENGTH: 100,
  },
};

export const QUESTION_GROUP = {
  ANSWER: {
    MAX_LENGTH: 255,
  },
};

export const PROJECT = {
  NAME: {
    MAX_LENGTH: 80,
  },
  PRODUCT: {
    NAME: {
      MAX_LENGTH: 30,
    },
    QUANTITY: {
      MIN: 1,
    },
  },
};

export const SOLAR_PRODUCT_ITEM = {
  SERIALNUMBER: {
    MAX_LENGTH: 30,
  },
};
