/* eslint-disable @typescript-eslint/ban-types */
import { isDevelopment } from '@mmw/environment';
import { isEmpty, noop, some } from 'lodash';
import React, {
  ReactElement,
  ReactNode,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid/v4';
import { ObjectSchema } from 'yup';

import FastFormContext from '../context';
import {
  useOnSubmitErrorCallback,
  useOnSubmitSuccessCallback,
  useStartFormState,
} from '../hooks/internal';
import useFastFormProviderContext from '../hooks/useFastFormProviderContext';
import { changeConfigAction } from '../store/actions';
import { FastFormOptions, FormId, OptionalFastFormOptions } from '../types';
import { DevJSONView } from './DevJSONView';

const DEFAULT_FAST_FORM_OPTIONS: FastFormOptions<any> = {
  enableReinitialize: true,
  deleteFormOnUnmount: false,
  cleanValuesOnReinitialize: false,
  mergeInitialValues: undefined,
  onPressEnter: true,
  isValuesEmpty: (values: any): boolean => isEmpty(some(values, isEmpty)),
};

export type FastFormPropsType<T extends Record<string, any>> = {
  formId?: FormId;
  initialValues: T;
  validationSchema: ObjectSchema<T>;
  children: ReactNode;
  options?: OptionalFastFormOptions<T>;
  onSubmitSuccess?: (values: T) => void;
  onSubmitError?: () => void;
};

function OnSubmitErrorWatcher() {
  useOnSubmitErrorCallback();
  return null;
}

function OnSubmitSuccessWatcher() {
  useOnSubmitSuccessCallback();
  return null;
}

export function OnValidationSchemaChanged(): null {
  const context = useFastFormProviderContext();
  const dispatch = useDispatch();
  const { formId, options, validationSchema } = context;
  useEffect(() => {
    dispatch(changeConfigAction(formId, options, validationSchema));
  }, [dispatch, formId, options, validationSchema]);
  return null;
}

const FastFormProvider = <T extends Record<string, any>>({
  formId,
  initialValues,
  validationSchema,
  children,
  options = {},
  onSubmitSuccess,
  onSubmitError,
}: FastFormPropsType<T>): ReactElement<any, any> | null => {
  const [ownFormId] = useState(() => {
    if (formId) {
      return formId;
    }
    return uuid();
  });
  const cachedOptions = useMemo(
    (): FastFormOptions<T> => ({
      ...DEFAULT_FAST_FORM_OPTIONS,
      ...options,
    }),
    [options],
  );
  const config = useMemo(
    () => ({
      options: cachedOptions,
      formId: ownFormId,
      validationSchema,
      validationDescription: validationSchema.describe({ parent: {} }),
      onSubmitSuccess: onSubmitSuccess || noop,
      onSubmitError: onSubmitError || noop,
    }),
    [
      cachedOptions,
      onSubmitError,
      // XXX: onSubmitSuccess removed from dependencies array because it was causing a bug
      // onSubmitSuccess,
      ownFormId,
      validationSchema,
    ],
  );
  useStartFormState(config, initialValues);
  return (
    <FastFormContext.Provider value={config}>
      {onSubmitSuccess && <OnSubmitSuccessWatcher />}
      {onSubmitError && <OnSubmitErrorWatcher />}
      {isDevelopment() ? (
        <Suspense fallback={null}>
          <DevJSONView>{children}</DevJSONView>
        </Suspense>
      ) : (
        children
      )}
    </FastFormContext.Provider>
  );
};

export default FastFormProvider;
