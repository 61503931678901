import { EMPTY_ARRAY } from '@mmw/constants-utils';
import React, { createContext, ReactNode, useMemo } from 'react';

const INITIAL_VALUES = {
  restrictedLanguages: EMPTY_ARRAY,
};

export type AvailableLanguagesContextValue = {
  restrictedLanguages: Array<string>;
};

export const AvailableLanguagesContext =
  createContext<AvailableLanguagesContextValue>(INITIAL_VALUES);

type Props = {
  children: ReactNode;
  restrictedLanguages: Array<string> | null | void;
};

export const AvailableLanguagesProvider = ({
  restrictedLanguages,
  children,
}: Props) => {
  const config = useMemo(
    () => ({
      restrictedLanguages: restrictedLanguages || EMPTY_ARRAY,
    }),
    [restrictedLanguages],
  );
  return (
    <AvailableLanguagesContext.Provider value={config}>
      {children}
    </AvailableLanguagesContext.Provider>
  );
};
