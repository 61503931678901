import ExtendableError from '@mmw/error';

export class AuthenticationRequiredError extends ExtendableError {}
export class ProjectDeletedOrReversedError extends ExtendableError {}
export class SearchFoundNoResultsError extends ExtendableError {}
export class ProjectError extends ExtendableError {
  constructor(id, error) {
    super(error);
    this.id = id;
    this.originalError = error;
  }
}
