export default {
  normal: () => `
    font-style: normal;
  `,
  italic: () => `
    font-style: italic;
  `,
  oblique: () => `
    font-style: oblique;
  `,
};
