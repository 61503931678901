/* eslint-disable no-template-curly-in-string */
import {
  INVALID_INPUT_ALONE_CHARACTERS,
  INVALID_INPUT_CHARACTERS,
  MAX_CHARACTER_REPETITION,
  NO_INFO_WORDS,
  NUMBERS,
} from '@mmw/common-validations/constants';
import { INVALID, REQUIRED } from '@mmw/constants-validation-names';

const namespace = 'validation';

const DEFAULT = {
  REQUIRED: {
    key: `${namespace}.${REQUIRED}`,
    message: 'Field is required',
  },
  IS_FUTURE: {
    key: `${namespace}.isFuture`,
    message: 'Date cannot be in the future',
  },
  NUMBER_MIN: {
    key: `${namespace}.numberMin`,
    message: 'Value must be at least {min}',
  },
  NUMBER_MAX: {
    key: `${namespace}.numberMax`,
    message: 'Value should not be more than {max}',
  },
  INVALID: {
    key: `${namespace}.${INVALID}`,
    message: 'Field is invalid',
  },
  MIN: {
    key: `${namespace}.minCharacters`,
    message: 'Must be at least {min} characters',
  },
  MIN_ITEMS: {
    key: `${namespace}.minItems`,
    message: 'Must have at least {min} items',
  },
  MAX: {
    key: `${namespace}.maxCharacters`,
    message: 'Must be at most {max} characters',
  },
  MAX_ITEMS: {
    key: `${namespace}.maxItems`,
    message: 'Must have less than or equal to {max} items',
  },
  MAX_FILES: {
    key: `${namespace}.maxFiles`,
    message: "Can't upload more than {max} files.",
  },
  MIN_FILES: {
    key: `${namespace}.minFiles`,
    message: 'At least {min} files must be uploaded.',
  },
  MAX_CHAR_EXCEEDED: {
    key: `${namespace}.maxCharExceeded`,
    message:
      'This field should only have up to {validationCount, number} characters and the given value has {valueCount, number}.',
  },
  EMAIL: {
    key: `${namespace}.email`,
    message: 'Must be a valid e-mail',
  },
  PHONE_NUMBER_LOOKUP_FAIL: {
    key: `${namespace}.phoneNumberLookup`,
    message: 'The phone number informed is invalid, check and try again',
  },
  ACCOUNT_CREATION: {
    EMAIL_NOT_AVAILABLE: {
      key: `${namespace}.consumer.accountCreation.emailNotAvailable`,
      message: 'This email is already in use, try to enter a different one',
    },
  },
  HAS_NUMBER: {
    key: `${namespace}.hasNumber`,
    message: 'Numbers not allowed',
    values: {
      restrictions: NUMBERS,
    },
  },
  HAS_CHARACTER_REPETITION: {
    key: `${namespace}.hasCharacterRepetition`,
    message:
      'Do not repeat characters more than {maxRepetition} times in a row',
    values: {
      maxRepetition: MAX_CHARACTER_REPETITION,
    },
  },
  HAS_AVOIDED_CHARACTERS: {
    key: `${namespace}.hasAvoidedCharacters`,
    message: 'Special characters must to be avoided',
    values: {
      restrictions: INVALID_INPUT_CHARACTERS,
    },
  },
  HAS_AVOIDED_WORDS: {
    key: `${namespace}.hasAvoidedWords`,
    message: 'Special words must to be avoided',
    values: {
      restrictions: NO_INFO_WORDS,
    },
  },
  HAS_AVOIDED_CHARACTERS_AND_WORDS: {
    key: `${namespace}.hasAvoidedCharactersAndWords`,
    message: 'Special characters and words must to be avoided',
    values: {
      restrictions: [...INVALID_INPUT_CHARACTERS, ...NO_INFO_WORDS],
    },
  },
  HAS_AVOIDED_ALONE_CHARACTER: {
    key: `${namespace}.hasAvoidedAloneCharacter`,
    message: 'Special alone characters must to be avoided',
    values: {
      restrictions: INVALID_INPUT_ALONE_CHARACTERS,
    },
  },
  HAS_ONLY_ZEROS: {
    key: `${namespace}.hasOnlyZeros`,
    message: 'Only zeros not allowed',
  },
  INSTALLATION_TYPE_PATTERN: {
    key: `${namespace}.installationTypePattern`,
    message:
      'Installation type must be 0, 1, Residential, Commercial, or an empty value. 0 stands for RESIDENTIAL and 1 stands for COMMERCIAL',
  },
  RESTRICTIONS: {
    key: `${namespace}.restrictions`,
    message: 'Restrictions',
  },
  INVALID_INPUTS: {
    key: `${namespace}.invalidInputs`,
    message: 'The following inputs are not allowed',
  },
  PASSWORD_MUST_MATCH: {
    key: `${namespace}.passwordsMustMatch`,
    message: 'Passwords must match',
  },
  PASSWORD_STRENGTH_SIX_DIGITS: {
    key: `${namespace}.passwordStrengthSix`,
    message:
      'The password has to be at least six characters long. Please use at least one letter uppercase and one lowercase, at least one digit and the at least one of the following special characters .!?@#$%^*\\-_+=',
  },
  COMPANY_ONLY_FOR_BUSINESS_CONSUMERS: {
    key: `${namespace}.companyOnlyForBusinessConsumers`,
    message:
      'Please note that an entry in this field is not necessary unless the customer is a business customer.\n' +
      'If so, please change the entry in the "Business customer" column to "Yes".',
  },
};

export const CUSTOM = {
  hasAvoidedCharactersAndWords: (restrictions: string[]) => ({
    ...DEFAULT.HAS_AVOIDED_CHARACTERS_AND_WORDS,
    values: {
      restrictions,
    },
  }),
  hasAvoidedAloneCharacter: (restrictions: string[]) => ({
    ...DEFAULT.HAS_AVOIDED_ALONE_CHARACTER,
    values: {
      restrictions,
    },
  }),
};

export default DEFAULT;
