import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';

import createFromProps from '../create-from-props';
import { FromProps, Justify } from '../types';

const applyJustify: F.Function<[Theme, Justify]> & FromProps = (
  theme,
  justify: Justify,
) => {
  if (!justify) return '';
  return `justify-content: ${justify}`;
};
applyJustify.fromProps = createFromProps(applyJustify, 'justify');

export default applyJustify;
