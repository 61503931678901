import contextualConfig from '@mmw/contextual-config';
import { includes, isEmpty, isString, uniqueId, without } from 'lodash';
import React, { ComponentType, Fragment, ReactElement } from 'react';
import ReactHtmlParser, { processNodes, Transform } from 'react-html-parser';
import { U } from 'ts-toolbelt';

import tagsToModifier, { tableAttributesToProps } from './tagsMap';

const { logger } = contextualConfig.application;

const log = logger.extend('html-parser');

const TABLE_TAGS = ['table', 'tr', 'td', 'th', 'thead', 'tbody'];
const MODIFIERS_TO_AVOID_IN_A = [
  'body1',
  'body2',
  'caption1',
  'caption2',
  'span',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
];
const generateModifiers = (
  modifiers: U.Nullable<string> = '',
  componentModifier: string,
): string => `${modifiers}, ${componentModifier}`;

const Render: React.FC = ({ children }: React.PropsWithChildren<any>) => (
  <>{children}</>
);

const HtmlParser = (
  html: string,
  Component: U.Nullable<ComponentType<unknown> | string>,
  modifiers: U.Nullable<string>,
  TypographyComponent: ComponentType<unknown>,
  customStyle?: Record<string, any>,
): U.Nullable<ReactElement[]> => {
  try {
    if (!isString(html) || isEmpty(html)) {
      return null;
    }
    const RenderComponent = Component || Render;
    // @ts-ignore
    const transform: Transform = node => {
      let children = null;
      const props = { ...node.attribs };
      if (props) {
        if (props.style && !includes(TABLE_TAGS, node.name)) delete props.style;
        if (props.class) delete props.class;
      }
      if (node.children) {
        // @ts-ignore
        children = processNodes(node.children, transform);
      }
      if (node.type === 'tag' && node.name === 'img') {
        return React.createElement('img', { ...node.attribs });
      }
      if (node.type === 'tag' && node.name === 'ul') {
        return React.createElement(
          'ul',
          { key: uniqueId(), style: customStyle },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'ol') {
        return React.createElement(
          'ol',
          { key: uniqueId(), style: customStyle },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'li') {
        return React.createElement(
          'li',
          { key: uniqueId(), style: customStyle },
          children,
        );
      }
      if (
        node.type === 'tag' &&
        (node.name === 'strong' || node.name === 'b')
      ) {
        return React.createElement(
          node.name,
          { key: uniqueId(), style: customStyle },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'u') {
        return React.createElement(
          'u',
          { key: uniqueId(), style: customStyle },
          children,
        );
      }
      if (node.type === 'tag' && includes(TABLE_TAGS, node.name)) {
        tableAttributesToProps(props);
        return React.createElement(
          node.name,
          { key: uniqueId(), style: customStyle, ...props },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'a') {
        const customModifiers = without(
          generateModifiers(modifiers, 'link'),
          ...MODIFIERS_TO_AVOID_IN_A,
        );
        const { onclick } = node.attribs;
        if (!isEmpty(onclick)) {
          const linkRegex = /'(.+)'/;
          const url = linkRegex.exec(onclick);
          return React.createElement(
            TypographyComponent,
            {
              key: uniqueId(),
              // @ts-ignore
              href: url[1],
              target: '_blank',
              modifiers: customModifiers,
              style: customStyle,
            },
            children,
          );
        }
        return React.createElement(
          TypographyComponent,
          {
            key: uniqueId(),
            style: customStyle,
            modifiers: customModifiers,
            ...props,
          },
          children,
        );
      }
      if (node.type === 'tag' && node.name !== 'br' && node.name !== 'a') {
        const componentModifier = tagsToModifier[node?.name] || 'body2';
        const customModifiers = generateModifiers(modifiers, componentModifier);
        if (['html', 'body', 'head'].indexOf(node.name) > -1) {
          return React.createElement(Fragment, { key: uniqueId() }, children);
        }
        return React.createElement(
          RenderComponent,
          {
            key: uniqueId(),
            variant: customModifiers,
            modifiers: customModifiers,
            style: {
              ...props.style,
              ...customStyle,
            },
            ...props,
          },
          children,
        );
      }
      if (node.type === 'text') {
        const customModifiers = generateModifiers(modifiers, '');
        return React.createElement(
          RenderComponent,
          {
            key: uniqueId(),
            modifiers: customModifiers,
            variant: node?.parent?.name,
            style: {
              ...props.style,
              ...customStyle,
            },
            ...props,
          },
          node.data,
        );
      }
      if (node.type === 'tag' && node.name === 'br') {
        return React.createElement('br', {
          key: uniqueId(),
          style: customStyle,
        });
      }
      if (node.type === 'tag' && node.name === 'hr') {
        return React.createElement('hr', {
          key: uniqueId(),
          style: { color: '#98a8b7' },
        });
      }
      return undefined;
    };
    const options = {
      transform,
    };
    return ReactHtmlParser(html, options);
  } catch (error) {
    log.error('Error during conversion of question html=', html, error);
    return null;
  }
};

export default HtmlParser;
