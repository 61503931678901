const namespace = 'consumerSearch';
const advancedSearchNamespace = `${namespace}.advancedSearch`;

const SEARCH_BY_LAST_NAME = {
  key: `${namespace}.searchByLastName`,
  message: 'Search by Last Name',
};

const ADVANCED_SEARCH = {
  TITLE: {
    key: `${advancedSearchNamespace}.modalTitle`,
    message: 'Consumer search',
  },
  DESCRIPTION: {
    key: `${advancedSearchNamespace}.modalDescription`,
    message: 'Here you can search for already registered consumers.',
  },
  SEARCH_RESULT: {
    key: `${advancedSearchNamespace}.searchResult`,
    message: 'Search result',
  },
  CONSUMERS_NOT_FOUND: {
    key: `${advancedSearchNamespace}.notFound`,
    message: 'No consumers found',
  },
};

const I18N = {
  ADVANCED_SEARCH,
  SEARCH_BY_LAST_NAME,
};

export default I18N;
