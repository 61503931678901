import contextualConfig from '@mmw/contextual-config';
import { isDevelopment } from '@mmw/environment';
import ReactGA from 'react-ga4';

function initialize() {
  const trackingID = contextualConfig?.googleAnalytics?.trackingID;
  if (!trackingID) {
    return;
  }
  ReactGA.initialize(trackingID, { testMode: isDevelopment() });
}

export default initialize;
