import { REGISTRATION as FIELDS } from '@mmw/constants-fields-names';
// import {
//   REGISTRATION as VALIDATIONS,
// } from '@mmw/constants-fields-validations';
import {
  AVAILABLE,
  INVALID,
  REQUIRED,
  // MAX_LENGTH,
} from '@mmw/constants-validation-names';

const namespace = 'validation';

const REGISTRATION = {
  PURCHASE_DATE: {},
  REGISTRATION_TYPE: {
    INVALID: {
      key: `${namespace}.${FIELDS.REGISTRATION_TYPE}.${INVALID}`,
      message: 'Registration Type must be valid',
    },
  },
  STORE_ID: {
    REQUIRED: {
      key: `${namespace}.${FIELDS.STORE_ID}.${REQUIRED}`,
      message: 'Store is required',
    },
    AVAILABLE: {
      key: `${namespace}.${FIELDS.STORE_ID}.${AVAILABLE}`,
      message: 'Selected store must be available to logged trader',
    },
  },
  SALES_TYPE: {
    INVALID: {
      key: `${namespace}.${FIELDS.SALES_TYPE}.${INVALID}`,
      message: 'Sales Type must be valid',
    },
  },
  INVOICE_NUMBER: {},
  EXTERNAL_ORDERNUMBER: {},
  PRODUCT_SELECTION: {},
  CONSUMER: {
    EMAIL: {
      REQUIRED: {
        key: `${namespace}.consumerEmail.${REQUIRED}`,
        message: 'The e-mail of your customer is required',
      },
    },
  },
};

export default REGISTRATION;
