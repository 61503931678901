const namespace = 'dashboard';
const highlightsNamespace = 'highlights';
const newRegistrationNamespace = `${namespace}.newRegistrationCard`;
const registrationNameNamespace = `${newRegistrationNamespace}.registrationName`;
const lastProjectsNamespace = `${namespace}.lastProjects`;
const newsNamespace = `${namespace}.news`;
const viewModeNamespace = `${namespace}.viewMode`;

const NEW_PROJECT_CARD = {
  TITLE: {
    key: `${newRegistrationNamespace}.title`,
    message: 'New Registration',
  },
  DESCRIPTION: {
    key: `${newRegistrationNamespace}.description`,
    message: 'Please enter the name of your project',
  },
  INPUT: {
    LABEL: {
      key: `${registrationNameNamespace}.label`,
      message: 'Project name',
    },
    PLACEHOLDER: {
      key: `${registrationNameNamespace}.placeholder`,
      message: 'Name your project',
    },
  },
  BUTTON: {
    key: `${newRegistrationNamespace}.createRegistration`,
    message: 'Create registration',
  },
};

export const ADMIN_DASHBOARD_HIGHLIGHTS = {
  WELCOME_TO_REC_PRO_PORTAL_ADMIN: {
    key: `${namespace}.welcomeToRecProPortalAdmin`,
    message: 'Welcome to REC ProPortal 2.0 Admin',
  },
  WELCOME_TO_REC_PRO_PORTAL_ADMIN_DESCRIPTION: {
    key: `${namespace}.welcomeToRecProPortalAdminDescription`,
    message:
      'With the ProPortal Admin Panel you can easily browse and edit all your certified installers and their registered REC installations. ',
  },
};

const LAST_PROJECTS = {
  TITLE: {
    key: `${lastProjectsNamespace}.title`,
    message: 'Your last registrations',
  },
  LAST_REGISTRATIONS: {
    key: `${lastProjectsNamespace}.lastRegistrations`,
    message: 'Last registrations',
  },
  ALL_PROJECTS: {
    key: `${lastProjectsNamespace}.allProjects`,
    message: 'All registrations',
  },
  PROJECT_ID: {
    key: `${lastProjectsNamespace}.projectId`,
    message: 'Registration id',
  },
  CONSUMER: {
    key: `${lastProjectsNamespace}.consumer`,
    message: 'Consumer',
  },
  TYPE: {
    key: `${lastProjectsNamespace}.type`,
    message: 'Type',
  },
  SYSTEM_SIZE: {
    key: `${lastProjectsNamespace}.systemSize`,
    message: 'System size',
  },
  STATUS: {
    key: `${lastProjectsNamespace}.status`,
    message: 'Status',
  },
  CONCEPT: {
    key: `${lastProjectsNamespace}.concept`,
    message: 'Concept',
  },
  INVALID: {
    key: `${lastProjectsNamespace}.invalid`,
    message: 'Invalid',
  },
  REGISTERED: {
    key: `${lastProjectsNamespace}.registered`,
    message: 'Registered',
  },
};

const NEWS_DATA = {
  TITLE: {
    key: `${newsNamespace}.title`,
    message: 'News',
  },
  READ_MORE: {
    key: `${newsNamespace}.readMore`,
    message: 'Read More',
  },
};
const VIEW_MODE = {
  LIST: {
    key: `${viewModeNamespace}.list`,
    message: 'list view',
  },
  MAP: {
    key: `${viewModeNamespace}.map`,
    message: 'map view',
  },
};

const HIGHLIGHTS = {
  // RECADMIN: {
  //   TITLE: {
  //     key: `${namespace}.${highlightsNamespace}.recAdminTitle`,
  //     message: 'Welcome to ProPortal 2.0 for Administrators',
  //   },
  //   CONTENT: {
  //     key: `${namespace}.${highlightsNamespace}.recAdminContent`,
  //     message:
  //       'With the ProPortal Admin Panel you can easily browse and edit all your certified installers and their registered REC installations.',
  //   },
  // },
  PRO_PORTAL: {
    TITLE: {
      key: `${namespace}.${highlightsNamespace}.recProportalTitle`,
      message: 'Welcome to ProPortal',
    },
    CONTENT: {
      key: `${namespace}.${highlightsNamespace}.recProportalContent`,
      message:
        'With the ProPortal you can easily register your REC installations and issue the REC ProTrust Warranty Certificate. Keep your company data updated to appear in our Installer Locater. Use powerful marketing tools to boost your sales.',
    },
  },
  REC_SELECT: {
    TITLE: {
      key: `${namespace}.${highlightsNamespace}.recSelectTitle`,
      message:
        'REC SELECT Program Register your installations and earn RECoins.',
    },
    CONTENT: {
      key: `${namespace}.${highlightsNamespace}.recSelectContent`,
      message:
        'Use your RECoins for benefits offered in your region. Eligible for REC Certified Solar Professional installers. ',
    },
  },
  SUNSNAP: {
    TITLE: {
      key: `${namespace}.${highlightsNamespace}.recSunsnapTitle`,
      message: 'REC SUNSNAP A must-have app for installers',
    },
    CONTENT: {
      key: `${namespace}.${highlightsNamespace}.recSunsnapContent`,
      message:
        'Use SunSnap to register your installation simply by scanning or keying in the barcode. Get instantly your REC ProTrust Warranty Certificate.',
    },
  },
  PROPAGE: {
    TITLE: {
      key: `${namespace}.${highlightsNamespace}.recPropageTitle`,
      message: 'REC PROPAGE Create your REC website in a few clicks!',
    },
    CONTENT: {
      key: `${namespace}.${highlightsNamespace}.recPropageContent`,
      message:
        'Customize and share your own dynamic website with content provided and maintained by REC. It’s easy, fast and free!',
    },
  },
};

const I18N = {
  NEW_PROJECT_CARD,
  LAST_PROJECTS,
  NEWS_DATA,
  VIEW_MODE,
  ADMIN_DASHBOARD_HIGHLIGHTS,
  HIGHLIGHTS,
};

export default I18N;
