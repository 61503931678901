import defaultProps from '@ui-system/default-props/skeleton';
import { SkeletonProps, SkeletonType } from '@ui-system/interfaces-skeleton';
import React from 'react';
import SkeletonLib, { SkeletonTheme } from 'react-loading-skeleton';

const Skeleton: SkeletonType = ({
  color,
  highlightColor,
  ...props
}: SkeletonProps) => (
  <SkeletonTheme color={color} highlightColor={highlightColor}>
    <SkeletonLib {...props} />
  </SkeletonTheme>
);

Skeleton.defaultProps = defaultProps;

export default Skeleton;
