import { deepmerge } from '@shared-utils/object';
import { FeatureThemeProvider } from '@ui-system/css';
import { Components } from '@ui-system/interfaces';
import { Theme, useTheme } from '@ui-system/theme';
import * as React from 'react';
import { useMemo } from 'react';

import {
  RootComponentsContext,
  useFeatureComponentsProviderValue,
} from './context';

interface Props extends React.PropsWithChildren<any> {
  components: Partial<Components>;
  theme?: Partial<Theme>;
}

export const FeatureComponentsProvider: React.FC<Props> = ({
  components,
  theme,
  children,
}: Props) => {
  const value = useFeatureComponentsProviderValue(components);
  const { ThemeProvider } = value.components;
  const rootTheme = useTheme();

  const mergedTheme = useMemo(
    () => deepmerge(rootTheme, theme),
    [rootTheme, theme],
  );
  return (
    <ThemeProvider theme={mergedTheme}>
      <FeatureThemeProvider theme={mergedTheme}>
        <RootComponentsContext.Provider value={value}>
          {children}
        </RootComponentsContext.Provider>
      </FeatureThemeProvider>
    </ThemeProvider>
  );
};

FeatureComponentsProvider.defaultProps = {
  theme: {},
};
