// import createStore from '@mmw/redux-dynamic-store';
import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { routerModule } from '@mmw/ui-web-libraries-react-router/module';
import { configureStore } from '@reduxjs/toolkit';
import { enableBatching } from 'redux-batched-actions';
import { createEpicMiddleware } from 'redux-observable';

import rootEpic from './rootEpic';
import rootReducer from './rootReducer';

const epicMiddleware = createEpicMiddleware();
const routerMiddlewares = routerModule.middlewares || EMPTY_ARRAY;

const store = configureStore({
  reducer: enableBatching(rootReducer),
  middleware: [epicMiddleware, ...routerMiddlewares],
});

epicMiddleware.run(rootEpic);

export default store;
