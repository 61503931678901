const namespace = 'termsPopUp';

const i18n = {
  TITLE: {
    key: `${namespace}.termsPopUpTitle`,
    message: 'Terms and Conditions',
  },
  TEXT: {
    key: `${namespace}.termsPopUpText`,
    message: 'We updated our Terms and Conditions, please read;',
  },
  BUTTON: {
    key: `${namespace}.termsPopUpButton`,
    message: 'Acknowledge',
  },
};

export default i18n;
