import { Hex } from '@ui-system/color';
import { Color } from '@ui-system/css/utils/types';
import { U } from '@utils/ts';
import get from 'lodash/get';

import { ColorMode } from './interfaces';

export function extractColorByName(
  color: U.Nullable<Color>,
  colors: ColorMode,
): Hex {
  let finalColor = color;
  if (color === 'primary' || color === 'secondary') {
    finalColor = `${color}.main` as Color;
  }
  return get(colors, finalColor as string) as Hex;
}
