const namespace = 'shippingAddress';
const modalNamespace = `${namespace}.modal`;

const MODAL = {
  TITLE: {
    EDIT: {
      key: `${modalNamespace}.editTitle`,
      message: 'Edit delivery address',
    },
    NEW: {
      key: `${modalNamespace}.newTitle`,
      message: 'Add delivery address',
    },
  },
  DESCRIPTION: {
    EDIT: {
      key: `${modalNamespace}.editDescription`,
      message: 'Edit the shipping address for this customer.',
    },
    NEW: {
      key: `${modalNamespace}.editDescription`,
      message: 'Associate a new shipping address for this customer.',
    },
  },
};

const I18N = {
  MODAL,
};

export default I18N;
