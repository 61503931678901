import { createDI } from '@di/core';
import defaultApiV2 from '@mmw/api-v2';
import { TraderJSON } from '@mmw/services-core-store-locator/types';
import {
  createAsyncStoreHooks,
  createAsyncStoreModule,
  createStoreModule,
} from '@zustand-store/core';
import qs from 'qs';
import { U } from 'ts-toolbelt';

import logger from './log';
import {
  searchCoordinatesSelector,
  searchRadiusSelector,
  storesListSelector,
  totalStoresListSelector,
} from './stateSelectors';
import {
  ContainerRquestParamsType,
  Coordinates,
  SearchRequestObject,
  StoresLocationSearchResult,
  TraderSearchSortOrder,
} from './types';

const BASE_PATH = 'v1/consumer/trader';

const DEFAULT_SEARCH_REQUEST = {
  sortOrder: TraderSearchSortOrder.DISTANCE,
  offset: 0,
  // will keep this in 100, maybe we need to implement a paginator later to handle more
  limit: 100,
  returnsDetails: true,
  returnsErpnumber: true,
  returnsDistance: true,
};

class StoreState {
  selectedStore: U.Nullable<TraderJSON> = null;

  alreadySearch: U.Nullable<boolean> = false;

  lastSearchByUserLocation: U.Nullable<boolean> = false;
}

export const useStoreState = createStoreModule({
  name: 'store-locator',
  initialState: new StoreState(),
  disablePersist: true,
});

export function setSelectedStore(store: U.Nullable<TraderJSON>) {
  useStoreState.setState({ selectedStore: store });
}

export function setAlreadySearch(alreadySearch: boolean) {
  useStoreState.setState({ alreadySearch });
}

export function setLastSearchByUserLocation(lastSearchByUserLocation: boolean) {
  useStoreState.setState({ lastSearchByUserLocation });
}

export function useSelectedStore() {
  return useStoreState(state => state.selectedStore);
}

export function useAlreadySearch() {
  return useStoreState(state => state.alreadySearch);
}

export function useLastSearchByUserLocation() {
  return useStoreState(state => state.lastSearchByUserLocation);
}

export const [getStoresSearchParams, setParams] = createDI<
  Partial<SearchRequestObject>
>('STORES_LOCATION_SEARCH');

export function setStoresSearchParams(
  customParams: Partial<SearchRequestObject>,
) {
  setParams({ ...DEFAULT_SEARCH_REQUEST, ...customParams });
}

function getStoreLocatorSearchPath(
  requestParams: Partial<SearchRequestObject>,
) {
  return `${BASE_PATH}?${qs.stringify(requestParams)}`;
}

function createStoreLocatorState(requestParams: ContainerRquestParamsType) {
  async function apiCall({
    language,
    lat,
    lng,
    radius,
    country,
    ...rest
  }): Promise<StoresLocationSearchResult> {
    try {
      logger.debug(
        `Trying to search trader location by latitude=${lat} and longitude=${lng}`,
      );

      const response = await defaultApiV2.get(
        getStoreLocatorSearchPath({
          language,
          lat,
          lng,
          radius,
          country,
          ...requestParams,
          ...rest,
        }),
      );
      const { data } = response;
      logger.info('Successfully found trader location');
      return data;
    } catch (error) {
      logger.error('Error when searching trader location, error=%O', error);
      throw error;
    }
  }

  const useStoresByLocationSearch = createAsyncStoreModule({
    asyncFunction: apiCall,
    name: 'store-location-search',
    disablePersist: true,
  });

  const {
    useLoading: useIsLoadingStoresByLocationSearch,
    useError: useStoresByLocationSearchError,
    useFetch: useSearchStoresByLocation,
    useReset: useResetStoresByLocationSearch,
  } = createAsyncStoreHooks<
    StoresLocationSearchResult,
    Partial<SearchRequestObject>
  >(useStoresByLocationSearch, {
    requirePayloadOnMount: true,
  });

  const useStoresByLocationList = (): U.Nullable<TraderJSON[]> =>
    useStoresByLocationSearch(storesListSelector);

  const useTotalStoresListByLocation = (): U.Nullable<number> =>
    useStoresByLocationSearch(totalStoresListSelector);

  const useOriginCoordinates = (): U.Nullable<Coordinates> => {
    const coordinates = useStoresByLocationSearch(searchCoordinatesSelector);
    if (coordinates?.lat === 0 || coordinates?.lng === 0) return null;
    return coordinates;
  };

  const useSearchRquestRadius = (): U.Nullable<number> =>
    useStoresByLocationSearch(searchRadiusSelector);

  return {
    useIsLoadingStoresByLocationSearch,
    useStoresByLocationSearchError,
    useSearchStoresByLocation,
    useResetStoresByLocationSearch,
    useStoresByLocationList,
    useTotalStoresListByLocation,
    useOriginCoordinates,
    useSearchRquestRadius,
    useStoresByLocationSearch,
  };
}

export default createStoreLocatorState;
