const namespace = 'consumerMaxDistance';

const CONSUMER_DISTANCE_CAMPAIGN_SWITCH = {
  TITLE: {
    key: `${namespace}Title`,
    message:
      'Bitte bestätigen Sie, dass es sich tatsächlich um einen Verkauf gehandelt hat',
  },
  CONTENT: {
    key: `${namespace}Content`,
    message:
      'Sie haben einen Verkauf im stationären Ladengeschäft vorgenommen, jedoch weicht die Postleitzahl des Endkunden wesentlich von der Ihres Ladengeschäftes ab. Bitte bestätigen Sie, dass es sich tatsächlich um einen stationären Verkauf gehandelt hat. Alternativ können Sie auch eine Online-Registrierung vornehmen. Bitte beachten Sie in diesem Fall, dass die Online Beratung gleichwertig zur stationären Beratung sein muss, d. h. eine Beratung per Telefon entspricht nicht diesen Qualitätsansprüchen! BSH behält sich das Recht vor durch Testkäufe die ordnungsgemäße Durchführung zu überwachen',
  },
  DENY_ACTION: {
    key: `${namespace}.denyAction`,
    message: 'Nein, leiten Sie mich zur Online-Kampagne weiter',
  },
};

const I18N = {
  CONSUMER_DISTANCE_CAMPAIGN_SWITCH,
};

export default I18N;
