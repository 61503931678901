import { assign, forEach, includes, isNumber, keys } from 'lodash';

const styleCustomTagsMap: Record<string, string> = {
  valign: 'vAlign',
  bgcolor: 'backgroundColor',
};

const unknownTagsMap: Record<string, string> = {
  cellpadding: 'cellPadding',
  cellspacing: 'cellSpacing',
};

const validTags = ['width', 'height', 'bgColor'];

export function tableAttributesToProps(attrs) {
  const propsKeys = keys(attrs);
  const invalidTableTags = keys(unknownTagsMap);
  const invalidStyleTags = keys(styleCustomTagsMap);
  const newProps = attrs;
  forEach(propsKeys, item => {
    if (includes(invalidStyleTags, item)) {
      const newKey = styleCustomTagsMap[item];
      assign(newProps, {
        style: {
          ...newProps.style,
          [newKey]: newProps[item],
        },
      });
      delete newProps[item];
    }
    if (includes(invalidTableTags, item)) {
      const newKey = unknownTagsMap[item];
      newProps[newKey] = newProps[item];
    }
    if (includes(validTags, item)) {
      if (newProps && isNumber(Number(newProps[item]))) {
        assign(newProps, {
          style: { ...newProps.style, [item]: `${newProps[item]}px` },
        });
        delete newProps[item];
      }
      return;
    }
    delete newProps[item];
  });
  return newProps;
}

const tagsToModifierMap: Record<string, string> = {
  p: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  span: 'span',
  a: 'a',
  text: '',
};

export default tagsToModifierMap;
