import { SalesOrgBrandJSON } from '@mmw/services-core-user-profile';

import {
  GET_SALES_ORG_BRANDS_LIST_ERROR,
  GET_SALES_ORG_BRANDS_LIST_START,
  GET_SALES_ORG_BRANDS_LIST_SUCCESS,
  GetSalesOrgBrandsListErrorAction,
  GetSalesOrgBrandsListStartAction,
  GetSalesOrgBrandsListSuccessAction,
} from './types';

export const getSalesOrgBrandsListStartAction =
  (): GetSalesOrgBrandsListStartAction => ({
    type: GET_SALES_ORG_BRANDS_LIST_START,
  });

export const getSalesOrgBrandsListSuccessAction = (
  list: SalesOrgBrandJSON[],
): GetSalesOrgBrandsListSuccessAction => ({
  type: GET_SALES_ORG_BRANDS_LIST_SUCCESS,

  payload: {
    list,
  },
});

export const getSalesOrgBrandsListErrorAction = (
  error: Error,
): GetSalesOrgBrandsListErrorAction => ({
  type: GET_SALES_ORG_BRANDS_LIST_ERROR,

  payload: {
    error,
  },
});

const initialActions = [
  // XXX: in theory not needed
  // getSalesOrgBrandsListStartAction(),
];

export default initialActions;
