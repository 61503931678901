import { createDI } from '@di/core';
import defaultApiV2 from '@mmw/api-v2';
import logger from '@mmw/logging-logger';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';
import { AxiosResponse } from 'axios';

import {
  GetManuAgreementSavePath,
  GetManuAgreementSearchPath,
} from './apiPaths';

type Api = typeof defaultApiV2;

type ManuAgreementServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

// Interfaces
interface OrgunitAgreementRequestObject {
  campaignID?: number | null;
  type?: Agreement | null;
  orgunitID?: number | null;
  salesOrgBrandID?: number | null;
  offerorID?: number | null;
  offerorIDs?: number[] | null;
  orgunitAgreementID?: number | null;
}

interface OrgunitAgreement {
  id: string | null;
  creationDate: string;
  acceptanceDate: string;
  acceptedBy: string;
  agreement: Agreement;
  agreementCode: string;
  approvalDate: string;
  approvedBy: string;
  authorisedSignatory: string;
  cancellationDate: string;
  cancelledBy: string;
  confirmationDate: string;
  confirmedBy: string;
  contractStatus: string;
  orgunit: Orgunit;
  expirationDate: string;
  renewalDate: string;
}

interface Orgunit {
  id: string;
  // Add other properties as needed
}

interface Agreement {
  id: number;
  orgunitId: number;
  campaignId: number;
  type: number;
  typeLabel: string;
  questionGroupType: any; // Replace 'any' with the appropriate type if available
  configurationId: number;
  agreementConfigurationKeys: any[]; // Replace 'any' with the appropriate type if available
  acontract: boolean;
}

interface OrgunitAgreementJSON {
  id: number;
  creationDate: Date | null;
  acceptanceDate: Date | null;
  confirmationDate: Date | null;
  approvalDate: Date | null;
  cancellationDate: Date | null;
  renewalDate: Date | null;
  expirationDate: Date | null;
  agreementCode: any; // Replace 'any' with the appropriate type if available
  contractStatus: any; // Replace 'any' with the appropriate type if available
  acceptedBy: number;
  confirmedBy: number | null;
  approvedBy: number | null;
  cancelledBy: number | null;
  authorisedSignatory: any; // Replace 'any' with the appropriate type if available
  agreement: Agreement;
  orgunitID: number;
}

class ManuAgreementService {
  api: Api;

  authenticationService: AuthenticationService;

  static createDI = createDI<ManuAgreementService>;

  constructor({ apiv2, authenticationService }: ManuAgreementServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  // Function to search orgunit agreements
  async searchOrgunitAgreements(
    req: OrgunitAgreementRequestObject,
    language: string,
  ): Promise<OrgunitAgreementJSON[]> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<OrgunitAgreementJSON[]> =
        await this.api.post(GetManuAgreementSearchPath(), req, {
          headers,
          params: { language },
        });
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  // Function to save orgunit agreement
  async saveOrgunitAgreement(
    json: OrgunitAgreementJSON,
    language: string,
  ): Promise<OrgunitAgreementJSON> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<OrgunitAgreementJSON> = await this.api.post(
        GetManuAgreementSavePath(),
        json,
        {
          headers,
          params: { language },
        },
      );
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }
}

export default ManuAgreementService;
