import { Style } from '@ui-system/interfaces/types';
import { ResizeMode } from '@ui-system/interfaces-image';
import { nanoid } from 'nanoid';
import React from 'react';
import { ReactPlayerProps } from 'react-player';

export type VideoProps = {
  src: string | undefined;
  preview?: string;
  style?: Style;
  height: number | string;
  width: number | string;
  resizeMode?: ResizeMode;
  ignorePxPerfect?: boolean;
  controls?: boolean;
  noCache?: boolean;
  getVideoSize?: (w: number, h: number) => void;
  children?: React.ReactNode;
} & ReactPlayerProps;

export type NativeVideoProps = {
  // placeholderStyle?: Record<string, any>;
  // PlaceholderContent?: React.ReactElement;
  containerStyle?: Record<string, any>;
  children?: React.ReactNode;
  transition?: boolean;
  onLoad?: (e: any) => void;
} & Partial<VideoProps>;

export type VideoType = React.FC<VideoProps>;

export const VIDEO_DEFAULT_PROPS: Partial<VideoProps> = {
  height: 100,
  width: 100,
  resizeMode: 'contain',
  ignorePxPerfect: false,
  noCache: false,
};

export const VIDEO_UUID = nanoid();
