import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';

import createFromProps from '../create-from-props';
import { Direction, FromProps } from '../types';

const applyDirection: F.Function<[Theme, Direction]> & FromProps = (
  theme,
  flexDirection: Direction,
) => {
  if (!flexDirection) return '';
  return `flex-direction: ${flexDirection}`;
};
applyDirection.fromProps = createFromProps(applyDirection, 'flexDirection');

export default applyDirection;
