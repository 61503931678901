import { Country } from '@mmw/services-core-common/types';

import {
  GET_AVAILABLE_COUNTRIES_ERROR,
  GET_AVAILABLE_COUNTRIES_START,
  GET_AVAILABLE_COUNTRIES_SUCCESS,
  GetAvailableCountriesErrorAction,
  GetAvailableCountriesStartAction,
  GetAvailableCountriesSuccessAction,
} from './types';

export const getAvailableCountriesStartAction =
  (): GetAvailableCountriesStartAction => ({
    type: GET_AVAILABLE_COUNTRIES_START,
  });

type GetAvailableCountriesSuccess = (
  availableCountries: Array<Country>,
) => GetAvailableCountriesSuccessAction;

export const getAvailableCountriesSuccessAction: GetAvailableCountriesSuccess =
  availableCountries => ({
    type: GET_AVAILABLE_COUNTRIES_SUCCESS,
    payload: {
      availableCountries,
    },
  });

export const getAvailableCountriesErrorAction = (
  error: Error,
): GetAvailableCountriesErrorAction => ({
  type: GET_AVAILABLE_COUNTRIES_ERROR,

  payload: {
    error,
  },
});

const initialActions = [getAvailableCountriesStartAction()];

export default initialActions;
