import IMAGE_RANKING from '@image-ranking/i18n';

import AUTH from './auth';
import DASHBOARD from './dashboard';
import DISTRIBUTOR from './distributor';
import EMEA_LANDING_PAGE from './emea-landing-page';
import EMEA_RANKING_PAGE from './emea-ranking-page';
import ENROLLMENT from './enrollment';
import EXCELLENCE_AWARDS from './excellence-awards';
import HELP from './help';
import REC_PRO_PORTAL_HOME_PAGE from './home';
import MARKETING_TOOLS from './marketing-tools';
import MASS_PROJECT_UPLOAD from './mass-project-upload';
import MASS_PROJECT_UPLOAD_AMER from './mass-project-upload-amer';
import MODULE_REGISTRATIONS from './module-registration';
import MY_COMPANY from './my-company';
import PROJECT from './project';
import PROJECTS_LIST from './projects-list';
import REC_SELECT_PARTNER_PROGRAM from './recSelectPartnerProgram';
import UPLOAD_PANELS_SPREADSHEET_MODAL from './upload-spreadsheet-modal';

const I18N = {
  REC_PRO_PORTAL_HOME_PAGE,
  IMAGE_RANKING,
  DASHBOARD,
  PROJECT,
  PROJECTS_LIST,
  DISTRIBUTOR,
  HELP,
  MASS_PROJECT_UPLOAD,
  MASS_PROJECT_UPLOAD_AMER,
  EMEA_LANDING_PAGE,
  EMEA_RANKING_PAGE,
  EXCELLENCE_AWARDS,
  UPLOAD_PANELS_SPREADSHEET_MODAL,
  REC_SELECT_PARTNER_PROGRAM,
  MODULE_REGISTRATIONS,
  MY_COMPANY,
  MARKETING_TOOLS,
  AUTH,
  ENROLLMENT,
};

export {
  AUTH,
  DASHBOARD,
  I18N as default,
  DISTRIBUTOR,
  EMEA_LANDING_PAGE,
  EMEA_RANKING_PAGE,
  ENROLLMENT,
  EXCELLENCE_AWARDS,
  HELP,
  IMAGE_RANKING,
  MARKETING_TOOLS,
  MASS_PROJECT_UPLOAD,
  MASS_PROJECT_UPLOAD_AMER,
  MODULE_REGISTRATIONS,
  MY_COMPANY,
  PROJECT,
  PROJECTS_LIST,
  REC_PRO_PORTAL_HOME_PAGE,
  REC_SELECT_PARTNER_PROGRAM,
  UPLOAD_PANELS_SPREADSHEET_MODAL,
};
