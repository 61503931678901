import {
  createTheme as createMuiTheme,
  Theme as MuiTheme,
} from '@material-ui/core/styles';
import { Theme } from '@ui-system/theme/interfaces';
import isArray from 'lodash/isArray';
import join from 'lodash/join';

// Every library must provide a fn to take our Theme and create theirs.
export type MaterialUiTheme = MuiTheme;

export default function createTheme(theme: Theme): MuiTheme {
  // TODO map theme colors.
  return createMuiTheme({
    palette: {
      primary: theme.colors[theme.mode].primary,
      secondary: theme.colors[theme.mode].secondary,
      error: {
        main: theme.colors[theme.mode].error,
      },
      success: {
        main: theme.colors[theme.mode].success,
      },
      info: {
        main: theme.colors[theme.mode].info,
      },
      warning: {
        main: theme.colors[theme.mode].warning,
      },
      text: {
        primary: theme.colors[theme.mode].black,
      },
    },
    typography: {
      fontFamily: !isArray(theme.typography.fontFamily.regular)
        ? theme.typography.fontFamily.regular
        : join(theme.typography.fontFamily.regular, ', '),
    },
  });
}
