const namespace = 'myCompany';

const I18N = {
  MY_COMPANY: {
    key: `${namespace}`,
    message: 'My Company',
  },
  FILL_TAXNUMBER_TITLE: {
    key: `${namespace}.fillTaxnumberTitle`,
    message: 'Australian Business Number (ABN)',
  },
  FILL_TAXNUMBER_CONTENT: {
    key: `${namespace}.fillTaxnumberContent`,
    message: 'Please fill the missing information on your company data',
  },
};

export default I18N;
