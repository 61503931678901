import forEach from 'lodash/forEach';

export async function eachAsync<Item>(
  arr: Item[],
  asyncFn: (item: Item, i: number, array: Item[]) => Promise<void>,
): Promise<void> {
  const promises: Promise<void>[] = [];
  forEach(arr, (item, i) => {
    promises.push(asyncFn(item, i, arr));
  });
  await Promise.all(promises);
}
