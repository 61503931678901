// import MomentUtils from '@date-io/moment';
import DayjsUtils from '@date-io/dayjs';
import {
  MuiPickersUtilsProvider,
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps,
} from '@material-ui/pickers';
import React from 'react';
import { useIntl } from 'react-intl';

interface MaterialPickersProviderProps {
  children?: React.ReactNode | React.ReactNodeArray;
}

export interface TimePickerProps extends MuiTimePickerProps {
  value: Date;
  onChange: (date: Date) => void;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  value,
  onChange,
  ...props
}: TimePickerProps) => (
  <MuiTimePicker value={value} onChange={onChange} {...props} />
);

export const MaterialPickersProvider: React.FC<
  MaterialPickersProviderProps
> = ({ children }: MaterialPickersProviderProps) => {
  // const language = useLanguage();
  // const locale = useLocalesMap(language);
  const intl = useIntl();
  return (
    <MuiPickersUtilsProvider
      // libInstance={moment}
      utils={DayjsUtils}
      locale={intl.locale}
    >
      {children}
    </MuiPickersUtilsProvider>
  );
};
