import ofType from '@mmw/redux-rx-of-type-operator';
import { ActionsObservable } from 'redux-observable';
import { EMPTY, from, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { FormId } from '../../types';
import { setFieldErrorsAction } from '../actions';
import { allFormsSelector } from '../stateSelector';
import {
  AllForms,
  RootState,
  UPDATE_FIELD_VALUE,
  UpdateFieldValueAction,
} from '../types';

type PipeData = [UpdateFieldValueAction, AllForms];

// https://github.com/jquense/yup#mixedvalidatevalue-any-options-object-promiseany-validationerror
const YUP_OPTIONS = { abortEarly: false };

const validateAllChanges = async (formId: FormId, allForms: AllForms) => {
  const form = allForms[formId];
  if (!form || !form.validationSchema) {
    return null;
  }
  const { validationSchema, currentValues } = form;
  try {
    await validationSchema.validate(currentValues, YUP_OPTIONS);
    return null;
  } catch (error) {
    return error.inner;
  }
};

const onUpdateFieldValidate = (
  action$: ActionsObservable<UpdateFieldValueAction>,
  state$: Observable<RootState>,
): Observable<any> =>
  action$.pipe(
    ofType(UPDATE_FIELD_VALUE),
    debounceTime(50),
    withLatestFrom(state$.pipe(map(allFormsSelector))),
    switchMap(
      ([
        {
          payload: { formId },
        },
        allForms,
      ]: PipeData) =>
        from(validateAllChanges(formId, allForms)).pipe(
          map(error => setFieldErrorsAction(formId, error)),
          catchError(() => of(EMPTY)),
        ),
    ),
  );

export default onUpdateFieldValidate;
