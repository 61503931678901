import { useDayOfTheWeekTranslation } from '@dates/core';
import { STORE as I18N } from '@mmw/constants-i18n-fields';
import {
  OpeningTimesJSON,
  TraderJSON,
} from '@mmw/services-core-store-locator/types';
import UI from '@ui-system/ui';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useMemo } from 'react';
import { U } from 'ts-toolbelt';

interface DayOfWeekProps {
  dayOfWeek: number;
}

const DayOfWeek: React.FC<DayOfWeekProps> = ({ dayOfWeek }: DayOfWeekProps) => {
  const translatedDay = useDayOfTheWeekTranslation(dayOfWeek);
  return <UI.Typography>{translatedDay}</UI.Typography>;
};

interface Props {
  store: U.Nullable<TraderJSON>;
}

const OpeningTimes: React.FC<Props> = ({ store }: Props) => {
  const openingTimes: U.Nullable<OpeningTimesJSON[]> = useMemo(() => {
    if (isEmpty(store) || isEmpty(store?.details)) return null;
    const storeDetails = find(
      store?.details,
      details => !isEmpty(details.openingTimes),
    );
    return get(storeDetails, 'openingTimes');
  }, [store]);
  if (isEmpty(openingTimes)) return null;

  return (
    <UI.Container direction="column" bg="gray.A100" p="3, 9">
      <UI.Typography
        i18n={I18N.OPENING_HOURS}
        modifiers="grayA300, uppercase"
      />
      <UI.Container direction="column" p="0, 2">
        {map(openingTimes, openeningInfo => (
          <UI.Container align="center" justify="space-between" w="200px">
            <DayOfWeek dayOfWeek={openeningInfo.dayOfWeek} />
            <UI.Container align="center" gap={1}>
              <UI.Typography>{`${openeningInfo.openingHour}:${String(
                openeningInfo.openingMinutes,
              )?.padStart(2, '0')}`}</UI.Typography>
              <UI.Typography>{' - '}</UI.Typography>
              <UI.Typography>{`${openeningInfo?.closingHour}:${String(
                openeningInfo?.openingMinutes,
              )?.padStart(2, '0')}`}</UI.Typography>
            </UI.Container>
          </UI.Container>
        ))}
      </UI.Container>
    </UI.Container>
  );
};

export default OpeningTimes;
