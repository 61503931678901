import { PAGINATOR } from '@mmw/constants-i18n-functionalities';
import { Container } from '@ui-system/common-container';
import { PaginatorProps } from '@ui-system/interfaces-paginator';
import Typography from '@ui-system/material-typography';
import React, { useCallback, useMemo } from 'react';

import Arrow from './Arrow';
import PaginatorSkeleton from './PaginatorSkeleton';

const LIMITS = [10, 20, 50, 100];

const Paginator: React.FC<PaginatorProps> = ({
  pagination: { offset, limit, total },
  paginate,
  loading,
  limits = LIMITS,
}: PaginatorProps) => {
  const page = useMemo(() => offset / limit + 1, [limit, offset]);
  const hide = useMemo(() => total <= limit, [limit, total]);

  const backward = useCallback(() => {
    paginate({ offset: offset - limit, limit });
  }, [limit, offset, paginate]);

  const foreward = useCallback(() => {
    paginate({ offset: offset + limit, limit });
  }, [limit, offset, paginate]);

  const pages = useMemo(() => {
    if (!total) return [];
    const size = Math.ceil(total / limit) || 0;

    if (!size || !Number.isFinite(size)) return [];
    return [...Array.from(Array(size).keys())].map(String).map((p: string) => {
      const option = Number(p) + 1;
      return <option key={option}>{option}</option>;
    });
  }, [total, limit]);
  const paging: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    ({ target: { value } }) => {
      if (!value) return;
      paginate({ offset: Number(value) * limit - limit, limit });
    },
    [paginate, limit],
  );

  const limitate: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    ({ target: { value } }) => {
      if (!value) return;
      paginate({ offset, limit: Number(value) });
    },
    [offset, paginate],
  );
  const limitations = useMemo(
    () => limits.map((l: number) => <option key={l}>{l}</option>),
    [limits],
  );

  const i18n = useMemo(() => ({ count: pages?.length }), [pages]);

  if (hide) return null;
  if (loading) return <PaginatorSkeleton />;

  return (
    <Container justify="center" align="center">
      <Arrow onClick={backward} hide={page <= 1} back />
      <Container align="center" gap={3}>
        <Container direction="column" align="center">
          <select onChange={paging} value={page}>
            {pages}
          </select>
        </Container>
        <Typography
          i18n={PAGINATOR.TOTAL_LABEL}
          values={i18n}
          variant="body2"
          modifiers="bold, lowercase"
        />
        <Container direction="column" align="center">
          <select onChange={limitate} value={limit}>
            {limitations}
          </select>
        </Container>
      </Container>
      <Arrow onClick={foreward} hide={page >= pages.length} />
    </Container>
  );
};

export default Paginator;
