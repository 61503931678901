import { useDeepCompareMemo } from '@react-utils/hooks';
import { memoizeWithHash } from '@shared-utils/function';
import { deepMergeAll } from '@shared-utils/object';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import * as utils from '@ui-system/css/utils';
import { Style } from '@ui-system/interfaces/types';
import { devices, useMediaQueries } from '@ui-system/media-query';
import {
  MakeStyleParam,
  MakeStyleParamWithoutProps,
  UseCustomProps,
  UseStyle,
  UseStyles,
} from '@ui-system/styles/types';
import { useTheme } from '@ui-system/theme';
import { isReactNative } from 'is-react-native';
import compact from 'lodash/compact';

export interface Callback<Props extends Record<string, any> | null = null> {
  (param: MakeStyleParam<Props>): Style;
}
export function makeStyle<Props extends Record<string, any> | null = null>(
  callback: Callback<Props>,
): UseStyle<Props> {
  const memoizedCallback = memoizeWithHash(callback);
  return function useStyle(props: Props extends null ? null : Props): Style {
    const assets = useAssets();
    const theme = useTheme();
    const mediaQueries = useMediaQueries();
    const memoProps = useDeepCompareMemo(() => props, [props]);
    return useDeepCompareMemo(
      () =>
        memoizedCallback({
          assets,
          theme,
          props: memoProps,
          utils,
          mediaQueries,
          devices,
          css,
          nullifyInReactNative(style) {
            if (isReactNative()) return css``;
            return style;
          },
        }),
      [assets, mediaQueries, memoProps, theme],
    );
  } as UseStyle<Props>;
}
export function makeCustomProps<
  Props extends Record<string, any> | null = null,
>(
  callback: (param: MakeStyleParam<Props>) => Record<string, any>,
): UseCustomProps<Props> {
  const memoizedCallback = memoizeWithHash(callback);
  return function useCustomProp(
    props: Props extends null ? null : Props,
  ): Record<string, any> {
    const assets = useAssets();
    const theme = useTheme();
    const mediaQueries = useMediaQueries();
    const memoProps = useDeepCompareMemo(() => props, [props]);
    return useDeepCompareMemo<Record<string, any>>(
      () =>
        memoizedCallback({
          assets,
          theme,
          props: memoProps,
          utils,
          mediaQueries,
          devices,
          css,
          nullifyInReactNative(style) {
            if (isReactNative()) return css``;
            return style;
          },
        }),
      [assets, mediaQueries, memoProps, theme],
    );
  } as UseCustomProps<Props>;
}

export function makeStyles<K extends string = string>(
  callback: (param: MakeStyleParamWithoutProps) => Record<K, Style>,
): UseStyles<K> {
  const memoizedCallback = memoizeWithHash(callback);
  return function useStyles(): Record<string, Style> {
    const assets = useAssets();
    const theme = useTheme();
    const mediaQueries = useMediaQueries();

    return useDeepCompareMemo(
      () =>
        memoizedCallback({
          nullifyInReactNative(style) {
            if (isReactNative()) return css``;
            return style;
          },
          assets,
          theme,
          utils,
          mediaQueries,
          devices,
          css,
        }),
      [assets, mediaQueries, theme],
    );
  } as unknown as UseStyles<K>;
}

export function useMergedStyles(
  ...styles: (Style | undefined | null)[]
): Style {
  return useDeepCompareMemo<Style>(
    () => deepMergeAll<Style>(compact(styles)),
    styles,
  );
}
