import { Hex } from '@ui-system/color';
import { Color } from '@ui-system/css';
import { Style } from '@ui-system/interfaces/types';
import { nanoid } from 'nanoid';
import * as React from 'react';
import { U } from 'ts-toolbelt';

export interface IconProps {
  color?: Color | Hex;
  style?: Style;
  modifiers?: string;
  transform?: string;
  name?: string;
  size?: number;
  width?: number;
  height?: number;
  onClick?: U.Nullable<() => void>;
  svg?: string;
}

export const ICON_UUID = nanoid();

export type IconType = React.FC<IconProps>;
