import { nanoid } from 'nanoid';
import React from 'react';
import SwitchSelector, {
  ISwitchSelectorOption,
  ISwitchSelectorProps,
} from 'react-native-switch-selector';
import { U } from 'ts-toolbelt';

export type SwitchSelectorRef = U.Nullable<React.Ref<SwitchSelector>>;

export type SwitchSelectorOptions = ISwitchSelectorOption;

export interface SwitchSelectorProps extends Partial<ISwitchSelectorProps> {
  options: ISwitchSelectorOption[];
  onClick: (value: number) => void;
  value?: number;
  initialValue?: number;
  innerRef?: SwitchSelectorRef;
  bordered?: boolean;
}

export type SwitchSelectorType<OtherProps = Record<string, any>> = React.FC<
  SwitchSelectorProps & OtherProps
>;

export const SWITCH_SELECTOR_UUID = nanoid();
