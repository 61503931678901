import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { U } from 'ts-toolbelt';

export const SET_SALES_ORG_BRAND =
  '@mmw/currentSalesOrgBrand/SET_SALES_ORG_BRAND';

export type SetSalesOrgBrand = {
  type: '@mmw/currentSalesOrgBrand/SET_SALES_ORG_BRAND';
  payload: {
    salesOrgBrandID: SalesOrgBrand;
  };
};

export type State = {
  currentSalesOrgBrandID: U.Nullable<SalesOrgBrand>;
};

export type Action = SetSalesOrgBrand;

export type Reducer = (state: State, action: Action) => State;

export const NAMESPACE = 'currentSalesOrgBrand';

export type RootState = {
  currentSalesOrgBrand: State;
};
