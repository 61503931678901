import contextualConfig from '@mmw/contextual-config';
import { appStartAction } from '@mmw/redux-action-app-start';
import each from 'lodash/each';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { U } from 'ts-toolbelt';

import { createAutoLoginAction } from './createAutoLoginAction';

type Props = {
  initialActions?: U.Nullable<Action<string>[]>;
};

const { logger } = contextualConfig.application;

let alreadyExecuted = false;

export const AppStoreInitializer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  initialActions,
}: React.PropsWithChildren<Props>) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // XXX: component may be re-created due to React app nature
    if (alreadyExecuted) {
      logger.warn(
        'Re render of AppStoreInitializer, this wont cause store dispatches!',
      );
      return;
    }
    alreadyExecuted = true;
    dispatch(appStartAction());
    const loginAction = createAutoLoginAction();
    if (loginAction) dispatch(loginAction);
    each(initialActions, dispatch);
  }, [dispatch, initialActions]);

  return <>{children}</>;
};
