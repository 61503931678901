import { useContext } from 'react';
import invariant from 'tiny-invariant';

import FastFormContext from '../context';

function useFastFormProviderContext() {
  const context = useContext(FastFormContext);
  invariant(context, 'You must use this inside <FastFormProvider>');
  return context;
}

export default useFastFormProviderContext;
