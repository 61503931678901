import { fastHash } from '@hasher/object-hash';
import { deepmerge } from '@shared-utils/object';
import { css } from '@ui-system/css';
import * as utils from '@ui-system/css/utils';
import {
  ComponentModifiers,
  ComponentModifiersFunction,
} from '@ui-system/interfaces';
import { devices, MediaQueries } from '@ui-system/media-query';
import { MakeStyleParamWithoutProps } from '@ui-system/styles/types';
import { Theme } from '@ui-system/theme';
import { O } from '@utils/ts';
import { isReactNative } from 'is-react-native';
import memoize from 'lodash/memoize';
import noop from 'lodash/noop';

export type ModifiersCallback<T extends string = string> = (
  param: MakeStyleParamWithoutProps,
) => ComponentModifiers<T>;

export function createComponentModifiers<T extends string = string>(
  callback: ModifiersCallback = noop as ModifiersCallback,
): ComponentModifiersFunction<T> {
  function componentModifiers(
    theme: Theme,
    mediaQueries: MediaQueries,
    assets: O.Object,
  ): ComponentModifiers<T> {
    return callback({
      theme,
      mediaQueries,
      utils,
      devices,
      css,
      assets,
      nullifyInReactNative(style) {
        if (isReactNative()) return css``;
        return style;
      },
    });
  }
  return memoize<ComponentModifiersFunction<T>>(componentModifiers, fastHash);
}

export function createComponentCustomModifiers<T extends string = string>(
  defaultModifiersFn: ComponentModifiersFunction<T>,
  callback: ModifiersCallback = noop as ModifiersCallback,
): ComponentModifiersFunction<T> {
  function componentModifiers(
    theme: Theme,
    mediaQueries: MediaQueries,
    assets: O.Object,
  ): ComponentModifiers<T> {
    const defaultModifiers = defaultModifiersFn(theme, mediaQueries, assets);
    const customModifiers = callback({
      theme,
      mediaQueries,
      utils,
      devices,
      css,
      assets,
      nullifyInReactNative(style) {
        if (isReactNative()) return css``;
        return style;
      },
    });
    return deepmerge(defaultModifiers, customModifiers);
  }

  return memoize<ComponentModifiersFunction<T>>(componentModifiers, fastHash);
}
