import { SerializedStyles } from '@emotion/serialize';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { useStoresByLocationList } from '@store-locator/store-creator/hooks';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces/types';
import UI from '@ui-system/ui';
import { SnackbarProviderProps } from 'notistack';
import React from 'react';
import ToastNotificationsProvider from 'toast-notifications';

import MapCustomizationProvider, {
  LocatorCustomizationContextValue,
} from './context';
import TraderSearchMap from './map';
import StoresSearch from './stores-search';

export * from './stores-search/form/Fields';

const CONTAINER_STYLE = css`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 40px;
`;
interface Props {
  customConfigs?: LocatorCustomizationContextValue;
  style?: Style | Style[] | SerializedStyles;
  notificationsProviderProps?: SnackbarProviderProps;
}

const StoreLocatorContent: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  style,
  customConfigs,
  notificationsProviderProps = EMPTY_OBJECT,
}: React.PropsWithChildren<Props>) => {
  const storesList = useStoresByLocationList();

  return (
    <ToastNotificationsProvider
      preventDuplicate
      {...notificationsProviderProps}
    >
      <UI.Container style={style || CONTAINER_STYLE} direction="column">
        <MapCustomizationProvider configs={customConfigs}>
          <TraderSearchMap storesList={storesList}>
            <StoresSearch storesList={storesList}>{children}</StoresSearch>
          </TraderSearchMap>
        </MapCustomizationProvider>
      </UI.Container>
    </ToastNotificationsProvider>
  );
};

export default StoreLocatorContent;
