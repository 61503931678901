import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentSalesOrgBrandAction } from '../actions';
import { currentSalesOrgBrandSelector } from '../stateSelector';

export const useSetCurrentSalesOrgBrand = () => {
  const dispatch = useDispatch();
  return useCallback(
    (salesOrgBrandID: number) => {
      dispatch(setCurrentSalesOrgBrandAction(salesOrgBrandID));
    },
    [dispatch],
  );
};

export const useCurrentSalesOrgBrand = () =>
  useSelector(currentSalesOrgBrandSelector);

export const useSetBaseSobByTrigger = (trigger: boolean) => {
  const setSob = useSetCurrentSalesOrgBrand();
  useEffect(() => {
    if (trigger) {
      setSob(-1);
    }
  }, [trigger, setSob]);
};
