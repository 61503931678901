import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import { css } from '@ui-system/css';
import { DatePickerProps } from '@ui-system/interfaces-datepicker';
import UI from '@ui-system/ui';
import moment from 'moment';
import React, { useMemo } from 'react';

import DateInput from './web-elements';

function formatDate(date) {
  return moment(date).format('DD.MM.YYYY');
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  label,
  labelModifiers,
  labelVariant,
  placeholderI18n,
  minimumDate,
  maximumDate,
  onChange,
  spanOnRight,
  CustomIcon,
  style,
  disableCleanIcon,
  inputProps,
}: DatePickerProps) => {
  const translate = useTranslate();
  const locale = useLanguage();
  const disableDays = useMemo(
    () => ({ before: minimumDate, after: maximumDate }),
    [maximumDate, minimumDate],
  );
  return (
    <UI.Container direction="column" w="100%">
      {label && (
        <UI.Typography
          i18n={label}
          modifiers={labelModifiers}
          variant={labelVariant}
          style={css`
            line-height: 16px;
          `}
        />
      )}
      <UI.Container>
        <DateInput
          disableCleanIcon={disableCleanIcon || !value}
          placeholder={translate(placeholderI18n)}
          onChange={onChange}
          value={value as Date}
          formatDate={formatDate}
          disabledDays={disableDays}
          spanOnRight={spanOnRight}
          CustomIcon={CustomIcon}
          locale={locale}
          showOutsideDays
          style={style}
          inputProps={inputProps}
        />
      </UI.Container>
    </UI.Container>
  );
};

DatePicker.defaultProps = {
  labelModifiers: 'uppercase, grayA400',
  labelVariant: 'caption',
};
export default DatePicker;
