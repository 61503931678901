import { isEmpty, noop } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import { validateStartAction } from '../store/actions';
import { useFormCurrentErrors } from './internal';
import useFormId from './useFormId';
import useFormValidationSchema from './useFormValidationSchema';
import useIsFormManuallyValidated from './useIsFormManuallyValidated';

const ONLY_WATCHED_FIELDS = true;

function useValidateWatchedFields(
  onSuccess: () => void,
  onError: F.Function = noop,
) {
  const dispatch = useDispatch();
  const formId = useFormId();
  const validationSchema = useFormValidationSchema();
  const isManuallyValidated = useIsFormManuallyValidated();
  const currentErrors = useFormCurrentErrors();
  useEffect(() => {
    if (isManuallyValidated) {
      if (isEmpty(currentErrors)) {
        onSuccess();
      } else {
        onError(currentErrors);
      }
    }
  }, [isManuallyValidated]);
  return useCallback(() => {
    dispatch(
      validateStartAction(formId, validationSchema, ONLY_WATCHED_FIELDS),
    );
  }, [dispatch, formId, validationSchema]);
}

export default useValidateWatchedFields;
