import { ResourceBundle } from '@mmw/services-core-resource/types';
import { bundleVersion } from '@mmw/utils-text-utils';
import get from 'lodash/get';
import { U } from 'ts-toolbelt';

import { State } from './types';

export const NAMESPACE = 'i18n';

export type RootState = {
  i18n: State;
};

export const bundleNameSelector = (state: RootState): string =>
  state[NAMESPACE].bundleName;

export const secondaryBundleNameSelector = (
  state: RootState,
): U.Nullable<string> => state[NAMESPACE].secondaryBundleName;

export const bundleNameFieldPath = [NAMESPACE, 'bundleName'];

export const secondaryBundleNameFieldPath = [NAMESPACE, 'secondaryBundleName'];

export const resourceBundleSelector = (state: RootState): ResourceBundle =>
  state[NAMESPACE].resourceBundle;

export const bundleVersionSelector = (state: RootState) => {
  const bundle = resourceBundleSelector(state);
  return get(bundle, bundleVersion, null);
};
export const resourceBundleFieldPath = [NAMESPACE, 'resourceBundle'];

export const languageSelector = (state: RootState): string =>
  state[NAMESPACE].currentLanguage;

export const languageFieldPath = [NAMESPACE, 'currentLanguage'];

export const loadingSelector = (state: RootState): boolean =>
  state[NAMESPACE].loading;

export const errorSelector = (state: RootState): U.Nullable<Error> =>
  state[NAMESPACE].error;
