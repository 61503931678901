import { Mutate, StoreApi, UseBoundStore } from 'zustand';

export class StoreConfig<State> {
  name: string;

  initialState?: State;

  constructor(params: Partial<StoreConfig<State>> = {}) {
    // eslint-disable-next-line no-constructor-return
    return {
      ...this,
      ...params,
    };
  }
}

export type CreateReturnType<State, Mos = []> = UseBoundStore<
  Mutate<StoreApi<State>, Mos>
>;
