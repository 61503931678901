import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { useMemo } from 'react';

import useBundleVersion from './useBundleVersion';

function useMemoWithBundle<T>(
  create: () => T,
  dependencies: ReadonlyArray<unknown> | null,
): T {
  const bundleVersion = useBundleVersion();
  return useMemo(create, [...(dependencies || EMPTY_ARRAY), bundleVersion]);
}

export default useMemoWithBundle;
