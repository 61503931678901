import { css } from '@ui-system/css';
import { CARD_ACTION_AREA_UUID, CARD_UUID } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import { F } from 'ts-toolbelt';

import { makeFinalStyleHook } from './internal';

const useActionAreaDefaultStyle = makeStyle(
  () => css`
    flex: 1;
    flex-direction: row;
  `,
);

const useCardDefaultStyle = makeStyle(
  ({ props }: { props: { onClick?: F.Function } }) => css`
    ${props?.onClick ? 'cursor: pointer;' : ''}
  `,
);

export const useCardStyle = makeFinalStyleHook(CARD_UUID, useCardDefaultStyle);

export const useActionAreaStyle = makeFinalStyleHook(
  CARD_ACTION_AREA_UUID,
  useActionAreaDefaultStyle,
);
