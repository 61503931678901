import { CountryCode } from '@mmw/constants-country-codes';
import I18N from '@mmw/constants-i18n-orgunit';
import contextualConfig from '@mmw/contextual-config';
import { useRestartForm } from '@mmw/redux-store-fast-form/hooks';
import { useValue } from '@mmw/redux-store-fast-form/hooks/fields';
import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import { TraderJSON } from '@mmw/services-core-store-locator/types';
import { getStoreLocatorService } from '@mmw/services-holder';
import {
  setAlreadySearch,
  setLastSearchByUserLocation,
  useAlreadySearch,
  useLastSearchByUserLocation,
} from '@store-locator/store-creator';
import {
  useIsLoadingStoresByLocationSearch,
  useResetStoresByLocationSearch,
  useSearchStoresByLocation,
  useTotalStoresListByLocation,
} from '@store-locator/store-creator/hooks';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import { useColors } from '@ui-system/theme/colors';
import UI from '@ui-system/ui';
import { useBoolean } from 'ahooks';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { F, U } from 'ts-toolbelt';

import ExpansibleCard from '../components/ExpansibleCard';
import NotificationsHandler from '../components/NotificationsHandler';
import StoreName from '../components/StoreName';
import {
  useCustomCardTitle,
  useCustomUrlPathAsObj,
  useCustomUrlRequestObj,
} from '../context';
import { MeasureUnitsProvider } from '../context/measure-units';
import {
  STORE_LOCATOR_FIELDPATHS,
  StoreLocatorSearchFormValues,
} from './form/configs';
import {
  CountrySelectFormField,
  MeasureUnitFormField,
  RadiusSelectFormField,
} from './form/Fields';
import SearchButton from './form/SearchButton';
import SearchFormProvider from './form/SearchFormProvider';
import RefreshSearchOnChangeValuesListener from './RefreshSearchOnChangeValuesListener';
import TabContentHandler from './TabsContentHandler';

const {
  application: { supportedCountries },
} = contextualConfig;

function useTabsConfigs() {
  const colors = useColors();
  return useMemo(
    () => ({
      tabsTitle: [
        {
          ...I18N.STORE_LOCATOR.SEARCH_STORE,
          icon: 'local',
          iconColor: 'gray.A400',
        },
        {
          ...I18N.STORE_LOCATOR.USE_MY_LOCATION,
          icon: 'local',
          iconColor: 'gray.A400',
        },
      ],
      inactiveTabStyles: {
        style: {
          backgroundColor: colors.gray.A100,
          boxShadow: `inset 0 0 10px ${colors.gray.A200}`,
        },
        modifiers: 'primary',
        iconColor: 'primary',
      },
    }),
    [colors.gray.A100, colors.gray.A200],
  );
}

const TABS_INDICATOR_PROPS = {
  style: css`
    display: none;
  `,
};

const CARD_STYLE = {
  position: 'absolute',
  left: 30,
  top: 60,
  zIndex: 999,
  width: 500,
};

const MOBILE_STYLE = {
  position: 'fixed',
  left: 0,
  bottom: 0,
  zIndex: 9995,
  width: '100%',
};

const CARD_CONTENT_STYLE = css`
  padding: 0;
  -webkit-box-shadow: 0px 2px 9px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 9px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 9px -3px rgba(0, 0, 0, 0.75);
`;

interface SearchContainerProps extends React.PropsWithChildren {
  isListFilled: boolean;
}

const StoresSearchContainer: React.FC<SearchContainerProps> = ({
  children,
  isListFilled,
}: SearchContainerProps) => {
  const [visible, { setTrue, setFalse }] = useBoolean(true);
  const isMobile = useIsMediaQueryUpToMD();
  const alreadySearch = useAlreadySearch();
  const total = useTotalStoresListByLocation();

  useEffect(() => {
    if (
      isMobile &&
      (isListFilled || (alreadySearch && total != null && total < 1))
    ) {
      setFalse();
    }
  }, [isListFilled, total, alreadySearch]);

  if (isMobile) {
    return (
      <UI.Container direction="column" style={MOBILE_STYLE}>
        <UI.Container
          onClick={visible ? setFalse : setTrue}
          w="100%"
          h="50px"
          bg="white"
          p="2"
          justify="center"
          align="center"
        >
          <UI.Icon name={visible ? 'down' : 'up'} size={25} />
        </UI.Container>
        {visible ? (
          <UI.Animation preset="DTT">
            <UI.Container
              direction="column"
              w="100%"
              bg="white"
              visible={visible}
            >
              {children}
            </UI.Container>
          </UI.Animation>
        ) : null}
      </UI.Container>
    );
  }
  return (
    <UI.Card
      style={CARD_STYLE as React.CSSProperties}
      id="search-card"
      elevation={5}
    >
      {children}
    </UI.Card>
  );
};

interface StoresSearchProps {
  storesList: U.Nullable<TraderJSON[]>;
  searchError?: U.Nullable<Error>;
  currentTab: number;
  setTab: F.Function<[number]>;
  setError?: F.Function;
}

const customSearchStoresContainer = css`
  max-height: 320px;
  overflow: auto;
  width: 100%;
  @media only screen and (max-width: 1270px) and (max-height: 700px) {
    max-height: 135px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1300px) and (min-height: 720px) and (max-height: 800px) {
    max-height: 280px;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1600px) and (min-height: 780px) and (max-height: 900px) {
    max-height: 320px;
  }
  @media screen and (min-width: 1600px) and (max-width: 1700px) and (min-height: 800px) and (max-height: 900px) {
    max-height: 370px;
  }
  @media screen and (min-width: 1700px) and (max-width: 1750px) and (min-height: 800px) and (max-height: 900px) {
    max-height: 500px;
  }
  @media screen and (min-width: 1750px) and (max-width: 1800px) and (min-height: 900px) and (max-height: 950px) {
    max-height: 600px;
  }
`;

const StoresSearch: React.FC<React.PropsWithChildren<StoresSearchProps>> = ({
  children,
  storesList,
  searchError,
  setError,
  currentTab,
  setTab,
}: React.PropsWithChildren<StoresSearchProps>) => {
  const isMobile = useIsMediaQueryUpToMD();
  const [isLoadingCurrentLocation, setIsLoading] = useState(false);
  const currentRadius = useValue<number>(STORE_LOCATOR_FIELDPATHS.radius.$path);
  const restartForm = useRestartForm();
  const isLoading = useIsLoadingStoresByLocationSearch();
  const language = useLanguage();
  const isListEmpty = isEmpty(storesList);
  const { tabsTitle, inactiveTabStyles } = useTabsConfigs();
  const resetSearch = useResetStoresByLocationSearch();
  const lastSearchByUserLocation = useLastSearchByUserLocation();

  const onChangeTab = useCallback(
    (nextTab: number) => {
      if (nextTab === 1) {
        /**
         * XXX: This was necessary to clean up form values and
         *  avoid re-submittind on switch between tabs,
         *  related to the FastForm onSubmitSuccess bug
         */
        restartForm(new StoreLocatorSearchFormValues(currentRadius as number));
        if (!lastSearchByUserLocation && isListEmpty) {
          resetSearch();
        }
      }
      setTab(nextTab);
    },
    [
      currentRadius,
      isListEmpty,
      lastSearchByUserLocation,
      resetSearch,
      restartForm,
      setTab,
    ],
  );

  const CustomCardTitle = useCustomCardTitle();

  return (
    <>
      <UI.Spinner visible={isLoading || isLoadingCurrentLocation} coverScreen />
      <NotificationsHandler mapsError={searchError} />
      <StoresSearchContainer isListFilled={!isListEmpty}>
        <UI.Card.Content style={CARD_CONTENT_STYLE}>
          <UI.TabBar
            tabsTitles={tabsTitle}
            titleModifiers="uppercase, secondary"
            currentTabIndex={currentTab}
            onSelect={onChangeTab}
            tabStyle={css`
              padding: 20px;
            `}
            indicatorColor="primary"
            inactiveTabStyles={inactiveTabStyles}
            TabIndicatorProps={TABS_INDICATOR_PROPS}
            variant="fullWidth"
          />
          <RefreshSearchOnChangeValuesListener
            shouldSearch={!isListEmpty}
            isCurrentLocationTab={currentTab === 1}
          />
          <TabContentHandler
            currentTab={currentTab}
            language={language}
            setLoading={setIsLoading}
            setError={setError}
            setTab={setTab}
            currentRadius={currentRadius}
          >
            {children}
          </TabContentHandler>
        </UI.Card.Content>
        {isMobile ? null : (
          <UI.Container
            style={customSearchStoresContainer}
            w="100%"
            p={isListEmpty ? '' : '1, 0, 0, 0'}
            shadow={5}
          >
            <UI.Card.CollapseArea
              style={css`
                width: 100%;
                height: 100%;
              `}
              in={!isListEmpty}
            >
              <UI.Container
                direction="column"
                style={css`
                  height: 100%;
                `}
              >
                {map(storesList, store => (
                  <ExpansibleCard
                    key={store.orgunitID}
                    store={store}
                    title={
                      CustomCardTitle ? (
                        <CustomCardTitle store={store} limitWidth />
                      ) : (
                        <StoreName store={store} limitWidth />
                      )
                    }
                  />
                ))}
              </UI.Container>
            </UI.Card.CollapseArea>
          </UI.Container>
        )}
      </StoresSearchContainer>
    </>
  );
};

interface Props {
  storesList: U.Nullable<TraderJSON[]>;
}

const StoresSearchWraper: React.FC<React.PropsWithChildren<Props>> = ({
  storesList,
  children,
}: React.PropsWithChildren<Props>) => {
  const isMobile = useIsMediaQueryUpToMD();
  const [searchError, setError] = useState<U.Nullable<Error>>(null);
  const [currentTab, setTab] = useState(0);
  const language = useLanguage();
  const searchStores = useSearchStoresByLocation();
  const customUrlRequestObj = useCustomUrlRequestObj();
  const customUrlPathsAsObj = useCustomUrlPathAsObj();
  const resetSearch = useResetStoresByLocationSearch();

  const onSearchStore = useCallback(
    async data => {
      resetSearch();
      setLastSearchByUserLocation(false);
      if (!data) return;
      try {
        const result =
          await getStoreLocatorService().googleSearchAddressCoordinates(data);
        setError(null);
        if (currentTab === 1) {
          setTab(0);
        }
        if (result?.lat && result?.lng) {
          searchStores({
            language,
            lat: result?.lat,
            lng: result?.lng,
            radius: data.radius,
            country: data.country,
            ...customUrlRequestObj,
            ...customUrlPathsAsObj,
          });
          setAlreadySearch(true);
        }
      } catch (e) {
        setError(e);
      }
    },
    [
      currentTab,
      customUrlPathsAsObj,
      customUrlRequestObj,
      language,
      resetSearch,
      searchStores,
    ],
  );

  return (
    <SearchFormProvider onSubmitSuccess={onSearchStore}>
      <MeasureUnitsProvider>
        <StoresSearch
          storesList={storesList}
          searchError={searchError}
          setError={setError}
          currentTab={currentTab}
          setTab={setTab}
        >
          {currentTab === 1 ? (
            <UI.Container
              direction="column"
              align="flex-end"
              justify="flex-end"
              p="3"
              w="100%"
            >
              <RadiusSelectFormField
                style={css`
                  width: ${isMobile ? '100%' : '205px'};
                `}
              />
              <MeasureUnitFormField />
            </UI.Container>
          ) : (
            <>
              <UI.Container direction="column" p="3, 3, 0, 3">
                {supportedCountries && supportedCountries.length > 1 ? (
                  <CountrySelectFormField
                    supportedCountries={supportedCountries as CountryCode[]}
                  />
                ) : null}
              </UI.Container>
              {children}
              <SearchButton />
            </>
          )}
        </StoresSearch>
      </MeasureUnitsProvider>
    </SearchFormProvider>
  );
};

export default StoresSearchWraper;
