// import debug from 'debug';
import logger, {
  disable as disableLog,
  enable as enableLog,
  subscribe as subscribeToLog,
} from './logger';
import { Debug as DebugType, Log as LogType } from './types';

export { getNamespaces } from './logger';

export type Debug = DebugType;
export type Log = LogType;

export default logger('mmw');
export const subscribe = subscribeToLog;
export const enable = enableLog;
export const disable = disableLog;
