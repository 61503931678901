import { I18nShape } from '@mmw/constants-i18n';
import isString from 'lodash/isString';
import { useCallback, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { F, U } from 'ts-toolbelt';

import { findMessage, textTransform } from '.';

export const useTranslation = (
  messageID: I18nShape['key'],
  message: I18nShape['message'],
  intl: IntlShape,
): string | null =>
  useMemo(
    () =>
      textTransform(findMessage(messageID, message, intl), {
        capitalize: true,
      }),
    [messageID, message, intl],
  );

type UseTranslateFn = F.Function<[U.Nullable<I18nShape | string>], string>;

export function useTranslate(): UseTranslateFn {
  const intl = useIntl();
  return useCallback(
    (i18n: U.Nullable<I18nShape | string>): string => {
      if (!i18n) return '';
      if (isString(i18n)) return i18n;

      const { key, message, values } = i18n;

      return textTransform(findMessage(key, message, intl, values), {
        capitalize: true,
      });
    },
    [intl],
  );
}

export function useTranslateShape(
  i18n?: U.Nullable<I18nShape | string>,
): string {
  const translate = useTranslate();

  return useMemo(() => {
    if (!i18n) return '';
    if (typeof i18n === 'string') return i18n;
    return translate(i18n);
  }, [i18n, translate]);
}

export function useStringMaxLength(
  max: number | null,
): (text: string) => string {
  return useCallback(
    text =>
      max
        ? textTransform(text, {
            maxLength: max,
          })
        : text,
    [max],
  );
}
