import { UNITED_KINGDOM, UNITED_STATES } from '@mmw/constants-country-codes';
import { includes } from 'lodash';
import { createContext, PropsWithChildren, useState } from 'react';
import { createStore, StoreApi } from 'zustand';

import { State } from './types';

export const MeasureUnitsContext = createContext<StoreApi<State> | null>(null);

export const MeasureUnitsProvider = ({ children }: PropsWithChildren) => {
  const [store] = useState(() =>
    createStore<State>(set => ({
      measureUnits: 'kms',
      setMeasureUnits: value => {
        set({
          measureUnits: includes([UNITED_STATES, UNITED_KINGDOM], value)
            ? 'miles'
            : 'kms',
        });
      },
      toggleMeasureUnits: callback => {
        set(state => ({
          measureUnits: state.measureUnits === 'kms' ? 'miles' : 'kms',
        }));
        callback();
      },
    })),
  );

  return (
    <MeasureUnitsContext.Provider value={store}>
      {children}
    </MeasureUnitsContext.Provider>
  );
};
