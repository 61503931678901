import qs from 'qs';

const BASE_PATH = '/v1/rec-group';

export const GetMarketingShopTokensPath = (params: {
  language: string;
}): string =>
  `${BASE_PATH}/marketing-tools/eidex/shop-token?${qs.stringify({
    ...params,
  })}`;

export const GetPrintShopTokensPath = (params: { language: string }): string =>
  `${BASE_PATH}/marketing-tools/sigel/shop-token?${qs.stringify({
    ...params,
  })}`;

export const GetRECCoinManagementTransactionPath = (): string =>
  `${BASE_PATH}/rec-coin-management/transaction`;

export const GetRECCoinManagementProjectStatusPath = (params: {
  projectID: number;
  language: string;
}): string =>
  `${BASE_PATH}/rec-coin-management/project/${
    params.projectID
  }/status?${qs.stringify({
    language: params.language,
  })}`;
