/* eslint-disable @typescript-eslint/no-unused-vars */
export const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = window.screen;

export default function pxPerfect<T = string | number>(
  size: number,
  _noSuffix = false,
  _based: 'width' | 'height' = 'width',
): T {
  return `${size}px` as unknown as T;
}
