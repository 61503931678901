import { Country } from '@mmw/services-core-common/types';
import { U } from 'ts-toolbelt';

export const GET_AVAILABLE_COUNTRIES_START =
  '@mmw/available-countries/GET_AVAILABLE_COUNTRIES_START';
export const GET_AVAILABLE_COUNTRIES_SUCCESS =
  '@mmw/available-countries/GET_AVAILABLE_COUNTRIES_SUCCESS';
export const GET_AVAILABLE_COUNTRIES_ERROR =
  '@mmw/available-countries/GET_AVAILABLE_COUNTRIES_ERROR';

export type GetAvailableCountriesStartAction = {
  type: '@mmw/available-countries/GET_AVAILABLE_COUNTRIES_START';
};

export type GetAvailableCountriesSuccessAction = {
  type: '@mmw/available-countries/GET_AVAILABLE_COUNTRIES_SUCCESS';
  payload: {
    availableCountries: Country[];
  };
};

export type GetAvailableCountriesErrorAction = {
  type: '@mmw/available-countries/GET_AVAILABLE_COUNTRIES_ERROR';
  payload: {
    error: Error;
  };
};

export type State = {
  loading: boolean;
  availableCountries: Country[];
  error: U.Nullable<Error>;
};

export type Action =
  | GetAvailableCountriesStartAction
  | GetAvailableCountriesSuccessAction
  | GetAvailableCountriesErrorAction;

export type Reducer = (state: State, action: Action) => State;
