import { StyleModifiers } from '../types';
import add from './add';

export type ModifierPattern = {
  [key: string]: {
    [key: string]: string;
  };
};

/*
mount modifiers based on object pattern
*/

export default (modifierPattern: ModifierPattern): StyleModifiers =>
  Object.keys(modifierPattern).reduce(
    (mountedModifiers, modifierName) => ({
      ...mountedModifiers,
      // TODO: [modifierName]: ({ theme }) => add(modifierPattern[modifierName], theme),
      [modifierName]: () => add(modifierPattern[modifierName]),
    }),
    {},
  );
