import createApi, {
  ApiResponse as ApiResponseType,
} from '@mmw/common-api-client';
import config from '@mmw/contextual-config';

export * from '@mmw/common-api-client';

const api = createApi({
  baseURI: config.api.auth.baseURI,
});

export default api;

export type ApiResponse<T> = ApiResponseType<T>;
