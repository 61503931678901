import { createAction } from '@reduxjs/toolkit';

import { createTypes } from './createTypes';

// enum ActionName {
//   start = 'start',
//   success = 'success',
//   error = 'error',
//   dismisserror = 'dismissError',
//   reset = 'reset',
//   cancel = 'cancel',
// }
// XXX: please do not add a return type to no BUG it out, check epics if you do that
export function createActions<StartPayload, SuccessPayload, ErrorPayload, Meta>(
  namespace: string,
  actionName: string,
) {
  const types = createTypes(namespace, actionName);

  const start = createAction(
    types.START,
    (payload: StartPayload, meta: Meta) => ({
      payload,
      meta,
    }),
  );
  const success = createAction(
    types.SUCCESS,
    (payload: SuccessPayload, meta: Meta) => ({
      payload,
      meta,
    }),
  );
  const error = createAction(
    types.ERROR,
    (payload: ErrorPayload, meta: Meta) => ({
      payload,
      meta,
    }),
  );
  const dismissError = createAction(types.DISMISS_ERROR, (meta: Meta) => ({
    payload: undefined,
    meta,
  }));
  const reset = createAction(types.RESET, (meta: Meta) => ({
    payload: undefined,
    meta,
  }));
  const cancel = createAction(types.CANCEL, (meta: Meta) => ({
    payload: undefined,
    meta,
  }));

  return {
    start,
    success,
    error,
    dismissError,
    reset,
    cancel,
  };
}

export const createAsyncActions = createActions;
