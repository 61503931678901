import { css } from '@ui-system/css';
import { createComponentModifiers } from '@ui-system/modifiers';
import pxPerfect from '@ui-system/px-perfect';

const componentModifiers = createComponentModifiers(({ theme }) => ({
  error: css`
    border-bottom-color: ${theme.colors[theme.mode].error};
  `,
  lg: css`
    font-size: ${pxPerfect(35)};
  `,
  uppercase: css`
    text-transform: uppercase;
  `,
  dark: css`
    color: ${theme.colors[theme.mode].black};
  `,
  fullWidth: css`
    width: 100%;
  `,
}));

export default componentModifiers;
