import { useDeepCompareMemo } from '@react-utils/hooks';
import { deepMergeAll, EMPTY_OBJECT } from '@shared-utils/object';
import { unflatten } from 'flat';
import { compact, pickBy } from 'lodash';
import assign from 'lodash/assign';
import each from 'lodash/each';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import replace from 'lodash/replace';
import split from 'lodash/split';
import values from 'lodash/values';
import qs from 'qs';

import { CurrentMediaQuery } from './types';
import { useMediaQueries } from './useMediaQuery';

type Props<T> = Partial<Record<keyof CurrentMediaQuery, T>>;

function stringParser<P>(str?: string): Props<P> {
  if (!str) return EMPTY_OBJECT as Props<P>;
  const cleanStrings = split(replace(str, ' ', ''), ';');

  const result = {};

  each(cleanStrings, cleanString => {
    const keyValue: Record<string, any> = qs.parse(cleanString);
    const [key] = keys(keyValue);
    const [value] = values(keyValue);
    if (value === 'false') {
      keyValue[key] = false;
    }
    if (value === 'true') {
      keyValue[key] = true;
    }
    assign(result, keyValue);
  });

  return unflatten(result);
}
export function usePropsByMediaQuery<P>(
  props?: Props<P> | string,
): Record<string, any> {
  const mediaQueries = useMediaQueries();
  return useDeepCompareMemo(() => {
    if (!props) return EMPTY_OBJECT;
    let parsedProps: Props<P>;
    if (isString(props)) {
      parsedProps = stringParser(props);
    } else {
      parsedProps = props;
    }
    const propsToMerge: P[] = [];
    const activeMediaQueries = pickBy(mediaQueries.current, value => value);

    each(keys(activeMediaQueries), (mediaQuery: string) => {
      if (parsedProps[mediaQuery]) {
        propsToMerge.push(parsedProps[mediaQuery]);
      }
    });
    // @ts-ignore
    return deepMergeAll(propsToMerge);
  }, [mediaQueries, props]);
}

export interface ResponsiveProps<P = Record<string, any>> {
  responsive?: string | Props<P>;
}
export function useResponsiveProps<P extends Record<string, any>>(
  responsive?: string | Props<P>,
  props?: P,
): P {
  const propsByMediaQuery = usePropsByMediaQuery(responsive);
  return useDeepCompareMemo<P>(
    () => deepMergeAll(compact([props, propsByMediaQuery])) as P,
    [props, propsByMediaQuery],
  );
}
