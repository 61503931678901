import { INFORMATION } from '@mmw/constants-i18n-common';

const productSelectionNamespace = 'productSelection';
const purchaseSelectionNamespace = 'purchaseSelection';
const consumerSelectionNamespace = 'consumerSelection';
const bankAccountNamespace = 'bankAccountSelection';
const questions = 'questions';
const uploadDeclarationDocumentNamespace = 'uploadDeclarationDocumentNamespace';
const uploadConsultingProtocolNamespace = 'uploadConsultingProtocolNamespace';

const PURCHASE_SELECTION = {
  TITLE: {
    key: `${purchaseSelectionNamespace}.title`,
    message: 'Purchase Selection',
  },
  DESCRIPTION: {
    key: `${purchaseSelectionNamespace}.description`,
    message:
      'You can enter the invoice or order number here. If you enter the order number, you can complete the registration with the invoice number later on.',
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${purchaseSelectionNamespace}Help`,
      message:
        'Please enter your details here. Please fill out all fields marked with *. For some actions, it may also be necessary to upload the invoice or the purchase slip. Please click on the button "Upload file".',
    },
  },
  CREATE_NEW_SELLER: {
    key: `${purchaseSelectionNamespace}.createNewSeller`,
    message: 'Create new seller',
  },
  SELLER_REGISTRATION: {
    TITLE: {
      key: `${purchaseSelectionNamespace}.sellerRegistration.title`,
      message: 'New seller registration',
    },
  },
};

const PRODUCT_SELECTION = {
  TITLE: {
    key: `${productSelectionNamespace}.title`,
    message: 'Product Selection',
  },
  DESCRIPTION: {
    key: `${productSelectionNamespace}.description`,
    message: 'Find your specific devices using the search for serial numbers.',
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${productSelectionNamespace}Help`,
      message:
        'You can find products by using our name search, or serialnumber search. After you select a product, you will be requested to inform fields like serialnumber, or imei of the product.',
    },
  },
  PRODUCT_KIT: {
    key: `${productSelectionNamespace}.productKit`,
    message: 'Product Kit',
  },
};

const QUESTIONS = {
  TITLE: {
    key: `${questions}.title`,
    message: 'Please answer the following questions',
  },
  DESCRIPTION: {
    key: `${questions}.description`,
    message:
      'As part of the manufacturer’s action, we ask you to answer the following questions.',
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${questions}Help`,
      message: 'Please answer all the following required questions.',
    },
  },
  NO_QUESTIONS_FEEDBACK: {
    key: `${questions}.noQuestionsFeedback`,
    message: 'There are no questions to answer by now.',
  },
};

const CONSUMER_SELECTION = {
  TITLE: {
    key: `${consumerSelectionNamespace}.fieldTitle`,
    message: 'Consumer',
  },
  DESCRIPTION: {
    key: `${consumerSelectionNamespace}.fieldDescription`,
    message:
      'Enter the consumer data here or search for a consumer that has already been registered through your company.',
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${consumerSelectionNamespace}Help`,
      message:
        'Fill in the consumer name to attempt to find the consumer. If you cant find it, you can always create a new one, by selecting the `New Consumer` option after you type the consumer`s name.',
    },
  },
  SEND_DOCUMENT_TO_CONSUMER_ANSWER: {
    key: `${consumerSelectionNamespace}.sendDocumentToConsumerAnswer`,
    message: 'Yes, send the document to his e-mail',
  },
  SEND_DOCUMENT_TO_CONSUMER_QUESTION: {
    key: `${consumerSelectionNamespace}.sendDocumentToConsumerQuestion`,
    message: 'Do you want to send the registration document for the consumer',
  },
  BUTTONS: {
    NEW: {
      key: `${consumerSelectionNamespace}.fieldButtonAdd`,
      message: 'New Consumer',
    },
    SHIPPING: {
      key: `${consumerSelectionNamespace}.fieldButtonShipping`,
      message: 'Shipping Address',
    },
    SEARCH: {
      key: `${consumerSelectionNamespace}.fieldButtonSearch`,
      message: 'Advanced Search',
    },
  },
};
const BANK_ACCOUNT = {
  TITLE: {
    key: `${bankAccountNamespace}.fieldTitle`,
    message: 'Bank Account',
  },
  DESCRIPTION: {
    key: `${bankAccountNamespace}.fieldDescription`,
    message: 'Please inform your bank account.',
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${bankAccountNamespace}Help`,
      message: 'Fill in your consumer bank account information.',
    },
  },
};

const UPLOAD_DECLARATION_DOCUMENT = {
  TITLE: {
    key: `${uploadDeclarationDocumentNamespace}.fieldTitle`,
    message: 'Please answer the following questions',
  },
  DESCRIPTION: {
    key: `${uploadDeclarationDocumentNamespace}.fieldDescription`,
    message:
      'Finally, please state whether you would like to upload the counseling protocol or the affidavit as evidence of performance. Both documents can be downloaded here and then filled out.',
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${uploadDeclarationDocumentNamespace}.help`,
      message:
        'Select a kind of proof, download declaration document. Sign, scan and upload it.',
    },
  },
  LABELS: {
    AFFIDAVIT: {
      key: `${uploadDeclarationDocumentNamespace}.labels.affidavit`,
      message: 'Affidavit',
    },
    MINUTES_OF_THE_CONSULTATION: {
      key: `${uploadDeclarationDocumentNamespace}.labels.minutesOfTheConsultation`,
      message: 'Minutes of the Consultation',
    },
    DONT_UPLOAD_ANY_DOCUMENT: {
      key: `${uploadDeclarationDocumentNamespace}.labels.dontUploadAnyDocument`,
      message: 'Dont upload any document',
    },
  },
  QUESTION: {
    key: `${uploadDeclarationDocumentNamespace}.whatAchivementProofUpload`,
    message: 'What kind of proof do you want to upload?',
  },
  ATTACHMENT: {
    key: `${uploadDeclarationDocumentNamespace}.attachment`,
    message: 'Please attach the document',
  },
};

const UPLOAD_CONSULTING_PROTOCOL = {
  TITLE: {
    key: `${uploadConsultingProtocolNamespace}.fieldTitle`,
    message: 'Please answer the following questions',
  },
  DESCRIPTION: {
    key: `${uploadConsultingProtocolNamespace}.fieldDescription`,
    message:
      'Finally, please upload the counseling protocol as evidence of performance. The document can be downloaded here and then filled out.',
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${uploadConsultingProtocolNamespace}.help`,
      message: '', // TODO: Help text
    },
  },
};

const CUSTOM_STEPS = {
  DEMO_DEVICES: {
    TITLE: {
      key: `demoDevicesSelection.title`,
      message: 'Vorführbereite Geräte',
    },
    DESCRIPTION: {
      key: `demoDevicesSelection.description`,
      message:
        'Verwalten Sie hier Ihre vorführbereiten Geräte. Zum Hinzufügen von neuen Geräten klicken Sie bitte auf das entsprechende Bild und geben Sie die jeweilige Seriennummer ein.',
    },
    LIST_TITLE: {
      key: `demoDevicesSelection.tableTitle`,
      message: 'Aktuell vorführbereit',
    },
    STORE_LOCATOR: {
      DEVICES_LABEL: {
        key: 'storeLocator.devicesLabel',
        message: 'Cold Brew Geräte verfügbar',
      },
    },
  },
};

const I18N = {
  PURCHASE_SELECTION,
  PRODUCT_SELECTION,
  CONSUMER_SELECTION,
  BANK_ACCOUNT,
  QUESTIONS,
  UPLOAD_DECLARATION_DOCUMENT,
  UPLOAD_CONSULTING_PROTOCOL,
  CUSTOM_STEPS,
};

export default I18N;
