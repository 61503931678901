import { useTheme } from '@ui-system/theme';
import React from 'react';
import styled from 'styled-components';

import wrapperStyle from './theme/wrapperStyle';

type ContainerProps = {
  children?: React.ReactNode;
  spanOnRight: boolean;
  fontFamily: any;
  colors: any;
};

const Container = styled.div<ContainerProps>(wrapperStyle);

type WrapperProps = {
  children?: React.ReactNode;
  spanOnRight: boolean;
};

function Wrapper({ children, spanOnRight }: WrapperProps): JSX.Element {
  const { colors, typography } = useTheme();
  return (
    <Container
      spanOnRight={spanOnRight}
      colors={colors}
      fontFamily={typography.fontFamily.regular}
    >
      {children}
    </Container>
  );
}

export default Wrapper;
