import * as React from 'react';
import { DefaultToast } from 'react-toast-notifications';
import styled, { withTheme } from 'styled-components';

type Props = {
  children: ReactNode;
  appearence: string;
};

const StyledDefaultToast = styled(DefaultToast)([]);

const Notification = ({ children, ...props }: Props) => (
  <StyledDefaultToast {...props}>{children}</StyledDefaultToast>
);

export default withTheme(Notification);
