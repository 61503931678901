import { FILE as FIELDS } from '@mmw/constants-fields-names';
import {
  REQUIRED,
  // MAX_LENGTH,
} from '@mmw/constants-validation-names';
// import { FILE as VALIDATIONS } from '@mmw/constants-fields-validations';

const namespace = 'validation';

const FILE = {
  NAME: {},
  TYPE: {
    INVALID: {
      key: `${namespace}.${FIELDS.TYPE}.${REQUIRED}`,
      message: 'File Type must be valid',
    },
  },
  DATA: {},
  FORMAT: {},
};

export default FILE;
