import { EMPTY_OBJECT } from '@mmw/constants-utils';
import get from 'lodash/get';
import join from 'lodash/join';
import { create, StateCreator } from 'zustand';
import { devtools, persist, PersistOptions } from 'zustand/middleware';

import { CreateReturnType, StoreConfig } from './types';

export function createStoreModule<State>({
  name,
  initialState,
  initializer,
  disablePersist,
  persistOptions = EMPTY_OBJECT,
}: StoreConfig<State> & {
  initializer?: StateCreator<State>;
  disablePersist?: boolean;
  persistOptions?: Omit<PersistOptions<any>, 'name'>;
}): CreateReturnType<State, []> {
  const STORE_CONFIG = new StoreConfig({ name });
  const stateCreator =
    (initializer as () => State) || (() => initialState as State);
  return create<State>()(
    devtools(
      disablePersist
        ? stateCreator
        : persist(stateCreator, { name: STORE_CONFIG.name, ...persistOptions }),
      { anonymousActionType: STORE_CONFIG.name, name: STORE_CONFIG.name },
    ),
  );
}

export function createSelector<T, S = Record<string, any>>(
  ...keys: string[]
): (state: S) => T {
  return function selector(state) {
    return get(state, join(keys, '.'));
  };
}
