import { createDI } from '@di/core';
import { ApiResponse } from '@mmw/api-auth';
import defaultApiV2 from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import {
  GetMarketingShopTokensPath,
  GetPrintShopTokensPath,
  GetRECCoinManagementProjectStatusPath,
  GetRECCoinManagementTransactionPath,
} from './apiPaths';
import logger from './log';
import {
  AddRecCoinTransactionResponse,
  CreateRecCoinWalletTransactionJSON,
  GetProjectRecCoinStatusResponse,
} from './types';

type Api = typeof defaultApiV2;

type MarketingToolsSSOServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class MarketingToolsSSOService {
  api: Api;

  authenticationService: AuthenticationService;

  static createDI = createDI<MarketingToolsSSOService>;

  constructor({
    apiv2,
    authenticationService,
  }: MarketingToolsSSOServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getMarketingShopSSOToken({
    language,
  }: {
    language: string;
  }): Promise<string> {
    logger.debug('Trying to get MarketingShopSSOUrl');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<string> = await this.api.get(
        GetMarketingShopTokensPath({ language }),
        { headers },
      );
      const { data } = response;
      logger.info('Successfully got MarketingShopSSOUrl');
      return data;
    } catch (error) {
      logger.error('Error when getting MarketingShopSSOUrl, error=%O', error);
      throw error;
    }
  }

  async getPrintShopSSOToken({
    language,
  }: {
    language: string;
  }): Promise<string> {
    logger.debug('Trying to get MarketingShopSSOUrl');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<string> = await this.api.get(
        GetPrintShopTokensPath({ language }),
        { headers },
      );
      const { data } = response;
      logger.info('Successfully got MarketingShopSSOUrl');
      return data;
    } catch (error) {
      logger.error('Error when getting MarketingShopSSOUrl, error=%O', error);
      throw error;
    }
  }

  async addRecCoinTransaction(
    json: CreateRecCoinWalletTransactionJSON,
  ): Promise<AddRecCoinTransactionResponse> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<AddRecCoinTransactionResponse> =
        await this.api.post(GetRECCoinManagementTransactionPath(), json, {
          headers,
        });
      const { data } = response;
      logger.info('Successfully got addRecCoinTransaction');
      return data;
    } catch (error) {
      logger.error('Error when getting addRecCoinTransaction, error=%O', error);
      throw error;
    }
  }

  async getProjectRecCoinStatus({
    projectID,
    language,
  }: {
    projectID: number;
    language: string;
  }): Promise<GetProjectRecCoinStatusResponse> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<GetProjectRecCoinStatusResponse> =
        await this.api.get(
          GetRECCoinManagementProjectStatusPath({ language, projectID }),
          {
            headers,
          },
        );
      const { data } = response;
      logger.info('Successfully got getProjectRecCoinStatus');
      return data;
    } catch (error) {
      logger.error(
        'Error when getting getProjectRecCoinStatus, error=%O',
        error,
      );
      throw error;
    }
  }
}

export default MarketingToolsSSOService;
