import { Color } from '@ui-system/css';
import { I18nShape } from '@ui-system/deps';
import { Modifiers, Style } from '@ui-system/interfaces/types';
import { TypographyVariant } from '@ui-system/interfaces-typography';
import { ResponsiveProps } from '@ui-system/media-query';
import { nanoid } from 'nanoid';
import * as React from 'react';
import { U } from 'ts-toolbelt';

export type ButtonColor = 'inherit' | 'primary' | 'secondary' | 'default';
export type ButtonVariant = 'text' | 'outlined' | 'contained' | 'white';

export type Responsive = ResponsiveProps<
  Pick<
    ButtonProps,
    'modifiers' | 'textModifiers' | 'accessoryRight' | 'accessoryLeft'
  >
>;

export interface ButtonProps extends Responsive {
  onClick?: (...args: any[]) => any;
  i18n?: U.Nullable<I18nShape | string>;
  children?: string | React.ReactNode;
  color?: ButtonColor;
  variant?: ButtonVariant;
  textVariant?: TypographyVariant;
  round?: boolean;
  style?: Style;
  textStyle?: Style;
  textModifiers?: Modifiers;
  modifiers?: Modifiers;
  accessoryRight?: string;
  accessoryLeft?: string;
  iconSize?: number;
  iconColor?: Color;
  loading?: U.Nullable<boolean>;
  disabled?: U.Nullable<boolean>;
  id?: string;
  fullWidth?: boolean;
}

export const BUTTON_UUID = nanoid();
export const BUTTON_TEXT_UUID = nanoid();
export const BUTTON_ICON_UUID = nanoid();

export type ButtonType = React.FC<ButtonProps>;
