import { useDeepCompareMemo } from '@react-utils/hooks';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { useAssets } from '@ui-system/assets';
import {
  ComponentModifiersFunction,
  Modifiers,
  Style,
  StyleOrFn,
} from '@ui-system/interfaces';
import { useMediaQueries } from '@ui-system/media-query';
import { useTheme } from '@ui-system/theme';
import { U } from '@utils/ts';
import noop from 'lodash/noop';

import { applyModifiers } from './applyModifiers';

export function useModifiers(
  modifiers: U.Nullable<Modifiers>,
  componentModifiers: U.Nullable<ComponentModifiersFunction> = noop as ComponentModifiersFunction,
  previousStyle: U.Nullable<Style> = undefined,
): Style {
  const theme = useTheme();
  const mediaQueries = useMediaQueries();
  const assets = useAssets();
  const finalCompModifiers = useDeepCompareMemo<
    Record<string, StyleOrFn>
  >(() => {
    if (componentModifiers) {
      return componentModifiers(theme, mediaQueries, assets);
    }
    return EMPTY_OBJECT as Record<string, StyleOrFn>;
  }, [theme, mediaQueries, assets, componentModifiers]);

  return useDeepCompareMemo(
    () => applyModifiers(modifiers, finalCompModifiers, previousStyle),
    [modifiers, finalCompModifiers, previousStyle],
  ) as Style;
}
