import COUNTRIES, { CountryCode } from '@mmw/constants-country-codes';

import LANGUAGE_CODES from './languageCodes';

// Languages Supported by MMW
const DEFAULT_LANGUAGES_BY_COUNTRY_CODE: Partial<Record<CountryCode, string>> =
  {
    [COUNTRIES.AUSTRIA]: LANGUAGE_CODES.DE, // German
    [COUNTRIES.AUSTRALIA]: LANGUAGE_CODES.EN, // English
    [COUNTRIES.BELGIUM]: LANGUAGE_CODES.DE, // German
    [COUNTRIES.CANADA]: LANGUAGE_CODES.EN, // English
    [COUNTRIES.CHINA]: LANGUAGE_CODES.ZH, // Chinese
    [COUNTRIES.FRANCE]: LANGUAGE_CODES.FR, // French
    [COUNTRIES.GERMANY]: LANGUAGE_CODES.DE, // German
    [COUNTRIES.GREAT_BRITAIN]: LANGUAGE_CODES.EN, // English
    [COUNTRIES.INDIA]: LANGUAGE_CODES.HI, // Hindi
    [COUNTRIES.INDONESIA]: LANGUAGE_CODES.ID, // Indonesian
    [COUNTRIES.ITALY]: LANGUAGE_CODES.IT, // Italian
    [COUNTRIES.JAPAN]: LANGUAGE_CODES.JA, // Japanese
    [COUNTRIES.MALAYSIA]: LANGUAGE_CODES.MS, // Malay
    [COUNTRIES.MEXICO]: LANGUAGE_CODES.ES, // Spanish
    [COUNTRIES.NETHERLANDS]: LANGUAGE_CODES.NL, // Dutch
    [COUNTRIES.PHILIPPINES]: LANGUAGE_CODES.EN, // English
    [COUNTRIES.POLAND]: LANGUAGE_CODES.PL, // Polish
    [COUNTRIES.PORTUGAL]: LANGUAGE_CODES.PT, // Portuguese
    [COUNTRIES.RUSSIA]: LANGUAGE_CODES.RU, // Russian
    [COUNTRIES.SAUDI_ARABIA]: LANGUAGE_CODES.AR, // Arabic
    [COUNTRIES.SOUTH_KOREA]: LANGUAGE_CODES.KO, // Korean
    [COUNTRIES.SPAIN]: LANGUAGE_CODES.ES, // Spanish
    [COUNTRIES.TAIWAN]: LANGUAGE_CODES.ZH, // Chinese
    [COUNTRIES.TURKEY]: LANGUAGE_CODES.TR, // Turkish
    [COUNTRIES.UNITED_ARAB_EMIRATES]: LANGUAGE_CODES.AR, // Arabic
    [COUNTRIES.UNITED_STATES]: LANGUAGE_CODES.EN, // English
    [COUNTRIES.UKRAINE]: LANGUAGE_CODES.UK, // Ukrainian
    [COUNTRIES.VIETNAM]: LANGUAGE_CODES.VI, // Vietnamese
  };

function getLanguageCodeFromCountryCode(
  countryCode: CountryCode,
  defaultLanguage: string,
): string {
  return DEFAULT_LANGUAGES_BY_COUNTRY_CODE[countryCode] || defaultLanguage;
}

export default getLanguageCodeFromCountryCode;
