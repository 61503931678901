import { Style } from '@ui-system/interfaces/types';
import { O } from '@utils/ts';
import { MotiView } from 'moti';
import React, { CSSProperties } from 'react';
import { UseSpringProps, UseTransitionProps } from 'react-spring/web';

export type PresetsKeys =
  | 'FADE_IN'
  | 'FADE_OUT'
  | 'LTR'
  | 'RTL'
  | 'TTD'
  | 'DTT';

export type Preset = UseSpringProps<O.Merge<O.Object, CSSProperties>>;

export type Values<TItem = any, DS extends O.Object = O.Object> = O.Merge<
  DS & CSSProperties,
  UseTransitionProps<TItem, DS>
>;

export type Presets = Record<PresetsKeys, Preset>;

export interface Props extends React.PropsWithChildren<any> {
  preset?: PresetsKeys;
  custom?: Preset;
  style?: Style;
  f?: number; // FLEX
  fullWidth?: boolean;
  duration?: number;
}

export interface TransitionProps {
  index: number;
  children: React.ReactNode | React.ReactNodeArray;
  values?: Values;
}

export type TransitionType = React.FC<TransitionProps>;

export enum EnterFromEnum {
  top = 'top',
  bottom = 'bottom',
}
export interface AnimateHeightProps
  extends React.ComponentProps<typeof MotiView> {
  children?: React.ReactNode;
  /**
   * If `true`, the height will automatically animate to 0. Default: `false`.
   */
  hide?: boolean;
  onHeightDidAnimate?: (height: number) => void;
  /**
   * Defines where the expanded view will be anchored.
   *
   * Default: `top`
   *
   * This prop is untested, use with caution
   */
  // enterFrom?: EnterFromEnum;
  initialHeight?: number;
}

export type AnimateHeightType = React.FC<AnimateHeightProps>;

export interface AnimationStatics {
  Transition: TransitionType;
  AnimateHeight: AnimateHeightType;
}

export type AnimationType = React.FC<React.PropsWithChildren<Props>> &
  AnimationStatics;
