const namespace = 'massProjectUploadAmer';
const massProjectUploadAmerFields = `${namespace}.fields`;

const MASS_PROJECT_UPLOAD_AMER = {
  REGISTER: {
    key: `${namespace}.registerWithSpreadsheetUpload`,
    message: 'Register with spreadsheet upload',
  },
};

export const MASS_PROJECT_UPLOAD_AMER_FIELDS = {
  PROJECT_NAME: {
    key: `${massProjectUploadAmerFields}.projectName`,
    message: 'Project name',
  },
  OWNER_FIRSTNAME: {
    key: `${massProjectUploadAmerFields}.ownerFirstname`,
    message: 'Owner first name',
  },
  OWNER_LASTNAME: {
    key: `${massProjectUploadAmerFields}.ownerLastname`,
    message: 'Owner last name',
  },
  OWNER_COMPANY: {
    key: `${massProjectUploadAmerFields}.ownerCompany`,
    message: 'Owner company',
  },
  OWNER_EMAIL: {
    key: `${massProjectUploadAmerFields}.ownerEmail`,
    message: 'Owner email',
  },
  OWNER_STREET: {
    key: `${massProjectUploadAmerFields}.ownerStreet`,
    message: 'Owner street',
  },
  OWNER_STREET_NO: {
    key: `${massProjectUploadAmerFields}.ownerStreetNo`,
    message: 'Owner street number',
  },
  OWNER_ZIPCODE: {
    key: `${massProjectUploadAmerFields}.ownerZipcode`,
    message: 'Owner zipcode',
  },
  OWNER_CITY: {
    key: `${massProjectUploadAmerFields}.ownerCity`,
    message: 'Owner city',
  },
  OWNER_STATE: {
    key: `${massProjectUploadAmerFields}.ownerState`,
    message: 'Owner state',
  },
  OWNER_COUNTRY: {
    key: `${massProjectUploadAmerFields}.ownerCountry`,
    message: 'Owner country',
  },
  CERTIFICATE_SEND: {
    key: `${massProjectUploadAmerFields}.certificateSend`,
    message: 'Certificate send',
  },
  OWNER_SYSTEM_LOCATION: {
    key: `${massProjectUploadAmerFields}.ownerSystemLocation`,
    message: 'Owner system location',
  },
  SYSTEM_FIRSTNAME: {
    key: `${massProjectUploadAmerFields}.systemFirstname`,
    message: 'System first name',
  },
  SYSTEM_LASTNAME: {
    key: `${massProjectUploadAmerFields}.systemLastname`,
    message: 'System last name',
  },
  SYSTEM_EMAIL: {
    key: `${massProjectUploadAmerFields}.systemEmail`,
    message: 'System email',
  },
  SYSTEM_STREET: {
    key: `${massProjectUploadAmerFields}.systemStreet`,
    message: 'System street',
  },
  SYSTEM_STREET_NO: {
    key: `${massProjectUploadAmerFields}.systemStreetNo`,
    message: 'System street number',
  },
  SYSTEM_ZIPCODE: {
    key: `${massProjectUploadAmerFields}.systemZipcode`,
    message: 'System zipcode',
  },
  SYSTEM_CITY: {
    key: `${massProjectUploadAmerFields}.systemCity`,
    message: 'System city',
  },
  SYSTEM_STATE: {
    key: `${massProjectUploadAmerFields}.systemState`,
    message: 'System state',
  },
  SYSTEM_COUNTRY: {
    key: `${massProjectUploadAmerFields}.systemCountry`,
    message: 'System country',
  },
  SYSTEM_PANEL_COUNT: {
    key: `${massProjectUploadAmerFields}.systemPanelCount`,
    message: 'System panel count',
  },
  SYSTEM_PANEL_TYPE: {
    key: `${massProjectUploadAmerFields}.systemPanelType`,
    message: 'System panel type',
  },
  SYSTEM_PANEL_WATTCLASS: {
    key: `${massProjectUploadAmerFields}.systemPanelWattclass`,
    message: 'System watt class',
  },
  SYSTEM_OPERATION_START: {
    key: `${massProjectUploadAmerFields}.systemOperationStart`,
    message: 'System operation start',
  },
  SYSTEM_INSTALLATION_TYPE: {
    key: `${massProjectUploadAmerFields}.systemInstallationType`,
    message: 'System Installation Type',
  },
  WARNING_TEMPLATE_UTF8: {
    key: `${massProjectUploadAmerFields}.warningTemplateUtf8`,
    message:
      'Please always use UTF-8 encoding when saving this file to ensure the correct storage of special characters.',
  },
  SYSTEM_PANEL_SERIALS: {
    key: `${massProjectUploadAmerFields}.systemPanelSerias`,
    message: 'System panel serials',
  },
};

const I18N = {
  MASS_PROJECT_UPLOAD_AMER,
  MASS_PROJECT_UPLOAD_AMER_FIELDS,
};

export default I18N;
