import { css } from '@ui-system/css';
import { MODAL_UUID } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useModalDefaultStyle = makeStyle(
  () => css`
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  `,
);

export const useModalStyle = makeFinalStyleHook(
  MODAL_UUID,
  useModalDefaultStyle,
);
