import { I18nShape } from '@mmw/constants-i18n/types';

const NAMESPACE = 'store';

export const STORE: I18nShape = {
  key: `${NAMESPACE}.store`,
  message: 'store',
};
export const STORES: I18nShape = {
  key: `${NAMESPACE}.stores`,
  message: 'stores',
};

export const DEMONSTRATION_DEVICES: I18nShape = {
  key: `${NAMESPACE}.demonstrationDevices`,
  message: 'demonstration devices',
};

export const NO_DEMONSTRATION_DEVICES_REGISTRED: I18nShape = {
  key: `${NAMESPACE}.noDemonstrationDevicesRegistered`,
  message: 'no demonstration devices registered',
};

export const NO_AUDITS_REGISTERED: I18nShape = {
  key: `${NAMESPACE}.noAuditsRegistered`,
  message: 'no audits registered',
};

export const NO_STORES_FOUND: I18nShape = {
  key: `${NAMESPACE}.noStoresFound`,
  message: 'no stores were found for your search',
};

export const STORES_FOUND: I18nShape = {
  key: `${NAMESPACE}.storesFound`,
  message: '{total} stores were found for your search',
};

export const ORGANIZATION_DATA_SUCCESSFULLY_UPDATED: I18nShape = {
  key: `${NAMESPACE}.theOrganizationDataWasSuccessfullyUpdated`,
  message: 'The organization data was successfully updated.',
};

export const UPDATE_ORGUNIT_DETAILS_FAILED: I18nShape = {
  key: `${NAMESPACE}.failedToUpdateOrgunitDetails`,
  message: 'Failed to update current orgunit details',
};

const ICON_LABELS_NAMESPACE = 'store';

export const ICON_LABELS = {
  EYE: {
    message: 'Store Locator',
    key: `${NAMESPACE}.${ICON_LABELS_NAMESPACE}.eye`,
  },
  GLOBE: {
    message: 'Webpage',
    key: `${NAMESPACE}.${ICON_LABELS_NAMESPACE}.globe`,
  },
  MAIL: {
    message: 'Email',
    key: `${NAMESPACE}.${ICON_LABELS_NAMESPACE}.mail`,
  },
  LOCATION: {
    message: 'Geo-Coordinates',
    key: `${NAMESPACE}.${ICON_LABELS_NAMESPACE}.location`,
  },
  CALENDAR: {
    message: 'Opening hours',
    key: `${NAMESPACE}.${ICON_LABELS_NAMESPACE}.calendar`,
  },
};

const I18N = {
  STORE,
  STORES,
  DEMONSTRATION_DEVICES,
  NO_DEMONSTRATION_DEVICES_REGISTRED,
  NO_STORES_FOUND,
  STORES_FOUND,
  NO_AUDITS_REGISTERED,
  ICON_LABELS,
};

export default I18N;
