const namespace = 'cookieConsent';
const COOKIE_CONSENT = {
  CONFIG: {
    TITLE: {
      key: `${namespace}.title`,
      message: 'Cookie Preference Center',
    },
    TEXT: {
      key: `${namespace}.text`,
      message:
        'When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer. Data obtained from cookies is not shared nor sold with third parties.',
    },
    CONTENT_TITLE: {
      key: `${namespace}.manageConsentTitle`,
      message: 'Manage Consent Preferences',
    },
  },
  // Temp texts for cookie consent content
  DYNAMIC_CONSENT_CONTENTS: {
    GOOGLE_MAPS_CONSENT: {
      TITLE: {
        key: `${namespace}.googleMapsConsentTitle`,
        message: 'Google Maps',
      },
      CONTENT: {
        key: `${namespace}.googleMapsConsent`,
        message:
          'Dieses Cookie ist notwendig um die {brand} Händler auf einer Landkarte anzuzeigen und die Distanz von Ihrem oder dem angegebenen Standort zu berechnen.',
      },
    },
    GOOGLE_RECAPTCHA_CONSENT: {
      TITLE: {
        key: `${namespace}.googleMapsConsentTitle`,
        message: 'Google ReCaptcha',
      },
      CONTENT: {
        key: `${namespace}.googleMapsConsent`,
        message:
          'Dieses Cookie dient zur Sicherheit und wird gesetzt, wenn Sie über diese Webseite Anfragen an einen {brand} Händler senden.',
      },
    },
    FUNCTIONAL_CONSENT: {
      TITLE: {
        key: `${namespace}.functionalConsentTitle`,
        message: 'Funktional notwendiges Cookie',
      },
      CONTENT: {
        key: `${namespace}.functionalConsent`,
        message:
          'Dieses Cookie ist notwendig zum einwandfreien Betrieb der Seite.',
      },
    },
    THIRD_PARTY_CONSENT: {
      CONTENT: {
        key: `${namespace}.thirdPartyConsent`,
        message:
          'When you visit our website you may notice some cookies that are not related to us. When you visit a page that contains embedded content, for example from YouTube, you may be sent cookies from these websites. We do not control the setting of such cookies and we advise you to check the third-party websites for more information about these cookies and how to manage them.',
      },
    },
    GOOGLE_ANALYTICS_CONSENT: {
      TITLE: {
        key: `${namespace}.googleAnalyticsConsentTitle`,
        message: 'Google Analytics',
      },
      CONTENT: {
        key: `${namespace}.googleAnalyticsConsent`,
        message:
          'Dieses Cookie hilft uns zu verstehen, wie Besucher mit unserer Website interagieren, Fehler zu entdecken und eine bessere Gesamtanalyse zu erstellen.',
      },
    },
    BANNER: {
      CONTENT: {
        key: `${namespace}.bannerContent`,
        message:
          'Auf unserer Website werden Cookies verwendet. Cookies, die nicht nur dem technisch einwandfreien und sicheren Seitenbetrieb dienen, kommen ausschließlich mit Ihrer Einwilligung zum Einsatz. Sie können eine erteilte Einwilligung jederzeit widerrufen.',
      },
      ACCEPT_ALL: {
        key: `${namespace}.acceptAll`,
        message: 'Alle akzeptieren',
      },
      CONFIGURATIONS: {
        key: `${namespace}.configurations`,
        message: 'Einstellungen',
      },
    },
    CONFIG_MODAL: {
      TITLE: {
        key: `${namespace}.modalTitle`,
        message: 'Erweiterte Cookie-Einstellungen',
      },
    },
  },
};

const I18N = { COOKIE_CONSENT };

export { COOKIE_CONSENT, I18N as default };
