import { useDaysOfTheWeek } from '@dates/core';
import { I18nShape } from '@mmw/constants-i18n';
import { useSetArrayValue } from '@mmw/redux-store-fast-form/hooks';
import { useSetValue, useValue } from '@mmw/redux-store-fast-form/hooks/fields';
import { OpeningTimesJSON } from '@mmw/services-core-manu-orgunit/types';
import UI from '@ui-system/ui';
import React, { useCallback, useMemo } from 'react';

type DateProp = { date: Date; update: (date: Date) => void };

const TIME_STYLE = {
  width: '112px',
};

function Time({ date, update }: DateProp) {
  return (
    <UI.Form.TimePicker value={date} onChange={update} style={TIME_STYLE} />
  );
}

function usePeriod(path: string, period?: OpeningTimesJSON) {
  const set = useSetValue<OpeningTimesJSON>(path);
  const [open, close] = period ? OpeningTimesJSON.date(period) : [];
  const update = useCallback(
    (target: 'day' | 'open' | 'close') => (date: Date) => {
      /**
       * ensure to update targetd props only
       */
      const next = { ...period };
      switch (target) {
        case 'day':
          next.dayOfWeek = date.getDay();
          break;
        case 'open':
          next.openingHour = date.getHours();
          next.openingMinutes = date.getMinutes();
          break;
        case 'close':
          next.closingHour = date.getHours();
          next.closingMinutes = date.getMinutes();
          break;
        default:
          return;
      }
      set(next);
    },
    [set, period],
  );
  return useMemo(
    () =>
      period
        ? {
            open,
            close,
            opening: update('open'),
            closing: update('close'),
          }
        : null,
    [close, open, period, update],
  );
}

function Period({ path, times }: { path: string; times: OpeningTimesJSON }) {
  const { open, close, opening, closing } = usePeriod(path, times);
  return (
    <UI.Container>
      <Time update={opening} date={open} />
      {/* <UI.Container w={24} align="center">
        <strong>-</strong>
      </UI.Container> */}
      <Time update={closing} date={close} />
    </UI.Container>
  );
}

const INITIAL = new OpeningTimesJSON({
  id: null,
  dayOfWeek: 0,
  openingHour: 9,
  openingMinutes: 0,
  closingHour: 12,
  closingMinutes: 0,
});

const ICON = {
  size: 24,
  color: 'gray.A400',
};

const ICON_CONTAINER = {
  marginLeft: '-16px',
  marginRight: '12px',
};

function DelIcon({ onClick }: { onClick: () => void }) {
  return (
    <UI.Container style={ICON_CONTAINER}>
      <UI.Icon {...ICON} name="close" onClick={onClick} />
    </UI.Container>
  );
}

function AddIcon({ onClick }: { onClick: () => void }) {
  return (
    <UI.Container style={ICON_CONTAINER}>
      <UI.Icon {...ICON} name="next" onClick={onClick} />
    </UI.Container>
  );
}

function useAddTime(path: string) {
  const push = useSetArrayValue<OpeningTimesJSON>(path);
  return useCallback(
    (dayweek: number, initial = INITIAL) => {
      push(
        new OpeningTimesJSON({
          ...initial,
          id: Date.now(),
          dayOfWeek: dayweek,
        }),
      );
    },
    [push],
  );
}
const FastFormOpeningTimes: React.FC<{
  i18n: I18nShape;
  path: string;
}> = ({ i18n, path }) => {
  const times = useValue<OpeningTimesJSON[]>(path, []);
  const set = useSetValue<OpeningTimesJSON[]>(path);
  // const clear = useCallback(() => set([]), [set]);
  const remove = useCallback(
    ({ id }: OpeningTimesJSON) =>
      () => {
        set(times.filter(p => p.id !== id));
      },
    [times, set],
  );
  const add = useAddTime(path);
  const weekdays = useDaysOfTheWeek().map(d => d.label);
  const timesByDay = useMemo(() => {
    const getByDay = (d: number) => times.filter(t => t.dayOfWeek === d);
    return weekdays.map((_, i) => getByDay(i));
  }, [times, weekdays]);
  const rmvDay = useCallback(
    (day: number) => {
      const dayTimes = timesByDay[day];
      set(times.filter(p => !dayTimes.some(d => d.id === p.id)));
    },
    [set, times, timesByDay],
  );
  const select = useCallback(
    (day: number) => (adding: boolean) => adding ? add(day) : rmvDay(day),
    [add, rmvDay],
  );
  const addAfter = useCallback(
    (day: number) => () =>
      add(day, {
        ...INITIAL,
        openingHour: 13,
        openingMinutes: 30,
        closingHour: 19,
        closingMinutes: 0,
      }),
    [add],
  );
  return (
    <UI.Container direction="column" w="100%">
      {weekdays?.map((day, i) => {
        const daytimes = timesByDay[i];
        const period1 = daytimes[0];
        const period2 = daytimes[1];
        return (
          <UI.Container align="center">
            <UI.Form.Checkbox
              checked={!!daytimes.length}
              onChange={select(i)}
              checkboxProps={{
                size: 'small',
              }}
              style={{
                width: '24px',
              }}
            />
            <UI.Container w="62px">
              <UI.Typography variant="caption" modifiers="capitalize">
                {day}
              </UI.Typography>
            </UI.Container>
            <UI.Container w="16px" />
            {period1 ? (
              <>
                <Period
                  times={period1}
                  path={`${path}.${times.findIndex(t => t.id === period1.id)}`}
                />
                {period2 ? (
                  <>
                    <DelIcon onClick={remove(daytimes[1])} />
                    <Period
                      times={period2}
                      path={`${path}.${times.findIndex(
                        t => t.id === period2.id,
                      )}`}
                    />
                  </>
                ) : (
                  <AddIcon onClick={addAfter(i)} />
                )}
              </>
            ) : null}
          </UI.Container>
        );
      })}
    </UI.Container>
  );
};

export default FastFormOpeningTimes;
