import { Color, Hex } from '@ui-system/color';

export interface Gray {
  A100: Hex;
  A200: Hex;
  A300: Hex;
  A400: Hex;
  A500: Hex;
  A600: Hex;
  A700: Hex;
  A800: Hex;
  A900: Hex;
}
export interface Rating {
  primary: Hex;
  secondary: Hex;
}

export enum ThemeMode {
  DARK = 'darkMode',
  LIGHT = 'lightMode',
}

export interface ColorMode {
  primary: Color;
  secondary: Color;
  black: Hex;
  white: Hex;
  info: Hex;
  warning: Hex;
  success: Hex;
  error: Hex;
  gray: Gray;
  background: Hex;
  basicDark: Hex;
  basicLight: Hex;
  light: Hex;
  rating: Rating;
}

export interface ThemeColors {
  [ThemeMode.DARK]: ColorMode;
  [ThemeMode.LIGHT]: ColorMode;
}

export interface TypographyColors {
  lightMode: {
    onPrimary: Hex;
    onSecondary: Hex;
    onBackground: Hex;
    placeholder: Hex;
  };
  darkMode: {
    onPrimary: Hex;
    onSecondary: Hex;
    onBackground: Hex;
    placeholder: Hex;
  };
}
