import { CampaignStatus } from '@mmw/constants-campaign-status';
import { CountryCode } from '@mmw/constants-country-codes';
import { FileType } from '@mmw/constants-file-types';
import { RegistrationSource } from '@mmw/constants-registration-sources';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { EMPTY_ARRAY } from '@mmw/constants-utils';
import {
  AddressJSON as _AddressJSON,
  ConsumerJSON as _ConsumerJSON,
  EMPTY_ADDRESS_JSON,
  FileJSON as _FileJSON,
  PersonJSON as _PersonJSON,
  QuestionGroupJSON,
} from '@mmw/services-core-common/types';
import { getFieldPaths } from '@shared-utils/object';
import { U } from 'ts-toolbelt';

export { FileType } from '@mmw/constants-file-types';

export type AddressJSON = _AddressJSON;
export type PersonJSON = _PersonJSON;
export type ConsumerJSON = _ConsumerJSON;

export type Pagination<T> = {
  list: Array<T>;
  limit: number;
  offset: number;
  total: number;
};

export type NotSavedPanel = {
  serialnumber: string;
  ean: string;
};

export type NotSavedPanelsObject = Record<string, NotSavedPanel>;

export type OrgunitJSON = {
  id?: number;
  participant?: number;
  owner?: string;
  address?: AddressJSON;
  phone?: string;
  crcity?: string;
  crnumber?: string;
  email?: string;
  homepage?: string;
  taxnumber?: string;
  salestaxid?: string;
  displayname?: string;
  gln?: string;
  fax?: string;
  managementbody?: string;
  salestype?: number;
  legalform?: number;
};

export type SolarValidationStatusModelJSON = {
  valid: boolean;
  ableToForceToProcess: boolean;
  installerErrorMessages: Array<string>;
  hasInstallerErrorMessages: boolean;
  systemSizeErrorMessages: Array<string>;
  hasSystemSizeErrorMessages: boolean;
  serialnumberErrorMessages: Array<string>;
  hasSerialnumberErrorMessages: boolean;
  supplierErrorMessages: Array<string>;
  hasSupplierErrorMessages: boolean;
  otherErrorMessages: Array<string>;
  hasOtherErrorMessages: boolean;
};

export type CampaignAddedValueJSON = {
  campaignAddedValueID: number;
  name: string;
  available: number;
  code: string;
  image: string;
};

export type CampaignJSON = {
  campaignID: number;
  name?: string;
  description?: string;
  code: string;
  type: string;
  imageNotNull: boolean;
  optional: boolean;
  requiresAccount: boolean;
  parent: boolean;
  mailingDate?: Date;
  validfrompurchase?: Date;
  validtopurchase?: Date;
  enddate?: Date;
  startdate?: Date;
  addedValues: Array<CampaignAddedValueJSON>;
};

export type QuestionJSON = {
  id: number;
  answer?: string;
  expectedAnswer?: string;
  code: string;
  text: string;
  position: number;
  answerID?: number;
  isOther: boolean;
};

export type ProjectCampaignItemJSON = {
  id?: number;
  campaignStatusLabel: string;
  campaignStatus: CampaignStatus;
  validationStatusModel: SolarValidationStatusModelJSON;
  errorLabels: Array<string>;
  campaign: CampaignJSON;
  questionGroups: Array<QuestionGroupJSON>;
  serialnumbersDuplicated: Array<string>;
  serialnumbersNotDelivered: Array<string>;
  serialnumbersPatternWrong: Array<string>;
};

export type ProjectProductJSON = {
  name: string;
  ean: string;
  productID: number;
  materialKey: number;
  salesOrgBrandDisplayName: string;
  brandName: string;
  actorvisibility: number;
  kit: boolean;
  flagchecknr: boolean;
  image?: string;
  salesOrgBrandID: number;
  quantity?: number;
  wattage?: number;
  classOfGoodsID?: number;
  classOfGoodsName?: string;
};

export type FileJSON = _FileJSON;

type Status = 0 | 2 | 3;

export type PanelJSON = {
  ean: string;
  // eslint-disable-next-line camelcase
  erp_order_nr: string;
  // eslint-disable-next-line camelcase
  erp_shipping_nr: string;
  group: string;
  notIncludeProjectID: number | null;
  palletnumber: string;
  projectID: number;
  salesOrgBrand: number;
  serialnumber: string;
};

export const EMPTY_PANEL = {
  ean: 'N/A',
  group: 'N/A',
  serialnumber: 'N/A',
  palletnumber: 'N/A',
  erp_order_nr: 'N/A',
  erp_shipping_nr: 'N/A',
  projectID: 0,
  salesOrgBrand: 0,
  notIncludeProjectID: null,
};

export type MaybePanelJSON = {
  ean?: string;
  // eslint-disable-next-line camelcase
  erp_order_nr?: string;
  // eslint-disable-next-line camelcase
  erp_shipping_nr?: string;
  group?: string;
  notIncludeProjectID?: U.Nullable<number>;
  palletnumber?: string;
  projectID?: number;
  salesOrgBrand?: number;
  serialnumber?: string;
};

export type InstallationSiteJSON = PersonJSON;

export type InstallerJSON = {
  id: number | null;
  participant: number;
  address: AddressJSON;
  phone: string;
};

export type ProjectJSON = {
  projectid: string;
  id: number;
  name?: string;
  userid?: string;
  description?: string;
  distributor?: U.Nullable<InstallerJSON>;
  duplicated?: boolean;
  consumer?: ConsumerJSON;
  installer?: OrgunitJSON;
  installationDate?: Date;
  registrationDate?: Date;
  installationPlace?: number;
  installationType?: number;
  invoiceDate?: Date;
  status: Status;
  type: string;
  installationSite?: InstallationSiteJSON;
  proposal?: string;
  registrationid?: string;
  generatedSystemSize?: number;
  generatedSystemSizeLabel?: string;
  systemSizeLabel?: string;
  systemSize?: number;
  campaignItems: Array<ProjectCampaignItemJSON>;
  products: Array<ProjectProductJSON>;
  files: Array<FileJSON>;
  brandName: string;
  salesOrgBrand: number;
  reversed: boolean;
  concept: boolean;
  registered: boolean;
  invalid: boolean;
  panelsAmount: number;
  validPanelsAmount: number;
  invalidPanelsAmount: number;
  ableToRegister: boolean;
  ableToRemoveItems: boolean;
  ableToAddItems: boolean;
  legalEntityID: number;
  ownerID: number;
  source: RegistrationSource;
  sources: RegistrationSource[];
};

export class ProjectRequestObject {
  salesOrgBrand: number;

  projectid?: string;

  status?: number;

  statusList?: number[] = [];

  type?: number;

  registrationid?: string;

  consumerLastName?: string;

  consumerCompany?: string;

  consumerCountry?: string; // consumerCountry,

  installerCompany?: string;

  brand?: number;

  registrationdate?: Date;

  fromRegistrationDate?: Date;

  toRegistrationDate?: Date;

  installationdate?: Date;

  fromInstallationDate?: Date;

  toInstallationDate?: Date;

  installationSiteCity?: string;

  installationPlace?: string;

  showReversedProjects?: boolean;

  productEan?: string;

  likeProductEan?: string;

  productName?: string;

  likeProductName?: string;

  serialnumber?: string;

  // eslint-disable-next-line camelcase
  erp_order_nr?: string;

  // eslint-disable-next-line camelcase
  erp_shipping_nr?: string;

  palletnumber?: string;

  ownerID?: number | null;

  proposal?: string;

  campaignCode?: string;

  limit?: number;

  offset?: number;

  sources?: RegistrationSource;
}

export const PROJECTS_REQUEST_FIELD_PATHS = getFieldPaths(
  new ProjectRequestObject(),
);
export type GroupedProductsByClassOfGoods = {
  productID: number;
  name: string;
  quantity: number;
  totalQuantity: number;
  currentQuantity: number;
  returnedQuantity: number;
  products: Array<ProjectProductJSON>;
};

export const ProjectTypes = {
  INSTALLATION: 0,
  DELIVERY: 1,
};

export type ProjectType = 0 | 1;

export type ProjectStatusValues = 0 | 2 | 3;

export enum ProjectStatus {
  CONCEPT = 0,
  REGISTERED = 2,
  INVALID = 3,
}

export type ProjectInstallationPlaceType = 0 | 1;

export const ProjectInstallationPlace = {
  GROUND_MOUNTED: 0,
  ON_ROOF_MOUNTED: 1,
};

export type TProjectInstallationType = 0 | 1;

export const ProjectInstallationType = {
  RESIDENTIAL: 0,
  COMMERCIAL: 1,
};

export const ProjectSystemSize = {
  LESS_THAN_25: '24',
  MORE_THAN_25: '26',
};

export type NewProjectJSON = {
  name: string;
  source: number;
  type: number;
  installationPlace: number;
  installationType: number;
  salesOrgBrand: number;
  systemSize?: number;
  installerId?: number;
};

export type UpdateNameAndInstallationPlaceJSON = {
  name: string;
  installationPlace: number;
  installationType: number;
  systemSize?: number;
};

export const PanelTreeItemTypesEnum = {
  EAN: 'ean',
  PALLET: 'palletnumber',
  SERIALNUMBER: 'serialnumber',
  ORDER: 'erp_order_nr',
  SHIPPING: 'erp_shipping_nr',
};

export type PanelTreeItemType =
  | 'ean'
  | 'palletnumber'
  | 'serialnumber'
  | 'erp_orderer'
  | 'erp_order_nr'
  | 'erp_shipping_nr';

export type BasePanelTreeItem<T, C> = {
  type: T;
  value: string;
  childrenSize: number;
  leafSize: number; // XXX: only for SERIAL Items,
  children: C;
  childrenRequest: PanelJSON;
  flasherData: number;
};

export interface PanelTreeItemInterface {
  type: PanelTreeItemType;
  value: string;
  childrenSize: number;
  leafSize: number; // XXX: only for SERIAL Items;
  children: Array<PanelTreeItemInterface>;
  childrenRequest: PanelJSON;
  flasherData: number;
}

export type PanelTreeItem = {
  type: PanelTreeItemType;
  value: string;
  childrenSize: number;
  leafSize: number; // XXX: only for SERIAL Items,
  children: Array<PanelTreeItem>;
  childrenRequest: PanelJSON;
  flasherData: number;
};

export const EMPTY_TREE_ITEM = {
  type: 'serialnumber',
  value: 'N/A',
  childrenSize: 0,
  leafSize: 0,
  children: [],
  childrenRequest: EMPTY_PANEL,
  flasherData: 0,
};

export type MaybePanelTreeItem = {
  type?: PanelTreeItemType;
  value?: string;
  childrenSize?: number;
  leafSize?: number; // XXX: only for SERIAL Items,
  children?: Array<PanelTreeItem>;
  childrenRequest?: MaybePanelJSON;
  flasherData?: number;
};

export type SerialPanelTreeItem = BasePanelTreeItem<'serialnumber', []>;

export type PalletPanelTreeItem = BasePanelTreeItem<
  'palletnumber',
  Array<SerialPanelTreeItem>
>;

export type EanPanelTreeItem = BasePanelTreeItem<
  'ean',
  Array<PalletPanelTreeItem>
>;

export type ShippingPanelTreeItem = BasePanelTreeItem<
  'erp_shipping_nr',
  Array<EanPanelTreeItem>
>;

export type OrderPanelTreeItem = BasePanelTreeItem<
  'erp_order_nr',
  Array<ShippingPanelTreeItem>
>;

export type PanelTreeItemTypes =
  | SerialPanelTreeItem
  | PalletPanelTreeItem
  | EanPanelTreeItem
  | ShippingPanelTreeItem
  | OrderPanelTreeItem;

export type PanelResultRow = {
  rowNr?: number;
  success?: boolean;
  error?: string;
  verifiedDatabase?: boolean;
  foundInDatabase?: boolean;
  product?: string;
  serialnumber?: string;
  palletnumber?: string;
  ean?: string;
  shippingnumber?: string;
  ordernumber?: string;
};

export type UploadPanelsResultJSON = {
  success: boolean;
  error: string;
  results: Array<PanelResultRow>;
  background: boolean;
};
// XXX: this is our own custom, not the API one =/ we do mapping internally to change
export type DeliveryRequestObject = {
  serialnumber?: string;
  palletnumber?: string;
  deliverynumber?: string;
  ordernumber?: string;
  salesOrgBrand?: number;
};

export type ProjectFileUploadRequest = {
  name: string;
  type: FileType;
  format: string;
  base64Data: string;
};

export type UploadFileResultJSON = {
  success: boolean;
  file: FileJSON | null;
  error: U.Nullable<string>;
  project: ProjectJSON | null;
};

export type ProjectStatusJSON = {
  valid: boolean;
  ableToComplete: boolean;
  completed: boolean;
  concept: boolean;
  warrantyYears: number | null;
};

export type ProjectActionJSON = {
  project: ProjectJSON;
};

export type ProjectsState = Record<
  number,
  {
    deleted: boolean;
    reversed: boolean;
    completed: boolean;
    accessDenied: boolean;
  }
>;

export type LoadProjectsResult = {
  pagination: Pagination<ProjectJSON>;
  projectsState: ProjectsState;
};

export const EMPTY_PROJECTS_RESULT = {
  pagination: {
    list: EMPTY_ARRAY,
    limit: 0,
    offset: 0,
    total: 0,
  },
  projectsState: {},
};

export type NewInstallerJSON = {
  erp: U.Nullable<string>;
  email: U.Nullable<string>;
} & InstallerJSON;

export type InstallerRequestFiltersJSON = {
  company?: U.Nullable<string>;
  street?: U.Nullable<string>;
  city?: U.Nullable<string>;
  state?: U.Nullable<string>;
  zipcode?: U.Nullable<string>;
  country?: U.Nullable<string>;
  salesOrgBrandID?: U.Nullable<SalesOrgBrand>;
  countries?: U.Nullable<string[]>;
};

export type InstallerRequestJSON = {
  limit: number;
  offset: number;
} & InstallerRequestFiltersJSON;

export type AddManualPanelResponse = {
  panelsTree: Array<OrderPanelTreeItem>;
  panels: Array<PanelResultRow>;
};

export const EMPTY_INSTALLER_JSON: NewInstallerJSON = {
  id: null,
  address: {
    ...EMPTY_ADDRESS_JSON,
  },
  phone: '',
  erp: '',
  email: '',
  participant: 0,
};

export type ProjectAndPanelsResult = {
  project: ProjectJSON;
  panels: Array<OrderPanelTreeItem>;
};

export type SeparatedPanels = {
  knownTree: Array<OrderPanelTreeItem>;
  notKnownTree: Array<OrderPanelTreeItem>;
  notKnownWithSerialTree: Array<OrderPanelTreeItem>;
  notKnownWithoutSerialTree: Array<OrderPanelTreeItem>;
  amount: {
    knownTree: {
      total: number;
    };
    notKnownTree: {
      total: number;
    };
    notKnownWithSerialTree: {
      total: number;
    };
    notKnownWithoutSerialTree: {
      total: number;
    };
  };
};

export type MultiUploadProjectJSON = {
  project: ProjectJSON;
  serialnumbers: Array<string>;
  panelType: string;
  panelWattClass: string;
  panelCount: number;
};

export type MultiUploadProjectEntryJSON = {
  serialnumbers: Array<string>;
  panelType: string;
  panelWattClass: string;
  panelCount: number;
};

export type MultiUploadProjectV2JSON = {
  project: ProjectJSON;
  entry: MultiUploadProjectEntryJSON[];
  sendCertificate: boolean;
  sendCertificateToInstaller: boolean;
};

export const CampaignScoreTypes = {
  KWP: 'KWP',
  NUMBER_OF_REGISTRATIONS: 'NUMBER_OF_REGISTRATIONS',
};

export type CampaignScoreType = 'KWP' | 'NUMBER_OF_REGISTRATIONS';

// maps campaign code to value
export type ProjectCampaignScore = Record<string, number>;

export type RankedCampaignScoresByInstallerRequestJSON = {
  campaignCode: string;
  countries: Array<CountryCode>;
  limit: number;
};

export type RankedCampaignRegistrationsJSON = {
  country: CountryCode;
  id: number;
  amount: number;
};
