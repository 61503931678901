const sonyNamespace = 'sony';
const softBundleNamespace = 'softBundle';

const SOFT_BUNDLE = {
  QUESTION: {
    key: `${sonyNamespace}.${softBundleNamespace}.question`,
    message: 'Did you sell this product as a bundle?',
  },
  QUESTION_2: {
    key: `${sonyNamespace}.${softBundleNamespace}.question2`,
    message: 'Did you sell this product as a bundle?',
  },
  YES: {
    key: `${sonyNamespace}.${softBundleNamespace}.yes`,
    message: 'Yes',
  },
  NO: {
    key: `${sonyNamespace}.${softBundleNamespace}.no`,
    message: 'No',
  },
  REDIRECT_MODAL: {
    TITLE: {
      key: `${sonyNamespace}.${softBundleNamespace}.redirectModalTitle`,
      message: 'Please, check this information',
    },
    CONTENT: {
      key: `${sonyNamespace}.${softBundleNamespace}.redirectModalContent`,
      message:
        'Your product selection gives you the right to register through the G Master Bundle campaign, do you want to redirect to it?',
    },
  },
};

export const PRODUCT_SELECTION_CUSTOM_TEXT = {
  HEADER: {
    key: `${sonyNamespace}.productSelection.headerCustomText`,
    message: 'Bitte registrieren Sie zuerst die Kamera',
  },
};

export const ALTERNATIVE_PRODUCT_SEARCH = {
  SEARCH_DESCRIPTION: {
    key: `${sonyNamespace}.alternativeProductSearch.searchDescription`,
    message:
      'Wenn Sie die Kamera zusammen mit einer der untenstehenden Accessoires verkauft haben, wählen Sie hier das entsprechende Produkt aus.',
  },
  ACESSORIES_DESCRIPTION: {
    key: `${sonyNamespace}.alternativeProductSearch.accessoriesDescription`,
    message:
      'Sollten Sie mehr als ein Accessoire verkauft haben, klicken Sie bitte erneut auf Produkt hinzufügen. Sie können bis zu 3 Accessoires registrieren.',
  },
};

const I18N = {
  SOFT_BUNDLE,
  PRODUCT_SELECTION_CUSTOM_TEXT,
  ALTERNATIVE_PRODUCT_SEARCH,
};

export default I18N;
