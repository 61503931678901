import REC_I18N_3 from '@mmw/constants-i18n';
import REC_I18N_2 from '@mmw/constants-i18n-solar';
import contextualConfig from '@mmw/contextual-config';
import logger from '@mmw/logging-logger';
import { ResourceKeyToMessage } from '@mmw/scripts-retail-client-rec-translate-i18n/traverse';
import * as MANU_REC_I18N from '@rec/manufacturer-client-web-i18n';
import I18N from '@rec/rec-select/i18n';
import REC_I18N from '@rec/retail-client-web-i18n';
import {
  COMMON,
  CONSUMER_SEARCH,
  EDIT_CONSUMER,
  NEW_CONSUMER,
} from '@retail/constants-i18n';
import { eachAsync } from '@shared-utils/array';
import { createStoreModule } from '@zustand-store/core';
import { parallelLimit } from 'async';
import {
  concat,
  each,
  filter,
  find,
  findIndex,
  forOwn,
  get,
  head,
  isArray,
  isEmpty,
  isObject,
  map,
  noop,
  take,
  uniqBy,
} from 'lodash';
import size from 'lodash/size';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import without from 'lodash/without';
import { StateCreator } from 'zustand/esm';

import { resourceService } from './api';
import { deepl } from './deepl';

export const USED_RESOURCES = {
  ...CONSUMER_SEARCH,
  ...COMMON,
  ...NEW_CONSUMER,
  ...EDIT_CONSUMER,
  ...I18N,
  ...REC_I18N,
  ...REC_I18N_2,
  ...REC_I18N_3,
  ...MANU_REC_I18N,
};

const traverse = (mainObj: Record<string, any>): ResourceKeyToMessage => {
  const ALL_KEY_MESSAGE_MAPPINGS: ResourceKeyToMessage = {};
  const addToMappings = (obj: Record<string, any>) => {
    const { key, message } = obj;
    if (!key) {
      return;
    }
    if (ALL_KEY_MESSAGE_MAPPINGS[key]) {
      return;
    }
    ALL_KEY_MESSAGE_MAPPINGS[key] = message;
  };

  const hasI18nShape = (obj: Record<string, any>) =>
    !isEmpty(obj.key) && !isEmpty(obj.message);

  const recursiveTraverse = (obj: Record<string, any>) => {
    if (hasI18nShape(obj)) {
      addToMappings(obj);
    }
    forOwn(obj, value => {
      if (isObject(value)) {
        recursiveTraverse(value);
      } else if (isArray(value)) {
        value.forEach(recursiveTraverse);
      }
    });
  };
  recursiveTraverse(mainObj);
  return ALL_KEY_MESSAGE_MAPPINGS;
};

const RESOURCES_ARRAY = map(traverse(USED_RESOURCES), (message, key) => ({
  key,
  message,
}));

export type XLSContentRow = {
  key: string;
  message: string;
  bundleName?: string;
  hasOnAnotherBundle?: {
    key: string;
    possibleTranslation: string;
    language: string;
  }[];
} & Record<string, string>;

type XLSContent = XLSContentRow[];

type UsedResource = Record<'key' | 'message', string>;
class AdminModeState {
  adminMode = false;

  adminModeIDs = false;

  printIds: Record<string, number> = {};

  adminModeShowKeys = false;

  setAdminMode: (mode: boolean) => void = noop;

  loading = false;

  loadingKeys: string[] = [];

  resourcesAtTime = 20;

  usedResources: UsedResource[] = RESOURCES_ARRAY;

  completedKeys: string[] = [];

  showCompletedKeys = false;

  availableLanguages: string[] = [];

  xlsContent: XLSContent = [];

  setXlsContent: (content: XLSContent) => void = noop;

  findTranslations: () => void = noop;

  setUsedResources: (content: Record<string, any>[]) => void = noop;

  approveEntireRoll: (
    items: {
      id: number | null;
      bundleName: string;
      language: string | null;
      resource: string;
      resourcekey: string;
    }[],
  ) => Promise<void>;

  saveResource: (params: {
    id: number | null;
    bundleName: string;
    language: string | null;
    resource: string;
    resourcekey: string;
  }) => Promise<void>;

  createDefaultResource: (params: {
    bundleName: string;
    resource: string;
    resourcekey: string;
  }) => Promise<void>;
}

export const getHasAllTranslations = (row: XLSContentRow) =>
  !some(
    map(
      contextualConfig.application.supportedLanguages,
      language => row[language],
    ),
    message => !!message && message === 'NO_RESOURCE_FOUND',
  );

const initializer: StateCreator<AdminModeState> = (setState, getState) => {
  const state = new AdminModeState();
  state.usedResources = RESOURCES_ARRAY;
  state.setAdminMode = mode => {
    setState({ adminMode: mode });
  };
  state.setXlsContent = content => {
    setState({ xlsContent: content });
  };

  state.findTranslations = async () => {
    const resourcesUsed = uniqBy(getState().usedResources, item => item.key);

    setState({ loading: true });
    const newContent: UsedResource[] & XLSContentRow[] = [];
    const tasks = map(
      take(resourcesUsed, getState().resourcesAtTime),
      resource => async callback => {
        const resourceCompleted = find(getState().xlsContent, {
          key: resource.key,
        });
        if (resourceCompleted && getHasAllTranslations(resource)) {
          newContent.push(resourceCompleted);
          callback(null, resourceCompleted);
        } else {
          newContent.push(resource);
          const itemIndex = findIndex(newContent, { key: resource.key });
          try {
            await eachAsync(
              contextualConfig.application.supportedLanguages,
              async language => {
                const languageToRequest =
                  language === 'en' ? undefined : language;

                const resourceByLanguage = await resourceService.getResource(
                  languageToRequest,
                  resource.key,
                  contextualConfig.application.defaultResourceBundleName,
                  contextualConfig.application.secondaryResourceBundleName,
                );
                const foundedResource = head(resourceByLanguage.list);
                let possibleTranslation;
                if (!get(foundedResource, 'resource')) {
                  possibleTranslation = await resourceService.tryTranslate(
                    resource.message,
                    language,
                  );

                  if (possibleTranslation) {
                    newContent[itemIndex].hasOnAnotherBundle = concat(
                      newContent[itemIndex].hasOnAnotherBundle || [],
                      {
                        key: resource.key,
                        possibleTranslation,
                        language,
                      },
                    );
                  } else {
                    possibleTranslation = await deepl(
                      resource.message,
                      language,
                    );
                  }
                }
                const key = language === 'en' ? 'message' : language;
                newContent[itemIndex][key] =
                  get(foundedResource, 'resource') ||
                  possibleTranslation ||
                  'NO_RESOURCE_FOUND';

                newContent[itemIndex].bundleName =
                  get(head(resourceByLanguage.list), 'bundleName') ||
                  newContent[itemIndex].bundleName;
              },
            );
          } catch (e) {
            logger.error(e);
          }
          if (
            getHasAllTranslations(newContent[itemIndex]) &&
            isEmpty(newContent[itemIndex].hasOnAnotherBundle)
          ) {
            setState({
              completedKeys: [
                ...getState().completedKeys,
                newContent[itemIndex].key,
              ],
            });
          }
          callback(null, newContent[itemIndex]);
        }
      },
    );
    parallelLimit(tasks, 2, (err, results) => {
      if (!err) {
        // @ts-ignore
        setState({ xlsContent: results });
        setState({ loading: false });
        return results;
      }
      return err;
    });
  };
  state.saveResource = async ({
    id,
    bundleName,
    language,
    resource,
    resourcekey,
  }) => {
    setState({
      loadingKeys: uniq(
        concat(getState().loadingKeys, `${resourcekey}-${language}`),
      ),
    });
    await resourceService.saveResource(
      id,
      bundleName,
      language,
      resource,
      resourcekey,
    );
    const xlsContent = map(getState().xlsContent, item => {
      if (item.hasOnAnotherBundle && item.key === resourcekey) {
        return {
          ...item,
          hasOnAnotherBundle: filter(
            item.hasOnAnotherBundle,
            previousPossibleTranslation =>
              previousPossibleTranslation.language !== language,
          ),
        };
      }
      if (language) {
        return { ...item, [language]: resource };
      }
      return item;
    });
    setState({
      loadingKeys: uniq(
        without(getState().loadingKeys, `${resourcekey}-${language}`),
      ), // @ts-ignore
      xlsContent,
    });
  };
  state.createDefaultResource = async ({
    bundleName,
    resource,
    resourcekey,
  }) => {
    setState({
      loadingKeys: uniq(concat(getState().loadingKeys, resourcekey)),
    });
    const resourceData = await resourceService.saveResource(
      null,
      bundleName,
      null,
      resource,
      resourcekey,
    );
    const xlsContent = map(getState().xlsContent, item => {
      if (item.key === resourcekey) {
        return {
          ...item,
          bundleName: resourceData.bundleName,
        };
      }
      return item;
    });
    setState({
      loadingKeys: uniq(without(getState().loadingKeys, resourcekey)),
      // @ts-ignore
      xlsContent,
    });
  };
  state.approveEntireRoll = async items => {
    await eachAsync(items, getState().saveResource);
  };
  return state;
};

export const useAdminMode = createStoreModule({
  name: `i18n-admin-mode-${contextualConfig.application.applicationId}`,
  initializer,
  disablePersist: true,
});

useAdminMode.subscribe(state => {
  let i = 0;
  if (size(values(state.printIds)) !== size(state.usedResources)) {
    const printIds = {};
    each(state.usedResources, item => {
      printIds[item.key] = i + 1;
      i += 1;
    });
    useAdminMode.setState({
      printIds,
    });
  }
});

export function useAdminModeValue() {
  return useAdminMode(state => state.adminMode);
}
export function useAdminModeIDsValue() {
  return useAdminMode(state => state.adminModeIDs);
}
export function useAdminModePrintID(key: string) {
  const { printIds } = useAdminMode();
  return printIds[key];
}
export function useAdminModeShowKeysValue() {
  return useAdminMode(state => state.adminModeShowKeys);
}
export function useAdminModeLoading() {
  return useAdminMode(state => state.loading);
}

export function useSetAdminModeValue() {
  return useAdminMode(state => state.setAdminMode);
}

export function useFindTranslations() {
  return useAdminMode(state => state.findTranslations);
}

export function useSaveResource() {
  return useAdminMode(state => state.saveResource);
}
export function useCreateDefaultResource() {
  return useAdminMode(state => state.createDefaultResource);
}
