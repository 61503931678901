// import UI from '@ui-system/ui';
import { Container } from '@ui-system/common-container';
import { Icon } from '@ui-system/common-icon';
import React from 'react';
import { F } from 'ts-toolbelt';

import { arrowLeftStyle, arrowRightStyle } from './theme';

type Props = {
  onPreviousClick?: F.Function;
  onNextClick?: F.Function;
};

const DatePickerNavbar: React.FC<Props> = ({
  onPreviousClick,
  onNextClick,
}: Props) => (
  <>
    <Container
      style={arrowLeftStyle}
      bg="primary"
      align="center"
      justify="center"
      onClick={() => {
        if (onPreviousClick) {
          onPreviousClick();
        }
      }}
    >
      <Icon name="previous" size={18} />
    </Container>
    <Container
      style={arrowRightStyle}
      bg="primary"
      align="center"
      justify="center"
      onClick={() => {
        if (onNextClick) {
          onNextClick();
        }
      }}
    >
      <Icon name="next" size={18} />
    </Container>
  </>
);

export default DatePickerNavbar;
