const namespace = 'RECProPortal';
const RECCustomerProgramsNamespace = 'RECCustomerPrograms';
const RECProPortalBenefitsNamespace = 'RECProPortalBenefits';
const RECHowToQualifyNamespace = 'RECHowToQualifyNamespace';

const REC_PRO_PORTAL_HOME = {
  LOGIN: {
    COMBINATION_IS_WRONG: {
      key: `${namespace}.login.combinationIsWrong`,
      message: 'Your username or password is wrong',
    },
    PLEASE_TRY_AGAIN: {
      key: `${namespace}.login.pleaseTryAgain`,
      message: 'Please try again',
    },
    FIRST_ACCESS_MESSAGE: {
      key: `${namespace}.login.firstAccessMessage`,
      message:
        'If this is your first access to the ProPortal 2.0, please click here to create a new password.',
    },
    EMAIL_FEEDBACK: {
      key: `${namespace}.login.emailFeedback`,
      message:
        'Thank you. If your username exists you’ll receive an email with further instructions.',
    },
  },
  TITLE: {
    key: `${namespace}.title`,
    message: `REC <strong>ProPortal</strong>`,
  },
  WELCOME: {
    key: `${namespace}.welcomeToTheRECProPortal`,
    message: `Welcome to the <strong>REC ProPortal</strong>`,
  },
  WELCOME_ADMIN: {
    key: `${namespace}.welcomeToTheRECProPortalAdmin`,
    message: `Welcome to ProPortal for Administrators`,
  },
  WELCOME_DESCRIPTION: {
    key: `${namespace}.welcomeDescription`,
    message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam accumsan, ex non ullamcorper dictum, ex nibh sodales lectus.`,
  },
  LOGIN_DESCRIPTION: {
    key: `${namespace}.loginDescription`,
    message: `<strong>Differentiate</strong> your business, win <strong>more customers</strong> and <strong>grow revenue.</strong>`,
  },
  REC_CUSTOMER_PROGRAMS: {
    BUTTON: {
      key: `${namespace}.${RECCustomerProgramsNamespace}.button`,
      message: 'Find out more',
    },
    TITLE: {
      key: `${namespace}.${RECCustomerProgramsNamespace}.title`,
      message: `Introducing the <br /> <strong>REC Customer Programs</strong>`,
    },
    DESCRIPTION: {
      key: `${namespace}.${RECCustomerProgramsNamespace}.programsDescription`,
      message: `At REC, we believe we can create more value for customers through alliances than by doing it alone. By partnering with REC - either as an REC Certified Solar Professional installer or as an REC Partner distributor - you gain access to knowledge an insights to help you accelerate you business.`,
    },
    REC_PARTNER_DISTRIBUTORS: {
      TITLE: {
        key: `${namespace}.${RECCustomerProgramsNamespace}.distributorsTitle`,
        message: `REC <strong>Partner Distributors</strong>`,
      },
      DESCRIPTION: {
        key: `${namespace}.${RECCustomerProgramsNamespace}.distributorsDescription`,
        message: `Our global network of distribution partners are here to supply you with high-quality REC panels, easily and efficiently. Select your region and country to see which of our Partners distribute into your country.`,
      },
    },
    REC_CERTIFIED_INSTALLERS: {
      TITLE: {
        key: `${namespace}.${RECCustomerProgramsNamespace}.installersTitle`,
        message: `REC <strong>Certified Installers</strong>`,
      },
      DESCRIPTION: {
        key: `${namespace}.${RECCustomerProgramsNamespace}.installersDescription`,
        message: `Through this unique installer certification program, we ensure solar installers are equipped with the knowhow and best practices to install REC panels, which provides end customers with an assurance that in addition to high-quality panels, they will receive a high-quality installation.`,
      },
    },
    CALL_TO_ACTION: {
      TITLE: {
        key: `${namespace}.${RECCustomerProgramsNamespace}.callToActionTitle`,
        message: `Want you to become a certified REC installer?`,
      },
      DESCRIPTION: {
        key: `${namespace}.${RECCustomerProgramsNamespace}.signUpDescription`,
        message: `<strong>Join our network</strong><br /><br />As a trusted partner for solar installers, REC is constantly seeking ways to simplify your live and installation efficiency.`,
      },
    },
  },
  REC_PRO_PORTAL_BENEFITS: {
    TITLE: {
      key: `${namespace}.${RECProPortalBenefitsNamespace}.title`,
      message: `REC <strong>ProPortal</strong>`,
    },
    ENJOY_BENEFITS: {
      key: `${namespace}.${RECProPortalBenefitsNamespace}.enjoyBenefits`,
      message: `Enjoy the benefits`,
    },
    DESCRIPTION: {
      key: `${namespace}.${RECProPortalBenefitsNamespace}.benefitsDescription`,
      message: `As a member of the REC Certified Solar Professional Program, you benefit from unique training to equip you with all the skills you need for your solar installation business. You will also receive great marketing support to drive your sales with REC solar panels, giving you a kickstart to success.`,
    },
    BENEFITS: {
      WARRANTY_TERMS: {
        TITLE: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.warrantyTermsTitle`,
          message: `Offer exclusive warranty terms`,
        },
        DESCRIPTION: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.warrantyTermsDescription`,
          message: `Get a jump on other installers. REC Certified Solar Professionals can exclusively offer the REC ProTrust Warranty, which gives up to 25 years coverage on product defects, performance and labor (conditions apply). Registering your installation is simple: use the REC SunSnap app or in the REC ProPortal.`,
        },
      },
      INSTALLER_LOCATOR: {
        TITLE: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.installerLocatorTitle`,
          message: `Installer locator and management team`,
        },
        DESCRIPTION: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.installerLocatorDescription`,
          message: `Getting your business listed on one of the most-visited pages of REC's website – the Installer Locator – provides added visibility and helps end customers easily find and contact you.`,
        },
      },
      MARKETING_TOOLS: {
        TITLE: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.marketingToolsTitle`,
          message: `Take advantage of marketing tools`,
        },
        DESCRIPTION: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.marketingToolsDescription`,
          message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
        },
      },
      PROGRAM_PARTNER: {
        TITLE: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.programPartnerTitle`,
          message: `Discover the Program Partner`,
        },
        DESCRIPTION: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.programPartnerDescription`,
          message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
        },
      },
      MARKETING_STORES: {
        TITLE: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.marketingStoresTitle`,
          message: `Use your RECoins at REC Marketing Stores`,
        },
        DESCRIPTION: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.marketingStoresDescription`,
          message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
        },
      },
      REC_CAMPAIGNS: {
        TITLE: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.recCampaignsTitle`,
          message: `Partners REC Campaigns. Enter to win!`,
        },
        DESCRIPTION: {
          key: `${namespace}.${RECProPortalBenefitsNamespace}.recCampaignsDescription`,
          message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
        },
      },
    },
  },
  HOW_TO_QUALIFY: {
    TITLE: {
      key: `${namespace}.${RECHowToQualifyNamespace}.howToQualifyTitle`,
      message: `How do I qualify?`,
    },
    DESCRIPTION: {
      key: `${namespace}.${RECHowToQualifyNamespace}.howToQualifyDescription`,
      message: `The REC Certified Solar Professional Program is for installer with at least two years experience who can demonstrate technical expertise - possibly through national accreditation. The steps after that are easy:`,
    },
    FIRST_STEP: {
      key: `${namespace}.${RECHowToQualifyNamespace}.howToQualifyFirstStep`,
      message: `Attend an REC Certified Solar Professional training session`,
    },
    SECOND_STEP: {
      key: `${namespace}.${RECHowToQualifyNamespace}.howToQualifySecondStep`,
      message: `Sign the REC Certified Solar Professional Program terms and conditions`,
    },
  },
  WANT_YOU_TO_BECOME_A_CERTIFIED_REC_INSTALLER: {
    key: 'wantYouToBecomeACertifiedRecInstaller',
    message: 'Want you to become a certified REC installer?',
  },
};

export default REC_PRO_PORTAL_HOME;
