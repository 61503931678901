import UI from '@ui-system/ui';
import {
  SnackbarKey,
  SnackbarProvider as MUISnackbarProvider,
  useSnackbar,
} from 'notistack';
import { useCallback } from 'react';

interface CloseBtnProps {
  snackbarKey: SnackbarKey;
}

const CloseIcon: React.FC<CloseBtnProps> = ({ snackbarKey }: CloseBtnProps) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <UI.Icon
      name="close"
      size={15}
      onClick={() => closeSnackbar(snackbarKey)}
    />
  );
};

interface ProviderProps {
  maxSnacks?: number;
}

const ToastNotificationsProvider: React.FC<
  React.PropsWithChildren<ProviderProps>
> = ({ children, maxSnacks = 3 }: React.PropsWithChildren<ProviderProps>) => {
  const renderCloseBtn = useCallback(
    snackbarKey => <CloseIcon snackbarKey={snackbarKey} />,
    [],
  );
  return (
    <MUISnackbarProvider action={renderCloseBtn} maxSnack={maxSnacks}>
      {children}
    </MUISnackbarProvider>
  );
};

export default ToastNotificationsProvider;
