import colors from './colors/colors';
import { Theme, ThemeMode } from './interfaces';
import shadows from './shadows';
import spacing from './spacing';
import typography from './typography/typography';

export const BASE_THEME: Theme = {
  mode: ThemeMode.LIGHT,
  colors,
  shadows,
  spacing,
  typography,
};
