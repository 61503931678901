import {
  FormControl,
  FormControlLabel,
  Switch as MuiSwitch,
} from '@material-ui/core';
import componentModifiers from '@ui-system/default-modifiers/form/switch';
import defaultProps from '@ui-system/default-props/form/switch';
import { useSwitchStyle } from '@ui-system/default-styles';
import { SwitchProps, SwitchType } from '@ui-system/interfaces-form';
import Typography from '@ui-system/material-typography';
import React from 'react';

export const Switch: SwitchType = ({
  checked,
  onChange,
  error,
  disabled,
  style,
  labelI18n,
  labelStyle,
  label,
  value,
  color,
  ...props
}: SwitchProps) => {
  const finalStyle = useSwitchStyle(style, '', componentModifiers);
  return (
    <FormControl
      required
      error={error}
      component="fieldset"
      disabled={disabled}
      style={finalStyle}
    >
      <FormControlLabel
        style={labelStyle}
        checked={checked}
        onChange={onChange}
        label={
          (labelI18n && <Typography i18n={labelI18n} />) || (
            <Typography>{label}</Typography>
          )
        }
        control={<MuiSwitch value={value} color={color} {...props} />}
      />
    </FormControl>
  );
};

Switch.defaultProps = defaultProps;

export default Switch;
