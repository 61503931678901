import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getFormCurrentValuesSelector } from '../../store/stateSelector';
import useFormId from '../useFormId';

function useFormValues<T>(): T {
  const formId = useFormId();
  const selector = useCallback(
    state => getFormCurrentValuesSelector(formId)(state),
    [formId],
  );

  return useSelector(selector);
}

export default useFormValues;
