import { U } from 'ts-toolbelt';

const limitString = (
  fullStr = '',
  limit: U.Nullable<number> = null,
  separator = '...',
  hideLastChars = false,
): string => {
  if (fullStr == null) return fullStr;
  if (fullStr.length <= limit || !limit) return fullStr;
  const sepLen = separator.length;
  const charsToShow = limit - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  if (hideLastChars) {
    return fullStr.substring(0, charsToShow) + separator;
  }

  return (
    fullStr.substring(0, frontChars) +
    separator +
    fullStr.substring(fullStr.length - backChars)
  );
};

export default limitString;
