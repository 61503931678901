import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getFieldValueByFieldPathSelector } from '../../store/stateSelector';
import { FieldPath } from '../../types';
import { useWatchField } from '../internal';
import useFormId from '../useFormId';

function useValue<T>(fieldPath: FieldPath, defaultValue?: T): T {
  useWatchField(fieldPath);
  const formId = useFormId();
  const selector = useCallback(
    state =>
      getFieldValueByFieldPathSelector(formId, fieldPath, defaultValue)(state),
    [formId, fieldPath, defaultValue],
  );
  return useSelector(selector);
}

export default useValue;
