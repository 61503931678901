import { ContainerType } from '@ui-system/interfaces-container';
import { isReactNative } from 'is-react-native';
import React, {
  LazyExoticComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import useFastFormProviderContext from '../hooks/useFastFormProviderContext';
import { getFormCurrentValuesSelector } from '../store/stateSelector';
import { FormId } from '../types';

function useCurrentValues(formId: FormId) {
  const submittedValuesSelector = useCallback(
    state => getFormCurrentValuesSelector(formId)(state),
    [formId],
  );
  return useSelector(submittedValuesSelector);
}

export const DevJSONView = ({ children }: React.PropsWithChildren<any>) => {
  const [Container, setContainer] =
    useState<LazyExoticComponent<ContainerType> | null>(null);

  const [devForm, setDevForm] = useState(false);

  const context = useFastFormProviderContext();
  const currentValues = useCurrentValues(context.formId);

  useEffect(() => {
    (async () => {
      const LazyContainer = React.lazy(
        () => import('@ui-system/common-container'),
      );
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { useDevMode } = require('@dev/core/dev-mode');
      useDevMode?.subscribe(state => {
        setDevForm(state.devForm);
      });
      setContainer(LazyContainer);
    })();
  }, []);
  if (!devForm) return children;
  if (!Container) return children;
  return (
    <Container
      border="1px solid blue"
      direction="column"
      id="dev-json-view"
      onClick={() => {
        if (!isReactNative() && window && window.dev) {
          window.dev({ currentValues, ...context });
        }
      }}
    >
      <Container>{context.formId} SEE JSON</Container>
      {children}
    </Container>
  );
};
