const namespace = 'massProjectUpload';
const massProjectUploadFields = `${namespace}.fields`;

const MASS_PROJECT_UPLOAD = {
  REGISTER: {
    key: `${namespace}.registerWithSpreadsheetUpload`,
    message: 'Register with spreadsheet upload',
  },
};

export const MASS_PROJECT_UPLOAD_FIELDS = {
  OWNER_FIRSTNAME: {
    key: `${massProjectUploadFields}.ownerFirstname`,
    message: 'Owner first name',
  },
  OWNER_LASTNAME: {
    key: `${massProjectUploadFields}.ownerLastname`,
    message: 'Owner last name',
  },
  OWNER_COMPANY: {
    key: `${massProjectUploadFields}.ownerCompany`,
    message: 'Owner company',
  },
  OWNER_EMAIL: {
    key: `${massProjectUploadFields}.ownerEmail`,
    message: 'Owner email',
  },
  OWNER_STREET: {
    key: `${massProjectUploadFields}.ownerStreet`,
    message: 'Owner street',
  },
  OWNER_STREET_NO: {
    key: `${massProjectUploadFields}.ownerStreetNo`,
    message: 'Owner street number',
  },
  OWNER_ADDRESS_LINE_1: {
    key: `${massProjectUploadFields}.ownerAddressLine1`,
    message: 'Owner address',
  },
  OWNER_ADDRESS_LINE_2: {
    key: `${massProjectUploadFields}.ownerAddressLine2`,
    message: 'Owner address line 2',
  },
  OWNER_ZIPCODE: {
    key: `${massProjectUploadFields}.ownerZipcode`,
    message: 'Owner zipcode',
  },
  OWNER_CITY: {
    key: `${massProjectUploadFields}.ownerCity`,
    message: 'Owner city',
  },
  OWNER_STATE: {
    key: `${massProjectUploadFields}.ownerState`,
    message: 'Owner state',
  },
  OWNER_COUNTRY: {
    key: `${massProjectUploadFields}.ownerCountry`,
    message: 'Owner country',
  },
  CERTIFICATE_SEND: {
    key: `${massProjectUploadFields}.certificateSend`,
    message: 'Certificate send',
  },
  CERTIFICATE_SEND_TO_INSTALLER: {
    key: `${massProjectUploadFields}.certificateSendToInstaller`,
    message: 'Certificate send to installer',
  },
  OWNER_SYSTEM_LOCATION: {
    key: `${massProjectUploadFields}.ownerSystemLocation`,
    message: 'Owner system location',
  },
  SYSTEM_FIRSTNAME: {
    key: `${massProjectUploadFields}.systemFirstname`,
    message: 'System first name',
  },
  SYSTEM_LASTNAME: {
    key: `${massProjectUploadFields}.systemLastname`,
    message: 'System last name',
  },
  SYSTEM_EMAIL: {
    key: `${massProjectUploadFields}.systemEmail`,
    message: 'System email',
  },
  SYSTEM_STREET: {
    key: `${massProjectUploadFields}.systemStreet`,
    message: 'System street',
  },
  SYSTEM_STREET_NO: {
    key: `${massProjectUploadFields}.systemStreetNo`,
    message: 'System street number',
  },
  SYSTEM_ADDRESS_LINE_1: {
    key: `${massProjectUploadFields}.systemAddressLine1`,
    message: 'System address',
  },
  SYSTEM_ADDRESS_LINE_2: {
    key: `${massProjectUploadFields}.systemAddressLine2`,
    message: 'System address line 2',
  },
  SYSTEM_ZIPCODE: {
    key: `${massProjectUploadFields}.systemZipcode`,
    message: 'System zipcode',
  },
  SYSTEM_CITY: {
    key: `${massProjectUploadFields}.systemCity`,
    message: 'System city',
  },
  SYSTEM_STATE: {
    key: `${massProjectUploadFields}.systemState`,
    message: 'System state',
  },
  SYSTEM_COUNTRY: {
    key: `${massProjectUploadFields}.systemCountry`,
    message: 'System country',
  },
  SYSTEM_PANEL_COUNT: {
    key: `${massProjectUploadFields}.systemPanelCount`,
    message: 'System panel count',
  },
  SYSTEM_PANEL_TYPE: {
    key: `${massProjectUploadFields}.systemPanelType`,
    message: 'System panel type',
  },
  SYSTEM_PANEL_WATTCLASS: {
    key: `${massProjectUploadFields}.systemPanelWattclass`,
    message: 'System watt class',
  },
  SYSTEM_OPERATION_START: {
    key: `${massProjectUploadFields}.systemOperationStart`,
    message: 'System operation start',
  },
  SYSTEM_PANEL_SERIALS: {
    key: `${massProjectUploadFields}.systemPanelSerias`,
    message: 'System panel serials',
  },
  WARNING_TEMPLATE_UTF8: {
    key: `${massProjectUploadFields}.warningTemplateUtf8`,
    message:
      'Please always use UTF-8 encoding when saving this file to ensure the correct storage of special characters.',
  },
  SYSTEM_INSTALLATION_TYPE: {
    key: `${massProjectUploadFields}.systemInstallationType`,
    message: 'System Installation Type',
  },
};

const I18N = {
  MASS_PROJECT_UPLOAD,
  MASS_PROJECT_UPLOAD_FIELDS,
};

export default I18N;
