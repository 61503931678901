import { Container } from '@ui-system/common-container';
import defaultProps from '@ui-system/default-props/rating';
import { RatingProps, RatingType } from '@ui-system/interfaces-rating';
import UI from '@ui-system/ui';
import { map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { U } from 'ts-toolbelt';

// import Animated, { add, Easing, interpolate } from 'react-native-reanimated';
// import { useTransition } from 'react-native-redash/lib/module/v1';

// const NonInteractiveStars =

const Rating: RatingType = ({
  onChange,
  maxRating,
  iconsSize,
  initialValue = 0,
  disabled,
  color,
  error,
  errorColor,
  p,
}: RatingProps) => {
  const [currentValue, setValue] = useState<U.Nullable<number>>(initialValue);
  const ratingPositions = Array.from(new Array(maxRating), (v, i) => i + 1);
  // const size = new Animated.Value(iconsSize);
  // const transition = useTransition(error, {
  //   duration: 600,
  //   easing: Easing.inOut(Easing.ease),
  // });
  const changeRating = useCallback(
    r => {
      if (disabled) return;
      setValue(r);
      if (onChange) onChange(r);
    },
    [disabled, onChange],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Container f={1} p={p == null ? 2 : p} opacity={disabled ? 0.3 : 1}>
      {map(ratingPositions, item => (
        <UI.Icon
          key={item}
          name={
            currentValue && item <= currentValue ? 'star-solid' : 'star-regular'
          }
          size={iconsSize}
          color={error ? errorColor : color}
          onClick={disabled ? null : () => changeRating(item)}
        />
      ))}
    </Container>
  );
};

Rating.defaultProps = defaultProps;

export default Rating;
