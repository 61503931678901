import { setConfig } from '@mmw/contextual-config';
import { getEnvironment } from '@mmw/environment';
import { enable as logEnable } from '@mmw/logging-logger';
import registerBrowserConsoleLogger from '@mmw/logging-logger/registerBrowserConsoleLogger';

import contextualConfig from './contextualConfig';

setConfig(() => contextualConfig);

if (getEnvironment() === 'development') {
  // logEnable('mmw:*');
  logEnable(
    'mmw:*,-mmw:api:common*,-mmw:common*,-mmw:redux*,-mmw:components:i18n-provider,-mmw:services:auth-api:token-parser',
  );
  registerBrowserConsoleLogger();
} else {
  logEnable(
    'mmw:*,-mmw:api:common*,-mmw:common*,-mmw:redux*,-mmw:components:i18n-provider,-mmw:services:auth-api:token-parser',
  );
}
