import componentModifiers from '@ui-system/default-modifiers/form/input';
import { InputProps } from '@ui-system/interfaces-form';

const defaultProps: Partial<InputProps> = {
  textAlign: 'left',
  modifiers: '',
  componentModifiers,
  type: 'text',
};

export default defaultProps;
