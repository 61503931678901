import { Style } from '@ui-system/interfaces/types';
import { ContainerProps } from '@ui-system/interfaces-container';
import { nanoid } from 'nanoid';
import React from 'react';

export type ScrollProps = {
  style?: Style;
  height: number | string;
  maxHeight: number | string;
  width: number | string;
  maxWidth: number | string;
  children?: React.ReactNode;
} & Partial<ContainerProps>;

export type NativeScrollProps = {
  // placeholderStyle?: Record<string, any>;
  // PlaceholderContent?: React.ReactElement;
  containerStyle?: Record<string, any>;
  children?: React.ReactNode;
  transition?: boolean;
  onLoad?: (e: any) => void;
} & Partial<ScrollProps>;

export type ScrollType = React.FC<ScrollProps>;

export const SCROLL_DEFAULT_PROPS: Partial<ScrollProps> = {
  height: 100,
  width: 100,
};

export const SCROLL_UUID = nanoid();
