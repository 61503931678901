interface WalletConversionValueObject {
  currency: string;
  multiplier: number;
  toWalletName: string;
}

export enum WattPointErrorReason {
  PRODUCT_DOES_NOT_PARTICIPATE = 'PRODUCT_DOES_NOT_PARTICIPATE',
  REGISTRATION_DATE_INVALID = 'REGISTRATION_DATE_INVALID',
  INSTALLATION_DATE_INVALID = 'INSTALLATION_DATE_INVALID',
  INSTALLER_COUNTRY_INVALID = 'INSTALLER_COUNTRY_INVALID',
  CAMPAIGN_ITEM_WITH_VALIDATION_ERRORS = 'CAMPAIGN_ITEM_WITH_VALIDATION_ERRORS',
  UNKNOWN = 'UNKNOWN',
  CAMPAIGN_ITEM_CANCELLED_BY_SYSTEM = 'CAMPAIGN_ITEM_CANCELLED_BY_SYSTEM',
}

export enum WattPointValueError {
  WATTAGE_NOT_FOUND = 'WATTAGE_NOT_FOUND',
  CAMPAIGN_PRODUCT_NOT_FOUND = 'CAMPAIGN_PRODUCT_NOT_FOUND',
  CAMPAIGN_PRODUCT_HAS_NO_VALUE = 'CAMPAIGN_PRODUCT_HAS_NO_VALUE',
  NO_BUSINESS_RELATION_FOUND = 'NO_BUSINESS_RELATION_FOUND',
  UNKNOWN = 'UNKNOWN',
}

interface WalletValueObject {
  name: string;
  currency: string;
  conversions: WalletConversionValueObject[];
}

interface ProjectWattPointValueObject {
  value: number;
  partials: ProjectWattPointPartialValueObject[];
}

interface ProjectWattPointPartialValueObject {
  ean: string;
  quantity: number;
  wattage?: number;
  productMultiplier?: number;
  value: number;
  erpusage?: string;
  systemSize?: number;
  error?: WattPointValueError;
}

export abstract class CommonWalletTransactionValueObject {
  id: number;

  amount: number;

  date: string;

  expiresAt: string;

  walletName: string;

  metadata: Record<string, string>;

  operation: string;

  wallet: WalletValueObject;

  voucher: CreditVoucherValueObject;
}

interface CreditVoucherValueObject {
  id: number;
  value: number;
  usedAt: string;
  validUntil: string;
  created: string;
  status: number;
  currency: string;
  metadata: Map<string, string>;
  code: string;
}

export enum WattPointStatus {
  CAMPAIGN_ITEM_NOT_TRIGGERED = 'CAMPAIGN_ITEM_NOT_TRIGGERED',
  CAMPAIGN_ITEM_INVALID = 'CAMPAIGN_ITEM_INVALID',
}

export interface CreateRecCoinWalletTransactionJSON {
  projectID: number;
  amount: number;
  info: { metadata: Record<string, string> };
}

export interface CommonWalletTransactionValueObject {
  id: number;
  amount: number;
  date: string;
  expiresAt: Date;
  walletName: string;
  metadata: Record<string, string>;
  operation: string;
  wallet: WalletValueObject;
  voucher: CreditVoucherValueObject;
}

export interface OrgunitWalletTransactionValueObject
  extends CommonWalletTransactionValueObject {
  orgunitID: number;
}

interface ProjectWattPointStatusValueObject {
  errorReason: WattPointErrorReason | null;
  status: WattPointStatus;
  validationErrors: string[];
  wattPointStatus: ProjectWattPointValueObject | null;
  wattPointBonusStatus: ProjectWattPointValueObject | null;
  transactions: OrgunitWalletTransactionValueObject[];
}

export type AddRecCoinTransactionResponse = OrgunitWalletTransactionValueObject;

export type GetProjectRecCoinStatusResponse = ProjectWattPointStatusValueObject;
