import { HandleBaseRedirect } from '@lib/navigation';
import { initialize, listenForHistory } from '@mmw/common-ga';
import contextualConfig from '@mmw/contextual-config';
import {
  useCurrentSalesOrgBrand,
  useSetCurrentSalesOrgBrand,
} from '@mmw/redux-store-current-salesorgbrand/hooks';
import {
  ConnectedRouter,
  history,
  Redirect,
  Route,
  ScrollToTop,
  Switch,
} from '@mmw/ui-web-libraries-react-router';
import React, { useEffect } from 'react';
import ReactGTM, { TagManagerArgs } from 'react-gtm-module';

import MainPage from './MainPage';

const { salesOrgBrandID } = contextualConfig.application;

// Google Analytics
initialize();
listenForHistory(history);

ReactGTM.initialize({
  gtmId: contextualConfig.googleTagManager?.gtmId,
} as TagManagerArgs);

const Navigation: React.FC = () => {
  const setSob = useSetCurrentSalesOrgBrand();
  const currentSob = useCurrentSalesOrgBrand();

  useEffect(() => {
    if (!currentSob) {
      setSob(salesOrgBrandID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // @ts-ignore
    <ConnectedRouter history={history}>
      <HandleBaseRedirect />
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <MainPage />
          </Route>
          <Route path="/:group">
            <MainPage />
          </Route>
          <Route path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </ScrollToTop>
    </ConnectedRouter>
  );
};

export default Navigation;
