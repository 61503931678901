import { F } from 'ts-toolbelt';

export enum NotificationType {
  success = 'success',
  warning = 'warning',
  error = 'danger',
}

export type ToastConfigProps = {
  type?: NotificationType;
  onDestroy?: F.Function;
};
