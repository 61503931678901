import contextualConfig from '@mmw/contextual-config';
import { useLoadScript } from '@react-google-maps/api';
import { U } from 'ts-toolbelt';

const {
  googleMaps: { apikey: key },
} = contextualConfig;

// const libraries = ['places'];

type GoogleLoaderReturnType = {
  isLoaded: boolean;
  loadError: U.Nullable<Error>;
};

const useGoogleLoader = (): GoogleLoaderReturnType => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: key as string,
    // @ts-ignore
    // libraries,
  });
  return {
    isLoaded,
    loadError,
  };
};

export default useGoogleLoader;
