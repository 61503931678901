import { TabsProps } from '@material-ui/core/Tabs';
import { I18nShape } from '@ui-system/deps';
import { TypographyVariant } from '@ui-system/interfaces-typography';
import { F } from '@utils/ts';
import { nanoid } from 'nanoid';
import React from 'react';

export type InactiveTabStyle = {
  style?: Record<string, any>;
  modifiers?: string;
  iconColor?: string;
};

export interface TabBarProps
  extends Partial<Omit<TabsProps, 'onSelect' | 'style'>> {
  tabsTitles: Array<
    | string
    | (I18nShape & { icon?: string; iconColor?: string; iconSize?: number })
  >;
  titleVariant?: TypographyVariant;
  titleModifiers?: string;
  titleStyle?: Record<string, any>;
  onSelect: F.Function<[number]>;
  currentTabIndex: number;
  bottom?: boolean;
  barStyle?: Record<string, any>;
  tabStyle?: Record<string, any>;
  indicatorStyle?: Record<string, any>;
  indicatorColor?: 'secondary' | 'primary';
  inactiveTabStyles?: InactiveTabStyle;
}

export type TabBarType = React.FC<TabBarProps>;

export const TAB_BAR_UUID = nanoid();
export const TAB_UUID = nanoid();
