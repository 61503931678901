import { get } from 'lodash';

import { State } from './types';

export const NAMESPACE = 'salesOrgBrands';

type RootState = {
  salesOrgBrands: State;
};

export const loadingSelector = (state: RootState) =>
  get(state[NAMESPACE], 'loading');
export const listSelector = (state: RootState) => get(state[NAMESPACE], 'list');
export const mapSelector = (state: RootState) => get(state[NAMESPACE], 'map');
export const errorSelector = (state: RootState) =>
  get(state[NAMESPACE], 'error');
export const sobSelectorById = (state: RootState, id: number) =>
  get(mapSelector(state), id);
export const sobNameSelectorById = (state: RootState, id: number) =>
  get(sobSelectorById(state, id), 'name');

export const sobImageSelectorById = (state: RootState, id: number) =>
  get(sobSelectorById(state, id), 'image');
