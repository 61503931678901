import 'dayjs/locale/de';
import 'dayjs/locale/nl';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/pl';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/en-au';

import { U } from '@utils/ts';
import dayjs from 'dayjs';
import toUpper from 'lodash/toUpper';

import datesbycountry from './datesbycountry.json';
import { FORMATS, Formats } from './formats';

export type CountryCode = keyof typeof datesbycountry;

export * from './formats';
export * from './week';

export function dateByLanguage(
  date: U.Nullable<Date | number | string>,
  language: U.Nullable<Formats>,
): string {
  if (date && language) {
    return dayjs(date).format(FORMATS[language] || FORMATS.en);
  }
  return '';
}

export function dateByCountry(
  date: U.Nullable<Date | number | string>,
  country: U.Nullable<CountryCode | string>,
): string {
  if (date && country) {
    return dayjs(new Date(date)).format(
      toUpper(
        datesbycountry[toUpper(country)]?.dateFormat ||
          datesbycountry.US.dateFormat,
      ),
    );
  }
  return '';
}

export function languageByCountry(country: U.Nullable<CountryCode | string>) {
  if (country) {
    return toUpper(datesbycountry[toUpper(country)]?.language || 'en');
  }
  return 'en';
}
