import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useIsMounted } from './useIsMounted';

interface UseCheckImageUrl {
  url: string | null;
  retry: () => Promise<void>;
  loading: boolean;
}
export const useCheckImageUrl = (
  imageUrl: string, // url that returns image
  urlToCheck: string, // must be an API that returns a boolean, or another http status but must respond a response body
  fallback: string | null = null,
): UseCheckImageUrl => {
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();
  const [url, setUrl] = useState<string | null>(null);
  const getImage = useCallback(async () => {
    try {
      if (isMounted()) {
        setLoading(true);
        const response = await axios.get(urlToCheck);
        if (response.data) {
          setUrl(imageUrl);
          setLoading(false);
        } else {
          setUrl(fallback);
          setLoading(false);
        }
      }
    } catch (e) {
      if (isMounted()) {
        setUrl(fallback);
        setLoading(false);
      }
    }
  }, [isMounted, urlToCheck, imageUrl, fallback]);

  useEffect(() => {
    const timeout = setTimeout(getImage, 1);
    return () => {
      clearTimeout(timeout);
    };
  }, [getImage]);

  return useMemo(
    () => ({ url, retry: getImage, loading }),
    [getImage, loading, url],
  );
};
