import { TERMS_AND_CONDITIONS } from '@mmw/constants-i18n-common';

const namespace = 'termsAndConditions';

const TERMS = {
  TITLE: TERMS_AND_CONDITIONS,
  DESCRIPTION: {
    key: `${namespace}.description`,
    message:
      'To continue you must accept the terms and conditions of the campaign or program.',
  },
  I_ACCEPT_TERMS: {
    key: `${namespace}.iAcceptTerms1`,
    message: 'I accept terms',
  },
  AND_CONDITIONS: {
    key: `${namespace}.iAcceptTerms2`,
    message: 'and conditions',
  },
  I_DO_NOT: {
    key: `${namespace}.iDoNot`,
    message: 'I do not',
  },
  ACCEPT: {
    key: `${namespace}.accept`,
    message: 'Accept',
  },
  DECLINE: {
    key: `${namespace}.decline`,
    message: 'Decline',
  },
  I_ACCEPT: {
    key: `${namespace}.iAccept`,
    message: 'I accept Terms and Conditions',
  },
};

const I18N = {
  TERMS,
};

export default I18N;
