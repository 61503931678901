import { getRandomInt } from '@mmw/utils-password';
import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';

import createFromProps from './create-from-props';
import { FromProps } from './types';

const COLORS = ['#EE7B30', '#ff1100', '#1900ff', '#ff00a2', '#2fff00'];

export const applyDevBorder: F.Function<[Theme, boolean]> & FromProps = (
  theme,
  dev: boolean,
) => {
  if (dev) return `border: 1px solid ${COLORS[getRandomInt(0, 4)]}`;
  return '';
};
applyDevBorder.fromProps = createFromProps(applyDevBorder, '___dev');
