import { Button as MuiButton } from '@material-ui/core';
import { call } from '@shared-utils/function';
import { Icon } from '@ui-system/common-icon';
import componentModifiers from '@ui-system/default-modifiers/button';
import textComponentModifiers from '@ui-system/default-modifiers/typography';
import defaultProps from '@ui-system/default-props/button';
import { useButtonStyle, useButtonTextStyle } from '@ui-system/default-styles';
import { ButtonProps, ButtonType } from '@ui-system/interfaces-button';
import { IconProps } from '@ui-system/interfaces-icon';
import Typography from '@ui-system/material-typography';
import { usePropsByMediaQuery } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import React, { useCallback, useMemo } from 'react';

const Button: ButtonType = ({
  i18n,
  onClick,
  variant,
  color,
  accessoryLeft,
  accessoryRight,
  iconSize,
  modifiers,
  style,
  children,
  iconColor,
  loading,
  disabled,
  responsive,
  textStyle,
  textModifiers,
  textVariant,
  ...props
}: ButtonProps) => {
  const responsiveProps =
    usePropsByMediaQuery<
      Pick<
        ButtonProps,
        'modifiers' | 'textModifiers' | 'accessoryRight' | 'accessoryLeft'
      >
    >(responsive);
  const mergedProps = useMemo(
    () => ({
      modifiers,
      textModifiers,
      accessoryRight,
      accessoryLeft,
      ...responsiveProps,
    }),
    [accessoryLeft, accessoryRight, modifiers, responsiveProps, textModifiers],
  );
  const iconRight: React.FC<IconProps> = useCallback(
    () => (
      <Icon
        name={mergedProps.accessoryRight}
        size={iconSize || 45}
        color={disabled ? 'gray.A100' : iconColor}
      />
    ),
    [disabled, iconColor, iconSize, mergedProps.accessoryRight],
  );

  const iconLeft: React.FC<IconProps> = useCallback(
    () => (
      <Icon
        name={mergedProps.accessoryLeft}
        size={iconSize || 45}
        color={disabled ? 'gray.A100' : iconColor}
      />
    ),
    [disabled, iconColor, iconSize, mergedProps.accessoryLeft],
  );
  const buttonTextStyle = useButtonTextStyle(
    textStyle,
    mergedProps.textModifiers,
    textComponentModifiers,
  );
  const buttonStyle = useButtonStyle(
    style,
    mergedProps.modifiers,
    componentModifiers,
    {
      disabled: disabled || loading,
    },
  );
  return (
    // @ts-ignore
    <MuiButton
      onClick={onClick}
      variant={variant}
      color={color}
      startIcon={call(iconLeft)}
      endIcon={!loading ? call(iconRight) : <UI.Spinner visible size="tiny" />}
      style={buttonStyle}
      disabled={disabled || loading}
      {...props}
    >
      {children || (
        <Typography
          i18n={i18n}
          style={buttonTextStyle}
          modifiers={mergedProps.textModifiers}
          variant={textVariant}
        />
      )}
    </MuiButton>
  );
};
Button.defaultProps = defaultProps;

export default Button;
