import '@mmw/web-utils-compatibility-polyfills';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { unregister } from './serviceWorkers';

unregister();

const container = document.getElementById('root');

const root = createRoot(container!);

if (root !== null) {
  root.render(<App />);
}
