const canonNamespace = 'canon';

const achievementProofNamespace = `${canonNamespace}.achievementProof`;

const ACHIEVEMENT_PROOF = {
  DESCRIPTION_TEMPLATE_FOR_UPLOAD: {
    key: `${achievementProofNamespace}.descriptionTemplateForUpload`,
    message:
      'Please download the empty template, fill it out and then upload the proof.',
  },
  DESCRIPTION_FILLED_TEMPLATE: {
    key: `${achievementProofNamespace}.descriptionPrefilledTemplate`,
    message:
      'Download the pre-filled proof with the customer data you have just entered. The document will automatically be attached to your registration. This option obliges you to keep the signed proof in the trade.',
  },
  ACTION_TEMPLATE_FOR_UPLOAD: {
    key: `${achievementProofNamespace}.actionTemplateForUpload`,
    message: 'Download template',
  },
  ACTION_FILLED_TEMPLATE: {
    key: `${achievementProofNamespace}.actionPrefilledTemplate`,
    message: 'Pre-filled proof',
  },
  FILL_REQUIRED_FIELDS: {
    key: `${achievementProofNamespace}.fillRequiredFields`,
    message:
      'Please inform the product, the serial number, and the customer in order for us to generate the document and fill it automatically.',
  },
  SUCCESS_GENERATION_DOCUMENT: {
    key: `${achievementProofNamespace}.successGeneratingDocument`,
    message: 'Success generating document',
  },
};

const CAN2020_VERBAND = {
  PARTICIPATION_MODAL: {
    TITLE: {
      key: 'CAN2020_VERBAND.participationModal.title',
      message: 'Professional offers',
    },
    MESSAGE: {
      key: 'CAN2020_VERBAND.participationModal.description',
      message:
        'Do you have a discount code and want to take part in the professional offer?',
    },
    BUTTONS: {
      TAKE_PART: {
        key: 'CAN2020_VERBAND.participationModal.accept',
        message: 'Take part',
      },
      DONT_PARTICIPATE: {
        key: 'CAN2020_VERBAND.participationModal.decline',
        message: "Don't participate",
      },
    },
  },
};

const CAN2020_VERBANDAT = {
  PARTICIPATION_MODAL: {
    TITLE: {
      key: 'CAN2020_VERBANDAT.participationModal.title',
      message: 'Professional offers',
    },
    MESSAGE: {
      key: 'CAN2020_VERBANDAT.participationModal.description',
      message:
        'Do you have a discount code and want to take part in the professional offer?',
    },
    BUTTONS: {
      TAKE_PART: {
        key: 'CAN2020_VERBANDAT.participationModal.accept',
        message: 'Take part',
      },
      DONT_PARTICIPATE: {
        key: 'CAN2020_VERBANDAT.participationModal.decline',
        message: "Don't participate",
      },
    },
  },
};

const CANON_BUNDLE = {
  CAMPAIGN_QUESTION: {
    key: 'CANON_BUNDLE.participationQuestionContent',
    message: 'Haben Sie die Kamera im Bundle mit einem Objektiv verkauft?',
  },
};

const PRODUCT_KIT_DISCLAIMER = {
  key: `${canonNamespace}.productKitDisclaimer`,
  message:
    'Bitte beachten Sie, dass bei einer Kit-Registrierung die Seriennummer des Gehäuses benötigt wird. Bitte entnehmen Sie diese der Kamera selbst oder der obersten Stelle des Seriennummer-Aufklebers auf der Produktverpackung.',
};

const CAN2023_EOS_R_PLUS_BUNDLE = {
  CAMPAIGN_QUESTION: {
    key: 'CAN2023_EOS_R_PLUS_BUNDLE.participationQuestionContent',
    message:
      'Haben Sie die Kamera im Bundle mit zwei der teilnehmen Objektiv verkauft?',
  },
};
const CAN2019_PARS_AT_AND_DE_MESSAGE = {
  CAMPAIGN_QUESTION: {
    key: 'rechnungHochladen',
    message:
      'Um bei den System Deals für September teilzunehmen, laden Sie bitte eine Rechnung hoch.',
  },
};

const I18N = {
  ACHIEVEMENT_PROOF,
  CAN2020_VERBAND,
  CAN2020_VERBANDAT,
  PRODUCT_KIT_DISCLAIMER,
  CANON_BUNDLE,
  CAN2023_EOS_R_PLUS_BUNDLE,
  CAN2019_PARS_AT_AND_DE_MESSAGE,
};

export default I18N;
