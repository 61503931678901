import { ButtonProps } from '@ui-system/interfaces-button';

const defaultProps: Partial<ButtonProps> = {
  variant: 'contained',
  color: 'primary',
  textModifiers: 'uppercase',
  iconColor: 'white',
  iconSize: 25,
};
export default defaultProps;
