import COMMON from '@mmw/constants-i18n-common';
import SOLAR from '@mmw/constants-i18n-solar';

const namespace = 'moduleRegistrations';

const I18N = {
  MODULE_REGISTRATIONS: {
    key: `${namespace}`,
    message: 'Module Registrations',
  },
  MODULE_REGISTRATIONS_PAGE_TITLE: {
    key: `${namespace},pageTitle`,
    message: 'What do you need today?',
  },
  NEW_REGISTRATION: {
    DESCRIPTION: {
      key: `${namespace}.newRegistrationDescription`,
      message:
        '<strong>Register your installations,</strong> validate the REC ProTrust Warranty, earn RECoins and more advantages!',
    },
    ACTION: COMMON.NEW_REGISTRATION,
  },
  ALL_REGISTRATIONS: {
    DESCRIPTION: {
      key: `${namespace}.allRegistrationsDescription`,
      message:
        '<strong>Consult all your Module Registrations,</strong> check the map, download the certificates.',
    },
    ACTION: {
      key: `${namespace}.allRegistrationsAction`,
      message: 'VIEW YOUR REGISTRATIONS',
    },
  },
  FLASH_DATA: {
    DESCRIPTION: {
      key: `${namespace}.flashDataDescription`,
      message:
        '<strong>Get the flash data for your REC panels,</strong> measures the output performance',
    },
    ACTION: SOLAR.SOLAR.FLASH_DATA.TITLE,
  },
  SUBMIT_A_CLAIM: {
    DESCRIPTION: {
      key: `${namespace}.submitAClaimDescription`,
      message:
        '<strong>In the unlikely event of a claim,</strong> we have processes in place that ensure our customers get the best possible service.',
    },
    ACTION: {
      key: `${namespace}.submitAClaimAction`,
      message: 'Submit a claim',
    },
  },
  FIND_REGISTRATIONS: {
    key: `${namespace}.findRegistrations`,
    message: 'Find Registrations',
  },
  WARRANTY_DOCUMENTS: {
    key: `${namespace}.warrantyDocuments`,
    message: 'Warranty documents',
  },
};

export default I18N;
