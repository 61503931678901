const LANGUAGE_CODES = {
  BG: 'bg', // Bulgarian
  CZ: 'cz', // Czech
  DE: 'de', // German
  DK: 'dk', // Danish
  EN: 'en', // English
  ES: 'es', // Spanish
  FI: 'fi', // Finnish
  FR: 'fr', // French
  HU: 'hu', // Hungarian
  IT: 'it', // Italian
  NL: 'nl', // Dutch
  PL: 'pl', // Polish
  PT: 'pt', // Portuguese
  RO: 'ro', // Romanian
  SE: 'se', // Swedish
  SK: 'sk', // Slovak
  JA: 'ja', // Japanese
  KO: 'ko', // Korean
  ZH: 'zh', // Chinese
  RU: 'ru', // Russian
  AR: 'ar', // Arabic
  TR: 'tr', // Turkish
  HE: 'he', // Hebrew
  HI: 'hi', // Hindi
  UK: 'uk', // Ukrainian
  VI: 'vi', // Vietnamese
  TH: 'th', // Thai
  ID: 'id', // Indonesian
  MS: 'ms', // Malay
};

export default LANGUAGE_CODES;
