import Notification from '@mmw/ui-hybrid-libraries-notifications';
import * as React from 'react';
import {
  DefaultToastContainer,
  ToastProvider,
} from 'react-toast-notifications';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

const StyledToastContainer = styled(DefaultToastContainer)`
  z-index: 1051 !important;
`;

const NotificationProvider = ({ children }: Props) => (
  <ToastProvider
    components={{ Toast: Notification, ToastContainer: StyledToastContainer }}
  >
    {children}
  </ToastProvider>
);

export default NotificationProvider;
