import { ContainerProps } from '@ui-system/interfaces-container';
import { nanoid } from 'nanoid';
import React from 'react';

import { CheckboxType } from './checkbox';
import { CheckboxGroupType } from './checkbox-group';
import { FormDatePickerType } from './form-date-picker';
import { InputProps, InputType } from './input';
import { MultipleSelectType } from './multiple-select';
import { RadioGroupType } from './radio-group';
import { SelectProps, SelectType } from './select';
import { SwitchType } from './switch';
import { TimePickerType } from './time-picker';

export interface FormTypeStatics {
  Input: InputType;
  Checkbox: CheckboxType;
  Select: SelectType;
  MultipleSelect: MultipleSelectType;
  RadioGroup: RadioGroupType;
  Switch: SwitchType;
  CheckboxGroup: CheckboxGroupType;
  TimePicker: TimePickerType;
  DatePicker: FormDatePickerType;
}

export type CommonFormFieldsProps = Pick<
  InputProps | SelectProps,
  | 'innerShadow'
  | 'innerShadowProps'
  | 'labelModifiers'
  | 'labelVariant'
  | 'modifiers'
>;

export type FormType = React.FC<ContainerProps> & FormTypeStatics;
export const FORM_INPUT_UUID = nanoid();
export const FORM_INPUT_CONTAINER_UUID = nanoid();
export const FORM_SELECT_UUID = nanoid();
export const FORM_SELECT_CONTAINER_UUID = nanoid();
export const FORM_SELECT_DISABLED_UUID = nanoid();
export const FORM_SELECT_PLACEHOLDER_UUID = nanoid();
export const FORM_SELECT_ARROW_DOWN_UUID = nanoid();

export const FORM_CHECKBOX_UUID = nanoid();
export const FORM_RADIO_GROUP_UUID = nanoid();
export const FORM_SWITCH_UUID = nanoid();
export const FORM_CHECKBOX_GROUP_UUID = nanoid();
export const FORM_TIME_PICKER_UUID = nanoid();
export const FROM_DATE_PICKER_UUID = nanoid();

export * from './checkbox';
export * from './checkbox-group';
export * from './form-date-picker';
export * from './input';
export * from './multiple-select';
export * from './radio-group';
export * from './select';
export * from './switch';
export * from './time-picker';
