import { TraderJSON } from '@mmw/services-core-store-locator/types';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import UI from '@ui-system/ui';
import map from 'lodash/map';
import React from 'react';
import { U } from 'ts-toolbelt';

import StoreInfoTooltip from '../components/StoreInfoTooltip';
import { useCustomMapMarker } from '../context';
import { getStorePosition } from '../utils';

const getPixelPositionOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -(height / 2),
});

interface MarkerProps {
  storesList: U.Nullable<TraderJSON[]>;
  selectedStore: U.Nullable<TraderJSON>;
}

const TraderMarkers: React.FC<MarkerProps> = ({
  storesList,
  selectedStore,
}: MarkerProps) => {
  const CustomMapMark = useCustomMapMarker();
  if (!storesList) return null;
  return (
    <>
      {map(storesList, store => (
        <OverlayViewF
          key={`${getStorePosition(store).lat}, ${getStorePosition(store).lng}`}
          position={getStorePosition(store)}
          getPixelPositionOffset={getPixelPositionOffset}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <StoreInfoTooltip
            store={store}
            showTooltip={selectedStore?.orgunitID === store.orgunitID}
          >
            {CustomMapMark ? (
              <CustomMapMark store={store} />
            ) : (
              <UI.Icon name="local" color="primary" size={45} />
            )}
          </StoreInfoTooltip>
        </OverlayViewF>
      ))}
    </>
  );
};

export default TraderMarkers;
