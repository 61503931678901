import { SEARCH } from '@mmw/constants-i18n-buttons';
import useSubmit from '@mmw/redux-store-fast-form/hooks/bypass-epic/useSubmit';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React from 'react';

const SearchButton: React.FC = () => {
  const submit = useSubmit();
  return (
    <UI.Container align="center" justify="center" p="2, 3">
      <UI.Button
        onClick={submit}
        i18n={SEARCH}
        style={css`
          width: 100%;
          padding: 10px;
        `}
      />
    </UI.Container>
  );
};

export default SearchButton;
