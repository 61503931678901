import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { get } from 'lodash';
import { U } from 'ts-toolbelt';

import { NAMESPACE, RootState } from './types';

export const currentSalesOrgBrandFieldPath = [
  NAMESPACE,
  'currentSalesOrgBrandID',
];

export const currentSalesOrgBrandSelector = <S = RootState>(
  state: S,
): U.Nullable<SalesOrgBrand> => get(state[NAMESPACE], 'currentSalesOrgBrandID');

export type { RootState } from './types';
