import {
  MissingAuthorityError,
  MissingSalesOrgBusinessRelationError,
} from '@mmw/services-auth-api-authentication/errors';
import { AuthenticationAuthorities } from '@mmw/services-auth-api-authentication/types';
import { EMPTY_ARRAY } from '@shared-utils/array';
import { createSelector } from 'reselect';
import { U } from 'ts-toolbelt';

import { NAMESPACE, RootState, State } from './types';

export const isLoggedIn = (state: RootState): boolean =>
  state[NAMESPACE].isLoggedIn;

export const verificationsCounterSelector = (state: RootState): number =>
  state[NAMESPACE].verificationsCounter;

export const verifyingSelector = (state: RootState): boolean =>
  state[NAMESPACE].verifying;

export const bypassForceChangePwSelector = (
  state: RootState,
): U.Nullable<string> => state[NAMESPACE].bypassForceChangePw;

export const accessTokenSelector = (state: RootState): State['accessToken'] =>
  state[NAMESPACE].accessToken;

export const requestingTanSelector = (
  state: RootState,
): State['requestingTan'] => state[NAMESPACE].requestingTan;

export const requestTanErrorSelector = (
  state: RootState,
): State['requestTanError'] => state[NAMESPACE].requestTanError;

export const verificationErrorSelector = (
  state: RootState,
): State['verificationError'] => state[NAMESPACE].verificationError;

export const loggingOutSelector = (state: RootState): State['loggingOut'] =>
  state[NAMESPACE].loggingOut;

export const authenticatingSelector = (
  state: RootState,
): State['authenticating'] => state[NAMESPACE].authenticating;

export const requestingUseridByEmailSelector = (
  state: RootState,
): State['requestingUseridByEmail'] => state[NAMESPACE].requestingUseridByEmail;

export const authenticationErrorSelector = (
  state: RootState,
): State['authenticationError'] => state[NAMESPACE].authenticationError;

export const googleAuthFailedSelector = (
  state: RootState,
): State['googleAuthFailed'] => state[NAMESPACE].googleAuthFailed;

export const isLoggedInFieldPath = [NAMESPACE, 'isLoggedIn'];

export const foundUseridPath = [NAMESPACE, 'foundUserid'];

export const foundUseridSelector = (state: RootState): State['foundUserid'] =>
  state[NAMESPACE].foundUserid;

export const loggedUserSelector = (state: RootState): State['loggedUser'] =>
  state[NAMESPACE].loggedUser;

export const loggedUserFieldPath = [NAMESPACE, 'loggedUser'];

export const loggedUseridSelector = (state: RootState): U.Nullable<string> => {
  if (state[NAMESPACE].loggedUser && state[NAMESPACE].isLoggedIn) {
    return state[NAMESPACE].loggedUser != null
      ? state[NAMESPACE].loggedUser?.userid
      : null;
  }
  return null;
};

export const loggedUserAuthoritiesSelector = (
  state: RootState,
): AuthenticationAuthorities[] | null => {
  if (state[NAMESPACE].loggedUser && state[NAMESPACE].isLoggedIn) {
    return state[NAMESPACE].loggedUser != null
      ? state[NAMESPACE].loggedUser?.authorities
      : null;
  }
  return null;
};

const getError = (state: RootState) =>
  authenticationErrorSelector(state) ||
  requestTanErrorSelector(state) ||
  verificationErrorSelector(state);

export const hasMissingAuthorityError = (state: RootState): boolean => {
  const error = getError(state);
  return error != null && error instanceof MissingAuthorityError;
};

export const hasMissingSalesOrgError = (state: RootState): boolean => {
  const error = getError(state);
  return error != null && error instanceof MissingSalesOrgBusinessRelationError;
};

export const verifiedAsNotLoggedInSelector = (state: RootState): boolean =>
  verificationsCounterSelector(state) > 0 &&
  !verifyingSelector(state) &&
  !isLoggedIn(state) &&
  !authenticatingSelector(state);

export const detectingAuthenticationStatusSelector = (
  state: RootState,
): boolean =>
  verificationsCounterSelector(state) === 0 || authenticatingSelector(state);

export const loggedUserOrgunitIDSelector = (
  state: RootState,
): U.Nullable<number> => {
  if (state[NAMESPACE].loggedUser && state[NAMESPACE].isLoggedIn) {
    return state[NAMESPACE].loggedUser != null
      ? state[NAMESPACE].loggedUser?.orgunitID
      : null;
  }
  return null;
};

export const loggedUserOrgunitFamilyIDsSelector = (
  state: RootState,
): number[] => {
  if (state[NAMESPACE].loggedUser && state[NAMESPACE].isLoggedIn) {
    return state[NAMESPACE].loggedUser != null
      ? state[NAMESPACE].loggedUser?.orgunitFamilyIDs || EMPTY_ARRAY
      : EMPTY_ARRAY;
  }
  return EMPTY_ARRAY;
};

export const loggedSalesOrgBrandIDsSelector = (
  state: RootState,
): U.Nullable<number[]> => {
  if (state[NAMESPACE].loggedUser && state[NAMESPACE].isLoggedIn) {
    return state[NAMESPACE].loggedUser != null
      ? state[NAMESPACE].loggedUser?.salesOrgBrandsIDs
      : null;
  }
  return null;
};

export const loggedSalesOrgBrandCountrySelector = (
  state: RootState,
): U.Nullable<string> => {
  if (state[NAMESPACE].loggedUser && state[NAMESPACE].isLoggedIn) {
    return state[NAMESPACE].loggedUser != null
      ? state[NAMESPACE].loggedUser?.orgunitCountry
      : null;
  }
  return null;
};

export const authenticationStateSelector = createSelector(
  authenticatingSelector,
  authenticationErrorSelector,
  (authenticating, authenticationError) => ({
    authenticating,
    authenticationError,
  }),
);

export const loggedInStatusSelector = createSelector(
  isLoggedIn,
  authenticatingSelector,
  verifyingSelector,
  verificationsCounterSelector,
  (loggedIn, authenticating, verifying, verificationsCounter) => ({
    isLoggedIn: loggedIn,
    authenticating,
    verifying,
    verificationsCounter,
  }),
);

export const authWithSystemTokenSelector = (state: RootState) =>
  state[NAMESPACE].authWithSystemToken;

/**
 * avoids firing service if not authenticated
 * @param callback to be called if isLoggedIn
 * @returns desired callback or async noop if !isLoggedIn
 */
export const authenticatedClientServiceSelector =
  <CB extends (...args: any) => Promise<any>>(callback: CB) =>
  (state: RootState): CB | (() => Promise<null>) => {
    const fallback = async () => null;
    return isLoggedIn(state) ? callback : fallback;
  };

export interface ClientServiceByAuthoritiesSelector {
  <CB extends (...args: any) => Promise<any>>(
    ...services: {
      callback: CB;
      authorities: string[];
    }[]
  ): (state: RootState) => CB | (() => Promise<null>);
}

export const clientServiceByAuthoritiesSelector: ClientServiceByAuthoritiesSelector =

    <CB extends (...args: any) => Promise<any>>(
      ...services: {
        callback: CB;
        authorities: AuthenticationAuthorities[];
      }[]
    ) =>
    (state: RootState) => {
      const fallback = async () => null;
      const userAuths = loggedUserAuthoritiesSelector(state);
      if (!userAuths || !isLoggedIn(state)) return fallback;
      // returns callback which includes some of user authorities
      const { callback } =
        services.find(
          serv =>
            serv.authorities.length === 0 ||
            serv.authorities.some(a => userAuths.includes(a)),
        ) || {};
      // fallback noop if no callback match
      return callback || fallback;
    };
