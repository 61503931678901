import { useDeepCompareMemo } from '@react-utils/hooks';
import { deepMergeAll } from '@shared-utils/object';
import {
  ComponentModifiersFunction,
  Modifiers,
  Style,
} from '@ui-system/interfaces';
import { useModifiers } from '@ui-system/modifiers';
import { useMergedStyles } from '@ui-system/style';
import { UseStyle } from '@ui-system/styles/types';
import {
  ComponentUuid,
  useComponentStyleFromCtx,
} from '@ui-system/styles-provider';
import { O, U } from '@utils/ts';

export function mergeStyles<StyleObj = Style>(
  defaultStyle: StyleObj = {} as StyleObj,
  styleFromCtx: Style = {},
  styleFromProps: U.Nullable<StyleObj> = {} as StyleObj,
): Style {
  // @ts-ignore
  return deepMergeAll([
    // @ts-ignore
    defaultStyle as O.Object,
    styleFromCtx as O.Object,
    styleFromProps as unknown as Style,
  ]) as O.Object;
}

export function useComponentFinalStyle<StyleObj = Style>(
  defaultStyle: StyleObj,
  styleFromProps: U.Nullable<StyleObj>,
  modifiers: U.Nullable<Modifiers>,
  componentModifiers: U.Nullable<ComponentModifiersFunction>,
  componentUuid: ComponentUuid,
): Style {
  const styleFromContext = useComponentStyleFromCtx(componentUuid);

  const style = useMergedStyles(
    defaultStyle as Style,
    styleFromContext,
    styleFromProps as Style,
  );
  return useModifiers(modifiers, componentModifiers, style);
}

export function makeStyleHookWithMergedCtx<
  Props extends O.Object | null = null,
>(
  componentUuid: ComponentUuid,
  useDefaultStyle: UseStyle<Props>,
): UseStyle<Props> {
  return function useStyle(props: Props) {
    const styleFromContext = useComponentStyleFromCtx(componentUuid);
    // @ts-ignore
    const defaultStyle = useDefaultStyle(props);
    return useDeepCompareMemo<Style>(
      () => mergeStyles(defaultStyle, styleFromContext),
      [defaultStyle, styleFromContext],
    );
  } as UseStyle<Props>;
}
export function makeFinalStyleHook<
  Props extends O.Object | null = null,
  StyleObj = Style | O.Object,
>(componentUuid: ComponentUuid, useDefaultStyle: UseStyle<Props>) {
  return function useFinalStyle(
    styleFromProps: U.Nullable<StyleObj>,
    modifiers: U.Nullable<Modifiers>,
    componentModifiers: U.Nullable<ComponentModifiersFunction>,
    // @ts-ignore
    props: Props = null,
  ): Style {
    // @ts-ignore
    const defaultStyle = useDefaultStyle(props);
    return useComponentFinalStyle(
      defaultStyle,
      styleFromProps as unknown as Style,
      modifiers,
      componentModifiers,
      componentUuid,
    );
  };
}
