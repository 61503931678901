import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getFormSubmitCountSelector } from '../store/stateSelector';
import useFormId from './useFormId';

function useFormSubmitCount(): number {
  const formId = useFormId();
  const selector = useCallback(
    state => getFormSubmitCountSelector(formId)(state),
    [formId],
  );

  return useSelector(selector);
}

export default useFormSubmitCount;
