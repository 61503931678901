// import {
//   COMMON as FIELDS,
// } from '@mmw/constants-fields-names';
// import {
//   COMMON as VALIDATIONS,
// } from '@mmw/constants-fields-validations';
// import { MAX_LENGTH, MIN_LENGTH, REQUIRED } from '@mmw/constants-validation-names';

// const namespace = 'validation';

const COMMON = {
  LANGUAGE: {},
  EMAIL: {},
  NAN: {
    key: 'validation.notANumberVerification',
    message: 'The value of this field must be numeric',
  },
};

export default COMMON;
