import { useMemo } from 'react';

import useFormSubmitCount from '../useFormSubmitCount';

function useIsFormSubmitted(): boolean {
  const submitCount = useFormSubmitCount();
  return useMemo(() => submitCount > 0, [submitCount]);
}

export default useIsFormSubmitted;
