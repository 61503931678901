import { BasePath } from '@di/core/BasePath';
import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import logger from './log';
import { getPaths, SOLAR_DELIVERY_BASE_PATH_TOKEN } from './paths';
import { DeliveryRequestObject, OrderPanelTreeItem } from './types';

type Api = typeof defaultApiV2;

type SolarDeliveriesOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class SolarDeliveriesService extends BasePath {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: SolarDeliveriesOptions) {
    super(SOLAR_DELIVERY_BASE_PATH_TOKEN);
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async searchDeliveries(
    requestObject: DeliveryRequestObject,
  ): Promise<Array<OrderPanelTreeItem>> {
    logger.debug('Trying to search deliveries by request=%O', requestObject);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<OrderPanelTreeItem>> =
        await this.api.post(
          getPaths(this.getBasePath()).SearchDeliveriesPath(),
          {
            serialNumber: requestObject.serialnumber,
            shippingERP: requestObject.deliverynumber,
            palletNr: requestObject.palletnumber,
            orderERP: requestObject.ordernumber,
            salesOrgBrandID: requestObject.salesOrgBrand,
          },
          {
            headers,
          },
        );
      const { data } = response;
      logger.info(`Successfully got deliveries, size ${data.length}`);
      return data;
    } catch (error) {
      logger.error('Error when getting deliveries, error=%O', error);
      throw error;
    }
  }

  async searchDeliveriesBySerialnumberOrPalletnumber(
    requestObject: DeliveryRequestObject,
  ): Promise<Array<OrderPanelTreeItem>> {
    const results = await this.searchDeliveries(requestObject);
    if (results.length) {
      return results;
    }
    return this.searchDeliveries({
      ...requestObject,
      serialnumber: undefined,
      palletnumber: requestObject.serialnumber,
    });
  }
}

export default SolarDeliveriesService;
