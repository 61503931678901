import { reduce } from 'lodash';

import {
  Action,
  GET_SALES_ORG_BRANDS_LIST_ERROR,
  GET_SALES_ORG_BRANDS_LIST_START,
  GET_SALES_ORG_BRANDS_LIST_SUCCESS,
  Reducer,
  State,
} from './types';

export const INITIAL_STATE = {
  list: null,
  loading: false,
  error: null,
};

const salesOrgBrandsReducer: Reducer = (
  state: State = INITIAL_STATE,
  { type, payload }: Action,
) => {
  switch (type) {
    case GET_SALES_ORG_BRANDS_LIST_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_SALES_ORG_BRANDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.list,
        map: reduce(
          payload.list,
          (obj, sob) => {
            // eslint-disable-next-line no-param-reassign
            obj[sob.id] = sob;
            return obj;
          },
          {},
        ),
      };

    case GET_SALES_ORG_BRANDS_LIST_ERROR:
      return {
        ...state,
        error: payload.error,
      };

    default:
      return state;
  }
};

export default salesOrgBrandsReducer;
