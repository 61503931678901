import { Color, OpacityRange } from '@ui-system/css';
import { Modifiers, Style } from '@ui-system/interfaces/types';
import { nanoid } from 'nanoid';
import React from 'react';

export interface DividerProps {
  style?: Style;
  color?: Color;
  modifiers?: Modifiers;
  opacity?: OpacityRange;
}

export const DIVIDER_UUID = nanoid();

export type DividerType = React.FC<DividerProps>;
