export default {
  underline: () => `
    text-decoration: underline;
  `,
  overline: () => `
    text-decoration: overline;
  `,
  lineThrough: () => `
    text-decoration: line-through;
  `,
  underlineOverline: () => `
    text-decoration: underline overline;
  `,
  blink: () => `
    text-decoration: blink;
  `,
};
