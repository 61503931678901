import { ACCOUNT as FIELDS } from '@mmw/constants-fields-names';

const bankAccountNamespace = 'bankAccount';

const BANK_ACCOUNT = {
  BANK_ACCOUNT: {
    LABEL: {
      key: `${bankAccountNamespace}.bankAccount`,
      message: 'Bank Account',
    },
  },
  COUNTRY: {
    LABEL: {
      key: `${bankAccountNamespace}.${FIELDS.COUNTRY}`,
      message: 'Country',
    },
  },
  ACCOUNT_OWNER: {
    LABEL: {
      key: `${bankAccountNamespace}.${FIELDS.ACCOUNTOWNER}`,
      message: 'Account owner',
    },
  },
  BIC: {
    LABEL: {
      key: `${bankAccountNamespace}.${FIELDS.BIC}`,
      message: 'BIC',
    },
    IGNORE_VALIDATION: {
      key: `${bankAccountNamespace}.${FIELDS.BIC}.fillManually`,
      message: 'Proceed without IBAN validation?',
    },
  },
  IBAN: {
    LABEL: {
      key: `${bankAccountNamespace}.${FIELDS.IBAN}`,
      message: 'IBAN',
    },
    NOT_FOUND: {
      key: `${bankAccountNamespace}.${FIELDS.IBAN}.notFound`,
      message: 'IBAN not found',
    },
    UNDETECTED_BIC: {
      key: `${bankAccountNamespace}.${FIELDS.IBAN}.undetectedBic`,
      message: 'Unable to detect BIC from the provided IBAN.',
    },
    VALID_IBAN_OR_ANY_BIC: {
      key: `${bankAccountNamespace}.${FIELDS.IBAN}.validIbanOrAnyBic`,
      message: 'Inform a valid IBAN or set BIC manually.',
    },
  },
  MANUAL_BANK_NAME: {
    LABEL: {
      key: `${bankAccountNamespace}.${FIELDS.MANUALBANKNAME}`,
      message: 'Credit institution',
    },
  },
  HELP: {
    TEXT: {
      key: `${bankAccountNamespace}.cardIbanBicHelp`,
      message:
        '*= You can find IBAN and BIC on the back of your bank card, or on your bank statement.',
    },
    TITLE: {
      key: `${bankAccountNamespace}.modalTitle`,
      message: 'Bank Account Help',
    },
    DESCRIPTION: {
      key: `${bankAccountNamespace}.modalDescription`,
      message: 'Check where you can find your BIC and IBAN in the image below.',
    },
  },
  OPTIONAL_LETTER_TO_EMAIL: {
    LABEL: {
      key: `${bankAccountNamespace}.sendDocumentToEmailLabel`,
      message:
        'Please send the letter of identification to the following email address',
    },
  },
  ACCOUNT_TO_ALL_BRANCHES: {
    LABEL: {
      key: `${bankAccountNamespace}.accountToAllBranchesLabel`,
      message:
        'Would you like to activate this bank data for your other branches with the same VAT ID',
    },
  },
  CONFIRM_LEGITIMIZATION: {
    LABEL: {
      key: `${bankAccountNamespace}.confirmLegitimizationLabel`,
      message:
        'I confirm that I have signed and sent the letter of identification.',
    },
  },
  REVIEW_BANK_DATA: {
    TITLE: {
      key: `${bankAccountNamespace}.reviewDataHeader`,
      message: 'Bank details',
    },
  },
  ACCOUNT_LEGITIMIZATION_WARNING: {
    key: `${bankAccountNamespace}.accountLegitimizationWarning`,
    message:
      'Please note that we need a signed and stamped legitimation letter from you in order to be able to carry out transfers. If you have already submitted this document, you can ignore this message. Otherwise you can download the legitimation letter here.',
  },
  DOWNLOAD_IDENTIFICATION_LETTER: {
    key: `${bankAccountNamespace}.downloadIdentificationLetter`,
    message: 'Download the letter of identification',
  },
  LEGITIMIZATION_OF_BANK_ACCOUNT: {
    key: `${bankAccountNamespace}.legitimizationOfBankAccount`,
    message: 'Legitimization of bank account',
  },
  DOWNLOAD_AND_FILL_TO_CONTINUE: {
    key: `${bankAccountNamespace}.downloadAndFillToContinue`,
    message:
      'You must download and fill the letter of identification in order to continue',
  },
};

export default BANK_ACCOUNT;
