import { I18nShape } from '@mmw/constants-i18n';
import { ComponentModifiersFunction, Style } from '@ui-system/interfaces/types';
import {
  ContainerInnerShadowProps,
  ContainerType,
} from '@ui-system/interfaces-container';
import { TypographyVariant } from '@ui-system/interfaces-typography';
import { nanoid } from 'nanoid';
import React from 'react';
import { F, U } from 'ts-toolbelt';

export interface DateInputProps<StyleObj = Style>
  extends Record<string, any>,
    ContainerInnerShadowProps {
  innerRef: React.Ref<ContainerType>;
  style?: Style;
  children: React.ReactNode;
  disabled?: boolean;
  hasValue: boolean;
  customIcon?: React.ReactNode;
  containerStyle?: StyleObj;
  onClick: () => void;
  onClickWipe: () => void;
  error?: boolean;
  modifiers?: string;
}

export interface DatePickerProps
  extends Record<string, any>,
    ContainerInnerShadowProps {
  style?: Style;
  inputProps?: Record<string, any>;
  modifiers?: string;
  value: Date | number;
  placeholderI18n?: I18nShape;
  onChange: (date: U.Nullable<Date | number | string>) => void;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  maximumDate?: Date;
  minimumDate?: Date;
  error?: boolean;
  required?: boolean;
  caption?: I18nShape;
  label?: I18nShape;
  labelComponent?: React.ReactElement;
  labelModifiers?: string;
  labelVariant?: TypographyVariant;
  componentModifiers?: ComponentModifiersFunction<string>;
  CustomIcon?: React.FC;
  disableCleanIcon?: boolean;
}

export interface DatePickerWrapperProps extends Partial<DatePickerProps> {
  value: Date | number;
  onChangeDate: (e: any, date: Date | number) => void;
  hide: () => void;
  isVisible: boolean;
  toggle?: F.Function;
}

export type CustomTextProps = {
  value: Date | number;
  placeholderI18n?: I18nShape;
};

export type DateInputType = React.FC<DateInputProps>;
export type DatePickerType = React.FC<DatePickerProps>;
export type DatePickerWrapperType = React.FC<DatePickerWrapperProps>;

export const DATE_PICKER_UUID = nanoid();
export const DATE_ICON_UUID = nanoid();
export const DATE_INPUT_UUID = nanoid();
export const DATE_TEXT_UUID = nanoid();
