import { Container } from '@ui-system/common-container';
import Skeleton from '@ui-system/common-skeleton';
import React from 'react';

const PaginatorSkeleton: React.FC = () => (
  <Container align="center" justify="center" p="4">
    <Container align="center" gap={2}>
      <Skeleton width={60} height={35} />
      <Skeleton width={180} height={25} />
      <Skeleton width={60} height={35} />
    </Container>
  </Container>
);

export default PaginatorSkeleton;
