import { CacheInterface } from '@mmw/common-cache';
import { ClientFingerprint } from '@mmw/common-client-fingerprint';
import autoBind from 'auto-bind';
import Fingerprint2 from 'fingerprintjs2';
import uuidv4 from 'uuid/v4';

import { FINGERPRINT_KEY } from './keys';
import logger from './log';
import { BrowserFingerprintOptions } from './types';

class BrowserFingerprint implements ClientFingerprint {
  fingerprintId: string | null;

  cache: CacheInterface;

  constructor({ cache, autoStart }: BrowserFingerprintOptions) {
    this.cache = cache;
    autoBind(this);
    if (!autoStart) {
      return;
    }
    if (window.requestIdleCallback) {
      requestIdleCallback((): void => {
        this.obtainFingerprint();
      });
    } else {
      setTimeout(this.obtainFingerprint, 500);
    }
  }

  async obtainFingerprint(): Promise<string | null> {
    try {
      const fingerprint: string | null = await this.cache.callPromise({
        cacheKey: FINGERPRINT_KEY,
        method: BrowserFingerprint.produceFingerprint,
      });
      this.fingerprintId = fingerprint;
      return fingerprint;
    } catch (error) {
      logger.error('Error while trying to calculate fingerprint=%O', error);
      return Promise.resolve(null);
    }
  }

  static async produceFingerprint(): Promise<string> {
    logger.trace('Trying to calculate fingerprint');
    const components = await Fingerprint2.getPromise();
    logger.trace('Got components=%O', components);
    const values = components.map(component => component.value);
    const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
    logger.trace('Calculated fingerprint as=%s', fingerprint);
    return fingerprint;
  }

  static produceFallbackFingerprint(): string {
    return uuidv4();
  }

  async getClientFingerprint(): Promise<string> {
    if (this.fingerprintId) {
      return Promise.resolve(this.fingerprintId);
    }
    const fingerprint = await this.obtainFingerprint();
    if (fingerprint) {
      return fingerprint;
    }
    logger.trace('Couldnt obtain fingerprint, will generate a fallback value');
    return BrowserFingerprint.produceFallbackFingerprint();
  }
}

export default BrowserFingerprint;
