import qs from 'qs';

const BASE_PATH = '/v1/trader/demo-device';

export const PATHS = {
  registerDemoDevicePath: (language): string =>
    `${BASE_PATH}/register-device?language=${language}`,
  getCampaignOverviewPath: (campaignID: string): string =>
    `${BASE_PATH}/campaign/${campaignID}/overview`,
  removeDeviceFromShowRoomPath: (): string => `${BASE_PATH}/show-room`,
  getShowRoomReadyPath: (campaignID: string): string =>
    `${BASE_PATH}/campaign/${campaignID}/show-room/ready`,
  getShowRoomNotReadyPath: (campaignID: string): string =>
    `${BASE_PATH}/campaign/${campaignID}/show-room/not-ready`,
  getDevicesPath: (
    salesOrgBrandID: number,
    orgunitID: number,
    language: string,
    campaignID?: number,
  ): string =>
    `${BASE_PATH}/devices?${qs.stringify({
      salesOrgBrandID,
      orgunitID,
      language,
      campaignID,
    })}`,
  // getAddedDevicesPath: (): string => `${BASE_PATH}/added-devices`,
};
