const APPLICATION_IDS = {
  ADMIN_CLIENT: 'admin-client',
  CONSUMER_CAMPAIGN_CLIENT: 'consumer-campaign-client',
  CONSUMER_CLIENT: 'consumer-client',
  MANUFACTURER_CLIENT: 'manufacturer-client',
  RETAIL_CLIENT: 'trader-client',
  WEBSERVICES_V2: 'api-v2',
  CAMPAIGN_FACTORY: 'campaign-factory',
  REC_PROJECTS_APP: 'rec-projects-app',
  RETAIL_APP: 'retail-app',
  REC_PROPORTAL: 'rec-proportal',
  REC_PROPORTAL_ADMIN: 'rec-proportal-admin',
  AEG_STORE_LOCATOR: 'aeg-store-locator',
  DELONGHI_STORE_LOCATOR: 'delonghi-store-locator',
  DENON_STORE_LOCATOR: 'denon-store-locator',
  REC_STORE_LOCATOR: 'rec-store_locator',
};

export default APPLICATION_IDS;
export type ApplicationId =
  (typeof APPLICATION_IDS)[keyof typeof APPLICATION_IDS];

export const {
  ADMIN_CLIENT,
  CONSUMER_CAMPAIGN_CLIENT,
  CONSUMER_CLIENT,
  MANUFACTURER_CLIENT,
  RETAIL_CLIENT,
  WEBSERVICES_V2,
  CAMPAIGN_FACTORY,
  REC_PROJECTS_APP,
  RETAIL_APP,
  REC_PROPORTAL,
  REC_PROPORTAL_ADMIN,
  AEG_STORE_LOCATOR,
  DELONGHI_STORE_LOCATOR,
  DENON_STORE_LOCATOR,
  REC_STORE_LOCATOR,
} = APPLICATION_IDS;
