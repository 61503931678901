import { getLogger } from '@mmw/contextual-config';
import includes from 'lodash/includes';
import { Action } from 'redux';
import { BATCH, BatchActionType } from 'redux-batched-actions';
import { combineEpics } from 'redux-observable';
import { EMPTY, from, of, OperatorFunction } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export interface BatchAction<T> {
  type: BatchActionType;
  payload: T[];
  meta?: {
    batch: true;
  };
}

const isAnyType = <Input extends Action, Type extends Input['type']>(
  action: Input,
  ...types: [Type, ...Type[]]
): boolean => includes(types, action.type);

const isType = <Input extends Action, Type extends Input['type']>(
  action: Input,
  type: Type,
): boolean => action.type === type;

export const isBatch = <Input extends Action>(action: Input): boolean =>
  action.type === BATCH;

export function ofType<
  // All possible actions your app can dispatch
  Input extends Action,
  // The types you want to filter for
  Type extends Input['type'],
  // The resulting actions that match the above types
  Output extends Input = Extract<Input, Action<Type>>,
>(
  predicate: (value: Input, index: number) => value is Output,
): OperatorFunction<Input, Output>;

export function ofType<
  // All possible actions your app can dispatch
  Input extends Action,
  // The types you want to filter for
  Type extends Input['type'],
  // The resulting actions that match the above types
  Output extends Input = Extract<Input, Action<Type>>,
>(...types: [Type, ...Type[]]): OperatorFunction<Input, Output>;

export function ofType<
  // All possible actions your app can dispatch
  Input extends Action,
  // The types you want to filter for
  Type extends Input['type'],
  // The resulting actions that match the above types
  Output extends Input = Extract<Input, Action<Type>>,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
>(types: any): OperatorFunction<Input, Output> {
  return mergeMap(action => {
    // HANDLE PREDICATE
    if (typeof types === 'function') {
      const predicate = types;
      if (isBatch(action)) {
        const batchAction: BatchAction<Input> = <BatchAction<Input>>(
          (<unknown>action)
        );
        return from(<Output[]>batchAction.payload.filter(predicate));
      }
      if (predicate(action, 0)) {
        return of(<Output>action);
      }
      return EMPTY;
    }

    // HANDLE TYPES
    const len = types.length;
    if (len === 1) {
      if (isType(action, types[0])) {
        return of(<Output>action);
      }
      if (isBatch(action)) {
        const batchAction: BatchAction<Input> = <BatchAction<Input>>(
          (<unknown>action)
        );
        return from(
          <Output[]>(
            batchAction.payload.filter(childAction =>
              isType(childAction, types[0]),
            )
          ),
        );
      }
    } else if (isAnyType(action, types)) {
      return of(<Output>action);
    } else if (isBatch(action)) {
      const batchAction: BatchAction<Input> = <BatchAction<Input>>(
        (<unknown>action)
      );
      return from(
        <Output[]>(
          batchAction.payload.filter(childAction =>
            isAnyType(childAction, types),
          )
        ),
      );
    }
    return EMPTY;
  });
}

export default ofType;

export const createRootEpic = function (...epics) {
  return (action$, store$, dependencies) =>
    combineEpics(...epics)(action$, store$, dependencies).pipe(
      catchError((error, source) => {
        getLogger().error('Error during epic', error);
        return source;
      }),
    );
};
