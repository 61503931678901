import { css } from '@ui-system/css';
import { IMAGE_UUID, ImageProps } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import { isReactNative } from 'is-react-native';
import isString from 'lodash/isString';

import { makeFinalStyleHook } from './internal';

const useImageDefaultStyle = makeStyle<Partial<ImageProps>>(
  ({ props }) => css`
    height: ${isString(props.height)
      ? props.height
      : `${String(props.height)}px`};
    width: ${isString(props.width) ? props.width : `${String(props.width)}px`};
    ${isReactNative() ? '' : `object-fit: ${props.resizeMode};`}
  `,
);

export const useImageStyle = makeFinalStyleHook<Partial<ImageProps>>(
  IMAGE_UUID,
  useImageDefaultStyle,
);
