import CommonService from '@mmw/services-core-common';
import ConsumerProductService from '@mmw/services-core-consumer-product';
import ResourceService from '@mmw/services-core-resource';
import StoreLocatorService from '@mmw/services-core-store-locator';
import {
  setClientFingerprintService,
  setCommonService,
  setConsumerProductService,
  setResourceService,
  setStoreLocatorService,
} from '@mmw/services-holder';
import BrowserFingerprint from '@mmw/web-common-browser-fingerprint';
import { BrowserFingerprintOptions } from '@mmw/web-common-browser-fingerprint/types';

const commonService = new CommonService({});
setCommonService(commonService);

const resourceService = new ResourceService({});
setResourceService(resourceService);

const clientFingerprint = new BrowserFingerprint(
  {} as BrowserFingerprintOptions,
);
setClientFingerprintService(clientFingerprint);

const storeLocatorService = new StoreLocatorService({});
setStoreLocatorService(storeLocatorService);

const consumerProductService = new ConsumerProductService({});
setConsumerProductService(consumerProductService);
