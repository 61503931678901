import { fastHash } from '@hasher/object-hash';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MUIMenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { css } from '@ui-system/css';
import { useMenuItemStyle, useMenuStyle } from '@ui-system/default-styles';
import {
  MenuContainerProps,
  MenuContainerType,
  MenuItemComponentProps,
  MenuOption,
  MenuOptionInterface,
  MenuProps,
  MenuType,
} from '@ui-system/interfaces-menu';
import UI from '@ui-system/ui';
import { useBoolean, useHover } from 'ahooks';
import { map } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

interface MenuItemProps {
  item: MenuOptionInterface;
  style: MenuProps['menuItemStyle'];
  Component?: React.FC<MenuItemComponentProps>;
}

const MenuItem: React.FC<MenuItemProps> = ({
  item,
  style,
  Component,
}: MenuItemProps) => {
  const menuOption = useMemo(() => new MenuOption(item), [item]);
  const ref = useRef();
  const hover = useHover(ref);
  const menuItemFinalStyle = useMenuItemStyle(style, null, null, {
    isHovering: hover,
  });

  const handleClick = menuOption.useOnClick();

  const onClick = useCallback(() => {
    if (menuOption.onClick) menuOption.onClick();
    else handleClick();
  }, [handleClick, menuOption]);
  const visible = menuOption.useIsVisible();

  if (!visible) return null;
  return (
    <MUIMenuItem
      onClick={onClick}
      key={fastHash(menuOption)}
      style={menuItemFinalStyle}
      // @ts-ignore
      ref={ref}
    >
      {Component ? (
        <Component option={menuOption} isHovering={hover} onClick={onClick} />
      ) : (
        <UI.Caption
          i18n={menuOption.label}
          modifiers={`center, ${hover ? 'white' : ''}`}
          {...(menuOption.labelProps || EMPTY_OBJECT)}
        />
      )}
    </MUIMenuItem>
  );
};

const STYLE = {
  zIndex: 999,
};

const MenuView: MenuType = ({
  isOpen,
  anchorEl,
  options,
  children,
  onClose,
  style,
  menuItemStyle,
  MenuItemComponent,
}: MenuProps): React.ReactElement => {
  const menuStyle = useMenuStyle(style, null, null);

  return (
    <Popper open={isOpen} anchorEl={anchorEl?.current} style={STYLE}>
      <UI.Animation>
        <ClickAwayListener onClickAway={onClose}>
          <div>
            {children || (
              <MenuList autoFocusItem={isOpen} style={menuStyle}>
                {map(options, item => (
                  <MenuItem
                    item={item}
                    style={menuItemStyle}
                    key={fastHash(item)}
                    Component={MenuItemComponent}
                  />
                ))}
              </MenuList>
            )}
          </div>
        </ClickAwayListener>
      </UI.Animation>
    </Popper>
  );
};

const DIV_STYLE = css`
  display: flex;
  height: 100%;
`;

export const MenuContainer: MenuContainerType = ({
  children,
  menuChildren,
  trigger,
  options,
  onToggleSubMenu,
  customItemContainerStyle,
  ...rest
}: MenuContainerProps) => {
  const ref = useRef();
  const [isOpen, { setFalse, toggle }] = useBoolean(false);
  const onClick = useCallback(() => {
    if (trigger?.includes('click')) toggle();
  }, [toggle, trigger]);

  const onMouse = useCallback(() => {
    if (trigger?.includes('hover')) toggle();
  }, [toggle, trigger]);

  useEffect(() => {
    if (onToggleSubMenu) {
      onToggleSubMenu(isOpen);
    }
  }, [isOpen]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus,jsx-a11y/no-static-element-interactions
    <div
      // @ts-ignore
      ref={ref}
      style={customItemContainerStyle || DIV_STYLE}
      onMouseEnter={onMouse}
      onMouseLeave={onMouse}
      onClick={onClick}
    >
      {children}
      <MenuView
        isOpen={isOpen}
        onClose={setFalse}
        anchorEl={ref}
        options={options}
        {...rest}
      >
        {menuChildren}
      </MenuView>
    </div>
  );
};

MenuContainer.defaultProps = {
  trigger: 'hover',
};

MenuView.MenuContainer = MenuContainer;

export default MenuView;
