const NAMES = {
  REQUIRED: 'required',
  AVAILABLE: 'available',
  MAX_LENGTH: 'maxLength',
  MIN_LENGTH: 'minLength',
  INVALID: 'invalid',
  PATTERN: 'pattern',
};

export default NAMES;

export const { MAX_LENGTH, MIN_LENGTH, REQUIRED, AVAILABLE, INVALID, PATTERN } =
  NAMES;
