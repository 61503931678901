import { ContainerPosition } from '@ui-system/interfaces-container';
import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';

import createFromProps from '../create-from-props';
import { FromProps } from '../types';

const applyPosition: F.Function<[Theme, ContainerPosition]> & FromProps = (
  theme,
  position: ContainerPosition,
) => {
  if (!position) return '';
  return `position: ${position}`;
};
applyPosition.fromProps = createFromProps(applyPosition, 'position');

export default applyPosition;
