import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';
import { isString } from 'lodash';

import createFromProps from '../create-from-props';
import { FromProps } from '../types';

type DimensionsParams = { w: number | string; h: number | string } & Record<
  string,
  any
>;

type ApplyDimensionsFnType = F.Function<[Theme, DimensionsParams, string]> &
  FromProps;

export const applyHeight: ApplyDimensionsFnType = (
  theme,
  prop: DimensionsParams,
) => `
    ${prop && (isString(prop) ? `height: ${prop}` : `height: ${prop}px`)};
 `;

export const applyWidth: ApplyDimensionsFnType = (
  theme,
  prop: DimensionsParams,
) => `
    ${prop && (isString(prop) ? `width: ${prop}` : `width: ${prop}px`)};
 `;

applyHeight.fromProps = createFromProps(applyHeight, 'h');
applyWidth.fromProps = createFromProps(applyWidth, 'w');
