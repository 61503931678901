import { ResourceBundle } from '@mmw/services-core-resource/types';
import I18nProvider, {
  I18nProviderProps,
} from '@mmw/ui-providers-i18n-provider';
import get from 'lodash/get';
import React from 'react';
import { U } from 'ts-toolbelt';

import {
  useBundleName,
  useLanguage,
  useResourceBundle,
  useSecondaryBundleName,
} from '../hooks';

type Props = Partial<I18nProviderProps> & {
  customBundle?: U.Nullable<Record<string, ResourceBundle>>;
};

const ConnectedI18nProvider: React.FC<Props> = ({
  children,
  customBundle,
  ...props
}: Props) => {
  const defaultLanguage = useLanguage();
  const bundleName = useBundleName();
  const secondaryBundleName = useSecondaryBundleName();
  const resources = useResourceBundle();
  const customResources = get(customBundle, defaultLanguage);

  return (
    <I18nProvider
      defaultLanguage={defaultLanguage}
      bundleName={bundleName}
      secondaryBundleName={secondaryBundleName}
      resources={customResources || resources}
      {...props}
    >
      {children}
    </I18nProvider>
  );
};

export default ConnectedI18nProvider;
