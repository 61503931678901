import { CircularProgress as MuiSpinner } from '@material-ui/core';
import { Container } from '@ui-system/common-container';
import { css } from '@ui-system/css';
import defaultProps from '@ui-system/default-props/spinner';
import { ContainerProps } from '@ui-system/interfaces-container';
import {
  SpinnerProps,
  SpinnerSize,
  SpinnerType,
} from '@ui-system/interfaces-spinner';
import UI from '@ui-system/ui';
import React, { CSSProperties } from 'react';

const OVERLAY_STYLE = css`
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  opacity: 0.4;
`;

const SIZES_MAP: Record<SpinnerSize, number> = {
  tiny: 15,
  small: 25,
  medium: 40,
  large: 50,
  giant: 70,
};

function getSize(size: SpinnerSize | undefined) {
  if (!size) return SIZES_MAP.medium;
  return SIZES_MAP[size];
}

const Overlay: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren) => (
  <UI.Container style={OVERLAY_STYLE}>{children}</UI.Container>
);

const SpinnerContainer: React.FC<ContainerProps> = ({
  children,
  visible,
  ...props
}: ContainerProps) => (
  <Container visible={visible} justify="center" f={1} align="center" {...props}>
    {children}
  </Container>
);

const Spinner: SpinnerType = ({
  color,
  size,
  style,
  visible = false,
  coverScreen,
  ...props
}: SpinnerProps) => {
  if (coverScreen) {
    return (
      <>
        <SpinnerContainer visible={visible} w="100vw" h="100vh" {...props}>
          <MuiSpinner
            // @ts-ignore
            color={color}
            size={getSize(size)}
            style={style as CSSProperties}
          />
        </SpinnerContainer>
        {visible ? <Overlay /> : null}
      </>
    );
  }
  return (
    <SpinnerContainer visible={visible} {...props}>
      <MuiSpinner
        // @ts-ignore
        color={color}
        size={getSize(size)}
        style={style as CSSProperties}
      />
    </SpinnerContainer>
  );
};

Spinner.defaultProps = defaultProps;

export default Spinner;
