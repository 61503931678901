import AUDIT from '@mmw/constants-i18n-audit';
import { I18N as AUDIT_CAMPAIGN_STATUS } from '@mmw/constants-i18n-audit-campaign-status';
import AUTHENTICATION from '@mmw/constants-i18n-authentication';
import BUTTONS from '@mmw/constants-i18n-buttons';
import CAMERA from '@mmw/constants-i18n-camera';
import CAMPAIGN_STATUS from '@mmw/constants-i18n-campaign-status';
import COMMON from '@mmw/constants-i18n-common';
import FIELDS from '@mmw/constants-i18n-fields';
import FUNCTIONALITIES from '@mmw/constants-i18n-functionalities';
import IBV from '@mmw/constants-i18n-ibv';
import LANGUAGES from '@mmw/constants-i18n-languages';
import MESSAGES from '@mmw/constants-i18n-messages';
import TERMS_POPUP from '@mmw/constants-i18n-rec-amer-terms-popup';
import STORE from '@mmw/constants-i18n-store';
import VALIDATIONS from '@mmw/constants-i18n-validations';

export * from './types';

export default {
  AUTHENTICATION,
  BUTTONS,
  MESSAGES,
  CAMPAIGN_STATUS,
  COMMON,
  FIELDS,
  LANGUAGES,
  VALIDATIONS,
  CAMERA,
  AUDIT_CAMPAIGN_STATUS,
  IBV,
  STORE,
  AUDIT,
  FUNCTIONALITIES,
  TERMS_POPUP,
};
