import { Shadow } from '@ui-system/css';
import { Style } from '@ui-system/interfaces/types';
import { nanoid } from 'nanoid';
import React from 'react';
import { FlatListProps } from 'react-native';
import { U } from 'ts-toolbelt';
// import { Elevation } from '@ui-system/css';

export interface Info<DataItem = any> {
  item: DataItem;
  index: number;
}

export interface ListProps
  extends Partial<Omit<FlatListProps<Info>, 'renderItem' | 'getItemLayout'>> {
  data: any[];
  renderItem: (info: Info) => U.Nullable<React.ReactElement>;
  style?: Style;
  contentContainerStyle?: Style;
  onLoadMore?: (info: { distanceFromEnd: number }) => void;
  keyExtractor: (item: any, index: number) => string;
  // itemElevation?: Elevation;
  itemElevation?: Shadow;
  getItemLayout?: (
    data: any[],
    index: number,
  ) => { length: number; offset: number; index: number };
  // onLoadMore: () => void;
  // initialNumToRender?: number;
  // HeaderComponent?: ReactNode;
  // ListFooterComponent?: ReactNode;
}

export const LIST_UUID = nanoid();
export const LIST_CONTENT_CONTAINER_UUID = nanoid();

export type ListType = React.FC<ListProps>;
