import {
  NAMESPACE as AVAILABLE_COUNTRIES_NAMESPACE,
  reducer as availableCountriesReducer,
} from '@mmw/redux-store-common-available-countries';
import {
  NAMESPACE as CURRENT_SOB_NAMESPACE,
  reducer as currentSobReducer,
} from '@mmw/redux-store-current-salesorgbrand';
import {
  NAMESPACE as FAST_FORM_NAMESPACE,
  reducer as fastFormReducer,
} from '@mmw/redux-store-fast-form';
import {
  NAMESPACE as I18N_NAMESPACE,
  reducer as i18nReducer,
} from '@mmw/redux-store-i18n';
import {
  NAMESPACE as SOBS_NAMESPACE,
  reducer as sobsReducer,
} from '@mmw/redux-store-salesorgbrands';
import { routerModule } from '@mmw/ui-web-libraries-react-router/module';
import { combineReducers } from 'redux';

export default combineReducers({
  ...routerModule.reducerMap,
  [FAST_FORM_NAMESPACE]: fastFormReducer,
  [I18N_NAMESPACE]: i18nReducer,
  [AVAILABLE_COUNTRIES_NAMESPACE]: availableCountriesReducer,
  [SOBS_NAMESPACE]: sobsReducer,
  // [LOGIN_BY_PASSWORD]: loginByPasswordReducer,
  [CURRENT_SOB_NAMESPACE]: currentSobReducer,
});
