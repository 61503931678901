import { I18nShape } from '@mmw/constants-i18n';
import { EMPTY_OBJECT } from '@mmw/constants-utils';
import { deepmerge } from '@shared-utils/object';
import find from 'lodash/find';
import noop from 'lodash/noop';
import { useEffect, useMemo } from 'react';
import { F, U } from 'ts-toolbelt';
import strIncludes from 'voca/includes';

import { useSystemNotifications, useToastNotification } from '../hooks';
import { NotificationType } from '../types';

const EMPTY_NOTFICATION_CONFIG = {
  condition: false,
  message: null,
  type: null,
  onDestroy: noop,
  error: null,
};

export type NotificationConfigItem = {
  condition: U.Nullable<boolean>;
  type: NotificationType;
  message: I18nShape | string;
  onDestroy?: F.Function;
  error?: U.Nullable<Error | any>;
};

export interface MultiNotificationsListenerProps {
  notifificationConfigs: NotificationConfigItem[];
  additionalConfigs?: Record<string, any>;
}

const MultiNotificationsListener: React.FC<MultiNotificationsListenerProps> = ({
  notifificationConfigs,
  additionalConfigs = EMPTY_OBJECT,
}: MultiNotificationsListenerProps) => {
  const notifyProps = (find(notifificationConfigs, item => item.condition) ||
    deepmerge(
      EMPTY_NOTFICATION_CONFIG,
      EMPTY_OBJECT,
    )) as NotificationConfigItem;
  let { condition } = notifyProps;
  const { type, message, onDestroy, error } = {
    ...EMPTY_NOTFICATION_CONFIG,
    ...notifyProps,
  };
  const addNotification = useSystemNotifications();
  const isUnknownError =
    error &&
    (strIncludes(error, 'Unknown') ||
      strIncludes(error, 'UNKNOWN') ||
      error.status === 500);
  if (error && !isUnknownError) {
    condition = false;
  }
  const options = useMemo(
    () => ({
      type,
      onDestroy,
      ...additionalConfigs,
    }),
    [additionalConfigs, onDestroy, type],
  );

  useEffect(() => {
    if (error && !isUnknownError) {
      addNotification(
        {
          title: message,
          content: {
            key: '',
            message: '',
          },
        },
        { onDismiss: onDestroy, appearance: 'error' },
      );
    }
  }, [error, isUnknownError, condition]);

  useToastNotification(message || error, isUnknownError || condition, options);

  return null;
};

export default MultiNotificationsListener;
