import qs from 'qs';

const BASE_PATH = '/v1/admin/demo-device';

export const RegisterDemoDevicePath = (language): string =>
  `${BASE_PATH}/register-device?language=${language}`;
export const GetDevicesPath = (
  orgunitID: number,
  language: string,
  salesOrgBrandID: number,
  campaignID?: number,
): string =>
  `${BASE_PATH}/devices?${qs.stringify({
    orgunitID,
    language,
    salesOrgBrandID,
    campaignID,
  })}`;

export const RemoveDeviceFromShowRoomPath = (): string =>
  `${BASE_PATH}/show-room`;

export const ChangeDemoDeviceAsReadyPath = (): string =>
  `${BASE_PATH}/show-room/ready`;

export const ChangeDemoDeviceAsNotReadyPath = (): string =>
  `${BASE_PATH}/show-room/not-ready`;
