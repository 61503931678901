import { PERSON as FIELDS } from '@mmw/constants-fields-names';
// import {
//   PERSON as VALIDATIONS,
// } from '@mmw/constants-fields-validations';
import {
  // MAX_LENGTH,
  // REQUIRED,
  INVALID,
} from '@mmw/constants-validation-names';

const namespace = 'validation';

const PERSON = {
  FIRSTNAME: {},
  LASTNAME: {},
  EMAIL: {
    INVALID: {
      key: `${namespace}.${FIELDS.EMAIL}.${INVALID}`,
      message: 'Email must be a valid.',
    },
  },
};

export default PERSON;
