import { I18nShape } from '@mmw/constants-i18n';
import { Style } from '@ui-system/interfaces/types';
import { TypographyVariant } from '@ui-system/interfaces-typography';
import UI from '@ui-system/ui';
import React from 'react';
import { U } from 'ts-toolbelt';

const REQUIRED_STYLE = { margin: 0 };

interface FieldLabelProps {
  label?: U.Nullable<I18nShape | string>;
  component?: React.ReactElement;
  style?: Style;
  modifiers?: string;
  variant?: TypographyVariant;
  showRequiredMark?: boolean;
}

export const FieldLabel: React.FC<FieldLabelProps> = ({
  label,
  component,
  style,
  modifiers,
  variant,
  showRequiredMark,
}: FieldLabelProps) => {
  if (!label && !component) return null;
  return (
    <UI.Container gap={1} align="center" m="1, 0">
      {label ? (
        <UI.Typography
          style={style}
          i18n={label}
          modifiers={modifiers}
          variant={variant || 'caption'}
        />
      ) : null}
      {component}
      {showRequiredMark ? (
        <UI.Caption style={REQUIRED_STYLE} modifiers="error">
          *
        </UI.Caption>
      ) : null}
    </UI.Container>
  );
};

interface FieldErrorProps {
  style?: Style;
  errorMessage?: I18nShape;
}

export const FieldError: React.FC<FieldErrorProps> = ({
  style,
  errorMessage,
}: FieldErrorProps) => {
  if (!errorMessage) return null;
  return (
    <UI.Container gap={1} m="1">
      <UI.Icon name="error" size={15} color="error" />
      <UI.Typography style={style} i18n={errorMessage} variant="caption" />
    </UI.Container>
  );
};

interface FieldSuccessProps {
  message: I18nShape;
}

export const FieldSuccess: React.FC<FieldSuccessProps> = ({
  message,
}: FieldSuccessProps) => (
  <UI.Container gap={1} align="center" m="1">
    <UI.Icon name="check" size={15} color="success" />
    <UI.Typography i18n={message} variant="caption" />
  </UI.Container>
);
