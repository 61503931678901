import { O, PartialDeep } from '@utils/ts';
import deepMerge from 'deepmerge';

import { EMPTY_OBJECT } from './constants';

export function deepmerge<
  T1 extends O.Object,
  T2 extends O.Object = PartialDeep<T1>,
>(o1: T1, o2?: T2): T1 {
  return deepMerge(
    o1 || (EMPTY_OBJECT as T1),
    o2 || (EMPTY_OBJECT as T2),
  ) as T1;
}

export const deepMergeAll = deepMerge.all;
