import { css } from '@ui-system/css';
import { TAB_BAR_UUID, TAB_UUID, TabBarProps } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useTabBarDefaultStyle = makeStyle<Partial<TabBarProps>>(
  () => css`
    overflow-x: auto;
  `,
);
const useTabDefaultStyle = makeStyle<Partial<TabBarProps>>(
  () => css`
    min-height: 35px;
  `,
);

export const useTabBarStyle = makeFinalStyleHook<Partial<TabBarProps>>(
  TAB_BAR_UUID,
  useTabBarDefaultStyle,
);

export const useTabStyle = makeFinalStyleHook<Partial<TabBarProps>>(
  TAB_UUID,
  useTabDefaultStyle,
);
