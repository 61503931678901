import { HELP } from '@mmw/constants-i18n-common';

const namespace = 'help';

const TITLE = HELP;

const PAGE = {
  TITLE,
  DESCRIPTION: {
    key: `${namespace}.description`,
    message:
      "Please choose one of the following categories. If you can't find an answer to your question here, you may send us your question creating a support ticket.",
  },
};

const categoryNamespace = `${namespace}.category`;

const CATEGORY = {
  REGISTRATION_OF_INSTALLATIONS: {
    key: `${categoryNamespace}.registrationOfInstallations`,
    message: 'Registration of installations',
  },
  CERTIFICATION_OF_INSTALLERS: {
    key: `${categoryNamespace}.certificationOfInstallers`,
    message: 'Certification of Installers',
  },
  TECHNICAL_QUESTIONS: {
    key: `${categoryNamespace}.technicalQuestions`,
    message: 'Technical Questions',
  },
  PROTRUST_WARRANTY: {
    key: `${categoryNamespace}.protrustWarranty`,
    message: 'ProTrust Warranty',
  },
  PROPORTAL: {
    key: `${categoryNamespace}.proportal`,
    message: 'ProPortal',
  },
  SUNSNAP: {
    key: `${categoryNamespace}.sunsnap`,
    message: 'SunSnap',
  },
  OTHERS: {
    key: `${categoryNamespace}.others`,
    message: 'Others',
  },
};

const ticketNamespace = `${namespace}.ticket`;

const TICKET = {
  TITLE: {
    key: `${ticketNamespace}.title`,
    message: 'create support ticket',
  },
  FORM: {
    LABEL: {
      SUBJECT: {
        key: `${ticketNamespace}.label.subject`,
        message: 'Subject',
      },
      SENDCOPY: {
        key: `${ticketNamespace}.label.sendCopy`,
        message: 'I want to receive a copy of this request by email.',
      },
      QUESTION: {
        key: `${ticketNamespace}.label.question`,
        message: 'Your Question',
      },
    },
    PLACEHOLDER: {
      EMAIL: {
        key: `${ticketNamespace}.placeholder.email`,
        message: 'youremail@solarcompany.com',
      },
      QUESTION: {
        key: `${ticketNamespace}.placeholder.question`,
        message: 'Type your question',
      },
    },
  },
  CARD: {
    DESCRIPTION: {
      key: `${ticketNamespace}.card.description`,
      message:
        "If you haven't found an answer to your question or need help regarding other topics, you may send is your question by filling out our contact form.",
    },
    BUTTON: {
      key: `${ticketNamespace}.card.button`,
      message: 'create support ticket',
    },
  },
  RESPONSE: {
    SUCCESS: {
      key: `${ticketNamespace}.response.success`,
      message: 'Ticket sent successfully.',
    },
    ERROR: {
      key: `${ticketNamespace}.response.error`,
      message:
        'An internal error happened. Sorry about this. Please try later!',
    },
  },
};

const I18N = {
  PAGE,
  CATEGORY,
  TICKET,
};

export default I18N;
