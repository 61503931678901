export type EMPTY_ARRAY_TYPE = readonly any[];

export const EMPTY_ARRAY = <[]>Object.freeze([]);

type ReadonlyObject = {
  readonly [x: string]: unknown;
};

export type EMPTY_OBJECT_TYPE = ReadonlyObject;

// eslint-disable-next-line @typescript-eslint/ban-types
export const EMPTY_OBJECT = <{}>Object.freeze({});

export const EMPTY_STRING = '';

export const NUMBER_REGEX = /^[0-9]*$/;

export const STRING_HTML_REGEX = /<[a-z][\s\S]*>/;

export const TYPOGRAPHY_HTML_REGEX = /(.<strong>)/;

export const BYTE_ORDER_MARK = '\ufeff'; // used to mark start/end of text stream
