import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getFormIsSuccessfullyValidatedSelector,
  getFormIsUnsuccessfullySubmittedSelector,
} from '../../store/stateSelector';
import { FormId } from '../../types';
import useFastFormProviderContext from '../useFastFormProviderContext';
import useFormSubmitCount from '../useFormSubmitCount';

function useIsUnsuccessfullySubmitted(formId: FormId) {
  const selector = useCallback(
    state => getFormIsUnsuccessfullySubmittedSelector(formId)(state),
    [formId],
  );
  return useSelector(selector);
}

function useIsSuccessfullyValidated(formId: FormId) {
  const selector = useCallback(
    state => getFormIsSuccessfullyValidatedSelector(formId)(state),
    [formId],
  );
  return useSelector(selector);
}

function onSubmitErrorCallback() {
  const { formId, onSubmitError } = useFastFormProviderContext();
  const isError = useIsUnsuccessfullySubmitted(formId);
  const submitCount = useFormSubmitCount();
  const isValidated = useIsSuccessfullyValidated(formId);
  useEffect(() => {
    if (!isError) {
      return;
    }
    if (!isValidated) {
      return;
    }
    if (submitCount > 0) {
      onSubmitError();
    }
  }, [onSubmitError, isError, submitCount]);
}

export default onSubmitErrorCallback;
