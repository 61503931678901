import { css } from '@ui-system/css';
import { MENU_ITEM_UUID, MENU_UUID, MenuProps } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useMenuDefaultStyle = makeStyle<Partial<MenuProps>>(
  ({ theme, utils }) => css`
    ${utils.applyBackgroundColor(theme, 'white')}
  `,
);
const useMenuItemDefaultStyle = makeStyle<Partial<{ isHovering }>>(
  ({ theme, utils, props }) => css`
    ${utils.applyJustify(theme, 'center')};
    ${utils.applyPadding(theme, 2)};
    ${props?.isHovering ? utils.applyBackgroundColor(theme, 'black') : ''}
  `,
);

export const useMenuStyle = makeFinalStyleHook<Partial<MenuProps>>(
  MENU_UUID,
  useMenuDefaultStyle,
);

export const useMenuItemStyle = makeFinalStyleHook<Partial<{ isHovering }>>(
  MENU_ITEM_UUID,
  useMenuItemDefaultStyle,
);
