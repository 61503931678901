import { convert } from 'convert';

export const KM_RADIUS_OPTIONS = [
  {
    value: 5,
    label: '5 km',
  },
  {
    value: 10,
    label: '10 km',
  },
  {
    value: 20,
    label: '20 km',
  },
  {
    value: 30,
    label: '30 km',
  },
  {
    value: 40,
    label: '40 km',
  },
  {
    value: 50,
    label: '50 km',
  },
];

export const MI_RADIUS_OPTIONS = [
  {
    value: convert(5, 'miles').to('kilometers'),
    label: '5 miles',
  },
  {
    value: convert(10, 'miles').to('kilometers'),
    label: '10 miles',
  },
  {
    value: convert(20, 'miles').to('kilometers'),
    label: '20 miles',
  },
  {
    value: convert(30, 'miles').to('kilometers'),
    label: '30 miles',
  },
  {
    value: convert(40, 'miles').to('kilometers'),
    label: '40 miles',
  },
  {
    value: convert(50, 'miles').to('kilometers'),
    label: '50 miles',
  },
];
