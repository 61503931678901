import { I18nShape } from '@mmw/constants-i18n';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import { deepmerge } from '@shared-utils/object';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { OptionsWithExtraProps, useSnackbar, VariantType } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { U } from 'ts-toolbelt';

import { NotificationType } from '../types';

const DEFAULT_TOAST_CONFIG = {
  autoHideDuration: 5000,
};

export function useToastNotificationFunction(
  configs?: OptionsWithExtraProps<VariantType>,
) {
  const translate = useTranslate();
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    (message: I18nShape | string, variant: VariantType = 'default') => {
      let text = message;
      if (isObject(text)) {
        text = translate(message);
      }
      return enqueueSnackbar(text, {
        variant,
        autoHideDuration: 5000,
        ...configs,
      });
    },
    [configs, enqueueSnackbar, translate],
  );
}

function useToastNotification(
  content: U.Nullable<I18nShape | string>,
  condition: boolean,
  toastConfig?: any,
) {
  const { enqueueSnackbar } = useSnackbar();
  const translate = useTranslate();
  const type = get(toastConfig, 'type');
  const toastVariant = useMemo(() => {
    if (!type) return 'default';
    return {
      [NotificationType.success]: 'success',
      [NotificationType.error]: 'error',
      [NotificationType.warning]: 'warning',
    }[type];
  }, [type]);
  const message = useMemo(() => {
    if (isString(content)) {
      return content;
    }
    return translate(content);
  }, [content, translate]);

  const configs = deepmerge(DEFAULT_TOAST_CONFIG, {
    onClose: toastConfig?.onDestroy,
    ...toastConfig,
  });

  useEffect(() => {
    if (condition && message) {
      enqueueSnackbar(message, {
        variant: toastVariant,
        ...configs,
      });
    }
  }, [condition, message]);
}

export default useToastNotification;
