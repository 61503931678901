import contextualConfig from '@mmw/contextual-config';
import { useSetFieldValue } from '@mmw/redux-store-fast-form/hooks';
import { getUserDeviceCountryByTimeZone } from '@mmw/web-utils-detect-browser-language';
import {
  setAlreadySearch,
  setLastSearchByUserLocation,
  useLastSearchByUserLocation,
} from '@store-locator/store-creator';
import {
  useOriginCoordinates,
  useSearchStoresByLocation,
} from '@store-locator/store-creator/hooks';
import { delay, reject } from 'lodash';
import isNumber from 'lodash/isNumber';
import React, { useEffect } from 'react';
import { F } from 'ts-toolbelt';

import { useCustomUrlPathAsObj, useCustomUrlRequestObj } from '../context';
import { useSetMeasureUnits } from '../context/measure-units';
import { STORE_LOCATOR_FIELDPATHS } from './form/configs';

const {
  application: { logger },
} = contextualConfig;

interface TabContentProps {
  currentTab: number;
  children: React.ReactNode;
  language: string;
  setError?: F.Function;
  setLoading: F.Function<[boolean]>;
  setTab: F.Function;
  currentRadius: number;
}

const TabContentHandler: React.FC<TabContentProps> = ({
  currentTab,
  children,
  language,
  setLoading,
  setError,
  setTab,
  currentRadius,
}: TabContentProps) => {
  const searchStores = useSearchStoresByLocation();
  const lastCoordinates = useOriginCoordinates();
  const customUrlRequestObj = useCustomUrlRequestObj();
  const customUrlPathsAsObj = useCustomUrlPathAsObj();
  const lastSearchByUserLocation = useLastSearchByUserLocation();
  const setFieldValue = useSetFieldValue();
  const setMeasureUnits = useSetMeasureUnits();

  useEffect(() => {
    async function getCurrentLocationCoordiantes() {
      try {
        const { geolocation } = window.navigator;
        setLoading(true);
        if (!geolocation) {
          setLoading(false);
          throw new Error('GEOLOCATION_NOT_SUPPORTED');
        }

        const getCurrentLocation = async (): Promise<GeolocationPosition> =>
          new Promise(resolve => {
            geolocation.getCurrentPosition(
              res => {
                resolve(res);
              },
              error => {
                if (error.code === error.PERMISSION_DENIED) {
                  setLoading(false);
                  setTab(0);
                  if (setError) {
                    setError(error);
                  }
                  reject(error);
                }
              },
              { timeout: 60000 },
            );
          });

        const result = await getCurrentLocation();
        return {
          lat: result?.coords?.latitude,
          lng: result?.coords?.longitude,
        };
      } catch (err) {
        logger.error(
          'Failed to search stores using current user location, error=%O',
          err,
        );
        setTab(0);
        return null;
      }
    }
    if (currentTab === 1) {
      if (!lastSearchByUserLocation) {
        getCurrentLocationCoordiantes().then(result => {
          if (!result?.lat || !result?.lng) return;
          const { lat, lng } = result;
          setMeasureUnits(getUserDeviceCountryByTimeZone());
          setLoading(false);

          if (
            !lastCoordinates?.lat ||
            (isNumber(lastCoordinates?.lat) && lastCoordinates?.lat !== lat)
          ) {
            searchStores({
              language,
              lat,
              lng,
              radius: currentRadius,
              country: null,
              ...customUrlRequestObj,
              ...customUrlPathsAsObj,
            });
            setLastSearchByUserLocation(true);
            setAlreadySearch(true);
          }
        });
      }
      delay(
        () => setFieldValue(STORE_LOCATOR_FIELDPATHS.country.$path, ''),
        500,
      );
    }
  }, [currentTab]);

  return <>{children}</>;
};

export default TabContentHandler;
