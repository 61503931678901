import { css } from '@ui-system/css';
import { SCROLL_UUID, ScrollProps } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import isString from 'lodash/isString';

import { makeFinalStyleHook } from './internal';

const useScrollDefaultStyle = makeStyle<Partial<ScrollProps>>(
  ({ props }) => css`
    height: ${isString(props.height)
      ? props.height
      : `${String(props.height)}px`};
    max-height: ${isString(props.maxHeight)
      ? props.maxHeight
      : `${String(props.maxHeight)}px`};
    width: ${isString(props.width) ? props.width : `${String(props.width)}px`};
    max-width: ${isString(props.maxWidth)
      ? props.maxWidth
      : `${String(props.maxWidth)}px`};
  `,
);

export const useScrollStyle = makeFinalStyleHook<Partial<ScrollProps>>(
  SCROLL_UUID,
  useScrollDefaultStyle,
);
