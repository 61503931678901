// import {
//   CAMPAIGN as FIELDS,
// } from '@mmw/constants-fields-names';
import { INVALID } from '@mmw/constants-validation-names';

const namespace = 'validation';

const CAMPAIGN = {
  ID: {},
  RULES: {
    INVALID: {
      key: `${namespace}.campaign.${INVALID}`,
      message:
        'A problem in your selection was detected. Please check the campaign terms and conditions.',
    },
  },
};

export default CAMPAIGN;
