import ReactGA from 'react-ga4';

function listenForHistory(history) {
  history.listen(location => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname }); // Update the user's current page
    // ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });
}

export default listenForHistory;
