import { PopoverProps } from '@ui-system/interfaces-popover';

const defaultProps: Partial<PopoverProps> = {
  sx: {
    pointerEvents: 'none',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  marginThreshold: 32,
  disableRestoreFocus: true,
};
export default defaultProps;
