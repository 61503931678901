import { PROJECT } from '@mmw/constants-fields-names';
import { REQUIRED } from '@mmw/constants-validation-names';

const namespace = 'validation';

const REGISTRATION = {
  NAME: {
    REQUIRED: {
      key: `${namespace}.${PROJECT.NAME}.${REQUIRED}`,
      message: 'Registration name is required',
    },
  },
  PRODUCT: {
    NAME: {
      REQUIRED: {
        key: `${namespace}.${PROJECT.PRODUCT.NAME}.${REQUIRED}`,
        message: 'Product name is required',
      },
    },
  },
};

export default REGISTRATION;
