const namespace = 'grohe';
const signUpNamespace = 'signup';
const bankAccountNamespace = 'bankAccount';
const disclaimerNamespace = 'disclaimer';

const VALIDATION = {
  ERP_MATERIAL_KEY_VALIDATION: {
    key: `${namespace}.erpMaterialKeyValidation`,
    message: 'The article number did not match.',
  },
};

const SIGNUP_PAGE = {
  WELCOME: {
    TITLE: {
      key: `${namespace}.${signUpNamespace}.welcomeTitle`,
      message:
        '<span>Willkommen zur <strong>GROHE CASHBACK</strong> Registrierung für Sie als Sanitärunternehmen.</span>',
    },
    DESCRIPTION: {
      key: `${namespace}.${signUpNamespace}.description`,
      message:
        'Im Rahmen unseres Meisterwerker-Programms haben Sie vom 01.01.21 bis 30.04.2021 die Möglichkeit die Aktionsprodukte (finden Sie in den Teilnahmebedingungen) zu registrieren und den jeweiligen CashBack zu erhalten.',
    },
  },
  LOGIN_DISCLAIMER: {
    key: `${namespace}.${signUpNamespace}.loginDisclaimer`,
    message:
      'Bitte beachten Sie, dass nur Gewerbliche Sanitärbetriebe mit Eintrag in der Handwerksrolle und deren Mitarbeiter an der Aktion teilnahmeberechtigt sind.',
  },
  SECURITY_TOKEN: {
    key: `grohe.${namespace}.${signUpNamespace}.securityToken`,
    message:
      'Bitte geben Sie das Sicherheitstoken ein, das an Ihre E-Mail gesendet wurde',
  },
  INFORM_EMAIL_LABEL: {
    key: `grohe.${namespace}.${signUpNamespace}.informEmailLabel`,
    message: 'Geben Sie Ihre E-Mail Adresse ein',
  },
  INFORM_PASSWORD_LABEL: {
    key: `grohe.${namespace}.${signUpNamespace}.informPasswordLabel`,
    message: 'Geben Sie Ihr Passwort ein',
  },
  COMPLETE_YOUR_REGISTRATION: {
    key: `grohe.${namespace}.${signUpNamespace}.securityToken`,
    message: 'Bitte geben Sie hier die Daten Ihres Unternehmens ein',
  },
  INSTALLER_DONT_HAVE_VAT_ID: {
    key: `grohe.${namespace}.${signUpNamespace}.installerDontHaveVatId`,
    message: 'umsatzsteuerbefreit',
  },
};

const BANK_ACCOUNT_PAGE = {
  TITLE: {
    key: `grohe.${namespace}.${bankAccountNamespace}.title`,
    message: 'Konto für Gutschriften',
  },
  DESCRIPTION: {
    key: `grohe.${namespace}.${bankAccountNamespace}.description`,
    message:
      'Bitte tragen Sie hier Ihre Bankverbindung ein, die für Überweisungen im Rahmen von Aktionen genutzt werden soll.',
  },
  FORM_HEADER_TITLE: {
    key: `grohe.${namespace}.${bankAccountNamespace}.formHeader`,
    message: 'Bankverbindungen bearbeiten',
  },
};

const BANK_ACCOUNT_DISCLAIMER = {
  DESCRIPTION: {
    key: `${namespace}.${disclaimerNamespace}.description`,
    message: `*Damit wir Ihnen den Cashback ausbezahlen können müssen Sie das von Ihnen angegebenen Bankkonto legitimieren.`,
  },
  DESCRIPTION_2: {
    key: `${namespace}.${disclaimerNamespace}.description2`,
    message: 'Für die Legitimation benutzen Sie bitte diesen Link:',
  },
  DESCRIPTION_3: {
    key: `${namespace}.${disclaimerNamespace}.description3`,
    message:
      'Ohne eine solche Legitimation können wir Ihnen keinen Cashback üb',
  },
};

const I18N = {
  VALIDATION,
  SIGNUP_PAGE,
  BANK_ACCOUNT_PAGE,
  BANK_ACCOUNT_DISCLAIMER,
};

export default I18N;
