import { Country } from '@mmw/services-core-common/types';
import { filter, includes, isEmpty, map } from 'lodash';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AvailableCountriesContext } from '../context';
import { availableCountriesSelector } from '../stateSelector';

const useAvailableCountries = (): Array<Country> => {
  const { restrictedCountries } = useContext(AvailableCountriesContext);
  const availableCountries = useSelector(availableCountriesSelector);
  return useMemo(
    () =>
      filter(
        availableCountries,
        item =>
          isEmpty(restrictedCountries) ||
          includes(restrictedCountries, item.id),
      ),
    [availableCountries, restrictedCountries],
  );
};

export const useAvailableCountriesIds = (): string[] => {
  const countries = useAvailableCountries();
  return useMemo(() => map<Country, string>(countries, c => c.id), [countries]);
};
export default useAvailableCountries;
