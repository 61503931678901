import { TraderJSON } from '@mmw/services-core-store-locator/types';
import useIsVisible from '@mmw/ui-hooks-use-is-visible';
import {
  setSelectedStore,
  useSelectedStore,
} from '@store-locator/store-creator';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React, { useCallback, useEffect, useRef } from 'react';
import { isChrome } from 'react-device-detect';

import {
  useConvertDistance,
  useCurrentMeasureUnits,
} from '../context/measure-units';
import Details from './details';
import OpeningTimes from './OpeningTimes';

interface ArrowProps {
  expanded: boolean;
}

const Arrow: React.FC<ArrowProps> = ({ expanded }: ArrowProps) => (
  <UI.Icon name={expanded ? 'down' : 'next'} size={15} />
);

interface Props {
  store: TraderJSON;
  title: React.ReactElement;
}

const ExpansibleCard: React.FC<Props> = ({ store, title }: Props) => {
  const cardRef = useRef<HTMLInputElement>(null);
  const { isVisible: expanded, toggle, hide } = useIsVisible(false);
  const selectedStore = useSelectedStore();
  const onExpandCard = useCallback(
    e => {
      e?.stopPropagation();
      if (!expanded) {
        setSelectedStore(store);
      } else {
        setSelectedStore(null);
      }
      toggle();
    },
    [expanded, store, toggle],
  );
  const distance = useConvertDistance(store.address.distance as number);
  const currentMeasureUnit = useCurrentMeasureUnits();

  useEffect(() => {
    if (
      expanded &&
      selectedStore &&
      selectedStore?.orgunitID !== store?.orgunitID
    ) {
      hide();
    }
    if (cardRef && cardRef.current) {
      setTimeout(
        () =>
          cardRef?.current?.scrollIntoView?.({
            // XXX: had to add a check because smooth behavior its not working on chrome
            behavior: isChrome ? 'auto' : 'smooth',
            block: 'center',
          }),
        250,
      );
    }
  }, [selectedStore]);

  return (
    <UI.Card square>
      <UI.Card.Content onClick={onExpandCard}>
        <UI.Container justify="space-between" align="center">
          {title}
          <UI.Container align="center" gap={3}>
            <UI.H5
              style={css`
                margin-right: 20px;
              `}
              modifiers="grayA300"
              suffix={` ${currentMeasureUnit}`}
            >
              {distance?.toFixed(2)}
            </UI.H5>
            <Arrow expanded={expanded} />
          </UI.Container>
        </UI.Container>
      </UI.Card.Content>
      <UI.Card.CollapseArea ref={cardRef} in={expanded} unmountOnExit>
        <Details store={store} contentPadding="2, 3" />
        <OpeningTimes store={store} />
      </UI.Card.CollapseArea>
    </UI.Card>
  );
};

export default ExpansibleCard;
