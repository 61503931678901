import { generateColorKit } from '@ui-system/color';
import { createTheme, ThemeMode } from '@ui-system/theme';
import { isReactNative } from 'is-react-native';

const TYPOGRAPHY = {
  fontFamily: {
    regular: 'OpenSans-Regular',
    bold: 'OpenSans-Bold',
    italic: 'OpenSans-Italic',
    thin: 'OpenSans-Regular',
    boldItalic: 'OpenSans-BoldItalic',
    thinItalic: 'OpenSans-Italic',
  },
  colors: {
    [ThemeMode.LIGHT]: {
      onBackground: '#000',
    },
  },
};

const TYPOGRAPHY_WEB = {
  fontFamily: {
    regular: 'Open Sans, sans-serif',
    bold: 'Open Sans, sans-serif',
    italic: 'Open Sans, sans-serif',
    thin: 'Open Sans, sans-serif',
    boldItalic: 'Open Sans, sans-serif',
    thinItalic: 'Open Sans, sans-serif',
  },
  colors: {
    [ThemeMode.LIGHT]: {
      onBackground: '#000',
    },
  },
};

export const REC_COLORS = {
  primary: generateColorKit('#F3B229'),
  secondary: generateColorKit('#2D373C'),
  basicDark: '#5B6872',
  basicLight: '#F1F1F1',
  background: '#ECECE9',
  dark: '#000', // BLACK
  light: '#FFF', // WHITE
  success: '#017E4E',
  error: '#E83222',
  alert: '#FF6200',
  info: '#FFF',
};

export const REC_THEME = createTheme({
  colors: {
    [ThemeMode.LIGHT]: REC_COLORS,
  },
  typography: isReactNative() ? TYPOGRAPHY : TYPOGRAPHY_WEB,
});

export * from './configureComponents';
export * from './StylesProvider';
