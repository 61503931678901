import { THIRD_PARTY_CAMPAIGN_ITEM as FIELDS } from '@mmw/constants-fields-names';
import { INVALID, REQUIRED } from '@mmw/constants-validation-names';

const namespace = 'validation';

const THIRD_PARTY_CAMPAIGN_ITEM = {
  SELECTED_PACKAGE: {
    INVALID: {
      key: `${namespace}.${FIELDS.SELECTED_PACKAGE}.${INVALID}`,
      message: 'Your selected package must be valid',
    },
  },
  TERMS: {
    REQUIRED: {
      key: `${namespace}.${FIELDS.TERMS}.${REQUIRED}`,
      message: 'You must accept the terms in order to acquire this package',
    },
  },
};

export default THIRD_PARTY_CAMPAIGN_ITEM;
