const namespace = 'marketingBudget';

const MARKETING_BUDGET = {
  TITLE: {
    key: `${namespace}.title`,
    message: 'Marketing Budget',
  },
  NO_BUDGETS_FOUND: {
    key: `${namespace}.noBudgetsFound`,
    message: 'No budgets found',
  },
  UPLOAD_DATASHEET: {
    key: `${namespace}.uploadDatasheet`,
    message: 'Upload Datasheet',
  },
  TRANSACTION: {
    CREATION_ERROR: {
      key: `${namespace}.transaction.creationError`,
      message: `Error while creating transaction`,
    },
    NEW_TRANSACTION: {
      key: `${namespace}.transaction.newTransaction`,
      message: `Submit new transaction`,
    },
    PARTNER_GROUPS: {
      RCSP: {
        key: `${namespace}.transaction.partnerGroups.rcsp`,
        message: `RCSP - Certified Solar Professional`,
      },
      RCSP_PREMIER: {
        key: `${namespace}.transaction.partnerGroups.rcspPremiere`,
        message: `RCSP Premier - Certified Solar Professional Premier`,
      },
      RCSP_ENTERPRISE: {
        key: `${namespace}.transaction.partnerGroups.rcspEnterprise`,
        message: `RCSP Enterprise - Certified Solar Professional Enterprise`,
      },
      RCSP_GOLD: {
        key: `${namespace}.transaction.partnerGroups.rcspGold`,
        message: `RCSP Gold - Certified Solar Professional Gold`,
      },
      RCSP_SILVER: {
        key: `${namespace}.transaction.partnerGroups.rcspSilver`,
        message: `RCSP Silver - Certified Solar Professional Silver`,
      },
      RCSP_BRONZE: {
        key: `${namespace}.transaction.partnerGroups.rcspSilver`,
        message: `RCSP Bronze - Certified Solar Professional Bronze`,
      },
      RCSP_PLATINUM: {
        key: `${namespace}.transaction.partnerGroups.rcspPlatinum`,
        message: `RCSP Platinum - Certified Solar Professional Platinum`,
      },
      RTIP: {
        key: `${namespace}.transaction.partnerGroups.rtip`,
        message: `RTIP - Trusted Installer Partner`,
      },
      RTIC: {
        key: `${namespace}.transaction.partnerGroups.rtic`,
        message: `RTIC - Trusted Installer Champion`,
      },
      KAM: {
        key: `${namespace}.transaction.partnerGroups.kam`,
        message: `KAM - Key Account Manager`,
      },
      RMM: {
        key: `${namespace}.transaction.partnerGroups.rmm`,
        message: `RMM - Regional Marketing Manager`,
      },
      AD: {
        key: `${namespace}.transaction.partnerGroups.ad`,
        message: `AD - Authorised Distributor`,
      },
      REC1P: {
        key: `${namespace}.transaction.partnerGroups.1p`,
        message: '1P - Platinum Partner',
      },
      RECPP: {
        key: `${namespace}.transaction.partnerGroups.pp`,
        message: 'PP - Platinum Partner',
      },
      REC2T: {
        key: `${namespace}.transaction.partnerGroups.2t`,
        message: '2T - Certified Solar Professional',
      },
      REC2SP: {
        key: `${namespace}.transaction.partnerGroups.2sp`,
        message: '2SP - Certified Solar Professional',
      },
    },
  },
};

export default MARKETING_BUDGET;
