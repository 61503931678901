import { memoize } from 'lodash';
import randomColor from 'random-color';
import { filter } from 'rxjs/operators';

import { EMPTY_ARRAY, LEVELS } from './constants';
import { Message, subject } from './logger';
import { getTimeStamp, log } from './utils';

// eslint-disable-next-line no-unused-vars
function getGroupColor(group: string) {
  return randomColor().hexString();
}
const memoizedGroupColor = memoize(getGroupColor);

function subscribeToLogger(msg: Message) {
  const { color } = LEVELS[msg.level];
  const groupColor = memoizedGroupColor(msg.group);
  const levelCss = `color: #fff;font-weight:bold; background-color: ${color}; padding: 3px 3px;`;
  const groupCss = `color: #fff;font-weight:bold; background-color: ${groupColor}; padding: 3px 3px;`;
  const level = msg.level.toUpperCase().padEnd(6);
  const output = `%c${getTimeStamp(msg.date)} ${level} %c${msg.group}%c ${
    msg.message
  }`;
  // eslint-disable-next-line no-console
  log(msg.level)(
    output,
    levelCss,
    groupCss,
    'color: inherit;',
    ...(msg.messageArgs || EMPTY_ARRAY),
  );
}

export default () => {
  subject.pipe(filter(Boolean)).subscribe(subscribeToLogger);
};
