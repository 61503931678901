import { css } from '@ui-system/css';
import { ICON_UUID, IconProps } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useIconDefaultStyle = makeStyle<Partial<IconProps>>(
  ({ theme }) => css`
    color: ${theme.colors[theme.mode].secondary.main};
  `,
);

export const useIconStyle = makeFinalStyleHook<Partial<IconProps>>(
  ICON_UUID,
  useIconDefaultStyle,
);
