import contextualConfig from '@mmw/contextual-config';

import { Action, Reducer, SET_SALES_ORG_BRAND, State } from './types';

const { salesOrgBrandID } = contextualConfig.application;

export const INITIAL_STATE = {
  currentSalesOrgBrandID: salesOrgBrandID || null,
};

const currentSalesOrgBrandReducer: Reducer = (
  state: State = INITIAL_STATE,
  { type, payload }: Action,
) => {
  switch (type) {
    case SET_SALES_ORG_BRAND:
      return {
        ...state,
        currentSalesOrgBrandID: payload.salesOrgBrandID,
      };
    default:
      return state;
  }
};

export default currentSalesOrgBrandReducer;
