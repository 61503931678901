const namespace = 'pages';
const homeNamespace = 'home';
const noCampaigns = 'noCampaigns';
const registrationNamespace = 'registration';
const signUpNamespace = 'signUp';
const navigationNamespace = 'navigation';
const agreementsNamespace = 'agreements';
const demoDevicesNamespace = 'demoDevices';
const noAgreements = 'noAgreements';
// const brandedSignUpNamespace = 'brandedSignUp';

const HOME_PAGE = {
  TITLE: {
    key: `${namespace}.${homeNamespace}.title`,
    message: 'Choose campaign or program',
  },
  DESCRIPTION: {
    key: `${namespace}.${homeNamespace}.description`,
    message:
      'Please select the campaign or program you want to participate with your product registration.',
  },
  NO_CAMPAIGNS: {
    TITLE: {
      key: `${namespace}.${homeNamespace}.${noCampaigns}.title`,
      message: 'No campaigns found',
    },
    DESCRIPTION: {
      key: `${namespace}.${homeNamespace}.${noCampaigns}.description`,
      message:
        'The store that you selected does not participate on any campaign. Please change your store or if you have any problems, please contact us.',
    },
  },
};
const REGISTRATION_PAGE = {
  PREMIUM_TITLE: {
    key: `${namespace}.${registrationNamespace}.title`,
    message: 'Premium Registration',
  },
  PREMIUM_DESCRIPTION: {
    key: `${namespace}.${registrationNamespace}.description`,
    message:
      'Here you can perform a registration. After you fill in the data, you can click the button `Register` to obtain the added values and finish the registration. If you have any questions regarding any step, press the question mark button',
  },
};

const SIGN_UP = {
  FORM: {
    FANTASY_NAME: {
      key: `${namespace}.${signUpNamespace}.fantasyName`,
      message: 'Fantasy Name',
    },
    TERMS: {
      key: `${namespace}.${signUpNamespace}.terms`,
      message: 'Ich akzeptiere die <u>Datenschutzbestimmungen</u>',
    },
    EMAIL: {
      key: `${namespace}.${signUpNamespace}.email`,
      message: 'Email',
    },
    SECURITY_TOKEN: {
      key: `${namespace}.${signUpNamespace}.securityToken`,
      message: 'Security Token',
    },
  },
  COMPLETE_YOUR_REGISTRATION: {
    key: `${namespace}.${signUpNamespace}.completeYourRegistration`,
    message: 'Complete your registration',
  },
  SIGN_UP_ERROR: {
    key: `${namespace}.${signUpNamespace}.signUpError`,
    message: 'Some fields require your attention',
  },
};

const NAVIGATION = {
  AGREEMENTS: {
    key: `${namespace}.${navigationNamespace}.agreements`,
    message: 'Agreements',
  },
  DEMO_DEVICES: {
    key: `${namespace}.${navigationNamespace}.demoDevices`,
    message: 'Demo Devices',
  },
};

const DEMO_DEVICES_PAGE = {
  TITLE: {
    key: `${namespace}.${demoDevicesNamespace}.title`,
    message: 'Devices ready for demonstration',
  },
  DESCRIPTION: {
    key: `${namespace}.${demoDevicesNamespace}.pageDescription`,
    message: `Manage your devices ready for demonstration in store {name} here. To add new devices, please click on the corresponding image. Once the device has been added to the showroom, it will appear in the Store Locator for your shop.`,
  },
  NO_CAMPAIGNS: {
    TITLE: {
      key: `${namespace}.${demoDevicesNamespace}.${noCampaigns}.title`,
      message: 'No campaigns found',
    },
    DESCRIPTION: {
      key: `${namespace}.${demoDevicesNamespace}.${noCampaigns}.notFoundDescription`,
      message:
        'The store that you selected does not participate on any campaign. Please change your store or if you have any problems, please contact us.',
    },
  },
};

const AGREEMENTS_PAGE = {
  NO_AGREEMENTS: {
    TITLE: {
      key: `${namespace}.${agreementsNamespace}.${noAgreements}.notFoundTitle`,
      message: 'No agreements found',
    },
    DESCRIPTION: {
      key: `${namespace}.${agreementsNamespace}.${noAgreements}.notFoundDescription`,
      message:
        'The store that you selected does not participate on any agreement. Please change your store or if you have any problems, please contact us.',
    },
  },
};

const I18N = {
  HOME_PAGE,
  REGISTRATION_PAGE,
  SIGN_UP,
  NAVIGATION,
  AGREEMENTS_PAGE,
  DEMO_DEVICES_PAGE,
};

export default I18N;
