const namespace = 'alpha.emeaRankingPage';
const tooltipNamespace = `${namespace}.tooltip`;
const bannerNamespace = `${namespace}.banner`;
const iconNamespace = `${namespace}.icon`;

const EMEA_RANKING_PAGE = {
  TOOLTIP: {
    TOPTEN: {
      key: `${tooltipNamespace}.topTen`,
      message: 'top ten',
    },
    REGION: {
      MIDDLE: {
        key: `${tooltipNamespace}.region.middle`,
        message: 'middle europe',
      },
      NORTHERN: {
        key: `${tooltipNamespace}.region.northern`,
        message: 'north / east europe',
      },
      SOUTHERN: {
        key: `${tooltipNamespace}.region.southern`,
        message: 'southern countries',
      },
    },
    REGISTERED_INSTALLATIONS: {
      key: `${tooltipNamespace}.registeredInstallations`,
      message: 'registered instalations',
    },
    EMPTY_INSTALLATIONS: {
      key: `${tooltipNamespace}.emptyInstallations`,
      message:
        'In this region, no REC Alpha installation was registered for participation, yet. Start registering now and win your customised REC marketing package!',
    },
    PLACE: {
      key: `${tooltipNamespace}.place`,
      message: 'place',
    },
  },
  BANNER: {
    LINE1: {
      key: `${bannerNamespace}.line1`,
      message: 'Discover the top ten in each region',
    },
    LINE2: {
      key: `${bannerNamespace}.line2`,
      message: 'Select on the map to the side and find out how many',
    },
    LINE3: {
      key: `${bannerNamespace}.line3`,
      message: 'REC alpha installations were made by top performers',
    },
  },
  ICON: {
    LINE1: {
      key: `${iconNamespace}.line1`,
      message: 'check top-5',
    },
    LINE2: {
      key: `${iconNamespace}.line2`,
      message: 'of your region',
    },
  },
  CHECK_YOUR_RANKING: {
    key: `${namespace}.checkYourRanking`,
    message: 'Check your ranking',
  },
};

export default EMEA_RANKING_PAGE;
