import { Country } from '@mmw/services-core-common/types';
import { chain } from 'lodash';
import { createSelector } from 'reselect';
import { U } from 'ts-toolbelt';

import { State } from './types';

export const NAMESPACE = 'availableCountries';

export type RootState = {
  [NAMESPACE]: State;
};

export const availableCountriesSelector = (
  state: RootState,
): State['availableCountries'] => state[NAMESPACE].availableCountries;

export const findCountryName = (
  countries: Array<Country>,
  code: U.Nullable<string>,
): U.Nullable<string> => {
  const countryArr = chain(countries)
    .filter((country: Country) => country.id === code)
    .map((country: Country) => country.name)
    .value();
  if (countryArr.length > 0) {
    return countryArr[0];
  }
  return null;
};

export const getCountryNameByCode = (
  state: RootState,
  code: U.Nullable<string>,
): U.Nullable<string> =>
  createSelector(availableCountriesSelector, (countries: Array<Country>) =>
    findCountryName(countries, code),
  )(state);
