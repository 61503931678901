import { getStaticUrl } from '@mmw/config';
import { Brand } from '@mmw/constants-brands';
import { CountryCode } from '@mmw/constants-country-codes';
import {
  BRAND_MAPPING,
  ORALB_DE,
  SalesOrgBrand,
  SIEMENS,
  SIEMENS_AT,
} from '@mmw/constants-salesorgbrand-ids';
import contextualConfig from '@mmw/contextual-config';
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { U } from 'ts-toolbelt';
import capitalize from 'voca/capitalize';

const { logger } = contextualConfig.application;
const SOBS_WITH_DIFFERENT_NAME_THAN_BRAND = [ORALB_DE];
const BRANDS_WITH_BACKGROUND_LOGO = [SIEMENS, SIEMENS_AT];

export function getCurrentSobIdByCountry(
  sobId: SalesOrgBrand,
  countryCode: CountryCode,
): SalesOrgBrand {
  if (sobId === SalesOrgBrand.NOOP) {
    return sobId;
  }
  if (includes(SOBS_WITH_DIFFERENT_NAME_THAN_BRAND, sobId)) {
    return sobId;
  }

  const currentBrandId = BRAND_MAPPING[sobId];
  if (!currentBrandId) {
    logger.error('Error couldnt find brand for sobId=', sobId);
    return sobId;
  }

  const currentBrandName = findKey(Brand, id => id === currentBrandId);
  if (currentBrandName && (countryCode === 'DE' || !countryCode)) {
    const foundSob = get(SalesOrgBrand, `${currentBrandName}`);
    if (foundSob) {
      return foundSob;
    }
  }
  const foundSob = get(SalesOrgBrand, `${currentBrandName}_${countryCode}`);
  if (foundSob) {
    return foundSob;
  }
  return sobId;
}

export function getBrandNameBySobId(sobId: SalesOrgBrand): string {
  const currentBrandName = findKey(SalesOrgBrand, id => id === sobId)?.split(
    '_',
  );
  return capitalize(currentBrandName[0]?.toLowerCase());
}

export function getBrandLogoBySob(
  sobId: U.Nullable<SalesOrgBrand>,
): U.Nullable<string> {
  if (!sobId) return null;
  if (includes(BRANDS_WITH_BACKGROUND_LOGO, sobId))
    return `${getStaticUrl()}/images/brandbuttons/${getBrandNameBySobId(
      sobId,
    )}.png`;
  return `${getStaticUrl()}/images/brandbuttons/${getBrandNameBySobId(
    sobId,
  )}-transparent.png`;
}
