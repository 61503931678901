import { useCallback } from 'react';

import useHistory from './useHistory';
import useLocation from './useLocation';

export type NaviagteToReturn = (
  route: string,
  state?: Record<string, any>,
) => void;
export type UseNavigateTo = () => NaviagteToReturn;

const useNavigateTo: UseNavigateTo = () => {
  const history = useHistory();
  const location = useLocation();
  return useCallback(
    (route: string, state) => {
      if (history) {
        history.push(route, { ...state, lastRoute: location.pathname });
      }
    },
    [history, location.pathname],
  );
};

export default useNavigateTo;
