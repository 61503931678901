import { hashObject } from '@hasher/object-hash';
import { EMPTY_ARRAY } from '@shared-utils/array';
import { noop } from 'lodash';
import { useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { deleteAction, restartAction, startAction } from '../../store/actions';
import { FastFormContextValue } from '../../types';

function useStartFormState<T>(
  config: FastFormContextValue<T>,
  initialValues: T,
) {
  const firstTime = useRef(true);
  const hash = useRef(null);
  const dispatch = useDispatch();
  // XXX: must use on layout effect to run dispatch start before others!
  useLayoutEffect(
    () => {
      const { options } = config;
      const newHash = hashObject([
        config.formId,
        initialValues,
        options,
        config.validationSchema,
      ]);

      const needsToReinitialize = hash.current !== newHash;
      if (firstTime.current) {
        dispatch(
          startAction(
            config.formId,
            initialValues,
            options,
            config.validationSchema,
          ),
        );
        firstTime.current = false;
      } else if (options.enableReinitialize && needsToReinitialize) {
        hash.current = newHash;
        dispatch(
          restartAction(
            config.formId,
            initialValues,
            options,
            config.validationSchema,
          ),
        );
      }
      // CLEANUP
      if (options.deleteFormOnUnmount === true) {
        return () => {
          dispatch(deleteAction(config.formId));
        };
      }
      return noop;
    },
    config.options.enableReinitialize ? [config, initialValues] : EMPTY_ARRAY,
  );
}

export default useStartFormState;
