const namespace = 'marketingTools';

export const BACK_TO_TOP = {
  key: `backToTop`,
  message: 'Back to top',
};

const I18N = {
  MARKETING_TOOLS: {
    key: `${namespace}`,
    message: 'Marketing Tools',
  },
  MARKETING_TOOLS_DESCRIPTION: {
    key: `${namespace}Description`,
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  DISCOVER_OTHER_MARKETING_TOOLS: {
    key: `${namespace}.discoverOtherMarketingTools`,
    message: 'Discover other marketing tools',
  },
  RECENT_NEWS: {
    TITLE: {
      key: `${namespace}.recentNews.title`,
      message: 'RECents News',
    },
    DESCRIPTION: {
      key: `${namespace}.recentNewsDescription`,
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
  },
  REC_RELEASES: {
    TITLE: {
      key: `${namespace}.recReleases.title`,
      message: 'REC Releases',
    },
    DESCRIPTION: {
      key: `${namespace}.recReleasesDescription`,
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    BUTTON: {
      key: `${namespace}.recReleases.button`,
      message: 'REC Releases',
    },
  },
  REC_PROPAGE: {
    TITLE: {
      key: `${namespace}.recProPage.title`,
      message: 'REC ProPage',
    },
    DESCRIPTION: {
      key: `${namespace}.recProPageDescription`,
      message:
        'With an REC ProPage you can create and share a customized and dynamic webpage with the latest REC content. This can be easily integrated into your own website, media channels or used as a stand- alone website.',
    },
    BUTTON: {
      key: `${namespace}.recProPage.button`,
      message: 'Create now',
    },
  },
  REC_WEB_TOOL_BOX: {
    TITLE: {
      key: `${namespace}.recWebToolBox.title`,
      message: 'REC WebToolBox',
    },
    DESCRIPTION: {
      key: `${namespace}.recWebToolBoxDescription`,
      message:
        'Using the REC Toolbox, you can add prepared REC content into your own existing website.  Browse the categories and decide which REC content you need, then copy and paste into your current website.',
    },
    BUTTON: {
      key: `${namespace}.recWebToolBox.button`,
      message: 'Open REC Toolbox',
    },
  },
  REC_MARKETING_SHOP: {
    TITLE: {
      key: `${namespace}.recMarketingShop.title`,
      message: 'REC Marketing Shop',
    },
    DESCRIPTION: {
      key: `${namespace}.recMarketingShopDescription`,
      message:
        'We have created a collection of useful REC-branded merchandise to support you in your day-to-day activities. Whether meeting with customers or installing on roofs, the practical items available to you will support you in sales and general work: from high-end apparel and office supplies, to POS and installation tools.',
    },
    BUTTON: {
      key: `${namespace}.recMarketingShop.button`,
      message: 'Visit shop',
    },
  },
  ALPHA_ADVANTAGE_CALCULATOR: {
    TITLE: {
      key: `${namespace}.alphaAdvantageCalculator.title`,
      message: 'Alpha Advantage Calculator',
    },
    DESCRIPTION: {
      key: `${namespace}.alphaAdvantageCalculatorDescription`,
      message:
        'Use our simple calculator to work out the Alpha Advantage for your customers.',
    },
    BUTTON: {
      key: `${namespace}.alphaAdvantageCalculator.button`,
      message: 'Open calculator',
    },
  },
  REC_PRINT_SHOP: {
    TITLE: {
      key: `${namespace}.recPrintShop.title`,
      message: 'REC Print Shop',
    },
    DESCRIPTION: {
      key: `${namespace}.recPrintShopDescription`,
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    BUTTON: {
      key: `${namespace}.recMarketingShop.button`,
      message: 'Visit shop',
    },
  },
  REC_PRO_SHOP: {
    TITLE: {
      key: `${namespace}.recProShop.title`,
      message: 'REC ProShop',
    },
    DESCRIPTION: {
      key: `${namespace}.recProShopDescription`,
      message:
        'Order the REC gear and co-branded collateral you need to support your sales.',
    },
    BUTTON: {
      key: `${namespace}.recProShop.button`,
      message: 'Visit shop',
    },
  },
  MARKETING_BUDGET: {
    TITLE: {
      key: `${namespace}.marketingBudget.title`,
      message: 'Marketing Budget',
    },
    DESCRIPTION: {
      key: `${namespace}.marketingBudgetDescription`,
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    BALANCE_LABEL: {
      key: `${namespace}.yourMarketingBudget`,
      message: 'Your marketing budget',
    },
    BUTTON: {
      key: `${namespace}.recMarketingShop.button`,
      message: 'Visit shop',
    },
    SPEND_YOUR_MARKETING_BUDGET: {
      key: `${namespace}.marketingBudget.spendYourMarketingBudget`,
      message: 'Where to spend your Marketing Budget',
    },
  },
  PRODUCT_DOWNLOADS: {
    TITLE: {
      key: `${namespace}.productDownloads.title`,
      message: 'Product Downloads',
    },
    DESCRIPTION: {
      key: `${namespace}.productDownloadsDescription`,
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    BUTTON: {
      key: `${namespace}.productDownloads.button`,
      message: 'Create Now',
    },
  },
};

export default I18N;
