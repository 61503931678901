import { LanguageCode } from '@mmw/constants-languages';
import qs from 'qs';

const BASE_PATH = '/v1/admin/properties';

export const GetResourcesPath = (
  // XXX: for default resource stored, NULL
  language: LanguageCode | null = 'noLanguage',
  key: string,
  bundleName: string,
): string =>
  `${BASE_PATH}?${qs.stringify({
    language: language || 'noLanguage',
    key,
    bundleName,
    limit: 1,
    offset: 0,
  })}`;

export const GetResourcesListPath = (
  key: string,
  bundleName: string,
  limit = 20,
  offset = 0,
): string =>
  `${BASE_PATH}?language=&${qs.stringify({
    key,
    bundleName,
    limit,
    offset,
  })}`;

export const SaveResourcePath = (): string => BASE_PATH;
export const TranslatePath = (): string => `${BASE_PATH}/translation`;
