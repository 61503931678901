import { Modifiers } from '@mmw/ui-theme/modifiers/types';
import { ComponentType, ReactElement } from 'react';
import { U } from 'ts-toolbelt';

import Parser from './Parser';

const HtmlParser = (
  html: string,
  Component: U.Nullable<ComponentType<unknown> | string>,
  BaseTextComponent?: U.Nullable<ComponentType<unknown>>,
  modifiers?: U.Nullable<Modifiers>,
  allowHtmlStyle = false,
): U.Nullable<ReactElement[]> =>
  Parser(html, Component, modifiers, BaseTextComponent, allowHtmlStyle);

export default HtmlParser;
