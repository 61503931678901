import { I18nShape } from '@ui-system/deps';
import { Style } from '@ui-system/interfaces/types';
import { TypographyProps } from '@ui-system/interfaces-typography';
import noop from 'lodash';
import { nanoid } from 'nanoid';
import React, { RefObject } from 'react';
import { F } from 'ts-toolbelt';

export interface MenuOptionInterface {
  label: I18nShape | string;
  onClick?: F.Function;
  useOnClick?: () => F.Function;
  useIsVisible?: F.Function<[...args: any[]], boolean>;
  labelProps?: Partial<TypographyProps>;
  route?: string;
  value?: string;
}

export class MenuOption implements MenuOptionInterface {
  label: I18nShape | string = '';

  onClick?: F.Function;

  // eslint-disable-next-line class-methods-use-this
  useOnClick: () => F.Function = () => noop;

  useIsVisible: F.Function<[...args: any[]], boolean>;

  labelProps?: Partial<TypographyProps>;

  route?: string;

  value?: string;

  constructor(params: Partial<MenuOption> = {}) {
    this.label = params.label || this.label;
    this.onClick = params.onClick || this.onClick;
    this.useOnClick = params.useOnClick || (() => this.onClick || noop);
    this.useIsVisible = params.useIsVisible || (() => true);
    this.labelProps = params.labelProps || this.labelProps;
    this.route = params.route || this.route;
    this.value = params.value || this.value;
  }
}

export interface MenuItemComponentProps {
  option: MenuOptionInterface;
  isHovering: boolean;
  onClick: () => void;
}

export interface MenuProps {
  onClose: F.Function;
  isOpen: boolean;
  anchorEl?: RefObject<any>;
  options?: MenuOptionInterface[];
  children?: React.ReactNode;
  MenuItemComponent?: React.FC<MenuItemComponentProps>;
  style?: Style;
  menuItemStyle?: Style;
}
export interface MenuContainerProps
  extends Omit<MenuProps, 'isOpen' | 'onClose'> {
  children: React.ReactNode;
  menuChildren?: MenuProps['children'];
  trigger?: 'hover' | 'click' | string;
  onToggleSubMenu?: F.Function<[boolean]>;
  customItemContainerStyle?: Record<string, any>;
}

export type MenuType = React.FC<MenuProps> & {
  MenuContainer: MenuContainerType;
};
export type MenuContainerType = React.FC<MenuContainerProps>;

export const MENU_UUID = nanoid();
export const MENU_ITEM_UUID = nanoid();
