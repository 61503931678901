export interface AsyncModuleActionTypes {
  START: string;
  SUCCESS: string;
  ERROR: string;
  DISMISS_ERROR: string;
  RESET: string;
  CANCEL: string;
}

export const START_PATTERN = '_START';
export const SUCCESS_PATTERN = '_SUCCESS';
export const ERROR_PATTERN = '_ERROR';
export const DISMISS_ERROR_PATTERN = '_DISMISS_ERROR';
export const CANCEL_PATTERN = '_CANCEL';
export const RESET_PATTERN = '_RESET';
export const CREATE_MULTI_STATE_STATE_PATTERN = '/CREATE_MULTI_STATE_STATE';

export function createTypes(
  namespace: string,
  actionName: string,
): AsyncModuleActionTypes {
  return {
    START: `${namespace}/${actionName}${START_PATTERN}`,
    SUCCESS: `${namespace}/${actionName}${SUCCESS_PATTERN}`,
    ERROR: `${namespace}/${actionName}${ERROR_PATTERN}`,
    DISMISS_ERROR: `${namespace}/${actionName}${DISMISS_ERROR_PATTERN}`,
    RESET: `${namespace}/${actionName}${RESET_PATTERN}`,
    CANCEL: `${namespace}/${actionName}${CANCEL_PATTERN}`,
  };
}
