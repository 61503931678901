import {
  NonInteractiveRatingStars,
  RatingCommonProps,
} from '@product-rating/view';
import UI from '@ui-system/ui';
import React, { useContext } from 'react';

import { LocatorCustomizationContext } from '../../context';

const ProductRating: React.FC<RatingCommonProps> = ({
  productID,
  productName,
  quantity,
}: RatingCommonProps) => {
  const { productRatingConfig } = useContext(LocatorCustomizationContext);

  if (productRatingConfig?.nonInteractive) {
    return (
      <NonInteractiveRatingStars
        productID={productID}
        productName={productName}
        quantity={quantity}
        {...productRatingConfig.customProps}
      />
    );
  }
  if (productRatingConfig?.Custom) {
    const CustomComponent = productRatingConfig?.Custom;
    return (
      <CustomComponent
        productID={productID}
        productName={productName}
        quantity={quantity}
        {...productRatingConfig.customProps}
      />
    );
  }

  return <UI.Typography variant="h6">{productName}</UI.Typography>;
};

export default ProductRating;
