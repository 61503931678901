import {
  useIsLoadingRating,
  useProductRatingValue,
} from '@product-rating/store-creator';
import { createArray } from '@shared-utils/array';
import NonInteractiveStars from '@ui-system/common-rating/ready-only-rating';
import { StarRatingProps } from '@ui-system/common-rating/ready-only-rating/StarRating';
import { css } from '@ui-system/css';
import { useColors } from '@ui-system/theme/colors';
import UI from '@ui-system/ui';
import map from 'lodash/map';
import React from 'react';

const starsPlaceholderArr = createArray(5);

const LoadingPlaceHolder: React.FC = () => (
  <UI.Container align="center" gap={1}>
    {map(starsPlaceholderArr, () => (
      <UI.Skeleton width={40} height={40} />
    ))}
  </UI.Container>
);

// TODO: temp generic type, need to add more specific props
export interface RatingCommonProps extends Partial<StarRatingProps> {
  productID: number;
  productName?: string;
  quantity?: number;
}

export const NonInteractiveRatingStars: React.FC<RatingCommonProps> = ({
  productID,
  productName,
  quantity,
  ...props
}: RatingCommonProps) => {
  const rating = useProductRatingValue(productID);
  const colors = useColors();
  const loading = useIsLoadingRating();

  if (loading) {
    return <LoadingPlaceHolder />;
  }

  return (
    <UI.Container direction="column">
      <UI.Typography variant="h6">{productName}</UI.Typography>
      {rating > 0 ? (
        <UI.Container
          direction="row"
          align="center"
          justify="center"
          gap={1}
          style={css`
            line-height: 0.5px;
          `}
        >
          <UI.Typography>{rating.toFixed(1)}</UI.Typography>
          <UI.Container align="center">
            <NonInteractiveStars
              initialRating={rating}
              isReadOnly
              unit="float"
              size={40}
              activeColor={colors.secondary.main}
              {...props}
            />
            {quantity ? (
              <UI.Typography
                style={css`
                  color: ${colors.gray.A300};
                  margin-bottom: 0.3rem;
                `}
              >
                ({quantity})
              </UI.Typography>
            ) : null}
          </UI.Container>
        </UI.Container>
      ) : null}
    </UI.Container>
  );
};
