import { get } from 'lodash';

import { apply } from '../modifiers';
import { StyledProps } from '../types';
import { UseReturn } from './types';

export default (
  componentProps: StyledProps<unknown>,
  componentName: string,
): UseReturn => {
  const { theme } = componentProps;
  if (!theme) {
    throw new Error(
      `not found theme in ${componentName} props, this should never happen`,
    );
  }
  const componentTheme = get(theme, componentName);
  if (componentTheme) {
    let toUse = '';
    const componentStyle = componentTheme.style;
    if (componentStyle) {
      toUse = toUse.concat(componentStyle(componentProps));
    }
    const componentModifiers = componentTheme.modifiers;
    if (componentModifiers) {
      toUse = toUse.concat(apply(componentModifiers)(componentProps));
    }
    return toUse;
  }
  throw new Error(`not found component theme in ${componentName}`);
};

/*  README
----------------------------------------
Component index.js:
(default styled component declaration)

import styled from 'styled-components';
import {
  use,
} from '@mmw/ui-theme/utils';

const Component = styled.element(
  props => use(props, 'component'),
);

export default Component;
----------------------------------------
Component Theme theme/index.js:
(default component theme export)

import style from './style';
import modifiers from './modifiers';

const theme = {
  style,
  modifiers,
};

export {
  theme as default,
  style,
  modifiers,
};
----------------------------------------
Component Style theme/style.js:
(default component style export)

export default (props) => `
  color: ${props.theme.colors.primary.font};
  background-color: ${props.theme.colors.pallet.primary};
  margin: ${props.margin};
`;
----------------------------------------
Component Modifiers theme/modifiers.js:
(default component modifiers export)

export default {
  error: ({ theme }) => `
    color: ${theme.colors.pallet.error};
  `,
};
----------------------------------------
Framework Theme index.js
(must to import your components' theme in framework theme)

import Component from '@mmw/ui-web-elements-component/theme';

export default {
  component,
};
----------------------------------------
Application Theme index.js
(if you need to override your components' theme to application usage)

import frameworkTheme from '@mmw/web-theme';

export default {
  ...frameworkTheme,
  component: {
    style: props => `...`,
    modifiers: {
      modifier: props => `...`,
    },
  },
};
----------------------------------------
*/
