import { fastHash } from '@hasher/object-hash';
import { useDeepCompareMemo } from '@react-utils/hooks';
import { deepmerge, deepMergeAll, EMPTY_OBJECT } from '@shared-utils/object';
import componentModifiers from '@ui-system/default-modifiers/container';
import { Style } from '@ui-system/interfaces/types';
import { ContainerProps, ContainerType } from '@ui-system/interfaces-container';
import { usePropsByMediaQuery } from '@ui-system/media-query';
import { useModifiers } from '@ui-system/modifiers';
import { useTheme } from '@ui-system/theme';
import { isReactNative } from 'is-react-native';
import compact from 'lodash/compact';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import pickBy from 'lodash/pickBy';
import React, { useMemo } from 'react';
import { U } from 'ts-toolbelt';
import strIncludes from 'voca/includes';

import { BaseContainer, BaseContainerPressable } from './ContainerView';
import Gradient from './Gradient';
import InnerShadow from './inner-shadow';

function getStyle(...styles: U.Nullable<Style>[]): Style {
  return deepMergeAll<Style>(compact(styles));
}

const memoizedGetStyle = memoize(getStyle, (...styles) =>
  fastHash(compact(styles)),
);
// Re-usable logic for web/native
export const Container: React.FC<ContainerProps> = ({
  children,
  gap,
  lastChildStyle,
  firstChildStyle,
  onClick,
  innerRef,
  modifiers,
  ...props
}: ContainerProps) => {
  const theme = useTheme();
  const childStyle: Style = useDeepCompareMemo(() => {
    const { direction } = props;
    if (!gap) return EMPTY_OBJECT;
    if ((direction === 'row' || !direction) && get(theme?.spacing, gap)) {
      return {
        marginLeft: get(theme?.spacing, gap),
      };
    }
    if (direction === 'row-reverse' && get(theme?.spacing, gap)) {
      return {
        marginRight: get(theme?.spacing, gap),
      };
    }
    if (direction === 'column-reverse' && get(theme?.spacing, gap)) {
      return {
        marginBottom: get(theme?.spacing, gap),
      };
    }
    return {
      marginTop: get(theme?.spacing, gap),
    };
  }, [gap, props, theme?.spacing]) as Style;
  const containerStyle = useModifiers(
    modifiers,
    componentModifiers,
    // @ts-ignore
    props.style,
  );
  const otherProps = useDeepCompareMemo(() => {
    const newProps = pickBy(props, (v, key) => key !== 'direction');
    return {
      ...newProps,
      [isReactNative() ? 'onPress' : 'onClick']: onClick,
      style: containerStyle,
      flexDirection: props.direction || 'row',
    };
  }, [onClick, props, containerStyle]);

  const Comp = useMemo<ContainerType>(
    () =>
      (onClick
        ? BaseContainerPressable
        : BaseContainer) as unknown as ContainerType,
    [onClick],
  );

  return (
    <Comp ref={innerRef} {...otherProps}>
      {!gap
        ? children
        : React.Children.map(children, (child, i) => {
            if (!child) return null;
            const contentStyle: U.Nullable<Style> = get(child, 'props.style');
            if (isEmpty(childStyle) && isEmpty(contentStyle)) return child;
            if (i === React.Children.count(children) - 1)
              return React.cloneElement(child as React.ReactElement<any, any>, {
                style: memoizedGetStyle(
                  contentStyle,
                  childStyle,
                  lastChildStyle,
                ),
              });
            if (i === 0) {
              return React.cloneElement(child as React.ReactElement<any, any>, {
                style: memoizedGetStyle(contentStyle, firstChildStyle),
              });
            }
            return React.cloneElement(child as React.ReactElement<any, any>, {
              style: memoizedGetStyle(contentStyle, childStyle),
            });
          })}
    </Comp>
  );
};

const ContainerWrapper: ContainerType = ({
  innerShadow,
  innerShadowProps,
  responsive,
  ...props
}: ContainerProps) => {
  const shadowContainerStyle = useDeepCompareMemo(() => {
    const borderRadiusValue = props?.style
      ? find(props?.style, (i, prop) => {
          if (strIncludes(prop, 'Radius')) return i;
          return 0;
        })
      : 0;
    return {
      flexDirection: props?.direction,
      borderRadius: borderRadiusValue,
      ...get(innerShadowProps, 'containerStyle'),
    };
  }, [innerShadowProps, props.borderRadius, props.direction]);
  const containerProps = usePropsByMediaQuery<ContainerProps>(responsive);
  const mergedProps = useDeepCompareMemo(
    () => deepmerge(props, containerProps),
    [props, containerProps],
  );
  if (innerShadow) {
    return (
      <InnerShadow containerStyle={shadowContainerStyle} {...innerShadowProps}>
        <Container {...mergedProps} />
      </InnerShadow>
    );
  }
  return <Container {...mergedProps} />;
};

ContainerWrapper.LinearGradient = Gradient;

export default ContainerWrapper;
