import { createStoreModule } from '@zustand-store/core';
import { isReactNative } from 'is-react-native';

class State {
  json: Record<string, any> | any[] | null = null;

  devForm = false;
}
export const useDevMode = createStoreModule({
  name: 'dev-mode',
  initialState: new State(),
});

export function setDevModeJSON(json: Record<string, any> | any[]) {
  useDevMode.setState({ json });
}
export function setDevForm(devForm = false) {
  useDevMode.setState({ devForm });
}

if (!isReactNative()) {
  window.dev = setDevModeJSON;
  window.devForm = setDevForm;
}
