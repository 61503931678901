import { Interpolation } from '@emotion/serialize';
import forEach from 'lodash/forEach';
import join from 'lodash/join';
import nth from 'lodash/nth';

export function mediaQueryParser(
  template: TemplateStringsArray,
  ...args: Array<Interpolation>
): string {
  if (typeof template === 'string') {
    return template;
  }
  const style: string[] = [];
  forEach(template, (line, i) => {
    style.push(join([line, nth(args, i)], ''));
  });
  return join(style, '');
}

export function mediaQueryParserNoop(): string {
  return '';
}
