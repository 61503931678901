import { EMPTY_OBJECT } from '@mmw/constants-utils';
import { cloneDeep, isEmpty, isEqual } from 'lodash';

import {
  CHANGE_CONFIG,
  DELETE,
  Reducer,
  RESET_FORM_STATE,
  RESTART,
  SET_FIELD_ERRORS,
  SET_FIELD_TOUCHED,
  SET_FIELD_UNWATCHED,
  SET_FIELD_WATCHED,
  SET_FORM_STATE,
  SET_INITIAL_VALUES,
  START,
  SUBMIT_ERROR,
  SUBMIT_START,
  SUBMIT_SUCCESS,
  UPDATE_FIELD_VALUE,
  UPDATE_FIELD_VALUES_BY_QUERY,
  UPDATE_INITIAL_FIELD_VALUE,
  VALIDATE_START,
  VALIDATE_SUCCESS,
} from './types';
import {
  normalizeErrors,
  normalizeFieldPath,
  normalizeTouchedFields,
  update,
  updateFieldPathsCommands,
} from './utils';

const INITIAL_STATE = {
  forms: EMPTY_OBJECT,
};

const FORM_FLAGS_INITIAL_STATE = {
  isValidating: false,
  isManuallyValidated: false,
  isSubmitting: false,
  submitCount: 0,
  isSuccessfullyValidated: false,
  isSuccessfullySubmitted: false,
  isUnsuccessfullySubmitted: false,
  isValid: true,
};

const INITIAL_FORM_STATE = {
  currentValues: EMPTY_OBJECT,
  submittedValues: null,
  currentErrors: EMPTY_OBJECT,
  initialValues: EMPTY_OBJECT,
  touchedFieldPaths: EMPTY_OBJECT,
  watchedFieldPaths: EMPTY_OBJECT,
  validationSchema: null,
  flags: {
    ...FORM_FLAGS_INITIAL_STATE,
  },
};

const fastFormReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case START: {
      const { initialValues, options, validationSchema } = action.payload;
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.payload.formId]: {
            ...INITIAL_FORM_STATE,
            options,
            initialValues,
            currentValues: cloneDeep(initialValues),
            validationSchema,
          },
        },
      };
    }
    case RESTART: {
      const {
        initialValues: newInitialValues,
        options,
        validationSchema,
      } = action.payload;
      const { cleanValuesOnReinitialize, ignoreInitialValuesOnRestart } =
        options;
      const previousForm = state.forms[action.payload.formId] || EMPTY_OBJECT;
      const previousFlags = previousForm.flags || FORM_FLAGS_INITIAL_STATE;

      const initialValues = ignoreInitialValuesOnRestart
        ? previousForm.initialValues || EMPTY_OBJECT
        : newInitialValues;
      const clonedInitialValues = cloneDeep(initialValues || EMPTY_OBJECT);
      let newCurrentValues = clonedInitialValues;

      if (options.mergeInitialValues) {
        newCurrentValues = options.mergeInitialValues(
          initialValues,
          previousForm.currentValues || EMPTY_OBJECT,
        );
      } else if (previousForm.currentValues) {
        newCurrentValues = !options.isValuesEmpty(previousForm.currentValues)
          ? previousForm.currentValues
          : cloneDeep(initialValues || EMPTY_OBJECT);
      } else {
        newCurrentValues = clonedInitialValues;
      }

      return {
        ...state,
        forms: {
          ...state.forms,
          [action.payload.formId]: {
            ...INITIAL_FORM_STATE,
            options,
            initialValues,
            currentValues: cleanValuesOnReinitialize
              ? clonedInitialValues
              : newCurrentValues,
            touchedFieldPaths: previousForm.touchedFieldPaths || EMPTY_OBJECT,
            currentErrors: previousForm.currentErrors || EMPTY_OBJECT,
            watchedFieldPaths: previousForm.watchedFieldPaths || EMPTY_OBJECT,
            validationSchema,
            flags: {
              ...previousFlags,
              submitCount: previousFlags.submitCount || 0,
              isManuallyValidated: previousFlags.isManuallyValidated || false,
            },
          },
        },
      };
    }
    case CHANGE_CONFIG: {
      const { options, validationSchema } = action.payload;
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.payload.formId]: {
            ...form,
            options,
            validationSchema,
          },
        },
      };
    }
    case UPDATE_FIELD_VALUE: {
      const { formId, changes } = action.payload;
      if (isEmpty(changes)) {
        return state;
      }
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      const touchedFieldPaths = form.touchedFieldPaths || EMPTY_OBJECT;
      const { currentValues } = form;
      const updatedValues = update(
        currentValues,
        updateFieldPathsCommands(changes),
      );
      const newTouchedFields = normalizeTouchedFields(changes);
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            isSuccessfullyValidated: false,
            isSuccessfullySubmitted: false,
            isUnsuccessfullySubmitted: false,
            currentValues: updatedValues,
            touchedFieldPaths: {
              ...touchedFieldPaths,
              ...newTouchedFields,
            },
            // // XXX: had to empty errors since we dont calculate the query field maps
            // // TODO: map query to fieldPaths so we can eliminate the errors
            // currentErrors: {},
          },
        },
      };
    }
    case UPDATE_INITIAL_FIELD_VALUE: {
      const { formId, changes } = action.payload;
      if (isEmpty(changes)) {
        return state;
      }
      const form = state.forms[action.payload.formId];
      if (!form) {
        return state;
      }
      const { initialValues, currentValues, options } = form;
      const newInitialValues = update(
        initialValues,
        updateFieldPathsCommands(changes),
      );

      let newCurrentValues = currentValues;
      if (options.mergeInitialValues) {
        newCurrentValues = options.mergeInitialValues(
          newInitialValues,
          currentValues || EMPTY_OBJECT,
        );
      }
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            initialValues: newInitialValues,
            currentValues: newCurrentValues,
          },
        },
      };
    }
    case SET_INITIAL_VALUES: {
      const { formId, initialValues: newInitialValues } = action.payload;
      const form = state.forms[action.payload.formId];
      if (!form) {
        return state;
      }
      const { currentValues, options } = form;

      let newCurrentValues = currentValues;
      if (options.mergeInitialValues) {
        newCurrentValues = options.mergeInitialValues(
          newInitialValues,
          currentValues || EMPTY_OBJECT,
        );
      }
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            initialValues: newInitialValues,
            currentValues: newCurrentValues,
          },
        },
      };
    }
    case UPDATE_FIELD_VALUES_BY_QUERY: {
      const { formId, query } = action.payload;
      if (isEmpty(query)) {
        return state;
      }
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      const { currentValues } = form;
      const updatedValues = update(currentValues, query);
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            currentValues: updatedValues,
            flags: {
              ...form.flags,
              isSuccessfullyValidated: false,
              isSuccessfullySubmitted: false,
              isUnsuccessfullySubmitted: false,
            },
            // // XXX: had to empty errors since we dont calculate the query field maps
            // // TODO: map query to fieldPaths so we can eliminate the errors
            // currentErrors: {},
          },
        },
      };
    }
    case SET_FIELD_TOUCHED: {
      const { formId, fieldPath } = action.payload;
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      const touchedFieldPaths = form.touchedFieldPaths || EMPTY_OBJECT;
      const path = normalizeFieldPath(fieldPath);
      if (touchedFieldPaths[path] === true) {
        return state;
      }
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            touchedFieldPaths: {
              ...touchedFieldPaths,
              [path]: true,
            },
          },
        },
      };
    }
    case SET_FIELD_WATCHED: {
      const { formId, fieldPath } = action.payload;
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      const watchedFieldPaths = form.watchedFieldPaths || EMPTY_OBJECT;
      const path = normalizeFieldPath(fieldPath);
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            watchedFieldPaths: {
              ...watchedFieldPaths,
              [path]: (watchedFieldPaths[path] || 0) + 1,
            },
          },
        },
      };
    }
    case SET_FIELD_UNWATCHED: {
      const { formId, fieldPath } = action.payload;
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      const watchedFieldPaths = form.watchedFieldPaths || EMPTY_OBJECT;
      const path = normalizeFieldPath(fieldPath);
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            watchedFieldPaths: {
              ...watchedFieldPaths,
              [path]:
                watchedFieldPaths[path] > 0
                  ? watchedFieldPaths[path] - 1
                  : watchedFieldPaths[path],
            },
          },
        },
      };
    }
    case SET_FIELD_ERRORS: {
      const { formId, errors } = action.payload;
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      const currentErrors = normalizeErrors(errors);
      if (isEqual(currentErrors, form.currentErrors)) {
        return state;
      }
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            currentErrors,
            isValid: isEmpty(currentErrors),
          },
        },
      };
    }
    case SUBMIT_START: {
      const { formId } = action.payload;
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            flags: {
              ...form.flags,
              submitCount: form.flags.submitCount + 1,
              isValidating: true,
              isSubmitting: false,
              isManuallyValidated: false,
              isSuccessfullyValidated: false,
              isSuccessfullySubmitted: false,
              isUnsuccessfullySubmitted: false,
            },
          },
        },
      };
    }
    case SUBMIT_SUCCESS: {
      const { formId, submittedValues } = action.payload;
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            currentErrors: {},
            submittedValues,
            flags: {
              ...form.flags,
              isValidating: false,
              isSubmitting: true,
              isSuccessfullyValidated: true,
              isSuccessfullySubmitted: true,
              isUnsuccessfullySubmitted: false,
              isValid: true,
            },
          },
        },
      };
    }
    case SUBMIT_ERROR: {
      const { formId, errors, submittedValues } = action.payload;
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      const currentErrors = normalizeErrors(errors);
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            currentErrors,
            submittedValues,
            flags: {
              ...form.flags,
              isValidating: false,
              isSubmitting: false,
              isSuccessfullyValidated: true,
              isSuccessfullySubmitted: false,
              isUnsuccessfullySubmitted: true,
              isValid: isEmpty(currentErrors),
            },
          },
        },
      };
    }
    case RESET_FORM_STATE: {
      const {
        formId,
        resetWatchedFields,
        resetTouchedFields,
        resetSubmitCount,
        resetErrors,
        resetValues,
        resetInitialValues,
        resetSubmittedValues,
      } = action.payload;
      const form = state.forms[formId] || INITIAL_FORM_STATE;
      const initialValues = resetInitialValues || form.initialValues;
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            initialValues,
            watchedFieldPaths:
              resetWatchedFields === true
                ? EMPTY_OBJECT
                : form.watchedFieldPaths,
            touchedFieldPaths:
              resetTouchedFields === true
                ? EMPTY_OBJECT
                : form.touchedFieldPaths,
            currentErrors:
              resetErrors === true ? EMPTY_OBJECT : form.currentErrors,
            currentValues:
              resetValues === true
                ? cloneDeep(initialValues)
                : form.currentValues,
            submittedValues:
              resetSubmittedValues === true
                ? EMPTY_OBJECT
                : form.submittedValues,
            flags: {
              ...form.flags,
              submitCount:
                resetSubmitCount === true ? 0 : form.flags.submitCount,
            },
          },
        },
      };
    }
    case SET_FORM_STATE: {
      const { formId, increaseSubmitCount, setUnsuccessfullySubmitted } =
        action.payload;
      const form = state.forms[formId] || INITIAL_FORM_STATE;
      let {
        isSuccessfullySubmitted,
        isUnsuccessfullySubmitted,
        isSuccessfullyValidated,
      } = form.flags;
      if (setUnsuccessfullySubmitted) {
        isSuccessfullySubmitted = false;
        isUnsuccessfullySubmitted = true;
        isSuccessfullyValidated = true;
      }
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            flags: {
              ...form.flags,
              submitCount: increaseSubmitCount
                ? form.flags.submitCount + 1
                : form.flags.submitCount,
              isSuccessfullySubmitted,
              isUnsuccessfullySubmitted,
              isSuccessfullyValidated,
            },
          },
        },
      };
    }
    case DELETE: {
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.payload.formId]: undefined,
        },
      };
    }
    case VALIDATE_START: {
      const { formId } = action.payload;
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            flags: {
              ...form.flags,
              isValidating: true,
              isManuallyValidated: false,
              isSubmitting: false,
              isSuccessfullyValidated: false,
            },
          },
        },
      };
    }
    case VALIDATE_SUCCESS: {
      const { formId, errors, onlyWatchedFields } = action.payload;
      const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
      const currentErrors = normalizeErrors(
        errors,
        onlyWatchedFields ? form.watchedFieldPaths : undefined,
      );
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...form,
            currentErrors,
            flags: {
              ...form.flags,
              isValidating: false,
              isManuallyValidated: true,
              isSuccessfullyValidated: true,
              isUnsuccessfullySubmitted: !isEmpty(currentErrors)
                ? true
                : form.flags.isUnsuccessfullySubmitted,
              isValid: isEmpty(currentErrors),
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default fastFormReducer;
