const namespace = 'recSelectApac';

const REC_SELECT_APAC = {
  BANNER: {
    TITLE: {
      key: `${namespace}.banner.title`,
      message: 'REC Select',
    },
    DESCRIPTION: {
      key: `${namespace}.banner.bannerDescription`,
      message:
        'Register your installations and earn RECoins. Redeem them for discount vouchers, plus more benefits!',
    },
  },
  CAMPAIGN_INFO: {
    WHAT_ARE_RECOINS: {
      TITLE: {
        key: `${namespace}.campaignInfo.whatAreRecoins.title`,
        message: 'What are RECoins?',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.whatAreRecoins.whatAreRecoinsDescription`,
        message:
          'RECoins are earned by REC Certified Solar Professional installers and can be redeemed for vouchers that are used towards a discount of future orders of REC products from official REC distributors.',
      },
    },
    HOW_TO_EARN_RECOINS: {
      TITLE: {
        key: `${namespace}.campaignInfo.howToEarnRecoins.title`,
        message: 'How to earn RECoins?',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.howToEarnRecoins.howToEarnCoinsDescription`,
        message:
          'Earn RECoins by registering REC installations that use either REC Alpha, REC TwinPeak 4 or REC TwinPeak 5 families of solar panels:',
      },
      REC_ALPHA_REWARD: {
        key: `${namespace}.campaignInfo.howToEarnRecoins.recAlphaReward`,
        message:
          'Each registered kW (kilowatt) of REC Alpha products corresponds to 2 RECoins (1 kW Alpha = 2 RECoins).',
      },
      REC_TWIN_PEAK_REWARD: {
        key: `${namespace}.campaignInfo.howToEarnRecoins.recTwinPeakReward`,
        message:
          'Each registered kW (kilowatt) from an REC TwinPeak 4 or REC TwinPeak 5 family product earns you 1 RECoin (1 kW TwinPeak 4 = 1 RECoin; 1 kW TwinPeak 5 = 1 RECoin).',
      },
      FOOTER: {
        key: `${namespace}.campaignInfo.howToEarnRecoins.footer`,
        message:
          'RECoins may be earned retroactively for successful registrations of installations dated January 1, 2023 or later.',
      },
    },
    HOW_MUCH_ARE_RECOINS_WORTH: {
      TITLE: {
        key: `${namespace}.campaignInfo.howMuchAreRecoinsWorth.title`,
        message: 'How much are RECoins worth?',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.howMuchAreRecoinsWorth.howMuchRecoinsAreWorthDescription`,
        message:
          '1 RECoin may be redeemed for a AU$5 voucher. For example, an installer has registered: 100 kW Alpha = 200 RECoins 50 kW TwinPeak 4 = 50 RECoins Total: 250',
      },
      FOOTER: {
        key: `${namespace}.campaignInfo.howMuchAreRecoinsWorth.footer`,
        message:
          'If all RECoins were to be redeemed at once, this would generate a voucher with a value of AU$1,250!',
      },
    },
    HOW_TO_REDEEM_RECOINS: {
      TITLE: {
        key: `${namespace}.campaignInfo.howToRedeemRecoins.title`,
        message: 'How to redeem RECoins?',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.howToRedeemRecoins.howtoRedeemRecoinsDescription`,
        message:
          'You can redeem RECoins for vouchers, using either the REC SunSnap app, or registration tool in the REC ProPortal. You decide how many RECoins you want to redeem. A voucher (including the equivalent AU$ value) is generated as a PDF, which you supply to your preferred official REC distributor at the time of order. The AU$ value indicated on the voucher will be discounted from your order’s invoice!',
      },
      FOOTER: {
        key: `${namespace}.campaignInfo.howToRedeemRecoins.footer`,
        message:
          'Important: Only REC Certified Solar Professional installers who have achieved REC Select status (Silver, Gold and Platinum) may redeem RECoins. You can find your status on the My Company page.',
      },
    },
    REC_SELECT_STATUS_AND_LEVELS: {
      TITLE: {
        key: `${namespace}.campaignInfo.recSelectStatusAndLevels.title`,
        message: 'REC Select status and tiers',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.recSelectStatusAndLevels.statusAndLevelsDescription`,
        message:
          'REC Select is an elite group of REC Certified Solar Professional installers. There are three REC Select tiers:',
      },
      FOOTER: {
        key: `${namespace}.campaignInfo.recSelectStatusAndLevels.footer`,
        message:
          '*(REC Alpha, REC TwinPeak 4  and/or REC TwinPeak 5) registered in the previous calendar year. Your RECSelect level is locked in for the year on February 1, and is based on registered volume (kW) in the previous calendar year. At the start of a new year, your counter is reset and the registered volume goes towards your next year’s level, while you keep accumulating RECoins at the same time.',
      },
    },
    BENEFITS_FOR_REC_SELECT: {
      TITLE: {
        key: `${namespace}.campaignInfo.benefitsForRecSelect.title`,
        message: 'Benefits for REC Select members:',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.benefitsForRecSelect.benefitsDescription`,
        message: `<ul> <li>Eligible to redeem RECoins</li> <li>Dedicated REC KAM support</li> <li>including C&I project opportunities</li> <li>Use of REC Select logo (according to level) attouchpoints such as website and e-mail signatures</li> <li>Co-marketing support from local PR agency together with funds of AU$5,000/annum (Gold-level only)</li> <li>Paid Social Media sales lead generation (Gold-level only)</li> <li>Invitation to REC’s annual ANZ Excellence Awards dinner (Gold-level only).</li> </ul>`,
      },
    },
  },
  // NAME: {
  //   key: `${namespace}.name`,
  //   message: 'REC Select Partner Program',
  // },
  // TITLE: {
  //   key: `${namespace}.title`,
  //   message: 'REC Select',
  // },
  // YOUR_LEVEL_LABEL: {
  //   key: `${namespace}.yourLevel`,
  //   message: 'Your level',
  // },
  // YOUR_BALANCE_LABEL: {
  //   key: `${namespace}.yourBalance`,
  //   message: 'Your balance',
  // },
  // YOU_EARNED_LABEL: {
  //   key: `${namespace}.youEarned`,
  //   message: 'You earned with this registration',
  // },
  // YOUR_STATEMENT: {
  //   key: `${namespace}.yourStatement`,
  //   message: 'Your statement',
  // },
  // YOUR_INSTALLATIONS: {
  //   key: `${namespace}.yourInstallations`,
  //   message: 'Your installations',
  // },
  // NEXT_LEVEL: {
  //   key: `${namespace}.nextLevel`,
  //   message: 'Next level',
  // },
  // ACHIEVED_LEVEL: {
  //   key: `${namespace}.achievedLevel`,
  //   message: 'Achieved level',
  // },
  // NEXT_LEVEL_MARK_LABEL: {
  //   YOU_NEED: {
  //     key: `${namespace}.nextLevelMark.youNeed`,
  //     message: 'You need',
  //   },
  //   TO_NEXT_LEVEL: {
  //     key: `${namespace}.nextLevelMark.toNext`,
  //     message: 'to the next level',
  //   },
  // },
  // INFORMATIVE: {
  //   EACH_REGISTERED_WATT: {
  //     key: `${namespace}.eachRegisteredWatt`,
  //     message: 'Each registered kWatt',
  //   },
  //   REGISTER_YOUR_INSTALLATIONS: {
  //     key: `${namespace}.registerYourInstallations`,
  //     message: 'Register your installations, earn points and more advantages!',
  //   },
  //   WATT_POINTS_CAN_BE_EARNED: {
  //     key: `${namespace}.wattPointsCanBeEarned`,
  //     message:
  //       'WattPoints can be earned by participants and redeemed for vouchers that allow a discount for purchases from an official REC distributor.',
  //     // message:
  //     //   'WattPoints can be earned by participants and redeemed for vouchers that allow a discount for purchases from an official REC distributor.<br /><br />WattPoints can be earned by registering REC installations with either TwinPeak 4 or Alpha family panels',
  //   },
  //   REC_TWIN_PEAK4_SERIES_KW_TO_RECOINS: {
  //     key: `${namespace}.recTwinpeak4SeriesKWattToRecoins`,
  //     message:
  //       'Each registered kWatt from a TwinPeak 4 family product corresponds to 1 RECoins',
  //   },
  //   FROM_TWIN_PEAK_FAMILY: {
  //     key: `${namespace}.fromTwinPeakFamily`,
  //     message: 'from a TwinPeak 4 family product corresponds to',
  //   },
  //   TWIN_PEAK_REWARD: {
  //     key: `${namespace}.twinPeakReward`,
  //     message: '1 RECoins',
  //   },
  //   FROM_ALPHA_FAMILY: {
  //     key: `${namespace}.fromTwinPeakFamily`,
  //     message: 'from a Alpha family product corresponds to',
  //   },
  //   ALPHA_REWARD: {
  //     key: `${namespace}.twinPeakReward`,
  //     message: '2 RECoins',
  //   },
  //   REC_ALPHA_SERIES_KW_TO_RECOINS: {
  //     key: `${namespace}.recAlphaSeriesKWattToRecoins`,
  //     message:
  //       'Each registered kWatt from a Alpha family product corresponds to 2 RECoins',
  //   },
  //   INSTALLATION_QUALIFIED: {
  //     key: `${namespace}.installationQualified`,
  //     message:
  //       'Nice Work! this installation qualifies for the REC SELECT Loyalty Program, where you can earn',
  //   },
  //   PAST_INSTALLATIONS: {
  //     key: `${namespace}.installationQualified`,
  //     message:
  //       'Your installations registered in 2021 qualify for the REC SELECT Loyalty Program, where you can earn',
  //   },
  //   BANNER_DESCRIPTION: {
  //     key: `${namespace}.bannerDescription`,
  //     message:
  //       'Register your installations and earn RECoins.  Redeem them for discount vouchers, plus more benefits!',
  //   },
  //   MORE_INFORMATION_BUTTON: {
  //     key: `${namespace}.moreInformationButton`,
  //     message: 'More information about REC SELECT',
  //   },
  // },
  // REDEEM_YOUR_REC_COINS: {
  //   key: `${namespace}.redeemYourRECCoins`,
  //   message:
  //     'Redeem your RECoins for a voucher that you can use for discount at any official REC distributor in your region',
  // },
  // REDEEM_REC_COINS: {
  //   key: `${namespace}.redeemRECCoins`,
  //   message: 'REDEEM RECoins',
  // },
  // CONVERT_YOUR_RECOINS: {
  //   key: `${namespace}.convertRECCoins`,
  //   message: 'Convert your RECoins',
  // },
  // ENTER_THE_AMOUNT_OF_REC_COINS: {
  //   key: `${namespace}.enterTheAmountOfRecCoins`,
  //   message: 'Enter the amount of RECoins you would like to redeem.',
  // },
  // REDEEM: {
  //   key: `${namespace}.redeem`,
  //   message: 'Redeem',
  // },
  // AVAILABLE: {
  //   key: `${namespace}.available`,
  //   message: 'Available',
  // },
  // CURRENT_VALUE: {
  //   key: `${namespace}.currentValue`,
  //   message: 'Current value',
  // },
  // VOUCHERS: {
  //   key: `${namespace}.vouchers`,
  //   message: 'Vouchers',
  // },
  // VOUCHER: {
  //   key: `${namespace}.voucher`,
  //   message: 'Voucher',
  // },
  // STATUS: {
  //   key: `${namespace}.status`,
  //   message: 'Status',
  // },
  // REDEEM_MODAL: {
  //   WITH_THIS_OPERATION: {
  //     key: `${namespace}.redeemModal.withThisOperation`,
  //     message: 'With this operation you will redeem',
  //   },
  //   THIS_OPERATION_WILL: {
  //     key: `${namespace}.redeemModal.thisOperationWill`,
  //     message:
  //       'This operation will use this voucher and give you a credit with REC worth',
  //   },
  //   CONVERSION: {
  //     key: `${namespace}.redeemModal.conversion`,
  //     // eslint-disable-next-line no-template-curly-in-string
  //     message: '{coins} RECoins for {currency} {value}',
  //   },
  //   DO_YOU_WANT_TO_PROCEED: {
  //     key: `${namespace}.redeemModal.doYouWantToProceed`,
  //     message: 'Do you want to proceed?',
  //   },
  // },
  // VOUCHER_ID: {
  //   key: `${namespace}.voucherId`,
  //   message: 'Voucher ID',
  // },
  // DATE_OF_CREATION: {
  //   key: `${namespace}.dateOfCreation`,
  //   message: 'Date of creation',
  // },
  // VALID_UNTIL: {
  //   key: `${namespace}.validUntil`,
  //   message: 'Valid until',
  // },
  // DATE_OF_USE: {
  //   key: `${namespace}.dateOfUse`,
  //   message: 'Date of use',
  // },
  // VALUE: {
  //   key: `${namespace}.value`,
  //   message: 'Value',
  // },
  // DO_YOU_WANT_TO_PARTICIPATE: {
  //   key: `${namespace}.wantToParticipate`,
  //   message: 'Do you want to participate?',
  // },
  // PARTICIPATE: {
  //   key: `${namespace}.participate`,
  //   message: 'Participate',
  // },
};

export default REC_SELECT_APAC;
