import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { F } from 'ts-toolbelt';
import uuid from 'uuid/v4';
import { ObjectShape } from 'yup';

import {
  generateValidationSchema,
  StoreLocatorSearchFormValues,
} from './configs';

const INITIAL_VALUES = new StoreLocatorSearchFormValues();
const FORM_OPTIONS = {
  enableReinitialize: false,
  cleanValuesOnReinitialize: false,
};

const FORM_ID = uuid();

interface Props {
  children: React.ReactNode;
  onSubmitSuccess: F.Function;
  customValidationSchema?: ObjectShape;
  // customInitialValues?: Record<string, any>;
}

const SearchFormProvider: React.FC<Props> = ({
  children,
  onSubmitSuccess,
  customValidationSchema, // customInitialValues,
}: Props) => (
  // const initialValues = useMemo(() => {
  //   if (isEmpty(customInitialValues)) return INITIAL_VALUES;
  //   return {
  //     ...INITIAL_VALUES,
  //     ...customInitialValues,
  //   };
  // }, [customInitialValues]);
  <FastFormProvider
    formId={FORM_ID}
    initialValues={INITIAL_VALUES}
    validationSchema={generateValidationSchema(customValidationSchema)}
    onSubmitSuccess={onSubmitSuccess}
    options={FORM_OPTIONS}
  >
    {children}
  </FastFormProvider>
);
export default SearchFormProvider;
