import { CountryCode } from '@mmw/constants-country-codes';
import { EMPTY_ARRAY } from '@mmw/constants-utils';
import React, { createContext, ReactNode, useMemo } from 'react';

const INITIAL_VALUES = {
  restrictedCountries: EMPTY_ARRAY,
};

export type AvailableCountriesContextValue = {
  restrictedCountries: Array<CountryCode>;
};

export const AvailableCountriesContext =
  createContext<AvailableCountriesContextValue>(INITIAL_VALUES);

type Props = {
  children: ReactNode;
  restrictedCountries: Array<CountryCode> | null | void;
};

export const AvailableCountriesProvider: React.FC<Props> = ({
  restrictedCountries,
  children,
}: Props) => {
  const config = useMemo(
    () => ({
      restrictedCountries: restrictedCountries || EMPTY_ARRAY,
    }),
    [restrictedCountries],
  );
  return (
    <AvailableCountriesContext.Provider value={config}>
      {children}
    </AvailableCountriesContext.Provider>
  );
};
