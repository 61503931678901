import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/ja';

import { Input } from '@material-ui/core';
import { I18nShape } from '@mmw/constants-i18n';
import { DATEPICKER_TODAY } from '@mmw/constants-i18n-common';
import { useMemo } from '@mmw/redux-store-i18n/hooks';
import { FormatDateType } from '@mmw/ui-hoc-with-format-date';
import { EMPTY_OBJECT } from '@mmw/ui-theme/utils';
import { Body2 } from '@mmw/ui-web-elements-typography';
import componentModifiers from '@ui-system/default-modifiers/form/input';
import { useInputStyle } from '@ui-system/default-styles';
import UI from '@ui-system/ui';
import React, { useCallback, useRef } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import {
  DayPickerInputProps,
  DayPickerProps,
  // eslint-disable-next-line import/no-unresolved
} from 'react-day-picker/types/Props';

import Navbar from './Navbar';
import StyledWrapper from './StyledWrapper';

const CLEAN_ICON_STYLE = {
  cursor: 'pointer',
};

export type DisabledDaysConfig = {
  before?: Date;
  after?: Date;
};

export type DateInputProps = {
  style?: Record<string, any>;
  locale?: string;
  formatDate?: FormatDateType;
  inputProps?: Record<string, any>;
  onChange: (value: number | Date) => void;
  value: number | string | Date;
  placeholder?: string | I18nShape;
  CustomIcon?: React.FC;
  spanOnRight: boolean;
  useTodayButton?: boolean;
  showOutsideDays?: boolean;
  disabledDays?: DisabledDaysConfig | DisabledDaysConfig[];
  dateFormat?: {
    year: string;
    month: string;
    day: string;
  };
  modifiers?: string | string[];
  disableCleanIcon?: boolean;
} & Partial<Omit<DayPickerInputProps, 'onChange'>>;

const DateInput: React.FC<DateInputProps> = ({
  locale,
  onChange,
  placeholder,
  formatDate,
  dateFormat,
  value,
  CustomIcon,
  spanOnRight,
  useTodayButton,
  showOutsideDays,
  disabledDays,
  inputProps,
  disableCleanIcon,
  ...rest
}: Partial<DateInputProps>) => {
  const datePickerRef = useRef(null);
  // @ts-ignore
  const dayPickerProps: Partial<DayPickerProps> = useMemo(
    () => ({
      locale,
      localeUtils: MomentLocaleUtils,
      selectedDays: value,
      navbarElement: Navbar,
      todayButton: useTodayButton ? (
        <Body2 className="DayPicker-TodayButton" i18n={DATEPICKER_TODAY} />
      ) : undefined,
      showOutsideDays,
      disabledDays,
    }),
    [locale, value, useTodayButton, showOutsideDays, disabledDays],
  );
  const onChangeValue = useCallback(
    v => {
      if (onChange) onChange(v);
    },
    [onChange],
  );
  const formattedValue = useMemo(
    () => (value && formatDate ? formatDate(value as Date, dateFormat) : value),
    [dateFormat, formatDate, value],
  );
  const cachedInputProps = useMemo(
    () => ({
      ...rest,
      ...(inputProps || EMPTY_OBJECT),
    }),
    [inputProps, rest],
  );
  const icon = useMemo(
    () =>
      CustomIcon ? (
        <CustomIcon />
      ) : (
        <UI.Icon transform="translate(0,-10%)" name="calendar" size={24} />
      ),
    [CustomIcon],
  );

  const cleanIcon = useMemo(() => {
    if (disableCleanIcon) return null;
    return (
      <UI.Container
        style={CLEAN_ICON_STYLE}
        align="center"
        justify="center"
        // @ts-ignore
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onChangeValue('');
        }}
      >
        <UI.Icon name="close" size={24} color="gray.A200" />
      </UI.Container>
    );
  }, [onChangeValue, disableCleanIcon]);
  const finalStyle = useInputStyle(
    undefined,
    undefined,
    componentModifiers,
    {},
  );
  const Component = useCallback(
    (props: Record<string, any>) => (
      <UI.Container style={finalStyle}>
        <Input
          fullWidth
          endAdornment={spanOnRight ? icon : cleanIcon}
          value={formattedValue as string}
          startAdornment={!spanOnRight ? icon : cleanIcon}
          disableUnderline
          readOnly
          disabled={inputProps?.disabled}
          {...props}
        />
      </UI.Container>
    ),
    [
      cleanIcon,
      finalStyle,
      formattedValue,
      icon,
      inputProps?.disabled,
      spanOnRight,
    ],
  );
  return (
    <StyledWrapper spanOnRight={spanOnRight}>
      <DayPickerInput
        component={Component}
        inputProps={cachedInputProps}
        value={formattedValue}
        dayPickerProps={dayPickerProps}
        placeholder={placeholder}
        onDayChange={onChangeValue}
        ref={datePickerRef}
      />
    </StyledWrapper>
  );
};

DateInput.defaultProps = {
  locale: 'en',
  spanOnRight: false,
  useTodayButton: false,
  showOutsideDays: false,
  dateFormat: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
};
export const MAX_TODAY_DISABLED_DAYS = [
  {
    after: new Date(),
  },
];
export default DateInput;
