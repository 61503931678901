import { CardProps as MaterialCardProps } from '@material-ui/core/Card';
import { CollapseProps } from '@material-ui/core/Collapse';
import { Direction, Shadow } from '@ui-system/css/utils/types';
import { I18nShape } from '@ui-system/deps';
import { ImageProps, ImageType } from '@ui-system/interfaces/image';
import { Style, StyleProp } from '@ui-system/interfaces/types';
import { TypographyVariant } from '@ui-system/interfaces/typography';
import { nanoid } from 'nanoid';
import React from 'react';

export type CardCommonProps = {
  style?: StyleProp | React.CSSProperties;
  children: React.ReactNode;
} & Partial<MaterialCardProps>;

export interface CardProps extends CardCommonProps {
  shadow?: Shadow;
  square?: boolean;
  direction?: Direction;
  elevation?: number;
}

export type CardMediaProps = ImageProps;
export interface CardActionAreaProps {
  style?: Style;
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

export interface CardHeaderProps {
  style?: Style;
  i18nTitle?: I18nShape;
  i18nDescription?: I18nShape;
  titleModifiers?: string;
  descriptionModifiers?: string;
  titleVariant?: TypographyVariant;
  descriptionVariant?: TypographyVariant;
  action?: React.ReactNode;
  accessory?: React.ReactNode;
}

export interface CardActionsProps extends CardCommonProps {
  style?: Style;
  disableSpacing?: boolean;
}

export interface CardStatics {
  ActionArea: CardActionAreaType;
  Media: ImageType;
  Header: CardHeaderType;
  Content: CardContentType;
  Actions: CardActionsType;
  CollapseArea: CardCollapseAreaType;
}

export type CardType = React.FC<CardProps> & CardStatics;
export type CardActionAreaType = React.FC<CardActionAreaProps>;
export type CardMediaType = React.FC<CardMediaProps>;
export type CardHeaderType = React.FC<CardHeaderProps>;
export type CardContentType = React.FC<CardCommonProps>;
export type CardActionsType = React.FC<CardActionsProps>;
export type CardCollapseAreaType = React.FC<CollapseProps>;

export const CARD_UUID = nanoid();
export const CARD_ACTION_AREA_UUID = nanoid();
