import { isIE11 } from '@mmw/constants-devices';

import apiV2 from './index';

apiV2.interceptors.request.use(config => {
  const { headers } = config;
  if (isIE11) {
    return {
      ...config,
      headers: {
        ...headers,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store',
        Expires: '0',
      },
    };
  }
  return config;
});
