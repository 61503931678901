import { deepMergeAll } from '@shared-utils/object';
import { fastHash } from '@ui-system/deps';
import { Modifiers, Style, StyleOrFn } from '@ui-system/interfaces';
import { U } from '@utils/ts';
import { compact, concat, is, map, split } from 'fp';
import { isArray, join, replace } from 'lodash';
import memoize from 'lodash/memoize';

export const internalApplyModifiers = (
  modifiers: U.Nullable<Modifiers>,
  componentModifiers: U.Nullable<Record<string, StyleOrFn>>,
  previousStyle: U.Nullable<Style> = undefined,
): U.Nullable<Style> => {
  if (!modifiers || !componentModifiers) return previousStyle;
  const safeModifiers = replace(
    isArray(modifiers) ? join(modifiers, ',') : modifiers,
    / /g,
    '',
  );
  const keys = split(',', safeModifiers);
  const stylesFromComponentModifiers = map(key => {
    if (is(Function, componentModifiers[key])) {
      // @ts-ignore
      return componentModifiers[key]() as () => Style;
    }
    return componentModifiers[key];
  }, keys);
  const arraysToDeepMerge = compact(
    concat([previousStyle], stylesFromComponentModifiers),
  );
  // @ts-ignore
  return deepMergeAll(arraysToDeepMerge);
  // return deepmerge(previousStyle, pick(keys, componentModifiers));
};

export const applyModifiers = memoize(internalApplyModifiers, (...params) =>
  fastHash(params),
);
