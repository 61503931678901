const warningModalNamespace = 'productRegistrationWarningModal';

const WARNING_MODAL = {
  TITLE: {
    key: `${warningModalNamespace}.title`,
    message: 'Confirm Registration',
  },
  DESCRIPTION: {
    key: `${warningModalNamespace}.description`,
    message:
      'Are you sure you want to perform the registration? We detected that a few issues are still pending. If you can’t correct them right now, you can perform the registration and contact us.',
  },
};

const formNamespace = 'productRegistrationForm';

const FORM = {
  INVALID: {
    key: `${formNamespace}.invalid`,
    message: 'Please check the form, one of the fields need your attention.',
  },
  CREATE_NEW_SELLER: {
    key: `${formNamespace}.createNewSeller`,
    message: 'Create new seller',
  },
};

const INVOICE_NUMBER_CONFIRMATION_MODAL = {
  TITLE: {
    key: 'invoiceNumberConfirmation.modal.title',
    message: 'Duplicated invoice number',
  },
  DESCRIPTION: {
    key: 'invoiceNumberConfirmation.modal.description',
    message:
      'This invoice number was already registered by you. Are you sure you want to go on?',
  },
};

const I18N = {
  WARNING_MODAL,
  FORM,
  INVOICE_NUMBER_CONFIRMATION_MODAL,
};

export default I18N;
