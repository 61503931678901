import ACCOUNT from './account';
import ADDRESS from './address';
import CAMPAIGN from './campaign';
import COMMON from './common';
import COMPANY from './company';
import CONSUMER from './consumer';
import DEFAULT from './default';
import FILE from './file';
import PERSON from './person';
import PRODUCT from './product';
import PROJECT from './project';
import QUESTION_GROUP from './questionGroup';
import REGISTRATION from './registration';
import REGISTRATION_ITEM from './registrationItem';
import SALESORGBRAND from './salesorgbrand';
import THIRD_PARTY_CAMPAIGN_ITEM from './thirdPartyCampaignItem';

export { default as ACCOUNT } from './account';
export { default as ADDRESS } from './address';
export { default as CAMPAIGN } from './campaign';
export { default as COMMON } from './common';
export { default as COMPANY } from './company';
export { default as CONSUMER } from './consumer';
export { default as DEFAULT } from './default';
export { CUSTOM } from './default';
export { default as FILE } from './file';
export { default as PERSON } from './person';
export { default as PRODUCT } from './product';
export { default as PROJECT } from './project';
export { default as QUESTION_GROUP } from './questionGroup';
export { default as REGISTRATION } from './registration';
export { default as REGISTRATION_ITEM } from './registrationItem';
export { default as SALESORGBRAND } from './salesorgbrand';
export { default as THIRD_PARTY_CAMPAIGN_ITEM } from './thirdPartyCampaignItem';
export { yupValidations } from './yup';

const I18N = {
  ACCOUNT,
  ADDRESS,
  CAMPAIGN,
  COMPANY,
  COMMON,
  CONSUMER,
  DEFAULT,
  FILE,
  PERSON,
  PRODUCT,
  QUESTION_GROUP,
  REGISTRATION,
  REGISTRATION_ITEM,
  SALESORGBRAND,
  THIRD_PARTY_CAMPAIGN_ITEM,
  PROJECT,
};

export default I18N;
