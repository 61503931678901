import { STORE } from '@mmw/constants-i18n-fields';
import { useTranslate, useTranslateShape } from '@mmw/utils-text-utils/hooks';
import { OptionType } from '@ui-system/interfaces-form';
import map from 'lodash/map';
import { useMemo } from 'react';

export const DAYS_OF_WEEK = [
  STORE.OPENING_TIMES.DAY_OF_WEEK.SUNDAY,
  STORE.OPENING_TIMES.DAY_OF_WEEK.MONDAY,
  STORE.OPENING_TIMES.DAY_OF_WEEK.TUESDAY,
  STORE.OPENING_TIMES.DAY_OF_WEEK.WEDNESDAY,
  STORE.OPENING_TIMES.DAY_OF_WEEK.THURSDAY,
  STORE.OPENING_TIMES.DAY_OF_WEEK.FRIDAY,
  STORE.OPENING_TIMES.DAY_OF_WEEK.SATURDAY,
];

export function useDaysOfTheWeek(): OptionType[] {
  const translate = useTranslate();
  return useMemo(
    () =>
      map(DAYS_OF_WEEK, (day, index) => ({
        value: index,
        label: translate(day),
      })),
    [translate],
  );
}

export function useDayOfTheWeekTranslation(dayOfTheWeek: number): string {
  return useTranslateShape(DAYS_OF_WEEK[dayOfTheWeek]);
}
