import { I18nShape } from '@mmw/constants-i18n';
import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { chain, isEmpty } from 'lodash';
import { useMemo } from 'react';

import { FieldPath, ValidationError } from '../../types';
import useNestedErrorMessages from './useNestedErrorMessages';

function useI18nNestedErrorMessage(fieldPath: FieldPath): Array<I18nShape> {
  const errors: Array<ValidationError> = useNestedErrorMessages(fieldPath);
  return useMemo((): Array<I18nShape> => {
    if (isEmpty(errors)) {
      return EMPTY_ARRAY;
    }
    const result = chain(errors)
      .map(
        (validationError: ValidationError) =>
          validationError.errors || EMPTY_ARRAY,
      )
      .flatten()
      .map((errorKey: string) => ({
        key: errorKey,
        message: errorKey,
      }))
      .value();
    return result;
  }, [errors]);
}

export default useI18nNestedErrorMessage;
