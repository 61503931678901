import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { currentSalesOrgBrandSelector } from '@mmw/redux-store-current-salesorgbrand/stateSelector';
import { getResourceService } from '@mmw/services-holder';
import { from, of } from 'rxjs';
import {
  catchError,
  map,
  pluck,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  changeLanguageErrorAction,
  changeLanguageSuccessAction,
} from './actions';
import {
  bundleNameFieldPath,
  secondaryBundleNameFieldPath,
} from './stateSelector';
import { CHANGE_LANGUAGE_START } from './types';

const getResources = async (
  language,
  bundleName,
  secondaryBundleName,
  salesOrgBrand,
) => {
  const resourcesPromise = getResourceService().getCachedResourceBundle({
    bundleName,
    language,
    salesOrgBrand,
  });
  const promises = [resourcesPromise];
  if (secondaryBundleName) {
    const secondaryResourcesPromise =
      getResourceService().getCachedResourceBundle({
        bundleName: secondaryBundleName,
        language,
        salesOrgBrand,
      });
    promises.push(secondaryResourcesPromise);
  }
  const [resources, secondaryResources] = await Promise.all(promises);
  if (secondaryResources) {
    return {
      ...secondaryResources,
      ...resources,
    };
  }
  return resources;
};

const { logger } = contextualConfig.application;

const changeLanguageEpic = (action$, state$) =>
  action$.pipe(
    ofType(CHANGE_LANGUAGE_START),
    withLatestFrom(
      state$.pipe(pluck(...bundleNameFieldPath)),
      state$.pipe(pluck(...secondaryBundleNameFieldPath)),
      state$.pipe(map(currentSalesOrgBrandSelector)),
    ),
    tap(() => logger.debug('Will load the resources')),
    switchMap(([action, bundleName, secondaryBundleName, salesOrgBrand]) =>
      from(
        getResources(
          action.payload.language,
          bundleName,
          secondaryBundleName,
          salesOrgBrand,
        ),
      ).pipe(
        map(data => changeLanguageSuccessAction(data, action.payload.language)),
        catchError(error => of(changeLanguageErrorAction(error))),
      ),
    ),
  );

export default changeLanguageEpic;
