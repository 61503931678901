// http://poseidon/mmw-srv/mmw/blob/master/mmw-constants/src/main/java/de/kybeidos/mmw/constants/UserRolesRules.java
// http://poseidon/mmw-srv/mmw/blob/master/mmw-constants/src-gen/de/kybeidos/mmw/constants/UserRole.java
// http://poseidon/mmw-srv/mmw/blob/master/mmw-constants/src/main/java/de/kybeidos/mmw/constants/UserRoleEnum.java

export enum Roles {
  // SOLAR ROLES
  SOLARSYSADMIN = 'SOLARSYSADMIN',
  SOLARADMIN = 'SOLARADMIN',
  SOLARUSER = 'SOLARUSER',
  SOLARDIST = 'SOLARDIST',
  SOLARMANUFCT = 'SOLARMANUFCT',
  // regular TRADER ROLES
  // OLD ROLES will be @deprecated
  USER = 'USER',
  LOGISTICS = 'LOGISTICS',
  BACKOFFICEREV = 'BACKOFFICEREV',
  ADMIN = 'ADMIN',
  WSADMIN = 'WSADMIN',
  USERBUYING = 'USERBUYING',
  COMGROUP = 'COMGROUP',
  SYSADMIN = 'SYSADMIN',
  // NEW trader ROLES not activated yet
  RETAIL_MANAGEMENT = 'RETAIL_MANAGEMENT',
  RETAIL_BOSS = 'RETAIL_BOSS',
  RETAIL_ADMIN = 'RETAIL_ADMIN',
  RETAIL_ACCOUNTING = 'RETAIL_ACCOUNTING',
  RETAIL_BUYING = 'RETAIL_BUYING',
  RETAIL_SALE = 'RETAIL_SALE',
  RETAIL_BO = 'RETAIL_BO',
  RETAIL_LOGISTICS = 'RETAIL_LOGISTICS',
  // MANUFACTURER ROLES
  MANUFACTURER = 'ROLE_RMANUFCT',
  // CONSUMER ROLES
  CONSUMER = 'CUSTOMER',
  CUSTOMER = 'ROLE_CUSTOMER',
}

export const {
  SOLARSYSADMIN,
  SOLARADMIN,
  SOLARUSER,
  SOLARDIST,
  USER,
  LOGISTICS,
  BACKOFFICEREV,
  ADMIN,
  WSADMIN,
  USERBUYING,
  COMGROUP,
  SYSADMIN,
  RETAIL_MANAGEMENT,
  RETAIL_BOSS,
  RETAIL_ADMIN,
  RETAIL_ACCOUNTING,
  RETAIL_BUYING,
  RETAIL_SALE,
  RETAIL_BO,
  RETAIL_LOGISTICS,
  MANUFACTURER,
  CONSUMER,
  CUSTOMER,
  SOLARMANUFCT,
} = Roles;

export const MANUF_ROLES = [MANUFACTURER];

export const MANUF_SOLAR_ROLES = [SOLARMANUFCT];

export const RETAIL_SOLAR_ROLES = [
  SOLARSYSADMIN,
  SOLARADMIN,
  SOLARUSER,
  SOLARDIST,
];

export const TRADER_ROLES = [
  USER,
  LOGISTICS,
  BACKOFFICEREV,
  ADMIN,
  WSADMIN,
  USERBUYING,
  COMGROUP,
  SYSADMIN,
  RETAIL_MANAGEMENT,
  RETAIL_BOSS,
  RETAIL_ADMIN,
  RETAIL_ACCOUNTING,
  RETAIL_BUYING,
  RETAIL_SALE,
  RETAIL_BO,
  RETAIL_LOGISTICS,
];

export const CONSUMER_ROLES = [CONSUMER, CUSTOMER];
