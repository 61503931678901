import { PRODUCT as FIELDS } from '@mmw/constants-fields-names';
// import {
//   PRODUCT as VALIDATIONS,
// } from '@mmw/constants-fields-validations';
import { INVALID } from '@mmw/constants-validation-names';

const namespace = 'validation';

const PRODUCT = {
  NAME: {},
  SERIALNUMBER: {},
  EAN: {
    INVALID: {
      key: `${namespace}.${FIELDS.EAN}.${INVALID}`,
      message: 'Ean not valid.',
    },
  },
  QUANTITY: {
    MIN: {
      key: `${namespace}.productQuantity.min`,
      message: 'The quantity should be greater or equal to 1',
    },
    MAX: {
      key: `${namespace}.productQuantity.max`,
      message: 'The quantity should not be greater than 10000',
    },
  },
};

export default PRODUCT;
