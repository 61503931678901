import 'dayjs/locale/de';

import { format } from 'date-fns';
import split from 'lodash/split';

export const DE = 'DD.MM.YYYY';
export const EN = 'MM.DD.YYYY';

export const FORMATS = {
  de: DE,
  'de-AT': 'DD.MM.YYYY',
  'de-CH': 'DD.MM.YYYY',
  en: EN,
  'en-GB': 'DD/MM/YYYY',
  'en-AU': 'DD/MM/YYYY',
  'en-CA': 'DD/MM/YYYY',
  'en-IN': 'DD-MM-YYYY',
  ja: 'YYYY/MM/DD',
  es: 'DD/MM/YYYY',
  'es-MX': 'DD/MM/YYYY',
  'es-AR': 'DD/MM/YYYY',
  'es-CO': 'DD/MM/YYYY',
  'es-PE': 'DD/MM/YYYY',
  fr: 'DD/MM/YYYY',
  'fr-CA': 'YYYY-MM-DD',
  'fr-BE': 'DD/MM/YYYY',
  it: 'DD/MM/YYYY',
  'it-CH': 'DD.MM.YYYY',
  nl: 'DD-MM-YYYY',
  'nl-BE': 'DD/MM/YYYY',
  pl: 'DD.MM.YYYY',
  pt: 'DD/MM/YYYY',
  'pt-BR': 'DD/MM/YYYY',
  'pt-PT': 'DD-MM-YYYY',
  ru: 'DD.MM.YYYY',
  'ru-UA': 'DD.MM.YYYY',
  'zh-CN': 'YYYY/MM/DD',
  'zh-TW': 'YYYY/MM/DD',
  'ar-SA': 'DD/MM/YYYY',
  'ar-EG': 'DD/MM/YYYY',
  'hi-IN': 'DD-MM-YYYY',
  'bn-BD': 'DD-MM-YYYY',
  'bn-IN': 'DD-MM-YYYY',
  'pa-IN': 'DD-MM-YYYY',
  'jv-ID': 'DD/MM/YYYY',
  'mr-IN': 'DD-MM-YYYY',
  'ta-IN': 'DD-MM-YYYY',
  'te-IN': 'DD-MM-YYYY',
  'ko-KR': 'YYYY/MM/DD',
  'vi-VN': 'DD/MM/YYYY',
} as const;

export type Formats = keyof typeof FORMATS;

export function formatTime(hour: number, minutes: number): string {
  const date = new Date();
  date.setHours(hour);
  date.setMinutes(minutes);
  const formatted = split(format(date, 'HH:mm'), ':');
  const hourFormatted = formatted[0];
  const minutesFormatted = formatted[1];
  return `${hourFormatted}:${minutesFormatted}`;
}
