import { ThemeColors } from '@ui-system/theme/colors';

import wrapperStyleBuilder from './wrapperStyleBuilder';

const wrapperStyle = ({
  fontFamily,
  colors,
  spanOnRight,
}: {
  fontFamily: string;
  colors: ThemeColors;
  spanOnRight: boolean;
}): string =>
  wrapperStyleBuilder({
    wrapper: {
      fontFamily,
      backgroundColor: colors.lightMode.white,
      // color: colors.lightMode.secondary.main,
    },
    caption: {
      // backgroundColor: 'red',
    },
    weekday: {
      // color: colors.lightMode.primary.main,
    },
    todayButton: {
      backgroundColor: colors.lightMode.primary.main,
      color: colors.lightMode.gray.A300,
      borderRadius: 0,
    },
    day: {
      // color: colors.lightMode.black,
      // borderRadius: 0,
      today: {
        // color: colors.lightMode.black,
      },
      outside: {
        color: colors.lightMode.gray.A500,
        hover: {
          backgroundColor: colors.lightMode.primary.main,
          color: colors.lightMode.white,
        },
      },
      disabled: {
        color: colors.lightMode.gray.A300,
        // hover: {
        //   color: colors.pallet.basic,
        // },
      },
      sunday: {
        // backgroundColor: colors.lightMode.primary.main,
        not: {
          // color: colors.lightMode.primary.main,
        },
      },
      selected: {
        // backgroundColor: colors.lightMode.primary.main,
        // color: colors.lightMode.white,
        hover: {
          // backgroundColor: colors.lightMode.primary.main,
          // color: colors.lightMode.white,
        },
      },
    },
    interactionDisabled: {
      backgroundColor: colors.lightMode.primary.main,
      color: colors.lightMode.white,
    },
    overlay: {
      position: spanOnRight ? 'right: 0;' : 'left: 0;',
      // backgroundColor: colors.lightMode.white,
    },
  });

export default wrapperStyle;
