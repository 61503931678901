import defaultSelectModifiers from '@ui-system/default-modifiers/form/select';
import { SelectProps } from '@ui-system/interfaces-form';

const defaultProps: Partial<SelectProps> = {
  disabled: false,
  modifiers: '',
  componentModifiers: defaultSelectModifiers,
};

export default defaultProps;
