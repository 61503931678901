import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';

import createFromProps from '../create-from-props';
import { Align, FromProps } from '../types';

const applyAlign: F.Function<[Theme, Align]> & FromProps = (
  theme: Theme,
  align: Align,
) => {
  if (!align) return '';
  return `align-items: ${align}`;
};
applyAlign.fromProps = createFromProps(applyAlign, 'align');

export default applyAlign;
