import axios from 'axios';

export async function deepl(
  text: string,
  to: string,
  from = 'en',
): Promise<string> {
  const payload = {
    text,
    target_lang: to,
    source_lang: from,
  };
  const response = await axios.post(
    'https://europe-west3-mmw-clients-rec.cloudfunctions.net/deepl?secret=',
    payload,
  );
  return response.data.text;
}
