import { checkVAT, countries } from 'jsvat';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import memoize from 'lodash/memoize';

import CountriesZipcodeRegex from './CountriesZipcodeRegex';

export const AVAILABLE_VAT_COUNTRIES = countries.reduce(
  (acc, country) => ({
    ...acc,
    [country.codes[0]]: country.codes,
  }),
  {},
);

export const validateVAT = memoize(
  (vat: string, country: string) =>
    checkVAT(
      vat,
      filter(countries, item => includes(item.codes, country)),
    ),
  (vat, country) => `VAT-number-validation=${vat}-${country}`,
);

export const validateZipcode = memoize(
  (zipcode: string, country: string): boolean => {
    const regex = CountriesZipcodeRegex[country];
    if (isEmpty(regex) || !isString(regex)) {
      return true;
    }
    const zipcodeRegex = new RegExp(regex);
    return zipcodeRegex.test(zipcode);
  },
  (zipcode, country) => `${country}-${zipcode}`,
);
