import { EMPTY_OBJECT } from '@mmw/constants-utils';
import contextualConfig from '@mmw/contextual-config';
import { bundleVersion } from '@mmw/utils-text-utils';

import {
  CHANGE_LANGUAGE_ERROR,
  CHANGE_LANGUAGE_START,
  CHANGE_LANGUAGE_SUCCESS,
  Reducer,
} from './types';

export const DEFAULT_LANGUAGE = contextualConfig.application.defaultLanguage;

const INITIAL_STATE = {
  currentLanguage: DEFAULT_LANGUAGE,
  loading: false,
  bundleName: contextualConfig.application.defaultResourceBundleName,
  secondaryBundleName:
    contextualConfig.application.secondaryResourceBundleName || null,
  resourceBundle: EMPTY_OBJECT,
  error: null,
};

const i18nReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE_START:
      return {
        ...state,
        loading: true,
        error: null,
        currentLanguage: action.payload.language,
      };

    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentLanguage: action.payload.language,
        resourceBundle: {
          ...action.payload.resourceBundle,
          [bundleVersion]: Date.now(),
        },
        error: null,
      };

    case CHANGE_LANGUAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default i18nReducer;
