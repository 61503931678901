/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/ban-types, no-param-reassign */
import {
  defaultTo,
  every,
  get as _get,
  isEmpty,
  isObject,
  isString,
  keys,
  map,
  negate,
  set as _set,
  some,
} from 'lodash';
import { F, O, S, U } from 'ts-toolbelt';

export * from './getFieldPaths';

export function isDeepEmpty(obj: any): boolean {
  if (isObject(obj)) {
    if (keys(obj).length === 0) return true;
    return every(map(obj, v => isDeepEmpty(v)));
  }
  if (isString(obj)) {
    return !obj.length;
  }
  return false;
}

export function hasNonEmptyProp(item: any): boolean {
  return some(item, negate(isEmpty));
}

export function deepOverride<T extends object>(
  target: T,
  ...sources: object[]
): T {
  sources.forEach(source => {
    keys(source).forEach(key => {
      // @ts-ignore
      const sourceValue = source[key];
      // @ts-ignore
      const targetValue = target[key];
      // @ts-ignore
      target[key] =
        targetValue &&
        sourceValue &&
        typeof targetValue === 'object' &&
        typeof sourceValue === 'object'
          ? deepOverride(targetValue, sourceValue)
          : sourceValue;
    });
  });
  return target;
}

export function get<
  ObjectType extends object,
  ParamPath extends string,
  DefaultValue,
>(
  object: U.Nullable<ObjectType>,
  path: F.AutoPath<ObjectType, ParamPath>,
  defaultValue?: O.Path<ObjectType, S.Split<ParamPath, '.'>> | DefaultValue,
): U.Nullable<O.Path<ObjectType, S.Split<ParamPath, '.'>>> {
  const value = _get(object, path);
  return defaultValue ? defaultTo(value, defaultValue) : value;
}

export function set<O extends object, P extends string>(
  object: O,
  path: F.AutoPath<O, P>,
  value: O.Path<O, S.Split<P, '.'>>,
): void {
  _set(object, path, value);
}

// TODO: generic type fix
export function getObjectPaths<T>(obj: T): {
  [k: string]: string;
} {
  return Object.keys(obj).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: curr,
    }),
    {},
  );
}
