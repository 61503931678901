import isEmpty from 'lodash/isEmpty';

export interface Callback<Return = void, Args extends any[] = []> {
  (...args: Args | []): Return;
}

export function call<Return = null, Args extends any[] = []>(
  callback: Callback<Return, Args> | undefined,
  ...args: Args | []
): Return {
  if (callback) {
    if (isEmpty(args)) callback();
    return callback(...args);
  }
  return null;
}

// USAGE
// const something = call(someFunction, arg1, arg2, arg3, argn,...);
