import { css } from '@ui-system/css';
import { TYPOGRAPHY_UUID } from '@ui-system/interfaces';
import { Callback, makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const defaultStyleFn: Callback = ({ theme }) => css`
  color: ${theme.typography.colors[theme.mode].onBackground};
  font-family: ${theme.typography.fontFamily.regular};
`;
const useDefaultStyle = makeStyle(defaultStyleFn);

// export function makeFinalStyleHook<
//   Props extends O.Object | null = null,
//   StyleObj = Style,
// >(componentUuid: ComponentUuid) {
//   return function useFinalStyle(
//     styleFromProps: U.Nullable<StyleObj>,
//     modifiers: U.Nullable<Modifiers>,
//     componentModifiers: ComponentModifiersFunction,
//     // @ts-ignore
//     props: Props = null,
//   ): Style {
//     // @ts-ignore
//     const defaultStyle = useDefaultStyle(props);
//     return useComponentFinalStyle(
//       defaultStyle,
//       styleFromProps as unknown as Style,
//       modifiers,
//       componentModifiers,
//       componentUuid,
//     );
//   };
// }
export const useTypographyStyle = makeFinalStyleHook(
  TYPOGRAPHY_UUID,
  useDefaultStyle,
);
