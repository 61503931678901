import { useValue } from '@mmw/redux-store-fast-form/hooks/fields';
import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import { setAlreadySearch } from '@store-locator/store-creator';
import {
  useOriginCoordinates,
  useSearchStoresByLocation,
} from '@store-locator/store-creator/hooks';
import { useEffect, useState } from 'react';
import { U } from 'ts-toolbelt';

import { useCustomUrlPathAsObj, useCustomUrlRequestObj } from '../context';
import { STORE_LOCATOR_FIELDPATHS } from './form/configs';

interface Props {
  shouldSearch: boolean;
  isCurrentLocationTab: boolean;
}

const RefreshSearchOnChangeValuesListener: React.FC<Props> = ({
  shouldSearch,
  isCurrentLocationTab,
}: Props) => {
  const currentRadius = useValue<number>(STORE_LOCATOR_FIELDPATHS.radius.$path);
  const [lastRadius, setLastRadius] =
    useState<U.Nullable<number>>(currentRadius);
  const searchStores = useSearchStoresByLocation();
  const currentCountry = useValue(STORE_LOCATOR_FIELDPATHS.country.$path);
  const customUrlRequestObj = useCustomUrlRequestObj();
  const customUrlPathsAsObj = useCustomUrlPathAsObj();
  const origin = useOriginCoordinates();
  const language = useLanguage();
  useEffect(() => {
    if (currentRadius && currentRadius !== lastRadius && shouldSearch) {
      searchStores({
        language,
        lat: origin?.lat,
        lng: origin?.lng,
        radius: currentRadius,
        country: (isCurrentLocationTab
          ? null
          : currentCountry) as U.Nullable<string>,
        ...customUrlRequestObj,
        ...customUrlPathsAsObj,
      });
      setAlreadySearch(true);
      setLastRadius(currentRadius);
    }
  }, [currentRadius]);

  return null;
};

export default RefreshSearchOnChangeValuesListener;
