import { CONSUMER as FIELDS } from '@mmw/constants-fields-names';

const typeNamespace = 'type';
const labelNamespace = 'label';
// const placeholderNamespace = 'placeholder';

const CONSUMER = {
  CONSUMERS: {
    LABEL: {
      key: `${labelNamespace}.consumers`,
      message: 'Consumers',
    },
  },
  CONSUMER: {
    LABEL: {
      key: `${labelNamespace}.consumer`,
      message: 'Consumer',
    },
  },
  ERP_CONSUMER: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.ERP_CONSUMER}`,
      message: 'ERP',
    },
  },
  TYPE: {
    LABEL: {
      key: `${labelNamespace}.${typeNamespace}.${FIELDS.TYPE}`,
      message: 'Type',
    },
    PRIVATE: {
      LABEL: {
        key: `${labelNamespace}.${typeNamespace}.${FIELDS.TYPE_PRIVATE}`,
        message: 'Private Client',
      },
    },
    BUSINESS: {
      LABEL: {
        key: `${labelNamespace}.${typeNamespace}.${FIELDS.TYPE_BUSINESS}`,
        message: 'Business Customer',
      },
    },
  },
};

export default CONSUMER;
