import contextualConfig from '@mmw/contextual-config';
import { chain, forEach, nth, replace, split } from 'lodash';
import { matchPath } from 'react-router';
import { MonoTypeOperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Action } from './types';

const { logger } = contextualConfig.application;

export const isRoute = (
  ...routePath: Array<string>
): MonoTypeOperatorFunction<Action> =>
  filter<Action>(
    ({
      payload: {
        location: { pathname },
      },
    }) =>
      chain(routePath)
        .map(
          (path: string) =>
            matchPath(pathname, {
              path,
              exact: true,
            }) != null,
        )
        .filter(Boolean)
        .size()
        .value() > 0,
  );

const QUERY_REGEX = /(^\?)/;

// xxx: I made this because the ES6 class its not compatible with IE11;
export const getQueryParameters = <T extends Record<string, unknown>>(
  str: string,
): T => {
  if (!str) return <T>{};
  // @ts-ignore
  const obj = <T>{};
  try {
    const cleanString = replace(str, QUERY_REGEX, '');
    const queryArray = split(cleanString, '&');
    forEach(queryArray, item => {
      const splitResult = split(item, '=');
      const key = nth(splitResult, 0);
      const value = nth(splitResult, 1) || false;
      if (typeof key === 'string') {
        if (value === 'true') {
          // @ts-ignore
          obj[key] = true;
        } else if (value === 'false') {
          // @ts-ignore
          obj[key] = false;
        } else {
          // @ts-ignore
          obj[key] = value;
        }
      }
    });
  } catch (error) {
    logger.warn('Error reading location search params', error);
  }
  return obj;
};
