import {
  SafeTypographyType,
  TypographyProps,
  TypographySet,
  TypographyType,
} from '@ui-system/interfaces-typography';
import React, { memo } from 'react';

const createTypographySet: (
  TypographyComponent: TypographyType,
  SafeTypographyComponent: SafeTypographyType,
) => TypographySet = (
  TypographyComponent: TypographyType,
  SafeTypographyComponent: SafeTypographyType,
) => {
  function createItem(): TypographyType {
    const Component: TypographyType = memo((props: TypographyProps) => (
      <TypographyComponent {...props} />
    )) as unknown as TypographyType;
    Component.Safe = SafeTypographyComponent;
    Component.CustomWrapper = TypographyComponent.CustomWrapper;
    return Component;
  }

  const H1: TypographyType = createItem();
  const H2: TypographyType = createItem();
  const H3: TypographyType = createItem();
  const H4: TypographyType = createItem();
  const H5: TypographyType = createItem();
  const H6: TypographyType = createItem();
  const Subtitle1: TypographyType = createItem();
  const Subtitle2: TypographyType = createItem();
  const Body1: TypographyType = createItem();
  const Body2: TypographyType = createItem();
  const ButtonText: TypographyType = createItem();
  const Caption: TypographyType = createItem();
  const Overline: TypographyType = createItem();

  H1.defaultProps = {
    variant: 'h1',
  };
  H2.defaultProps = {
    variant: 'h2',
  };
  H3.defaultProps = {
    variant: 'h3',
  };
  H4.defaultProps = {
    variant: 'h4',
  };
  H5.defaultProps = {
    variant: 'h5',
  };
  H6.defaultProps = {
    variant: 'h6',
  };
  Subtitle1.defaultProps = {
    variant: 'subtitle1',
  };
  Subtitle2.defaultProps = {
    variant: 'subtitle2',
  };
  Body1.defaultProps = {
    variant: 'body1',
  };
  Body2.defaultProps = {
    variant: 'body2',
  };
  Caption.defaultProps = {
    variant: 'caption',
  };
  ButtonText.defaultProps = {
    variant: 'button',
  };
  Overline.defaultProps = {
    variant: 'overline',
  };

  return {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    Subtitle1,
    Subtitle2,
    Body1,
    Body2,
    Caption,
    ButtonText,
    Overline,
  };
};

export default createTypographySet;
