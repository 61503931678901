import contextualConfig from '@mmw/contextual-config';
import get from 'lodash/get';
import { useMemo } from 'react';
import { F, O, S, U } from 'ts-toolbelt';

import { getQueryParameters } from '../operators';
import useLocation from './useLocation';

const { logger } = contextualConfig.application;

export const useQueryParam = <
  QueryType extends Record<string, string>,
  P extends string = string,
  ReturnType extends U.Nullable<
    O.Path<QueryType, S.Split<P, '.'>>
  > = U.Nullable<O.Path<QueryType, S.Split<P, '.'>>>,
>(
  paramName: F.AutoPath<QueryType, P>,
): U.Nullable<ReturnType> => {
  const location = useLocation();
  return useMemo(() => {
    try {
      const query = new URLSearchParams(location.search);
      return <ReturnType>query.get(paramName);
    } catch (error) {
      logger.warn('Error reading location search params', error);
      const query = <QueryType>getQueryParameters<QueryType>(location.search);
      return <ReturnType>get(query, paramName);
    }
  }, [location.search, paramName]);
};

export const useQueryParamAsBoolean = <
  QueryType extends Record<string, string>,
  P extends string = string,
  ReturnType extends U.Nullable<
    O.Path<QueryType, S.Split<P, '.'>>
  > = U.Nullable<O.Path<QueryType, S.Split<P, '.'>>>,
>(
  paramName: F.AutoPath<QueryType, P>,
): U.Nullable<boolean> => {
  const queryParam = useQueryParam<QueryType, P, ReturnType>(paramName);
  return queryParam ? queryParam === String(true) : false;
};

export default useQueryParam;
