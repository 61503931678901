const namespace = 'audit';

const AUDIT = {
  WELCOME_TITLE: {
    key: `${namespace}.welcomeTitle`,
    message: 'Herzlichen Glückwunsch, <br /> Ihre Anmeldung war erfolgreich!',
  },
  WELCOME_DESCRIPTION: {
    key: `${namespace}.welcomeDescription`,
    message:
      'Wir werden Sie per E-Mail darüber informieren, sobald Ihre Daten verarbeitet wurden und Sie die erste Bestellung vornehmen können. Den Bearbeitungsstand Ihrer Anmeldung können Sie jederzeit auf dieser Seite verfolgen.<br/> Bitte füllen Sie auch das <a href="https://staging.markenmehrwert.com/static/retail-client/campaigns/oralb/audit/sepaForm.pdf" target="_blank"> SEPA-Basis-Lastschriftmandat </a>aus und Senden es an an buchhaltung@walzer.de oder Fax 0203-5705757',
  },
  WELCOME_TITLE_OTHER_STATUS: {
    key: `${namespace}.welcomeTitleOtherStatus`,
    message: 'Sehr geehrter Handelspartner,',
  },
  WELCOME_DESCRIPTION_OTHER_STATUS: {
    key: `${namespace}.welcomeDescriptionOtherStatus`,
    message:
      'Auf dieser Seite finden Sie Ihren Partnerstatus und können weitere Verkaufsstellen für die Distribution der Oral-B iO Produkte anmelden. <br /> Bitte füllen Sie auch das <a href="https://staging.markenmehrwert.com/static/retail-client/campaigns/oralb/audit/sepaForm.pdf" target="_blank"> SEPA-Basis-Lastschriftmandat </a>aus und Senden es an an buchhaltung@walzer.de oder Fax 0203-5705757',
  },
  BUTTONS: {
    FINISH_AUDITING: {
      key: `${namespace}.buttons.finishAuditing`,
      message: 'Finish auditing',
    },
    REPORT_SELLOUT: {
      key: `${namespace}.buttons.reportSellout`,
      message: 'Report sellout',
    },
    ORDER_NOW: {
      key: `${namespace}.buttons.orderNow`,
      message: 'Order now',
    },
    COMPLETE: {
      key: `${namespace}.buttons.complete`,
      message: 'Abschliessen',
    },
    ADD_ANOTHER_STORE: {
      key: `${namespace}.buttons.addAnotherStore`,
      message: 'Verkaufsstelle hinzufügen',
    },
    DOWNLOAD_SEPA_FORM: {
      key: `${namespace}.buttons.downloadSepaForm`,
      message: 'Download SEPA Form',
    },
    NEW_AUDIT_POS: {
      key: `${namespace}.buttons.newAuditPOS`,
      message: 'New Audit - POS',
    },
    NEW_AUDIT_PREMUM_POS: {
      key: `${namespace}.buttons.newAuditPremiumPOS`,
      message: 'New Audit - Premium POS',
    },
  },
  QUESTIONS: {
    TITLE: {
      key: `${namespace}.questions.title`,
      message: 'Beratung und Information:',
    },
    HELP_MODAL: {
      TITLE: {
        key: `${namespace}.questions.helpModal.title`,
        message: 'Audit Fotonachweise',
      },
      DESCRIPTION: {
        key: `${namespace}.questions.helpModal.description`,
        message:
          'Bitte beachten Sie, dass dieser Schritt nach Erhalt der Ware bzw. zu einem späteren Zeitpunkt durchgeführt werden kann.',
      },
    },
  },
  FILES: {
    OPTION1: {
      TITLE: {
        key: `${namespace}.files.option1.title`,
        message: 'Bitte laden Sie hier folgende Screenshots hoch:',
      },
      DESCRIPTION: {
        key: `${namespace}.files.option1.description`,
        message:
          '1 picture illustrating which type of in store presence could be secured: shelf, T&F, gondola end, Shop in Shop',
      },
    },
    TITLE1: {
      key: `${namespace}.files.title1`,
      message: 'Bitte laden Sie hier folgende Screenshots hoch:',
    },
    AUDIT_IMAGE: {
      key: `${namespace}.auditImage`,
      message: 'Audit image',
    },
  },
  CHANGE_STATUS: {
    key: `${namespace}.changeStatus`,
    message: 'Change status',
  },
  UPCOMING_AUDITS: {
    key: `${namespace}.upcomingAudits`,
    message: 'Upcoming audits',
  },
  OPEN_AUDIT: {
    key: `${namespace}.openAudit`,
    message: 'Open audit',
  },
  LAST_AUDIT: {
    key: `${namespace}.lastAudit`,
    message: 'Last audit',
  },
  NEXT_AUDIT: {
    key: `${namespace}.nextAudit`,
    message: 'Next audit',
  },
  INCOMPLETE_EXAMINATION: {
    key: `${namespace}.incompleteExamination`,
    message: 'Imcomplete examination',
  },
  ADD_ANOTHER_STORE: {
    key: `${namespace}.addAnotherStore`,
    message: 'Möchten Sie weitere Verkaufsstellen hinzufügen?',
  },
  LEAVE_AUDIT_WITHOUT_SAVING: {
    key: `${namespace}.leaveAuditWithoutSaving`,
    message:
      'Are you sure you want to leave, the data you entered wont be saved if you leave right now.',
  },
  QUESTION_ISSUE_PLACEHOLDER: {
    key: `${namespace}.questionIssuePlaceholder`,
    message:
      'Write the reason the question answer is not valid. Explain that the user will have to change their answer in order for the audit to be correct. This is only for the question answer, not applies to the files.',
  },
  QUESTION_FILE_PLACEHOLDER: {
    key: `${namespace}.questionFileIssuePlaceholder`,
    message:
      'Write the reason this file is not valid. Explain that the user must change this specific picture and replace with a different one. This applies to only this file being viewed.',
  },
  REASONS: {
    key: `${namespace}.reasons`,
    message: 'Reasons',
  },
  OPEN_QUESTION_CORRECTION_TICKET: {
    key: `${namespace}.openQuestionCorrectionTicket`,
    message: 'Open a question answer correction ticket',
  },
  OPEN_QUESTION_FILE_CORRECTION_TICKET: {
    key: `${namespace}.openQuestionFileCorrectionTicket`,
    message: 'Open a question file correction ticket',
  },
  NOT_ENOUGH_FILES: {
    key: `${namespace}.notEnoughFilesUploaded`,
    message:
      'Not enough files uploaded. Please check the instructions above and upload all the necessary files.',
  },
  HAS_REJECTED_FILES: {
    key: `${namespace}.hasRejectedFiles`,
    message:
      'You have rejected files. Please replace the files marked with error icon. You can see the reasoning for rejection with a mouse over each file.',
  },
  PENDING_CORRECTIONS_SELECTED: {
    key: `${namespace}.correctionsSelected`,
    message: '{count} pending corrections',
  },
  APPROVALS_SELECTED: {
    key: `${namespace}.approvalsSelected`,
    message: '{count} approved',
  },
  PARTICIPATION: {
    key: `${namespace}.participation`,
    message: 'participation',
  },
};

const POS_DOCU = {
  TITLE: {
    key: `${namespace}.posDocuTitle`,
    message: 'Retail wall and product presentations',
  },
  DESCRIPTION: {
    key: `${namespace}.posDocuDescription`,
    message: 'Upload your retail walls here to showcase your Point of Sale',
  },
};

const I18N = {
  AUDIT,
  POS_DOCU,
};

export default I18N;
