import { Token } from 'typedi';

export const AUTHENTICATION_EMAIL_APPLICATION_BASE_URL = new Token<string>(
  'AUTHENTICATION_EMAIL_APPLICATION_BASE_URL',
);

export const AUTHENTICATION_EMAIL_APPLICATION_PATH_TOKEN = new Token<string>(
  'AUTHENTICATION_EMAIL_APPLICATION_PATH_TOKEN',
);

export const SET_USER_APPLICATION_CALLBACK_URL_TOKEN = new Token<string>(
  'SET_USER_APPLICATION_CALLBACK_URL_TOKEN',
);

export const SET_ADMIN_APPLICATION_CALLBACK_URL_TOKEN = new Token<string>(
  'SET_USER_APPLICATION_CALLBACK_URL_TOKEN',
);

export const AUTHENTICATION_EMAIL_REGULAR_USER_APPLICATION_BASE_URL =
  new Token<string>('AUTHENTICATION_EMAIL_REGULAR_USER_APPLICATION_BASE_URL');

export const AUTHENTICATION_EMAIL_REGULAR_USER_APPLICATION_CONTEXT_PATH =
  new Token<string>(
    'AUTHENTICATION_EMAIL_REGULAR_USER_APPLICATION_CONTEXT_PATH',
  );

export const SET_USER_APPLICATION_ID = new Token<string>(
  'SET_USER_APPLICATION_ID',
);

export const SET_ADMIN_APPLICATION_ID = new Token<string>(
  'SET_ADMIN_APPLICATION_ID',
);
