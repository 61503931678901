import { css } from '@ui-system/css';
import {
  BUTTON_ICON_UUID,
  BUTTON_TEXT_UUID,
  BUTTON_UUID,
  ButtonProps,
  IconProps,
} from '@ui-system/interfaces';
import { DEVICES } from '@ui-system/media-query/device';
import { makeStyle } from '@ui-system/style';
import { U } from 'ts-toolbelt';

import { makeFinalStyleHook } from './internal';

const useButtonDefaultStyle = makeStyle<{ disabled: boolean }>(
  ({ theme, props }) => css`
    border-radius: 0;
    min-height: 45px;
    color: ${theme.colors[theme.mode].white};
    ${props?.disabled
      ? `opacity: 0.9;
    background-color: ${theme.colors[theme.mode].gray.A300};
    border-width: 0;
    border-color: transparent;
    elevation: 0;
    `
      : ''}
    ${DEVICES.iPad ? 'min-height: 20px' : ''};
  `,
);

export const useButtonStyle = makeFinalStyleHook<Partial<ButtonProps>>(
  BUTTON_UUID,
  useButtonDefaultStyle,
);

const useButtonTextDefaultStyle = makeStyle<{ disabled?: U.Nullable<boolean> }>(
  ({ theme, props }) => css`
    color: ${props?.disabled
      ? theme.colors[theme.mode].white
      : theme.colors[theme.mode].black};
  `,
);
export const useButtonTextStyle = makeFinalStyleHook(
  BUTTON_TEXT_UUID,
  useButtonTextDefaultStyle,
);

const useButtonIconDefaultStyle = makeStyle<Partial<IconProps>>(
  ({ theme }) => css`
    color: ${theme.colors[theme.mode].secondary.main};
  `,
);

export const useButtonIconStyle = makeFinalStyleHook<Partial<IconProps>>(
  BUTTON_ICON_UUID,
  useButtonIconDefaultStyle,
);
