import { enableBatching } from 'redux-batched-actions';
import { IModule } from 'redux-dynamic-modules';

import changeLanguageEpic from './changeLanguageEpic';
import onLocalSalesOrgBrandChangedEpic from './onLocalSalesOrgBrandChangedEpic';
import i18nReducer from './reducer';
import { NAMESPACE as i18nNamespace, RootState } from './stateSelector';

export const reducer = i18nReducer;
export const NAMESPACE = i18nNamespace;
export const epics = [changeLanguageEpic, onLocalSalesOrgBrandChangedEpic];

type Epics = typeof epics;

export function getI18nModule(
  customEpics?: Partial<Epics>[],
): IModule<RootState> & { epics: Epics } {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    // @ts-ignore
    epics: customEpics || epics,
    initialActions: [], // no need due to listening epics causing the language start epic
  };
}
