import { TraderJSON } from '@mmw/services-core-store-locator/types';
import { CircleF, useGoogleMap } from '@react-google-maps/api';
import { Coordinates } from '@store-locator/store-creator/types';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import { useColors } from '@ui-system/theme/colors';
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo } from 'react';
import { F, U } from 'ts-toolbelt';

import { calculateZoom } from '../utils';
import TraderMarkers from './TraderMarkers';

const INITIAL_MARKER_PIXEL = -280;
const MOBILE_INITIAL_MARK_PIXEL = 0;

interface Props {
  origin: U.Nullable<Coordinates>;
  radius: U.Nullable<number>;
  storesList: U.Nullable<TraderJSON[]>;
  selectedStore: U.Nullable<TraderJSON>;
  setZoom: F.Function<[number]>;
}

const MapInterface: React.FC<Props> = ({
  origin,
  radius,
  storesList,
  selectedStore,
  setZoom,
}: Props) => {
  const gmap = useGoogleMap();
  const colors = useColors();
  const isMobile = useIsMediaQueryUpToMD();
  const panMap = useCallback(
    (lat: U.Nullable<number>, lng: U.Nullable<number>) => {
      if (!lat || !lng) return;
      gmap?.panTo({
        lat,
        lng,
      });
    },
    [gmap],
  );

  const circleOptions = useMemo(
    () => ({
      strokeColor: colors.error as unknown as string,
      strokeWeight: 2,
      fillOpacity: 0,
    }),
    [colors.error],
  );

  useEffect(() => {
    if (origin?.lat && origin?.lng) {
      const { lat, lng } = origin;
      panMap(lat, lng);
    }
  }, [origin?.lat]);

  useEffect(() => {
    if (selectedStore) {
      const {
        address: { lat, lng },
      } = selectedStore;
      panMap(lat, lng);
    }

    if (!selectedStore && origin?.lat) {
      const { lat, lng } = origin;
      panMap(lat, lng);
    }
  }, [selectedStore]);

  useEffect(() => {
    if (radius) {
      setZoom(calculateZoom(radius, isMobile));
      delay(() => {
        gmap?.panBy(
          isMobile ? MOBILE_INITIAL_MARK_PIXEL : INITIAL_MARKER_PIXEL,
          0,
        );
      }, 300);
    }
  }, [radius]);

  return (
    <>
      <TraderMarkers selectedStore={selectedStore} storesList={storesList} />
      {origin && radius && !isEmpty(storesList) ? (
        <CircleF
          center={origin}
          radius={radius * 1000}
          options={circleOptions}
        />
      ) : null}
    </>
  );
};

export default MapInterface;
