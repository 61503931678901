import {
  forEach,
  indexOf,
  isArray,
  isEmpty,
  memoize,
  replace,
  startsWith,
  toString,
  trim,
} from 'lodash';
import qs from 'qs';
import { IntlShape } from 'react-intl';
import { U } from 'ts-toolbelt';

import logger from './log';

const NO_RESOURCE_ID = 'NO_RESOURCE_ID_FOUND';
export const bundleVersion = '_bundleVersion';
const DOT_SEPARATOR = '.';

type Mode = 'DEFAULT' | 'NO_DEFAULT_RESOURCE';

export const Modes = {
  DEFAULT: 'DEFAULT',
  NO_DEFAULT_RESOURCE: 'NO_DEFAULT_RESOURCE',
};

const CONFIG = {
  mode: Modes.DEFAULT,
};

export const changeMode = (mode: Mode): void => {
  CONFIG.mode = mode;
};

export const validateUrlProtocol: (arg0: string) => string = url =>
  url.indexOf('://') === -1 ? `http://${url}` : url;

export const internalFindMessage = (
  messageId: string | void,
  defaultMessage: string,
  intl: IntlShape,
  values?: Record<string, unknown> | null,
  fullMessageId?: string,
): string => {
  if (!messageId || isEmpty(messageId)) {
    return toString(defaultMessage);
  }
  if (intl.messages[`${messageId}`]) {
    return intl.formatMessage(
      {
        id: messageId,
        defaultMessage: intl.messages[`${messageId}`],
      },
      values as Record<string, string>,
    );
  }
  const separatorIndex = indexOf(messageId, DOT_SEPARATOR);

  if (separatorIndex !== -1) {
    const slicedMessageId = messageId.slice(separatorIndex + 1);
    return internalFindMessage(
      slicedMessageId,
      defaultMessage,
      intl,
      values,
      fullMessageId || messageId,
    );
  }
  logger.warn(`No resource found for ${messageId}`, {
    messageId: fullMessageId || messageId,
    defaultMessage,
  });
  if (CONFIG.mode === Modes.NO_DEFAULT_RESOURCE) {
    return toString(messageId);
  }
  return intl.formatMessage(
    {
      id: NO_RESOURCE_ID,
      defaultMessage,
    },
    values as Record<string, string>,
  );
};

export const findMessage = memoize(
  internalFindMessage,
  (
    messageId: string | void,
    defaultMessage: string,
    intl: IntlShape,
    values?: Record<string, unknown> | null,
  ) => {
    const { _bundleVersion } = intl.messages;
    // TODO: XXX there may be a bug in the toString values, it will cache if value change
    return `internalFindMessage
      ${_bundleVersion}
      ${intl.locale}
      ${messageId}
      ${defaultMessage}
      ${qs.stringify(values)}
      `;
  },
);

// THIS IS NEEDED BECAUSE TEXT TRANSFORM WASNT WORKING ON ANDROID DEVICES
type TextTransformOptions = {
  uppercase?: boolean;
  lowercase?: boolean;
  capitalize?: boolean;
  maxLength?: number;
  prefix?: any;
  suffix?: any;
};

export const textTransform = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  content: any,
  options: TextTransformOptions = {},
): string => {
  const { uppercase, lowercase, capitalize, maxLength, prefix, suffix } =
    options;
  if (isEmpty(content)) return null as unknown as string; // I DONT MESS WITH THIS CENTRAL LOGIC
  if (uppercase) {
    return `${prefix || ''}${toString(content).toUpperCase()}${suffix || ''}`;
  }
  if (lowercase) {
    return `${prefix || ''}${toString(content).toLowerCase()}${suffix || ''}`;
  }
  if (capitalize) {
    return `${prefix || ''}${toString(content)
      .charAt(0)
      .toUpperCase()}${toString(content).slice(1)}${suffix || ''}`;
  }
  if (content && maxLength && content.length > maxLength) {
    return `${toString(content).substring(0, maxLength)}...`;
  }
  return `${prefix || ''}${content}${suffix || ''}`;
};

export function removeInitialDigits(
  originalValue: string,
  toRemoveDigits: string | string[],
): string {
  let newValue = trim(toString(originalValue));
  if (isArray(toRemoveDigits)) {
    forEach(toRemoveDigits, v => {
      if (startsWith(newValue, v)) {
        newValue = replace(newValue, v, '');
      }
    });
  } else if (startsWith(newValue, toRemoveDigits)) {
    newValue = replace(newValue, toRemoveDigits, '');
  }
  return newValue;
}

export function replaceInitialsOnlyOnce(
  originalValue: U.Nullable<string>,
  initials: U.Nullable<string[]>,
): string {
  let newValue = trim(toString(originalValue));
  forEach(initials, value => {
    if (startsWith(<string>originalValue, value)) {
      newValue = replace(<string>originalValue, value, '');
    }
  });
  return newValue;
}
