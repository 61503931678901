import {
  filter,
  find,
  flatMap,
  includes,
  intersection,
  isEmpty,
  map,
  size,
} from 'lodash';

type BaseCategory = {
  id: string;
  name: string;
};

export type Category = {
  subCategories: Array<BaseCategory>;
} & BaseCategory;

export const CATEGORY_TRADER_ID = 'TRADER';
export const CATEGORY_GASTRONOMY_ID = 'GASTRONOMY';
export const CATEGORY_BEAUTY_ID = 'BEAUTY_AND_WELLNESS';

const CATEGORIES: Category = [
  {
    id: CATEGORY_TRADER_ID,
    name: 'Handel',
    subCategories: [
      { name: 'Apotheken und Drogerien', id: 'PHARMACY' },
      { name: 'Buchhandel', id: 'BOOKS' },
      { name: 'Elektro und Technik', id: 'ELECTRO' },
      { name: 'Friseure und Schönheit', id: 'BEAUTYANDHAIR' },
      { name: 'Gesundheit', id: 'HEALTH' },
      { name: 'Lebensmittel', id: 'FOOD' },
      { name: 'Mode und Accessoires', id: 'FASHIONANDACESSOIRE' },
      { name: 'Optik', id: 'LENSES' },
      { name: 'Kaufhäuser/Shopping-Center', id: 'SHOPPINGCENTER' },
      { name: 'Lederwaren', id: 'LEATHER' },
      { name: 'Reisebüro', id: 'TRAVEL' },
      { name: 'Schuhe', id: 'SHOES' },
      { name: 'Uhren & Schmuck', id: 'WATCHANDJEWELLERY' },
      { name: 'Wohnen und Einrichten', id: 'HOUSING' },
    ],
  },
  {
    id: CATEGORY_GASTRONOMY_ID,
    name: 'Gastronomie',
    subCategories: [
      { name: 'Biergarten', id: 'BEERGARDEN' },
      { name: 'Brauerei / Braustube', id: 'BREWERY' },
      { name: 'Café / Konditorei', id: 'COFFEESHOP' },
      { name: 'Diskothek / Club / Tanzlokal', id: 'DANCEHALL' },
      { name: 'Eisdiele / Eiscafe', id: 'ICECREAMPARLOUR' },
      { name: 'Erlebnisgastronomie', id: 'ADVENTURECATERING' },
      { name: 'Gasthaus / Gasthof / Gaststätte', id: 'GUESTHOUSE' },
      { name: 'Gourmet-Restaurant', id: 'GOURMETRESTAURANT' },
      { name: 'Grill-Restaurant', id: 'GRILLRESTAURANT' },
      { name: 'Hofladen / Direktvermarkter', id: 'DIRECTMARKETING' },
      { name: 'Hütte', id: 'LODGE' },
      { name: 'Pub / Bistro / Bar', id: 'BAR' },
      { name: 'Pizzeria / Trattoria / Osteria', id: 'PIZZERIA' },
      { name: 'Restaurant', id: 'RESTAURANT' },
      { name: 'Schnellimbiss / Systemgastronomie', id: 'FASTFOOD' },
      { name: 'Strauße / Besenwirtschaft', id: 'BESEN' },
      { name: 'Streetfood', id: 'STREETFOOD' },
      { name: 'Weinlokal', id: 'WINEPUB' },
      { name: 'Übernachtungsmöglichkeit', id: 'ACCOMODATION' },
      { name: 'Ausflugslokal', id: 'RESTAURANTDESTINATION' },
    ],
  },
  {
    id: CATEGORY_BEAUTY_ID,
    name: 'Beauty & Wellness',
    subCategories: [
      { name: 'Frisör', id: 'HAIRDRESSER' },
      { name: 'Spa', id: 'SPA' },
    ],
  },
];

export default CATEGORIES;

const CATEGORY_AND_SUB_CATEGORIES = [
  ...CATEGORIES,
  ...flatMap(CATEGORIES, 'subCategories'),
];

export function hasSubCategories(ids: Array<string>) {
  if (isEmpty(ids)) {
    return false;
  }
  const mainCategoryIds = map(CATEGORIES, 'id');
  const matches = intersection(mainCategoryIds, ids);
  return size(ids) !== size(matches);
}

export function removeMainCategories(ids: Array<string>) {
  const mainCategoryIds = map(CATEGORIES, 'id');
  return filter(ids, id => !includes(mainCategoryIds, id));
}

export function getCategoriesNames(ids: Array<string>) {
  return filter(
    map(ids, id => {
      const found = find(CATEGORY_AND_SUB_CATEGORIES, s => s.id === id);
      return found != null ? found.name : null;
    }),
    Boolean,
  );
}
