import { OnErrorFn } from '@formatjs/intl';
import ResourceService from '@mmw/services-core-resource';
import { ResourceBundle } from '@mmw/services-core-resource/types';
import autoBindReact from 'auto-bind/react';
import * as React from 'react';
import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { F, U } from 'ts-toolbelt';

import logger from './log';

export type I18nProviderProps = {
  bundleName: string;
  secondaryBundleName?: U.Nullable<string>;
  children: React.ReactNode;
  renderOnlyIfLoaded?: boolean;
  resourceService?: U.Nullable<ResourceService>;
  defaultLanguage: string;
  resources?: U.Nullable<ResourceBundle>;
  textComponent?: React.FC;
};

type State = {
  resources?: ResourceBundle;
  loaded: boolean;
};

class I18nProvider extends React.Component<I18nProviderProps, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    renderOnlyIfLoaded: false,
    textComponent: null,
    resourceService: null,
    resources: null,
    secondaryBundleName: null,
  };

  constructor(props: I18nProviderProps) {
    super(props);
    this.state = {
      resources: {},
      loaded: false,
    };
    autoBindReact(this);
  }

  async componentDidMount(): Promise<void> {
    return this.loadResources();
  }

  static onMessageNotFound(error: F.Parameters<OnErrorFn>[0]): void {
    logger.warn(error.message);
  }

  getResources(): ResourceBundle | null | undefined {
    const { resources: givenResources, resourceService } = this.props;
    const { resources, loaded } = this.state;
    if (!resourceService) {
      return givenResources;
    }
    if (loaded) {
      return resources;
    }
    return givenResources;
  }

  isLoaded(): boolean {
    const { resources: givenResources } = this.props;
    const { loaded } = this.state;
    return givenResources != null || loaded;
  }

  async loadResources(): Promise<void> {
    const {
      bundleName,
      secondaryBundleName,
      resourceService,
      defaultLanguage,
    } = this.props;
    if (!resourceService) {
      return; // XXX: allow control to be on the outside
    }
    try {
      let resources = await resourceService.getResourceBundle({
        bundleName,
        language: defaultLanguage,
      });
      if (secondaryBundleName) {
        const secondaryResources = await resourceService.getResourceBundle({
          bundleName,
          language: defaultLanguage,
        });
        resources = {
          ...secondaryResources,
          ...resources,
        };
      }
      this.setState({ resources, loaded: true });
    } catch (error) {
      logger.error('Error while loading the resource bundle, error=%O', error);
    }
  }

  render(): ReactNode {
    const {
      children,
      renderOnlyIfLoaded,
      textComponent,
      defaultLanguage: language,
    } = this.props;
    const isLoaded = this.isLoaded();
    if (!isLoaded && renderOnlyIfLoaded) {
      return null;
    }
    const resources = this.getResources();
    return (
      <IntlProvider
        locale={language}
        messages={resources || {}}
        onError={I18nProvider.onMessageNotFound}
        textComponent={textComponent || 'span'}
      >
        {children}
      </IntlProvider>
    );
  }
}

export default I18nProvider;
