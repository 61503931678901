const namespace = 'project';

const PROJECT = {
  ID: {
    key: `${namespace}.id`,
    message: 'Project ID',
  },
  SYSTEM_LOCATION: {
    key: `${namespace}.systemLocation`,
    message: 'System location',
  },
  SYSTEM_OWNER: {
    key: `${namespace}.systemOwner`,
    message: 'System owner',
  },
  WARRANTY: {
    key: `${namespace}.warranty`,
    message: 'Warranty',
  },
  ACTIONS: {
    DOWNLOAD_CERTIFICATES: {
      key: `${namespace}.actions.downloadCertificates`,
      message: 'Download Certificates',
    },
    DOWNLOAD_FLASH_DATA_REPORTS: {
      key: `${namespace}.actions.downloadFlashData`,
      message: 'Download Flasher Data',
    },
    REVALIDATE: {
      key: `${namespace}.actions.revalidate`,
      message: 'Revalidate',
    },
    REVERSE_REGISTRATIONS: {
      key: `${namespace}.actions.reverseRegistrations`,
      message: 'Reverse',
    },
    NEW_REGISTRATION: {
      key: `${namespace}.actions.newProject`,
      message: 'Creating new project for partner {name} ({id})',
    },
  },
  MODAL: {
    key: `${namespace}.confirmationModal`,
    message: 'for the following projects',
  },
  INSTALLATION_DATE: {
    TITLE: {
      key: `${namespace}.installationDate`,
      message: 'Installation date',
    },
    DESCRIPTION: {
      key: `${namespace}.installationDateDescription`,
      message:
        'The date selected by the installer using the datepicker on the registration process',
    },
    FROM: {
      key: `${namespace}.fromInstallationDate`,
      message: 'From installation date',
    },
    TO: {
      key: `${namespace}.toInstallationDate`,
      message: 'To installation date',
    },
  },
  REGISTRATION_DATE: {
    TITLE: {
      key: `${namespace}.registrationDate`,
      message: 'Registration date',
    },
    DESCRIPTION: {
      key: `${namespace}.registrationDateDescription`,
      message: 'The date when the project was created.',
    },
    FROM: {
      key: `${namespace}.fromRegistrationDate`,
      message: 'From registration date',
    },
    TO: {
      key: `${namespace}.toRegistrationDate`,
      message: 'To registration date',
    },
  },

  SALESORGBRAND: {
    key: `${namespace}.salesOrgBrand`,
    message: 'Brand',
  },
  INSTALLER: {
    key: `${namespace}.installer`,
    message: 'Installer',
  },
  STATUS: {
    key: `${namespace}.status`,
    message: 'Status',
  },
  SYSTEM_SIZE: {
    key: `${namespace}.systemSize`,
    message: 'System Size',
  },
  CAMPAIGNS: {
    key: `${namespace}.campaigns`,
    message: 'Campaigns',
  },
  PANELS: {
    LABEL: {
      key: `${namespace}.panel`,
      message: '{count, plural, one {# Panel} other {# Panels}}',
    },
    SERIALNUMBER: {
      key: `${namespace}.panel.serialnumber`,
      message: 'Serial No',
    },
  },
  CONSUMER: {
    key: `${namespace}.consumer`,
    message: 'Consumer',
    COUNTRY: {
      key: `${namespace}.consumer.country`,
      message: 'Country',
    },
    LASTNAME: {
      key: `${namespace}.consumer.lastname`,
      message: 'Last Name',
    },
  },
};

export default PROJECT;
