// XXX= copied from http=//poseidon/mmw-srv/mmw/blob/master/mmw-constants/src/main/java/de/kybeidos/mmw/constants/BrandCodeEnum.java

export enum Brand {
  AEG = 13,
  BAUKNECHT = 15,
  BEKO = 69,
  BEURER = 24,
  BOSCH = 19,
  BRAUN = 23,
  BROTHER = 52,
  CANON = 55,
  CONGSTAR = 41,
  DELONGHI = 12,
  DENON = 8,
  DYSON = 21,
  FESTOOL = 54,
  FISSLER = 62,
  GIGASET = 48,
  GOOGLE = 47,
  GORENJE = 46,
  GROHE = 61,
  HAIER = 35,
  HISENSE = 36,
  HOOVER = 63,
  INNOTECHSOLAR = 26,
  JBL = 38,
  JURA = 29,
  KAERCHER = 39,
  KENWOOD = 40,
  KRUPS = 2,
  KWS = 51,
  LG = 33,
  LIEBHERR = 53,
  LOEWE = 16,
  MEGABRAND = 34,
  MELITTA = 22,
  MIELE = 50,
  MOTOROLA = 43,
  MVV = 11,
  NEFF = 65,
  NIKON = 32,
  NOOP = -1,
  O2 = 42,
  OPPO = 58,
  OTHER = 150,
  ROMMELSBACHER = 71,
  PANASONIC = 14,
  PHILIPS = 6,
  PIONEER = 7,
  REC = 28,
  RGI = 100,
  ROWENTA = 1,
  SAECO = 9,
  SAGE = 64,
  SAMSUNG = 18,
  SCHENKER = 44,
  SEVERIN = 27,
  SHARP = 4,
  SHARPSOLAR = 5,
  SIEMENS = 17,
  SONY = 20,
  SONYEXP = 10,
  STADTWERKEHD = 25,
  STIHL = 57,
  TEFAL = 3,
  TEST = 30,
  TOSHIBA = 31,
  VARTA = 49,
  YAMAHA = 45,
  ZEISS = 56,
}

export const { REC } = Brand;
