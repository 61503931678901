import { Checkbox } from '@ui-system/material-form-checkbox';
import { CheckboxGroup } from '@ui-system/material-form-checkbox-group';
import { DatePicker } from '@ui-system/material-form-date-picker';
import { Input } from '@ui-system/material-form-input';
import { MultipleSelect } from '@ui-system/material-form-multiple-select';
import { RadioGroup } from '@ui-system/material-form-radio-group';
import { Select } from '@ui-system/material-form-select';
import { Switch } from '@ui-system/material-form-switch';
import { TimePicker } from '@ui-system/material-form-time-picker';
import { createInstance } from '@ui-system/proxy/createInstance';
import UI from '@ui-system/ui';

const Form = createInstance(UI.Container, {
  Input,
  Select,
  Checkbox,
  DatePicker,
  TimePicker,
  RadioGroup,
  Switch,
  CheckboxGroup,
  MultipleSelect,
});

export default Form;
