import { filter, find, map } from 'lodash';

export type Service = {
  id: string;
  name: string;
};

const SERVICES: Array<Service> = [
  { name: 'Telefonische Beratung', id: 'PHONEADVICE' },
  { name: 'Verkauf von Lebensmitteln', id: 'FOODSALE' },
  { name: 'Online Beratung und Bestellung', id: 'ONLINEADVICE' },
  { name: 'Abholservice', id: 'PICKUPSERVICE' },
  { name: 'Lieferservice', id: 'DELIVERYSERVICE' },
  { name: 'Kauf von Gutscheinen', id: 'VOUCHERSALE' },
];

export default SERVICES;

export function getServicesNames(ids: Array<string>) {
  return filter(
    map(ids, id => {
      const found = find(SERVICES, s => s.id === id);
      return found != null ? found.name : null;
    }),
    Boolean,
  );
}
