const namespace = 'enrollment';

export const SOLAR_PROFESSIONAL_CERTIFICATE = {
  CERTIFICATE: {
    key: `${namespace}.certificate`,
    message: 'REC Certificate',
  },
  CONFIRM_DATA: {
    key: `${namespace}.confirmData`,
    message:
      'I confirm that the data about my company is correct and I want to generate my REC Certificate',
  },
  POPUP_HEADER: {
    key: `${namespace}.header`,
    message: 'YOUR RCSP CERTIFICATE',
  },
  POPUP_TITLE: {
    key: `${namespace}.title`,
    message: 'This is your REC RCSP Certificate!',
  },
  POPUP_TEXT: {
    key: `${namespace}.text`,
    message: 'You can always find it on the My Company tab',
  },
};

const I18N = { SOLAR_PROFESSIONAL_CERTIFICATE };

export default I18N;
