import { createSelector } from '@reduxjs/toolkit';
import { chain, get, isEmpty, keys, startsWith } from 'lodash';

import { FieldPath, FormId } from '../types';
import { FormState, NAMESPACE, RootState } from './types';
import { normalizeFieldPath } from './utils';

export const allFormsSelector = createSelector(
  (state: RootState) => state[NAMESPACE].forms,
  forms => forms,
);

export const getFormSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): FormState<any> | null =>
      allFormsSelector(state)[formId],
    formById => formById,
  );

export const getFormCurrentValuesSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'currentValues'),
  );

export const getFormInitialValuesSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'initialValues'),
  );

export const getFormWatchedFieldsSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'watchedFieldPaths'),
  );

export const getFormSubmittedValuesSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'submittedValues'),
  );

export const getFormIsSuccessfullySubmittedSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'flags.isSuccessfullySubmitted'),
  );

export const getFormIsUnsuccessfullySubmittedSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'flags.isUnsuccessfullySubmitted'),
  );

export const getFormIsSuccessfullyValidatedSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'flags.isSuccessfullyValidated'),
  );

export const getIsFormValidatingSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'flags.isValidating'),
  );

export const getIsFormValidSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'flags.isValid'),
  );

export const getIsFormManuallyValidatedSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'flags.isManuallyValidated'),
  );

export const getFormSubmitCountSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'flags.submitCount'),
  );

export const getFormTouchedFieldsSelector = (formId: FormId) =>
  createSelector(
    (state: RootState): any => getFormSelector(formId)(state),
    form => get(form, 'touchedFieldPaths'),
  );

export const getFormCurrentErrorsSelector = (formId: FormId) =>
  createSelector(
    (state: RootState) => getFormSelector(formId)(state),
    form => get(form, 'currentErrors'),
  );

export const getFieldValueByFieldPathSelector = (
  formId: FormId,
  fieldPath: FieldPath,
  defaultValue: any,
) =>
  createSelector(
    (state: RootState): any => getFormCurrentValuesSelector(formId)(state),
    currentValues => get(currentValues, fieldPath, defaultValue),
    // (formId, fieldPath) => `field-value-${formId}-${fieldPath}`,
  );

export const getIsFieldTouchedSelector = (
  formId: FormId,
  fieldPath: FieldPath,
) =>
  createSelector(
    (state: RootState): any => getFormTouchedFieldsSelector(formId)(state),
    touchedFields => {
      // const touchedFields = ;
      if (!touchedFields) {
        return false;
      }
      if (touchedFields[fieldPath]) {
        return touchedFields[fieldPath];
      }
      const normalizedFieldPath = normalizeFieldPath(fieldPath);
      return touchedFields[normalizedFieldPath] || false;
    },
    // (formId, fieldPath) => `field-touched-${formId}-${fieldPath}`,
  );

export const getIsFieldTouchedOrFormSubmittedSelector = (
  formId: FormId,
  fieldPath: FieldPath,
) =>
  createSelector(
    (state: RootState): any => ({
      isTouched: getIsFieldTouchedSelector(formId, fieldPath)(state),
      submitCount: getFormSubmitCountSelector(formId)(state),
    }),
    ({ isTouched, submitCount }) => isTouched || submitCount > 0,
    // (formId, fieldPath) => `field-touched-or-submitted-${formId}-${fieldPath}`,
  );

export const getIsFieldTouchedAndFormSubmittedSelector = (
  formId: FormId,
  fieldPath: FieldPath,
) =>
  createSelector(
    (state: RootState): any => ({
      isTouched: getIsFieldTouchedSelector(formId, fieldPath)(state),
      submitCount: getFormSubmitCountSelector(formId)(state),
    }),
    ({ isTouched, submitCount }) => isTouched && submitCount > 0,
    // (formId, fieldPath) => `field-touched-and-submitted-${formId}-${fieldPath}`,
  );

export const getFieldErrorsByFieldPathSelector = (
  formId: FormId,
  fieldPath: FieldPath,
  defaultValue: any,
) =>
  createSelector(
    (state: RootState): any => getFormCurrentErrorsSelector(formId)(state),
    errors => {
      if (!errors) {
        return defaultValue;
      }
      if (errors[fieldPath]) {
        return errors[fieldPath] || defaultValue;
      }
      const normalizedFieldPath = normalizeFieldPath(fieldPath);
      return errors[normalizedFieldPath] || defaultValue;
    },
    // (formId, fieldPath) => `field-errors-${formId}-${fieldPath}`,
  );

export const getNestedFieldErrorsByFieldPathSelector = (
  formId: FormId,
  fieldPath: FieldPath,
  defaultValue: any,
) =>
  createSelector(
    (state: RootState): any => getFormCurrentErrorsSelector(formId)(state),
    currentErrors => {
      if (!currentErrors || isEmpty(currentErrors)) {
        return defaultValue;
      }
      const normalizedFieldPath = normalizeFieldPath(fieldPath);
      const allErrorFieldPaths = keys(currentErrors);
      return chain(allErrorFieldPaths)
        .filter(
          errorFieldPath =>
            errorFieldPath !== (normalizeFieldPath as unknown as string),
        )
        .filter(errorFieldPath =>
          startsWith(errorFieldPath, normalizedFieldPath),
        )
        .map(errorFieldPath => currentErrors[errorFieldPath])
        .value();
    },
    // (formId, fieldPath) => `field-nested-errors-${formId}-${fieldPath}`,
  );
