const namespace = 'questionGroup';
const validationNamespace = 'validation';

const QUESTION_GROUP = {
  ANSWER_REQUIRED: {
    key: `${namespace}.${validationNamespace}.requiredAnswer`,
    message: 'Required answer',
  },
};

export default QUESTION_GROUP;
