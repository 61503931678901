import 'tippy.js/dist/tippy.css'; // optional

import Tippy, { TippyProps } from '@tippyjs/react';
import UI from '@ui-system/ui';
import React from 'react';

import { TooltipProps, TooltipType } from './types';

function getOverlay(i18n: TooltipProps['i18n']) {
  return (
    <UI.Container>
      <UI.Caption modifiers="white" i18n={i18n} />
    </UI.Container>
  );
}

const STYLE = {
  display: 'flex',
};

export const Tooltip: TooltipType<TippyProps> = ({
  children,
  i18n,
  overlay,
  placement,
  tooltipProps,
  ...rest
}: TooltipProps<TippyProps>) => (
  <Tippy
    content={overlay || getOverlay(i18n)}
    placement={placement}
    {...tooltipProps}
  >
    <div style={STYLE}>
      <UI.Container {...rest}>{children}</UI.Container>
    </div>
  </Tippy>
);
