import { I18nShape } from '@mmw/constants-i18n';
import { ERROR, SUCCESS } from '@mmw/constants-i18n-common';
import noop from 'lodash/noop';
import { useMemo } from 'react';
import { F, U } from 'ts-toolbelt';

import { useToastNotification } from '../hooks';
import { NotificationType, ToastConfigProps } from '../types';

export interface NotifyListenerProps {
  successI18n?: U.Nullable<I18nShape | string>;
  errorI18n?: U.Nullable<I18nShape | string>;
  warningI18n?: U.Nullable<I18nShape | string>;
  success?: boolean;
  error?: U.Nullable<Error | string>;
  warning?: boolean;
  customConfigs?: ToastConfigProps;
  onDestroy?: F.Function;
}

const NotifyListener: React.FC<NotifyListenerProps> = ({
  successI18n,
  warningI18n,
  errorI18n,
  error,
  success,
  warning,
  onDestroy,
  customConfigs,
}: NotifyListenerProps): null => {
  const errorMessage = useMemo(() => {
    if (errorI18n) {
      return errorI18n;
    }
    return {
      key: String(error),
      message: String(error),
    };
  }, [error, errorI18n]);
  const config = useMemo(() => {
    if (success) {
      return {
        i18n: successI18n,
        options: {
          type: NotificationType.success,
          onDestroy,
          ...customConfigs,
        },
      };
    }
    if (warning && warningI18n) {
      return {
        i18n: warningI18n,
        options: {
          type: NotificationType.warning,
          onDestroy,
          ...customConfigs,
        },
      };
    }
    if (error) {
      return {
        i18n: errorMessage,
        options: {
          type: NotificationType.error,
          onDestroy,
          ...customConfigs,
        },
      };
    }
    return {
      i18n: null,
    };
  }, [
    success,
    warning,
    warningI18n,
    error,
    successI18n,
    onDestroy,
    customConfigs,
    errorMessage,
  ]);

  const { i18n, options } = config;

  useToastNotification(i18n, warning || success || !!error, options);
  return null;
};

NotifyListener.defaultProps = {
  successI18n: ERROR,
  errorI18n: SUCCESS,
  onDestroy: noop,
};

export default NotifyListener;
