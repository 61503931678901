import React, { useEffect } from 'react';

import { useLocation } from './hooks';

const ScrollToTopWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
};

export const ScrollToTop = ScrollToTopWrapper;

export default ScrollToTopWrapper;
