import { assign, memoize, reduce } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { F, U } from 'ts-toolbelt';

import { DEFAULT_LEVEL, LEVELS } from './constants';
import { Log } from './types';

const ALL = '*';
type NameSpaces = U.Nullable<string | string[]>;
type EnabledGroups = Array<string | RegExp>;
type SkippedGroups = Array<RegExp>;

let DISABLED = false;
let ENABLED_GROUPS: EnabledGroups = [ALL];
let SKIPPED_GROUPS: SkippedGroups = [];
let CURRENT_NAMESPACES: NameSpaces = null;

export function getNamespaces(): NameSpaces {
  return CURRENT_NAMESPACES;
}

export function enable(namespaces: string | Array<string>): void {
  CURRENT_NAMESPACES = namespaces;
  DISABLED = false;
  if (!namespaces || !namespaces.length) {
    return;
  }
  ENABLED_GROUPS = [];
  SKIPPED_GROUPS = [];

  const split = (typeof namespaces === 'string' ? namespaces : '').split(
    /[\s,]+/,
  );
  const len = split.length;

  for (let i = 0; i < len; i += 1) {
    if (split[i]) {
      const names = split[i].replace(/\*/g, '.*?');
      if (names[0] === '-') {
        SKIPPED_GROUPS.push(new RegExp(`^${names.substr(1)}$`));
      } else {
        ENABLED_GROUPS.push(new RegExp(`^${names}$`));
      }
    }
  }
}

export function disable(): void {
  DISABLED = true;
}

function isGroupEnabled(group: string, expr: RegExp) {
  return expr.test(group);
}

function isGroupDisabled(group: string, expr: RegExp) {
  return expr.test(group);
}

function canLogWithParams(
  group: string,
  enabledGroups: EnabledGroups,
  skippedGroups: SkippedGroups,
) {
  if (!group) {
    return true;
  }
  if (!enabledGroups.length) {
    return false;
  }
  if (enabledGroups.length === 1 && enabledGroups[0] === ALL) {
    return true;
  }
  const skippedLen = skippedGroups.length;
  for (let i = 0; i < skippedLen; i += 1) {
    if (isGroupDisabled(group, skippedGroups[i])) {
      return false;
    }
  }
  const enabledLen = enabledGroups.length;
  for (let i = 0; i < enabledLen; i += 1) {
    if (
      enabledGroups[i] instanceof RegExp &&
      isGroupEnabled(group, enabledGroups[i] as RegExp)
    ) {
      return true;
    }
  }
  return false;
}

const memoizedCanLogWithParams = memoize(canLogWithParams);

function canLog(group: string) {
  return (
    !DISABLED && memoizedCanLogWithParams(group, ENABLED_GROUPS, SKIPPED_GROUPS)
  );
}

export type Message = {
  group: string;
  message: string;
  messageArgs: U.Nullable<any[]>;
  level: string;
  date: Date;
};

const MESSAGE_CHANNEL = new BehaviorSubject<U.Nullable<Message>>(null);

function createLoggerFunction(group: string, givenLevel?: string) {
  const level = givenLevel || DEFAULT_LEVEL;
  function log(message: string, ...messageArgs: any[]) {
    if (!canLog(group)) {
      return;
    }
    MESSAGE_CHANNEL.next({
      group,
      message,
      messageArgs,
      level,
      date: new Date(),
    });
  }
  return log;
}

function logger(group: string): Log {
  const newLogger = reduce(
    LEVELS,
    (result, value, key) => {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      result[key] = createLoggerFunction(group, key);
      return result;
    },
    {},
  );
  // @ts-ignore
  newLogger.extend = function extendLogger(newGroupName: string) {
    return logger(`${group}:${newGroupName}`);
  };
  // @ts-ignore
  return assign(createLoggerFunction(group), newLogger);
}
export default logger;
export const subscribe = function subscribe(
  callback: F.Function<[U.Nullable<Message>]>,
): void {
  MESSAGE_CHANNEL.subscribe(callback);
};

export const subject = MESSAGE_CHANNEL;
