import { applyBorderRadius } from '../apply-border';

export const square = 'border-radius: 0;';
export const round = applyBorderRadius(null, 50);

const shorthand = {
  square,
  round,
};

export default shorthand;
