import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import autoBind from 'auto-bind';

import { consumerProductRatingPath } from './apiPaths';
import logger from './log';
import { ProducteRatingValueReturn } from './types';

type Api = typeof defaultApiV2;

type ConsumerProductServiceOptions = {
  apiv2?: Api;
};

class ConsumerProductService {
  api: Api;

  constructor({ apiv2 }: ConsumerProductServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    autoBind(this);
  }

  async getProductRatingValue(productId: number): Promise<number> {
    logger.debug(
      'Trying to get product rating value for productID=%s',
      productId,
    );
    try {
      const response: ApiResponse<ProducteRatingValueReturn> =
        await this.api.get(consumerProductRatingPath(productId));
      const { data } = response;
      logger.info(
        'Successfully got product rating value of productID=%s',
        productId,
      );
      return Number(data.rating?.toFixed(2)) || 0;
    } catch (error) {
      logger.error('Error when getting product rating value, error=%O', error);
      throw error;
    }
  }
}

export default ConsumerProductService;
