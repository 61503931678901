import { createDI } from '@di/core';
import defaultApiV2 from '@mmw/api-v2';
import logger from '@mmw/logging-logger';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';
import { AxiosResponse } from 'axios';

import { GetManuOnlineReporting } from './apiPaths';

type Api = typeof defaultApiV2;

type ManuOnlineReportingServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};
export interface OnlineReportingResponse {
  id?: number;
  category: string;
  subCategory: string;
  name: string;
  iframeUrl: string;
}
class ManuOnlineReportingService {
  api: Api;

  authenticationService: AuthenticationService;

  static createDI = createDI<ManuOnlineReportingService>;

  constructor({
    apiv2,
    authenticationService,
  }: ManuOnlineReportingServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getAvailableOnlineReporting(
    language?: string,
  ): Promise<OnlineReportingResponse[]> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<OnlineReportingResponse[]> =
        await this.api.get(GetManuOnlineReporting(), {
          headers,
          params: { language },
        });
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }
}

export default ManuOnlineReportingService;
