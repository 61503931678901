const massProductRegistrationModal = 'massProductRegistrationModal';
const massProductRegistrationPage = 'massProductRegistrationPage';
const massProductRegistrationFeedback = 'massProductRegistrationFeedback';

const MODAL = {
  TITLE: {
    key: `${massProductRegistrationModal}.title`,
    message: 'Registration by mass upload',
  },
  DESCRIPTION: {
    key: `${massProductRegistrationModal}.description`,
    message: 'Please upload the completed template.',
  },
  DOWNLOAD_TEMPLATE: {
    key: `${massProductRegistrationModal}.downloadTemplate`,
    message: 'Download template',
  },
  DOWNLOAD_INSTRUCTIONS: {
    key: `${massProductRegistrationModal}.downloadInstructions`,
    message: 'Download instructions',
  },
  CHANGE_PARSER: {
    key: `${massProductRegistrationModal}.changeParser`,
    message: `Use America's XLS parser instead`,
  },
  UPLOAD_DOCUMENT: {
    key: `${massProductRegistrationModal}.uploadDocument`,
    message: 'Sell by upload register',
  },
  EMPTY_FILE: {
    key: `${massProductRegistrationModal}.emptyFile`,
    message: "You file doesn't contain any data.",
  },
  INVALID_FILE: {
    key: `${massProductRegistrationModal}.invalidFile`,
    message:
      'Invalid file format. Please upload an Excel Sheet according to the provided template.',
  },
};
const PAGE = {
  TITLE: {
    key: `${massProductRegistrationPage}.title`,
    message: 'Processed file',
  },
  EDIT_NOTICE: {
    key: `${massProductRegistrationPage}.editNotice`,
    message:
      'Now you can correct your registrations right in the table! Position your mouse over the cell you want to edit and click in the Edit button.',
  },
};

const FEEDBACK = {
  THERE_ARE: {
    key: `${massProductRegistrationFeedback}.thereAre`,
    message: 'Your upload contains',
  },
  REGISTRATIONS: {
    key: `${massProductRegistrationFeedback}.registrations`,
    message: 'valid registration(s) and',
  },
  WITH_ERRORS: {
    key: `${massProductRegistrationFeedback}.withErrors`,
    message: 'registrations with errors.',
  },
  CLICK_SAVE: {
    key: `${massProductRegistrationFeedback}.clickSave`,
    message: 'Please click Next to complete your registration.',
  },
  YOUR_REGISTRATION_WAS: {
    key: `${massProductRegistrationFeedback}.yourRegistrationWas`,
    message: 'Your registration was sucessfully saved.',
  },
  DID_CONSULTING: {
    key: `${massProductRegistrationFeedback}.didConsulting`,
    message: 'Did you consult the customer?',
  },
  IS_TRADE_IN: {
    key: `${massProductRegistrationFeedback}.isTradeIn`,
    message: 'Trade in?',
  },
  DID_DEMONSTRATION: {
    key: `${massProductRegistrationFeedback}.didDemonstration`,
    message: 'Did you do any demonstration/placement and advice?',
  },
  DID_DELIVERY: {
    key: `${massProductRegistrationFeedback}.didDelivery`,
    message: 'Did the product was delivered to the customer?',
  },
  HAS_SERVICE_RECEIPT: {
    key: `${massProductRegistrationFeedback}.hasServiceReceipt`,
    message: 'Did the receipt was provided to the customer from services?',
  },
  INVALID_ROWS: {
    key: `${massProductRegistrationFeedback}.invalidRows`,
    message:
      'Rows with red background will not be stored to our database. Please correct these rows if you want them to be processed. For this please click on >back<, correct the data in the sheet, and then make a new upload with the corrected sheet.',
  },
  WARNING_TITLE: {
    key: `${massProductRegistrationFeedback}.warningTitle`,
    message: 'Warning',
  },
  INVALID_ROW: {
    key: `${massProductRegistrationFeedback}.invalidRow`,
    message:
      'This data cannot be processed and prevents the respective row from be stored to the database.',
  },
  EDIT_ROW_INFO: {
    key: `${massProductRegistrationFeedback}.editRowInfo`,
    message:
      'You can position your mouse over the columns with error and an edit button will appear, than you can click on it and edit the column data.',
  },
  EXCEL_SECURITY_WARNING: {
    key: `${massProductRegistrationFeedback}.excelSecurityWarning`,
    message:
      'Important: An error message appears when you open Excel. Please click on "Yes" and ignore them. You can then fill in and upload the file as usual.',
  },
};

const I18N = {
  MODAL,
  PAGE,
  FEEDBACK,
};

export default I18N;
