import { BasePath } from '@di/core/BasePath';
import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import { ApiBadRequestError } from '@mmw/common-api-client/errors';
import { FileType } from '@mmw/constants-file-types';
import { LanguageCode } from '@mmw/constants-languages';
import { EMPTY_OBJECT } from '@mmw/constants-utils';
import contextualConfig from '@mmw/contextual-config';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import { AuthenticationAuthorities } from '@mmw/services-auth-api-authentication/types';
import {
  ConsumerJSON,
  UploadableFileBlob,
} from '@mmw/services-core-common/types';
import ConsumerService from '@mmw/services-core-consumer';
import SolarDeliveriesService from '@mmw/services-core-solar-delivery';
import autoBind from 'auto-bind';
import {
  filter,
  find,
  get,
  isEmpty,
  map,
  omit,
  pick,
  size,
  uniq,
} from 'lodash';
import { U } from 'ts-toolbelt';

import { ProjectError, SearchFoundNoResultsError } from './errors';
import logger from './log';
import { getPaths, PROJECTS_SERVICE_BASE_PATH_TOKEN } from './paths';
import { convertDeleteRequest } from './transformers';
import {
  AddManualPanelResponse,
  CampaignScoreType,
  DeliveryRequestObject,
  FileJSON,
  InstallationSiteJSON,
  InstallerJSON,
  InstallerRequestJSON,
  LoadProjectsResult,
  MultiUploadProjectJSON,
  MultiUploadProjectV2JSON,
  NewInstallerJSON,
  NewProjectJSON,
  NotSavedPanel,
  OrderPanelTreeItem,
  Pagination,
  PalletPanelTreeItem,
  PanelResultRow,
  PanelTreeItemTypes,
  ProjectActionJSON,
  ProjectAndPanelsResult,
  ProjectCampaignItemJSON,
  ProjectCampaignScore,
  ProjectFileUploadRequest,
  ProjectInstallationPlaceType,
  ProjectJSON,
  ProjectRequestObject,
  ProjectsState,
  RankedCampaignRegistrationsJSON,
  RankedCampaignScoresByInstallerRequestJSON,
  UpdateNameAndInstallationPlaceJSON,
  UploadFileResultJSON,
  UploadPanelsResultJSON,
} from './types';

type Api = typeof defaultApiV2;

type ProjectsServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
  consumerService: ConsumerService;
  solarDeliveriesService: SolarDeliveriesService;
};

class ProjectsService extends BasePath {
  api: Api;

  authenticationService: AuthenticationService;

  consumerService: ConsumerService;

  solarDeliveriesService: SolarDeliveriesService;

  constructor({
    apiv2,
    authenticationService,
    consumerService,
    solarDeliveriesService,
  }: ProjectsServiceOptions) {
    super(PROJECTS_SERVICE_BASE_PATH_TOKEN);
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    this.consumerService = consumerService;
    this.solarDeliveriesService = solarDeliveriesService;
    autoBind(this);
  }

  async nonTrader(): Promise<boolean> {
    // exception for non-trader users
    const logged = await this.authenticationService.isLoggedIn();
    const auths = await this.authenticationService.getUserAuthorities();
    return (
      !logged ||
      auths.includes(AuthenticationAuthorities.ROLE_SOLARDIST) ||
      auths.includes(AuthenticationAuthorities.PERM_MANUFACTURER_SOLAR_PROJECTS)
    );
  }

  async retrieveProject(id: number, language: string): Promise<ProjectJSON> {
    logger.debug('Trying to get project by id=%s', id);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.get(
        getPaths(this.getBasePath()).RetrieveProjectPath(id, language),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got project by id=%s', id);
      return data;
    } catch (error) {
      logger.error('Error when retrieving project=%s, error=%O', id, error);
      throw new ProjectError(id, error);
    }
  }

  async retrieveProjectPanelsTree(
    id: number,
    language: string,
  ): Promise<Array<OrderPanelTreeItem>> {
    logger.debug('Trying to get project panels tree by id=%s', id);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<OrderPanelTreeItem>> =
        await this.api.get(
          getPaths(this.getBasePath()).RetrieveProjectPanelsTreePath(
            id,
            language,
          ),
          {
            headers,
          },
        );
      const { data } = response;
      logger.info('Successfully got project panels tree by id=%s', id);
      return data;
    } catch (error) {
      logger.error('Error when getting project panels tree, error=%O', error);
      throw new ProjectError(id, error);
    }
  }

  async saveProjectManualPanels(
    id: number,
    panels: Array<PanelResultRow>,
    language: string,
  ): Promise<AddManualPanelResponse> {
    logger.debug(
      'Trying to save manual project panels for project id=%s',
      id,
      panels,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<AddManualPanelResponse> = await this.api.post(
        getPaths(this.getBasePath()).SaveProjectManualPanelsPath(id, language),
        panels,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully saved manual project panels by id=%s', data);
      return data;
    } catch (error) {
      logger.error('Error when saving project panels tree, error=%O', error);
      throw new ProjectError(id, error);
    }
  }

  async createPanelsWithoutSerialnumber(
    projectID: number,
    ean: string,
    quantity: number,
    language: string,
  ): Promise<ProjectAndPanelsResult> {
    logger.debug('Trying to create panels no/sn for project id=%s', projectID);
    try {
      const panels = map(new Array(quantity), (x: any, index: number) => ({
        rowNr: index,
        ean,
        serialnumber: 'N/A',
      }));
      return this.createPanelsManually(projectID, panels, language);
    } catch (error) {
      logger.error('Error when creating panels no/sn, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async createPanelsManually(
    projectID: number,
    panels: Array<PanelResultRow>,
    language: string,
  ): Promise<ProjectAndPanelsResult> {
    logger.debug('Trying to create manual panels for project id=%s', projectID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<AddManualPanelResponse> = await this.api.post(
        getPaths(this.getBasePath()).SaveProjectManualPanelsPath(
          projectID,
          language,
        ),
        panels || EMPTY_OBJECT,
        { headers },
      );
      const { data } = response;
      logger.info('Successfully created manual panels by id=%s', projectID);
      const project = await this.retrieveProject(projectID, language);
      return {
        panels: data.panelsTree,
        project,
      };
    } catch (error) {
      logger.error('Error when creating manual panels, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async updatePanelsWithoutSerialnumber(
    projectID: number,
    panelsToUpdate: Array<NotSavedPanel>,
    noSerialPanelsByEan: Array<PalletPanelTreeItem>,
    language: string,
  ): Promise<ProjectAndPanelsResult> {
    const eansToUpdate = uniq(map(panelsToUpdate, 'ean'));
    await Promise.all(
      eansToUpdate.map(ean =>
        this.updatePanelsWithoutSerialnumberByEan(
          projectID,
          filter(panelsToUpdate, p => p.ean === ean),
          find(
            noSerialPanelsByEan,
            panels => panels?.childrenRequest.ean === ean,
          ),
          language,
          ean,
        ),
      ),
    );
    const project = await this.retrieveProject(projectID, language);
    const panels = await this.retrieveProjectPanelsTree(projectID, language);
    return {
      panels,
      project,
    };
  }

  async updatePanelsWithoutSerialnumberByEan(
    projectID: number,
    panelsToUpdate: Array<NotSavedPanel>,
    noSerialPanelsByEan: PalletPanelTreeItem | null | void,
    language: string,
    ean: string,
  ): Promise<void> {
    const countWithoutSn =
      noSerialPanelsByEan != null ? noSerialPanelsByEan.childrenSize : 0;
    const countPanelsToUpdate = size(panelsToUpdate);
    const diffWithoutSnLeft = countWithoutSn - countPanelsToUpdate;
    if (countPanelsToUpdate === 0) {
      return;
    }
    if (noSerialPanelsByEan != null && !isEmpty(noSerialPanelsByEan.children)) {
      // deletes only the panel serial with N/A as s/n,
      // thats why we get the first child, otherwise it would delete all with that same pallet
      const panelToDelete = noSerialPanelsByEan.children[0];
      const toDelete = [panelToDelete];
      logger.info(
        `Will delete unknown panels ean=${ean}, size=${panelToDelete.leafSize}`,
      );
      await this.deleteProjectPanelsByTreeRequest(
        projectID,
        toDelete,
        language,
      );
    }
    const amountToCreateWithoutSn =
      diffWithoutSnLeft > 0 ? diffWithoutSnLeft : 0;
    const unknownNewPanels = map(
      new Array(amountToCreateWithoutSn),
      (x: any, index: number) => ({
        rowNr: index,
        ean,
        serialnumber: 'N/A',
      }),
    );
    const indexRelative = size(unknownNewPanels);
    const knownNewPanels = map(
      panelsToUpdate,
      (p: NotSavedPanel, index: number) => ({
        rowNr: index + indexRelative,

        ...p,
      }),
    );
    const newPanels = [...unknownNewPanels, ...knownNewPanels];
    logger.info(
      `Will create ${unknownNewPanels.length} unknown and ${knownNewPanels.length} known panels`,
    );
    await this.createPanelsManually(projectID, newPanels, language);
  }

  async saveProjectPanelsByTreeRequest(
    id: number,
    panels: Array<OrderPanelTreeItem>,
    language: string,
  ): Promise<Array<OrderPanelTreeItem>> {
    logger.debug('Trying to save project panels tree by id=%s', id);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<OrderPanelTreeItem>> =
        await this.api.post(
          getPaths(this.getBasePath()).SaveProjectPanelsTreePath(id, language),
          panels.map(panel => panel.childrenRequest),
          { headers },
        );
      const { data } = response;
      logger.info('Successfully saved project panels tree by id=%s', id);
      return data;
    } catch (error) {
      logger.error('Error when saving project panels tree, error=%O', error);
      throw new ProjectError(id, error);
    }
  }

  async deleteProjectPanelsByTreeRequest(
    id: number,
    panels: Array<PanelTreeItemTypes>,
    language: string,
  ): Promise<Array<OrderPanelTreeItem>> {
    logger.debug('Trying to delete project panels tree by id=%s', id);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<OrderPanelTreeItem>> =
        await this.api.post(
          getPaths(this.getBasePath()).DeleteProjectPanelsTreePath(
            id,
            language,
          ),
          panels.map(panel => convertDeleteRequest(panel.childrenRequest)),
          { headers },
        );
      const { data } = response;
      logger.info('Successfully deleted project panels tree by id=%s', id);
      return data;
    } catch (error) {
      if (
        error instanceof ApiBadRequestError &&
        get(error, 'body.error') === 5
      ) {
        const panelsTree = await this.retrieveProjectPanelsTree(id, language);
        return panelsTree;
      }
      logger.error(
        'Error when deleting project panels tree by id=%s, error=%O',
        id,
        error,
      );
      throw new ProjectError(id, error);
    }
  }

  async getProjects(
    options: ProjectRequestObject,
    language: string,
  ): Promise<Pagination<ProjectJSON>> {
    logger.debug('Trying to get projects by request=%O', options);
    try {
      await this.authenticationService.ensureLogin(
        ProjectsService.name,
        this.getProjects.name,
        JSON.stringify({ ...options, language }),
      );
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<ProjectJSON>> =
        await this.api.post(
          getPaths(this.getBasePath()).GetProjectsPath(language),
          options,
          {
            headers,
          },
        );
      const { data } = response;
      logger.info(`Successfully got projects, size ${data.total}`);
      return data;
    } catch (error) {
      logger.error('Error when getting projects, error=%O', error);
      throw error;
    }
  }

  async getProjectsWithStateSync(
    options: ProjectRequestObject,
    projectIDs: Array<number>,
    language: string,
  ): Promise<LoadProjectsResult> {
    logger.debug(
      'Trying to get projects with sync state by request=%O',
      options,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const [projectsState, response] = await Promise.all([
        this.syncProjectsStatus(projectIDs, language),
        this.api.post(
          getPaths(this.getBasePath()).GetProjectsPath(language),
          options,
          {
            headers,
          },
        ),
      ]);
      // const projectsState = await this.syncProjectsStatus(projectIDs, language);
      // const response: ApiResponse<Pagination<ProjectJSON>> = await this.api
      //   .post(
      //    getPaths(this.getBasePath).GetProjectsPath(language),
      //     options,
      //     { headers },
      //   );
      const { data } = response;
      logger.info(
        `Successfully got projects with sync state, size ${data.total}`,
      );
      return {
        pagination: data,
        projectsState,
      };
    } catch (error) {
      logger.error(
        'Error when getting projects with sync state, error=%O',
        error,
      );
      throw error;
    }
  }

  async syncProjectsStatus(
    projectIDs: Array<number>,
    language: string,
  ): Promise<ProjectsState> {
    logger.debug('Trying to sync projects status for ids=', projectIDs);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectsState> = await this.api.post(
        getPaths(this.getBasePath()).SyncProjectsStatusPath(language),
        projectIDs,
        { headers },
      );
      const { data } = response;
      logger.info('Successfully synchronized projects status');
      return data;
    } catch (error) {
      logger.error('Error on synchronizing projects status, error=%O', error);
      throw error;
    }
  }

  async createProject(
    newProject: NewProjectJSON,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to create new project=O%', newProject);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.post(
        getPaths(this.getBasePath()).CreateProjectPath(language),
        newProject,
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully created project, id=${data.id}`);
      if (newProject.systemSize) {
        return this.updateProjectSystemSize(
          data.id,
          newProject.systemSize,
          language,
        );
      }
      return data;
    } catch (error) {
      logger.error('Error when creating project, error=%O', error);
      throw error;
    }
  }

  async updateProjectNameAndInstallationPlace(
    projectID: number,
    {
      name,
      installationPlace,
      systemSize,
      installationType,
    }: UpdateNameAndInstallationPlaceJSON,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug(
      'Trying to update name and installation place of project id=s%',
      projectID,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectNamePath(projectID, language),
        {
          name,
        },
        {
          headers,
        },
      );
      const response: ApiResponse<ProjectJSON> = await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectInstallationPlacePath(
          projectID,
          language,
        ),
        {
          installationPlace,
        },
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(
        `Successfully updated project name and installation place, id=${data.id}`,
      );
      if (systemSize) {
        return this.updateProjectSystemSize(data.id, systemSize, language);
      }
      if (installationType) {
        return this.updateProjectInstallationType(
          data.id,
          installationType,
          language,
        );
      }
      return data;
    } catch (error) {
      logger.error('Error when updating project, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async updateProjectInstallationPlace(
    projectID: number,
    installationPlace: ProjectInstallationPlaceType,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug(
      'Trying to update name and installation place of project id=s%',
      projectID,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectInstallationPlacePath(
          projectID,
          language,
        ),
        {
          installationPlace,
        },
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(
        `Successfully updated project name and installation place, id=${data.id}`,
      );
      return data;
    } catch (error) {
      logger.error('Error when updating project, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async updateProjectInstallationType(
    projectID: number,
    installationType: number,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug(
      'Trying to update name and installation place of project id=s%',
      projectID,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectInstallationTypePath(
          projectID,
          language,
        ),
        {
          installationType,
        },
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(
        `Successfully updated project name and installation place, id=${data.id}`,
      );
      return data;
    } catch (error) {
      logger.error('Error when updating project, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async updateProjectName(
    projectID: number,
    name: string,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to update name of project id=s%', projectID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response = await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectNamePath(projectID, language),
        {
          name,
        },
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully updated project name, id=${data.id}`);
      return data;
    } catch (error) {
      logger.error('Error when updating project, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async updateProjectSystemSize(
    projectID: number,
    systemSize: number,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to update systemSize of project id=s%', projectID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response = await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectSystemSizePath(
          projectID,
          language,
        ),
        {
          systemSize,
        },
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully updated project systemSize, id=${data.id}`);
      return data;
    } catch (error) {
      logger.error('Error when updating project, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async updateProjectDescription(
    projectID: number,
    description: string,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to update description of project id=s%', projectID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response = await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectDescriptionPath(
          projectID,
          language,
        ),
        {
          description,
        },
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully updated project description, id=${data.id}`);
      return data;
    } catch (error) {
      logger.error('Error when updating project, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async saveProjectInstallationSite(
    projectID: number,
    installationSite: InstallationSiteJSON,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug(
      'Trying to update installation site of project id=s%',
      projectID,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const previousProject = await this.retrieveProject(projectID, language);
      const installationSiteID =
        previousProject && previousProject.installationSite != null
          ? previousProject.installationSite.personID
          : null;
      const siteToUpdate = {
        // XXX: API requires those fields that I setted below
        ...installationSite,
        personID: installationSiteID,
        domkeysalutation: installationSite.domkeysalutation || 'EMPTY',
        domkeytitle: installationSite.domkeytitle || 'EMPTY',
        firstname: installationSite.firstname || 'N/A',
        lastname: installationSite.lastname || 'N/A',
      };
      const response: ApiResponse<ProjectJSON> = await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectInstallationSitePath(
          projectID,
          language,
        ),
        siteToUpdate,
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully updated installation site, id=${data.id}`);
      return data;
    } catch (error) {
      logger.error(
        'Error when updating project installation site, error=%O',
        error,
      );
      throw new ProjectError(projectID, error);
    }
  }

  async saveProjectInstallationDate(
    projectID: number,
    installationDate: Date | null,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug(
      'Trying to update installation date of project id=s%',
      projectID,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectInstallationDatePath(
          projectID,
          language,
        ),
        { installationDate },
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully updated installation date, id=${data.id}`);
      return data;
    } catch (error) {
      logger.error(
        'Error when updating project installation date, error=%O',
        error,
      );
      throw new ProjectError(projectID, error);
    }
  }

  async saveProjectInvoiceDate(
    projectID: number,
    invoiceDate: Date | null,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to update invoice date of project id=s%', projectID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectInvoiceDatePath(
          projectID,
          language,
        ),
        { invoiceDate },
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully updated invoice date, id=${data.id}`);
      return data;
    } catch (error) {
      logger.error('Error when updating project invoice date, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async saveProjectConsumer(
    projectID: number,
    consumer: ConsumerJSON,
    branchID: number,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to save consumer of project id=s%', projectID);
    try {
      const previousConsumerID = consumer.consumerID;
      const savedConsumer = await this.consumerService.saveConsumer(
        consumer,
        branchID,
      );
      const newConsumerID = savedConsumer.consumerID;
      if (previousConsumerID == null || previousConsumerID !== newConsumerID) {
        const headers =
          await this.authenticationService.getAuthenticationHttpHeaders();
        const response: ApiResponse<ProjectJSON> = await this.api.post(
          // XXX: newConsumerID is always set
          getPaths(this.getBasePath()).ChangeProjectConsumerPath(
            projectID,
            newConsumerID,
            language,
          ),
          {},
          { headers },
        );
        const { data } = response;
        logger.info(
          'Successfully changed consumer on project, id=%s',
          projectID,
        );
        return data;
      }
      // XXX: always returns a project, we know it exists at this point
      return this.retrieveProject(projectID, language);
    } catch (error) {
      logger.error('Error when saving consumer, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async changeProjectConsumer(
    projectID: number,
    consumerID: number,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to save consumer of project id=s%', projectID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      await this.api.post(
        getPaths(this.getBasePath()).ChangeProjectConsumerPath(
          projectID,
          consumerID,
          language,
        ),
        {},
        { headers },
      );
      logger.info('Successfully changed consumer on project, id=%s', projectID);
      return this.retrieveProject(projectID, language);
    } catch (error) {
      logger.error('Error when saving consumer, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async changeProjectInstaller(
    projectID: number,
    installerID: number,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to save consumer of project id=s%', projectID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      await this.api.post(
        getPaths(this.getBasePath()).ChangeProjectInstallerPath(
          projectID,
          installerID,
          language,
        ),
        {},
        { headers },
      );
      logger.info(
        'Successfully changed installer on project, id=%s',
        projectID,
      );
      return this.retrieveProject(projectID, language);
    } catch (error) {
      logger.error('Error when saving installer, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async setProjectDistributor(
    projectID: number,
    distributorID: number,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to save distributor of project id=s%', projectID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      await this.api.post(
        getPaths(this.getBasePath()).SetProjectDistributorPath(
          projectID,
          distributorID,
          language,
        ),
        {},
        { headers },
      );
      logger.info(
        'Successfully changed distributor on project, id=%s',
        projectID,
      );
      return this.retrieveProject(projectID, language);
    } catch (error) {
      logger.error('Error when saving distributor, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async saveProjectInstaller(
    projectID: number,
    installer: NewInstallerJSON,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to update installer of project id=s%', projectID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.put(
        getPaths(this.getBasePath()).SaveProjectInstallerPath(
          projectID,
          language,
        ),
        installer,
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully updated installer, id=${data.id}`);
      return data;
    } catch (error) {
      logger.error('Error when updating project installer, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async createOrUpdateProjectConsumer(
    projectID: number,
    formValues: {
      firstname: string;
      lastname: string;
      phone: string;
      email: string;
      sameAddress: boolean;
      street: string;
      nr: string;
      zipcode: string;
      city: string;
      country: string;
      state: string;
      name1: string;
      complement: string;
      company: string;
    },
    branchID: number,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to save consumer of project id=s%', projectID);
    try {
      const project = await this.retrieveProject(projectID, language);
      if (project == null) {
        throw new Error('Cant update consumer, project wasnt found');
      }
      const consumerID =
        project && project.consumer != null
          ? project.consumer.consumerID
          : undefined;
      const { installationSite: person } = project;
      if (person == null) {
        throw new Error(
          'Installation Site is not defined, cant update consumer',
        );
      }
      const { address: installationSiteAddress } = person;
      if (installationSiteAddress == null) {
        throw new Error(
          'Installation Site Address is not defined, cant update consumer',
        );
      }
      const addressToSave = formValues.sameAddress
        ? installationSiteAddress
        : formValues;

      // TODO: Change create/return types!
      return await this.saveProjectConsumer(
        projectID,
        {
          consumerID,
          person: {
            ...omit(person, 'address'),
            firstname: formValues.firstname,
            lastname: formValues.lastname,
            phone: formValues.phone,
            email: formValues.email,
            address: {
              ...pick(person, 'address'),
              company: formValues.company,
              street: addressToSave.street,
              nr: addressToSave.nr,
              zipcode: addressToSave.zipcode,
              city: addressToSave.city,
              country: addressToSave.country,
              state: addressToSave.state,
              name1: addressToSave.name1,
              complement: addressToSave.complement,
            },
          },
          company: pick(person, 'company'),
        },
        branchID,
        language,
      );
    } catch (error) {
      logger.error('Error when changing project consumer, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async deleteProject(projectID: number): Promise<void> {
    logger.debug(`Trying to delete project id=${projectID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      await this.api.delete(
        getPaths(this.getBasePath()).DeleteProjectPath(projectID),
        {
          headers,
        },
      );
      logger.info(`Successfully deleted project, id=${projectID}`);
    } catch (error) {
      logger.error(
        `Error when trying to delete project id=${projectID}`,
        error,
      );
      throw new ProjectError(projectID, error);
    }
  }

  async updateProjectFileTypes(
    projectID: number,
    fileToTypeMap: Record<number, FileType>,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug(`Trying to update project files types id=${projectID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.post(
        getPaths(this.getBasePath()).UpdateProjectFilesTypesPath(
          projectID,
          language,
        ),
        fileToTypeMap,
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully updated project file types, id=${projectID}`);
      return data;
    } catch (error) {
      logger.error(
        `Error when trying to update project file types id=${projectID}`,
        error,
      );
      throw new ProjectError(projectID, error);
    }
  }

  async deleteProjectFile(
    projectID: number,
    fileID: number,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug(
      `Trying to delete project file id=${projectID}, file=${fileID}`,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.delete(
        getPaths(this.getBasePath()).DeleteProjectFilePath(
          projectID,
          fileID,
          language,
        ),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(
        `Successfully deleted project file=${fileID}, id=${projectID}`,
      );
      return data;
    } catch (error) {
      logger.error(
        `Error when trying to delete project id=${projectID}`,
        error,
      );
      throw new ProjectError(projectID, error);
    }
  }

  async uploadProjectFile(
    projectID: number,
    file: ProjectFileUploadRequest,
    language: string,
  ): Promise<UploadFileResultJSON> {
    logger.debug(`Trying to upload file to project id=${projectID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UploadFileResultJSON> = await this.api.post(
        getPaths(this.getBasePath()).UploadProjectFilePath(
          projectID,
          file.type,
          language,
        ),
        {
          name: file.name,
          format: file.format,
          file: file.base64Data,
          type: file.type,
        },
        {
          headers,
        },
      );
      const { data } = response;
      if (!data.success) throw new Error(data.error);
      logger.info(
        `Successfully uploaded file to project id=${projectID}, status=%s`,
        data.success,
      );
      return data;
    } catch (error) {
      logger.error(
        `Error when trying upload file to project id=${projectID}`,
        error,
      );
      throw new ProjectError(projectID, error);
    }
  }

  async registerProject(
    projectID: number,
    language: string,
    simulate: boolean,
    sendMail: boolean,
    certificateLanguage?: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to register project id=s%', projectID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectActionJSON> = await this.api.post(
        getPaths(this.getBasePath()).RegisterProjectPath(
          projectID,
          language,
          simulate,
          sendMail,
          certificateLanguage,
        ),
        {},
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully registered project, id=${projectID}`);
      return data.project;
    } catch (error) {
      if (
        error instanceof ApiBadRequestError &&
        get(error, 'body.reason') === 'STATUS'
      ) {
        const project = await this.retrieveProject(projectID, language);
        return project;
      }
      logger.error('Error when registering project, error=%O', error);
      throw new ProjectError(projectID, error);
    }
  }

  async searchAndAddPanels(
    projectID: number,
    requestObject: DeliveryRequestObject,
    language: LanguageCode,
  ): Promise<ProjectAndPanelsResult> {
    logger.debug(
      'Trying to search and add panels by request=%O',
      requestObject,
    );
    const searchResult =
      await this.solarDeliveriesService.searchDeliveries(requestObject);
    if (isEmpty(searchResult)) {
      logger.debug('No results found for request=%O', requestObject);
      throw new SearchFoundNoResultsError();
    }
    const panels = await this.saveProjectPanelsByTreeRequest(
      projectID,
      searchResult,
      language,
    );
    const project = await this.retrieveProject(projectID, language);
    return {
      panels,
      project,
    };
  }

  async removePanelsFromProject(
    projectID: number,
    panels: Array<PanelTreeItemTypes>,
    language: LanguageCode,
  ): Promise<ProjectAndPanelsResult> {
    logger.debug('Removing panels from project by request=%O', panels);
    const removeResult = await this.deleteProjectPanelsByTreeRequest(
      projectID,
      panels,
      language,
    );
    const project = await this.retrieveProject(projectID, language);
    return {
      panels: removeResult,
      project,
    };
  }

  async uploadPanelsSpreadSheetFile(
    projectId: number,
    file: UploadableFileBlob,
    format: string,
    language: LanguageCode,
  ): Promise<UploadPanelsResultJSON> {
    logger.debug(
      'Trying to upload panels spreadsheet file for project by id',
      projectId,
    );
    try {
      // const formData = new FormData();

      // formData.append('file', file.blob);
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UploadPanelsResultJSON> = await this.api.post(
        getPaths(this.getBasePath()).UploadPanelsSpreadSheetPath(
          projectId,
          language,
          format,
        ),
        file,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(
        'Successfully upload panels spreadsheet file by id=',
        projectId,
      );
      return data;
    } catch (error) {
      logger.error(
        'Error when upload panels spreadsheet file, error=%O',
        error,
      );
      throw new ProjectError(projectId, error);
    }
  }

  async savePanelsFromSpreadSheetUpload(
    projectId: number,
    results: Array<PanelResultRow>,
    language: LanguageCode,
  ): Promise<ProjectAndPanelsResult> {
    logger.debug(
      'Trying to save panels from spreadsheet upload for project by id',
      projectId,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<any> = await this.api.post(
        getPaths(this.getBasePath()).SavePanelsFromSpreadSheetUpload(
          projectId,
          language,
        ),
        results,
        { headers },
      );
      const { data } = response;
      logger.info(
        'Successfully saved panels from spreadsheet upload by id=',
        projectId,
      );
      const project = await this.retrieveProject(projectId, language);
      return {
        panels: data.panelsTree,
        project,
      };
    } catch (error) {
      logger.error(
        'Error when saving panels from spreadsheet upload, error=%O',
        error,
      );
      throw error;
    }
  }

  async searchInstallers(
    options: InstallerRequestJSON,
    language: string,
  ): Promise<Pagination<InstallerJSON>> {
    logger.debug('Trying to search installers by request=%O', options);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<InstallerJSON>> =
        await this.api.post(
          getPaths(this.getBasePath()).SearchInstallersPath(language),
          options,
          {
            headers,
          },
        );
      const { data } = response;
      logger.info(`Successfully found installers, size ${data.total}`);
      return data;
    } catch (error) {
      logger.error('Error when searching installers, error=%O', error);
      throw error;
    }
  }

  async getDrawCampaignsForRegister(
    projectId: number,
    language: string,
    simulate: boolean,
    campaignItems?: ProjectCampaignItemJSON[],
  ): Promise<{
    project: ProjectJSON;
    background: boolean;
  }> {
    logger.debug('Trying to load draw campaigns for', projectId);
    let headers = null;
    try {
      if (await this.nonTrader()) return null;
      headers = await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<{
        project: ProjectJSON;
        background: boolean;
      }> = await this.api.post(
        getPaths(this.getBasePath()).GetDrawCampaignsPath(
          projectId,
          language,
          simulate,
        ),
        campaignItems || [],
        { headers },
      );
      logger.info('Successfully got draw campaigns', projectId);
      return response.data;
    } catch (error) {
      logger.error('Error when getting draw campaign, error=%O', error);
      throw error;
    }
  }

  async getCampaignScores(
    campaignCodes: Array<string>,
    projectId?: number,
    scoreType?: CampaignScoreType,
    orgunitID?: number,
  ): Promise<ProjectCampaignScore> {
    logger.debug('Trying to load register score for', campaignCodes);
    if (!scoreType) {
      logger.warn('No score type, cant calculate', campaignCodes);
      return {};
    }
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectCampaignScore> = await this.api.post(
        getPaths(this.getBasePath()).GetCampaignScoresPath(
          projectId,
          scoreType,
          orgunitID,
        ),
        campaignCodes,
        { headers },
      );
      logger.info('Successfully got scores for campaign', campaignCodes);
      return response.data;
    } catch (error) {
      logger.error('Error when getting register score, error=%O', error);
      throw new ProjectError(projectId, error);
    }
  }

  async drawFinish(
    projectId: number,
    language: string,
    certificateLanguage?: string,
  ): Promise<{
    project: ProjectJSON;
    background: boolean;
  }> {
    logger.debug('Trying to finish draw campaign', projectId);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<{
        project: ProjectJSON;
        background: boolean;
      }> = await this.api.post(
        getPaths(this.getBasePath()).GetDrawFinishPath(
          language,
          projectId,
          certificateLanguage,
        ),
        {},
        { headers },
      );
      logger.info('Successfully finished draw campaign', projectId);
      return response.data;
    } catch (error) {
      logger.error('Error when finishing draw campaign, error=%O', error);
      throw new ProjectError(projectId, error);
    }
  }

  async updateQuestions(
    projectId: number,
    language: string,
    simulate: boolean,
    campaignItems?: ProjectCampaignItemJSON[],
  ): Promise<{
    project: ProjectJSON;
    background: boolean;
  }> {
    logger.debug('Trying to update questions', projectId);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<{
        project: ProjectJSON;
        background: boolean;
      }> = await this.api.post(
        getPaths(this.getBasePath()).UpdateQuestionsPath(
          projectId,
          language,
          simulate,
        ),
        campaignItems || [],
        { headers },
      );
      logger.info('Successfully updated questions', projectId, response.body);
      return response.data;
    } catch (error) {
      logger.error('Error when updating questions, error=%O', error);
      throw new ProjectError(projectId, error);
    }
  }

  async getDistributorsList(
    language: string,
    request: InstallerRequestJSON,
  ): Promise<Pagination<InstallerJSON>> {
    logger.debug('Trying to get distributors list', language, request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<InstallerJSON>> =
        await this.api.post(
          getPaths(this.getBasePath()).GetDistributorsListPath(language),
          request,
          { headers },
        );
      const { data } = response;
      logger.info('Successfully get distributors list', language, data.length);
      return data;
    } catch (error) {
      logger.error('Error when getting distributors list, error=%O', error);
      throw new ProjectError(language, error);
    }
  }

  async createAndRegisterProject(
    project: MultiUploadProjectJSON,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to create new project=O%', project);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.post(
        getPaths(this.getBasePath()).CreateAndRegisterProjectPath(language),
        project,
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully created project, id=${data.id}`);
      return data;
    } catch (error) {
      logger.error('Error when creating project, error=%O', error);
      throw error;
    }
  }

  async createAndRegisterProjectV2(
    project: MultiUploadProjectV2JSON,
    language: string,
  ): Promise<ProjectJSON> {
    logger.debug('Trying to create new project=O%', project);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProjectJSON> = await this.api.post(
        getPaths(this.getBasePath()).CreateAndRegisterProjectV2Path(language),
        project,
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully created project, id=${data.id}`);
      return data;
    } catch (error) {
      logger.error('Error when creating project, error=%O', error);
      throw error;
    }
  }

  async getRankedCampaignScoresByInstaller(
    request: RankedCampaignScoresByInstallerRequestJSON,
  ): Promise<Array<RankedCampaignRegistrationsJSON>> {
    logger.debug('Trying to get score ranking by=O%', request);
    try {
      const response: ApiResponse<Array<RankedCampaignRegistrationsJSON>> =
        await this.api.post(
          getPaths(this.getBasePath()).GetRankedCampaignScoresByInstallerPath(),
          request,
        );
      const { data } = response;
      logger.info('Successfully got ranking');
      return data;
    } catch (error) {
      logger.error('Error when loading ranking, error=%O', error);
      throw error;
    }
  }

  async downloadSelectedProjectsCertificates(
    request: number[],
    language: string,
  ): Promise<Blob> {
    logger.debug('Trying to download project certificates by request', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Blob> = await this.api.post(
        getPaths(this.getBasePath()).MultiDownloadRegistrationCertificatePath(
          language,
        ),
        request,
        {
          headers,
          responseType: 'blob',
        },
      );
      logger.info('Successfully downloaded project certificates');
      return new Blob([response.data]);
    } catch (error) {
      logger.error(
        'Error when downloading project certificates by request, error=%O',
        error,
      );
      throw error;
    }
  }

  async downloadProjectCertificate(
    projectID: number,
    language: string,
    accessToken: string,
  ): Promise<Blob> {
    logger.debug(
      'Trying to download project certificates by projectID',
      projectID,
    );
    try {
      const response: ApiResponse<Blob> = await this.api.get(
        getPaths(this.getBasePath()).DownloadRegistrationCertificatePath(
          projectID,
          accessToken,
          language,
        ),
        {
          responseType: 'arraybuffer',
        },
      );
      logger.info('Successfully downloaded project certificates');
      return new Blob([response.data]);
    } catch (error) {
      logger.error(
        'Error when downloading project certificates by request, error=%O',
        error,
      );
      return null;
    }
  }

  async downloadProjectCertificatePath(
    projectID: number,
    accessToken: U.Nullable<string>,
    language?: U.Nullable<LanguageCode>,
  ): Promise<string> {
    const path = getPaths(
      this.getBasePath(),
    ).DownloadRegistrationCertificatePath(projectID, accessToken, language);
    return `${contextualConfig.api.v2.baseURI}${path}`;
  }

  async downloadFlasherData(
    projectIDs: number[],
    language: string,
  ): Promise<Blob> {
    logger.debug(
      `Trying to download projects flasher data total=${projectIDs.length}`,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Blob> = await this.api.post(
        getPaths(this.getBasePath()).GetDownloadFlasherDataPath(language),
        [...projectIDs],
        {
          headers,
          responseType: 'blob',
        },
      );
      logger.info(
        `Successfully downloaded projects flasher data total=${projectIDs.length}`,
      );
      return response.data;
    } catch (error) {
      logger.error(
        `Error when trying to download projects flasher data total=${projectIDs.length}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async revalidateProjectById(
    projectIDs: number[],
    language: string,
  ): Promise<any> {
    logger.debug(
      `Trying to revalidate project for project total=${projectIDs.length}`,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response = await this.api.post(
        getPaths(this.getBasePath()).GetRevalidateProjectPath(language),
        [...projectIDs],
        {
          headers,
        },
      );
      logger.info(
        `Successfully revalidated project for project total=${projectIDs.length}`,
      );
      return response.data;
    } catch (error) {
      logger.error(
        `Error when trying to revalidate project for project total=${projectIDs.length}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async reverseProjectPath(
    projectIDs: number[],
    language: string,
  ): Promise<any> {
    logger.debug(
      `Trying to reverse project for project total=${projectIDs.length}`,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response = await this.api.post(
        getPaths(this.getBasePath()).GetReverseProjectPath(language),
        [...projectIDs],
        {
          headers,
        },
      );
      logger.info(
        `Successfully reversed project for project total=${projectIDs.length}`,
      );
      return response.data;
    } catch (error) {
      logger.error(
        `Error when trying to reverse project for project total=${projectIDs.length}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async getOrgunitSystemSizesByYear(year: string): Promise<U.Nullable<number>> {
    logger.debug('Trying to get sum of all system sizes of year=%s', year);
    try {
      if (await this.nonTrader()) return 0;

      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<number> = await this.api.get(
        getPaths(this.getBasePath()).GetSumOfSystemSizesPath(year),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got sum of system sizes of year=%s', year);
      return data;
    } catch (error) {
      logger.error(
        'Error when retrieving sum of system sizes, error=%O',
        error,
      );
      return null;
    }
  }

  async buildFileDownloaUrl(
    file: FileJSON,
    projectID: number,
    accessToken: string,
  ): Promise<string> {
    const path = getPaths(this.getBasePath()).GetProjectFilePath(
      projectID,
      file.fileID,
      accessToken,
    );
    return `${contextualConfig.api.v2.baseURI}${path}`;
  }

  async downloadProductsReportingXlsPath(
    projectID: number,
    accessToken: string,
    language: LanguageCode,
  ): Promise<string> {
    const path = getPaths(this.getBasePath()).DownloadProductsReportingXlsPath(
      projectID,
      accessToken,
      language,
    );
    return `${contextualConfig.api.v2.baseURI}${path}`;
  }

  async downloadAvailableProductsXlsPath(
    projectID: number,
    accessToken: string,
    language: LanguageCode,
  ): Promise<string> {
    const path = getPaths(this.getBasePath()).DownloadAvailableProductsXlsPath(
      projectID,
      accessToken,
      language,
    );
    return `${contextualConfig.api.v2.baseURI}${path}`;
  }

  async downloadProjects({
    language,
    request,
  }: {
    language: string;
    request: ProjectRequestObject;
  }): Promise<Blob> {
    logger.debug('Trying to download projects by request=%O', request);
    try {
      await this.authenticationService.ensureLogin(
        ProjectsService.name,
        this.getProjects.name,
      );
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Blob> = await this.api.post(
        getPaths(this.getBasePath()).DownloadProjectsPath(language),
        request,
        {
          headers,
        },
      );
      logger.info(`Successfully downloaded projects`);
      return response.data;
    } catch (error) {
      logger.error('Error when downloading projects, error=%O', error);
      throw error;
    }
  }
}

export default ProjectsService;
