import { Pagination } from '@mmw/services-core-common/types';
import Fuse, { FuseSearchOptions } from 'fuse.js';
import { find, flatMap, isEmpty, map, slice, trim } from 'lodash';

import { TraderJSON, TraderSearchRequestObject } from './types';
import { addServiceAndCategoryNames, filterStoresByCategories } from './utils';

const DEFAULT_FUZZY_OPTIONS = {
  includeScore: true,
  threshold: 0.45,
  keys: [
    {
      name: 'displayname',
      weight: 0.4,
    },
    {
      name: 'categoriesNames',
      weight: 0.2,
    },
    {
      name: 'servicesNames',
      weight: 0.1,
    },
    {
      name: 'address.street',
      weight: 0.2,
    },
    {
      name: 'address.nr',
      weight: 0.1,
    },
  ],
};

function performFuzySearch(
  currentStores: Array<TraderJSON>,
  fullText: string,
  fuzzySearchOptions?: FuseSearchOptions = DEFAULT_FUZZY_OPTIONS,
): Promise<Array<number>> {
  return new Promise(resolve => {
    if (isEmpty(fullText)) {
      resolve(map(currentStores, 'orgunitID'));
      return;
    }
    const fuse = new Fuse(currentStores, {
      ...fuzzySearchOptions,
    });
    setTimeout(() => {
      const results = fuse.search(fullText);
      return resolve(map(flatMap(results, 'item'), 'orgunitID'));
    }, 0);
  });
}

const fuzzySearchTraders = async (
  request: TraderSearchRequestObject,
  stores: Array<TraderJSON>,
  fuzzySearchOptions?: FuseSearchOptions,
): Promise<Pagination<TraderJSON>> => {
  const { fullText, categories, offset = 0, limit = 20 } = request;
  const currentStores = filterStoresByCategories(stores, categories);
  const filledStores = addServiceAndCategoryNames(currentStores);
  // XXX: we work with ids, to not cause re-render, due to `filledStores` changing everytime
  const foundStoresIds = await performFuzySearch(
    filledStores,
    trim(fullText),
    fuzzySearchOptions,
  );
  const slicedStoresIds = slice(foundStoresIds, offset, offset + limit);
  const slicedStores = map(slicedStoresIds, storeId =>
    find(currentStores, s => s.orgunitID === storeId),
  );
  const allStores = map(foundStoresIds, storeId =>
    find(currentStores, s => s.orgunitID === storeId),
  );
  return {
    limit,
    offset,
    total: foundStoresIds.length,
    list: slicedStores,
    fullList: allStores,
  };
};

export default fuzzySearchTraders;
