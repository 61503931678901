const namespace = 'oralb';
const signUpNamespace = 'signup';
const salesTypeNamespace = 'salesType';
const productInfoNamespace = 'productInfo';
const auditNamespace = 'audit';

const AUDIT = {
  ID: {
    key: `${namespace}.${auditNamespace}.id`,
    message: 'Audit ID',
  },
  PARTICIPATION: {
    key: `${namespace}.${auditNamespace}.participation`,
    message: 'Audit Participation',
  },
  AUDITS: {
    key: `${namespace}.${auditNamespace}.audits`,
    message: 'Audits',
  },
};

const SIGNUP_PAGE = {
  WELCOME: {
    TITLE: {
      key: `${namespace}.${signUpNamespace}.welcomeTitle`,
      message: '<strong>Sehr geehrter Handelspartner,</strong>',
    },
    TITLE_DESCRIPTION: {
      key: `${namespace}.${signUpNamespace}.titleDescription`,
      message:
        'vielen Dank für Ihr Interesse an der neuen <strong>Oral-B iO</strong>.',
    },
    DESCRIPTION: {
      key: `${namespace}.${signUpNamespace}.description`,
      message:
        'Im Folgenden informieren wir Sie über die Voraussetzungen für den direkten Warenbezug der <strong>Oral-B iO</strong> über <strong>Procter & Gamble.</strong>',
    },
    DESCRIPTION_2: {
      key: `${namespace}.${signUpNamespace}.description2`,
      message:
        'Um eine erfolgreiche Einführung zu ermöglichen, hat <strong>Procter & Gamble</strong> eine Liste von spezifischen Kriterien zu Fähigkeiten von Einzelhändlern festgelegt (Kriterien-basierte Distribution). Die Kriterien sind für 12 Monate festgelegt, beginnend mit der Einführung am 20. Juli 2020. Die Kriterien beziehen sich auf die Fähigkeiten eines jeden Händlers, Konsumenten das richtige Maß an Aufklärung, ein immersives Erlebnis sowie personalisierte Dienstleistungen anzubieten. Infolgedessen werden wir die <strong>Oral-B iO</strong> in der Einführungsphase ausschließlich an Händler verkaufen, die diese Kriterien erfüllen, da diese entscheidend für die erfolgreiche Einführung der neun Technologie sind.',
    },
    DESCRIPTION_3: {
      key: `${namespace}.${signUpNamespace}.description3`,
      message:
        'Bitte melden Sie sich hier unter Angabe Ihrer E-Mail Adresse an, um den Qualifizierungsprozess für den Direktbezug der <strong>Oral-B iO</strong> über <strong>Procter & Gamble</strong> zu starten.',
    },
  },
  LOGIN_DISCLAIMER: {
    key: `${namespace}.${signUpNamespace}.loginDisclaimer`,
    message:
      'Bitte beachten Sie, dass nur Gewerbliche Sanitärbetriebe mit Eintrag in der Handwerksrolle und deren Mitarbeiter an der Aktion teilnahmeberechtigt sind.',
  },
  SECURITY_TOKEN: {
    key: `${namespace}.${signUpNamespace}.securityToken`,
    message:
      'Bitte geben Sie das Sicherheitstoken ein, das an Ihre E-Mail gesendet wurde',
  },
  INFORM_EMAIL_LABEL: {
    key: `${namespace}.${signUpNamespace}.informEmailLabel`,
    message: 'Geben Sie Ihre E-Mail Adresse ein',
  },
  INFORM_PASSWORD_LABEL: {
    key: `${namespace}.${signUpNamespace}.informPasswordLabel`,
    message: 'Geben Sie Ihr Passwort ein',
  },
  COMPLETE_YOUR_REGISTRATION: {
    key: `${namespace}.${signUpNamespace}.completeYourRegistration`,
    message: 'Bitte geben Sie hier die Daten Ihres Unternehmens ein',
  },
  MODAL: {
    MODAL_TITLE: {
      key: `${namespace}.${signUpNamespace}.modalTitle`,
      message:
        'Bitte stellen Sie sicher, dass die eingegebenen Daten korrekt sind.',
    },
    MODAL_BUTTONS: {
      CONFIRM: {
        key: `${namespace}.${signUpNamespace}.modalConfirmButton`,
        message: 'Confirm',
      },
      CANCEL: {
        key: `${namespace}.${signUpNamespace}.modalCancelButton`,
        message: 'Cancel',
      },
    },
  },
};

const SALES_TYPE_PAGE = {
  TITLE: {
    key: `${namespace}.${salesTypeNamespace}.title`,
    message: 'Wir möchten Sie besser kennenlernen.',
  },
  QUESTION: {
    key: `${namespace}.${salesTypeNamespace}.description`,
    message: 'Über welchen Vertiebskanal möchten Sie die Oral-B iO Verkaufen?',
  },
  CHECKBOX: {
    ONLINE: {
      key: `${namespace}.${salesTypeNamespace}.checkboxOnline`,
      message: 'Online',
    },
    OFFLINE: {
      key: `${namespace}.${salesTypeNamespace}.checkboxOffline`,
      message: 'Offline',
    },
  },
};

const PRODUCT_INFO_PAGE = {
  DESCRIPTION: {
    key: `${namespace}.${productInfoNamespace}.description`,
    message:
      '<strong>Oral-B</strong> ist der Innovationsführer in der Mundpflege, mit über 60 Jahren Erfahrung im Bereich der innovativen Mundhygiene. Mittlerweile ist hinlänglich bekannt, dass die Benutzung von elektrischen Zahnbürsten die Mundgesundheit von Konsumenten deutlich verbessert. Unsere Mission ist es seit jeher, elektrische Zahnbürsten als Standard des Zähneputzens zu etablieren, indem wir Konsumenten über die Vorteile des elektrischen Putzens aufklären und weiterhin an Innovationen arbeiten, welche die Mundgesundheit kontinuierlich verbessern.',
  },
  DESCRIPTION_2: {
    key: `${namespace}.${productInfoNamespace}.description2`,
    message:
      'Im Sommer 2020 werden wir die <strong>größte Oral-B Innovation der letzten 20 Jahre</strong> auf den Markt bringen: Die neue <strong>Oral-B iO</strong> – die erste elektrische Zahnbürste von Oral-B mit Magnetantrieb. Die neue Zahnbürste bietet Konsumenten – klinisch bewiesen – bestmögliche Plaque Entfernung, Gingivitis Reduktion und eine Verbesserung der Zahnfleischgesundheit im Vergleich zu herkömmlichen Handzahnbürsten und anderen elektrischen Zahnbürsten auf dem Markt. Die neue <strong>Oral-B iO</strong> unterscheidet sich grundlegend von bereits auf dem Markt erhältlichen elektrischen Zahnbürsten:',
  },
  PRODUCT_INFO: {
    ITEM_1: {
      key: `${namespace}.${productInfoNamespace}.productInfoItem1`,
      message:
        '<strong>1: Neue Technologie,</strong> das erste magnetische Antriebssystem mit neuartigen Mikrovibrationen gegenüber einem herkömmlichen mechanischen Antrieb;',
    },
    ITEM_2: {
      key: `${namespace}.${productInfoNamespace}.productInfoItem2`,
      message:
        '<strong>2: Neue intelligente Andruckkontrolle,</strong> die bei optimalem Druck grün und bei zu starkem Druck not leuchtet;',
    },
    ITEM_3: {
      key: `${namespace}.${productInfoNamespace}.productInfoItem3`,
      message:
        '<strong>3: Neues interaktives Display,</strong> das persönliches Echtzeitfeedback gibt;',
    },
    ITEM_4: {
      key: `${namespace}.${productInfoNamespace}.productInfoItem4`,
      message:
        '<strong>4: Neue künstliche Intelligenz,</strong> die 16 Zonen im Mundraum Iokalisiert und analysiert, um Hilfestellung für ein gründlicheres Putzen zu bieten;',
    },
    ITEM_5: {
      key: `${namespace}.${productInfoNamespace}.productInfoItem5`,
      message:
        '<strong>5: Neue App,</strong> die sich mit der Zahnbürste verbindet und zeitgleich Feedback zur Putztechnik liefert;',
    },
    ITEM_6: {
      key: `${namespace}.${productInfoNamespace}.productInfoItem6`,
      message:
        '<strong>6: Neue innovative Aufsteckbürsten</strong> mit dichter Borstenanordnung sorgen dank einer Überlagerung von konkaven und konvexen Borstenformen für eine verbesserte Reinigung.',
    },
  },
};

const I18N = {
  SIGNUP_PAGE,
  SALES_TYPE_PAGE,
  PRODUCT_INFO_PAGE,
  AUDIT,
};

export default I18N;
