import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';

import createFromProps from '../create-from-props';
import { FromProps } from '../types';

const applyFlex: F.Function<[Theme, number]> & FromProps = (
  theme,
  flex: number,
) => {
  if (flex) return `flex: ${flex}`;
  return '';
};
applyFlex.fromProps = createFromProps(applyFlex, 'f');

export default applyFlex;
