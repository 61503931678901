import { css } from '@ui-system/css';
import {
  DATE_ICON_UUID,
  DATE_INPUT_UUID,
  DATE_TEXT_UUID,
  DatePickerProps,
} from '@ui-system/interfaces';
import { iPadFactor } from '@ui-system/media-query';
import pxPerfect from '@ui-system/px-perfect';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useDateInputDefaultStyle = makeStyle<{ disabled: boolean }>(
  ({ props, theme }) => css`
    text-align: center;
    opacity: ${props?.disabled ? 0.4 : 1};
    min-height: ${pxPerfect(40)};
    color: ${theme.typography.colors[theme.mode].onBackground};
  `,
);

const useDateValueDefaultStyle = makeStyle<Partial<DatePickerProps>>(
  ({ theme, props }) => css`
    color: ${props?.placeholderI18n
      ? theme.colors[theme.mode].gray.A500
      : theme.typography.colors[theme.mode].onBackground};
    font-family: ${theme.typography.fontFamily.regular};
    font-size: ${pxPerfect(iPadFactor(18))};
  `,
);

const useDefaultDateIconStyle = makeStyle<{ disabled: boolean }>(
  ({ theme, props }) => css`
    color: ${props?.disabled
      ? theme.colors[theme.mode].gray.A200
      : theme.colors[theme.mode].white};
    opacity: ${props?.disabled ? 0.4 : 1};
  `,
);

export const useDateInputStyle = makeFinalStyleHook<Partial<DatePickerProps>>(
  DATE_INPUT_UUID,
  useDateInputDefaultStyle,
);

export const useDateIconStyle = makeFinalStyleHook<Partial<DatePickerProps>>(
  DATE_ICON_UUID,
  useDefaultDateIconStyle,
);

export const useDateValueStyle = makeFinalStyleHook<Partial<DatePickerProps>>(
  DATE_TEXT_UUID,
  useDateValueDefaultStyle,
);
