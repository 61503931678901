import { I18nShape } from '@mmw/constants-i18n/types';

const NAMESPACE = 'audit';

export const AUDIT = {
  message: 'audit',
  key: `${NAMESPACE}.audit`,
  IMAGE: {
    message: 'image audit',
    key: `${NAMESPACE}.audit.image`,
  },
  SELECT_IMAGE: {
    message: 'Select an image to audit',
    key: `${NAMESPACE}.audit.selectImage`,
  },
};
export const AUDITS = {
  message: 'audits',
  key: `${NAMESPACE}.audits`,
};
export const AUDIT_STATUS: I18nShape = {
  message: 'audit status',
  key: `${NAMESPACE}.auditStatus`,
};
export const DATE: I18nShape = {
  message: 'Date',
  key: `${NAMESPACE}.date`,
};
export const NO_AUDITS_REGISTERED: I18nShape = {
  message: 'no audit registered',
  key: `${NAMESPACE}.noAuditRegistered`,
};
const TICKET_NAMESPACE = `${NAMESPACE}.ticket`;
export const TICKET = {
  TODO: {
    message: 'todo',
    key: `${TICKET_NAMESPACE}.status.todo`,
  },
  IN_PROGRESS: {
    message: 'in progress',
    key: `${TICKET_NAMESPACE}.status.inProgress`,
  },
  CLOSED: {
    message: 'closed',
    key: `${TICKET_NAMESPACE}.status.closed`,
  },
};

const I18N = {
  AUDIT,
  AUDITS,
  DATE,
  NO_AUDITS_REGISTERED,
  TICKET,
};

export default I18N;
