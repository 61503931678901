import { languageQuery } from '@mmw/utils-query-utils';
import qs from 'qs';

const BASE_PATH = '/v1/utils';

export const GetCountriesPath = (language: string): string =>
  `${BASE_PATH}/countries?${languageQuery(language)}`;

export const GetLanguagesPath = (language: string): string =>
  `${BASE_PATH}/languages?${languageQuery(language)}`;

export const GetSalesTypesPath = (language: string): string =>
  `${BASE_PATH}/salestypes?${languageQuery(language)}`;

export const GetSalutationsPath = (language: string): string =>
  `${BASE_PATH}/salutations?${languageQuery(language)}`;

export const GetTitlesPath = (language: string): string =>
  `${BASE_PATH}/titles?${languageQuery(language)}`;

export const ValidateZipcodePath = (zipcode: string, country: string): string =>
  `${BASE_PATH}/validate/zipcode?${qs.stringify({
    zipcode,
    country,
  })}`;

export const ValidateAbnPath = (abn: string, orgunitID: string): string =>
  `${BASE_PATH}/validate-abn/${abn}?${qs.stringify({
    excludeOrgunitID: orgunitID,
  })}`;

export const GenerateQRCodePath = (url: string): string =>
  `${BASE_PATH}/generate-qrcode?${qs.stringify({
    url: encodeURIComponent(url),
  })}`;
