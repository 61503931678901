/* eslint-disable no-param-reassign */
import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import { BadGetawayError } from '@mmw/common-api-client/errors';
import { EN, LanguageCode } from '@mmw/constants-languages';
import contextualConfig from '@mmw/contextual-config';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import { Pagination } from '@mmw/services-core-common/types';
import autoBind from 'auto-bind';
import { isEmpty, trim } from 'lodash';
import SqlString from 'sqlstring';
import googleTranslate from 'translate';

import {
  GetResourcesListPath,
  GetResourcesPath,
  SaveResourcePath,
  TranslatePath,
} from './apiPaths';
import logger from './log';
import { ResourceListJSON, Translations } from './types';

type Api = typeof defaultApiV2;

type AdminResourceServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class AdminResourceService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: AdminResourceServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getResource(
    // XXX: for default resource stored, NULL
    language: LanguageCode | null | undefined,
    resourcekey: string,
    bundleName: string,
    secondaryBundleName: string | null = null,
  ): Promise<Pagination<ResourceListJSON>> {
    logger.info('Trying to get resource by resourcekey=%s', resourcekey);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<ResourceListJSON>> =
        await this.api.get(
          GetResourcesPath(language, resourcekey, bundleName),
          {
            headers,
          },
        );
      logger.info('Successfully got resource by resourcekey=%s', resourcekey);
      if (response.data.total === 0 && secondaryBundleName) {
        const secondaryResponse: ApiResponse<Pagination<ResourceListJSON>> =
          await this.api.get(
            GetResourcesPath(language, resourcekey, secondaryBundleName),
            {
              headers,
            },
          );
        return secondaryResponse.data;
      }
      return response.data;
    } catch (error) {
      logger.error('Error when getting resource, error=%O', error);
      throw error;
    }
  }

  async getResources(
    resourcekey: string,
    bundleName: string,
    secondaryBundleName: string | null = null,
  ): Promise<Pagination<ResourceListJSON>['list']> {
    logger.info('Trying to get resources by resourcekey=%s', resourcekey);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<ResourceListJSON>> =
        await this.api.get(GetResourcesListPath(resourcekey, bundleName), {
          headers,
        });
      logger.info('Successfully got resource by resourcekey=%s', resourcekey);
      if (response.data.total === 0 && secondaryBundleName) {
        const secondaryResponse: ApiResponse<Pagination<ResourceListJSON>> =
          await this.api.get(
            GetResourcesListPath(resourcekey, secondaryBundleName),
            {
              headers,
            },
          );
        return secondaryResponse.data.list;
      }
      return response.data.list;
    } catch (error) {
      logger.error('Error when getting resource, error=%O', error);
      throw error;
    }
  }

  async hasResourceForKeyAndLanguage(
    resourcekey: string,
    language: LanguageCode | null, // XXX: for default resource stored, NULL
    bundleName: string,
    secondaryBundleName: string | null = null,
  ) {
    const resource = await this.getResource(language, resourcekey, bundleName);
    const secondaryResource = await this.getResource(
      language,
      resourcekey,
      secondaryBundleName || '',
    );
    return resource.total > 0 || secondaryResource.total > 0;
  }

  async tryTranslate(
    text: string,
    languageToFind: LanguageCode,
  ): Promise<string> {
    logger.info('Trying to find translation for resource=%s', text);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response = await this.api.post(
        TranslatePath(),
        {
          baseResource: text,
          languageToFind,
        },
        {
          headers,
        },
      );
      const result = response.data;
      logger.info('Trying to find translation for resource=%s', text, result);
      return result;
    } catch (error) {
      logger.error('Error when trying to translate, error=%O', error);
      throw error;
    }
  }

  async saveResource(
    id: number | null = null,
    bundleName: string,
    language: string | null,
    resource: string,
    resourcekey: string,
  ) {
    logger.info(
      'Trying to save resource by resourcekey=%s',
      resourcekey,
      id,
      bundleName,
      language,
      resource,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response = await this.api.post(
        SaveResourcePath(),
        {
          id,
          bundleName,
          language,
          resource,
          resourcekey,
        },
        {
          headers,
        },
      );
      logger.info('Successfully save resource by resourcekey=%s', resourcekey);
      return response.data;
    } catch (error) {
      logger.error('Error when saving resource, error=%O', error);
      throw error;
    }
  }

  generateLanguageTranslator(
    inputLanguage: LanguageCode,
    outputLanguage: LanguageCode,
    bundleName: string,
    allTranslations: Translations,
    secondaryBundleName?: string,
  ) {
    return async (message: string, key: string) => {
      allTranslations[key] = allTranslations[key] || {};

      // HANDLE DEFAULT HERE
      if (outputLanguage === inputLanguage) {
        let hasDefaultResource;
        hasDefaultResource = await this.hasResourceForKeyAndLanguage(
          key,
          null,
          bundleName,
        );
        if (secondaryBundleName && !hasDefaultResource) {
          hasDefaultResource = await this.hasResourceForKeyAndLanguage(
            key,
            null,
            secondaryBundleName,
          );
        }
        if (hasDefaultResource) {
          return;
        }
        allTranslations[key][outputLanguage] = message;
        return;
      }

      // HANDLE ACTUAL TRANSLATIONS HERE
      let hasResource;
      hasResource = await this.hasResourceForKeyAndLanguage(
        key,
        outputLanguage,
        bundleName,
      );
      if (secondaryBundleName && !hasResource) {
        hasResource = await this.hasResourceForKeyAndLanguage(
          key,
          outputLanguage,
          secondaryBundleName,
        );
      }
      if (hasResource) {
        logger.warn('Found resource for ', key, outputLanguage);
        return;
      }

      let result = null;
      try {
        result = await this.tryTranslate(message, outputLanguage);
      } catch (error) {
        if (error instanceof BadGetawayError) {
          try {
            result = await this.tryTranslate(message, outputLanguage);
          } catch (error2) {
            // do nothing
          }
        }
      }
      if (!isEmpty(result)) {
        allTranslations[key][outputLanguage] = result;
        return;
      }
      try {
        const googleResult =
          await AdminResourceService.translateOnGoogleTranslation(
            message,
            inputLanguage,
            outputLanguage,
          );
        if (!isEmpty(googleResult)) {
          allTranslations[key][outputLanguage] = googleResult;
          return;
        }
        logger.warn(
          'Couldnt find google translation for ',
          key,
          outputLanguage,
        );
      } catch (error) {
        logger.error('Error translation google ', key, outputLanguage);
      }
    };
  }

  static createSqlInsert(
    message: string,
    key: string,
    language: LanguageCode | null,
    bundleName: string,
  ) {
    const lang = language ? `'${language}'` : 'NULL';
    return trim(
      'insert into resource (BUNDLE_NAME,RESOURCEKEY,LANGUAGE,BRAND,CONSUMER,RESOURCE,HTML)' +
        ` values ('${bundleName}','${trim(
          key,
        )}', ${lang}, NULL, '0', ${SqlString.escape(trim(message))},'0');\n`,
    );
  }

  static createSqlUpdate(
    message: string,
    key: string,
    language: LanguageCode | null | void,
    bundleName: string,
  ) {
    const lang = language ? `'${language}'` : 'NULL';
    if (language) {
      return trim(
        `update resource SET RESOURCE = ${SqlString.escape(
          trim(message),
        )} WHERE RESOURCEKEY = '${key}' AND BUNDLE_NAME = '${bundleName}' AND LANGUAGE = '${lang}';\n`,
      );
    }
    return trim(
      `update resource SET RESOURCE = ${SqlString.escape(
        trim(message),
      )} WHERE RESOURCEKEY = '${key}' AND BUNDLE_NAME = '${bundleName}' AND LANGUAGE is null;\n`,
    );
  }

  static getGoogleTranslateApiKey() {
    const config = contextualConfig.googleTranslate;
    return config ? config.apikey : null;
  }

  static async translateOnGoogleTranslation(
    message: string,
    from: LanguageCode,
    to: LanguageCode,
  ) {
    return googleTranslate(message, {
      to,
      from,
      engine: 'google',
      key: AdminResourceService.getGoogleTranslateApiKey(),
    });
  }

  static createSQLInserts(translations: Translations, bundleName: string) {
    const SQLS = [];
    const resourceKeys = Object.keys(translations);
    resourceKeys.forEach(key => {
      const translatedLanguageCodes = Object.keys(translations[key]);
      translatedLanguageCodes.forEach(translatedLanguageCode => {
        const translatedMessage = translations[key][translatedLanguageCode];
        SQLS.push(
          // @ts-ignore
          AdminResourceService.createSqlInsert(
            translatedMessage,
            key,
            translatedLanguageCode === EN ? null : translatedLanguageCode,
            bundleName,
          ),
        );
      });
    });
    return SQLS;
  }
}

export default AdminResourceService;
