import { ACCOUNT as FIELDS } from '@mmw/constants-fields-names';
// import {
//   ACCOUNT as VALIDATIONS,
// } from '@mmw/constants-fields-validations';
import { PATTERN } from '@mmw/constants-validation-names';

const namespace = 'validation';

const ACCOUNT = {
  ACCOUNTOWNER: {},
  ACCOUNT: {},
  AGENCY: {},
  BIC: {},
  IBAN: {
    PATTERN: {
      key: `${namespace}.${FIELDS.IBAN}.${PATTERN}`,
      message: 'IBAN pattern is not valid for this country',
    },
  },
  COUNTRY: {},
  MANUALBANKNAME: {},
};

export default ACCOUNT;
