import { EMPTY_ARRAY, EMPTY_OBJECT } from '@mmw/constants-utils';
import updateImmutality from 'immutability-helper';
import {
  head,
  isEmpty,
  map,
  memoize,
  merge,
  pickBy,
  reduce,
  reduceRight,
  replace,
  split,
} from 'lodash';
import { O } from 'ts-toolbelt';

import logger from '../log';
import { FieldPath, ValidationError } from '../types';
import { FieldPathToValue, FormUpdate } from './types';

export const updateFieldPathCommand = (formChange: FormUpdate) => {
  if (isEmpty(formChange) || isEmpty(formChange.fieldPath)) {
    logger.error('Error, no field path informed', formChange);
    return EMPTY_OBJECT;
  }
  const change = {
    ...formChange,
    command: formChange.command || '$set',
  };
  const splitedFielPaths = split(change.fieldPath, '.');
  const comm = reduceRight(
    splitedFielPaths,
    (currCommands, fieldName) => ({ [fieldName]: currCommands }),
    { [change.command]: change.value },
  );
  logger.trace('Generated command', change, comm);
  return comm;
};

export const updateFieldPathsCommands = (changes: Array<FormUpdate>) => {
  const commandsArray = map(changes, updateFieldPathCommand);
  const commands = merge(...commandsArray);
  logger.trace('Generated update merged command', commands);
  return commands;
};

export const normalizeFieldPath = memoize((fieldPath: FieldPath): string => {
  const firstChar = head(fieldPath);
  let modPath = fieldPath;
  if (/\[/.test(firstChar)) {
    modPath = replace(fieldPath, /\[/g, '');
  } else {
    modPath = replace(fieldPath, /\[/g, '.');
  }
  modPath = replace(modPath, /\]/g, '');
  return modPath;
});

const fixErrorPath = memoize((error: ValidationError) => {
  const modifiedError = error;
  let { path } = modifiedError;
  if (/\["/.test(path) || /"]/.test(path)) {
    path = replace(replace(path, /\["/g, ''), /"]/g, '');
  }
  modifiedError.path = path;
  if (modifiedError?.params?.path) {
    modifiedError.params.path = path;
  }
  return modifiedError;
});

export function update<T>(currentValues: T, query: O.Object): T {
  try {
    return updateImmutality(currentValues, query);
  } catch (error) {
    logger.error('Error during update execution', error, currentValues);
    return currentValues;
  }
}

export const normalizeErrors = (
  errors: Array<ValidationError> | null | void,
  watchedFieldPaths?: FieldPathToValue<number>,
): FieldPathToValue<ValidationError> => {
  const currentErrors: FieldPathToValue<ValidationError> = reduce(
    errors || EMPTY_ARRAY,
    (obj, error: ValidationError) => {
      /**
       * XXX: Added this to fix a annomaly maybe caused by yup on the
       * Jura Manufacturer edit company form the brAddress paths were
       * wrongly set with brackets like "["brAddress.city"]"
       */
      const errorWithPathFixed = fixErrorPath(error);
      return {
        ...obj,
        [normalizeFieldPath(error.path)]: errorWithPathFixed,
      };
    },
    EMPTY_OBJECT,
  );

  if (!watchedFieldPaths) {
    return currentErrors;
  }
  const currentWatchedFields: FieldPathToValue<number> = pickBy(
    watchedFieldPaths,
    value => value > 0,
  );
  return pickBy(
    currentErrors,
    (value, key) => currentWatchedFields[key] != null,
  );
};

export const normalizeTouchedFields = (
  changes: Array<FormUpdate> | null | void,
) => {
  const newTouchedFields: FieldPathToValue<boolean> = reduce(
    changes || EMPTY_ARRAY,
    (obj, change) => ({
      ...obj,
      [normalizeFieldPath(change.fieldPath)]: true,
    }),
    EMPTY_OBJECT,
  );
  return newTouchedFields;
};
