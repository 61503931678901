import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';
import isString from 'lodash/isString';

import createFromProps from '../create-from-props';
import { FromProps } from '../types';

export const applyBorderRadius: F.Function<[Theme, number | string]> &
  FromProps = (theme, radius: number | string) => {
  if (!radius) return '';
  if (isString(radius)) {
    return `
    borderRadius: ${radius};
    overflow: hidden;
  `;
  }
  return `
    borderRadius: ${radius}px;
    overflow: hidden;
  `;
};

applyBorderRadius.fromProps = createFromProps(
  applyBorderRadius,
  'borderRadius',
);

export const applyBorderBottomRightRadius: F.Function<[Theme, number]> &
  FromProps = (theme, radius: number) => {
  if (!radius) return '';
  return `
    borderBottomRightRadius: ${radius}px;
    overflow: hidden;
  `;
};

applyBorderBottomRightRadius.fromProps = createFromProps(
  applyBorderBottomRightRadius,
  'borderBottomRightRadius',
);

export const applyBorderBottomLeftRadius: F.Function<[Theme, number]> &
  FromProps = (theme, radius: number) => {
  if (!radius) return '';
  return `
    borderBottomLeftRadius: ${radius}px;
    overflow: hidden;
  `;
};

applyBorderBottomLeftRadius.fromProps = createFromProps(
  applyBorderBottomLeftRadius,
  'borderBottomLeftRadius',
);

export const applyBorderTopRightRadius: F.Function<[Theme, number]> &
  FromProps = (theme, radius: number) => {
  if (!radius) return '';
  return `
    borderTopRightRadius: ${radius}px;
    overflow: hidden;
  `;
};

applyBorderTopRightRadius.fromProps = createFromProps(
  applyBorderTopRightRadius,
  'borderTopRightRadius',
);

export const applyBorderTopLeftRadius: F.Function<[Theme, number]> &
  FromProps = (theme, radius: number) => {
  if (!radius) return '';
  return `
    borderTopLeftRadius: ${radius}px;
    overflow: hidden;
  `;
};

applyBorderTopLeftRadius.fromProps = createFromProps(
  applyBorderTopLeftRadius,
  'borderTopLeftRadius',
);
