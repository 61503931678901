import { ThemeMode } from '../colors';
import { ThemeTypography, TypographyColors } from './interfaces';

export const BASE_TYPOGRAPHY_COLORS: TypographyColors = {
  [ThemeMode.LIGHT]: {
    onPrimary: '#fff',
    onSecondary: '#000',
    onBackground: '#000',
    placeholder: '#555555',
  },
  [ThemeMode.DARK]: {
    onPrimary: '#fff',
    onSecondary: '#fff',
    onBackground: '#fff',
    placeholder: '#555555',
  },
};

const BASE_TYPOGRAPHY: ThemeTypography = {
  fontFamily: {
    regular: "'Arial', Regular",
    bold: "'Arial', Bold",
    italic: "'Arial', Italic",
    thin: "'Arial', Light",
    boldItalic: "'Arial', BoldItalic",
    thinItalic: "'Arial', LightItalic",
  },
  colors: BASE_TYPOGRAPHY_COLORS,
};

export default BASE_TYPOGRAPHY;
