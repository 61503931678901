import { RootComponentsProvider } from '@ui-system/components-provider';
import { RootThemeProvider } from '@ui-system/css';
import { Components } from '@ui-system/interfaces';
import { Theme } from '@ui-system/theme';
import * as React from 'react';

export type Props = React.PropsWithChildren<{
  theme: Theme;
  components: Components;
}>;

export const UiSystemProvider: React.FC<Props> = ({
  children,
  theme,
  components,
}: Props) => {
  const { ThemeProvider } = components;
  return (
    <ThemeProvider theme={theme}>
      <RootComponentsProvider components={components}>
        <RootThemeProvider theme={theme}>{children}</RootThemeProvider>
      </RootComponentsProvider>
    </ThemeProvider>
  );
};
