import _ from 'lodash';
import { HOC, withPropsOnChange } from 'recompose';

const toArray = (value: Array<string> | string | void): Array<string> => {
  if (!value) {
    return [];
  }

  return _.isArray(value) ? value : [value];
};

type EnhancedComponentProps = {
  modifiers?: string | Array<string>;
};

type Props = {
  modifiers?: string | Array<string>;
};

// P would be the component Props
export type WithModifier<P> = (
  defaultModifier: string | Array<string>,
  restrictedModifiers?: Array<string>,
) => HOC<unknown, P & EnhancedComponentProps>;

// TODO: WithModifier should receive type param from outside
const withModifier: WithModifier<unknown> = (
  defaultModifier,
  restrictedModifiers,
) =>
  withPropsOnChange(['modifiers'], ({ modifiers }: Props) => {
    const defaultMod = toArray(defaultModifier);
    const currentMod = toArray(modifiers);
    const currentNotConflictingModifiers = _.filter(
      currentMod,
      (element: string) =>
        !restrictedModifiers || !_.includes(restrictedModifiers, element),
    );
    const currentConflictingModifiers = _.filter(
      currentMod,
      (element: string) =>
        restrictedModifiers && _.includes(restrictedModifiers, element),
    );
    const useModifiers = currentConflictingModifiers.length
      ? currentConflictingModifiers
      : defaultMod;
    return {
      modifiers: [...useModifiers, ...currentNotConflictingModifiers],
    };
  });

export default withModifier;
