import { hasAny } from '@mmw/utils-array';
import { filter, find, isEmpty, map } from 'lodash';

import {
  getCategoriesNames,
  hasSubCategories,
  removeMainCategories,
} from './categories';
import { getServicesNames } from './services';
import { TraderJSON } from './types';

export function filterStoresByCategories(
  currentStores: Array<TraderJSON>,
  categories: Array<string>,
) {
  if (isEmpty(categories)) {
    return currentStores;
  }
  const testCategories = hasSubCategories(categories)
    ? removeMainCategories(categories)
    : categories;
  return filter(
    currentStores,
    store =>
      find(store.details, details =>
        hasAny(details.categories, testCategories),
      ) != null,
  );
}

function addServiceAndCategoryNamesToStore(store: TraderJSON) {
  if (!store.details[0]) {
    return store;
  }
  const details = store.details[0];
  return {
    ...store,
    categoriesNames: getCategoriesNames(details.categories),
    servicesNames: getServicesNames(details.services),
  };
}

export function addServiceAndCategoryNames(stores: Array<TraderJSON>) {
  return map(stores, addServiceAndCategoryNamesToStore);
}

export function getStoreGiftCardWebsite(trader: TraderJSON) {
  if (!trader.details[0]) {
    return null;
  }
  return trader.details[0].giftCards.website;
}

export function getStoreGiftCardEmail(trader: TraderJSON) {
  if (!trader.details[0]) {
    return null;
  }
  return trader.details[0].giftCards.email;
}
