import { REGISTRATION_ITEM as FIELDS } from '@mmw/constants-fields-names';
// import {
//   REGISTRATION_ITEM as VALIDATIONS,
// } from '@mmw/constants-fields-validations';
import { INVALID, PATTERN } from '@mmw/constants-validation-names';

const namespace = 'validation';

const REGISTRATION_ITEM = {
  SELECTION: {
    MAX_ONE_KIT_OR_NON_KIT_PRODUCTS: {
      key: `${namespace}.maxOneOrNonKitProducts`,
      message: 'You can only select one kit or a single product',
    },
    SINGLE_KIT_OR_MULTIPLE_PRODUCTS: {
      key: `${namespace}.singleKitOrMultipleProducts`,
      message: 'You can only select one kit or multiple products',
    },
  },
  SERIALNUMBER: {
    PATTERN_MUST_BE_VALID: {
      key: `${namespace}.${FIELDS.SERIALNUMBER}.${PATTERN}`,
      message: 'Serialnumber pattern must be valid',
    },
  },
  IMEI: {},
  GIVEN_EAN: {},
  COUPON: {
    COUPON_MUST_BE_VALID: {
      key: `${namespace}.${FIELDS.COUPON}.${INVALID}`,
      message: 'Coupon must be valid and available',
    },
  },
  PROMOTION_CODE: {},
};

export default REGISTRATION_ITEM;
