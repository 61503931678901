import { Container } from '@ui-system/common-container';
import { useImageStyle } from '@ui-system/default-styles/image';
import { ImageProps } from '@ui-system/interfaces-image';
import { usePropsByMediaQuery } from '@ui-system/media-query';
import { useMergedStyles } from '@ui-system/style';
import UI from '@ui-system/ui';
import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Image: React.FC<ImageProps> = ({
  src,
  source,
  preview,
  height,
  width,
  style,
  resizeMode,
  getImageSize,
  responsive,
  fallbackSrc,
  ...rest
}: ImageProps) => {
  const responsiveProps = usePropsByMediaQuery<ImageProps>(responsive);
  const props = useMemo(
    () => ({
      src,
      height,
      width,
      resizeMode,
      source,
      ...responsiveProps,
    }),
    [src, source, height, width, resizeMode, responsiveProps],
  );

  const imgRef = useRef<HTMLImageElement | null>(null);
  const imageStyle = useImageStyle(style as CSSProperties, '', null, {
    height: props.height,
    width: props.width,
    resizeMode: props.resizeMode,
  });

  useEffect(() => {
    const currentImage = imgRef?.current;
    if (currentImage && getImageSize) {
      getImageSize(
        currentImage.nextElementSibling?.clientWidth,
        currentImage.nextElementSibling?.clientHeight,
      );
    }
  }, [getImageSize]);
  const displayNoneStyle = useMergedStyles(imageStyle, { display: 'none' });

  if (!props.source && !props.src && preview) {
    return (
      <Container style={imageStyle} {...rest}>
        {/* XXX: This is due to react native accepting number, I think
        // @ts-ignore */}
        <img ref={imgRef} src={preview} alt="preview" style={imageStyle} />
      </Container>
    );
  }
  return (
    <Container style={imageStyle} {...rest}>
      <img
        ref={imgRef}
        src={props.source || (props.src as string) || fallbackSrc}
        style={displayNoneStyle}
        alt=""
      />
      <LazyLoadImage
        src={props.source || props.src}
        placeholder={<UI.Skeleton height={props.height} width={props.width} />}
        alt=""
        style={imageStyle}
        height={props.height}
        width={props.width}
        effect="blur"
        visibleByDefault
      />
    </Container>
  );
};

export default Image;
