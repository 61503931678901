import { text } from './align';
import color from './color';
import { decoration, style, transform, weight } from './font';

export default {
  ...text,
  ...style,
  ...transform,
  ...weight,
  ...decoration,
  ...color(),
};
