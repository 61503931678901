import { ThemeProvider } from '@emotion/react';
import { Theme } from '@ui-system/theme';
import React, { ReactNode } from 'react';

export { useTheme } from '@emotion/react';

interface Props {
  children: ReactNode;
  theme: Theme;
}

const RootThemeProvider: React.FC<Props> = ({ theme, children }: Props) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default RootThemeProvider;
