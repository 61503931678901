const labelNamespace = 'label';
// const placeholderNamespace = 'placeholder';

const CAMPAIGN = {
  CAMPAIGN: {
    LABEL: {
      key: `${labelNamespace}.campaign`,
      message: 'Campaign',
    },
  },
};

export default CAMPAIGN;
