import {
  FormControl,
  FormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core';
import { css } from '@ui-system/css';
import componentModifiers from '@ui-system/default-modifiers/form/checkbox';
import { useRadioGroupStyle } from '@ui-system/default-styles';
import { useTranslateFunction } from '@ui-system/deps';
import {
  Option,
  RadioGroupProps,
  RadioGroupType,
} from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import { map, toUpper } from 'lodash';
import React, { useCallback, useMemo } from 'react';

const Label = ({ option }) => {
  const translate = useTranslateFunction();
  const label = useMemo(
    () => toUpper(translate(option.labelI18n)),
    [option.labelI18n, translate],
  );

  return (
    <UI.Overline
      modifiers="grayA400"
      style={css`
        line-height: 16px;
        height: 16px;
        margin-left: 5px;
      `}
    >
      {label}
    </UI.Overline>
  );
};

export const RadioGroup: RadioGroupType = ({
  value,
  onChange,
  error,
  disabled,
  style,
  options,
  labelI18n,
  label,
  required,
  caption,
  ...props
}: RadioGroupProps) => {
  const handleChange = useCallback(
    event => {
      onChange(event.target.value);
    },
    [onChange],
  );
  const finalStyle = useRadioGroupStyle(style, '', componentModifiers);
  const translate = useTranslateFunction();
  const texts = useMemo(() => {
    const labelText = toUpper(translate(labelI18n));
    return {
      label: labelText,
      caption: translate(caption),
    };
  }, [caption, labelI18n, translate]);
  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      disabled={disabled}
      style={finalStyle}
    >
      {texts.label || required ? (
        <UI.Overline
          modifiers={`grayA400, ${error || texts.caption ? 'error' : ''}`}
          style={css`
            line-height: 16px;
            height: 16px;
            margin-left: 5px;
          `}
        >
          {`${texts.label || ''}${required ? ' * ' : ''}`}
        </UI.Overline>
      ) : null}
      <MuiRadioGroup value={value} onChange={handleChange} {...props}>
        {map(options, (option: Option) => (
          <FormControlLabel
            checked={value === option.value}
            key={option.value}
            value={option.value}
            control={<MuiRadio />}
            label={<Label option={option} />}
            disabled={option.disabled}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;
