import { browserName } from 'react-device-detect';

const EDGE = 'EDGE';
const IE = 'IE';

const isIEOrEDGE: boolean =
  IE === `${browserName.toUpperCase()}` ||
  EDGE === `${browserName.toUpperCase()}`;

export default isIEOrEDGE;
