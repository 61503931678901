import { Theme } from '@ui-system/theme';
import { F, U } from '@utils/ts';

import createFromProps from '../create-from-props';
import { FromProps, OpacityRange } from '../types';

const applyOpacity: F.Function<[Theme, U.Nullable<OpacityRange>]> &
  FromProps = (theme, opacity) => {
  if (opacity) return `opacity: ${opacity};`;
  return '';
};

applyOpacity.fromProps = createFromProps(applyOpacity, 'opacity');

export default applyOpacity;
