import { isDevelopment } from '@mmw/environment';
import logger from '@mmw/logging-logger';
import delay from 'lodash/delay';
import { F } from 'ts-toolbelt';
import { Container, Token } from 'typedi';

import { createDI } from './createDI';

const TOKENS = new Map<string, F.Function>();

export class BasePath<T extends string = string> {
  getBasePath: () => string;

  token: Token<T>;

  checkBasePath = () => {
    const hasPath = Container.has(this.token);
    if (!hasPath && isDevelopment()) {
      throw new Error(`basePath was not set for ${this.token.name}`);
    }
  };

  static createDI: typeof createDI = createDI;

  constructor(token: Token<T>) {
    this.token = token;
    this.getBasePath = function getBasePath() {
      this.checkBasePath();
      return Container.get(token);
    };
    if (this.token.name) {
      TOKENS.set(this.token.name, this.checkBasePath);
    }
  }
}

function checkBasePaths() {
  delay(() => {
    TOKENS.forEach((checker, key) => {
      logger.extend('dependency-injection').info('Will check DI for', key);
      checker();
    });
  }, 1000);
}
checkBasePaths();
