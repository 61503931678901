/* eslint-disable no-template-curly-in-string */
import DEFAULT from './default';

export const string = {
  min: DEFAULT.MIN.key, // uses ${min} param
  max: DEFAULT.MAX.key, // uses ${max} param
  email: DEFAULT.EMAIL.key,
  default: DEFAULT.INVALID.key,
  required: DEFAULT.REQUIRED.key,
  oneOf: DEFAULT.INVALID.key,
  notOneOf: DEFAULT.INVALID.key,
  notType: DEFAULT.INVALID.key,
};
export const number = {
  notType: DEFAULT.INVALID.key,
};

export const array = {
  min: DEFAULT.MIN_ITEMS.key,
  max: DEFAULT.MAX_ITEMS.key,
  default: DEFAULT.INVALID.key,
  required: DEFAULT.REQUIRED.key,
  oneOf: DEFAULT.INVALID.key,
  notOneOf: DEFAULT.INVALID.key,
  notType: DEFAULT.INVALID.key,
};

export const mixed = {
  default: DEFAULT.INVALID.key,
  required: DEFAULT.REQUIRED.key,
  oneOf: DEFAULT.INVALID.key,
  notOneOf: DEFAULT.INVALID.key,
  notType: DEFAULT.INVALID.key,
};

export const date = {
  default: DEFAULT.INVALID.key,
  required: DEFAULT.REQUIRED.key,
  notOneOf: DEFAULT.INVALID.key,
  notType: DEFAULT.INVALID.key,
};

// Object format reference: https://github.com/jquense/yup/blob/master/src/locale.js
export const yupValidations = {
  string,
  mixed,
  array,
  date,
  number,
};
