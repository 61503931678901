const countryNamespace = 'country';

const COUNTRIES = {
  PLACEHOLDER: {
    key: `${countryNamespace}.placeholder`,
    message: 'Select a country',
  },
};

export default COUNTRIES;
