import { SalesOrgBrandJSON } from '@mmw/services-core-user-profile';

export const GET_SALES_ORG_BRANDS_LIST_START =
  '@mmw/salesOrgBrands/GET_SALES_ORG_BRANDS_LIST_START';
export const GET_SALES_ORG_BRANDS_LIST_SUCCESS =
  '@mmw/salesOrgBrands/GET_SALES_ORG_BRANDS_LIST_SUCCESS';
export const GET_SALES_ORG_BRANDS_LIST_ERROR =
  '@mmw/salesOrgBrands/GET_SALES_ORG_BRANDS_LIST_ERROR';

export type GetSalesOrgBrandsListStartAction = {
  type: '@mmw/salesOrgBrands/GET_SALES_ORG_BRANDS_LIST_START';
};

export type GetSalesOrgBrandsListSuccessAction = {
  type: '@mmw/salesOrgBrands/GET_SALES_ORG_BRANDS_LIST_SUCCESS';
  payload: {
    list: SalesOrgBrandJSON[];
  };
};

export type GetSalesOrgBrandsListErrorAction = {
  type: '@mmw/salesOrgBrands/GET_SALES_ORG_BRANDS_LIST_ERROR';
  payload: {
    error: Error;
  };
};

export type State = {
  error: Error | null;
  loading: boolean;
  list: SalesOrgBrandJSON[] | null;
  map: {
    [number]: SalesOrgBrandJSON;
  };
};

export type Action =
  | GetSalesOrgBrandsListStartAction
  | GetSalesOrgBrandsListSuccessAction
  | GetSalesOrgBrandsListErrorAction;

export type Reducer = (state: State, action: Action) => State;
