import createStoreLocatorState, {
  getStoresSearchParams,
} from '@store-locator/store-creator';

export const {
  useSearchStoresByLocation,
  useIsLoadingStoresByLocationSearch,
  useStoresByLocationSearchError,
  useResetStoresByLocationSearch,
  useStoresByLocationList,
  useTotalStoresListByLocation,
  useOriginCoordinates,
  useSearchRquestRadius,
  useStoresByLocationSearch,
} = createStoreLocatorState(getStoresSearchParams());
