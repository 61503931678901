import { css } from '@ui-system/css';
import { VIDEO_UUID, VideoProps } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import { isReactNative } from 'is-react-native';
import isString from 'lodash/isString';

import { makeFinalStyleHook } from './internal';

const useVideoDefaultStyle = makeStyle<Partial<VideoProps>>(
  ({ props }) => css`
    height: ${isString(props.height)
      ? props.height
      : `${String(props.height)}px`};
    width: ${isString(props.width) ? props.width : `${String(props.width)}px`};
    ${isReactNative() ? '' : `object-fit: ${props.resizeMode};`};
    border: none;
  `,
);

export const useVideoStyle = makeFinalStyleHook<Partial<VideoProps>>(
  VIDEO_UUID,
  useVideoDefaultStyle,
);
