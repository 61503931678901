const namespace = 'alpha.emeaLandingPage';

const EMEA_LANDING_PAGE = {
  DOCUMENT_TITLE: {
    key: `${namespace}.documentTitle`,
    message: 'REC Alpha EMEA',
  },
  BACK_TO_PRO_PORTAL: {
    key: `${namespace}.backToProPortal`,
    message: 'Back to ProPortal',
  },
  YOUR_SOLAR: {
    key: `${namespace}.yourSolar`,
    message: 'Your solar',
  },
  COMPANY: {
    key: `${namespace}.company`,
    message: 'Company',
  },
  BANNER_TEXT: {
    key: `${namespace}.bannerText`,
    message: 'Drive your success with <strong>REC Alpha</strong>',
  },
  FIRST_SECTION: {
    TITLE: {
      key: `${namespace}.firstSection.title`,
      message: 'Be an REC Alpha Top-Performer',
    },
    SUBTITLE: {
      key: `${namespace}.firstSection.subtitle`,
      message:
        'Attention REC Certified Solar Professional installers, who want to reach new heights',
    },
    CONTENT: {
      key: `${namespace}.firstSection.content`,
      message:
        'Your chance to win your customized premium REC marketing package. Join the <strong>REC Alpha for Top-Performers campaign</strong> with the Intersolar Award winning panel.',
    },
  },
  CAMPAIGN: {
    SLOGAN: {
      key: `${namespace}.campaign.slogan`,
      message:
        'Drive your success with REC Alpha and win your <strong>customized REC marketing package!</strong>',
    },
    PERIOD: {
      key: `${namespace}.campaign.period`,
      message: 'Campaign period August 5 to October 31, 2020.',
    },
    CONTENT: {
      key: `${namespace}.campaign.content`,
      message:
        "Don't miss the chance to win 1 of 12 attractive prizes worth between 500 € and 1.000 €",
    },
  },
  HOW_DOES_IT_WORK: {
    TITLE: {
      key: `${namespace}.howDoesItWork.title`,
      message: 'How does it work?',
    },
    CONTENT1: {
      key: `${namespace}.howDoesItWork.content1`,
      message:
        "The <strong>top 3 installers</strong> per group in EMEA region with the highest total amount of <strong>registered REC Alpha</strong> systems within the campaign's period will win.",
    },
    CONTENT2: {
      key: `${namespace}.howDoesItWork.content2`,
      message:
        'To learn more about the countries and which group your company is part of, please check the <strong>Terms and Conditions</strong> of the sweepstake.',
    },
  },
  WHO_IS_ELIGIBLE: {
    TITLE: {
      key: `${namespace}.whoIsEligible.title`,
      message: 'Who is eligible?',
    },
    CONTENT: {
      key: `${namespace}.whoIsEligible.content`,
      message:
        'All REC Certified Solar Professional installers in Europe, sourcing their REC panels from REC Platinum Partners or REC Authorized Distributors or directly from REC Solar EMEA GmbH.',
    },
  },
  HOW_TO_PARTICIPATE: {
    TITLE: {
      key: `${namespace}.howToParticipate.title`,
      message: 'How to participate?',
    },
    CONTENT: {
      key: `${namespace}.howToParticipate.content`,
      message:
        'No additional Registration Required. All you need is to install REC Alpha panels and register the systems in the REC ProPortal or in the REC SunSnap app.',
    },
    LIST_ITEM1: {
      key: `${namespace}.howToParticipate.listItem1`,
      message:
        '1. Go to the <a href="https://recgroup.zinfi.net/concierge/ucm/#/ucm/2052/content">Module Registration Page</a> and click on the REC Alpha Registration as usual.',
    },
    LIST_ITEM2: {
      key: `${namespace}.howToParticipate.listItem2`,
      message:
        '2. During registration, you will be asked if you want to include the system for your participation in the sweepstakes. Say Yes.',
    },
    LIST_ITEM3: {
      key: `${namespace}.howToParticipate.listItem3`,
      message: "3. Accept the T&C in the Pop-Up and you're done!",
    },
    LIST_ITEM4: {
      key: `${namespace}.howToParticipate.listItem4`,
      message:
        '2. So check out the REC ProPortal and SunSnap App and learn all details of',
    },
    BOTTOM_LINE: {
      key: `${namespace}.howToParticipate.bottomLine`,
      message: 'We want you to be one of the winners and wish you success!',
    },
  },
  LINKS: {
    REGISTER_REC_ALPHA_SYSTEMS: {
      key: `${namespace}.links.registerRecAlphaSystems`,
      message: 'REGISTER REC ALPHA SYSTEMS',
    },
    TERMS_AND_CONDITIONS: {
      key: `${namespace}.links.terms&Conditions`,
      message: 'TERMS & CONDITIONS',
    },
    CHECK_TOP_10_OF_YOUR_REGION: {
      key: `${namespace}.links.checkTop10OfYourRegion`,
      message: 'CHECK TOP-10 OF YOUR REGION',
    },
    GET_STARTED: {
      key: `${namespace}.links.getStarted`,
      message: 'Get started',
    },
    COMING_SOON: {
      key: `${namespace}.links.comingSoon`,
      message: 'Coming soon',
    },
  },
  BEFORE_FOOTER: {
    TITLE: {
      key: `${namespace}.beforeFooter.title`,
      message: 'Experience Alpha Performance',
    },
    CONTENT1: {
      key: `${namespace}.beforeFooter.content1`,
      message: 'The "REC Alpha for Top Performers" sweepstake is organized by',
    },
    CONTENT2: {
      key: `${namespace}.beforeFooter.content2`,
      message: 'REC Solar EMEA GmbH, Leopoldstr. 175, 80804 Munich.',
    },
  },
  FOOTER: {
    COPYRIGHT: {
      key: `${namespace}.footer.copyright`,
      message: 'Copyright © REC Solar Holdings AS',
    },
    CONTACT_REC: {
      key: `${namespace}.footer.contactRec`,
      message: 'Contact REC',
    },
    PRIVACY_POLICE: {
      key: `${namespace}.footer.privacyPolice`,
      message: 'Privacy Police',
    },
    TERMS_OF_USE: {
      key: `${namespace}.footer.termsOfUse`,
      message: 'Terms of use',
    },
  },
};

const I18N = {
  EMEA_LANDING_PAGE,
};

export default I18N;
