const PERSON = {
  DOMKEYSALUTATION: 'domkeysalutation',
  DOMKEYTITLE: 'domkeytitle',
  FIRSTNAME: 'firstname',
  LASTNAME: 'lastname',
  EMAIL: 'email',
  ERP_CONSUMER: 'erp_personkey',
  COMPANY: 'company',
  COMPANY_NAME: 'companyname',
  STATE: 'state',
  PHONE: 'phone',
  MOBILE_PHONE: 'phonemobile',
  BIRTHDATE: 'birthdate',
};

const CONSUMER = {
  ERP_CONSUMER: 'erpConsumer', // orgunitconsumers relationship table
  CONSUMER_NAME: 'consumerName',
  MEMBERNUMBER: 'membernumber',
  TYPE: 'type',
  TYPE_BUSINESS: 'business',
  TYPE_PRIVATE: 'private',
};

const ADDRESS = {
  COMPANY: 'company',
  STREET: 'street',
  NR: 'nr',
  COMPLEMENT: 'complement',
  ZIPCODE: 'zipcode',
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
  NAME1: 'name1',
  NAME2: 'name2',
  NUMBER: 'number',
};

const COMPANY = {
  COMPANY: 'company',
  FAX: 'fax',
  PHONE: 'phone',
  SALESTAXID: 'salestaxid',
  TAXNUMBER: 'taxnumber',
  HOMEPAGE: 'homepage',
  CRNUMBER: 'crnumber',
  CRCITY: 'crcity',
  EMAIL: 'email',
  LEGAL_FORM: 'legalform',
};

const COMMON = {
  LANGUAGE: 'language',
  EMAIL: 'email',
};

const CAMPAIGN = {
  ID: 'id',
};

const SALESORGBRAND = {
  ID: 'id',
};

const REGISTRATION = {
  PURCHASE_DATE: 'purchaseDate',
  REGISTRATION_TYPE: 'registrationType',
  STORE_ID: 'storeID',
  STORE: 'store',
  SALES_TYPE: 'salesType',
  INVOICE_NUMBER: 'invoiceNumber',
  PURCHASE_ORDER_NUMBER: 'purchaseNumber',
  EXTERNAL_ORDERNUMBER: 'externalOrdernumber',
  INVOICE_NUMBER_OR_PURCHASE_ORDER_NUMBER: 'invoiceNumberOrPurchaseOrderNumber',
  PRODUCT: 'product',
  CONSUMER: 'consumer',
  SALESMANID: 'salesmanid',
};

const REGISTRATION_ITEM = {
  SERIALNUMBER: 'serialnumber',
  IMEI: 'imei',
  GIVEN_EAN: 'givenEan',
  COUPON: 'coupon',
  PROMOTION_CODE: 'promotionCode',
};

const FILE = {
  TYPE: 'type',
  FORMAT: 'format',
  NAME: 'name',
  DATA: 'file', // stored as file in db, sent to external storage, doesnt return from API, only on specific APIs for download
};

const PRODUCT = {
  NAME: 'name',
  EAN: 'ean',
  IMEI: 'imei',
  COUPON: 'coupon',
  SERIALNUMBER: 'serialnumber',
  MODEL_NAME: 'modelName',
  MATERIAL_KEY: 'erpMaterialKey',
  ARTICLE: 'article',
};

const ACCOUNT = {
  ACCOUNTOWNER: 'accountowner',
  ACCOUNT: 'account',
  AGENCY: 'agency',
  BIC: 'bic',
  IBAN: 'iban',
  COUNTRY: 'country',
  MANUALBANKNAME: 'manualbankname',
};

const QUESTION_GROUP = {
  ANSWER: 'answer',
};

const THIRD_PARTY_CAMPAIGN_ITEM = {
  SELECTED_PACKAGE: 'selectedPackage',
  TERMS: 'terms',
};

const PROJECT = {
  NAME: 'name',
  PRODUCT: {
    PRODUCT: 'product', // XXX: just to organize.
    NAME: 'name',
    QUANTITY: 'quantity',
    ORDER_NO: 'erp_order_nr',
    DELIVERY_NO: 'erp_shipping_nr',
    PALLET_NO: 'palletnumber',
    SERIAL_NO: 'serialnumber',
  },
};

const FIELD_PATHS = {
  PERSON,
  CONSUMER,
  ADDRESS,
  COMMON,
  CAMPAIGN,
  SALESORGBRAND,
  REGISTRATION,
  FILE,
  PRODUCT,
  REGISTRATION_ITEM,
  ACCOUNT,
  QUESTION_GROUP,
  THIRD_PARTY_CAMPAIGN_ITEM,
  COMPANY,
  PROJECT,
};

export {
  ACCOUNT,
  ADDRESS,
  CAMPAIGN,
  COMMON,
  COMPANY,
  CONSUMER,
  FIELD_PATHS as default,
  FILE,
  PERSON,
  PRODUCT,
  PROJECT,
  QUESTION_GROUP,
  REGISTRATION,
  REGISTRATION_ITEM,
  SALESORGBRAND,
  THIRD_PARTY_CAMPAIGN_ITEM,
};
