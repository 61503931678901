export default {
  uppercase: () => `
    text-transform: uppercase;
  `,
  lowercase: () => `
    text-transform: lowercase;
  `,
  capitalize: () => `
    text-transform: capitalize;
  `,
  fullWidth: () => `
    text-transform: full-width;
  `,
  none: () => `
    text-transform: none;
  `,
};
