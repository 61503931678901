import { TimePicker as LibTimePicker } from '@lib/material-pickers-ui-system';
import { FormControl, FormControlLabel } from '@material-ui/core';
import componentModifiers from '@ui-system/default-modifiers/form/time-picker';
import { useTimePickerStyle } from '@ui-system/default-styles';
import {
  TimePickerProps,
  TimePickerType,
} from '@ui-system/interfaces-form/time-picker';
import UI from '@ui-system/ui';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import React from 'react';

// @ts-ignore
const Input = props => <UI.Form.Input {...omit(props, ['InputProps'])} />;

export const TimePicker: TimePickerType = ({
  value,
  onChange,
  ampm,
  cancelLabel,
  okLabel,
  error,
  disabled,
  labelModifiers,
  style,
  ...props
}: TimePickerProps) => {
  const finalStyle = useTimePickerStyle(style, '', componentModifiers);
  return (
    <FormControl
      required
      error={error}
      component="fieldset"
      disabled={disabled}
      style={finalStyle}
    >
      {/* @ts-ignore */}
      <FormControlLabel
        control={
          <LibTimePicker
            value={value}
            onChange={onChange}
            ampm={ampm}
            cancelLabel={
              isString(cancelLabel) ? (
                <UI.Typography modifiers={labelModifiers}>
                  {cancelLabel}
                </UI.Typography>
              ) : (
                <UI.Typography modifiers={labelModifiers} i18n={cancelLabel} />
              )
            }
            okLabel={
              isString(okLabel) ? (
                <UI.Typography modifiers={labelModifiers}>
                  {okLabel}
                </UI.Typography>
              ) : (
                <UI.Typography modifiers={labelModifiers} i18n={okLabel} />
              )
            }
            {...props}
          />
        }
      />
    </FormControl>
  );
};

TimePicker.defaultProps = {
  cancelLabel: 'Cancel',
  okLabel: 'Ok',
  TextFieldComponent: Input,
};

export default TimePicker;
