import contextualConfig from '@mmw/contextual-config';
import { Environment } from '@mmw/environment';
import { createStoreModule } from '@zustand-store/core';
import { StateCreator } from 'zustand/esm';

class AdminTokenState {
  token = '';

  apiV2BaseURL = contextualConfig.api.v2.baseURI;

  apiV2Env = Environment.STAGING_CLOUD;

  setToken: (token: string) => void;
}

const initializer: StateCreator<AdminTokenState> = setState => {
  const state = new AdminTokenState();
  state.setToken = token => {
    setState({ token });
  };
  return state;
};

export const useAdminToken = createStoreModule({
  name: `admin-token-${contextualConfig.application.applicationId}`,
  initializer,
});

export function useSetAdminToken() {
  return useAdminToken(state => state.setToken);
}
