import { enableBatching } from 'redux-batched-actions';

import initialActions from './actions';
import sobsEpics from './epics';
import salesOrgBrandsReducer from './reducer';
import { NAMESPACE as salesOrgBrandsNamespace } from './stateSelector';

export const epics = sobsEpics;
export const reducer = salesOrgBrandsReducer;
export const NAMESPACE = salesOrgBrandsNamespace;

export function getSalesOrgBrandsModule() {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    initialActions,
    epics,
  };
}
