import qs from 'qs';

export const languageQuery = (language?: string) => {
  if (!language) {
    return '';
  }
  return qs.stringify({
    language,
  });
};

export const formatQuery = (format?: string) => {
  if (!format) {
    return '';
  }
  return qs.stringify({
    format,
  });
};

export const loginUrlQuery = (loginUrl?: string) => {
  if (!loginUrl) {
    return '';
  }
  return qs.stringify({
    loginUrl,
  });
};
