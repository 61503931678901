import { createRootEpic } from '@mmw/redux-rx-of-type-operator';
import { epics as availableCountriesEpic } from '@mmw/redux-store-common-available-countries';
import { epics as currentSobEpics } from '@mmw/redux-store-current-salesorgbrand';
import { epics as fastFormEpics } from '@mmw/redux-store-fast-form';
import { epics as i18nEpics } from '@mmw/redux-store-i18n';
import { epics as sobsEpics } from '@mmw/redux-store-salesorgbrands';

export default createRootEpic(
  ...i18nEpics,
  ...fastFormEpics,
  ...availableCountriesEpic,
  ...sobsEpics,
  ...currentSobEpics,
  // createLoginByPassword(EMPTY_OBJECT),
);
