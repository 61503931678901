import { isLoggedInFieldPath } from '@mmw/redux-store-auth-api-authentication/stateSelector';
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';

import { getSalesOrgBrandsListStartAction } from '../actions';

const onUserChangedEpic = (action$, state$) =>
  state$.pipe(
    pluck(...isLoggedInFieldPath),
    distinctUntilChanged(),
    filter(Boolean),
    map(() => getSalesOrgBrandsListStartAction()),
  );
export default onUserChangedEpic;
