import { I18nShape } from '@mmw/constants-i18n';
import { useMemo } from '@mmw/redux-store-i18n/hooks';
import React, { ReactNode, useContext } from 'react';
import { U } from 'ts-toolbelt';

type ResourcesContextValue = {
  prefixKey: string;
};

type ResourcesProvider = {
  children: ReactNode;
};

const INITIAL_VALUES = {
  prefixKey: '',
};

const ResourcesContext =
  React.createContext<ResourcesContextValue>(INITIAL_VALUES);

type Props = ResourcesProvider & ResourcesContextValue;

export const ResourcesContextProvider: React.FC<Props> = ({
  children,
  prefixKey,
}: ResourcesProvider & ResourcesContextValue) => {
  const value = useMemo(() => ({ prefixKey }), [prefixKey]);
  return (
    <ResourcesContext.Provider value={value}>
      {children}
    </ResourcesContext.Provider>
  );
};
export const useResourcesContext = (): ResourcesContextValue =>
  useContext(ResourcesContext);

export const usePrefixKey = (): string => {
  const ctx = useResourcesContext();
  return ctx.prefixKey;
};

export const useI18nShapeWithPrefixKey = (
  i18n: U.Nullable<I18nShape>,
): I18nShape => {
  const prefixKey = usePrefixKey();
  return useMemo(() => {
    if (!i18n) return { key: '', message: '' };
    return {
      ...i18n,
      key: `${prefixKey}.${i18n.key}`,
    };
  }, [i18n, prefixKey]);
};

export default ResourcesContext;
