import contextualConfig from '@mmw/contextual-config';
import IMAGE_EXTENSIONS from 'image-extensions';
import {
  chain,
  cloneDeep,
  filter,
  find,
  flatMap,
  flattenDeep,
  isEmpty,
  isObject,
  map,
  reduce,
  size,
} from 'lodash';
import groupByFp from 'lodash/fp/groupBy';
import mapFp from 'lodash/fp/map';
import orderByFp from 'lodash/fp/orderBy';
import pipeFp from 'lodash/fp/pipe';
import propFp from 'lodash/fp/prop';
import reduceFp from 'lodash/fp/reduce';
import toPairsFp from 'lodash/fp/toPairs';
import qs from 'qs';

import {
  AddressJSON,
  EanPanelTreeItem,
  EMPTY_PANEL,
  EMPTY_TREE_ITEM,
  FileJSON,
  FileType,
  GroupedProductsByClassOfGoods,
  InstallationSiteJSON,
  MaybePanelJSON,
  MaybePanelTreeItem,
  OrderPanelTreeItem,
  PalletPanelTreeItem,
  PanelJSON,
  PanelTreeItem,
  PanelTreeItemType,
  PanelTreeItemTypes,
  PanelTreeItemTypesEnum,
  ProjectCampaignItemJSON,
  ProjectJSON,
  ProjectProductJSON,
  ProjectStatusJSON,
  SeparatedPanels,
  SerialPanelTreeItem,
  ShippingPanelTreeItem,
} from './types';

const { defaultLanguage } = contextualConfig.application;

export const groupProductsByClassOfGoods = (
  products: Array<ProjectProductJSON>,
): Array<GroupedProductsByClassOfGoods> =>
  pipeFp(
    groupByFp(propFp('classOfGoodsName')),
    toPairsFp,
    mapFp(([classOfGoodsName, cogProducts]) => ({
      name: classOfGoodsName,
      products: cogProducts,
      totalQuantity: pipeFp(
        reduceFp((sum, product) => sum + product.totalQuantity, 0),
      )(cogProducts),
      totalReturnedQuantity: pipeFp(
        reduceFp((sum, product) => sum + product.returnedQuantity, 0),
      )(cogProducts),
    })),

    orderByFp(propFp('name'), ['asc']),
  )(products);

export const getWarrantyCampaign = (
  campaignItems: Array<ProjectCampaignItemJSON>,
) =>
  find(
    campaignItems,
    citem =>
      citem.validationStatusModel.valid && citem.campaign.type === 'WARRANTY',
  );

export const getInvalidWarrantyCampaigns = (
  campaignItems: Array<ProjectCampaignItemJSON>,
) => {
  if (!campaignItems) return null;
  return filter<ProjectCampaignItemJSON>(
    campaignItems,
    (citem: ProjectCampaignItemJSON) =>
      !citem.validationStatusModel.valid && citem.campaign.type === 'WARRANTY',
  );
};

export const getInvalidWarrantyYears = (
  campaignItem: ProjectCampaignItemJSON | null,
) => {
  if (!campaignItem) return 0;
  const warrantyYears = campaignItem.campaign.code.match('[0-9]{1,2}');
  if (warrantyYears) {
    return parseInt(warrantyYears[0], 10);
  }
  return 0;
};

export const getWarrantyYears = (
  campaignItems: Array<ProjectCampaignItemJSON>,
) => {
  const campaignItem = getWarrantyCampaign(campaignItems);
  if (!campaignItem) return 0;
  const arrayOfMatches = campaignItem.campaign.code.match('[0-9]{1,2}Y');
  if (arrayOfMatches) {
    const [years] = arrayOfMatches;
    return parseInt(years.slice(0, -1), 10);
  }
  return 0;
};

export const getProjectStatus = (
  project: ProjectJSON,
): ProjectStatusJSON | null => {
  if (project) {
    return {
      valid: project.registered,
      ableToComplete: project.ableToRegister,
      ableToAddPanels: project.ableToAddItems,
      ableToRemovePanels: project.ableToRemoveItems,
      completed: project.registered || project.invalid,
      concept: project.concept,
      warrantyYears: getWarrantyYears(project.campaignItems),
    };
  }
  return null;
};

export const buildFileUrl = (file: FileJSON, accessToken: string): string =>
  `${file.url}?${qs.stringify({
    'X-AUTH-ACCESS-TOKEN': accessToken,
    language: defaultLanguage,
  })}`;

export type Markers = Array<{
  latitude: number;
  longitude: number;
}>;

export const transformToMarkers = (projects: Array<ProjectJSON>): Markers =>
  chain(projects)
    .map('installationSite')
    .filter(
      (installationSite: InstallationSiteJSON) =>
        installationSite && installationSite.address,
    )
    .map('address')
    .filter((address: AddressJSON) => address.lat && address.lng)
    .map((address: AddressJSON) => ({
      latitude: address.lat,
      longitude: address.lng,
    }))
    .value();

const findPanelsTreeByLevel = (
  data: Array<PanelTreeItem>,
  type: PanelTreeItemType,
): Array<PanelTreeItem> => {
  if (!data || !data.length) {
    return [];
  }
  if (data[0].type === type) {
    return data;
  }
  const results = flatMap(data, item => item.children);
  return findPanelsTreeByLevel(results, type);
};

export const findPanelsTreeSize = (data: Array<PanelTreeItem>) =>
  chain(data || [])
    .map((item: PanelTreeItem) => item.childrenSize)
    .sum()
    .value();

export const findPanelsOnPalletnumberLevel = (
  data: Array<OrderPanelTreeItem>,
): Array<PalletPanelTreeItem> => findPanelsTreeByLevel(data, 'palletnumber');

export const findPanelsOnEanLevel = (
  data: Array<OrderPanelTreeItem>,
): Array<EanPanelTreeItem> => findPanelsTreeByLevel(data, 'ean');

export const findPanelsOnDeliverynumberLevel = (
  data: Array<OrderPanelTreeItem>,
): Array<ShippingPanelTreeItem> =>
  findPanelsTreeByLevel(data, 'erp_shipping_nr');

export const findPanelsOnSerialnumberLevel = (
  data: Array<OrderPanelTreeItem>,
): Array<SerialPanelTreeItem> => findPanelsTreeByLevel(data, 'serialnumber');

export const isProjectImage = (file: FileJSON) =>
  file.type === FileType.COVER_PHOTO ||
  file.type === FileType.PHOTO ||
  (IMAGE_EXTENSIONS.includes(file.format) && file.type !== FileType.DOCUMENT);
export const isProjectCoverImage = (file: FileJSON) =>
  file.type === FileType.COVER_PHOTO;

export const isProjectDocument = (file: FileJSON) => !isProjectImage(file);

export const getProjectCoverImage = (
  project: ProjectJSON,
  accessToken: string | null,
) => {
  if (!project || !project.files) {
    return null;
  }
  const file = find(project.files, isProjectCoverImage);
  if (!file) {
    return null;
  }
  if (!accessToken) {
    return null;
  }
  return buildFileUrl(file, accessToken);
};

export const isUnknownValue = (value: string): boolean => value === 'N/A';
export const isKnownPanel = (panel: PanelTreeItemTypes) =>
  !isUnknownValue(panel.value);
export const isUnknownPanel = (panel: PanelTreeItemTypes) =>
  isUnknownValue(panel.value);
export const filterKnownPanels = (
  panels: Array<PanelTreeItemTypes>,
): Array<PanelTreeItemTypes> => filter(panels, isKnownPanel);

export const filterUnknownPanels = (
  panels: Array<PanelTreeItemTypes>,
): Array<PanelTreeItemTypes> => filter(panels, isUnknownPanel);

export const getPanelChildrenSize = (
  panels: Array<PanelTreeItemTypes>,
): number =>
  reduce(
    panels,
    (prev, next) => {
      if (next.type === PanelTreeItemTypesEnum.SERIALNUMBER) {
        return prev + (next.leafSize || 0);
      }
      return prev + (next.childrenSize || 0);
    },
    0,
  );

// Receives tree already fixed
const cleanEmptyNodes = (panels: Array<PanelTreeItemTypes>) =>
  filter(
    map(panels, panel => {
      if (!panel) return null;
      if (panel.leafSize > 0) {
        return panel;
      }
      if (isEmpty(panel.children)) {
        return null;
      }
      const children = cleanEmptyNodes(panel.children);
      if (isEmpty(children)) {
        return null;
      }
      return {
        ...panel,
        children,
      };
    }),
    isObject,
  );

export const mapPanelsByTypeValue = (
  panels: Array<OrderPanelTreeItem>,
  type: PanelTreeItemType,
  // XXX: only works on leaf!
  value: string,
  // XXX: negates the comparison, meaning, !value
  isNot = false,
): Array<OrderPanelTreeItem> => {
  if (isEmpty(panels)) return [];
  function mapChildren(children: Array<PanelTreeItem>) {
    return children.map(child => {
      // if have children recurse
      if (!isEmpty(child.children)) {
        const newChildren = filter(mapChildren(child.children), isObject);
        const childrenSize = getPanelChildrenSize(newChildren);

        return {
          ...child,
          children: newChildren,
          childrenSize,
        };
      }
      // if match value return child
      if (
        child.type === type && isNot
          ? child.value !== value
          : child.value === value
      ) {
        return child;
      }
      return null;
    });
  }

  return cleanEmptyNodes(mapChildren(panels));
};

export const getChildrenByType = (
  panels: PanelTreeItem[] | OrderPanelTreeItem[],
  type: PanelTreeItemType,
  // XXX: negates the comparison, meaning, !value
  isNot = false,
): PanelTreeItem[] => {
  if (isEmpty(panels)) return [];
  function mapChildren(children: Array<PanelTreeItem>) {
    return children
      .map(child => {
        if (isNot ? child.type !== type : child.type === type) {
          return child;
        }
        if (child.children.length > 0) return mapChildren(child.children);
        // if match type return child
        return null;
      })
      .filter(child => child !== null);
  }
  return flattenDeep(mapChildren(panels));
};

export const sumLeafSizes = (
  panels: PanelTreeItem[] | OrderPanelTreeItem[],
  type: PanelTreeItemType,
): number =>
  getChildrenByType(panels, type).reduce(
    (acc, child) => acc + child.leafSize,
    0,
  );

export const separatePanels = (
  panels: Array<OrderPanelTreeItem>,
): SeparatedPanels => {
  const knownTree: Array<OrderPanelTreeItem> = filterKnownPanels(
    cloneDeep(panels),
  );

  const notKnownTree: Array<OrderPanelTreeItem> = filterUnknownPanels(
    cloneDeep(panels),
  );
  const notKnownWithSerialTree: Array<OrderPanelTreeItem> =
    mapPanelsByTypeValue(cloneDeep(notKnownTree), 'serialnumber', 'N/A', true);
  const notKnownWithoutSerialTree: Array<OrderPanelTreeItem> =
    mapPanelsByTypeValue(cloneDeep(notKnownTree), 'serialnumber', 'N/A');
  const separatedPanels = {
    knownTree,
    notKnownTree,
    notKnownWithSerialTree,
    notKnownWithoutSerialTree,
  };

  const amount = {
    total: sumLeafSizes(panels, 'serialnumber'),
    knownTree: {
      total: sumLeafSizes(separatedPanels.knownTree, 'serialnumber'),
    },
    notKnownTree: {
      total: sumLeafSizes(separatedPanels.notKnownTree, 'serialnumber'),
    },
    notKnownWithSerialTree: {
      total: sumLeafSizes(
        separatedPanels.notKnownWithSerialTree,
        'serialnumber',
      ),
    },
    notKnownWithoutSerialTree: {
      total: sumLeafSizes(
        separatedPanels.notKnownWithoutSerialTree,
        'serialnumber',
      ),
    },
  };
  return {
    ...separatedPanels,
    amount,
  };
};

export const parseSystemSizeToInt: (arg0: string) => number = string =>
  parseInt((string.match(/\d/g) || []).join(''), 10);

export const flatPanelsAsSerialPanelTreeItems = (
  panel: PalletPanelTreeItem,
): Array<SerialPanelTreeItem> => {
  const known: Array<SerialPanelTreeItem> = filterKnownPanels(panel.children);

  const unknown: Array<SerialPanelTreeItem> = filterUnknownPanels(
    panel.children,
  );
  const unknownSize = panel.childrenSize - size(known);
  const uknownArray = Array.from({ length: unknownSize }, () =>
    cloneDeep(unknown[0]),
  );
  return [].concat(known).concat(uknownArray);
};

const convertUnknownValueToEmpty = (value: string) =>
  isUnknownValue(value) ? null : value;

export const convertDeleteRequest = (childrenRequest: PanelJSON) => ({
  ...childrenRequest,
  erp_order_nr: convertUnknownValueToEmpty(childrenRequest.erp_order_nr),
  erp_shipping_nr: convertUnknownValueToEmpty(childrenRequest.erp_shipping_nr),
  palletnumber: convertUnknownValueToEmpty(childrenRequest.palletnumber),
});

export const createChildrenRequest = (panel: MaybePanelJSON): PanelJSON =>
  panel ? { ...EMPTY_PANEL, ...panel } : EMPTY_PANEL;

export const createTreeItem = (item: MaybePanelTreeItem): PanelTreeItem =>
  item
    ? {
        ...EMPTY_TREE_ITEM,
        ...item,
        childrenRequest: createChildrenRequest(item.childrenRequest || {}),
      }
    : EMPTY_TREE_ITEM;
