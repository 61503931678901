import MUITable from '@material-ui/core/Table';
import MUITableBody from '@material-ui/core/TableBody';
import MUITableCell from '@material-ui/core/TableCell';
import MUITableHead from '@material-ui/core/TableHead';
import MUITableRow from '@material-ui/core/TableRow';
import componentModifiers from '@ui-system/default-modifiers/table';
import defaultProps from '@ui-system/default-props/table';
import { useTableRowStyle, useTableStyle } from '@ui-system/default-styles';
import {
  TableProps,
  TableRowProps,
  TableType,
} from '@ui-system/interfaces-table';
import React, { useCallback, useMemo } from 'react';

const Table: TableType = ({
  style,
  children,
  stickyHeader,
  size,
  ...props
}: TableProps): React.ReactElement => {
  const finalStyle = useTableStyle(style, '', componentModifiers);
  return (
    <MUITable
      style={finalStyle}
      stickyHeader={stickyHeader}
      size={size}
      {...props}
    >
      {children}
    </MUITable>
  );
};

const TableHead = props => <MUITableHead {...props} />;
const TableBody = props => <MUITableBody {...props} />;
const TableCell = props => <MUITableCell {...props} />;
const TableRow: TableType['Row'] = ({
  style,
  onClick,
  ...props
}: TableRowProps) => {
  const clickProps = useMemo(() => ({ isClickable: !!onClick }), [onClick]);
  const onClickRow = useCallback(
    e => {
      if (!onClick) return;
      e.preventDefault();
      onClick();
    },
    [onClick],
  );
  const finalStyle = useTableRowStyle(style, null, null, clickProps);
  return <MUITableRow style={finalStyle} onClick={onClickRow} {...props} />;
};

Table.Head = TableHead;
Table.Body = TableBody;
Table.Cell = TableCell;
Table.Row = TableRow;

Table.defaultProps = defaultProps;

export default Table;
