import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { filter, find, get } from 'lodash';
import { useMemo } from 'react';
import { SchemaObjectDescription } from 'yup';

import { FieldPath } from '../../types';
import useFieldCurrentConfig from './useFieldCurrentConfig';

export type FieldConfigurationContextValue = {
  required: boolean;
  maxLength: number | undefined;
  minLength: number | undefined;
  type: 'string' | 'number' | 'date' | 'object' | 'array' | string | null; // XXX: yup types,
  tests: Array<{
    name: string;
    params: {};
  }>;
};

export type Options = {
  requiredValidationNames: Array<string>;
};

const DEFAULT_OPTIONS = {
  requiredValidationNames: ['required', 'maxLength', 'minLength'],
};

const INITIAL_VALUES = {
  required: false,
  maxLength: undefined,
  minLength: undefined,
  configured: false,
  type: null,
  tests: EMPTY_ARRAY,
};

const getTest = (validationDescription, testName) =>
  find(validationDescription.tests, test => test.name === testName);

const hasTest = (validationDescription, testName) =>
  getTest(validationDescription, testName) != null;

const hasAnyTest = (validationDescription, testNames) =>
  filter(testNames, testName => hasTest(validationDescription, testName))
    .length > 0;

const getTestParam = (
  validationDescription,
  testName,
  paramName,
  defaultValue?,
) => {
  const test = getTest(validationDescription, testName);
  if (!test) {
    return defaultValue || undefined;
  }
  return get(test.params, paramName, defaultValue);
};

const calculateFieldConfig = (
  currentConfig: SchemaObjectDescription | null,
  givenOptions?: Options,
): FieldConfigurationContextValue => {
  const options = givenOptions || DEFAULT_OPTIONS;
  if (!currentConfig) return { ...INITIAL_VALUES };
  return {
    ...INITIAL_VALUES,
    configured: true,
    required: hasAnyTest(currentConfig, options.requiredValidationNames),
    maxLength: getTestParam(currentConfig, 'max', 'max', null),
    minLength: getTestParam(currentConfig, 'min', 'min', null),
    type: currentConfig.type,
    tests: currentConfig.tests,
  };
};

const useFieldConfiguration = (fieldPath: FieldPath, options?: Options) => {
  const fieldCurrentConfig = useFieldCurrentConfig(fieldPath);
  return useMemo(
    () => calculateFieldConfig(fieldCurrentConfig, options),
    [options, fieldCurrentConfig],
  );
};

export default useFieldConfiguration;
