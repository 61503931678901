import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';
import { get } from 'lodash';

import createFromProps from '../create-from-props';
import { FromProps, Padding, Shadow } from '../types';

const applyShadow: F.Function<[Theme, Padding]> & FromProps = (
  theme,
  shadow: Shadow,
) => get(theme.shadows, shadow, '');
applyShadow.fromProps = createFromProps(applyShadow, 'shadow');

export default applyShadow;
