// import {
//   SALESORGBRAND as FIELDS,
// } from '@mmw/constants-fields-names';
// import { REQUIRED } from '@mmw/constants-validation-names';

// const namespace = 'validation';

const SALESORGBRAND = {
  ID: {},
};

export default SALESORGBRAND;
