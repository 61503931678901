import { currentSalesOrgBrandSelector } from '@mmw/redux-store-current-salesorgbrand/stateSelector';
import {
  distinctUntilChanged,
  map,
  pluck,
  withLatestFrom,
} from 'rxjs/operators';

import { changeLanguageStartAction } from './actions';
import { languageFieldPath } from './stateSelector';

const onLocalSalesOrgBrandChangedEpic = (action$, state$) =>
  state$.pipe(
    map(currentSalesOrgBrandSelector),
    distinctUntilChanged(),
    withLatestFrom(state$.pipe(pluck(...languageFieldPath))),
    map(([, language]) => changeLanguageStartAction(language)),
  );
export default onLocalSalesOrgBrandChangedEpic;
