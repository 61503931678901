import browserUpdate from 'browser-update';
import { F } from 'ts-toolbelt';

interface Options {
  required: {
    i?: number;
  };
  reminder?: number;
  insecure?: boolean;
  style?: 'top' | 'bottom' | 'corner';
  // eslint-disable-next-line camelcase
  no_permanent_hide?: boolean;
  // eslint-disable-next-line camelcase
  text_for_i_in_de?: {
    msg: string;
    msgmore: string;
    bupdate: string;
    bignore: string;
  };
}

const DEFAULT_CONFIG = {
  required: {
    i: 11,
  },
  insecure: true,
  reminder: 12,
  style: 'corner',
  no_permanent_hide: true,
  unsupported: true,
  text_for_i_in_de: {
    msg: 'Ihr Webbrowser (Internet Explorer) ist veraltet.',
    msgmore:
      'Aktualisieren Sie Ihren Browser für mehr Sicherheit, Geschwindigkeit und den besten Komfort auf dieser Seite.',
    bupdate: 'Browser aktualisieren',
    bignore: 'Ignorieren',
  },
};

const NotifyBrowserUpdate = (options?: Options = DEFAULT_CONFIG): F.Function =>
  browserUpdate(options);

export default NotifyBrowserUpdate;
