import { css } from '@ui-system/css';
import { createComponentModifiers } from '@ui-system/modifiers';

const componentModifiers = createComponentModifiers(({ theme }) => ({
  light: css`
    background-color: ${theme.colors[theme.mode].white};
  `,
}));

export default componentModifiers;
