const namespace = 'recSelectEmea';

const REC_SELECT_EMEA = {
  BANNER: {
    TITLE: {
      key: `${namespace}.banner.title`,
      message: 'REC Select',
    },
    DESCRIPTION: {
      key: `${namespace}.banner.bannerDescription`,
      message:
        'Register your installations and earn RECoins.  Redeem them for discount vouchers, plus more benefits!',
    },
    SECOND_DESCRIPTION: {
      key: `${namespace}.banner.banner2ndDescription`,
      message:
        'Summer Promotion:<br/>Unleash the power of the sun with REC Alpha Pure-R',
    },
  },
  CAMPAIGN_INFO: {
    WHAT_ARE_RECOINS: {
      TITLE: {
        key: `${namespace}.campaignInfo.whatAreRecoins.title`,
        message: 'What is REC SELECT?',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.whatAreRecoins.whatAreRecoinsDescription`,
        message:
          "Welcome to REC SELECT, our rewards program exclusively for RCSPs. Your company installs our REC modules? Perfect! Join our program and earn RECoins for your business with registered REC installations. It's never been easier to earn marketing dollars and use them to store at stores offered by REC.",
      },
    },
    HOW_TO_EARN_RECOINS: {
      TITLE: {
        key: `${namespace}.campaignInfo.howToEarnRecoins.title`,
        message: 'How does it work?',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.howToEarnRecoins.howToEarnCoinsDescription`,
        message:
          'Accept the terms and conditions of participation (link to document) of REC SELECT once. After the terms and conditions of participation are activated, registered installations of certain REC modules will automatically participate in REC SELECT. After each new installation registration, your current points balance will be calculated in RECoins and displayed in your points balance (link to points balance). RECoins can be easily converted into Euros to make purchases in one of the REC stores. Your available marketing budget will be displayed in the budget status.',
      },
    },
    HOW_MUCH_ARE_RECOINS_WORTH: {
      TITLE: {
        key: `${namespace}.campaignInfo.howMuchAreRecoinsWorth.title`,
        message: 'How do you get RECoins?',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.howMuchAreRecoinsWorth.howMuchRecoinsAreWorthDescription`,
        message:
          'REC currently rewards REC Alpha Series Alpha Pure and Alpha Pure-R products and REC TwinPeak Series TwinPeak 4 and TwinPeak 5 modules. Participating REC products and the number of RECoins awarded are subject to change for the future. Promotions or campaigns may also be rewarded with RECoins. For this reason, we recommend that you keep up to date via our RECent News newsletter or our ProPortal. REC Alpha Series Currently, each registered kW of REC Alpha Series corresponds to 4 RECoins REC TwinPeak Series Currently, each registered kW of REC TwinPeak Series corresponds to 2 RECoins. If you are a REC SELECT member and have accepted the terms and conditions of participation, you will see your current point balance with the associated installations in the registration tool.',
      },
    },
    HOW_TO_REDEEM_RECOINS: {
      TITLE: {
        key: `${namespace}.campaignInfo.howToRedeemRecoins.title`,
        message: 'What is important?',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.howToRedeemRecoins.howtoRedeemRecoinsDescription`,
        message:
          'The incentive program will start in January 2023, after which you will be able to collect RECoins for registered REC installations of participating products once the participation condition is accepted. Confirming the participation condition will activate your participation and you will be able to collect RECoins by registering your project installations. RECoins are collected for a period of one calendar year at a time and will be available for use until March 31 of the following year, after which it will expire; no cash payment, credit or other refund of RECoins is possible.',
      },
    },
    REC_SELECT_STATUS_AND_LEVELS: {
      TITLE: {
        key: `${namespace}.campaignInfo.recSelectStatusAndLevels.title`,
        message: 'How and from when can I redeem and purchase RECoins?',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.recSelectStatusAndLevels.statusAndLevelsDescription`,
        message:
          'The use of RECoins will be possible from April 1, 2023. From this date on, you will be able to convert your RECoins to make purchases in the REC Marketing Shops. To do this, simply convert (link to XX) the RECoins into euros before visiting our REC Marketing Shop. Your available euro amount will be displayed and automatically transferred to the connected REC Shops. There is an electronic data exchange between the stores, ProPortal and SunSnap App, so your budget inflows and outflows are always up-to-date. No matter which system you are currently in. Use RECoins in the REC Marketing Shops',
      },
    },
    BENEFITS_FOR_REC_SELECT: {
      TITLE: {
        key: `${namespace}.campaignInfo.benefitsForRecSelect.title`,
        message: 'Advantages for REC Select participants',
      },
      DESCRIPTION: {
        key: `${namespace}.campaignInfo.benefitsForRecSelect.benefitsDescription`,
        message: `<ul>
                      <li>Your eligibility to participate in REC Select and earn RECoins </li>
                      <li>Your commitment to REC pays off</li>
                      <li>Your business earns marketing dollars</li>
                      <li>Convenient access to paid promotional sales and advertising materials</li>
                      <li>You have ideas for a joint promotion? Let's talk about it!</li>
                    </ul>`,
      },
    },
  },
  ENTER_THE_AMOUNT_OF_REC_COINS: {
    key: `${namespace}.enterTheAmountOfRecCoins`,
    message: 'Enter the amount of RECoins you would like to convert.',
  },
  CONVERT: {
    key: `${namespace}.convert`,
    message: 'Convert',
  },
  AVAILABLE: {
    key: `${namespace}.available`,
    message: 'Available',
  },
  THIS_CONVERSION_WILL_RESULT: {
    key: `${namespace}.thisConversionWillResult`,
    message: 'This conversion will result in',
  },
  CURRENT_VALUE_IN_MARKETING_BUDGET: {
    key: `${namespace}.currentValueInMarketingBudget`,
    message: 'Current value in Marketing Budget',
  },
  CONVERT_ALL_RECOINS: {
    key: `${namespace}.convertAllRecoins`,
    message: 'Convert all RECoins',
  },
  INFORM_AMOUNT: {
    key: `${namespace}.informAmount`,
    message: 'Inform Amount',
  },
  ACCEPT_AND_CONFIRM: {
    key: `${namespace}.acceptAndConfirm`,
    message: 'Accept and Confirm',
  },
  CONVERSION_RESULT: {
    key: `${namespace}.conversionResult`,
    message: 'With this operation you will convert',
  },
  CONVERSION_RATE: {
    key: `${namespace}.conversionRate`,
    message: `{recoins} RECoins into {marketingBudget}`,
  },
  CONVERSION_BENEFIT: {
    key: `${namespace}.conversionBenefit`,
    message: 'Which you can use in all connected marketing online stores.',
  },
  CONVERSION_CONFIRM: {
    key: `${namespace}.conversionConfirm`,
    message: 'Do you want to proceed? This process cannot be undone.',
  },
  CONVERSION_ACCEPT_TERMS: {
    key: `${namespace}.conversionAcceptTerms`,
    message: 'I accept the Terms and Conditions for Marketing Budget',
  },
  CONVERT_TO_MARKETING_BUDGET: {
    key: `${namespace}.convertToMarketingBudget`,
    message: 'Convert to Marketing Budget',
  },
  CONVERSION_OF_RECOINS: {
    key: `${namespace}.conversionOfRecoins`,
    message: 'Conversion of RECoins',
  },
  // NAME: {
  //   key: `${namespace}.name`,
  //   message: 'REC Select Partner Program',
  // },
  // TITLE: {
  //   key: `${namespace}.title`,
  //   message: 'REC Select',
  // },
  // YOUR_LEVEL_LABEL: {
  //   key: `${namespace}.yourLevel`,
  //   message: 'Your level',
  // },
  // YOUR_BALANCE_LABEL: {
  //   key: `${namespace}.yourBalance`,
  //   message: 'Your balance',
  // },
  // YOU_EARNED_LABEL: {
  //   key: `${namespace}.youEarned`,
  //   message: 'You earned with this registration',
  // },
  // YOUR_STATEMENT: {
  //   key: `${namespace}.yourStatement`,
  //   message: 'Your statement',
  // },
  // YOUR_INSTALLATIONS: {
  //   key: `${namespace}.yourInstallations`,
  //   message: 'Your installations',
  // },
  // NEXT_LEVEL: {
  //   key: `${namespace}.nextLevel`,
  //   message: 'Next level',
  // },
  // ACHIEVED_LEVEL: {
  //   key: `${namespace}.achievedLevel`,
  //   message: 'Achieved level',
  // },
  // NEXT_LEVEL_MARK_LABEL: {
  //   YOU_NEED: {
  //     key: `${namespace}.nextLevelMark.youNeed`,
  //     message: 'You need',
  //   },
  //   TO_NEXT_LEVEL: {
  //     key: `${namespace}.nextLevelMark.toNext`,
  //     message: 'to the next level',
  //   },
  // },
  // INFORMATIVE: {
  //   EACH_REGISTERED_WATT: {
  //     key: `${namespace}.eachRegisteredWatt`,
  //     message: 'Each registered kWatt',
  //   },
  //   REGISTER_YOUR_INSTALLATIONS: {
  //     key: `${namespace}.registerYourInstallations`,
  //     message: 'Register your installations, earn points and more advantages!',
  //   },
  //   WATT_POINTS_CAN_BE_EARNED: {
  //     key: `${namespace}.wattPointsCanBeEarned`,
  //     message:
  //       'WattPoints can be earned by participants and redeemed for vouchers that allow a discount for purchases from an official REC distributor.',
  //     // message:
  //     //   'WattPoints can be earned by participants and redeemed for vouchers that allow a discount for purchases from an official REC distributor.<br /><br />WattPoints can be earned by registering REC installations with either TwinPeak 4 or Alpha family panels',
  //   },
  //   REC_TWIN_PEAK4_SERIES_KW_TO_RECOINS: {
  //     key: `${namespace}.recTwinpeak4SeriesKWattToRecoins`,
  //     message:
  //       'Each registered kWatt from a TwinPeak 4 family product corresponds to 1 RECoins',
  //   },
  //   FROM_TWIN_PEAK_FAMILY: {
  //     key: `${namespace}.fromTwinPeakFamily`,
  //     message: 'from a TwinPeak 4 family product corresponds to',
  //   },
  //   TWIN_PEAK_REWARD: {
  //     key: `${namespace}.twinPeakReward`,
  //     message: '1 RECoins',
  //   },
  //   FROM_ALPHA_FAMILY: {
  //     key: `${namespace}.fromTwinPeakFamily`,
  //     message: 'from a Alpha family product corresponds to',
  //   },
  //   ALPHA_REWARD: {
  //     key: `${namespace}.twinPeakReward`,
  //     message: '2 RECoins',
  //   },
  //   REC_ALPHA_SERIES_KW_TO_RECOINS: {
  //     key: `${namespace}.recAlphaSeriesKWattToRecoins`,
  //     message:
  //       'Each registered kWatt from a Alpha family product corresponds to 2 RECoins',
  //   },
  //   INSTALLATION_QUALIFIED: {
  //     key: `${namespace}.installationQualified`,
  //     message:
  //       'Nice Work! this installation qualifies for the REC SELECT Loyalty Program, where you can earn',
  //   },
  //   PAST_INSTALLATIONS: {
  //     key: `${namespace}.installationQualified`,
  //     message:
  //       'Your installations registered in 2021 qualify for the REC SELECT Loyalty Program, where you can earn',
  //   },
  //   BANNER_DESCRIPTION: {
  //     key: `${namespace}.bannerDescription`,
  //     message:
  //       'Register your installations and earn RECoins.  Redeem them for discount vouchers, plus more benefits!',
  //   },
  //   MORE_INFORMATION_BUTTON: {
  //     key: `${namespace}.moreInformationButton`,
  //     message: 'More information about REC SELECT',
  //   },
  // },
  // REDEEM_YOUR_REC_COINS: {
  //   key: `${namespace}.redeemYourRECCoins`,
  //   message:
  //     'Redeem your RECoins for a voucher that you can use for discount at any official REC distributor in your region',
  // },
  // REDEEM_REC_COINS: {
  //   key: `${namespace}.redeemRECCoins`,
  //   message: 'REDEEM RECoins',
  // },
  // CONVERT_YOUR_RECOINS: {
  //   key: `${namespace}.convertRECCoins`,
  //   message: 'Convert your RECoins',
  // },
  // ENTER_THE_AMOUNT_OF_REC_COINS: {
  //   key: `${namespace}.enterTheAmountOfRecCoins`,
  //   message: 'Enter the amount of RECoins you would like to redeem.',
  // },
  // REDEEM: {
  //   key: `${namespace}.redeem`,
  //   message: 'Redeem',
  // },
  // AVAILABLE: {
  //   key: `${namespace}.available`,
  //   message: 'Available',
  // },
  // CURRENT_VALUE: {
  //   key: `${namespace}.currentValue`,
  //   message: 'Current value',
  // },
  // VOUCHERS: {
  //   key: `${namespace}.vouchers`,
  //   message: 'Vouchers',
  // },
  // VOUCHER: {
  //   key: `${namespace}.voucher`,
  //   message: 'Voucher',
  // },
  // STATUS: {
  //   key: `${namespace}.status`,
  //   message: 'Status',
  // },
  // REDEEM_MODAL: {
  //   WITH_THIS_OPERATION: {
  //     key: `${namespace}.redeemModal.withThisOperation`,
  //     message: 'With this operation you will redeem',
  //   },
  //   THIS_OPERATION_WILL: {
  //     key: `${namespace}.redeemModal.thisOperationWill`,
  //     message:
  //       'This operation will use this voucher and give you a credit with REC worth',
  //   },
  //   CONVERSION: {
  //     key: `${namespace}.redeemModal.conversion`,
  //     // eslint-disable-next-line no-template-curly-in-string
  //     message: '{coins} RECoins for {currency} {value}',
  //   },
  //   DO_YOU_WANT_TO_PROCEED: {
  //     key: `${namespace}.redeemModal.doYouWantToProceed`,
  //     message: 'Do you want to proceed?',
  //   },
  // },
  // VOUCHER_ID: {
  //   key: `${namespace}.voucherId`,
  //   message: 'Voucher ID',
  // },
  // DATE_OF_CREATION: {
  //   key: `${namespace}.dateOfCreation`,
  //   message: 'Date of creation',
  // },
  // VALID_UNTIL: {
  //   key: `${namespace}.validUntil`,
  //   message: 'Valid until',
  // },
  // DATE_OF_USE: {
  //   key: `${namespace}.dateOfUse`,
  //   message: 'Date of use',
  // },
  // VALUE: {
  //   key: `${namespace}.value`,
  //   message: 'Value',
  // },
  // DO_YOU_WANT_TO_PARTICIPATE: {
  //   key: `${namespace}.wantToParticipate`,
  //   message: 'Do you want to participate?',
  // },
  // PARTICIPATE: {
  //   key: `${namespace}.participate`,
  //   message: 'Participate',
  // },
};

export default REC_SELECT_EMEA;
