import { ButtonColor, ButtonVariant } from '@ui-system/interfaces-button';
import { TypographyVariant } from '@ui-system/interfaces-typography';

export const TYPOGRAPHY_VARIANTS_MAP_FOR_UI_KITTEN: Record<
  string,
  TypographyVariant
> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 's1' as 'subtitle1',
  subtitle2: 's2' as 'subtitle2',
  body1: 'p1' as 'body1',
  body2: 'p2' as 'body1',
  caption: 'c1' as 'caption',
  button: 'c1' as 'button',
  overline: 'label' as 'overline',
};

export const VARIANTS_MAP_FOR_UI_KITTEN: Record<string, ButtonVariant> = {
  text: 'ghost' as 'text',
  outlined: 'outline' as 'outlined',
  contained: 'filled' as 'contained',
};

export const COLOR_MAP_FOR_UI_KITTEN: Record<string, ButtonColor> = {
  default: 'primary',
  inherit: 'primary',
  primary: 'primary',
  secondary: 'basic' as 'secondary',
  light: 'control' as 'secondary',
};
