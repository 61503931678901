import ofType from '@mmw/redux-rx-of-type-operator';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import logger from '../../log';
import { validateSuccessAction } from '../actions';
import { allFormsSelector } from '../stateSelector';
import {
  AllForms,
  RootState,
  VALIDATE_START,
  ValidateStartAction,
  ValidateSuccessAction,
  ValidationError,
} from '../types';

type PipeData = [ValidateStartAction, AllForms];

// https://github.com/jquense/yup#mixedvalidatevalue-any-options-object-promiseany-validationerror
const YUP_OPTIONS = { abortEarly: false };

const onValidateStart = (
  action$: ActionsObservable<ValidateStartAction>,
  state$: Observable<RootState>,
): Observable<ValidateSuccessAction<any>> =>
  action$.pipe(
    ofType(VALIDATE_START),
    withLatestFrom(state$.pipe(map(allFormsSelector))),
    tap(() => logger.info('Validate form start')),
    switchMap(
      ([
        {
          payload: { formId, validationSchema, onlyWatchedFields },
        },
        allForms,
      ]: PipeData) =>
        from(
          validationSchema.validate(
            allForms[formId].currentValues,
            YUP_OPTIONS,
          ),
        ).pipe(
          map(() => validateSuccessAction(formId, [], onlyWatchedFields)),
          catchError((error: ValidationError) =>
            of(validateSuccessAction(formId, error.inner, onlyWatchedFields)),
          ),
        ),
    ),
  );

export default onValidateStart;
