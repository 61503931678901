import { Preset, Presets } from './types';

export const FADE_IN: Preset = {
  opacity: 1,
  from: { opacity: 0 },
};

export const FADE_OUT: Preset = {
  opacity: 0,
  from: { opacity: 1 },
};

export const LTR: Preset = {
  marginRight: 0,
  from: { marginRight: -500 },
};

export const RTL: Preset = {
  marginLeft: 0,
  from: { marginLeft: -500 },
};
export const TTD: Preset = {
  marginTop: 0,
  from: { marginTop: -500 },
};

export const DTT: Preset = {
  marginBottom: 0,
  from: { marginBottom: -200 },
};

const PRESETS: Presets = {
  FADE_IN,
  FADE_OUT,
  LTR,
  RTL,
  TTD,
  DTT,
};

export default PRESETS;
