import { nanoid } from 'nanoid';
import { useMemo } from 'react';

interface ItemGenerator<I> {
  (i: number): I;
}
export function createArray<I = string>(
  size: number,
  itemGenerator?: ItemGenerator<I>,
): I[] {
  const arr: I[] = [];
  for (let i = 0; i < size; i += 1) {
    arr.push(itemGenerator ? itemGenerator(i) : (nanoid() as unknown as I));
  }
  return arr;
}

export function useCreateArray(limit = 10) {
  return useMemo(() => createArray(limit), [limit]);
}
