/* eslint-disable @typescript-eslint/ban-types */
import { get } from '@mmw/utils-object-utils';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { goBack, push, replace } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import qs from 'qs';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { F, O, S, U } from 'ts-toolbelt';

type NavigateOptions = {
  replace: boolean;
};

function getNavigateActionCreator(
  options?: NavigateOptions,
): typeof replace | typeof push {
  if (options && options.replace) {
    return replace;
  }
  return push;
}

type NavigateFn<S> = (pathname: string, state?: S) => void;
type NavigateWithParamsFn<P, Q extends U.Nullable<Record<string, unknown>>> = (
  pathname: string,
  pathnameParams: P,
  queryParams?: Q,
) => void;

function useNavigate<S>(options?: NavigateOptions): NavigateFn<S> {
  const dispatch = useDispatch();
  const actionCreator = getNavigateActionCreator(options);
  return useCallback(
    (pathname, state?: S) => {
      dispatch(
        actionCreator({
          pathname,
          state,
        }),
      );
    },
    [actionCreator, dispatch],
  );
}

export function useNavigateToRoute(pathName: string) {
  const navigateTo = useNavigate();
  return useCallback(
    (params: Record<string, any> = EMPTY_OBJECT) =>
      navigateTo(pathName, params),
    [navigateTo, pathName],
  );
}

export function useNavigateBack() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(goBack()), [dispatch]);
}

export function useNavigateWithParams<
  P,
  Q extends U.Nullable<Record<string, unknown>> = null,
>(options?: NavigateOptions): NavigateWithParamsFn<P, Q> {
  const dispatch = useDispatch();
  const actionCreator = getNavigateActionCreator(options);
  return useCallback(
    (pathname: string, pathnameParams: P, queryParams?: Q) => {
      const path = generatePath(pathname, pathnameParams);
      const search = isEmpty(queryParams)
        ? undefined
        : qs.stringify(queryParams);
      dispatch(
        actionCreator({
          pathname: path,
          search,
        }),
      );
    },
    [actionCreator, dispatch],
  );
}

export function useParamsPath<
  Params extends object,
  Path extends string,
  DefaultValue,
>(
  path: F.AutoPath<Params, Path>,
  defaultValue?: O.Path<Params, S.Split<Path, '.'>> | DefaultValue,
): U.Nullable<O.Path<Params, S.Split<Path, '.'>>> {
  const params = useParams<Params>();
  return get<Params, Path, DefaultValue>(params, path, defaultValue);
}

export function useParamsPathAsInt<
  Params extends object,
  Path extends string,
  DefaultValue,
>(
  path: F.AutoPath<Params, Path>,
  defaultValue?: O.Path<Params, S.Split<Path, '.'>> | DefaultValue,
): U.Nullable<number> {
  const params = useParams<Params>();
  const value = get<Params, Path, DefaultValue>(params, path, defaultValue);
  if (isNumber(value)) {
    return value;
  }
  const number = parseInt(value as string, 10);
  if (isNaN(number)) {
    return null;
  }
  return number;
}

export default useNavigate;
