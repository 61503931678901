import { Hex, isHexColor } from '@ui-system/color';
import { Theme } from '@ui-system/theme';
import { extractColorByName } from '@ui-system/theme/colors/extractColorByName';
import { F, U } from '@utils/ts';
import { get } from 'lodash';

import createFromProps from '../create-from-props';
import { Color, FromProps } from '../types';

const applyBackgroundColor: F.Function<[Theme, U.Nullable<Color | Hex>]> &
  FromProps = (theme, color) => {
  if (color && isHexColor(color)) {
    return `background-color: ${color};`;
  }
  const mode = get(theme.colors, theme.mode, null);
  const bgColor =
    color && mode
      ? extractColorByName(color as Color, theme.colors[theme.mode])
      : 'transparent';
  return `background-color: ${bgColor};`;
};

applyBackgroundColor.fromProps = createFromProps(applyBackgroundColor, 'bg');

export default applyBackgroundColor;
