import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getFieldErrorsByFieldPathSelector } from '../../store/stateSelector';
import { FieldPath, ValidationError } from '../../types';
import { useWatchField } from '../internal';
import useFormId from '../useFormId';

function useErrorMessages(fieldPath: FieldPath): ValidationError | null {
  useWatchField(fieldPath);
  const formId = useFormId();
  const selector = useCallback(
    state =>
      getFieldErrorsByFieldPathSelector(formId, fieldPath, EMPTY_ARRAY)(state),
    [formId, fieldPath],
  );
  const errors: ValidationError | null = useSelector(selector);
  return errors;
}

export default useErrorMessages;
