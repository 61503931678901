import MUIDivider from '@material-ui/core/Divider';
import componentModifiers from '@ui-system/default-modifiers/divider';
import defaultProps from '@ui-system/default-props/divider';
import { useDividerStyle } from '@ui-system/default-styles';
import { DividerProps, DividerType } from '@ui-system/interfaces-divider';
import { useHex } from '@ui-system/theme/colors';
import React from 'react';

const Divider: DividerType = ({
  style,
  modifiers,
  color,
  opacity,
}: DividerProps): React.ReactElement => {
  const hex = useHex(color);
  const finalStyle = useDividerStyle(style, modifiers, componentModifiers, {
    color,
    opacity,
  });
  return <MUIDivider style={finalStyle} color={hex} />;
};

Divider.defaultProps = defaultProps;

export default Divider;
