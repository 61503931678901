const namespace = 'distributor';
const modalNamespace = `${namespace}.modal`;

const DISTRIBUTOR = {
  MODAL: {
    TITLE: {
      key: `${modalNamespace}.title`,
      message: 'Distributor',
    },
    DESCRIPTION: {
      key: `${modalNamespace}.description`,
      message: 'Inform the distributor',
    },
  },
  SELECT_THE_DISTRIBUTOR: {
    key: `${namespace}.selectTheDistributor`,
    message: 'Select the distributor',
  },
  NOT_FOUND: {
    key: `${namespace}.notFound`,
    message: 'No distributors found on this country',
  },
};

const I18N = {
  DISTRIBUTOR,
};

export default I18N;
