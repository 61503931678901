import { css } from '@ui-system/css';
import {
  TABLE_ROW_UUID,
  TABLE_UUID,
  TableProps,
  TableRowProps,
} from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useTableDefaultStyle = makeStyle<Partial<TableProps>>(() => css``);

type TableRowStyleProps = Partial<TableProps & TableRowProps> & {
  isClickable?: boolean;
};

const useTableRowDefaultStyle = makeStyle<TableRowStyleProps>(
  ({ props }) => css`
    ${props?.isClickable ? 'cursor: pointer' : ''};
  `,
);

export const useTableStyle = makeFinalStyleHook<Partial<TableProps>>(
  TABLE_UUID,
  useTableDefaultStyle,
);

export const useTableRowStyle = makeFinalStyleHook<Partial<TableRowStyleProps>>(
  TABLE_ROW_UUID,
  useTableRowDefaultStyle,
);
