import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import autoBind from 'auto-bind';
import { isEmpty, map } from 'lodash';

import {
  GenerateQRCodePath,
  GetCountriesPath,
  GetLanguagesPath,
  GetSalesTypesPath,
  GetSalutationsPath,
  GetTitlesPath,
  ValidateAbnPath,
} from './apiPaths';
import DEFAULT_COUNTRIES_DATA from './data/defaultCountries.json';
import logger from './log';
import {
  ABNValidationResult,
  Country,
  DomainValue,
  Language,
  ResourceJSON,
  SalesType,
  Salutation,
  SimpleApiConnectionStatusJSON,
  Title,
} from './types';
import { AVAILABLE_VAT_COUNTRIES, validateVAT, validateZipcode } from './utils';

type Api = typeof defaultApiV2;

type CommonServiceOptions = {
  apiv2?: Api;
};

class CommonService {
  api: Api;

  constructor({ apiv2 }: CommonServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    autoBind(this);
  }

  static async getLocalAvailableCountries(): /* language: string */ Promise<
    Array<Country>
  > {
    // TODO: add cache
    return CommonService.transformCountries(DEFAULT_COUNTRIES_DATA);
  }

  async getCountries(language: string): Promise<Array<Country>> {
    logger.debug('Trying to get countries for language=%s', language);
    try {
      const response: ApiResponse<Array<DomainValue>> = await this.api.get(
        GetCountriesPath(language),
      );
      const { data } = response;
      logger.info(`Successfully got countries, size ${data.length}`);
      return CommonService.transformCountries(data);
    } catch (error) {
      logger.error('Error when getting countries, error=%O', error);
      throw error;
    }
  }

  async getLanguages(language: string): Promise<Array<Language>> {
    logger.debug('Trying to get languages for language=%s', language);
    try {
      const response: ApiResponse<Array<Language>> = await this.api.get(
        GetLanguagesPath(language),
      );
      const { data } = response;
      logger.info(`Successfully got languages, size ${data.length}`);
      return data;
    } catch (error) {
      logger.error('Error when getting languages, error=%O', error);
      throw error;
    }
  }

  async getSalesTypes(language: string): Promise<Array<SalesType>> {
    logger.debug('Trying to get sales types for language=%s', language);
    try {
      const response: ApiResponse<Array<SalesType>> = await this.api.get(
        GetSalesTypesPath(language),
      );
      const { data } = response;
      logger.info(`Successfully got sales types, size ${data.length}`);
      return data;
    } catch (error) {
      logger.error('Error when getting sales types, error=%O', error);
      throw error;
    }
  }

  async getSalutations(language: string): Promise<Array<Salutation>> {
    logger.debug('Trying to get salutations for language=%s', language);
    try {
      const response: ApiResponse<Array<ResourceJSON>> = await this.api.get(
        GetSalutationsPath(language),
      );
      const { data } = response;
      logger.info(`Successfully got salutations, size ${data.length}`);
      return CommonService.transformSalutations(data);
    } catch (error) {
      logger.error('Error when getting salutations, error=%O', error);
      throw error;
    }
  }

  async getTitles(language: string): Promise<Array<Title>> {
    logger.debug('Trying to get titles for language=%s', language);
    try {
      const response: ApiResponse<Array<ResourceJSON>> = await this.api.get(
        GetTitlesPath(language),
      );
      const { data } = response;
      logger.info(`Successfully got titles, size ${data.length}`);
      return CommonService.transformSalutations(data); // XXX: equivalent
    } catch (error) {
      logger.error('Error when getting titles, error=%O', error);
      throw error;
    }
  }

  async getApiConnectionStatus(): Promise<boolean> {
    logger.debug('Trying to check the api connection status');
    try {
      const response: ApiResponse<SimpleApiConnectionStatusJSON> =
        await this.api.get('health-check');
      const { data } = response;
      logger.info('Successfully connected to api server');
      return !isEmpty(data);
    } catch (error) {
      logger.error('Api connection failed');
      return false;
    }
  }

  static transformCountries(data: Array<DomainValue>): Array<Country> {
    return map(data, item => ({
      id: item.domkey,
      name: item.value,
    }));
  }

  static transformSalutations(data: Array<ResourceJSON>): Array<Salutation> {
    return map(data, item => ({
      id: item.resourcekey,
      name: item.resource,
    }));
  }

  static validateZipcode(zipcode: string, country: string): boolean | Error {
    logger.trace(
      'Trying to validate zipcode=%s for country=%s',
      zipcode,
      country,
    );
    try {
      const response = validateZipcode(zipcode, country);
      logger.debug('Successfully validated zipcode, result=%s', response);
      return response;
    } catch (error) {
      logger.error('Error when validating zipcode, error=%O', error);
      throw error;
    }
  }

  static isAvailableVATCountry(country: string): boolean {
    logger.trace('Checking if VAT is available for country=%s', country);
    try {
      const vatCountries = Object.keys(AVAILABLE_VAT_COUNTRIES);
      const response = vatCountries.includes(country);
      logger.debug(
        'Successfully checked VAT availability for country=%s',
        country,
        response,
      );
      return response;
    } catch (error) {
      logger.error(
        'Error checking VAT availability for country, error=%O',
        error,
      );
      throw error;
    }
  }

  static validateVAT(vat: string, country: string): boolean | Error {
    logger.trace('Trying to validate VAT=%s for country=%s', vat, country);
    try {
      if (CommonService.isAvailableVATCountry(country)) {
        const result = validateVAT(vat, country);
        const response =
          result.isValid && result?.country?.isoCode.short === country;
        logger.debug('Successfully validated VAT, result=%s', response);
        return response;
      }
      logger.debug('VAT validation not available for country=%s', country);
      return true;
    } catch (error) {
      logger.error('Error when validating VAT, error=%O', error);
      throw error;
    }
  }

  async validateABN(
    abn: string,
    orgunitID: string,
  ): Promise<ABNValidationResult> {
    logger.trace('Trying to validate ABN=%s', abn);
    try {
      const response: ApiResponse<ABNValidationResult> = await this.api.get(
        ValidateAbnPath(abn, orgunitID),
      );
      const { data } = response;
      logger.info(`Successfully validated abn`);
      return data;
    } catch (error) {
      logger.error('Error when validating abn, error=%O', error);
      throw error;
    }
  }

  async generateQRCode(url: string): Promise<unknown> {
    logger.trace('Trying to generate QR Code url=%s', url);
    try {
      const response: ApiResponse<unknown> = await this.api.get(
        GenerateQRCodePath(url),
      );
      const { data } = response;
      logger.info(`Successfully generated QR Code`);
      return data;
    } catch (error) {
      logger.error('Error when generating QR Code, error=%O', error);
      throw error;
    }
  }
}

export default CommonService;
export const DEFAULT_INSTANCE: CommonService = new CommonService({});
