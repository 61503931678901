import { ResourceBundle } from '@mmw/services-core-resource/types';
import { U } from 'ts-toolbelt';

export const CHANGE_LANGUAGE_START = '@mmw/i18n/CHANGE_LANGUAGE_START';
export const CHANGE_LANGUAGE_SUCCESS = '@mmw/i18n/CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_ERROR = '@mmw/i18n/CHANGE_LANGUAGE_ERROR';

export type ChangeLanguageStartAction = {
  type: '@mmw/i18n/CHANGE_LANGUAGE_START';
  payload: {
    language: string;
  };
};

export type ChangeLanguageSuccessAction = {
  type: '@mmw/i18n/CHANGE_LANGUAGE_SUCCESS';
  payload: {
    resourceBundle: ResourceBundle;
    language: string;
  };
};

export type ChangeLanguageErrorAction = {
  type: '@mmw/i18n/CHANGE_LANGUAGE_ERROR';
  payload: {
    error: Error;
  };
};

export type State = {
  currentLanguage: string;
  loading: boolean;
  resourceBundle: ResourceBundle;
  error: U.Nullable<Error>;
  bundleName: string;
  secondaryBundleName: U.Nullable<string>;
};

export type Action =
  | ChangeLanguageStartAction
  | ChangeLanguageSuccessAction
  | ChangeLanguageErrorAction;

export type Reducer = (state: State, action: Action) => State;
