import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getUserProfileService } from '@mmw/services-holder';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  getSalesOrgBrandsListErrorAction,
  getSalesOrgBrandsListSuccessAction,
} from '../actions';
import { GET_SALES_ORG_BRANDS_LIST_START } from '../types';

const { logger } = contextualConfig.application;

const getSalesOrgBrandsEpic = action$ =>
  action$.pipe(
    ofType(GET_SALES_ORG_BRANDS_LIST_START),
    tap(() => logger.debug('Will load the sales org brands data')),
    switchMap(() =>
      from(getUserProfileService().getLoggedUserSalesOrgBrands()).pipe(
        map(data => getSalesOrgBrandsListSuccessAction(data)),
        catchError(error => of(getSalesOrgBrandsListErrorAction(error))),
      ),
    ),
  );

export default getSalesOrgBrandsEpic;
