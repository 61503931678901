import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// polyfill required by IE11 to use window.fetch API
import 'isomorphic-fetch';
// polyfills required by exceljs
import 'core-js/modules/es.promise';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.object.assign';
import 'core-js/modules/es.object.keys';
import 'core-js/modules/es.symbol';
import 'core-js/modules/es.symbol.async-iterator';
import 'regenerator-runtime/runtime';
import '@mmw/api-v2/ie11Polyfill'; // XXX: IE11 headers polyfill

// nanoid polyfill for IE11
if (!window.crypto) {
  // @ts-ignore
  window.crypto = window.msCrypto;
}
