import {
  useAdminMode,
  useAdminModeIDsValue,
  useAdminModePrintID,
  useAdminModeShowKeysValue,
  useAdminModeValue,
} from '@i18n/ui-admin/admin-mode';
import isIE11 from '@mmw/constants-devices/isIE11';
import { I18nShape } from '@mmw/constants-i18n';
import contextualConfig from '@mmw/contextual-config';
import ResourcesContext from '@mmw/ui-providers-resources';
import { isEmpty } from 'lodash';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactDOM from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';
import styled from 'styled-components';

if (!isIE11) {
  try {
    const adminModeIDs =
      new URLSearchParams(window.location.search).get('adminModeIDs') ===
      'true';

    const adminMode =
      new URLSearchParams(window.location.search).get('adminMode') === 'true';

    const adminModeShowKeys =
      new URLSearchParams(window.location.search).get('adminModeShowKeys') ===
      'true';
    useAdminMode.setState({
      adminModeIDs,
      adminMode,
      adminModeShowKeys,
    });
  } catch (error) {
    // console.log(error);
  }
}

const POPPER_MODIFIERS = {
  preventOverflow: {
    enabled: true,
    boundariesElement: 'viewport',
    escapeWithReference: false,
  },
};

type Props = {
  i18n?: I18nShape;
  children: ReactNode | ReactElement;
};

const createTooltipContainer = (): HTMLElement => {
  let element = document.querySelector('#resourceTooltip');
  if (element == null) {
    element = document.createElement('div');
    element.setAttribute('id', 'resourceTooltip');

    document.body.appendChild(element);
  }
  // @ts-ignore
  return element;
};

const TooltipElement = styled.span`
  cursor: default;
  padding: 0 5px;
  :hover {
    background: rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
  }
`;

const TootipContent = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fonts.fontFamily};
    background-color: #f5f5f9;
    color: rgba(0, 0, 0, 0.87);
    max-width: 600px;
    font-size: 1rem;
    border: 1px solid #dadde9;
    padding: 10px;
    z-index: 1100;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow-x: auto;
  `};
`;

const KeyWithPrefix = styled.div`
  display: inline-block;
  font-size: 12px;
`;

const KeyContainer = styled.p`
  display: flex;
  justify-content: flex-start;
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CopyButton = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    width: 150px;
    background-color: ${theme.colors.pallet.primary};
    color: ${theme.colors.pallet.light};
    cursor: pointer;
    padding: 10px;
  `};
`;

const CallbackMessage = styled.span`
  color: red;
  font-weight: bold;
  margin-top: 5px;
`;

export const ResourceKeyTooltip: React.FC<Props> = ({
  i18n,
  children,
}: Props) => {
  const adminMode = useAdminModeValue();
  const adminModeIDs = useAdminModeIDsValue();
  const adminModeShowKeys = useAdminModeShowKeysValue();
  const { prefixKey } = useContext(ResourcesContext);

  const [contentCopied, setCopied] = useState(false);
  const [popped, popTooltip] = useState(false);
  const copiedCallback = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }, [setCopied]);
  // @ts-ignore
  const { key, message, meta } = i18n || {};
  const { key: metaKey, bundleName } = meta || {};
  const campaignPrefixKey = useMemo(() => {
    if (!isEmpty(prefixKey) && key) {
      return `${prefixKey}.${key}`;
    }
    return undefined;
  }, [key, prefixKey]);
  const id = useAdminModePrintID(key || '');
  if ((!adminMode && !adminModeIDs && !adminModeShowKeys) || isEmpty(i18n))
    return <>{children}</>;
  return (
    <Manager>
      {adminMode &&
        popped &&
        ReactDOM.createPortal(
          // @ts-ignore
          <Popper modifiers={POPPER_MODIFIERS}>
            {({ ref, placement, style }) => {
              if (!message && (!key || !metaKey)) return null;
              return (
                <TootipContent
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  onFocus={() => popTooltip(true)}
                  onMouseEnter={() => popTooltip(true)}
                  onMouseLeave={() => popTooltip(false)}
                >
                  {campaignPrefixKey && (
                    <KeyWithPrefix>
                      <strong>
                        Custom resource by campaign supported using key:{' '}
                      </strong>
                      {campaignPrefixKey}
                    </KeyWithPrefix>
                  )}
                  <KeyContainer>
                    {`ResourceKey: ${metaKey || key}`}
                  </KeyContainer>
                  <p>{`Resource: ${message}`}</p>
                  <p>{`Bundle: ${
                    bundleName ||
                    contextualConfig.application.defaultResourceBundleName
                  }`}</p>
                  <CopyToClipboard
                    text={key || metaKey}
                    onCopy={copiedCallback}
                  >
                    <CopyButton onClick={e => e.stopPropagation()}>
                      Copy key
                    </CopyButton>
                  </CopyToClipboard>
                  {contentCopied && (
                    <CallbackMessage> Key copied!</CallbackMessage>
                  )}
                </TootipContent>
              );
            }}
          </Popper>,
          createTooltipContainer(),
        )}
      <Reference>
        {({ ref }) => (
          <TooltipElement
            ref={ref}
            onFocus={() => popTooltip(true)}
            onMouseOver={() => popTooltip(true)}
            onMouseLeave={() => popTooltip(false)}
          >
            {adminModeShowKeys ? (
              <CallbackMessage>
                {key || 'MISSING DEFAULT RESOURCE'}
              </CallbackMessage>
            ) : (
              children
            )}
            {adminModeIDs && <CallbackMessage>{id}</CallbackMessage>}
          </TooltipElement>
        )}
      </Reference>
    </Manager>
  );
};

ResourceKeyTooltip.defaultProps = {
  i18n: undefined,
};
