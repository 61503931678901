import { I18nShape } from '@mmw/constants-i18n';
import {
  I18N,
  NotificationConfig,
  NotificationConfigObject,
  NotificationOptions,
} from '@mmw/ui-hybrid-libraries-notifications/hooks/types';
import {
  DEFAULT_CONFIG,
  getI18n,
  modifiersMap,
} from '@mmw/ui-hybrid-libraries-notifications/hooks/utils';
import UI from '@ui-system/ui';
import React, { useCallback } from 'react';
import { AddToast, useToasts } from 'react-toast-notifications';
import { F } from 'ts-toolbelt';

export type ToastParams = [
  React.ReactNode,
  NotificationOptions,
  (id: string) => void,
];

function setToastParams(
  i18n: I18N | I18nShape,
  config: NotificationConfig = DEFAULT_CONFIG,
): ToastParams {
  // config can be a string, a "config object" or undefined
  // @ts-ignore
  const toastOptions: NotificationConfigObject =
    typeof config === 'string'
      ? { ...DEFAULT_CONFIG, appearance: config }
      : { ...DEFAULT_CONFIG, ...config };
  const { appearance } = toastOptions;
  // i18n could be { title: I18nShape, content: I18nShape } or an I18nShape itself
  // in web case we are actually using only one I18nShape prioritizing content
  const { title, content } = getI18n(i18n);
  const modifiers = appearance ? modifiersMap[appearance] : undefined;
  return [
    <UI.Container direction="column">
      {title ? (
        <UI.Caption i18n={title} modifiers={`${modifiers}, bold`} />
      ) : null}
      {content ? <UI.Caption modifiers={modifiers} i18n={content} /> : null}
    </UI.Container>,
    toastOptions,
    toastOptions.onDismiss,
  ];
}

// use when you can set i18n and/or config as constant
type UseNotificationCb = F.Function<[], ReturnType<AddToast>>;
const useNotification = (
  i18n: I18N | I18nShape,
  config?: NotificationConfig,
): UseNotificationCb => {
  const { addToast } = useToasts();
  return useCallback(() => {
    addToast(...setToastParams(i18n, config));
  }, [addToast, config, i18n]);
};

// use when you need to set i18n and/or config dynamically
type UseNotificationsCb = F.Function<
  [I18N | I18nShape, NotificationConfig?],
  ReturnType<AddToast>
>;
const useNotifications = (): UseNotificationsCb => {
  const { addToast } = useToasts();
  return useCallback(
    (i18n: I18N | I18nShape, config?: NotificationConfig) => {
      addToast(...setToastParams(i18n, config));
    },
    [addToast],
  );
};

// TODO:
// web components are using useNotifications unnecessarily
// refactor those componentes to useNotification instead
// XXX: WRAPPER TO PREVENT WEB PAGE WITH NO OLD THEME CONFIGURED TO BREAK
export {
  useNotification as useSystemNotification,
  useNotifications as useSystemNotifications,
};
