import { values } from 'lodash';

export const INSTALLER_REQUEST_FILTERS_JSON = {
  COMPANY: 'company',
  STREET: 'street',
  NR: 'nr',
  ZIPCODE: 'zipcode',
  CITY: 'city',
  COUNTRY: 'country',
  STATE: 'state',
};
export const INSTALLER_REQUEST_FILTERS_JSON_FIELDS_LIST = values(
  INSTALLER_REQUEST_FILTERS_JSON,
);

export const INSTALLER_JSON_FIELD_PATHS = {
  COMPANY: 'address.company',
  STREET: 'address.street',
  NUMBER: 'address.nr',
  ZIPCODE: 'address.zipcode',
  CITY: 'address.city',
  COUNTRY: 'address.country',
  STATE: 'address.state',
  EMAIL: 'email',
  PHONE: 'phone',
};

export const DELIVERY_REQUEST_FIELD_PATHS = {
  ORDER_NO: 'ordernumber',
  DELIVERY_NO: 'deliverynumber',
  PALLET_NO: 'palletnumber',
  SERIAL_NO: 'serialnumber',
};
