import { BasePath } from '@di/core';
import qs from 'qs';

const BASE_PATHS = {
  MANU: '/v1/manu/solar-delivery',
  TRADER: '/v1/trader/solar-delivery',
};

export const [
  getSolarDeliveryBasePath,
  setSolarDeliveryBasePath,
  SOLAR_DELIVERY_BASE_PATH_TOKEN,
] = BasePath.createDI<string, 'SOLAR_DELIVERY_SERVICE_BASE_PATH_TOKEN'>(
  'SOLAR_DELIVERY_SERVICE_BASE_PATH_TOKEN',
);

export function setManufacturerSolarDeliveryBasePath() {
  setSolarDeliveryBasePath(BASE_PATHS.MANU);
}

export function setTraderSolarDeliveryBasePath() {
  setSolarDeliveryBasePath(BASE_PATHS.TRADER);
}

export function getPaths(basePath: string | null) {
  return {
    SearchDeliveriesPath: (): string =>
      `${basePath}/tree?${qs.stringify({
        fetchChildren: true,
      })}`,
  };
}
