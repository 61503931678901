import { EMPTY_ARRAY, EMPTY_OBJECT } from '@mmw/constants-utils';

import applyThisStylePath from './apply-this-style-path';
import mergeStyle from './mergeStyle';
import use from './use';

const utils = {
  use,
  applyThisStylePath,
  mergeStyle,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
};

export {
  applyThisStylePath,
  utils as default,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  mergeStyle,
  use,
};
