import { css } from '@ui-system/css';
import {
  BUTTON_UUID,
  DATE_INPUT_UUID,
  FORM_INPUT_UUID,
  FORM_SELECT_UUID,
} from '@ui-system/interfaces';
import { makeStyles } from '@ui-system/style';
import { RootStylesProvider } from '@ui-system/styles-provider';
import React from 'react';

const useStyles = makeStyles(({ utils, theme }) => ({
  [BUTTON_UUID]: css`
    border: 2px solid ${theme.colors[theme.mode].primary.main};
    border-color: ${theme.colors[theme.mode].primary.main};
    box-shadow: none;
  `,
  [FORM_INPUT_UUID]: css`
    padding: 2px 10px;
    border: 1px solid ${theme.colors[theme.mode].gray.A300};
    font-size: 12px;
    min-height: 45px;
    ${utils.applyBorderRadius(theme, 4)};
  `,
  [FORM_SELECT_UUID]: css`
    padding: 0 10px;
    width: 100%;
    text-transform: uppercase;
    min-height: 45px;
    border: 1px solid ${theme.colors[theme.mode].gray.A300};
    ${utils.applyBorderRadius(theme, 4)};
  `,
  [DATE_INPUT_UUID]: css`
    padding: 2px 10px;
    border: 1px solid ${theme.colors[theme.mode].gray.A300};
    font-size: 12px;
    height: 45px;
    width: 100%;
    z-index: 0;
    ${utils.applyBackgroundColor(theme, 'white')};
    ${utils.applyBorderRadius(theme, 4)};
  `,
}));

export const StylesProvider: React.FC<React.PropsWithChildren<any>> = ({
  children,
}: React.PropsWithChildren<any>) => {
  const styles = useStyles();
  return <RootStylesProvider styles={styles}>{children}</RootStylesProvider>;
};
