import { PopoverProps as MuiPopoverProps } from '@material-ui/core';
import { F } from '@utils/ts';
import { nanoid } from 'nanoid';
import * as React from 'react';

export type PopoverProps = {
  children?: string | React.ReactNode;
  HoverElement?: string | React.ReactNode;
  sx?: Array<F.Function | object | boolean> | F.Function | object;
} & MuiPopoverProps;

export type HoverElementProps = Partial<MuiPopoverProps>;

export const POPOVER_UUID = nanoid();

export type PopoverType = React.FC<PopoverProps>;
