import { intersection } from 'lodash';

const numbers = '0123456789';
const numbersChars = numbers.split('');
const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const lettersChars = letters.split('');
const lowerLetterChars = letters.toLowerCase().split('');
const specialChars = '!=^*_?@-';
const specialCharsList = specialChars.split('');
const possibleCharacters =
  specialChars +
  letters +
  specialChars +
  numbers +
  specialChars +
  letters.toLowerCase();

function getRandomChar() {
  return possibleCharacters.charAt(
    Math.floor(Math.random() * possibleCharacters.length),
  );
}

export function getRandomInt(min: number, max: number): number {
  const internalMin = Math.ceil(min);
  const internalMax = Math.floor(max);
  return Math.floor(Math.random() * (internalMax - internalMin)) + internalMin;
}

function tryGeneratePassword() {
  let pass = '';
  let i = 0;
  while (i <= 10) {
    pass += getRandomChar();
    // eslint-disable-next-line no-plusplus
    i++;
  }
  return pass;
}

function isPasswordValid(pass) {
  const passChars = pass.split('');
  const hasNumbers = intersection(numbersChars, passChars).length > 0;
  const hasLetters = intersection(lettersChars, passChars).length > 0;
  const hasLowerLetters = intersection(lowerLetterChars, passChars).length > 0;
  const hasSpecials = intersection(specialCharsList, passChars).length > 0;
  return hasNumbers && hasLetters && hasLowerLetters && hasSpecials;
}

// eslint-disable-next-line import/prefer-default-export
export function generateComplexPassword() {
  const pass = tryGeneratePassword();
  if (!isPasswordValid(pass)) {
    return generateComplexPassword();
  }
  return pass;
}
