import pxPerfect from '@ui-system/px-perfect';
import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';
import { get, isString, join, map, split, take } from 'lodash';

import createFromProps from '../create-from-props';
import { FromProps, Padding } from '../types';

const applyPadding: F.Function<[Theme, Padding]> & FromProps = (
  theme,
  padding: Padding,
) => {
  let values;
  let value;
  if (isString(padding))
    values = map(split(padding, ','), item => Number(item));
  const spacing = get(theme, ['spacing', padding]);
  if (spacing && !values) value = spacing;
  if (value) return `padding: ${pxPerfect(value)}`;
  if (values) {
    const realValues = map(take(values, 4), vl =>
      pxPerfect(theme.spacing[vl as number]),
    );
    return `padding: ${join(realValues, ' ')}`;
  }
  return '';
};
applyPadding.fromProps = createFromProps(applyPadding, 'p');

export default applyPadding;
