import { AddressJSON } from '@mmw/services-core-common/types';
import { get, isEmpty } from 'lodash';
import { U } from 'ts-toolbelt';

type Address = AddressJSON;

export const joinStreetAndNumber = (
  address: U.Nullable<Partial<Address>>,
): U.Nullable<string> => {
  if (!address) return '';
  if (isEmpty(address.street)) return null;
  const street = get(address, 'street');
  const nr = get(address, 'nr');
  if (!isEmpty(street) && !isEmpty(nr)) {
    return `${street}, ${nr}`;
  }
  return `${street}`;
};

const joinAddress = (
  address: U.Nullable<Partial<Address>>,
): U.Nullable<string> => {
  if (!address) return null;
  if (isEmpty(address.street) || isEmpty(address.zipcode)) return null;
  let string = joinStreetAndNumber(address);
  const city = get(address, 'city');
  const state = get(address, 'state');
  const zipcode = get(address, 'zipcode');
  const countryCode = get(address, 'country');
  if (!isEmpty(zipcode)) string += `, ${zipcode}`;
  if (!isEmpty(zipcode)) string += `, ${city}`;
  if (!isEmpty(state)) string += `, ${state}`;
  if (!isEmpty(countryCode)) string += `, ${countryCode}`;
  return string;
};

export default joinAddress;
