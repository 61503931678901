import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse as ApiResponseType,
} from 'axios';

import {
  badResponseInterceptor,
  goodRequestInterceptor,
  goodResponseInterceptor,
} from './interceptor';
import logger from './log';

export * from 'axios';

export type Api = AxiosInstance;
export type CreateApiOptions = AxiosRequestConfig & { baseURI?: string };

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const createApi = ({
  baseURI,
  baseURL,
  headers,
  ...otherOptions
}: CreateApiOptions): Api => {
  logger.debug(
    `Starting api with baseURI=${baseURI} and options=%O`,
    otherOptions,
  );
  const api = axios.create({
    baseURL: baseURL || baseURI,
    // XXX: https://developer.mozilla.org/en-US/docs/Web/API/Request/mode
    // mode: isDevelopment() ? 'cors' : 'same-origin',
    // credentials: isDevelopment() ? 'cors' : 'same-origin',
    withCredentials: true, // XXX: we changed libs here ... axios expects true only =/
    // withCredentials: isDevelopment(),
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    ...otherOptions,
  });
  api.interceptors.request.use(goodRequestInterceptor);
  api.interceptors.response.use(
    goodResponseInterceptor,
    badResponseInterceptor,
  );
  return api;
};

export default createApi;
export type ApiResponse<T> = ApiResponseType<T>;
