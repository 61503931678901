import { I18nShape } from '@mmw/constants-i18n';
import { useFastFormField } from '@mmw/redux-store-fast-form/hooks/fields';
import { Style } from '@ui-system/interfaces';
import UI from '@ui-system/ui';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';

interface Props {
  fieldPath: string;
  i18n?: I18nShape;
  style?: Style;
}

const FastFormSwitch: React.FC<Props> = ({ fieldPath, i18n, style }: Props) => {
  const { value, setValue, errors, canShowError } =
    useFastFormField<boolean>(fieldPath);
  const caption: I18nShape = {
    message: errors?.message,
    key: 'error.key',
    values: { value },
  };

  const onChange = useCallback(() => {
    setValue(!value);
  }, [setValue, value]);

  return (
    <UI.Form.Switch
      checked={value}
      onChange={onChange}
      labelI18n={i18n}
      error={canShowError && !isEmpty(errors)}
      caption={caption}
      style={style}
    />
  );
};

FastFormSwitch.defaultProps = {
  i18n: undefined,
};
export default FastFormSwitch;
