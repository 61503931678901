import { useDeepCompareMemo } from '@react-utils/hooks';
import { ContainerType } from '@ui-system/interfaces-container';
import { isReactNative } from 'is-react-native';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import {
  BaseColumn,
  BaseColumnPressable,
  BaseRow,
  BaseRowPressable,
  Container,
} from './Elements';
import { ContainersProps } from './types';

export * from './types';

export const Row: React.FC<ContainersProps> = ({
  children,
  spacing,
  align,
  gap,
  justify,
  autoWidth,
  flex,
  hideContent,
  w,
  h,
  onClick,
  ...props
}: ContainersProps) => {
  const theme = useTheme();
  // @ts-ignore
  const { values } = theme.spacing;

  const otherProps = useDeepCompareMemo(
    () => ({
      ...props,
      [isReactNative() ? 'onPress' : 'onClick']: onClick,
      style: props.style,
    }),
    [onClick, props],
  );

  const Comp = useMemo<ContainerType>(
    () => (onClick ? BaseRowPressable : BaseRow) as unknown as ContainerType,
    [onClick],
  );

  return (
    <Comp
      flex={flex}
      spacing={spacing}
      justify={justify}
      align={align}
      autoWidth={autoWidth}
      hideContent={hideContent}
      w={w}
      h={h}
      {...otherProps}
    >
      {React.Children.map(children, (child, i) => {
        if (!child || !React.isValidElement(child)) return null;
        if (i === 0) return child;
        const contentStyle = get(child, 'props.style', {});
        return React.cloneElement(child, {
          style: { ...contentStyle, marginLeft: values[gap] },
        });
      })}
    </Comp>
  );
};

export const Column: React.FC<ContainersProps> = ({
  children,
  spacing,
  align,
  gap,
  justify,
  autoWidth,
  innerRef,
  flex,
  w,
  h,
  onClick,
  ...props
}: ContainersProps) => {
  const theme = useTheme();
  // @ts-ignore
  const { values } = theme.spacing;

  const otherProps = useDeepCompareMemo(
    () => ({
      ...props,
      [isReactNative() ? 'onPress' : 'onClick']: onClick,
      style: props.style,
    }),
    [onClick, props],
  );

  const Comp = useMemo<ContainerType>(
    () =>
      (onClick ? BaseColumnPressable : BaseColumn) as unknown as ContainerType,
    [onClick],
  );

  return (
    <Comp
      flex={flex}
      spacing={spacing}
      justify={justify}
      align={align}
      autoWidth={autoWidth}
      ref={innerRef}
      w={w}
      h={h}
      {...otherProps}
    >
      {React.Children.map(children, (child, i) => {
        if (!child || !React.isValidElement(child)) return null;
        if (i === 0) return child;
        const contentStyle = get(child, 'props.style', {});
        return React.cloneElement(child, {
          style: { ...contentStyle, marginTop: values[gap] },
        });
      })}
    </Comp>
  );
};

Row.defaultProps = {
  autoWidth: false,
};

Column.defaultProps = {
  autoWidth: false,
};

export default Container;
