import { Container } from '@ui-system/common-container';
import { Icon } from '@ui-system/common-icon';
import React from 'react';
import { F } from 'ts-toolbelt';

interface Props {
  onClick: F.Function;
  back?: boolean;
  hide?: boolean;
  size?: number;
}

const Arrow: React.FC<Props> = ({ onClick, back, hide, size }: Props) => {
  if (hide) return null;
  return (
    <Container align="center">
      <Icon name={back ? 'back' : 'next'} size={size} onClick={onClick} />
    </Container>
  );
};

Arrow.defaultProps = {
  size: 20,
};

export default Arrow;
