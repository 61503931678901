import LANGUAGE_CODES from './languageCodes';

export { default as getLanguageCodeFromCountryCode } from './getLanguageCodeFromCountryCode';
export default LANGUAGE_CODES;
export type LanguageCode = typeof LANGUAGE_CODES[keyof typeof LANGUAGE_CODES];
export const {
  BG,
  CZ,
  DE,
  DK,
  EN,
  ES,
  FI,
  FR,
  HU,
  IT,
  NL,
  PL,
  PT,
  RO,
  SE,
  SK,
  JA,
} = LANGUAGE_CODES;
