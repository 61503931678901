import { PerfectScroll } from '@perfect-scroll/core';
import { Container } from '@ui-system/common-container';
import { useScrollStyle } from '@ui-system/default-styles/scroll';
import { ScrollProps, ScrollType } from '@ui-system/interfaces-scroll';
import React, { CSSProperties } from 'react';

const Scroll: ScrollType = ({
  height,
  width,
  style,
  children,
  maxWidth,
  maxHeight,
  ...rest
}: ScrollProps) => {
  const scrollStyle = useScrollStyle(style as CSSProperties, '', null, {
    height,
    width,
    maxWidth,
    maxHeight,
  });
  return (
    <PerfectScroll>
      <Container style={scrollStyle} w={width} h={height} {...rest}>
        {children}
      </Container>
    </PerfectScroll>
  );
};

export default Scroll;
