import { BRAND_MAPPING, SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';

import logger from './log';

// eslint-disable-next-line import/prefer-default-export
export function mapToBrandCode(salesOrgBrand: SalesOrgBrand | null | void) {
  if (!salesOrgBrand || salesOrgBrand <= 0) {
    return null;
  }
  const brand = BRAND_MAPPING[salesOrgBrand];
  if (!brand) {
    logger.error(
      `Could not map salesOrgBrand=${salesOrgBrand} to brand`,
      BRAND_MAPPING,
    );
    return null;
  }
  return `A${brand}`;
}
