import './wrapper.css';

import { useIcon } from '@lib/icons-set-provider';
import IconButton from '@material-ui/core/IconButton';
import { isHexColor } from '@ui-system/color';
import Container from '@ui-system/common-container';
import defaultProps from '@ui-system/default-props/icon';
import { IconProps } from '@ui-system/interfaces-icon';
import { useHex } from '@ui-system/theme/colors';
import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { Props, ReactSVG } from 'react-svg';

const BUTTON_STYLE = { padding: 6 };

const getBeforeInjection: (params: {
  height?: number;
  width?: number;
}) => Props['beforeInjection'] =
  ({ height, width }) =>
  svg => {
    svg.setAttribute('display', 'flex');
    if (height) svg.setAttribute('height', `${height}px`);
    if (width) svg.setAttribute('width', `${width}px`);
  };

const Icon: React.FC<IconProps> = ({
  name,
  size,
  width,
  height,
  color,
  transform,
  onClick,
  svg,
  style,
}: IconProps) => {
  // @ts-ignore
  const icon = useIcon(name);
  // @ts-ignore
  let fill = useHex(color);
  if (color && isHexColor(color)) {
    fill = color;
  }
  const sizes = useMemo(
    () => ({ height: height || size, width: width || size }),
    [height, width, size],
  );
  const finalStyle = useMemo(
    () => ({
      // height: size,
      // width: size,
      transform,
      fill,
      ...sizes,
      ...style,
    }),
    [fill, sizes, style, transform],
  );
  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      if (onClick) {
        onClick();
      }
    },
    [onClick],
  );
  const clickableIconFinalStyle = useMemo(
    () => ({
      ...finalStyle,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
    }),
    [finalStyle],
  );
  const customButtonStyle = useMemo(
    () => ({ ...BUTTON_STYLE, ...style }),
    [style],
  );

  if (!icon && !svg) return null;

  if (onClick) {
    return (
      <Container style={style}>
        <IconButton style={customButtonStyle} onClick={handleClick}>
          <ReactSVG
            src={svg || icon}
            style={clickableIconFinalStyle}
            beforeInjection={getBeforeInjection(sizes)}
          />
        </IconButton>
      </Container>
    );
  }
  return (
    <ReactSVG
      src={svg || icon}
      style={finalStyle}
      beforeInjection={getBeforeInjection(sizes)}
      className="web-icon-wrapper-class"
    />
  );
};

Icon.defaultProps = defaultProps;

export default Icon;

export const AnimatedIcon = null;
