export default {
  left: () => `
    text-align: left;
  `,
  right: () => `
    text-align: right;
  `,
  center: () => `
    text-align: center;
  `,
  start: () => `
    text-align: start;
  `,
  end: () => `
    text-align: end;
  `,
  justify: () => `
    text-align: justify;
  `,
  justifyAll: () => `
    text-align: justify-all;
  `,
  matchParent: () => `
    text-align: match-parent;
  `,
};
