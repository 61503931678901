import { INFORMATION } from '@mmw/constants-i18n-common';

const juraNamespace = 'jura';
const uploadFilesNamespace = 'uploadFiles';
const uploadRegistrationSheetNameSpace = 'uploadRegistrationSheetNameSpace';
const wizardSuccessBasic = `${juraNamespace}.wizardSuccessBasic`;
const questionsWarning = `${juraNamespace}.questionsWarning`;

const UPLOAD_FILES = {
  IBV0: {
    key: `${juraNamespace}.${uploadFilesNamespace}.ibv0`,
    message: 'Upload IBV card',
  },
  IBV1: {
    key: `${juraNamespace}.${uploadFilesNamespace}.ibv1`,
    message: 'Upload purchase files',
  },
  IBV2: {
    key: `${juraNamespace}.${uploadFilesNamespace}.ibv2`,
    message: 'Upload instructions files',
  },
};

const UPLOAD_REGISTRATION_SHEET_STEP = {
  TITLE: {
    key: `${juraNamespace}.${uploadRegistrationSheetNameSpace}.title`,
    message: 'Upload Registration Sheet',
  },
  REGISTRATION_SHEET_LABEL: {
    key: `${juraNamespace}.${uploadRegistrationSheetNameSpace}.label`,
    message: 'Commissioning card',
  },
  REGISTRATION_SHEET_DESCRIPTION: {
    key: `${uploadRegistrationSheetNameSpace}.description`,
    message:
      'Upload the image (photo or scanner) of the product registration sheet filled with the purchase and buyer data.',
  },
  INSTALLATION_INSTRUCTIONS_DESCRIPTION: {
    key: `${uploadRegistrationSheetNameSpace}.installationInstructions.description`,
    message:
      'Upload the image (photo or scanner) of the product registration sheet filled with the checklist for installation instructions.',
  },
  UPLOAD_ANOTHER_DOCUMENT: {
    key: `${juraNamespace}.${uploadRegistrationSheetNameSpace}.uploadAnotherDocument`,
    message: 'Upload another document',
  },
  FILES_SUMMARY: {
    IBV0_FILE_LABEL: {
      key: `${juraNamespace}.${uploadRegistrationSheetNameSpace}.ibv0FileLabel`,
      message: 'Purchase data with checklist',
    },
    IBV1_FILE_LABEL: {
      key: `${juraNamespace}.${uploadRegistrationSheetNameSpace}.ibv1Label`,
      message: 'Purchase and client data',
    },
    IBV2_FILE_LABEL: {
      key: `${juraNamespace}.${uploadRegistrationSheetNameSpace}.ibv2Label`,
      message: 'Instructions checklist',
    },
  },
  UPLOAD_MODE_SWITCHER: {
    SINGLE_FILE_LABEL: {
      key: `${juraNamespace}.${uploadRegistrationSheetNameSpace}.switcherSingleFileLabel`,
      message: 'Upload purchase data and checklist in one file',
    },
    TWO_FILES_LABEL: {
      key: `${juraNamespace}.${uploadRegistrationSheetNameSpace}.switcherTwoFilesLabel`,
      message: 'Upload purchase data pass and checklist separately',
    },
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${uploadRegistrationSheetNameSpace}.help`,
      message:
        'You will find the Registration Sheet in the product packaging, please answer the short questionnaire, with the data and signatures and send the image of the document (photographed or scanned) through the Upload File button.',
    },
  },
  CORRECT_FILES_WITH_ISSUES: {
    key: `${juraNamespace}.${uploadRegistrationSheetNameSpace}.correctFilesWithIssues`,
    message: 'Please upload the correction files indicated below',
  },
};

const REGISTRATION_STATUS = {
  STATUS_DESCRIPTION: {
    key: `${juraNamespace}.campaignStatusDescription`,
    message: '*Wenn gültig = Vorbehaltlich der Prüfung durch JURA.',
  },
};

const WIZARD_SUCCESS_BASIC = {
  ANNOUNCE_ONE: {
    key: `${wizardSuccessBasic}.announceOne`,
    message:
      'Vielen Dank für Ihre Registrierung. Diese können Sie nun in Ihrer Registrierungsliste einsehen.',
  },
  ANNOUNCE_TWO: {
    key: `${wizardSuccessBasic}.announceTwo`,
    message:
      'Um eine Neue Registrierung durchzuführen, klicken Sie bitte auf den entsprechenden Button.',
  },
};

const QUESTIONS_WARNING = {
  POINT_OF_SALE: {
    LOCAL_STORE: {
      WARNING: {
        key: `${questionsWarning}.localStoreText`,
        message:
          'Bitte bestätigen Sie, dass es sich tatsächlich um eine stationäre Inbetriebnahme gehandelt hat.',
      },
      ADDITIONAL_INFO: {
        key: `${questionsWarning}.localStoreAdditionalInfo`,
        message:
          'Sie haben eine Inbetriebnahme im stationären Ladengeschäft vorgenommen, jedoch weicht die Postleitzahl des Endkunden wesentlich von der Ihres Ladengeschäftes ab. Bitte bestätigen Sie, dass es sich tatsächlich um eine stationäre Inbetriebnahme gehandelt hat. Alternativ können Sie auch eine virtuelle Inbetriebnahme registrieren. Bitte beachten Sie in diesem Fall, dass die virtuelle Inbetriebnahme „gleichwertig“ zur stationären Inbetriebnahme sein muss, d. h. eine Inbetriebnahme per Telefon entspricht nicht diesen Qualitätsansprüchen!',
      },
    },
    ONLINE: {
      WARNING: {
        key: `${questionsWarning}.onlineText`,
        message:
          'Die virtuelle Inbetriebnahme wird in gleicher Höhe wie die stationäre Inbetriebnahme vergütet. Aus diesem Grund muss die virtuelle Inbetriebnahme qualitativ gleichwertig, unter Berücksichtigung der Besonderheiten des Vertriebskanals, zur stationären Inbetriebnahme sein. Um eine qualitativ gleichwertige Leistung zu erbringen, sind aus Sicht von JURA folgende Leistungen notwendig:',
      },
      ADDITIONAL_INFO: {
        key: `${questionsWarning}.onlineAdditionalInfo`,
        message:
          '<ol><li>Die Inbetriebnahme muss auf dem gleichen Gerätetyp erfolgen, den der Kunde erworben hat. <br /></li><li>Es muss eine Inbetriebnahme durch geschultes Fachpersonal erfolgen. Demzufolge muss dieser Mitarbeiter über ausreichende Kenntnisse mit JURA-Kaffeevollautomaten und den Umgang mit Übertragungstechnik verfügen. <br/></li><li>Die Inbetriebnahme muss alle Punkte der Inbetriebnahmekarte umfassen. <br/></li><li>Bei der virtuellen Inbetriebnahme muss hochwertiges Equipment eingesetzt werden, d.h. <br/> <ol type="a"><li>eine hochauflösende Kamera auf einem Stativ</li><li>Technik, die eine sehr gute Bild- und Tonqualität gewährleistet</li><li>eine störungsfreie Datenübertragung</li><li>die Einweisung muss bidirektional sein und Frage des Kunden müssen beantwortet werden</li></ol> <br /></li><li>Die vollständig ausgefüllte Original-IB-Karte mit den Unterschriften des Endkunden (bei der IB-Leistung/Datenverarbeitung) und des Fachhändlers (IB-Leistung) muss hochgeladen werden. <br /></li></ol>',
      },
    },
  },
};

const I18N = {
  UPLOAD_FILES,
  UPLOAD_REGISTRATION_SHEET_STEP,
  REGISTRATION_STATUS,
  WIZARD_SUCCESS_BASIC,
  QUESTIONS_WARNING,
};

export default I18N;
