import { kebabCase } from 'lodash';

/*
add style to modifier
*/

export type Modifier = {
  [key: string]: string;
};

export default (modifier: Modifier): string =>
  Object.keys(modifier).reduce(
    (acc, style: string) => `
    ${acc}
    ${kebabCase(style)}: ${modifier[style]};
  `,
    '',
  );
