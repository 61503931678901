import { I18nShape } from '@mmw/constants-i18n/types';

const namespace = 'ibv';
const tutorialNamespace = 'tutorial';

export const IBV_REGISTRATIONS: I18nShape = {
  key: `${namespace}.ibvRegistrations`,
  message: 'IBV Registrations',
};

export const FILE_REJECTED_WHY: I18nShape = {
  key: `${namespace}.fileRejectedWhy`,
  message: 'File rejected why',
};

export const PURCHASE_AND_CLIENT_DATA: I18nShape = {
  key: `${namespace}.purchaseAndClientData`,
  message: 'Purchase and client data',
};

export const INSTRUCTIONS_CHECKLIST: I18nShape = {
  key: `${namespace}.instructionsChecklist`,
  message: 'Instructions Checklist',
};

export const IBV_CARD: I18nShape = {
  key: `${namespace}.ibvCard`,
  message: 'IBV Card',
};

export const CLICK_TO_VALIDATE: I18nShape = {
  key: `${namespace}.clickToValidate`,
  message: 'Please click on the picture to validate it',
};

export const ADDITIONAL_INFORMATION: I18nShape = {
  key: `${namespace}.additionalInformation`,
  message: 'Additional Information',
};

export const CAMPAIGN_NAME: I18nShape = {
  key: `${namespace}.campaignName`,
  message: 'Campaign Name',
};

export const PLEASE_DOWNLOAD_PDF: I18nShape = {
  key: `${namespace}.pleaseDownloadPdf`,
  message: 'Please download the PDF file then validate it',
};

export const WARNING_MESSAGE: I18nShape = {
  key: `${namespace}.ibvUploadWarning`,
  message:
    'Attention, this image will pass an automatic check, please follow the instructions below correctly.',
};

export const UPLOAD_TUTORIAL_SCREENS = {
  IBV1: {
    INFO: {
      key: `${namespace}1.${tutorialNamespace}.placementInfo`,
      message:
        'Photograph only the part of the sheet with the fields already filled in',
    },
  },
  IBV2: {
    INFO: {
      key: `${namespace}2.${tutorialNamespace}.placementInfo`,
      message:
        'Photograph only the part of the sheet with the fields already filled in and signed',
    },
  },
  PLACEMENT_TITLE: {
    key: `${namespace}.${tutorialNamespace}.placementTitle`,
    message: 'Place the Product Registration Sheet on a flat, lighted surface',
  },
  CAM_MARKS_INFO_TITLE: {
    key: `${namespace}.${tutorialNamespace}.camMarksInfoTitle`,
    message:
      'Place the filled part of the sheet within the red marks on the screen',
  },
};

export const IBV_TUTORIAL_MODAL = {
  SUBTITLE_ONE: {
    key: `${namespace}.${tutorialNamespace}.subtitleOne`,
    message:
      'Please submit a legible image of the parts of the registration sheet that have been completed and signed.',
  },
  SUBTITLE_TWO: {
    key: `${namespace}.${tutorialNamespace}.subtitleTwo`,
    message:
      'Make sure that the resolution of the images allows for data reading and signature recognition.',
  },
};

export const CHANGE_IBV_STATUS = {
  LABEL: {
    key: `${namespace}.changeIbvStatus`,
    message: 'Change IBV Status',
  },
  ACTION: {
    WARN: {
      key: `${namespace}.changeIbvStatusWarn`,
      message: 'This action will change the status of the IBV Registration to',
    },
    CONFIRM: {
      key: `${namespace}.changeIbvStatusConfirm`,
      message:
        '<strong>This will skip the IBV regular validation process.</strong> <br /> Are you sure you want to proceed with this action?',
    },
  },
};

const I18N = {
  IBV_REGISTRATIONS,
  FILE_REJECTED_WHY,
  PURCHASE_AND_CLIENT_DATA,
  INSTRUCTIONS_CHECKLIST,
  IBV_CARD,
  CLICK_TO_VALIDATE,
  ADDITIONAL_INFORMATION,
  PLEASE_DOWNLOAD_PDF,
  UPLOAD_TUTORIAL_SCREENS,
  IBV_TUTORIAL_MODAL,
  WARNING_MESSAGE,
  CHANGE_IBV_STATUS,
};

export default I18N;
