const namespace = 'partner';

const PARTNER = {
  ID: {
    key: `${namespace}.partnerId`,
    message: 'Partner ID',
  },
  LABEL: {
    key: `${namespace}.label`,
    message: 'Partner',
  },
  GROUP: {
    key: `${namespace}.partnerGroup`,
    message: 'Partner Group',
  },
  LAST_UPDATED: {
    key: `${namespace}.lastUpdated`,
    message: 'Last updated',
  },
  MASTER_DATA: {
    key: `${namespace}.masterData`,
    message: 'Master Data',
  },
  PARTNERSTATUS: {
    key: `${namespace}.partnerStatus`,
    message: 'Partner Status',
  },
  NEW_PARTNERS: {
    key: `${namespace}.newPartners`,
    message: 'New partners',
  },
  TEAM: {
    key: `${namespace}.team`,
    message: 'Team',
  },
  END_OF_CONTRACT: {
    key: `${namespace}.endOfContract`,
    message: 'End of contract',
  },
  APPROVAL_STATUS: {
    key: `${namespace}.approvalStatus`,
    message: 'Approval status',
  },
  NO_PARTNERS_FOUND: {
    key: `${namespace}.noPartnersFound`,
    message: 'No partners found',
  },
  LAST_LOGIN: {
    key: `${namespace}.lastLogin`,
    message: 'Last Login',
  },
};

export default PARTNER;
