export const MAX_CHARACTER_REPETITION = 2;

export const NUMBERS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

export const INVALID_INPUT_CHARACTERS = [
  '"',
  '`',
  '´',
  '_',
  ':',
  ';',
  '~',
  '?',
  '!',
  '(',
  ')',
  '}',
  '{',
  ']',
  '[',
  '<',
  '>',
  '=',
  '+',
  '/',
  '*',
  '%',
  '#',
  '&',
  '$',
  '§',
];

export const INVALID_INPUT_ALONE_CHARACTERS = ['-', '.'];

export const NO_INFO_WORDS = [
  'unknown',
  'no info',
  'unbekannt',
  'xy',
  'yx',
  'no',
  'na',
];
