import { RatingProps } from '@ui-system/interfaces-rating';

const defaultProps: Partial<RatingProps> = {
  iconsSize: 60,
  maxRating: 5,
  disabled: false,
  color: 'primary',
  error: false,
  errorColor: 'error',
};

export default defaultProps;
