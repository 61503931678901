import { F } from '@utils/ts';
import { ReactNode, RefObject } from 'react';
import { U } from 'ts-toolbelt';

type CommonOptions = 'center' | 'flexStart' | 'flexEnd';

type AlignOptions = 'stretch' | 'baseline';

type JustifyOptions = 'space-around' | 'space-between' | 'space-evenly';

enum Align {
  'center' = 'center',
  'flex-start' = 'flex-start',
  'flex-end' = 'flex-end',
  'stretch' = 'stretch',
  'baseline' = 'baseline',
}

enum Justify {
  'center' = 'center',
  'flex-start' = 'flex-start',
  'flex-end' = 'flex-end',
  'space-around' = 'space-around',
  'space-between' = 'space-between',
  'space-evenly' = 'space-evenly',
}

export type AlignItemsOptionsType = Align[CommonOptions & AlignOptions];

export type JustifyContentOptionsType = Justify[CommonOptions & JustifyOptions];

export type ContainersProps = {
  children?: ReactNode;
  spacing?: number | number[] | string;
  align?: AlignItemsOptionsType;
  justify?: JustifyContentOptionsType;
  gap?: number;
  autoWidth?: boolean;
  id?: string;
  style?: Record<string, any>;
  flex?: number;
  hideContent?: boolean;
  innerRef?: RefObject<HTMLDivElement | Record<string, any>>; // Record<string, any> is the Native Version.
  onClick?: F.Function;
  w?: U.Nullable<string | number>;
  h?: U.Nullable<string | number>;
};
