import isNumber from 'lodash/isNumber';
import { RefObject } from 'react';
import styled from 'styled-components';

import { ContainersProps } from './types';

export const Container = styled.div<ContainersProps & { ref?: RefObject<any> }>`
  display: ${({ hideContent }) => (hideContent ? 'none' : 'flex')};
  box-sizing: border-box;
  align-items: ${({ align }: ContainersProps) => align};
  justify-content: ${({ justify }: ContainersProps) => justify};  
  ${({ w }) => {
    if (!w) return '';
    if (isNumber(w)) return `width: ${w}px`;
    return `width: ${w}`;
  }};
  ${({ h }) => {
    if (!h) return '';
    if (isNumber(h)) return `height: ${h}px`;
    return `height: ${h}`;
  }};
  }};
  ${({ onClick }) => (onClick ? 'cursor: pointer' : '')};
  ${({ spacing, theme }: ContainersProps & { theme: any }) =>
    theme.spacing(spacing, '', theme)}
`;

export const BaseColumn = styled.div<
  ContainersProps & { ref?: RefObject<any> }
>`
  width: ${({ autoWidth }: ContainersProps) => (autoWidth ? 'auto' : '100%')};
  display: ${({ hideContent }) => (hideContent ? 'none' : 'flex')};
  flex-direction: column;
  box-sizing: border-box;
  align-items: ${({ align }: ContainersProps) => align || 'flex-start'};
  justify-content: ${({ justify }: ContainersProps) => justify || 'flex-start'};
  ${({ w }) => {
    if (!w) return '';
    if (isNumber(w)) return `width: ${w}px`;
    return `width: ${w}`;
  }};
  ${({ h }) => {
    if (!h) return '';
    if (isNumber(h)) return `height: ${h}px`;
    return `height: ${h}`;
  }};
  ${({ onClick }) => (onClick ? 'cursor: pointer' : '')};
  ${({ spacing, theme }: ContainersProps & { theme: any }) =>
    theme.spacing(spacing, '', theme)}
`;

export const BaseRow = styled.div<ContainersProps & { ref?: RefObject<any> }>`
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '100%')};
  display: ${({ hideContent }) => (hideContent ? 'none' : 'flex')};
  box-sizing: border-box;
  align-items: ${({ align }: ContainersProps) => align || 'flex-start'};
  justify-content: ${({ justify }: ContainersProps) => justify || 'flex-start'};
  ${({ w }) => {
    if (!w) return '';
    if (isNumber(w)) return `width: ${w}px`;
    return `width: ${w}`;
  }};
  ${({ h }) => {
    if (!h) return '';
    if (isNumber(h)) return `height: ${h}px`;
    return `height: ${h}`;
  }};
  ${({ onClick }) => (onClick ? 'cursor: pointer' : '')};
  ${({ spacing, theme }: ContainersProps & { theme: any }) =>
    theme.spacing(spacing, '', theme)}
`;

export const BaseRowPressable = BaseRow;
export const BaseColumnPressable = BaseColumn;
