import { I18nShape } from '@mmw/constants-i18n/types';

const NAMESPACE = 'auditCampaignStatus';

export const OPEN: I18nShape = {
  message: 'Open',
  key: `${NAMESPACE}.open`,
};
export const TRANSMITTED: I18nShape = {
  message: 'Transmitted',
  key: `${NAMESPACE}.transmitted`,
};

export const APPROVED_PENDING: I18nShape = {
  message: 'In Progress',
  key: `${NAMESPACE}.approvedPending`,
};

export const APPROVED_FOR_INITIAL_ORDER: I18nShape = {
  message: 'Approved for initial order',
  key: `${NAMESPACE}.approvedForInitialOrder`,
};

export const IN_VERFICATION: I18nShape = {
  message: 'In verfication',
  key: `${NAMESPACE}.inVerfication`,
};

export const APPROVED: I18nShape = {
  message: 'Approved',
  key: `${NAMESPACE}.approved`,
};

export const REJECTED: I18nShape = {
  message: 'Rejected',
  key: `${NAMESPACE}.rejected`,
};

export const NEEDS_REVISION: I18nShape = {
  message: 'Needs Revision',
  key: `${NAMESPACE}.needsRevision`,
};

export const I18N = {
  OPEN,
  TRANSMITTED,
  APPROVED_FOR_INITIAL_ORDER,
  IN_VERFICATION,
  APPROVED,
  REJECTED,
};
