const namespace = 'excellenceAwards';

const EXCELLENCE_AWARDS = {
  BANNER_TITLE: {
    key: `${namespace}.bannerTitle`,
    message: 'REC Excellence Awards 2022 Enter to win!',
  },
  DESCRIPTION: {
    key: `${namespace}.descriptionDashboardSection`,
    message:
      'You could win for an outstanding residential and/or commercial installation',
  },
  INFORMATION: {
    key: `${namespace}.bannerContent`,
    message:
      'Send us a photo/video for a chance to win the REC Excellence Award 2022.  REC will present you and the winning project in our communication activities.',
  },
  ABOUT: {
    PERIOD: {
      TITLE: {
        key: 'campaignsOverview.campaignPeriod',
        message: 'Campaign Period',
      },
      CONTENT: {
        key: `${namespace}.registrationPeriod`,
        message:
          'The last day to submit registrations to be included in this campaign is Feb. 16, 2023.',
      },
    },
    ELEGIBLE_TOPICS: {
      TITLE: {
        key: `${namespace}.eligibleTitle`,
        message: 'Eligible to take part',
      },
      CONTENT: {
        key: `${namespace}.eligibleContent`,
        message:
          'Only systems where the installation date in 2022 and uses either Alpha or TwinPeak 4 panels qualify.',
      },
    },
    CONTENT: {
      key: `${namespace}.aboutContent`,
      message: `
      <strong>Share your best projects from 2022 with us!</strong><br />
      For 2022 installations already registered, go back and add some images. Select that you want to enter this system. Or, during new registrations for systems installed in 2022 that, add images, accept terms, and submit.
      <br /><br />
      <strong>Show us your best achievements and allow us to thank you!</strong><br /><br />
      
      <strong>Compete on the following categories:</strong><br />
      ● REC Alpha Series Residential Installation of the Year <br />
      ● REC Alpha Series Commercial Installation of the Year <br />
      ● REC TwinPeak 4 Series Residential Installation of the Year <br />
      ● REC TwinPeak 4 Series Commercial Installation of the Year <br />
      <br />
      <strong>The REC jury will also select the most impressive projects for:</strong><br />
      ● REC Distribution Partner of the Year <br />
      ● REC Media Advocate of the Year <br />
      ● REC Certified Solar Professional of the Year <br />
      ● REC Alpha Series RCSP of the Year`,
    },
    JOIN_NOW: {
      key: `${namespace}.joinAction`,
      message: 'Join now! Select your best photos or videos. Good Luck!',
    },
  },
  RULES: {
    RULE1: {
      key: `${namespace}.rule.rule1`,
      message: 'Submissions must be made by February 16, 2023',
    },
    RULE2: {
      key: `${namespace}.rule.rule2`,
      message:
        'Only systems installed in 2022 and uses either Alpha or TwinPeak 4 panels qualify',
    },
    RULE3: {
      key: `${namespace}.rule.rule3`,
      message: 'Upload of two files (image and/or video) required',
    },
    RULE4: {
      key: `${namespace}.rule.rule4`,
      message: 'Unlimited number of submissions per installer company',
    },
  },
  FIRST_SECTION_TITLE: {
    key: `${namespace}.firstSectionTitle`,
    message: 'Be recognized for your success.',
  },

  NAV_BAR_ITEM: {
    key: `${namespace}.navBarItem`,
    message: 'Excellence Awards',
  },
  PARTICIPATE: {
    key: `${namespace}.participateDashboardButton`,
    message: 'Participate',
  },
  SEE_FILE: {
    key: `${namespace}.seeFile`,
    message: 'See file',
  },
  SUCCESS_ON_CONTEST_SUBMIT: {
    key: `${namespace}.successOnContestSubmit`,
    message: 'Your contest was successfully submitted.',
  },
  ERROR_ON_CONTEST_SUBMIT: {
    key: `${namespace}.errorOnContestSubmit`,
    message: 'Something gone wrong with your submission, please try again.',
  },
  REC_EXCELLENCE_AWARDS: {
    key: `${namespace}.recExcellenceAwards`,
    message: 'REC EXCELLENCE AWARDS',
  },
  REC_EXCELLENCE_AWARDS_RATING: {
    key: `${namespace}.recExcellenceAwardsRating`,
    message: 'REC EXCELLENCE AWARDS - Rating',
  },
  ENTER_TO_WIN_INSTALLATION_OF_THE_YEAR: {
    key: `${namespace}.enterToWinInstallationOfTheYear`,
    message: 'Enter to win',
  },

  FIRST_SECTION_SUBTITLE: {
    key: `${namespace}.firstSectionSubtitle`,
    message:
      'You could win for an outstanding residential and/or commercial installation.',
  },
  FORM_SECTION_TITLE: {
    key: `${namespace}.formSectionTitle`,
    message: 'YOUR REGISTRATION',
  },
  FORM: {
    SEND_INSTALLATION_DATA: {
      key: `${namespace}.form.sendInstallationData`,
      message: 'SUBMIT',
    },
    INSTALLATION_TYPE: {
      key: `${namespace}.form.installationType`,
      message: 'Select installation type:',
    },
    RESIDENTIAL: {
      key: `${namespace}.form.residential`,
      message: 'Residential',
    },
    COMMERCIAL: {
      key: `${namespace}.form.commercial`,
      message: 'Commercial',
    },
    INSTALLATION_NAME: {
      key: `${namespace}.form.installationName`,
      message: 'installationName',
    },
    INSTALLATION_NAME_PLACEHOLDER: {
      key: `${namespace}.form.installationNamePlaceholder`,
      message: 'installationNamePlaceholder',
    },
    INSTALLATION_SYSTEM_SIZE: {
      key: `${namespace}.form.installationSystemSize`,
      message: 'System Size',
    },
    INSTALLATION_SYSTEM_SIZE_UNIT: {
      key: `${namespace}.form.installationSystemSizeUnit`,
      message: 'kWp',
    },
    INSTALLATION_SYSTEM_SIZE_PLACEHOLDER: {
      key: `${namespace}.form.installationSystemSizePlaceholder`,
      message: 'installationSystemSizePlaceholder',
    },
    INSTALLATION_LOCATION: {
      key: `${namespace}.form.installationLocation`,
      message: 'Location',
    },
    INSTALLATION_LOCATION_PLACEHOLDER: {
      key: `${namespace}.form.installationLocationPlaceholder`,
      message: 'installationLocationPlaceholder',
    },
    INSTALLATION_ID: {
      key: `${namespace}.form.installationId`,
      message: 'Registration Id',
    },
    INSTALLATION_CONSUMER: {
      key: `${namespace}.form.installationConsumer`,
      message: 'Customer',
    },
    INSTALLATION_DESCRIPTION: {
      key: `${namespace}.form.installationDescription`,
      message:
        'Is there anything special you want to mention about the installation',
    },
  },
  UPLOAD_FILES: {
    SECTION_TITLE: {
      key: `${namespace}.uploadFiles.sectionTitle`,
      message: 'UPLOAD IMAGES / VIDEOS OF YOUR INSTALLATION',
    },
    SECTION_CONTENT: {
      key: `${namespace}.uploadFiles.sectionContent`,
      message:
        'If you would like to select files that are already part of your registration, click here.',
    },
    EXAMPLE_SECTION_CONTENT: {
      key: `${namespace}.uploadFiles.exampleSectionContent`,
      message: 'You can upload images and videos up to 300mb.',
    },
    UPLOAD_CARD_CONTENT: {
      key: `${namespace}.uploadFiles.uploadCardContent`,
      message: 'Drag and drop here your media file',
    },
    UPLOAD_BUTTON: {
      key: `${namespace}.uploadFiles.uploadButton`,
      message: 'Upload photo or video',
    },
  },
  TERMS_AND_CONDITIONS: {
    I_ACCEPT_THE: {
      key: `${namespace}.termsAndConditions.iAcceptThe`,
      message: 'I accept the',
    },
    I_ACCEPT_THE_CONTEST: {
      key: `${namespace}.termsAndConditions.iAcceptTheContest`,
      message: 'I accept the contest',
    },
    TERMS_AND_CONDITIONS_LINK: {
      key: `${namespace}.termsAndConditions.link`,
      message: 'Terms and Conditions',
    },
    DATA_PROTECTION_STATEMENT_LINK: {
      key: `${namespace}.dataProtectionStatement.link`,
      message: 'Data Protection Statement',
    },
  },
  REGISTRATION_SELECTION: {
    TITLE: {
      key: `${namespace}.registrationSelection.title`,
      message: 'Select a project to enter',
    },
    PLACEHOLDER: {
      key: `${namespace}.registrationSelection.placeholder`,
      message: 'Search by name, ID, customer or location',
    },
  },
  INSTALLATION_DATE: {
    key: `${namespace}.installationDate`,
    message: 'Installation date',
  },
  INSTALLATION_TYPE: {
    key: `${namespace}.installationType`,
    message: 'Installation type',
  },
  MODEL_TYPE: {
    key: `${namespace}.modelType`,
    message: 'Model type',
  },
  QUANTITY_OF_MODULES: {
    key: `${namespace}.quantityOfModules`,
    message: 'Quantity of modules',
  },
  VALIDATION: {
    FILES: {
      key: `${namespace}.validation.files`,
      message: 'You must upload 2 files (photos or videos).',
    },
    SINGLE_FILE: {
      key: `${namespace}.validation.singleFile`,
      message: 'You must upload at least one file (photo or video).',
    },
    ABOUT_THE_INSTALLATION: {
      key: `${namespace}.validation.aboutTheInstallation`,
      message: 'Your description must have at least 200 characters.',
    },
    INSTALLATION_TYPE: {
      key: `${namespace}.validation.installationType`,
      message: 'You have to choose one installation type.',
    },
    REQUIRED: {
      key: `${namespace}.validation.requiredField`,
      message: '*: Required field',
    },
  },
};

const I18N = {
  EXCELLENCE_AWARDS,
};

export default I18N;
