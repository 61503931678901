const uploadDocumentNamespace = 'uploadSalesReceipt';

const TITLE = {
  key: `${uploadDocumentNamespace}.title`,
  message: 'Upload document',
};
const LABEL = {
  key: `${uploadDocumentNamespace}.label`,
  message: 'Upload Sales Receipt',
};
export const ALLOWED_FORMATS = {
  key: `${uploadDocumentNamespace}.allowedFiles`,
  message:
    'Only the following file extensions are supported: jpg, png, gif, jpeg, pdf.',
};
const SEND_FILE = {
  key: `${uploadDocumentNamespace}.sendFile`,
  message: 'Send File',
};
const INVOICE_FILES_LIMIT_REACHED = {
  key: `${uploadDocumentNamespace}.invoiceFilesLimitReached`,
  message: 'You reached the maximum number of invoice files.',
};

const I18N = {
  TITLE,
  LABEL,
  ALLOWED_FORMATS,
  SEND_FILE,
  INVOICE_FILES_LIMIT_REACHED,
};

export default I18N;
