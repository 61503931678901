import { mediaQueryParserNoop } from './parser';
import { Devices } from './types';

export const DEVICES = {
  android: false,
  ios: false,
  iPad: false,
  iPhoneX: false,
  iPhoneSE: false,
  tablet: false,
};

export function iPadFactor(n: number, factor?: number): number {
  return n;
}

export const devices: Devices = {
  ios: mediaQueryParserNoop,
  android: mediaQueryParserNoop,
  iPad: mediaQueryParserNoop,
  iPhoneX: mediaQueryParserNoop,
  iPhoneSE: mediaQueryParserNoop,
  tablet: mediaQueryParserNoop,
};
