import ExtendableError from '@mmw/error';

export class ApiNotFoundError extends ExtendableError {
  constructor(message = 'Request Not Found') {
    super(message);
    this.status = 404;
  }
}

export class ApiBadRequestError extends ExtendableError {
  constructor(message = 'Bad Request Found', body: any) {
    super(message);
    this.status = 400;
    this.body = body;
  }
}

export class ApiUnauthorizedError extends ExtendableError {
  constructor(message = 'Unauthorized', body: any) {
    super(message);
    this.status = 401;
    this.body = body;
  }
}

export class ApiForbiddenError extends ExtendableError {
  constructor(message = 'Forbidden', body: any) {
    super(message);
    this.status = 403;
    this.body = body;
  }
}

export class BadGetawayError extends ExtendableError {
  constructor(status: number, body: any) {
    super('Bad Getaway');
    this.status = status;
    this.body = body;
  }
}

export class ApiError extends ExtendableError {
  constructor(status: number, body: any) {
    super('Api Error');
    this.status = status;
    this.body = body;
  }
}
