import 'tippy.js/themes/light.css';

import { TraderJSON } from '@mmw/services-core-store-locator/types';
import useIsVisible from '@mmw/ui-hooks-use-is-visible';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToSM } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import React, { useEffect, useMemo } from 'react';
import { Tooltip } from 'tooltip';
import { U } from 'ts-toolbelt';

import { useCustomCardTitle } from '../context';
import Details from './details';
import StoreName from './StoreName';

interface ContentProps {
  store: U.Nullable<TraderJSON>;
  limitTitleWidth?: boolean;
}

const StorePinContent: React.FC<ContentProps> = ({
  store,
  limitTitleWidth,
}: ContentProps) => {
  const isMobile = useIsMediaQueryUpToSM();
  const CustomCardTitle = useCustomCardTitle();
  return (
    <UI.Container
      direction="column"
      style={css`
        z-index: 99999;
        min-width: ${isMobile ? '100%' : '450px'};
        max-width: 500px;
        min-height: 150px;
        max-height: 560px;
        overflow: auto;
      `}
      bg="white"
      p="2"
    >
      {CustomCardTitle ? (
        <CustomCardTitle store={store} limitWidth={limitTitleWidth} />
      ) : (
        <StoreName store={store} limitWidth={limitTitleWidth} />
      )}
      <Details store={store} contentPadding="1" />
    </UI.Container>
  );
};

interface Props extends Omit<ContentProps, 'limitTitleWidth'> {
  showTooltip?: boolean;
}

const StoreInfoTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  store,
  showTooltip,
  children,
}: React.PropsWithChildren<Props>) => {
  const { isVisible, show, hide, toggle } = useIsVisible();
  const isMobile = useIsMediaQueryUpToSM();
  const tootltipProps = useMemo(
    () => ({
      theme: 'light',
      interactive: true,
      maxWidth: 'max-content',
      visible: isVisible,
      onClickOutside: hide,
      modifiers: 'flip',
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'bottom', 'right'],
            },
          },
        ],
      },
      /**
       * XXX
       * commented this to mantain the position of tooltip when map zoom changes
       * (this could cause the tooltip to hide behind the search content card
       * in some positions, with appendTo option, tooltip was always on top of all);
       */
      // appendTo: document.body,
    }),
    [hide, isVisible],
  );

  useEffect(() => {
    if (showTooltip) {
      show();
    }
  }, [showTooltip]);

  if (isMobile) {
    return (
      <>
        {React.cloneElement(children as React.ReactElement<any>, {
          onClick: show,
        })}
        <UI.Modal
          style={css`
            padding: 0 5px;
          `}
          visible={isVisible}
          toggle={hide}
        >
          <StorePinContent store={store} limitTitleWidth />
        </UI.Modal>
      </>
    );
  }

  return (
    <Tooltip
      placement="right"
      overlay={<StorePinContent store={store} />}
      tooltipProps={tootltipProps}
    >
      {React.cloneElement(children as React.ReactElement<any>, {
        onClick: toggle,
      })}
    </Tooltip>
  );
};

export default StoreInfoTooltip;
