const labelNamespace = 'store';

const STORE = {
  DAYS_OF_THE_WEEK: {
    key: `${labelNamespace}.daysOfTheWeek`,
    message: 'Days of the week',
  },
  DEALER_FINDER: {
    key: `${labelNamespace}.dealerFinder`,
    message: 'Dealer finder',
  },
  OPENING_HOURS: {
    key: `${labelNamespace}.openingHours`,
    message: 'Opening hours',
  },
  OPENING_TIMES: {
    TITLE: {
      key: `${labelNamespace}.openingTimes`,
      message: 'Opening times',
    },
    DAY_OF_WEEK: {
      SUNDAY: {
        key: `${labelNamespace}.sunday`,
        message: 'Sunday',
      },
      MONDAY: {
        key: `${labelNamespace}.monday`,
        message: 'Monday',
      },
      TUESDAY: {
        key: `${labelNamespace}.tuesday`,
        message: 'Tuesday',
      },
      WEDNESDAY: {
        key: `${labelNamespace}.wednesday`,
        message: 'Wednesday',
      },
      THURSDAY: {
        key: `${labelNamespace}.thursday`,
        message: 'Thursday',
      },
      FRIDAY: {
        key: `${labelNamespace}.friday`,
        message: 'Friday',
      },
      SATURDAY: {
        key: `${labelNamespace}.saturday`,
        message: 'Saturday',
      },
    },
  },
  STORES_LIST_SEARCH_FIELD: {
    PLACEHOLDER: {
      key: `${labelNamespace}.searchField`,
      message: 'ID or Company',
    },
  },
};

export default STORE;
