import './configureServices';
import './configureConfig';
import './configureFeatures';

import ICON_SET, { RECIconSetType } from '@brands/rec-icons';
import { CookiesProvider } from '@cookie-consent/core';
import IconSetProvider from '@lib/icons-set-provider';
import contextualConfig from '@mmw/contextual-config';
import { INITIAL_ACTIONS } from '@mmw/redux-store-common-available-countries';
import { AvailableLanguagesProvider } from '@mmw/redux-store-common-available-languages/context';
import ConnectedI18nProvider from '@mmw/redux-store-i18n/components/ConnectedI18nProvider';
import NotificationProvider from '@mmw/ui-web-providers-notifications';
import NotifyBrowserUpdate from '@mmw/web-utils-notify-browser-update';
import { CUSTOM_COMPONENTS, REC_THEME, StylesProvider } from '@rec/ui-system';
import { AppStoreInitializer } from '@retail/app-store-initializer';
import { UiSystemProvider } from '@ui-system/config';
import { css } from '@ui-system/css';
import { BUTTON_UUID } from '@ui-system/interfaces/button';
import { makeStyles } from '@ui-system/style';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import store from './configure-store';
import NavigationContainer from './NavigationContainer';

NotifyBrowserUpdate();

const useCustomStyles = makeStyles(() => ({
  [BUTTON_UUID]: css`
    border-radius: 0;
  `,
}));

const App: React.FC = () => {
  const customStyles = useCustomStyles();
  return (
    <ReduxProvider store={store}>
      <AvailableLanguagesProvider
        restrictedLanguages={contextualConfig.application.supportedLanguages}
      >
        <AppStoreInitializer initialActions={INITIAL_ACTIONS}>
          <ConnectedI18nProvider>
            <UiSystemProvider theme={REC_THEME} components={CUSTOM_COMPONENTS}>
              <IconSetProvider<RECIconSetType> customSet={ICON_SET}>
                <StylesProvider customStyles={customStyles}>
                  <NotificationProvider>
                    <CookiesProvider>
                      <NavigationContainer />
                    </CookiesProvider>
                  </NotificationProvider>
                </StylesProvider>
              </IconSetProvider>
            </UiSystemProvider>
          </ConnectedI18nProvider>
        </AppStoreInitializer>
      </AvailableLanguagesProvider>
    </ReduxProvider>
  );
};

export default App;
