import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import SelectMui from '@material-ui/core/Select';
import { css } from '@ui-system/css';
import componentModifiers from '@ui-system/default-modifiers/form/select';
import defaultProps from '@ui-system/default-props/form/select';
import {
  useSelectContainerStyle,
  useSelectStyle,
} from '@ui-system/default-styles';
import { I18nShape, useTranslateFunction } from '@ui-system/deps';
import { SelectProps } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import { is } from 'fp';
import { toUpper } from 'lodash';
import map from 'lodash/map';
import noop from 'lodash/noop';
import React, { ElementType, useCallback, useMemo } from 'react';

const EMPTY_OPTION = {
  label: '',
  value: '',
};

function getValue(value: SelectProps['value']): SelectProps['value'] {
  if (is(Number, value)) {
    return value;
  }
  return value || '';
}

const Select: React.FC<SelectProps> = ({
  options,
  disabled,
  error,
  caption,
  label,
  onChange,
  value,
  style,
  modifiers,
  labelModifiers,
  placeholderI18n,
  placeholderModifiers,
  IconComponent,
  selectInputStyle,
  formControlStyle,
  disablePlaceholder,
  ...props
}: SelectProps) => {
  const getOnClick = useCallback(
    (option: SelectProps['options'][number]) => () => {
      if (onChange) {
        onChange(option.value);
      }
    },
    [onChange],
  );
  const finalSelectStyle = useSelectStyle(
    selectInputStyle,
    `${modifiers}${error && ', error'}${disabled && ', disabled'}`,
    componentModifiers,
  );
  const finalSelectContainerStyle = useSelectContainerStyle(style, null, null);
  const translate = useTranslateFunction();
  const texts = useMemo(() => {
    const labelText = toUpper(translate(label));
    return {
      placeholder: translate(placeholderI18n),
      // label: props.required ? `* ${labelText}` : labelText,
      label: labelText,
      caption: translate(caption),
    };
  }, [caption, label, placeholderI18n, translate]);

  return (
    <UI.Container
      style={finalSelectContainerStyle}
      direction="column"
      w={(finalSelectStyle?.width as string) || 'auto'}
    >
      <FormControl
        disabled={disabled}
        error={error}
        fullWidth={finalSelectStyle?.width === '100%'}
        style={formControlStyle}
      >
        {texts.label || props.required ? (
          <UI.Overline
            modifiers={
              error || texts.caption
                ? `error, ${labelModifiers || ''}`
                : labelModifiers
            }
            style={css`
              line-height: 16px;
              height: 16px;
              margin-left: 5px;
            `}
          >
            {`${texts.label || ''}${props.required ? ' * ' : ''}`}
          </UI.Overline>
        ) : null}

        {/* @ts-ignore */}
        <SelectMui
          value={getValue(value)}
          onChange={noop}
          disabled={disabled}
          error={error}
          style={finalSelectStyle}
          IconComponent={IconComponent as ElementType}
          displayEmpty
          disableUnderline
          {...props}
        >
          {texts.placeholder ? (
            <MenuItem
              value=""
              onClick={getOnClick(EMPTY_OPTION)}
              disabled={disablePlaceholder}
            >
              <UI.Body2
                modifiers={placeholderModifiers || modifiers}
                i18n={placeholderI18n}
              >
                {texts.placeholder}
              </UI.Body2>
            </MenuItem>
          ) : null}
          {map(options, option => (
            <MenuItem
              value={getValue(option.value) as string}
              key={option.value as string}
              onClick={getOnClick(option)}
            >
              {React.isValidElement(option.label) ? (
                option.label
              ) : (
                <UI.Caption
                  i18n={option.label as string | number | I18nShape}
                  modifiers={modifiers}
                />
              )}
            </MenuItem>
          ))}
        </SelectMui>
        <UI.Caption modifiers="error">{texts.caption}</UI.Caption>
      </FormControl>
    </UI.Container>
  );
};

Select.defaultProps = defaultProps;
export default Select;
