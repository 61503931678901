import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  getFormTouchedFieldsSelector,
  getIsFieldTouchedOrFormSubmittedSelector,
} from '../../store/stateSelector';
import { FieldPath } from '../../types';
import useFormId from '../useFormId';

function useIsFieldTouchedOrFormSubmitted(fieldPath: FieldPath): boolean {
  const formId = useFormId();
  const selector = useCallback(
    state => getIsFieldTouchedOrFormSubmittedSelector(formId, fieldPath)(state),
    [formId, fieldPath],
  );

  return useSelector(selector);
}

export function useIsFormTouched(): boolean {
  const formId = useFormId();
  const selector = useCallback(
    state => !isEmpty(getFormTouchedFieldsSelector(formId)(state)),
    [formId],
  );

  return useSelector(selector);
}

export default useIsFieldTouchedOrFormSubmitted;
