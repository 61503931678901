import { Token } from 'typedi';

export const TAN_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN = new Token<string>(
  'TAN_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN',
);
export const TAN_EMAIL_BODY_RESOURCE_KEY_TOKEN = new Token<string>(
  'TAN_EMAIL_BODY_RESOURCE_KEY_TOKEN',
);
export const WELCOME_EMAIL_BODY_RESOURCE_KEY_TOKEN = new Token<string>(
  'WELCOME_EMAIL_BODY_RESOURCE_KEY_TOKEN',
);
export const WELCOME_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN = new Token<string>(
  'WELCOME_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN',
);
export const COMPLEX_ACCOUNT_EMAIL_BODY_RESOURCE_KEY_TOKEN = new Token<string>(
  'COMPLEX_ACCOUNT_EMAIL_BODY_RESOURCE_KEY_TOKEN',
);
export const COMPLEX_ACCOUNT_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN =
  new Token<string>('COMPLEX_ACCOUNT_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN');
export const RECOVER_PASSWORD_EMAIL_BODY_RESOURCE_KEY_TOKEN = new Token<string>(
  'RECOVER_PASSWORD_EMAIL_BODY_RESOURCE_KEY_TOKEN',
);
export const RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN =
  new Token<string>('RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN');

export const RECOVER_PASSWORD_BUNDLE_TOKEN = new Token<string>(
  'RECOVER_PASSWORD_BUNDLE_TOKEN',
);

export const AUTHENTICATION_EMAIL_BODY_RESOURCE_KEY_TOKEN = new Token<string>(
  'AUTHENTICATION_EMAIL_BODY_RESOURCE_KEY_TOKEN',
);
export const AUTHENTICATION_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN =
  new Token<string>('AUTHENTICATION_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN');

export const AUTHENTICATION_EMAIL_BUNDLE_TOKEN = new Token<string>(
  'AUTHENTICATION_EMAIL_BUNDLE_TOKEN',
);

export const SET_USER_BODY_RESOURCE_KEY_TOKEN = new Token<string>(
  'SET_USER_BODY_RESOURCE_KEY_TOKEN',
);

export const SET_USER_SUBJECT_RESOURCE_KEY_TOKEN = new Token<string>(
  'SET_USER_SUBJECT_RESOURCE_KEY_TOKEN',
);

export const SET_ADMIN_BODY_RESOURCE_KEY_TOKEN = new Token<string>(
  'SET_ADMIN_BODY_RESOURCE_KEY_TOKEN',
);

export const SET_ADMIN_SUBJECT_RESOURCE_KEY_TOKEN = new Token<string>(
  'SET_ADMIN_SUBJECT_RESOURCE_KEY_TOKEN',
);

export const SET_USER_BUNDLE_TOKEN = new Token<string>('SET_USER_BUNDLE_TOKEN');

export const UNBLOCK_ACCOUNT_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN =
  new Token<string>('UNBLOCK_ACCOUNT_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN');
export const UNBLOCK_ACCOUNT_EMAIL_BODY_RESOURCE_KEY_TOKEN = new Token<string>(
  'UNBLOCK_ACCOUNT_EMAIL_BODY_RESOURCE_KEY_TOKEN',
);
