import { TraderJSON } from '@mmw/services-core-store-locator/types';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import { U } from 'ts-toolbelt';

import { useCustomMapMarker, useCustomStoreName } from '../context';

interface CommonProps {
  store: U.Nullable<TraderJSON>;
}

export const NameComponent: React.FC<CommonProps> = ({
  store,
}: CommonProps) => {
  const CustomStoreName = useCustomStoreName();
  const name = useMemo(
    () => store?.displayname || get(store, 'address.company'),
    [store],
  );
  if (!CustomStoreName && !name) return null;
  if (CustomStoreName) {
    return <CustomStoreName store={store} />;
  }
  return <UI.Typography modifiers="bold, capitalize">{name}</UI.Typography>;
};

interface StoreNameProps extends CommonProps {
  limitWidth?: boolean;
}

const StoreName: React.FC<StoreNameProps> = ({
  store,
  limitWidth,
}: StoreNameProps) => {
  const CustomMapMark = useCustomMapMarker();
  return (
    <UI.Container
      style={css`
        ${limitWidth ? 'max-width: 260px' : ''};
      `}
      align="center"
      m="0, 0, 3, 0"
      gap={2}
    >
      {CustomMapMark ? (
        <CustomMapMark store={store} />
      ) : (
        <UI.Icon name="local" size={25} color="gray.A200" />
      )}
      <NameComponent store={store} />
    </UI.Container>
  );
};

export default StoreName;
