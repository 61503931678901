import {
  FILE as FILE_FIELDS,
  REGISTRATION as FIELDS,
} from '@mmw/constants-fields-names';

const labelNamespace = 'label';
const placeholderNamespace = 'placeholder';
const fileNamespace = 'file';

const REGISTRATION = {
  PURCHASE_DATE: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.PURCHASE_DATE}`,
      message: 'Purchase Date',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.${FIELDS.PURCHASE_DATE}`,
      message: 'Choose the Purchase Date',
    },
  },
  INVOICE_NUMBER_OR_PURCHASE_ORDER_NUMBER: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.INVOICE_NUMBER_OR_PURCHASE_ORDER_NUMBER}`,
      message: 'Invoice Number or Purchase Order Number',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.${FIELDS.INVOICE_NUMBER_OR_PURCHASE_ORDER_NUMBER}`,
      message: 'Inform your Invoice Number or Purchase Order Number',
    },
  },
  INVOICE_NUMBER: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.INVOICE_NUMBER}`,
      message: 'Invoice Number',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.${FIELDS.INVOICE_NUMBER}`,
      message: 'Inform Invoice Number',
    },
  },
  SALESMAN: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.SALESMANID}`,
      message: 'Salesman',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.${FIELDS.SALESMANID}`,
      message: 'Inform the Salesman',
    },
  },
  STORE: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.STORE}`,
      message: 'Store',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.${FIELDS.STORE}`,
      message: 'Inform Store',
    },
  },
  PURCHASE_ORDER_NUMBER: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.PURCHASE_ORDER_NUMBER}`,
      message: 'Purchase Order Number',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.${FIELDS.PURCHASE_ORDER_NUMBER}`,
      message: 'Enter Purchase Order Num',
    },
  },
  SALES_TYPE: {
    LABEL: {
      key: `${labelNamespace}.${FIELDS.SALES_TYPE}`,
      message: 'Sale Type',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.${FIELDS.SALES_TYPE}`,
      message: 'Inform the sale type',
    },
  },
  SALESPERSON: {
    LABEL: {
      key: `${labelNamespace}.salesperson`,
      message: 'Saleperson',
    },
    PLACEHOLDER: {
      key: `${placeholderNamespace}.salesperson`,
      message: 'Inform the salesperson',
    },
  },
  ONLINE: {
    LABEL: {
      key: `${labelNamespace}.online`,
      message: 'ONLINE point of sale (Internet shop)',
    },
  },
  OFFLINE: {
    LABEL: {
      key: `${labelNamespace}.offline`,
      message: 'OFFLINE point of sale (Retail shop)',
    },
  },
  OFFLINE_AND_ONLINE: {
    LABEL: {
      key: `${labelNamespace}.offlineAndOnline`,
      message: 'OFFLINE and ONLINE point of sale (Retail shop)',
    },
  },
  FILE: {
    NAME: {
      LABEL: {
        key: `${fileNamespace}.${FILE_FIELDS.NAME}.${labelNamespace}`,
        message: 'File Name',
      },
      PLACEHOLDER: {
        key: `${fileNamespace}.${FILE_FIELDS.NAME}.${placeholderNamespace}`,
        message: 'File Name',
      },
    },
  },
};

export default REGISTRATION;
