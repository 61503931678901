const BASE_PATH = '/v1/trader/open/orgunit';

const ADDRESS_BASE_PATH = 'v1/address';

export const GetCreateStorePath = (): string => `${BASE_PATH}/store-locator`;

export const GetContactStorePath = (): string =>
  `${BASE_PATH}/store-locator/contact`;

export const GetAddressCoordinatesPath = (): string =>
  `${ADDRESS_BASE_PATH}/calculate-coordinates`;
