import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { restartAction } from '../store/actions';
import useFastFormProviderContext from './useFastFormProviderContext';

function useRestartForm(): (initialValues: Record<string, any>) => void {
  const dispatch = useDispatch();
  const context = useFastFormProviderContext();
  const { formId, options, validationSchema } = context;
  return useCallback(
    (initialValues: Record<string, any>) => {
      dispatch(restartAction(formId, initialValues, options, validationSchema));
    },
    [formId, dispatch],
  );
}

export default useRestartForm;
