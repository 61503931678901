import { useCookieStore } from '@cookie-consent/core/configurable-cookies-consent/cookiesState';
import { REC } from '@mmw/constants-salesorgbrand-ids';
import { setStoresSearchParams } from '@store-locator/store-creator';
import {
  SearchRequestObject,
  TraderSearchSortOrder,
} from '@store-locator/store-creator/types';

useCookieStore.getState().setCookieConfig('googleMaps', true);

const STORES_SEARCH_PARAMS: Partial<SearchRequestObject> = {
  salesOrgBrand: REC,
  limit: 20,
  returnsOnlyTradersRankedByBrand: true,
  returnsOnlyWithContractsNotExpired: true,
  forceFilterByBusinessRelation: true,
  sortOrder: TraderSearchSortOrder.BRAND_RANKING,
  returnsDistance: true,
};

setStoresSearchParams(STORES_SEARCH_PARAMS);
