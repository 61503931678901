import { css } from '@ui-system/css';
import {
  LIST_CONTENT_CONTAINER_UUID,
  LIST_UUID,
} from '@ui-system/interfaces-list';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

// CONTENT CONTAINER
const useContentContainerDefaultStyle = makeStyle(
  () => css`
    background-color: transparent;
  `,
);

export const useContentContainerStyle = makeFinalStyleHook(
  LIST_CONTENT_CONTAINER_UUID,
  useContentContainerDefaultStyle,
);

// LIST
const useListDefaultStyle = makeStyle(
  () => css`
    background-color: transparent;
  `,
);

export const useListStyle = makeFinalStyleHook(LIST_UUID, useListDefaultStyle);
