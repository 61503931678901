import { isDevelopment } from '@mmw/environment';
import Container from '@ui-system/common-container';
import { styled } from '@ui-system/css';
import {
  useScreenFooterStyle,
  useScreenHeaderStyle,
} from '@ui-system/default-styles/page';
import {
  ContentProps,
  FooterProps,
  HeaderProps,
  PageProps,
  PageType,
} from '@ui-system/interfaces-page';
import { useMediaQueries } from '@ui-system/media-query';
import { pickBy } from 'lodash';
import keys from 'lodash/keys';
import React, { useEffect, useMemo, useState } from 'react';

const BasePage = styled.div<PageProps>`
  margin: 0;
  box-sizing: border-box;
  display: flex;
  background: ${({ theme }) => theme.colors[theme.mode].background}
    url(${({ backgroundImageUrl }) => backgroundImageUrl}) no-repeat;
  background-position-x: center;
  background-position-y: ${({ backgroundImageOffsetY }) =>
    backgroundImageOffsetY || '90px'};
  background-size: ${({ backgroundImageHeight, backgroundImageWidth }) =>
    `${backgroundImageWidth || 'auto'} ${backgroundImageHeight || '350px'}`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  grid-template-rows: ${({ gridTemplateRows }) =>
    gridTemplateRows || '60px auto 200px'};
`;

// export const headerHeight = ({ theme, showNavBar }): string =>
//   `${theme.page.variable.LOGO_HEIGHT} + ${
//     theme.page.variable.NAVBAR_HEIGHT
//   } + ${theme.page.variable.NAV_MENU_HEIGHT} - ${
//     showNavBar ? '0px' : theme.page.variable.NAVBAR_HEIGHT
//   }`;

const Header: React.FC<HeaderProps> = ({ style, children, ...props }) => {
  const finalStyle = useScreenHeaderStyle(style, null, null);
  return (
    <Container id="pageHeader" style={finalStyle} {...props}>
      {children}
    </Container>
  );
};

const Footer: React.FC<FooterProps> = ({
  style,
  children,
  ...props
}: FooterProps) => {
  const finalStyle = useScreenFooterStyle(style, null, null);
  return (
    <Container style={finalStyle} {...props}>
      {children}
    </Container>
  );
};

const Content = styled.div<ContentProps>`
  margin: 0;
  min-height: 500px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ContentContainer = styled.div<ContentProps>(
  ({ headerHeight }) => `
  padding-top: ${headerHeight}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`,
);

export const DevMediaQueryBox = () => {
  const mediaQuery = useMediaQueries();
  const mediaQueries = useMemo(
    () => ({
      active: keys(pickBy(mediaQuery.current, value => value)),
      inactive: keys(pickBy(mediaQuery.current, value => !value)),
    }),
    [mediaQuery],
  );
  if (!isDevelopment()) return null;
  return (
    <div
      style={{
        background: 'yellow',
      }}
    >
      <p>Media Queries</p>
      <p>ACTIVE</p>
      <p>{JSON.stringify(mediaQueries.active, null, 2)}</p>
      <p>INACTIVE</p>
      <p>{JSON.stringify(mediaQueries.inactive, null, 2)}</p>
    </div>
  );
};

const currentHeaderHeight = document.getElementById('pageHeader')?.offsetHeight;
export const Page: PageType = ({
  children,
  showDevInfo,
  height,
  ...rest
}: PageProps) => {
  const [headerHeight, setHeaderHeight] = useState<number>(
    currentHeaderHeight || 30,
  );

  useEffect(() => {
    if (currentHeaderHeight !== headerHeight && currentHeaderHeight) {
      setHeaderHeight(currentHeaderHeight);
    }
  }, [currentHeaderHeight]);

  const customHeight = height !== undefined ? height : headerHeight;

  return (
    <BasePage {...rest}>
      <ContentContainer headerHeight={customHeight}>
        {children}
        {showDevInfo ? <DevMediaQueryBox /> : null}
      </ContentContainer>
    </BasePage>
  );
};
Page.Content = Content;
Page.Header = Header;
Page.Footer = Footer;
