import * as svgs from './svgs';
import { RECIconSetType } from './types';

const REC_ICON_SET: RECIconSetType = {
  ...svgs,
  regSource: {
    sunsnap: svgs.sunsnap,
    proportal: svgs.proportal,
    proportalMassUpload: svgs.proportalMassUpload,
    proportalAdmin: svgs.proportalAdmin,
    solarThirdParty: svgs.solartThirdParty,
  },
};

export default REC_ICON_SET;

export * from './types';
