import style from 'ansi-styles';

export const TRACE = 'trace';
export const DEBUG = 'debug';
export const INFO = 'info';
export const WARN = 'warn';
export const ERROR = 'error';
export const FATAL = 'fatal';
export const EMERG = 'emerg';

export const DEFAULT_LEVEL = INFO;

export const LEVELS = {
  [TRACE]: { priority: 10, color: '#6699cc', colorStyle: style.bgGray },
  [DEBUG]: { priority: 20, color: '#66cccc', colorStyle: style.bgBlue },
  [INFO]: { priority: 30, color: '#99cc99', colorStyle: style.bgGreen },
  [WARN]: { priority: 40, color: '#ffcc66', colorStyle: style.bgYellow },
  [ERROR]: { priority: 50, color: '#f2777a', colorStyle: style.bgRed },
  [FATAL]: { priority: 50, color: '#f2777a', colorStyle: style.bgRed },
  [EMERG]: { priority: 50, color: '#f2777a', colorStyle: style.bgRed },
};

export const EMPTY_ARRAY = [];
