import { I18nShape } from '@mmw/constants-i18n';
import limitString from '@mmw/constants-string-utils';
import { STRING_HTML_REGEX } from '@mmw/constants-utils';
import { ResourceKeyTooltip } from '@mmw/resource-key-tooltip';
import HtmlParser from '@mmw/ui-libraries-html-react-parser';
import ResourcesContext from '@mmw/ui-providers-resources';
import { use } from '@mmw/ui-theme/utils';
import { findMessage } from '@mmw/utils-text-utils';
import { defaultTo, isArray, isEmpty, isString, memoize } from 'lodash';
import React, { ReactNode, useContext } from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';
import styled from 'styled-components';
import { O } from 'ts-toolbelt';

const TypographySpan = styled.span(props => use(props, 'typography'));
const TypographyH1 = styled.h1(props => use(props, 'typography'));
const TypographyH2 = styled.h2(props => use(props, 'typography'));
const TypographyH3 = styled.h3(props => use(props, 'typography'));
const TypographyH4 = styled.h4(props => use(props, 'typography'));
const TypographyH5 = styled.h5(props => use(props, 'typography'));
const TypographyH6 = styled.h6(props => use(props, 'typography'));
const TypographyDefault = styled.pre(props => use(props, 'typography.pre'));
const TypographyP = styled.p(props => use(props, 'typography'));
const TypographyA = styled.a(props => use(props, 'typography.a'));

const hasModifier = (modifiers: string | Array<string>, modifier: string) => {
  const array = isArray(modifiers);
  if (array && modifiers.indexOf(modifier) > -1) {
    return true;
  }
  return modifiers === modifier;
};

const getTypography = memoize((modifiers: Array<string>) => {
  if (modifiers) {
    if (
      hasModifier(modifiers, 'body1') ||
      hasModifier(modifiers, 'body2') ||
      hasModifier(modifiers, 'caption1') ||
      hasModifier(modifiers, 'caption2')
    ) {
      return TypographySpan;
    }
    if (hasModifier(modifiers, 'span')) {
      return TypographySpan;
    }
    if (hasModifier(modifiers, 'h1')) {
      return TypographyH1;
    }
    if (hasModifier(modifiers, 'h2')) {
      return TypographyH2;
    }
    if (hasModifier(modifiers, 'h3')) {
      return TypographyH3;
    }
    if (hasModifier(modifiers, 'h4')) {
      return TypographyH4;
    }
    if (hasModifier(modifiers, 'h5')) {
      return TypographyH5;
    }
    if (hasModifier(modifiers, 'h6')) {
      return TypographyH6;
    }
    if (hasModifier(modifiers, 'p')) {
      return TypographyP;
    }
    if (hasModifier(modifiers, 'a')) {
      return TypographyA;
    }
  }
  return TypographyDefault;
});

export type Props = {
  i18n?: I18nShape;
  children?: ReactNode | string;
  date?: Date | string;
  dateFormat?: FormatDateOptions;
  prefix?: string;
  suffix?: string;
  values?: O.Object;
  htmlStyledText?: boolean;
  modifiers?: string | string[];
  charLimit?: number;
  hideLastChars?: boolean;
};

const ToParse = ({ children }: { children: ReactNode }) => <>{children}</>;

const Typography: React.FC<Props> = ({
  i18n,
  children,
  date,
  dateFormat,
  values,
  prefix,
  suffix,
  htmlStyledText,
  charLimit,
  hideLastChars,
  ...otherProps
}: Props) => {
  const { prefixKey } = useContext(ResourcesContext);
  let content = children;
  let campaignPrefixKey = null;
  const intl = useIntl();
  const { modifiers } = otherProps;
  const TypographyTemplate = getTypography(modifiers as string[]);
  if (i18n) {
    const { key, message, values: i18nValues } = i18n;
    if (!isEmpty(prefixKey) && key) {
      campaignPrefixKey = `${prefixKey}.${key}`;
    }
    content = `${prefix}${findMessage(
      defaultTo(campaignPrefixKey, key),
      message,
      intl,
      i18nValues || values,
    )}${suffix}`;
  } else if (date) {
    content = intl.formatDate(date, dateFormat);
  } else if (isString(content)) {
    content = `${prefix}${content}${suffix}`;
  }
  if (STRING_HTML_REGEX.test(content as string)) {
    content = HtmlParser(
      content as string,
      ToParse,
      TypographyTemplate,
      modifiers,
      htmlStyledText,
    );
  }
  // const intlVersion = intl[bundleVersion];
  return (
    <ResourceKeyTooltip i18n={i18n}>
      <TypographyTemplate {...otherProps}>
        {isString(content) && charLimit
          ? limitString(content, charLimit, '...', hideLastChars)
          : content}
      </TypographyTemplate>
    </ResourceKeyTooltip>
  );
};

Typography.defaultProps = {
  dateFormat: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  prefix: '',
  suffix: '',
  hideLastChars: true,
};

export default Typography;
