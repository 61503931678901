import { ResourceKeyTooltip } from '@mmw/resource-key-tooltip';
import { styled } from '@ui-system/css';
import {
  ButtonProps,
  ButtonType,
  Components,
  PageType,
} from '@ui-system/interfaces';
import { components } from '@ui-system/material';
import { Theme } from '@ui-system/theme';
import includes from 'lodash/includes';

import { DatePickerIcon } from './Datepicker';
import { IconComponent } from './Select';

components.Typography.CustomWrapper = ResourceKeyTooltip;

const { Button, Page } = components;
const NewButton: ButtonType = styled(Button)`
  min-width: 105px;
  background-color: ${({
    theme,
    variant,
    disabled,
  }: { theme: Theme } & ButtonProps) => {
    if (disabled) return theme.colors[theme.mode].gray.A200;
    if (variant === 'contained') return theme.colors[theme.mode].primary.main;
    if (variant === 'text') return 'transparent';
    if (variant === 'outlined') return 'transparent';
    if (variant === 'white') return theme.colors[theme.mode].white;

    return theme.colors[theme.mode].white;
  }};
  ${({ theme, variant, disabled }: { theme: Theme } & ButtonProps) => {
    if (disabled)
      return `
        border-color: ${theme.colors[theme.mode].gray.A900} !important;
        * {
          color: ${theme.colors[theme.mode].gray.A500} !important;
        }
        svg {
            fill: ${theme.colors[theme.mode].gray.A500};
        }
      `;
    switch (variant) {
      case 'contained':
        return `
          * {
            color: ${theme.colors[theme.mode].black} !important;
          }
          svg {
            fill: ${theme.colors[theme.mode].black};
          }
        `;
      case 'outlined':
        return `
            * {
                color: ${theme.colors[theme.mode].primary.main} !important;
            }
            
            svg {
                fill: ${theme.colors[theme.mode].primary.main};
            }
        `;
      case 'text':
        return '';

      case 'white':
        return `border-color: ${theme.colors[theme.mode].white}  !important;`;
      default:
        return '';
    }
  }}
  :hover {
    border-color: ${({ theme }: { theme: Theme }) =>
      theme.colors[theme.mode].black} !important;
    background-color: ${({ theme }: { theme: Theme } & ButtonProps) =>
      theme.colors[theme.mode].black} !important;
    * {
      color: ${({ theme, modifiers }: { theme: Theme } & ButtonProps) => {
        if (includes(modifiers, 'dark')) return 'unset';
        return `${theme.colors[theme.mode].primary.main} !important`;
      }};
    }
    svg {
      fill: ${({ theme, modifiers }: { theme: Theme } & ButtonProps) => {
        if (includes(modifiers, 'dark')) return 'unset';
        return theme.colors[theme.mode].primary.main;
      }};
    }
  }
` as unknown as ButtonType;

const NewContent: PageType['Content'] = styled(Page.Content)`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
` as unknown as PageType['Content'];

Page.Content = NewContent;

Page.defaultProps = {
  ...Page.defaultProps,
  gridTemplateRows: 'auto',
};

NewButton.defaultProps = {
  ...Button.defaultProps,
  variant: 'contained',
  iconSize: 25,
  iconColor: 'black',
};

components.Form.Select.defaultProps = {
  ...components.Form.Select.defaultProps,
  labelModifiers: 'grayA400',
  IconComponent,
};

components.DatePicker.defaultProps = {
  ...components.DatePicker.defaultProps,
  CustomIcon: DatePickerIcon,
};

export const CUSTOM_COMPONENTS: Components = {
  ...components,
  Button: NewButton,
  Page,
};
