import hashSum from 'hash-sum';
import { nanoid } from 'nanoid';
import hash from 'object-hash';

export function hashObject(object: Record<string, any> | string): string {
  try {
    return hash(object);
  } catch (error) {
    return nanoid();
  }
}

export function fastHash(item: Record<string, any> | string | any[]): string {
  try {
    return hashSum(item);
  } catch (error) {
    return nanoid();
  }
}

export interface WithHash {
  hash: string;
}

export type Hashed<T> = {
  hash: string;
  data: T;
};
