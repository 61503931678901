import { Chip as MuiChip } from '@material-ui/core';
import defaultProps from '@ui-system/default-props/chip';
import { ChipProps, ChipType } from '@ui-system/interfaces-chip';
import React from 'react';

const Chip: ChipType = ({
  label,
  onClick,
  onDelete,
  variant,
  color,
  disabled,
  ...props
}: ChipProps) => (
  // @ts-ignore

  <MuiChip
    label={label}
    onClick={onClick}
    onDelete={onDelete}
    variant={variant}
    color={color}
    disabled={disabled}
    {...props}
  />
);
Chip.defaultProps = defaultProps;

export default Chip;
