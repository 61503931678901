import { INSTALLER_JSON_FIELD_PATHS as INSTALLER_FIELDS } from '@mmw/services-core-projects/fieldPaths';

const labelNamespace = 'label';

const INSTALLER = {
  PHONE: {
    LABEL: {
      key: `${labelNamespace}.${INSTALLER_FIELDS.PHONE}`,
      message: 'Phone',
    },
  },
};

export default INSTALLER;
