import { Popover as MuiPopover } from '@material-ui/core';
import { css } from '@ui-system/css';
import defaultProps from '@ui-system/default-props/popover';
import { PopoverProps, PopoverType } from '@ui-system/interfaces-popover';
import UI from '@ui-system/ui';
import * as React from 'react';

const PAPER_PROPS = {
  style: css`
    background-color: transparent;
    margin-top: 10px;
  `,
};

const Popover: PopoverType = ({
  children,
  HoverElement,
  sx,
  anchorOrigin,
  transformOrigin,
  anchorPosition,
  disableRestoreFocus,
  anchorEl,
  onClose,
  open,
}: PopoverProps) => (
  <UI.Container>
    {HoverElement}
    <MuiPopover
      id="mouse-over-popover"
      sx={sx}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      anchorPosition={anchorPosition}
      onClose={onClose}
      disableRestoreFocus={disableRestoreFocus}
      elevation={0}
      PaperProps={PAPER_PROPS}
    >
      {children}
    </MuiPopover>
  </UI.Container>
);

Popover.defaultProps = defaultProps;

export default Popover;
