import withModifier from '@mmw/ui-hoc-with-modifier';
import React, { ComponentType } from 'react';
import { withProps } from 'recompose';

import Typography, { Props } from './Typography';

export type HOC<A, B> = (a: React.ComponentType<A>) => React.ComponentType<B>;

const AVAILABLE_MODIFIERS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'body1',
  'body2',
  'caption1',
  'a',
  'caption2',
];

export const H1 = withModifier('h1', AVAILABLE_MODIFIERS)(Typography);

export const H2 = withModifier('h2', AVAILABLE_MODIFIERS)(Typography);

export const H3 = withModifier('h3', AVAILABLE_MODIFIERS)(Typography);

export const H4 = withModifier('h4', AVAILABLE_MODIFIERS)(Typography);

export const H5 = withModifier('h5', AVAILABLE_MODIFIERS)(Typography);

export const H6 = withModifier('h6', AVAILABLE_MODIFIERS)(Typography);

export const Body1 = withModifier('body1', AVAILABLE_MODIFIERS)(Typography);

export const Body2 = withModifier('body2', AVAILABLE_MODIFIERS)(Typography);

export const Caption1 = withModifier(
  'caption1',
  AVAILABLE_MODIFIERS,
)(Typography);

export const Caption2 = withModifier(
  'caption2',
  AVAILABLE_MODIFIERS,
)(Typography);

export const P = withModifier('p', AVAILABLE_MODIFIERS)(Typography);

export const Span = withModifier('span', AVAILABLE_MODIFIERS)(Typography);

export const A = withModifier('a', AVAILABLE_MODIFIERS)(Typography);

export const Paragraph = Typography;

type EnhancedComponentProps = {
  fontFamily: string;
};

type WithProps = (
  // TODO: fix type
  fn: () => EnhancedComponentProps,
) => HOC<unknown, EnhancedComponentProps>;

export type TypographyPropsType = Props;
export type TypographyType = ComponentType<TypographyPropsType>;

type TypographyExportType = {
  H1: TypographyType;
  H2: TypographyType;
  H3: TypographyType;
  H4: TypographyType;
  H5: TypographyType;
  H6: TypographyType;
  Body1: TypographyType;
  Body2: TypographyType;
  Caption1: TypographyType;
  Caption2: TypographyType;
  Paragraph: TypographyType;
  P: TypographyType;
};

export const withFontFamily = (
  fontFamilyName: string,
): TypographyExportType => {
  const withFamily: WithProps = withProps(() => ({
    fontFamily: fontFamilyName,
  }));

  const ModdedTypography: TypographyExportType = withFamily(Typography);

  ModdedTypography.H1 = withModifier(
    'h1',
    AVAILABLE_MODIFIERS,
  )(ModdedTypography);

  ModdedTypography.H2 = withModifier(
    'h2',
    AVAILABLE_MODIFIERS,
  )(ModdedTypography);

  ModdedTypography.H3 = withModifier(
    'h3',
    AVAILABLE_MODIFIERS,
  )(ModdedTypography);

  ModdedTypography.H4 = withModifier(
    'h4',
    AVAILABLE_MODIFIERS,
  )(ModdedTypography);

  ModdedTypography.H5 = withModifier(
    'h5',
    AVAILABLE_MODIFIERS,
  )(ModdedTypography);

  ModdedTypography.H6 = withModifier(
    'h6',
    AVAILABLE_MODIFIERS,
  )(ModdedTypography);

  ModdedTypography.Body1 = withModifier(
    'body1',
    AVAILABLE_MODIFIERS,
  )(ModdedTypography);

  ModdedTypography.Body2 = withModifier(
    'body2',
    AVAILABLE_MODIFIERS,
  )(ModdedTypography);

  ModdedTypography.Caption1 = withModifier(
    'caption1',
    AVAILABLE_MODIFIERS,
  )(ModdedTypography);

  ModdedTypography.Caption2 = withModifier(
    'caption2',
    AVAILABLE_MODIFIERS,
  )(ModdedTypography);

  ModdedTypography.Paragraph = ModdedTypography;
  ModdedTypography.P = ModdedTypography;
  return ModdedTypography;
};

export default P;
