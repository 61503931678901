import { useDeepCompareMemo } from '@react-utils/hooks';
import { useUISystem } from '@ui-system/components-provider';
import { Components } from '@ui-system/interfaces';
import { O, U } from '@utils/ts';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import keys from 'lodash/keys';
import React, { memo } from 'react';

export function createInstance<
  Props extends O.Object,
  Type extends React.FC<Props>,
  Statics,
>(Component: Type, statics: U.Nullable<Statics> = null): Type & Statics {
  // @ts-ignore
  const Comp = (props: Props) => <Component {...props} />;
  forEach(keys(statics), item => {
    // @ts-ignore
    Comp[item] = statics[item];
  });
  return Comp as Type & Statics;
}

export function createUISystemInstance<
  Type extends React.FC,
  Props extends O.Object = O.Object,
>(component: keyof Components | string): Type {
  const Comp = React.forwardRef<Type>((props: Props, ref) => {
    const UI = useUISystem();
    const propsWithRef = useDeepCompareMemo(
      () => ({ ...props, innerRef: ref }),
      [props],
    );
    const Component = useDeepCompareMemo(
      () => get(UI, component),
      [UI, component],
    );
    return <Component {...propsWithRef} />;
  });
  return memo(Comp) as unknown as Type;
}
