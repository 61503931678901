const NAMESPACE = 'recExcellenceAwardsRanking';

const I18N = {
  VIEW_ALL: {
    key: `${NAMESPACE}.viewAll`,
    message: 'View all',
  },
  RATE: {
    key: `${NAMESPACE}.rate`,
    message: 'Rate',
  },
  RANK_BY: {
    key: `${NAMESPACE}.rankBy`,
    message: 'Rank by',
  },
  RANK: {
    key: `${NAMESPACE}.rankBy`,
    message: 'Rank',
  },
  VIEW_ONLY_NOT_RATED: {
    key: `${NAMESPACE}.viewOnlyNotRated`,
    message: 'View only not rated',
  },
  RESIDENTIAL_INSTALLATIONS: {
    key: `${NAMESPACE}.rankByResidentialInstallations`,
    message: 'Residential installations',
  },
  COMMERCIAL_INSTALLATIONS: {
    key: `${NAMESPACE}.rankByCommercialInstallations`,
    message: 'Commercial installations',
  },
  FILTERS: {
    key: `${NAMESPACE}.filters`,
    message: 'Filters',
  },
  CLEAN_FILTERS: {
    key: `${NAMESPACE}.cleanFilters`,
    message: 'Clean filters',
  },
  ENTER_THE_PAGE_SECRET: {
    key: `${NAMESPACE}.enterThePageSecret`,
    message: 'Enter the page secret',
  },
  DOWNLOAD_MESSAGE: {
    key: `${NAMESPACE}.downloadMessage`,
    message:
      'Your download will start immediately. If the file is too large, it may take a few seconds.',
  },
};

export default I18N;
