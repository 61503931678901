import { useMediaQueries } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import * as React from 'react';

import {
  RootStylesContext,
  RootStylesContextValue,
  useRootStylesContextValue,
} from './context';

interface Props extends React.PropsWithChildren<unknown> {
  styles: RootStylesContextValue;
}
export const MediaQueryHelper = () => {
  const media = useMediaQueries();
  return (
    <UI.Container>
      <UI.Typography>
        {window.visualViewport?.width} x {window.visualViewport?.height}
      </UI.Typography>
      <UI.Typography>{JSON.stringify(media.current, null, 2)}</UI.Typography>
    </UI.Container>
  );
};
export const RootStylesProvider: React.FC<Props> = ({
  styles,
  children,
}: Props) => {
  const value = useRootStylesContextValue(styles);
  return (
    <RootStylesContext.Provider value={value}>
      {children}
    </RootStylesContext.Provider>
  );
};
