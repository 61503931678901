import React, { useCallback } from 'react';

const ENTER_KEY_CODE = 13;
const ESC_KEY_CODE = 27;
export const isEnter = (e: React.KeyboardEvent) => e.keyCode === ENTER_KEY_CODE;

/**
 * This hook was build in this way to satisfact many different use cases,
 *  like in useOnPressEnterSubmit hook when the first parameter coulde be a boolean
 *  value instead a function
 */

const useInputKeyDown = () =>
  useCallback(
    (
      e: React.KeyboardEvent,
      onEnterFunc?: (
        syntheticKeyboardEvent: React.KeyboardEvent,
      ) => void | null,
      onEscFunc?: (syntheticKeyboardEvent: React.KeyboardEvent) => void | null,
    ) => {
      const isEnterPress = e.keyCode === ENTER_KEY_CODE;
      const isEscPress = e.keyCode === ESC_KEY_CODE;
      if (!isEnterPress && !isEscPress) {
        return;
      }
      if (isEnterPress && onEnterFunc) {
        e.preventDefault();
        onEnterFunc(e);
      }
      if (isEscPress) {
        if (onEscFunc) {
          e.preventDefault();
          onEscFunc(e);
        }
      }
    },
    [],
  );

export default useInputKeyDown;
