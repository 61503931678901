import { COMPANY as FIELDS } from '@mmw/constants-fields-names';
import { COMPANY as VALIDATIONS } from '@mmw/constants-fields-validations';
import { MAX_LENGTH, REQUIRED } from '@mmw/constants-validation-names';

const namespace = 'validation';

const COMPANY = {
  COMPANY: {
    MAX_LENGTH: {
      key: `${namespace}.${FIELDS.COMPANY}.${MAX_LENGTH}`,
      message: `Company must have a maximum of ${VALIDATIONS.COMPANY.MAX_LENGTH} length`,
    },
    REQUIRED: {
      key: `${namespace}.${FIELDS.COMPANY}.${REQUIRED}`,
      message: 'Company must be required',
    },
  },
  FAX: {
    MAX_LENGTH: {
      key: `${namespace}.${FIELDS.FAX}.${MAX_LENGTH}`,
      message: `Fax must have a maximum of ${VALIDATIONS.FAX.MAX_LENGTH} length`,
    },
  },
  PHONE: {
    MAX_LENGTH: {
      key: `${namespace}.${FIELDS.PHONE}.${MAX_LENGTH}`,
      message: `Phone must have a maximum of ${VALIDATIONS.PHONE.MAX_LENGTH} length`,
    },
  },
  SALESTAXID: {
    MAX_LENGTH: {
      key: `${namespace}.${FIELDS.SALESTAXID}.${MAX_LENGTH}`,
      message: `Salestaxid must have a maximum of ${VALIDATIONS.SALESTAXID.MAX_LENGTH} length`,
    },
    REQUIRED: {
      key: `${namespace}.${FIELDS.SALESTAXID}.${REQUIRED}`,
      message: 'Salestaxid must be required',
    },
  },
  TAXNUMBER: {
    MAX_LENGTH: {
      key: `${namespace}.${FIELDS.TAXNUMBER}.${MAX_LENGTH}`,
      message: `Tax number must have a maximum of ${VALIDATIONS.TAXNUMBER.MAX_LENGTH} length`,
    },
    REQUIRED: {
      key: `${namespace}.${FIELDS.TAXNUMBER}.${REQUIRED}`,
      message: 'Tax number must be required',
    },
    VAT: {
      key: `${namespace}.${FIELDS.TAXNUMBER}.vat`,
      message: 'The VAT number is incorrect',
    },
    ABN: {
      key: `${namespace}.${FIELDS.TAXNUMBER}.abn`,
      message: 'The ABN number is invalid',
    },
  },
  HOMEPAGE: {
    MAX_LENGTH: {
      key: `${namespace}.${FIELDS.HOMEPAGE}.${MAX_LENGTH}`,
      message: `Homepage must have a maximum of ${VALIDATIONS.HOMEPAGE.MAX_LENGTH} length`,
    },
  },
  CRNUMBER: {
    MAX_LENGTH: {
      key: `${namespace}.${FIELDS.CRNUMBER}.${MAX_LENGTH}`,
      message: `Number must have a maximum of ${VALIDATIONS.CRNUMBER.MAX_LENGTH} length`,
    },
    REQUIRED: {
      key: `${namespace}.${FIELDS.CRNUMBER}.${REQUIRED}`,
      message: 'Number must be required',
    },
  },
  CRCITY: {
    MAX_LENGTH: {
      key: `${namespace}.${FIELDS.CRCITY}.${MAX_LENGTH}`,
      message: `City must have a maximum of ${VALIDATIONS.CRCITY.MAX_LENGTH} length`,
    },
    REQUIRED: {
      key: `${namespace}.${FIELDS.CRCITY}.${REQUIRED}`,
      message: 'City must be required',
    },
  },
  EMAIL: {
    MAX_LENGTH: {
      key: `${namespace}.${FIELDS.EMAIL}.${MAX_LENGTH}`,
      message: `Email must have a maximum of ${VALIDATIONS.EMAIL.MAX_LENGTH} length`,
    },
    REQUIRED: {
      key: `${namespace}.${FIELDS.EMAIL}.${REQUIRED}`,
      message: 'Email must be required',
    },
  },
};

export default COMPANY;
