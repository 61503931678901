import contextualConfig from '@mmw/contextual-config';
import useLanguage from '@mmw/redux-store-i18n/hooks/useLanguage';
import qs from 'qs';
import { useMemo } from 'react';

type LegalLinksShape = {
  imprintLink: string;
  dataProtectionLink: string;
  gtcLink: string;
};

const useLegalLinks = (ignoreLanguage?: boolean): LegalLinksShape => {
  const language = useLanguage();
  const { imprint, dataProtection, gtc } = contextualConfig.links.common;
  return useMemo(
    () => ({
      imprintLink: `${imprint}${
        ignoreLanguage ? '' : `?${qs.stringify({ language })}`
      }`,
      dataProtectionLink: `${dataProtection}${
        ignoreLanguage ? '' : `?${qs.stringify({ language })}`
      }`,
      gtcLink: `${gtc}${
        ignoreLanguage ? '' : `?${qs.stringify({ language })}`
      }`,
    }),
    [dataProtection, gtc, ignoreLanguage, imprint, language],
  );
};

export default useLegalLinks;
