import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React from 'react';

const STYLE = css`
  z-index: 1;
  position: absolute;
  right: 5px;
  top: 5px;
`;

export const IconComponent: React.FC = props => (
  <UI.Container align="center" justify="center" p={1} style={STYLE} {...props}>
    <UI.Icon name="down" size={20} color="primary" />
  </UI.Container>
);
