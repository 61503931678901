const namespace = 'productSearch';
const advancedSearchNamespace = `${namespace}.advancedSearch`;
const alternativeSearchNamespace = `${namespace}.alternativeSearch`;

const PRODUCTS_NOT_FOUND = {
  key: `${advancedSearchNamespace}.notFound`,
  message: 'No products found for your query!',
};

const SERIAL_SCANNED_NOT_FOUND_BUT_QUEUED = {
  key: `${advancedSearchNamespace}.serialScannedNotFoundButQueued`,
  message:
    'The serial number was successfully scanned. Please select your product now.',
};

const ADVANCED_SEARCH = {
  TITLE: {
    key: `${advancedSearchNamespace}.modalTitle`,
    message: 'Product search',
  },
  DESCRIPTION: {
    key: `${advancedSearchNamespace}.modalDescription`,
    message: 'Please select a product by clicking on the corresponding line',
  },
  SEARCH_RESULT: {
    key: `${advancedSearchNamespace}.searchResult`,
    message: 'Search result',
  },
  PRODUCTS_NOT_FOUND,
  SEARCH_INFO: {
    key: `${advancedSearchNamespace}.searchInfo`,
    message:
      'If you can not find a product via the serial number search, please try the name or the EAN and then enter the serial number.',
  },
};

const ALTERNATIVE_PRODUCT_SEARCH = {
  TITLE: {
    key: `${alternativeSearchNamespace}.title`,
    message: 'Product search',
  },
  DESCRIPTION: {
    key: `${alternativeSearchNamespace}.description`,
    message:
      'If you sold some of the lenses below along with the other products, please select them and press save.',
  },
  SHOW_ALTERNATIVE_SEARCH: {
    key: `${alternativeSearchNamespace}.showAlternativeSearch`,
    message: 'Show Alternative Search',
  },
};
const PRODUCTS_LIMIT_REACHED = {
  key: `${namespace}.limitReachedAlert`,
  message: 'You reached the limit of selected products for this campaign.',
};

const I18N = {
  ADVANCED_SEARCH,
  PRODUCTS_NOT_FOUND,
  PRODUCTS_LIMIT_REACHED,
  ALTERNATIVE_PRODUCT_SEARCH,
  SERIAL_SCANNED_NOT_FOUND_BUT_QUEUED,
};

export default I18N;
