import { EMPTY_OBJECT } from '@mmw/constants-utils';
import { CardProps } from '@ui-system/interfaces-card';

export const actionAreaDefaultProps = {
  style: {
    flex: 1,
    flexDirection: 'row',
  },
};

const defaultProps: Partial<CardProps> = {
  style: EMPTY_OBJECT,
  square: false,
  elevation: 1,
};

export default defaultProps;
