import useLegalLinks from '@mmw/clients-ui-hooks-use-legal-links';
import { I18nShape } from '@mmw/constants-i18n';
import { SAVE } from '@mmw/constants-i18n-buttons';
import { DATA_PROTECTION, IMPRINT } from '@mmw/constants-i18n-common';
import { getBrandNameBySobId } from '@mmw/constants-salesorgbrand-utils';
import contextualConfig from '@mmw/contextual-config';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToSM } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';

import { COOKIE_CONSENT } from '../i18n';
import {
  useAcceptAllCookiesAndCloseBanner,
  useAnalyticsCookies,
  useCookiesBannerVisible,
  useCookiesModalVisible,
  useFunctionalCookies,
  useGoogleMapsCookies,
  useGoogleReCaptchaCookies,
  useUseCookiesBanner,
  useUseCookiesModal,
} from './cookiesState';

const { salesOrgBrandID } = contextualConfig.application;
const { DYNAMIC_CONSENT_CONTENTS: I18N } = COOKIE_CONSENT;

function useBodyOverflow() {
  const modal = useCookiesModalVisible();
  const banner = useCookiesBannerVisible();
  useEffect(() => {
    if (modal || banner) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [modal, banner]);
}

interface CookieItemProps {
  title: I18nShape;
  content: I18nShape;
  children: React.ReactNode;
}

const CookieItem: React.FC<CookieItemProps> = ({
  title,
  content,
  children,
}: CookieItemProps) => {
  const consentContent = useMemo(
    () => ({
      ...content,
      values: { brand: getBrandNameBySobId(salesOrgBrandID) },
    }),
    [content],
  );
  return (
    <UI.Container
      align="center"
      justify="space-between"
      m="7, 0"
      w="100%"
      p="0, 4, 0, 0"
    >
      <UI.Container direction="column" w="90%">
        <UI.H6 i18n={title} modifiers="bold" gap={2} />
        <UI.Typography i18n={consentContent} />
      </UI.Container>
      {children}
    </UI.Container>
  );
};

const CookieItemGoogleMaps: React.FC = () => {
  const { value, setValue } = useGoogleMapsCookies();

  return (
    <CookieItem
      title={I18N.GOOGLE_MAPS_CONSENT.TITLE}
      content={I18N.GOOGLE_MAPS_CONSENT.CONTENT}
    >
      <UI.Form.Switch
        checked={value}
        value={value ? 'true' : undefined}
        onChange={() => setValue(!value)}
      />
    </CookieItem>
  );
};

const CookieItemGoogleReCaptcha: React.FC = () => {
  const { value, setValue } = useGoogleReCaptchaCookies();

  return (
    <CookieItem
      title={I18N.GOOGLE_RECAPTCHA_CONSENT.TITLE}
      content={I18N.GOOGLE_RECAPTCHA_CONSENT.CONTENT}
    >
      <UI.Form.Switch
        checked={value}
        value={value ? 'true' : undefined}
        onChange={() => setValue(!value)}
      />
    </CookieItem>
  );
};

// function CookieItemMarketing() {
//   const { value, setValue } = useMarketingCookies();
//
//   return (
//     <div className="cookie-item">
//       <div className="cookie-text">
//         <h6 className="font-weight-bold">Aktivieren von Marketing-Cookies</h6>
//         <p>
//           Diese Cookies werden verwendet, um die Wirksamkeit von Werbung zu
//           verfolgen, einen relevanteren Service zu bieten und Werbung besser auf
//           Ihre Interessen abzustimmen.
//         </p>
//       </div>
//       <Toggle
//         isChecked={value}
//         value={value ? 'true' : undefined}
//         onChange={() => setValue(!value)}
//       />
//     </div>
//   );
// }

const CookieItemFunctional: React.FC = () => {
  const { value, setValue } = useFunctionalCookies();

  return (
    <CookieItem
      title={I18N.FUNCTIONAL_CONSENT.TITLE}
      content={I18N.FUNCTIONAL_CONSENT.CONTENT}
    >
      <UI.Form.Switch
        checked={value}
        value={value ? 'true' : undefined}
        onChange={() => setValue(!value)}
        disabled
      />
    </CookieItem>
  );
};

const CookieItemAnalytics: React.FC = () => {
  const { value, setValue } = useAnalyticsCookies();

  return (
    <CookieItem
      title={I18N.GOOGLE_ANALYTICS_CONSENT.TITLE}
      content={I18N.GOOGLE_ANALYTICS_CONSENT.CONTENT}
    >
      <UI.Form.Switch
        checked={value}
        value={value ? 'true' : undefined}
        onChange={() => setValue(!value)}
      />
    </CookieItem>
  );
};

const BANNER_STYLE = { position: 'fixed', bottom: 0 };
const SAVE_BTN_CONTAINER_STYLE = { alignSelf: 'flex-end' };
const MODAL_CONTENT_STYLE = css`
  max-width: 1400px;
  max-height: 100vh;
`;

const CONTENT_OVERFLOW_STYLE = { overflowY: 'auto' };

export const ConfigurationsModal: React.FC = () => {
  const visible = useCookiesModalVisible();
  const { toggle } = useUseCookiesModal();

  useBodyOverflow();

  if (!visible) return null;

  return (
    <UI.Modal visible={visible} hideCloseButton>
      <UI.Container
        style={MODAL_CONTENT_STYLE}
        direction="column"
        bg="white"
        justify="space-between"
        m="9"
        h="90vh"
        responsive="upToSm.w=100vw;upToSm.h=90vh;smToMd.h=37vh;upToMd.justify=center;upToMd.gap=2;upToSm.m=0;smToMd.h=80vh;upToSm.m=0"
      >
        <UI.Container bg="secondary" p="3" w="100%">
          <UI.H3 i18n={I18N.CONFIG_MODAL.TITLE} modifiers="white" />
        </UI.Container>
        <UI.Container
          style={CONTENT_OVERFLOW_STYLE}
          direction="column"
          p="0, 3"
          h="100%"
          responsive="mdToLg.h=600"
        >
          <CookieItemFunctional />
          <CookieItemGoogleMaps />
          <CookieItemGoogleReCaptcha />
          <CookieItemAnalytics />
        </UI.Container>
        <UI.Container
          style={SAVE_BTN_CONTAINER_STYLE}
          align="center"
          justify="flex-end"
          p="5, 2"
        >
          <UI.Button i18n={SAVE} onClick={toggle} />
        </UI.Container>
      </UI.Container>
    </UI.Modal>
  );
};

const MODAL_STYLE = { zIndex: 1000 };

export const ConfigurableCookieConsent: React.FC = () => {
  const { value: visible, toggle: toggleBanner } = useUseCookiesBanner();
  const cookiesModalVisible = useCookiesModalVisible();
  const { toggle: toggleModal } = useUseCookiesModal();
  const { imprintLink, dataProtectionLink } = useLegalLinks(true);
  // const { isDataProtectionVisible, toggleDataProtection } =
  //   useDataProtectionModalState();
  // const { isImprintVisible, toggleImprint } = useImprintModalState();

  const isUpToSm = useIsMediaQueryUpToSM();

  const onAcceptAll = useAcceptAllCookiesAndCloseBanner();
  useBodyOverflow();

  useLayoutEffect(() => {
    if (cookiesModalVisible) {
      toggleModal();
      toggleBanner();
    }
  }, []);

  return (
    <>
      <UI.Modal
        style={MODAL_STYLE}
        contentStyle={BANNER_STYLE}
        visible={visible}
        hideCloseButton
      >
        <UI.Container
          align="center"
          justify="space-between"
          bg="white"
          w="100%"
          h={120}
          p="5"
          responsive="upToMd.direction=column;upToSm.h=450px;smToMd.h=250px;upToMd.w=100vw;upToMd.p=2;"
        >
          <UI.Container w={780} responsive="upToMd.w=100%;upToMd.p=4,0,0,0">
            {isUpToSm ? (
              <UI.H6 i18n={I18N.BANNER.CONTENT} modifiers="center" />
            ) : (
              <UI.Body2 i18n={I18N.BANNER.CONTENT} />
            )}
          </UI.Container>
          <UI.Container
            p="3"
            w={220}
            justify="center"
            align="center"
            responsive="upToMd.w=100%;upToMd.p=2,0"
            gap={2}
          >
            <UI.Link
              // onClick={toggleImprint}
              href={imprintLink}
              target="_blank"
              i18n={IMPRINT}
              modifiers="grayA400"
              textVariant="body1"
            />
            <UI.Link
              // onClick={toggleDataProtection}
              href={dataProtectionLink}
              target="_blank"
              i18n={DATA_PROTECTION}
              modifiers="grayA400"
              textVariant="body1"
            />
          </UI.Container>
          <UI.Container
            align="center"
            gap={2}
            responsive="upToSm.direction=column;upToMd.w=100%;upToMd.justify=space-between"
            w="40%"
            justify="flex-end"
          >
            <UI.Button
              style={css`
                padding: 10px;
              `}
              i18n={I18N.BANNER.CONFIGURATIONS}
              onClick={toggleModal}
              fullWidth={isUpToSm}
            />
            <UI.Button
              style={css`
                padding: 10px;
              `}
              i18n={I18N.BANNER.ACCEPT_ALL}
              onClick={onAcceptAll}
              fullWidth={isUpToSm}
            />
          </UI.Container>
        </UI.Container>
      </UI.Modal>
      <ConfigurationsModal />
      {/* <DataProtectionModal
        isVisible={isDataProtectionVisible}
        toggle={toggleDataProtection}
      />
      <ImprintModal isVisible={isImprintVisible} toggle={toggleImprint} /> */}
    </>
  );
};
