import React, { useMemo } from 'react';
import { config, useSpring, useTransition } from 'react-spring';

import AnimateHeight from './AnimateHeight';
import { AnimatedComponent } from './component';
import PRESETS from './presets';
import { AnimationType, Props, TransitionProps, TransitionType } from './types';

export const Animation: AnimationType = ({
  children,
  preset,
  custom,
  style,
  f,
  duration,
  fullWidth,
}: Props) => {
  // @ts-ignore
  const animatedStyle = useSpring(custom || PRESETS[preset] || PRESETS.FADE_IN);
  const finalStyle = useMemo(
    () => ({
      ...animatedStyle,
      ...style,
      flex: f,
      duration,
      width: fullWidth ? '100%' : undefined,
      reset: true,
    }),
    [animatedStyle, duration, f, fullWidth, style],
  );
  // @ts-ignore
  return <AnimatedComponent style={finalStyle}>{children}</AnimatedComponent>;
};

const VALUES = {
  from: { opacity: 0, position: 'absolute', width: 'auto' },
  enter: { opacity: 1, position: 'relative', width: 'auto' },
  leave: { opacity: 0, position: 'absolute', width: 'auto' },
  config: config.molasses,
};

export const Transition: TransitionType = ({
  index,
  children,
  values,
}: TransitionProps) => {
  // @ts-ignore
  const transitions = useTransition(index, p => p, values || VALUES);
  return (
    <>
      {transitions.map(({ item, props, key }) => {
        const Comp = children[item];
        return (
          <AnimatedComponent style={props} key={key}>
            {Comp}
          </AnimatedComponent>
        );
      })}
    </>
  );
};

Animation.Transition = Transition;
Animation.AnimateHeight = AnimateHeight;
Animation.defaultProps = {};
