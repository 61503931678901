import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  setFieldUnwatchedAction,
  setFieldWatchedAction,
} from '../../store/actions';
import { FieldPath } from '../../types';
import useFormId from '../useFormId';

function useWatchField(fieldPath: FieldPath) {
  const dispatch = useDispatch();
  const formId = useFormId();
  useEffect(() => {
    dispatch(setFieldWatchedAction(formId, fieldPath));
    return () => {
      dispatch(setFieldUnwatchedAction(formId, fieldPath));
    };
  }, [fieldPath, formId]);
}

export default useWatchField;
