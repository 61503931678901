import { I18nShape } from '@mmw/constants-i18n';
import { get, noop } from 'lodash';
import { AppearanceTypes } from 'react-toast-notifications';

import { I18N, NotificationConfigObject, NotificationI18n } from './types';

export const modifiersMap: Record<AppearanceTypes, string> = {
  info: 'info',
  error: 'error',
  warning: 'basicDark',
  success: 'basicDark',
};

export const DEFAULT_CONFIG: NotificationConfigObject = {
  appearance: 'info',
  placement: 'top-right',
  autoDismiss: true,
  autoDismissTimeout: 3000,
  transitionDuration: 1000,
  onDismiss: noop,
};

export function getI18n(i18n: I18N | I18nShape): NotificationI18n {
  const title = get(i18n, 'title', null);
  const content = get(i18n, 'content', title || i18n || null);
  return { title, content };
}
