const namespace = 'editConsumer';
const modalNamespace = `${namespace}Modal`;

const MODAL = {
  TITLE: {
    key: `${modalNamespace}.title`,
    message: 'Edit consumer',
  },
  DESCRIPTION: {
    key: `${modalNamespace}.description`,
    message:
      'Please enter here all necessary information about the consumer. Especially for bundle promotions in which the addition is sent by post, it is important to specify the complete postal address, otherwise no delivery can be made.',
  },
  SEARCH_RESULT: {},
};

const CONSUMER_REQUIRES_EDIT = {
  key: `${namespace}.consumerRequiresEdit`,
  message:
    'The consumer selected has fields that require your attention! Please edit!',
};

const I18N = {
  MODAL,
  CONSUMER_REQUIRES_EDIT,
};

export default I18N;
