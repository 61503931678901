import { ADDRESS } from '@mmw/constants-i18n-validations';
import CommonService from '@mmw/services-core-common';
import * as Yup from 'yup';

export const METHOD_NAME = 'zipcode';
const MESSAGE_KEY = ADDRESS.ZIPCODE.INVALID.key;
const TEST_NAME = 'zipcodeTest';
const DEFAULT_COUNTRY_FIELD_PATH = 'country';

export default Yup.addMethod(
  Yup.string,
  METHOD_NAME,
  function validateZipCodeByCountry(
    customMessageKey?: string,
    countryFieldPath?: string,
  ) {
    return this.test(
      TEST_NAME,
      customMessageKey || MESSAGE_KEY,
      // @ts-ignore
      function validationFn(zipcode, ctx) {
        const country =
          this.parent[countryFieldPath || DEFAULT_COUNTRY_FIELD_PATH];
        if (!zipcode || !country) {
          return true;
        }
        if (!zipcode && ctx.schema.spec.optional) {
          return true;
        }
        return CommonService.validateZipcode(zipcode, country);
      },
    );
  },
);
