const namespace = 'agreementAutoActivation';

const I18N = {
  TITLE: {
    key: namespace,
    message: 'Agreement Activation',
  },
  DESCRIPTION: {
    key: `${namespace}.description`,
    message:
      'To continue you must accept the agreement of the campaign or program.',
  },
  AGREEMENTS_WARNING: {
    key: `${namespace}.agreementsWarning`,
    message:
      'To continue you must accept all agreement contracts of the campaign or program.',
  },
  AGREEMENT_APP_WARNING: {
    key: `${namespace}.agreementsAppWarning`,
    message:
      'Please contact your superior to activate the campaign through the MMW Retail Client',
  },
};

export default I18N;
