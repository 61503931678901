import { Color } from '@ui-system/css';
import { ContainerProps } from '@ui-system/interfaces-container';
import UI from '@ui-system/ui';
import React from 'react';
import { U } from 'ts-toolbelt';

interface Props extends React.PropsWithChildren, Partial<ContainerProps> {
  customColor?: U.Nullable<Color>;
}

const Header: React.FC<Props> = ({
  customColor,
  children,
  ...props
}: Props) => (
  <UI.Page.Header>
    <UI.Container
      bg={customColor || 'secondary'}
      align="center"
      justify="center"
      w="100vw"
      p="2"
      shadow={5}
      responsive="upToSm.h=90px"
      {...props}
    >
      {children}
    </UI.Container>
  </UI.Page.Header>
);

export default Header;
