import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import {
  AddressJSON,
  OperationResult,
  Pagination,
} from '@mmw/services-core-common/types';
import autoBind from 'auto-bind';
import Fuse from 'fuse.js';
import at from 'lodash/fp/at';
import isEmpty from 'lodash/fp/isEmpty';
import join from 'lodash/fp/join';
import pipe from 'lodash/fp/pipe';
import reject from 'lodash/fp/reject';
import get from 'lodash/get';
import trim from 'lodash/trim';
import { U } from 'ts-toolbelt';

import {
  GetAddressCoordinatesPath,
  GetContactStorePath,
  GetCreateStorePath,
} from './apiPaths';
import fuzzySearchTraders from './fuzzySearch';
import logger from './log';
import lookupAddressCache from './lookupAddressCache';
import {
  CoordinatesResult,
  CreateStoreLocatorOrgunitJSON,
  GoogleAddressSearchApiReturn,
  TraderJSON,
  TraderSearchRequestObject,
} from './types';

type Api = typeof defaultApiV2;

type StoreLocatorServiceOptions = {
  apiv2?: Api;
  authenticationService?: AuthenticationService;
};

class StoreLocatorService {
  api: Api;

  // TODO: future require everyone with auth if necessary
  authenticationService?: AuthenticationService | null;

  constructor({ apiv2, authenticationService }: StoreLocatorServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  async fuzzySearchTraders(
    request: TraderSearchRequestObject,
    stores: Array<TraderJSON>,
    fuzzySearchOptions?: Fuse.FuseSearchOptions,
  ): Promise<Pagination<TraderJSON>> {
    logger.debug(
      'Trying to find traders on fuzzy by',
      request,
      fuzzySearchOptions,
    );
    const result = await fuzzySearchTraders(
      request,
      stores,
      fuzzySearchOptions,
    );
    logger.debug('Success find traders, total ', result.total);
    return result;
  }

  async createStore(
    request: CreateStoreLocatorOrgunitJSON,
  ): Promise<OperationResult> {
    logger.debug('Trying to create store', request);
    try {
      const response: ApiResponse<OperationResult> = await this.api.post(
        GetCreateStorePath(),
        request,
      );
      const { data } = response;
      logger.info('Successfully created store as=%O', data);
      return data;
    } catch (error) {
      logger.error('Error when creating store, error=%O', error);
      throw error;
    }
  }

  async contactStore(
    request: CreateStoreLocatorOrgunitJSON,
  ): Promise<OperationResult> {
    logger.debug('Trying to contact store', request);
    try {
      const response: ApiResponse<OperationResult> = await this.api.post(
        GetContactStorePath(),
        request,
      );
      const { data } = response;
      logger.info('Successfully contacted store as=%O', data);
      return data;
    } catch (error) {
      logger.error('Error when creating store, error=%O', error);
      throw error;
    }
  }

  async googleSearchAddressCoordinates(
    address: Partial<AddressJSON>,
  ): Promise<U.Nullable<CoordinatesResult>> {
    try {
      const query =
        typeof address === 'object'
          ? pipe(
              at(['street', 'zipcode', 'city', 'country']),
              reject(isEmpty),
              join('+'),
            )(address)
          : address;

      const coordsFromLocalCache = get(lookupAddressCache, trim(query));

      if (coordsFromLocalCache) {
        return coordsFromLocalCache;
      }
      const response: ApiResponse<GoogleAddressSearchApiReturn> =
        await this.api.post(GetAddressCoordinatesPath(), address);
      const { data } = response;
      logger.info('Successfully calculated coordinates=%O', data);

      if (isEmpty(data) || data?.success === false) {
        logger.error('Cannot calcualte coordinates for the informed data');
        throw new Error('Die angegebene Adresse konnte nicht gefunden werden.');
      }
      return {
        lat: data?.latitude,
        lng: data?.longitude,
      };
    } catch (error) {
      logger.error(
        'Error when trying to calculate coordinates for the informed address, error=%O',
        error,
      );
      throw error;
    }
  }
}

export default StoreLocatorService;
