const namespace = 'newConsumer';
const modalNamespace = `${namespace}Modal`;

const MODAL = {
  TITLE: {
    key: `${modalNamespace}.title`,
    message: 'Create new consumers',
  },
  DESCRIPTION: {
    key: `${modalNamespace}.description`,
    message:
      'Please enter here all necessary information about the consumer. Especially for bundle promotions in which the addition is sent by post, it is important to specify the complete postal address, otherwise no delivery can be made.',
  },
  SEARCH_RESULT: {},
};

const I18N = {
  MODAL,
};

export default I18N;
