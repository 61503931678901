import { deepmerge } from '@shared-utils/object';
import { PartialDeep } from '@utils/ts';

import { Theme } from './interfaces';

export function extendTheme(
  themeToExtend: Theme,
  newTheme: PartialDeep<Theme>,
): Theme {
  return deepmerge(themeToExtend, newTheme) as Theme;
}
