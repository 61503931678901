import { enableBatching } from 'redux-batched-actions';

import initialActions from './actions';
import getAvailableCountriesEpic from './epic';
import getAvailableCountriesReducer from './reducer';
import { NAMESPACE as getAvailableCountriesNamespace } from './stateSelector';

export const reducer = getAvailableCountriesReducer;
export const epics = [getAvailableCountriesEpic];
export const NAMESPACE = getAvailableCountriesNamespace;
export const INITIAL_ACTIONS = initialActions;

export function getAvailableCountriesModule() {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    initialActions,
    epics,
  };
}
