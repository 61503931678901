import contextualConfig from '@mmw/contextual-config';
import { DEFAULT_ACCESS_TOKEN_HEADER_NAME } from '@mmw/services-auth-api-authentication/keys';
import AdminResourceService from '@mmw/services-core-admin-resource';
import axios from 'axios';
import { set } from 'lodash';

import { useAdminToken } from './admin-token';

const api = axios.create({ baseURL: contextualConfig.api.v2.baseURI });

api.interceptors.request.use(config => {
  set(
    config?.headers as object,
    DEFAULT_ACCESS_TOKEN_HEADER_NAME.toLowerCase(),
    useAdminToken.getState().token,
  );
  set(config, 'baseURL', useAdminToken.getState().apiV2BaseURL);
  return config;
});

class AuthClass {
  token: string;

  async getAuthenticationHttpHeaders() {
    this.token = useAdminToken.getState().token;
    return {
      [DEFAULT_ACCESS_TOKEN_HEADER_NAME.toLowerCase()]: this.token,
    };
  }
}

export const resourceService = new AdminResourceService({
  apiv2: api,
  // @ts-ignore
  authenticationService: new AuthClass(),
});
