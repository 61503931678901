const namespace = 'functionalities';

const I18N = {
  CHANGE_BRAND: {
    key: `${namespace}.changeBrand`,
    message: 'Change brand',
  },
  RECENT_REGISTRATIONS: {
    key: `${namespace}.recentRegistrations`,
    message: 'Recent Registrations',
  },
  NEW_REGISTRATION: {
    key: `${namespace}.newRegistration`,
    message: 'New Registration',
  },
  CHOOSE_A_BRAND: {
    key: 'chooseABrand',
    message: 'Please select a brand to see current campaigns and programs.',
  },
  CONTACT: {
    key: `${namespace}.contact`,
    message: 'Contact',
  },
  SEARCH_PRODUCTS_BY: {
    key: `${namespace}.searchProductsBy`,
    message: 'Search products by',
  },
  DRAG_FILE_TO_UPLOAD: {
    key: `${namespace}.dragYourFile`,
    message: 'Drag and drop your file here',
  },
  TAKE_PICTURE: {
    key: `${namespace}.takePicture`,
    message: 'Take picture',
  },
  PAGINATOR: {
    TOTAL_LABEL: {
      key: `${namespace}.paginator.totalLabel`,
      message: 'of {count} by',
    },
    ITEMS_PER_PAGE_LABEL: {
      key: `${namespace}.paginator.itemsPerPage`,
      message: 'Items per page',
    },
  },
};

export const {
  CHANGE_BRAND,
  RECENT_REGISTRATIONS,
  NEW_REGISTRATION,
  CONTACT,
  SEARCH_PRODUCTS_BY,
  CHOOSE_A_BRAND,
  PAGINATOR,
  DRAG_FILE_TO_UPLOAD,
  TAKE_PICTURE,
} = I18N;

export default I18N;
