// eslint-disable-next-line max-classes-per-file
import ExtendableError from '@mmw/error';

export class CaptchaBlockedError extends ExtendableError {}
export class EmailBlockedError extends ExtendableError {}
export class BadCredentialsError extends ExtendableError {}
export class InvalidApplicationError extends ExtendableError {}
export class TanRequestNotSuccessfullError extends ExtendableError {}
export class TanReSendRequestNotSuccessfullError extends ExtendableError {}
export class DuplicatedEmailError extends ExtendableError {}
export class UserNotFoundError extends ExtendableError {}
export class UnknownError extends ExtendableError {}
export class MissingAuthorityError extends ExtendableError {}
export class MissingSalesOrgBusinessRelationError extends ExtendableError {}
export class UserScopeNameNotAllowedError extends ExtendableError {}
