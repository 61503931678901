import { enableBatching } from 'redux-batched-actions';

import currentBrandReducer from './reducer';
import { NAMESPACE as currentBrandNamespace } from './types';

export const reducer = currentBrandReducer;
export const NAMESPACE = currentBrandNamespace;
export const epics = [];

export function getCurrentSalesOrgBrandModule() {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    epics,
  };
}

export * from './types';
