import { EMPTY_ARRAY } from '@mmw/constants-utils';

import {
  GET_AVAILABLE_COUNTRIES_ERROR,
  GET_AVAILABLE_COUNTRIES_START,
  GET_AVAILABLE_COUNTRIES_SUCCESS,
  Reducer,
} from './types';

const INITIAL_STATE = {
  loading: false,
  availableCountries: EMPTY_ARRAY,
  error: null,
};

const getAvailableCountriesReducer: Reducer = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case GET_AVAILABLE_COUNTRIES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_AVAILABLE_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        availableCountries: action.payload.availableCountries,
        error: null,
      };

    case GET_AVAILABLE_COUNTRIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default getAvailableCountriesReducer;
