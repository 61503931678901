const gigasetNamespace = 'gigaset';
const productRegistrationNamespace = 'productRegistrationNamespace';

export const PRODUCT_REGISTRATION = {
  TITLE: {
    key: `${gigasetNamespace}.${productRegistrationNamespace}.title`,
    message: 'Premium Registration',
  },
  TITLE_DESCRIPTION: {
    key: `${gigasetNamespace}.${productRegistrationNamespace}.titleDescription`,
    message:
      'Register your sales and earn points in the Gigaset Germany Partner program',
  },
  SCORE: {
    key: `${gigasetNamespace}.${productRegistrationNamespace}.score`,
    message: 'Score',
  },
  STEP: {
    HEADER: {
      DESCRIPTION: {
        key: `${gigasetNamespace}.${productRegistrationNamespace}.stepHeaderDescription`,
        message:
          'Find your specific devices using the search for IMEI numbers.',
      },
    },
  },
  POINTS: {
    key: `${gigasetNamespace}.${productRegistrationNamespace}.points`,
    message: 'Points',
  },
};

export default {
  PRODUCT_REGISTRATION,
};
