import { createDI } from '@di/core';
import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import { LanguageCode } from '@mmw/constants-languages';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import { OperationResultJSON } from '@mmw/services-core-manu-orgunit/types';
import autoBind from 'auto-bind';

import {
  ChangeDemoDeviceAsNotReadyPath,
  ChangeDemoDeviceAsReadyPath,
  GetDevicesPath,
  RegisterDemoDevicePath,
  RemoveDeviceFromShowRoomPath,
} from './apiPaths';
import logger from './log';
import {
  DemoDeviceRegisteredJSON,
  RegisterDemoDeviceRequestJSON,
} from './types';

type Api = typeof defaultApiV2;

type AdminDemoDeviceServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class AdminDemoDeviceService {
  static createDI = createDI<AdminDemoDeviceService>;

  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: AdminDemoDeviceServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async registerDevice(
    request: RegisterDemoDeviceRequestJSON,
    language: LanguageCode,
  ): Promise<DemoDeviceRegisteredJSON[]> {
    logger.debug('Trying to register demo device');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<DemoDeviceRegisteredJSON[]> =
        await this.api.post(RegisterDemoDevicePath(language), request, {
          headers,
        });
      const { data } = response;
      logger.info('Successfully registered demo device');
      return data;
    } catch (error) {
      logger.error('Error when registering demo device, error=%O', error);
      throw error;
    }
  }

  async getDevices(
    orgunitID: number,
    language: string,
    salesOrgBrandID: number,
    campaignID?: number,
  ): Promise<DemoDeviceRegisteredJSON[]> {
    logger.debug('Trying to get devices');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<DemoDeviceRegisteredJSON[]> =
        await this.api.get(
          GetDevicesPath(orgunitID, language, salesOrgBrandID, campaignID),
          {
            headers,
          },
        );
      const { data } = response;
      logger.info('Successfully got devices');
      return data;
    } catch (error) {
      logger.error('Error when getting devices, error=%O', error);
      throw error;
    }
  }

  async removeDeviceFromShowRoom(
    registrationItemIDs: number[],
  ): Promise<OperationResultJSON> {
    logger.debug(
      'Trying to remove device from showroom of ids',
      registrationItemIDs,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<OperationResultJSON> = await this.api.delete(
        RemoveDeviceFromShowRoomPath(),
        {
          headers,
          data: registrationItemIDs,
        },
      );
      const { data } = response;
      logger.info(
        'Successfully removed device from show room',
        registrationItemIDs,
      );
      return data;
    } catch (error) {
      logger.error('Error when removing from show room, error=%O', error);
      throw error;
    }
  }

  async changeDeviceToReady(
    registrationItemIDs: number[],
  ): Promise<OperationResultJSON> {
    logger.debug(
      'Trying to change device to ready of ids',
      registrationItemIDs,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<OperationResultJSON> = await this.api.post(
        ChangeDemoDeviceAsReadyPath(),
        registrationItemIDs,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(
        'Successfully change device to ready in show room',
        registrationItemIDs,
      );
      return data;
    } catch (error) {
      logger.error('Error when change device to ready, error=%O', error);
      throw error;
    }
  }

  async changeDeviceToNotReady(
    registrationItemIDs: number[],
  ): Promise<OperationResultJSON> {
    logger.debug(
      'Trying to change device to not ready of ids',
      registrationItemIDs,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<OperationResultJSON> = await this.api.post(
        ChangeDemoDeviceAsNotReadyPath(),
        registrationItemIDs,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(
        'Successfully change device to not ready in show room',
        registrationItemIDs,
      );
      return data;
    } catch (error) {
      logger.error('Error when change device to not ready, error=%O', error);
      throw error;
    }
  }
}

export default AdminDemoDeviceService;
