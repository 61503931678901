import { ErpUsage, OrgUnitJSON } from '@mmw/services-core-manu-orgunit/types';
import UI from '@ui-system/ui';
import { get } from 'lodash';
import React from 'react';

// xxx: REC asked us to roll back the images for now
import REC1P from './1P.jpg';
import AD from './AD.jpg';
import RCSP from './RCSP.jpg';
import RCSP_BRONZE from './RCSP_BRONZE.jpg';
import RCSP_GOLD from './RCSP_GOLD.jpg';
// xxx: PLATINUM is not available yet, only in 2024.
import RCSP_PLATINUM from './RCSP_PLATINUM.jpg';
import RCSP_SILVER from './RCSP_SILVER.jpg';
import RCSP_ENTERPRISE from './RCSP-ENTERPRISE.jpg';
import RCSP_PREMIER from './RCSP-PREMIER.jpg';
import RTIC from './RTIC.jpg';
import RTIP from './RTIP.jpg';
// xxx: vertical logos
import REC1P_VERTICAL from './vertical/1P.svg';
import AD_VERTICAL from './vertical/AD.svg';
import RCSP_VERTICAL from './vertical/RCSP.svg';
import RCSP_BRONZE_VERTICAL from './vertical/RCSP_BRONZE.svg';
import RCSP_ENTERPRISE_VERTICAL from './vertical/RCSP_ENTERPRISE.svg';
import RCSP_GOLD_VERTICAL from './vertical/RCSP_GOLD.svg';
import RCSP_PLATINUM_VERTICAL from './vertical/RCSP_PLATINUM.svg';
import RCSP_PREMIER_VERTICAL from './vertical/RCSP_PREMIER.svg';
import RCSP_SILVER_VERTICAL from './vertical/RCSP_SILVER.svg';
import RTIC_VERTICAL from './vertical/RTIC.svg';
import RTIP_VERTICAL from './vertical/RTIP.svg';

const LOGO = {
  [ErpUsage['1P']]: REC1P,
  [ErpUsage.PP]: REC1P,
  [ErpUsage['2T']]: RCSP,
  [ErpUsage['2SP']]: RCSP,
  [ErpUsage.AD]: AD,
  [ErpUsage.RTIC]: RTIC,
  [ErpUsage.RTIP]: RTIP,
  [ErpUsage.RCSP]: RCSP,
  [ErpUsage.RCSP_PREMIER]: RCSP_PREMIER,
  [ErpUsage.RCSP_ENTERPRISE]: RCSP_ENTERPRISE,
  [ErpUsage.RCSP_BRONZE]: RCSP_BRONZE,
  [ErpUsage.RCSP_GOLD]: RCSP_GOLD,
  [ErpUsage.RCSP_SILVER]: RCSP_SILVER,
  [ErpUsage.RCSP_PLATINUM]: RCSP_PLATINUM,
};

const VERTICAL_LOGOS = {
  [ErpUsage['1P']]: REC1P_VERTICAL,
  [ErpUsage.PP]: REC1P_VERTICAL,
  [ErpUsage['2T']]: RCSP_VERTICAL,
  [ErpUsage['2SP']]: RCSP_VERTICAL,
  [ErpUsage.AD]: AD_VERTICAL,
  [ErpUsage.RTIC]: RTIC_VERTICAL,
  [ErpUsage.RTIP]: RTIP_VERTICAL,
  [ErpUsage.RCSP]: RCSP_VERTICAL,
  [ErpUsage.RCSP_PREMIER]: RCSP_PREMIER_VERTICAL,
  [ErpUsage.RCSP_ENTERPRISE]: RCSP_ENTERPRISE_VERTICAL,
  [ErpUsage.RCSP_BRONZE]: RCSP_BRONZE_VERTICAL,
  [ErpUsage.RCSP_GOLD]: RCSP_GOLD_VERTICAL,
  [ErpUsage.RCSP_SILVER]: RCSP_SILVER_VERTICAL,
  [ErpUsage.RCSP_PLATINUM]: RCSP_PLATINUM_VERTICAL,
};

type Props = {
  orgunit?: OrgUnitJSON;
  erpusage?: ErpUsage;
  vertical?: boolean;
  width?: number;
};

export default function ErpBadge({
  orgunit,
  erpusage,
  vertical = false,
  width = 175,
}: Props) {
  const erp =
    erpusage || (get(orgunit, 'erpServices.0.erpusage') as unknown as ErpUsage);
  const src = vertical ? VERTICAL_LOGOS[erp] : LOGO[erp];
  if (!src) return <div />;

  return (
    <UI.Image src={src} width={width} height="auto" resizeMode="contain" />
  );
}
