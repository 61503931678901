import contextualConfig from '@mmw/contextual-config';
import { AddressJSON } from '@mmw/services-core-common/types';
import {
  OrgunitDetailsJSON,
  OrgunitDetailsType,
  TraderJSON,
} from '@mmw/services-core-store-locator/types';
import { Coordinates } from '@store-locator/store-creator/types';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import find from 'lodash/find';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useMemo } from 'react';
import { U } from 'ts-toolbelt';

import { StoreLocatorSearchFormValues } from './stores-search/form/configs';

const {
  googleMaps: { routeBase },
} = contextualConfig;

export function createRouteLink(
  from: string,

  /**
   * https://goo.gl/6kjKZb
   * */
  to: AddressJSON,
): string {
  let address: string | AddressJSON = to;
  if (typeof to === 'object') {
    address = `${to.street} ${to.nr || ''}, ${to.city}`;
  }
  return `${routeBase}?saddr=${from}&${qs.stringify({
    daddr: address,
    ie: 'UTF8',
  })}`;
}

export function calculateZoom(radius: number, isMobile?: boolean) {
  if (radius === 5) {
    return 11;
  }
  if (isMobile) {
    return 11 - (radius / 5) * 0.25;
  }
  return 11.5 - (radius / 5) * 0.25;
}

export function useCalculatedCenterMarker(radius: U.Nullable<number>) {
  const isMobile = useIsMediaQueryUpToMD();
  const defaultMarker = -280;
  const mobileDefualtMarker = 0;
  return useMemo(() => {
    if (!radius) return defaultMarker;
    const currentZoom = calculateZoom(radius);
    if (isMobile) {
      return defaultMarker;
    }
    return -(currentZoom * 32);
  }, [defaultMarker, isMobile, radius]);
}

export function getStorePosition(store: TraderJSON): Coordinates {
  return {
    lat: store.address.lat as number,
    lng: store.address.lng as number,
  };
}

export function generateRequest(data: Partial<StoreLocatorSearchFormValues>) {
  if (isEmpty(data?.city) && isEmpty(data?.zipcode)) return null;

  if (isEmpty(data?.city) && data?.zipcode) {
    return {
      query: `Germany postal code ${data?.zipcode}`,
      fields: ['name', 'geometry'],
    };
  }

  if (isEmpty(data?.zipcode) && data?.city) {
    return {
      query: data?.city,
      fields: ['name', 'geometry'],
    };
  }

  return {
    query: `${data?.zipcode} ${data?.city}`,
    fields: ['name', 'geometry'],
  };
}

export function getStoreDetails(
  store: U.Nullable<TraderJSON>,
): OrgunitDetailsJSON {
  return (
    find(store?.details, detail => detail.type === OrgunitDetailsType.SALES) ||
    (head(store?.details) as OrgunitDetailsJSON)
  );
}
