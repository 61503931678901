import { sendEvent } from '@mmw/common-ga';
import ofType from '@mmw/redux-rx-of-type-operator';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import logger from '../../log';
import { submitErrorAction, submitSuccessAction } from '../actions';
import { allFormsSelector } from '../stateSelector';
import {
  AllForms,
  RootState,
  SUBMIT_START,
  SubmitErrorAction,
  SubmitStartAction,
  SubmitSuccessAction,
  ValidationError,
} from '../types';

type PipeData = [SubmitStartAction, AllForms];

// https://github.com/jquense/yup#mixedvalidatevalue-any-options-object-promiseany-validationerror
const YUP_OPTIONS = { abortEarly: false };

function getGaOptions(formId, allForms) {
  if (!allForms[formId].options || !allForms[formId].options.ga) {
    return null;
  }
  return allForms[formId].options.ga;
}

const onSubmitStart = (
  action$: ActionsObservable<SubmitStartAction>,
  state$: Observable<RootState>,
): Observable<SubmitSuccessAction<any> | SubmitErrorAction<any>> =>
  action$.pipe(
    ofType(SUBMIT_START),
    withLatestFrom(state$.pipe(map(allFormsSelector))),
    tap(
      ([
        {
          payload: { formId },
        },
        allForms,
      ]: PipeData) => {
        logger.info('Submit form start');
        const ga = getGaOptions(formId, allForms);
        if (ga && ga.onSubmit) {
          sendEvent(ga.onSubmit);
        }
      },
    ),
    switchMap(
      ([
        {
          payload: { formId, validationSchema },
        },
        allForms,
      ]: PipeData) =>
        from(
          validationSchema.validate(
            allForms[formId].currentValues,
            YUP_OPTIONS,
          ),
        ).pipe(
          tap(() => {
            const ga = getGaOptions(formId, allForms);
            if (ga && ga.onSuccess) {
              sendEvent(ga.onSuccess);
            }
          }),
          map(values => submitSuccessAction(formId, values)),
          catchError(
            (error: ValidationError) => (
              tap(() => {
                const ga = getGaOptions(formId, allForms);
                if (ga && ga.onError) {
                  sendEvent(ga.onError);
                }
              }),
              of(
                submitErrorAction(
                  formId,
                  allForms[formId].currentValues,
                  error.inner,
                ),
              )
            ),
          ),
        ),
    ),
  );

export default onSubmitStart;
