import { INFORMATION } from '@mmw/constants-i18n-common';

const summaryNamespace = 'summary';
const successNamespace = 'success';
const downloadRegistrationNamespace = `${summaryNamespace}.downloadRegistrationNamespace`;
const registrationDataNamespace = `${summaryNamespace}.registrationData`;
const registrationCompletedWithDownloadNamespace = `${summaryNamespace}.registrationCompleted`;
const registrationCompletedNamespace = `${successNamespace}.registrationCompleted`;
const purchaseNamespace = `${registrationDataNamespace}.purchase`;
const questionsNamespace = `${purchaseNamespace}.questions`;

const DOWNLOAD_REGISTRATION_DOCUMENTS = {
  TITLE: {
    key: `${downloadRegistrationNamespace}.title`,
    message: 'Registration Complete',
  },
  DESCRIPTION: {
    key: `${downloadRegistrationNamespace}.description`,
    message:
      'Congratulations, your registration was successfully completed. If you wish to download the registration document, please click below.',
  },
  DOWNLOAD_BUTTON: {
    key: `${downloadRegistrationNamespace}.download`,
    message: 'Download registration document',
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${downloadRegistrationNamespace}.help.content`,
      message:
        'If you have any questions regarding the processing of your registration, please check your campaign status and the status help content. If that does not help you, you can always contact us.',
    },
  },
};

const REGISTRATION_DATA = {
  TITLE: {
    key: `${registrationDataNamespace}.${summaryNamespace}.title`,
    message: 'Summary of your registration',
  },
  DESCRIPTION: {
    key: `${registrationDataNamespace}.${summaryNamespace}.description`,
    message: 'Review the information and complete your registration.',
  },
  PURCHASE: {
    key: purchaseNamespace,
    message: 'Purchase Information',
  },
  // PRODUCT: {}, // using SelectedProductCard component
  QUESTIONS: {
    key: questionsNamespace,
    message: 'Questions',
    ANSWERED: {
      key: `${questionsNamespace}.questionsAnswered`,
      message: 'Questionnaire Answered',
    },
  },
  WARRANTY: {
    TITLE: {
      key: 'extendedWarranty',
      message: 'Extended Warranty',
    },
    EXTEND_BUTTON: {
      key: 'extendWarranty.confirmation',
      message: 'Extended warranty contract',
    },
    DOWNLOAD_BUTTON: {
      key: 'extendWarranty.download',
      message: 'Download warranty contract',
    },
  },
  ADD_SERVICES: {
    key: 'addServices',
    message: 'Additional Services',
  },
  REGISTRATION_SHEET: {
    key: 'registrationSheet',
    message: 'Registration Sheet',
  },
};

const COMPLETED_REGISTRATION = {
  TITLE: {
    key: `${registrationCompletedWithDownloadNamespace}.title`,
    message: 'Registration Complete',
  },
  DESCRIPTION: {
    key: `${registrationCompletedWithDownloadNamespace}.description`,
    message:
      'Congratulations, your registration was successfully completed. If you wish to download the registration document, please click below.',
  },
  NO_DOWNLOAD_DESCRIPTION: {
    key: `${registrationCompletedNamespace}.simpleDescription`,
    message: 'Congratulations, your registration was successfully completed.',
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${registrationCompletedWithDownloadNamespace}.help.content`,
      message:
        'If you have any questions regarding the processing of your registration, please check your campaign status and the status help content. If that does not help you, you can always contact us.',
    },
  },
};

const I18N = {
  REGISTRATION_DATA,
  DOWNLOAD_REGISTRATION_DOCUMENTS,
  COMPLETED_REGISTRATION,
};

export default I18N;
