import { useVideoStyle } from '@ui-system/default-styles/video';
import { VideoProps } from '@ui-system/interfaces-video';
import React, { CSSProperties } from 'react';
import ReactPlayer from 'react-player';

const Video: React.FC<VideoProps> = ({
  src,
  height,
  width,
  resizeMode,
  style,
  controls,
  ...rest
}: VideoProps) => {
  const videoStyle = useVideoStyle(style as CSSProperties, '', null, {
    height,
    width,
    resizeMode,
  });
  return (
    <ReactPlayer
      url={src}
      controls={controls}
      width={width}
      height={height}
      config={{
        file: {
          attributes: {
            style: videoStyle,
          },
        },
      }}
      {...rest}
    />
  );
};

export default Video;
