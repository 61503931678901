import {
  DefaultIconLevelsNames,
  DefaultIconSetNames,
  IconSetType,
} from './interfaces';
import {
  arrow,
  back,
  calendar,
  camera,
  cart,
  check,
  clear,
  close,
  closeCircle,
  dashboard,
  down,
  download,
  downSolid,
  edit,
  editCircle,
  emptySearch,
  error,
  fax,
  file,
  flash,
  flashAuto,
  flashlight,
  flashlightOn,
  flashOff,
  flipCam,
  gallery,
  hide,
  inExamination,
  info,
  key,
  list,
  local,
  login,
  mail,
  menu,
  mobile,
  next,
  notVisible,
  person,
  phone,
  place,
  plus,
  previous,
  product,
  qrcode,
  question,
  questions,
  redeem,
  search,
  star,
  starSolid,
  status,
  up,
  upload,
  upSolid,
  view,
  visible,
  warning,
  world,
  write,
} from './svgs';

const DEFAULT_ICONS_SET: IconSetType<
  DefaultIconSetNames,
  DefaultIconLevelsNames
> = {
  error,
  qrcode,
  menu,
  next,
  previous,
  clear,
  list,
  camera,
  close: {
    regular: close,
    circle: closeCircle,
  },
  dashboard,
  question,
  flipCam,
  gallery,
  calendar,
  back,
  key,
  login,
  person,
  search,
  place,
  check,
  redeem,
  info,
  phone,
  mobile,
  fax,
  up: {
    regular: up,
    solid: upSolid,
  },
  down: {
    regular: down,
    solid: downSolid,
  },
  star: {
    regular: star,
    solid: starSolid,
  },
  edit: {
    regular: edit,
    circle: editCircle,
  },
  view,
  hide,
  write,
  warning,
  product,
  cart,
  file,
  questions,
  arrow,
  local,
  mail,
  visible,
  notVisible,
  status,
  world,
  inExamination,
  emptySearch,
  download,
  upload,
  plus,
  flash,
  flashAuto,
  flashOff,
  flashlight,
  flashlightOn,
};

export default DEFAULT_ICONS_SET;
