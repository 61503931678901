import { I18nShape } from '@mmw/constants-i18n';
import { PERSON as PERSON_I18N } from '@mmw/constants-i18n-fields';
import { AddressJSON } from '@mmw/services-core-common/types';
import { TraderJSON } from '@mmw/services-core-store-locator/types';
import joinAddress from '@mmw/utils-address/joinAddress';
import { useOriginCoordinates } from '@store-locator/store-creator/hooks';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useMemo } from 'react';
import { U } from 'ts-toolbelt';
import strIncludes from 'voca/includes';

import { useCustomTooltipItem } from '../../context';
import GMapsLogo from '../../map/Google-Maps-Logo.png';
import { createRouteLink, getStoreDetails } from '../../utils';
import DemoDevices from './DemoDevices';

const GOOGLE_MAPS_LOGO_STYLE = {
  alignSelf: 'flex-end',
};
const MOBILE = {
  display: 'flex',
  flexDirection: 'column',
};
const DESKTOP = {
  display: 'flex',
  justifyContent: 'space-between',
};
function fixLinkAddress(link: string) {
  if (!strIncludes(link, 'http') || !strIncludes(link, 'https')) {
    return `https://${link}`;
  }
  return link;
}

interface LabelItemProps {
  labelI18n: I18nShape;
  text: U.Nullable<string>;
  linkUrl?: U.Nullable<string>;
}

const LabelItem: React.FC<LabelItemProps> = ({
  labelI18n,
  text,
  linkUrl,
}: LabelItemProps) => {
  if (!text) return null;
  return (
    <UI.Container direction="column" m="1, 0">
      <UI.Typography i18n={labelI18n} modifiers="grayA300, uppercase" />
      {linkUrl ? (
        <UI.Link href={fixLinkAddress(linkUrl)}>
          <UI.Typography>{text}</UI.Typography>
        </UI.Link>
      ) : (
        <UI.Typography>{text}</UI.Typography>
      )}
    </UI.Container>
  );
};

interface Props {
  store: U.Nullable<TraderJSON>;
  contentPadding?: string;
}

const ERP_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginRight: '1rem',
};

const Details: React.FC<Props> = ({ store, contentPadding }: Props) => {
  const storeDetails = useMemo(() => getStoreDetails(store), [store]);
  const coordinates = useOriginCoordinates();
  const retailEmail = get(storeDetails, 'email', store?.email);
  const storeHomepage = get(storeDetails, 'homepage', store?.homePage);
  const CustomTooltipItem = useCustomTooltipItem();
  const isMobile = useIsMediaQueryUpToMD();
  const onClickRoute = useCallback(() => {
    if (coordinates?.lat && !isEmpty(store?.address)) {
      return window.open(
        createRouteLink(`/Current+Location/`, store?.address as AddressJSON),
        '_blank',
      );
    }
    // eslint-disable-next-line no-alert
    return alert('Failed to trace a route to the retailer');
  }, [coordinates, store?.address]);
  return (
    <UI.Container
      style={isMobile ? MOBILE : DESKTOP}
      direction={CustomTooltipItem ? 'column' : 'row'}
    >
      <UI.Container
        style={css`
          width: 100%;
        `}
        f={1}
        direction="column"
        p={contentPadding}
      >
        {store?.address ? (
          <UI.Typography
            style={css`
              flex: 1;
              display: flex;
              flex-wrap: wrap;
            `}
          >
            {joinAddress(store?.address)}
          </UI.Typography>
        ) : null}

        <LabelItem
          labelI18n={PERSON_I18N.PHONE.LABEL}
          text={get(storeDetails, 'phone', store?.phone)}
        />
        <LabelItem
          labelI18n={PERSON_I18N.EMAIL.LABEL}
          text={retailEmail}
          linkUrl={retailEmail ? `mailto:${retailEmail}` : null}
        />
        <LabelItem
          labelI18n={PERSON_I18N.HOMEPAGE.LABEL}
          text={storeHomepage}
          linkUrl={storeHomepage}
        />
      </UI.Container>
      {CustomTooltipItem ? <CustomTooltipItem store={store} /> : null}
      <UI.Container align="flex-start" direction="column" p="0, 3">
        <DemoDevices devices={store?.demoDevices} />
        <UI.Container
          style={GOOGLE_MAPS_LOGO_STYLE}
          align="center"
          gap={2}
          onClick={onClickRoute}
        >
          <UI.Image src={GMapsLogo} width={100} height={55} />
          <UI.Icon name="next" size={20} color="gray.A400" />
        </UI.Container>
      </UI.Container>
    </UI.Container>
  );
};

export default Details;
