const uploadProofOfSelloutNamespace = 'uploadProofOfSellout';
const posDocuNamespace = 'posDocu';
const classOfGoodsNamespace = 'classOfGoods';

const PROOF_OF_SELLOUT = {
  LABEL: {
    key: `${uploadProofOfSelloutNamespace}.label`,
    message: 'Upload Proof of Sellout',
  },
};

const POS_DOCU = {
  LABEL: {
    key: `${posDocuNamespace}.label`,
    message: 'POS-DOCU',
  },
  CLASS_OF_GOODS: {
    LABEL: {
      key: `${posDocuNamespace}.${classOfGoodsNamespace}.selectorLabel`,
      message: 'Please select a Class of Good for this picture',
    },
  },
};

const I18N = {
  PROOF_OF_SELLOUT,
  POS_DOCU,
};

export default I18N;
