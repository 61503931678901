import { SchemaObjectDescription } from 'yup';

import useFastFormProviderContext from './useFastFormProviderContext';

function useFormValidationDescription(): SchemaObjectDescription {
  const context = useFastFormProviderContext();
  return context.validationDescription;
}

export default useFormValidationDescription;
