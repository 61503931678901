import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import {
  languageSelector,
  RootState as LangState,
} from '@mmw/redux-store-i18n/stateSelector';
import { getCommonService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  getAvailableCountriesErrorAction,
  getAvailableCountriesSuccessAction,
} from './actions';
import { RootState } from './stateSelector';
import {
  GET_AVAILABLE_COUNTRIES_START,
  GetAvailableCountriesErrorAction,
  GetAvailableCountriesStartAction,
  GetAvailableCountriesSuccessAction,
} from './types';

const { logger } = contextualConfig.application;

const getAvailableCountriesEpic = (
  action$: ActionsObservable<GetAvailableCountriesStartAction>,
  state$: Observable<RootState & LangState>,
): Observable<
  GetAvailableCountriesSuccessAction | GetAvailableCountriesErrorAction
> =>
  action$.pipe(
    ofType(GET_AVAILABLE_COUNTRIES_START),
    tap(() => logger.debug('Get Available Countries Start')),
    combineLatest(state$.pipe(map(languageSelector), distinctUntilChanged())),
    tap(([, language]) =>
      logger.debug('Trying to get available countries by lang=%s', language),
    ),
    switchMap(([, language]) =>
      from(getCommonService().getCountries(language)).pipe(
        tap(data =>
          logger.info(
            `Got countries for lang=%s with size=${data.length}`,
            language,
          ),
        ),
        map(data => getAvailableCountriesSuccessAction(data)),
        catchError(error => of(getAvailableCountriesErrorAction(error))),
      ),
    ),
  );

export default getAvailableCountriesEpic;
