import { EMPTY_OBJECT } from '@shared-utils/object';
import { O } from '@utils/ts';

import {
  FastFormOptions,
  FieldPath,
  FormId,
  ValidationError,
  ValidationSchema,
} from '../types';

export const START = '@mmw/fast-form/START';
export const RESTART = '@mmw/fast-form/RESTART';
export const CHANGE_CONFIG = '@mmw/fast-form/CHANGE_CONFIG';
export const SET_INITIAL_VALUES = '@mmw/fast-form/SET_INITIAL_VALUES';
export const DELETE = '@mmw/fast-form/DELETE';
export const UPDATE_FIELD_VALUE = '@mmw/fast-form/UPDATE_FIELD_VALUE';
export const UPDATE_INITIAL_FIELD_VALUE =
  '@mmw/fast-form/UPDATE_INITIAL_FIELD_VALUE';
export const UPDATE_FIELD_VALUES_BY_QUERY =
  '@mmw/fast-form/UPDATE_FIELD_VALUES_BY_QUERY';
export const SET_FIELD_TOUCHED = '@mmw/fast-form/SET_FIELD_TOUCHED';
export const SET_FIELD_ERRORS = '@mmw/fast-form/SET_FIELD_ERRORS';
export const SET_FIELD_WATCHED = '@mmw/fast-form/SET_FIELD_WATCHED';
export const SET_FIELD_UNWATCHED = '@mmw/fast-form/SET_FIELD_UNWATCHED';
export const RESET_FORM_STATE = '@mmw/fast-form/RESET_FORM_STATE';
export const SET_FORM_STATE = '@mmw/fast-form/SET_FORM_STATE';
export const SUBMIT_START = '@mmw/fast-form/SUBMIT_START';
export const SUBMIT_SUCCESS = '@mmw/fast-form/SUBMIT_SUCCESS';
export const SUBMIT_ERROR = '@mmw/fast-form/SUBMIT_ERROR';
export const VALIDATE_START = '@mmw/fast-form/VALIDATE_START';
export const VALIDATE_SUCCESS = '@mmw/fast-form/VALIDATE_SUCCESS';

// https://github.com/kolodny/immutability-helper
export type FormUpdate = {
  fieldPath: FieldPath;
  value: any;
  command?:
    | '$set'
    | '$push'
    | '$unshift'
    | '$splice'
    | '$toggle'
    | '$unset'
    | '$merge';
};

export type FieldPathToValue<V> = {
  [FieldPath]: V;
};

export type StartAction<T> = {
  type: '@mmw/fast-form/START';
  payload: {
    formId: FormId;
    initialValues: T;
    options: FastFormOptions<T>;
    validationSchema: ValidationSchema | null;
  };
};

export type RestartAction<T> = {
  type: '@mmw/fast-form/RESTART';
  payload: {
    formId: FormId;
    initialValues: T;
    options: FastFormOptions<T>;
    validationSchema: ValidationSchema | null;
  };
};

export type ChangeConfigAction<T> = {
  type: '@mmw/fast-form/CHANGE_CONFIG';
  payload: {
    formId: FormId;
    options: FastFormOptions<T>;
    validationSchema: ValidationSchema | null;
  };
};
export type SetInitialValuesAction<T> = {
  type: '@mmw/fast-form/SET_INITIAL_VALUES';
  payload: {
    formId: FormId | null;
    initialValues: T;
  };
};

export type DeleteAction = {
  type: '@mmw/fast-form/DELETE';
  payload: {
    formId: FormId;
  };
};

export type UpdateFieldValueAction = {
  type: '@mmw/fast-form/UPDATE_FIELD_VALUE';
  payload: {
    formId: FormId;
    changes: Array<FormUpdate>;
  };
};

export type UpdateInitialFieldValueAction = {
  type: '@mmw/fast-form/UPDATE_INITIAL_FIELD_VALUE';
  payload: {
    formId: FormId;
    changes: Array<FormUpdate>;
  };
};

export type UpdateQueryObject = {}; // https://github.com/kolodny/immutability-helper

export type UpdateFieldValuesByQueryAction = {
  type: '@mmw/fast-form/UPDATE_FIELD_VALUES_BY_QUERY';
  payload: {
    formId: FormId;
    query: UpdateQueryObject;
  };
};

export type SetFieldTouchedAction = {
  type: '@mmw/fast-form/SET_FIELD_TOUCHED';
  payload: {
    formId: FormId;
    fieldPath: FieldPath;
  };
};

export type SetFieldErrorsAction = {
  type: '@mmw/fast-form/SET_FIELD_ERRORS';
  payload: {
    formId: FormId;
    errors: Array<ValidationError> | null;
  };
};

export type SetFieldWatchedAction = {
  type: '@mmw/fast-form/SET_FIELD_WATCHED';
  payload: {
    formId: FormId;
    fieldPath: FieldPath;
  };
};

export type SetFieldUnwatchedAction = {
  type: '@mmw/fast-form/SET_FIELD_UNWATCHED';
  payload: {
    formId: FormId;
    fieldPath: FieldPath;
  };
};

export class ResetFormStateOptions<T extends O.Object> {
  resetWatchedFields?: boolean = true;

  resetTouchedFields?: boolean = true;

  resetSubmitCount?: boolean = true;

  resetErrors?: boolean = true;

  resetValues?: boolean = true;

  resetSubmittedValues?: boolean = true;

  resetInitialValues?: T = EMPTY_OBJECT;
}

export type ResetFormStateAction<T> = {
  type: '@mmw/fast-form/RESET_FORM_STATE';
  payload: {
    formId: FormId;
  } & ResetFormStateOptions<T>;
};

export type SetFormStateOptions = {
  increaseSubmitCount?: boolean;
  setUnsuccessfullySubmitted?: boolean;
};

export type SetFormStateAction = {
  type: '@mmw/fast-form/SET_FORM_STATE';
  payload: {
    formId: FormId;
  } & SetFormStateOptions;
};

export type SubmitStartAction = {
  type: '@mmw/fast-form/SUBMIT_START';
  payload: {
    formId: FormId;
    validationSchema: ValidationSchema;
  };
};

export type SubmitSuccessAction<T> = {
  type: '@mmw/fast-form/SUBMIT_SUCCESS';
  payload: {
    formId: FormId;
    submittedValues: T;
  };
};

export type SubmitErrorAction<T> = {
  type: '@mmw/fast-form/SUBMIT_ERROR';
  payload: {
    formId: FormId;
    submittedValues: T;
    errors: Array<ValidationError>;
  };
};

export type ValidateStartAction = {
  type: '@mmw/fast-form/VALIDATE_START';
  payload: {
    formId: FormId;
    onlyWatchedFields: boolean;
    validationSchema: ValidationSchema;
  };
};

export type ValidateSuccessAction = {
  type: '@mmw/fast-form/VALIDATE_SUCCESS';
  payload: {
    formId: FormId;
    onlyWatchedFields: boolean;
    errors: Array<ValidationError>;
  };
};

export type FormState<T> = {
  initialValues: T;
  currentValues: T;
  submittedValues: T | null;
  currentErrors: FieldPathToValue<ValidationError>;
  touchedFieldPaths: FieldPathToValue<boolean>;
  watchedFieldPaths: FieldPathToValue<number>;
  validationSchema: ValidationSchema | null;
  flags: {
    isValidating: boolean;
    isSubmitting: boolean;
    submitCount: number;
    isManuallyValidated: boolean;
    isSuccessfullyValidated: boolean;
    isSuccessfullySubmitted: false;
    isUnsuccessfullySubmitted: false;
    isValid: boolean;
  };
  options: FastFormOptions | null;
};

export type AllForms = {
  [FormId]: FormState<any>;
};

export type State = {
  forms: AllForms;
};

export const NAMESPACE = 'fastForm';

export type RootState = {
  fastForm: State;
};

export type Action =
  | StartAction<any>
  | RestartAction<any>
  | ChangeConfigAction<any>
  | SetInitialValuesAction<any>
  | DeleteAction
  | UpdateFieldValueAction
  | UpdateInitialFieldValueAction
  | UpdateFieldValuesByQueryAction
  | SetFieldTouchedAction
  | SetFieldErrorsAction
  | SetFieldWatchedAction
  | SetFieldUnwatchedAction
  | ResetFormStateAction<any>
  | SetFormStateAction
  | SubmitStartAction
  | SubmitSuccessAction<any>
  | SubmitErrorAction<any>
  | ValidateStartAction
  | ValidateSuccessAction;

export type Reducer = (state: State, action: Action) => State;
