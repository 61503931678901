import { CountryCode } from '@mmw/constants-country-codes';
import { LanguageCode } from '@mmw/constants-languages';
import isEmpty from 'lodash/isEmpty';
import { U } from 'ts-toolbelt';

import timeZonesToCountry from './timeZonesToCountry.json';

export function getBrowserCountryCodeByLang(
  supportedCountries: U.Nullable<CountryCode[]>,
  defaultCountry?: CountryCode,
): string {
  if (
    !navigator ||
    navigator.language === null ||
    (!supportedCountries && defaultCountry)
  ) {
    return defaultCountry as string;
  }
  // if (supportedCountries.length === 1) {
  //   return supportedCountries[0];
  // }
  const langCountryCode = navigator.language.slice(
    3,
    navigator.language.length,
  );

  if (!supportedCountries?.includes(langCountryCode) && defaultCountry) {
    return defaultCountry;
  }
  return langCountryCode;
}

export function getUserDeviceCountryByTimeZone(
  supportedCountries?: U.Nullable<CountryCode[]>,
  defaultCountry?: CountryCode,
) {
  let userCountry;

  if (Intl) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzArr = userTimeZone.split('/');
    const userCity = tzArr[tzArr.length - 1];
    userCountry = timeZonesToCountry[userCity];
  }
  if (userCountry && isEmpty(supportedCountries)) {
    return userCountry;
  }

  if (
    !isEmpty(supportedCountries) &&
    !supportedCountries?.includes(userCountry) &&
    defaultCountry
  ) {
    return defaultCountry;
  }
  // fallback by browser lang
  userCountry = getBrowserCountryCodeByLang(supportedCountries, defaultCountry);

  return userCountry;
}

const detectBrowserLanguage = (
  supportedLanguages: LanguageCode[],
  fallback: LanguageCode,
): string => {
  if (!navigator || navigator.language === null) {
    return fallback;
  }
  const language = navigator.language.slice(0, 2);
  if (supportedLanguages.includes(language)) {
    return language;
  }
  return fallback;
};

export default detectBrowserLanguage;
