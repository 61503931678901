import { Color, css } from '@ui-system/css';
import {
  SCREE_BOTTOM_UUID,
  SCREEN_HEADER_UUID,
  SCREEN_UUID,
} from '@ui-system/interfaces';
import pxPerfect from '@ui-system/px-perfect';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useScreenDefaultStyle = makeStyle<{
  backgroundColor: Color;
}>(({ props, utils, theme }) => ({
  ...css`
    ${utils.applyBackgroundColor(theme, props?.backgroundColor)};
  `,
  ...props,
}));

const useScreenHeaderDefaultStyle = makeStyle<{ style: Record<string, any> }>(
  ({ props, theme }) => ({
    ...css`
      z-index: 999;
      height: ${pxPerfect(65, false, 'height')};
      background-color: transparent;
    `,
    ...props,
  }),
);

// const useSreenContentDefaultStyle = makeStyle<{ style: Record<string, any> }>(
//   ({ props, utils, theme }) => ({
//     ...css``,
//     ...props,
//   }),
// );

const useScreenBottomDefaultStyle = makeStyle<{
  backgroundColor: Color;
}>(({ props, utils, theme }) => ({
  ...css`
    ${utils.applyBackgroundColor(
      theme,
      props?.backgroundColor || 'background',
    )};
    position: absolute;
    bottom: ${pxPerfect(10)};
  `,
  ...props,
}));

export const useScreenStyle = makeFinalStyleHook(
  SCREEN_UUID,
  useScreenDefaultStyle,
);

export const useScreenHeaderStyle = makeFinalStyleHook(
  SCREEN_HEADER_UUID,
  useScreenHeaderDefaultStyle,
);

export const useScreenBottomStyle = makeFinalStyleHook(
  SCREE_BOTTOM_UUID,
  useScreenBottomDefaultStyle,
);

// export const useScreenContentStyle = makeFinalStyleHook(
//   SCREEN_CONTENT_UUID,
//   useSreenContentDefaultStyle,
// );

// const useButtonTextDefaultStyle = makeStyle(() => css``);
// export const useButtonTextStyle = makeFinalStyleHook(
//   BUTTON_TEXT_UUID,
//   useButtonTextDefaultStyle,
// );
