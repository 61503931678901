import { useDeepCompareEffect } from '@react-utils/hooks';
import qs from 'qs';
import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom';

import { useLocation, useNavigateTo, useQueryParams } from './hooks';

export { default as history } from './history';
export { ScrollToTop } from './ScrollToTop';
export {
  ConnectedRouter,
  connectRouter,
  push,
  replace,
  routerMiddleware,
} from 'connected-react-router';

export const NavigationRouter = BrowserRouter;
export const NavigationLink = Link;
export const NavigationRoute = Route;
export const NavigationSwitch = Switch;
export const NavigationRedirect = Redirect;
export const AnimatedNavigationSwitch = Switch;

export * from './hooks';
export * from './listeners';

export const HandleBaseRedirect = (): null => {
  const location = useLocation();
  const { router, ...rest } = useQueryParams<Record<string, any>>();
  const navigateTo = useNavigateTo();
  useDeepCompareEffect(() => {
    if (location?.pathname?.includes('.html')) {
      navigateTo(router, rest);
    } else if (router) {
      navigateTo(`${router}?${qs.stringify(rest)}`);
    }
  }, [location?.pathname, navigateTo, rest, router]);

  return null;
};

export const HandleBaseRedirectForBucketRoot = (): null => {
  const location = useLocation();
  const { router, ...rest } = useQueryParams<Record<string, any>>();
  const navigateTo = useNavigateTo();

  useDeepCompareEffect(() => {
    if (location?.pathname?.includes('.html')) {
      navigateTo(router, rest);
    } else if (router) {
      navigateTo(`${router}?${qs.stringify(rest)}`);
    }
  }, [location?.pathname, navigateTo, rest, router]);

  return null;
};
