import { Components } from '@ui-system/interfaces';
import * as React from 'react';

import {
  RootComponentsContext,
  useRootComponentsContextValue,
} from './context';

interface Props extends React.PropsWithChildren<unknown> {
  components: Components;
}
export const RootComponentsProvider: React.FC<Props> = ({
  components,
  children,
}: Props) => {
  const value = useRootComponentsContextValue(components);
  return (
    <RootComponentsContext.Provider value={value}>
      {children}
    </RootComponentsContext.Provider>
  );
};
