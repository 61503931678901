import { Environment, getEnvironment } from '@mmw/environment';
import logger, {
  disable as logDisable,
  enable as logEnable,
  getNamespaces as logNamespaces,
} from '@mmw/logging-logger';
import { deepOverride } from '@mmw/utils-object-utils';

import { Config } from './types';

export const PIWIK_URL = 'https://piwik.markenmehrwert.com/piwik/';
export const NO_REPLY_EMAIL = 'noreply@markenmehrwert.com';

export const defaultConfig: Config = {
  environment: 'development',
  i18n: {
    defaultLanguage: 'de',
  },
  recaptcha: {
    publickey: '6LcVceYUAAAAAE91ElDhqvBMAUYKxSI727x_Zct1',
    // publickeyv2: '6LeccJoUAAAAAN-jjND9orxo9mCe2t9XhW-bDRun',
    publickeyv2: '6Ldx0OkSAAAAALTwwI0bHQKIVnHlJ1pUf8Z3XlQi',
  },
  api: {
    defaultTimeout: 120 * 1000,
    // host: 'http://10.0.1.131:8080',
    // v2: {
    //   baseURI: 'http://10.0.1.131:8080/api-v2',
    // },
    // auth: {
    //   baseURI: 'http://10.0.1.131:8080/auth-api',
    // },
    host: 'http://localhost:8080',
    authHost: 'http://localhost:8080',
    v2: {
      baseURI: 'http://localhost:8080/api-v2',
    },
    jobs: {
      baseURI: 'http://localhost:8080/api-v2',
    },
    auth: {
      baseURI: 'http://localhost:8081/auth-api',
    },
    faq: {
      baseURI: 'http://localhost:8082/faq-api',
    },
  },
  clients: {
    retailClient: {
      baseURI: 'http://localhost:3002/retail-client',
      version: '3.27.18',
    },
    manufacturerClient: {
      baseURI: 'http://localhost:3002/manufacturer-client',
      version: '1.0.0',
    },
    consumerClient: {
      baseURI: 'http://localhost:3002/consumer-client',
      version: '1.0.0',
    },
  },
  static: {
    host: 'http://10.0.1.131:8080/static',
  },
  store: {
    defaultNamespace: 'global',
    authenticationNamespace: 'authentication',
    fingerprintNamespace: 'fingerprint',
  },
  core: {
    authApi: {
      accessTokenKey: 'auth-api-access-token-key',
      refreshTokenKey: 'auth-api-refresh-token-key',
    },
  },
  links: {
    common: {
      imprint: 'https://www.markenmehrwert.com/start/impressum/',
      dataProtection:
        'https://www.markenmehrwert.com/start/datenschutzerklaerung/',
      gtc: 'https://www.markenmehrwert.com/start/agb/',
      retailClient: {
        v3: 'https://staging-cloud.mmw.ag/retail-client',
      },
    },
  },
};

export const stagingConfig: Config = {
  environment: 'staging',
  recaptcha: {
    publickey: '6LcVceYUAAAAAE91ElDhqvBMAUYKxSI727x_Zct1',
  },
  i18n: {
    defaultLanguage: 'de',
  },
  api: {
    defaultTimeout: 120 * 1000,
    host: 'https://staging-cloud.mmw.ag',
    authHost: 'https://staging-cloud.mmw.ag',
    // v2: {
    //   baseURI: 'http://10.0.1.131:8080/api-v2',
    // },
    // auth: {
    //   baseURI: 'http://10.0.1.131:8080/auth-api',
    // },
    v2: {
      baseURI: 'https://staging-cloud.mmw.ag/api-v2',
    },
    jobs: {
      baseURI: 'https://staging-cloud.mmw.ag/api-v2',
    },
    auth: {
      baseURI: 'https://staging-cloud.mmw.ag/auth-api',
    },
    faq: {
      baseURI: 'https://staging-cloud.mmw.ag/faq-api',
    },
  },
  clients: {
    retailClient: {
      baseURI: 'https://staging-cloud.mmw.ag/retail-client',
      version: '3.29.0',
    },
    manufacturerClient: {
      baseURI: 'https://staging-cloud.mmw.ag/manufacturer-client',
      version: '1.0.0',
    },
    consumerClient: {
      baseURI: 'https://staging.mmw.ag/consumer-client',
      version: '1.0.0',
    },
  },
  static: {
    host: 'https://staging-cloud.mmw.ag/static',
  },
  store: {
    defaultNamespace: 'global',
    authenticationNamespace: 'authentication',
    fingerprintNamespace: 'fingerprint',
  },
  core: {
    authApi: {
      accessTokenKey: 'auth-api-access-token-key',
      refreshTokenKey: 'auth-api-refresh-token-key',
    },
  },
  links: {
    common: {
      imprint: 'https://www.markenmehrwert.com/start/impressum/',
      dataProtection:
        'https://www.markenmehrwert.com/start/datenschutzerklaerung/',
      gtc: 'https://www.markenmehrwert.com/start/agb/',
      retailClient: {
        v3: 'https://staging-cloud.mmw.ag/retail-client',
      },
    },
  },
};

export const stagingCloudConfig: Config = {
  environment: 'staging-cloud',
  recaptcha: {
    publickey: '6LcVceYUAAAAAE91ElDhqvBMAUYKxSI727x_Zct1',
    // publickey: '6LcicOYUAAAAAIV6qBYtfEqhPoqW9jB8Jl_Ri4YU',
  },
  i18n: {
    defaultLanguage: 'de',
  },
  api: {
    defaultTimeout: 120 * 1000,
    host: 'https://staging-cloud.mmw.ag',
    authHost: 'https://staging-cloud-login.mmw.ag',
    // v2: {
    //   baseURI: 'http://10.0.1.131:8080/api-v2',
    // },
    // auth: {
    //   baseURI: 'http://10.0.1.131:8080/auth-api',
    // },
    v2: {
      baseURI: 'https://staging-cloud.mmw.ag/api-v2',
    },
    jobs: {
      baseURI: 'https://staging-cloud.mmw.ag/api-v2',
    },
    auth: {
      baseURI: 'https://staging-cloud.mmw.ag/auth-api',
    },
    faq: {
      baseURI: 'https://staging-cloud.mmw.ag/faq-api',
    },
  },
  clients: {
    retailClient: {
      baseURI: 'https://staging-cloud.mmw.ag/retail-client',
      version: '3.29.0',
    },
    manufacturerClient: {
      baseURI: 'https://staging-cloud.mmw.ag/manufacturer-client',
      version: '1.0.0',
    },
    consumerClient: {
      baseURI: 'https://staging-cloud.mmw.ag/consumer-client',
      version: '1.0.0',
    },
  },
  static: {
    host: 'https://staging-cloud.mmw.ag/static',
  },
  store: {
    defaultNamespace: 'global',
    authenticationNamespace: 'authentication',
    fingerprintNamespace: 'fingerprint',
  },
  core: {
    authApi: {
      accessTokenKey: 'auth-api-access-token-key',
      refreshTokenKey: 'auth-api-refresh-token-key',
    },
  },
  links: {
    common: {
      imprint: 'https://www.markenmehrwert.com/start/impressum/',
      dataProtection:
        'https://www.markenmehrwert.com/start/datenschutzerklaerung/',
      gtc: 'https://www.markenmehrwert.com/start/agb/',
      retailClient: {
        v3: 'https://staging-cloud.mmw.ag/retail-client',
      },
    },
  },
};

export const live: Config = {
  environment: 'live',
  recaptcha: {
    publickey: '6LcicOYUAAAAAIV6qBYtfEqhPoqW9jB8Jl_Ri4YU',
    // publickeyv2: '6LeccJoUAAAAAN-jjND9orxo9mCe2t9XhW-bDRun',
    publickeyv2: '6Ldx0OkSAAAAALTwwI0bHQKIVnHlJ1pUf8Z3XlQi',
  },
  api: {
    defaultTimeout: 60 * 1000,
    host: 'https://www.markenmehrwert.com',
    authHost: 'https://login.markenmehrwert.com',
    v2: {
      baseURI: 'https://www.markenmehrwert.com/api-v2',
    },
    jobs: {
      baseURI: 'https://google.markenmehrwert.com/api-v2',
    },
    auth: {
      baseURI: 'https://login.markenmehrwert.com/auth-api',
    },
    faq: {
      baseURI: 'https://www.markenmehrwert.com/faq-api',
    },
  },
  clients: {
    // The retail and manufacturer versions default set here
    retailClient: {
      baseURI: 'https://www.markenmehrwert.com/retail-client',
      version: '3.27.18',
    },
    manufacturerClient: {
      baseURI: 'https://www.markenmehrwert.com/manufacturer-client',
      version: '1.0.0',
    },
    consumerClient: {
      baseURI: 'https://www.markenmehrwert.com/consumer-client',
      version: '1.0.0',
    },
  },
  static: {
    host: 'https://www.markenmehrwert.com/static',
  },
  store: defaultConfig.store,
  core: defaultConfig.core,
  links: {
    common: {
      imprint: 'https://www.markenmehrwert.com/start/impressum/',
      dataProtection:
        'https://www.markenmehrwert.com/start/datenschutzerklaerung/',
      gtc: 'https://www.markenmehrwert.com/start/agb/',
      retailClient: {
        v3: 'https://www.markenmehrwert.com/retail-client',
      },
    },
  },
};

// XXX: deepmerge was not working here, replaced with deepOverride
const stagingCloud: Config = deepOverride(stagingConfig, {
  environment: Environment.STAGING_CLOUD,
  api: {
    host: 'https://staging-cloud.mmw.ag',
    authHost: 'https://staging-cloud-login.mmw.ag',
    v2: {
      baseURI: 'https://staging-cloud.mmw.ag/api-v2',
    },
    jobs: {
      baseURI: 'https://staging-cloud.mmw.ag/api-v2',
    },
    auth: {
      baseURI: 'https://staging-cloud-login.mmw.ag/auth-api',
    },
    faq: {
      baseURI: 'https://staging-cloud.mmw.ag/faq-api',
    },
  },
  clients: {
    retailClient: {
      baseURI: 'https://staging-cloud.mmw.ag/retail-client',
      version: '3.29.0',
    },
    manufacturerClient: {
      baseURI: 'https://staging-cloud.mmw.ag/manufacturer-client',
      version: '1.0.0',
    },
  },
  static: {
    host: 'https://staging-cloud.mmw.ag/static',
  },
});

export const development: Config = {
  ...defaultConfig,
  // ...live,
};

export const staging: Config = {
  ...stagingConfig,
};

export const environment = getEnvironment();

const getEnvironmentConfig = (): Config => {
  const oldNamespaces = logNamespaces();
  logEnable('*');
  logger.info('Starting up config for env=%s', environment);
  logDisable();
  if (oldNamespaces != null) {
    logEnable(oldNamespaces);
  }
  switch (environment) {
    case Environment.STAGING:
      return {
        ...defaultConfig,
        ...staging,
      };
    case Environment.STAGING_CLOUD:
      return {
        ...defaultConfig,
        ...stagingCloud,
        // ...live,
      };
    case Environment.DEVELOPMENT:
      return {
        ...defaultConfig,
        ...stagingCloud,
        // ...live,
      };
    case Environment.LIVE:
      return {
        ...defaultConfig,
        ...live,
      };
    default:
      return {
        ...defaultConfig,
        ...development,
      };
  }
};

const config: Config = getEnvironmentConfig();
export default config;

export function getStaticUrl(): string {
  return config.static.host;
}

export function getDefaultApiHostUrl(): string {
  return config.api.host;
}

export function getDefaultApiAuthHostUrl(): string {
  return config.api.authHost;
}

export function getPiwikUrl(): string {
  return PIWIK_URL;
}

export function getRetailClientVersion(): string {
  return config.clients.retailClient.version;
}

export function getManufacturerClientVersion(): string {
  return config.clients.manufacturerClient.version;
}

export function getRetailClientBaseUrl(): string {
  return config.clients.retailClient.baseURI;
}

export function getConsumerClientBaseUrl(): string {
  return config.clients.consumerClient.baseURI;
}

export function getRetailClientRecoverUsernameUrl(): string {
  return `${getRetailClientBaseUrl()}/recover-password/username`;
}

export function getRetailClientRecoverPasswordUrl(): string {
  return `${getRetailClientBaseUrl()}/recover-password/password`;
}

export function getConsumerClientRecoverPasswordUrl(): string {
  return `${getConsumerClientBaseUrl()}/recover-password/password`;
}
