import {
  applyAlign,
  applyBackgroundColor,
  applyBorderRadius,
  applyDevBorder,
  applyDirection,
  applyFlex,
  applyHeight,
  applyJustify,
  applyMargin,
  applyOpacity,
  applyPadding,
  applyShadow,
  applyWidth,
  styled,
} from '@ui-system/css';
import { ContainerProps } from '@ui-system/interfaces-container';
import isString from 'lodash/isString';

// Individual render/view
export const BaseContainer = styled.div<ContainerProps>`
  flex-direction: row;
  ${applyMargin.fromProps};
  ${applyPadding.fromProps};
  ${applyAlign.fromProps};
  ${applyDirection.fromProps};
  ${applyJustify.fromProps};
  ${applyFlex.fromProps};
  ${applyBackgroundColor.fromProps};
  ${applyShadow.fromProps};
  ${applyDevBorder.fromProps};
  ${applyHeight.fromProps};
  ${applyWidth.fromProps};
  ${applyOpacity.fromProps};
  ${applyBorderRadius.fromProps};
  ${({ position }) => (position ? `position: ${position};` : '')}
  ${({ border }) => (border ? `border: ${border};` : '')}
  ${({ borderRadius }) => {
    if (!borderRadius) return '';
    if (isString(borderRadius)) {
      return `border-radius: ${borderRadius};`;
    }
    return borderRadius ? `border-radius: ${borderRadius}px;` : '';
  }}
  display: ${({ visible = true }) => (visible ? 'flex' : 'none')};
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')}
`;

// ${applyPosition.fromProps}
// ${applyBorderRadius.fromProps};
// ${applyBorderBottomRightRadius.fromProps};
// ${applyBorderBottomLeftRadius.fromProps};
// ${applyBorderTopRightRadius.fromProps};
// ${applyBorderTopLeftRadius.fromProps};

export const BaseContainerPressable = BaseContainer;
export const LinearGradient = BaseContainer;
