import { I18nShape } from '@mmw/constants-i18n';
import { ValidationError } from '@mmw/redux-store-fast-form';
import {
  useMaxAndMinValidationValues,
  useSubmitStart,
} from '@mmw/redux-store-fast-form/hooks';
import {
  useFastFormField,
  useInputFieldConfigProps,
} from '@mmw/redux-store-fast-form/hooks/fields';
import useFastFormProviderContext from '@mmw/redux-store-fast-form/hooks/useFastFormProviderContext';
import { deepmerge } from '@shared-utils/object';
import { InputProps } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import React, { useMemo } from 'react';
import { U } from 'ts-toolbelt';

export interface FastFormInputProps extends Partial<InputProps> {
  fieldPath: string;
  placeholder?: U.Nullable<string | I18nShape>;
  customErrors?: U.Nullable<ValidationError>;
  forceShowError?: boolean;
  showWithError?: boolean;
  [props: string]: any;
}

const FastFormInput: React.FC<FastFormInputProps> = ({
  fieldPath,
  placeholder,
  customErrors,
  forceShowError,
  showWithError,
  ...rest
}: FastFormInputProps) => {
  const { value, setValue, errors, canShowError } =
    useFastFormField<string>(fieldPath);
  const { min, max } = useMaxAndMinValidationValues(fieldPath);
  const caption = useMemo(() => {
    if (
      !isEmpty(errors?.errors) &&
      isObject(errors?.errors[0]) &&
      errors?.errors[0]
    ) {
      return errors?.errors[0];
    }
    if (!isEmpty(errors?.message) || !isEmpty(customErrors?.message)) {
      return {
        message: errors?.message || customErrors?.message,
        key: errors?.message || customErrors?.message,
        values: { value, min, max },
      };
    }
    return null;
  }, [customErrors?.message, errors?.errors, errors?.message, max, min, value]);
  const inputFieldConfigProps = useInputFieldConfigProps(fieldPath);
  const otherProps = useMemo(
    () => deepmerge(inputFieldConfigProps, rest),
    [inputFieldConfigProps, rest],
  );
  const submit = useSubmitStart();
  const { options } = useFastFormProviderContext();

  return (
    // @ts-ignore
    <UI.Form.Input
      value={isNumber(value) ? String(value) : value || ''}
      onChange={setValue}
      placeholder={placeholder}
      error={
        showWithError ||
        ((forceShowError || canShowError) &&
          (!isEmpty(errors) || !isEmpty(customErrors?.message)))
      }
      caption={caption as I18nShape}
      data-test-id={fieldPath}
      onSubmitEditing={options.onPressEnter ? submit : undefined}
      {...otherProps}
    />
  );
};

export default FastFormInput;
