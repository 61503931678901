import { css, shorthand } from '@ui-system/css';
import { createComponentModifiers } from '@ui-system/modifiers';

const componentModifiers = createComponentModifiers(({ theme }) => ({
  fullWidth: css`
    flex: 1;
    color: ${theme.colors[theme.mode].primary.main};
  `,
  primary: {
    backgroundColor: theme.colors[theme.mode].primary.main,
    borderColor: theme.colors[theme.mode].primary.main,
  },
  error: {
    backgroundColor: theme.colors[theme.mode].error,
    borderColor: theme.colors[theme.mode].error,
    color: theme.colors[theme.mode].white,
  },
  success: {
    backgroundColor: theme.colors[theme.mode].success,
    borderColor: theme.colors[theme.mode].success,
    color: theme.colors[theme.mode].white,
  },
  grayA100: {
    backgroundColor: theme.colors[theme.mode].gray.A100,
    borderColor: theme.colors[theme.mode].gray.A100,
  },
  grayA300: {
    backgroundColor: theme.colors[theme.mode].gray.A300,
    borderColor: theme.colors[theme.mode].gray.A300,
    color: theme.colors[theme.mode].white,
  },
  grayA400: {
    backgroundColor: theme.colors[theme.mode].gray.A400,
    borderColor: theme.colors[theme.mode].gray.A400,
    color: theme.colors[theme.mode].white,
  },
  secondary: css`
    background-color: ${theme.colors[theme.mode].secondary.main};
    border-color: ${theme.colors[theme.mode].secondary.main};
  `,
  outlinedTransparent: css`
    background-color: transparent;
    border-color: ${theme.colors[theme.mode].black};
    color: ${theme.colors[theme.mode].black};
  `,
  white: {
    backgroundColor: theme.colors[theme.mode].white,
    borderColor: theme.colors[theme.mode].white,
    color: theme.colors[theme.mode].black,
  },
  info: {
    backgroundColor: theme.colors[theme.mode].info,
    borderColor: theme.colors[theme.mode].info,
  },
  square: css`
    ${shorthand.square}
  `,
  dense: css`
    height: 30px;
  `,
  w300px: css`
    width: 300px;
  `,
}));

export default componentModifiers;
