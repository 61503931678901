import { ADDRESS as FIELDS } from '@mmw/constants-fields-names';
// import {
//   ADDRESS as VALIDATIONS,
// } from '@mmw/constants-fields-validations';
import { INVALID } from '@mmw/constants-validation-names';

const namespace = 'validation';

const ADDRESS = {
  COMPANY: {},
  STREET: {},
  NR: {},
  COMPLEMENT: {},
  ZIPCODE: {
    INVALID: {
      key: `${namespace}.${FIELDS.ZIPCODE}.${INVALID}`,
      message: 'Zip Code must be valid',
    },
  },
  CITY: {},
  STATE: {},
  COUNTRY: {},
  NAME1: {},
  NAME2: {},
};

export default ADDRESS;
