const namespace = 'recSelectPartnerProgram';

const SELECT_PARTNER_PROGRAM = {
  NAME: {
    key: `${namespace}`,
    message: 'REC Select Partner Program',
  },
  TITLE: {
    key: `${namespace}.title`,
    message: 'REC Select',
  },
  YOUR_LEVEL_LABEL: {
    key: `${namespace}.yourLevel`,
    message: 'Your level',
  },
  YOUR_BALANCE_LABEL: {
    key: `${namespace}.yourBalance`,
    message: 'Your balance',
  },
  YOU_EARNED_LABEL: {
    key: `${namespace}.youEarned`,
    message: 'You earned with this registration',
  },
  YOUR_STATEMENT: {
    key: `${namespace}.yourStatement`,
    message: 'Your statement',
  },
  TERMS_AND_CONDITIONS: {
    key: `${namespace}.termsAndConditions`,
    message: 'Terms and Conditions',
  },
  YOUR_INSTALLATIONS: {
    key: `${namespace}.yourInstallations`,
    message: 'Your installations',
  },
  NEXT_LEVEL: {
    key: `${namespace}.nextLevel`,
    message: 'Next level',
  },
  ACHIEVED_LEVEL: {
    key: `${namespace}.achievedLevel`,
    message: 'Achieved level',
  },
  NEXT_LEVEL_MARK_LABEL: {
    YOU_NEED: {
      key: `${namespace}.nextLevelMark.youNeed`,
      message: 'You need',
    },
    TO_NEXT_LEVEL: {
      key: `${namespace}.nextLevelMark.toNext`,
      message: 'to the next level',
    },
  },
  INFORMATIVE: {
    EACH_REGISTERED_WATT: {
      key: `${namespace}.eachRegisteredWatt`,
      message: 'Each registered kWatt',
    },
    REGISTER_YOUR_INSTALLATIONS: {
      key: `${namespace}.registerYourInstallations`,
      message: 'Register your installations, earn points and more advantages!',
    },
    WATT_POINTS_CAN_BE_EARNED: {
      key: `${namespace}.wattPointsCanBeEarned`,
      message:
        'WattPoints can be earned by participants and redeemed for vouchers that allow a discount for purchases from an official REC distributor.',
      // message:
      //   'WattPoints can be earned by participants and redeemed for vouchers that allow a discount for purchases from an official REC distributor.<br /><br />WattPoints can be earned by registering REC installations with either TwinPeak 4 or Alpha family panels',
    },
    REC_TWIN_PEAK4_SERIES_KW_TO_RECOINS: {
      key: `${namespace}.recTwinpeak4SeriesKWattToRecoins`,
      message:
        'Each registered kWatt from a TwinPeak 4 family product corresponds to 1 RECoins',
    },
    FROM_TWIN_PEAK_FAMILY: {
      key: `${namespace}.fromTwinPeakFamily`,
      message: 'from a TwinPeak 4 family product corresponds to',
    },
    TWIN_PEAK_REWARD: {
      key: `${namespace}.twinPeakReward`,
      message: '1 RECoins',
    },
    FROM_ALPHA_FAMILY: {
      key: `${namespace}.fromTwinPeakFamily`,
      message: 'from a Alpha family product corresponds to',
    },
    ALPHA_REWARD: {
      key: `${namespace}.twinPeakReward`,
      message: '2 RECoins',
    },
    REC_ALPHA_SERIES_KW_TO_RECOINS: {
      key: `${namespace}.recAlphaSeriesKWattToRecoins`,
      message:
        'Each registered kWatt from a Alpha family product corresponds to 2 RECoins',
    },
    INSTALLATION_QUALIFIED: {
      key: `${namespace}.installationQualified`,
      message:
        'Nice Work! this installation qualifies for the REC SELECT Loyalty Program, where you can earn',
    },
    PAST_INSTALLATIONS: {
      key: `${namespace}.installationQualified`,
      message:
        'Your installations registered in 2021 qualify for the REC SELECT Loyalty Program, where you can earn',
    },
    BANNER_DESCRIPTION: {
      key: `${namespace}.bannerDescription`,
      message:
        'Register your installations and earn RECoins.  Redeem them for discount vouchers, plus more benefits!',
    },
    MORE_INFORMATION_BUTTON: {
      key: `${namespace}.moreInformationButton`,
      message: 'More information about REC SELECT',
    },
  },
  REDEEM_YOUR_REC_COINS: {
    key: `${namespace}.redeemYourRECCoins`,
    message:
      'Redeem your RECoins for a voucher that you can use for discount at any official REC distributor in your region',
  },
  REDEEM_REC_COINS: {
    key: `${namespace}.redeemRECCoins`,
    message: 'REDEEM RECoins',
  },
  DISABLED_REDEEM_RECOIN_WARNING: {
    key: `${namespace}.disabledRedeemWarning`,
    message:
      'We are unable to process the redemption of your RECoins due to system size limitations. To initiate the redemption, your total registered volume must be 300kWp or higher. Thank you for your understanding!',
  },
  CONVERT_YOUR_RECOINS: {
    key: `${namespace}.convertRECCoins`,
    message: 'Convert your RECoins',
  },
  CONVERT_YOUR_RECOINS_TO_BUDGET: {
    key: `${namespace}.convertRECCoinsToMarketingBudget`,
    message: 'Convert your RECoins to Marketing Budget',
  },
  ENTER_THE_AMOUNT_OF_REC_COINS: {
    key: `${namespace}.enterTheAmountOfRecCoins`,
    message: 'Enter the amount of RECoins you would like to redeem.',
  },
  REDEEM: {
    key: `${namespace}.redeem`,
    message: 'Redeem',
  },
  AVAILABLE: {
    key: `${namespace}.available`,
    message: 'Available',
  },
  CURRENT_VALUE: {
    key: `${namespace}.currentValue`,
    message: 'Current value',
  },
  VOUCHERS: {
    key: `${namespace}.vouchers`,
    message: 'Vouchers',
  },
  VOUCHER: {
    key: `${namespace}.voucher`,
    message: 'Voucher',
  },
  STATUS: {
    key: `${namespace}.status`,
    message: 'Status',
  },
  REDEEM_MODAL: {
    WITH_THIS_OPERATION: {
      key: `${namespace}.redeemModal.withThisOperation`,
      message: 'With this operation you will redeem',
    },
    THIS_OPERATION_WILL: {
      key: `${namespace}.redeemModal.thisOperationWill`,
      message:
        'This operation will use this voucher and give you a credit with REC worth',
    },
    CONVERSION: {
      key: `${namespace}.redeemModal.conversion`,
      // eslint-disable-next-line no-template-curly-in-string
      message: '{coins} RECoins for {currency} {value}',
    },
    DO_YOU_WANT_TO_PROCEED: {
      key: `${namespace}.redeemModal.doYouWantToProceed`,
      message: 'Do you want to proceed?',
    },
  },
  VOUCHER_ID: {
    key: `${namespace}.voucherId`,
    message: 'Voucher ID',
  },
  DATE_OF_CREATION: {
    key: `${namespace}.dateOfCreation`,
    message: 'Date of creation',
  },
  VALID_UNTIL: {
    key: `${namespace}.validUntil`,
    message: 'Valid until',
  },
  DATE_OF_USE: {
    key: `${namespace}.dateOfUse`,
    message: 'Date of use',
  },
  VALUE: {
    key: `${namespace}.value`,
    message: 'Value',
  },
  DO_YOU_WANT_TO_PARTICIPATE: {
    key: `${namespace}.wantToParticipate`,
    message: 'Do you want to participate?',
  },
  PARTICIPATE: {
    key: `${namespace}.participate`,
    message: 'Participate',
  },
  RECOINS_BALANCE_LABEL: {
    key: 'yourRECoinsBalance',
    message: 'Your balance of RECoins',
  },
};

export default SELECT_PARTNER_PROGRAM;
