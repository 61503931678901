import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React from 'react';

import Footer from './Footer';
import Header from './Header';

const Content: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren) => (
  <UI.Container
    direction="column"
    align="center"
    p="5"
    style={css`
      min-height: 100vh;
      height: 100%;
      width: 100vw;
      position: relative;
      z-index: 1;
      overflow-x: hidden;
    `}
    responsive="xsToMd.p=5, 3"
  >
    {children}
  </UI.Container>
);

interface PageStatics {
  Header: typeof Header;
  Footer: typeof Footer;
  Content: typeof Content;
}

const Page: React.FC<React.PropsWithChildren<{ height?: number }>> &
  PageStatics = ({
  children,
  height,
}: React.PropsWithChildren<{ height?: number }>) => (
  <UI.Page
    height={height}
    style={css`
      overflow-x: hidden;
    `}
  >
    {children}
  </UI.Page>
);

Page.Header = Header;
Page.Footer = Footer;
Page.Content = Content;

export default Page;
