import { INFORMATION } from '@mmw/constants-i18n-common';

const namespace = 'dataProtection';

const DATA_PROTECTION = {
  DESCRIPTION: {
    key: `${namespace}.description`,
    message:
      'Congratulations, your registration was successfully completed. If you wish to download the registration document, please click below.',
  },
  HELP: {
    TITLE: INFORMATION,
    CONTENT: {
      key: `${namespace}.helpContent`,
      message:
        'Please confirm here the agreement of the customer to the data protection regulations as well as the storage of the vouchers in your enterprise. If you do not confirm this at this point, you will later be asked to upload the signed supporting documents.',
    },
  },
  FIRST_QUESTION_LABEL: {
    key: `${namespace}.firstQuestionLabel`,
    message:
      '(If you already have the completed receipt, you can proceed directly to step 2)',
  },
  SECOND_QUESTION: {
    key: `${namespace}.secondQuestion`,
    message:
      'How would you like to manage the registration document signed by the consumer? Please choose from a) or b)',
  },
  ALT_SECOND_QUESTION: {
    key: `${namespace}.altSecondQuestion`,
    message: 'Upload the registration document',
  },
  FIRST_ALTERNATIVE: {
    key: `${namespace}.firstAlternative`,
    message:
      'I confirm that the consumer agrees to the storage and processing of his data and that the required signature is available on the registration document. These receipts are filed for controls in our company for 3 years.',
  },
  SECOND_ALTERNATIVE: {
    key: `${namespace}.secondAlternative`,
    message:
      'Complete the registration by uploading the signed registration form.',
  },
  CONFIRM_STEP_LABEL: {
    key: `${namespace}.confirmStepLabel`,
    message: '.',
  },
  CONFIRM_DATA_PROTECTION: {
    key: `${namespace}.confirmation`,
    message: 'Confirm data protection',
  },
  LEAVE_DATA_PROTECTION_PAGE: {
    key: `${namespace}.leaveDataProtectionPage`,
    message:
      'Are you sure that you want to leave this page? Your registration will be incomplete without data protection consent.',
  },
};

export default DATA_PROTECTION;
