import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setFieldValueAction } from '../store/actions';
import { FieldPath } from '../types';
import useFormId from './useFormId';

type SetFieldValueCb<T> = (fieldPath: FieldPath, value: T) => void;

function useSetFieldValue<T>(): SetFieldValueCb<T> {
  const dispatch = useDispatch();
  const formId = useFormId();
  return useCallback(
    (fieldPath, value: T) =>
      dispatch(setFieldValueAction(formId, fieldPath, value)),
    [dispatch, formId],
  );
}
export function useSetFieldValueByFormId<T>(
  formId?: string,
): SetFieldValueCb<T> {
  const dispatch = useDispatch();
  return useCallback(
    (fieldPath, value: T) =>
      dispatch(setFieldValueAction(formId, fieldPath, value)),
    [dispatch, formId],
  );
}

export default useSetFieldValue;
