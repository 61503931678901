import { I18nShape } from '@mmw/constants-i18n';
import { useFastFormField } from '@mmw/redux-store-fast-form/hooks/fields';
import UI from '@ui-system/ui';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

interface Props {
  fieldPath: string;
  disabledCaption?: boolean;
  i18n?: I18nShape;
  [props: string]: any;
}
const FastFormCheckBox: React.FC<Props> = ({
  fieldPath,
  i18n,
  disabledCaption,
  ...rest
}: Props) => {
  const { value, setValue, errors, canShowError } =
    useFastFormField<boolean>(fieldPath);

  const caption: I18nShape | null = useMemo(() => {
    if (!isEmpty(errors?.message) && errors) {
      return {
        message: errors.message,
        key: errors.message,
        values: { value },
      };
    }
    return null;
  }, [errors, value]);

  return (
    <UI.Form.Checkbox
      checked={value}
      onChange={setValue}
      labelI18n={i18n}
      error={canShowError && !isEmpty(errors)}
      caption={!disabledCaption && caption}
      {...rest}
    />
  );
};

FastFormCheckBox.defaultProps = {
  i18n: undefined,
  disabledCaption: false,
};
export default FastFormCheckBox;
