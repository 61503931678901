import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  submitErrorAction,
  submitStartAction,
  submitSuccessAction,
} from '../../store/actions';
import { ValidationError } from '../../types';
import { useFormValues } from '../internal';
import useFormId from '../useFormId';
import useFormValidationSchema from '../useFormValidationSchema';

function useSubmit() {
  const dispatch = useDispatch();
  const formId = useFormId();
  const values = useFormValues();
  const validationSchema = useFormValidationSchema();
  return useCallback(() => {
    dispatch(submitStartAction(formId, validationSchema));
    validationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        dispatch(submitSuccessAction(formId, values));
      })
      .catch(error => {
        const validationError = error as ValidationError;
        dispatch(submitErrorAction(formId, values, validationError.inner));
      });
  }, [dispatch, formId, validationSchema, values]);
}

export default useSubmit;
