import { isString } from 'lodash';
import { InterpolationFunction, StyledProps } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { Theme } from '../../types';

const propModifierName = 'modifiers';
const splitByComma: (arg0: string) => string[] = str => str.split(', ');

type ComponentsProps = StyledProps<Theme & Record<string, any>>;
type ModifiersToApplyType =
  | Record<string, (props: ComponentsProps) => string>
  | Record<string, (props: ComponentsProps) => string>[];

// apply modifiers on styled-components
export default (
  modifiersToApply: ModifiersToApplyType,
): InterpolationFunction<ComponentsProps> => {
  if (Array.isArray(modifiersToApply)) {
    return applyStyleModifiers(
      modifiersToApply.reduce(
        (appliedModifiers, modifierToApply) => ({
          ...appliedModifiers,
          ...modifierToApply,
        }),
        {},
      ),
    );
  }
  return (props: ComponentsProps) => {
    const modifiers = props[propModifierName];
    if (modifiers && isString(modifiers)) {
      const parsedProps = { ...props, modifiers: splitByComma(modifiers) };
      return applyStyleModifiers(modifiersToApply)(parsedProps);
    }
    return applyStyleModifiers(modifiersToApply)(props);
  };
};
